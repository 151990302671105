import * as model from "../lib/model";

import * as hotkey from "../lib/hotkeyClient";

export class TagSearchItem {
    text: string;
    section?: model.AppSections;
    constructor(data: Partial<TagSearchItem>) {
        Object.assign(this, data);
    }
}

export class CommandResultItem {
    category: string;
    command: () => void;
    name: String;
    text?: String;
    shortcut?: String;
    id: number;
}
export function getCommands(connect: model.ConnectProps): Array<CommandResultItem> {
    let retval = new Array<CommandResultItem>();
    let id = 0;
    let me = this;
    function redirectToTagWithSting(tag: string) {
        let me = this;
        let vs = connect.state.viewState.deepCopy();

        vs.appSection = model.AppSections.notes;
        vs.noteViewState = new model.NoteViewState();
        vs.noteViewState.functionType = model.NoteFunctionType.tag;
        vs.noteViewState.query = tag
        window.location.hash = vs.getHash();
    }
    retval.push({
        category: 'Jump',
        command: () => {
            let vs = connect.state.viewState.deepCopy();
            vs.appSection = model.AppSections.inbox
            window.location.hash = vs.getHash();
        }, id: id++,
        name: "Inbox"
    })
    retval.push({
        category: 'Jump',
        command: () => {
            let vs = connect.state.viewState.deepCopy();
            vs.appSection = model.AppSections.today
            window.location.hash = vs.getHash();
        }, id: id++,
        name: "Today"
    })
    retval.push({
        category: 'Jump',
        command: () => {
            let vs = connect.state.viewState.deepCopy();
            vs.appSection = model.AppSections.snoozed
            window.location.hash = vs.getHash();
        }, id: id++,
        name: "Upcoming"
    })
    retval.push({
        category: 'Jump',
        command: () => {
            let vs = connect.state.viewState.deepCopy();
            vs.appSection = model.AppSections.notes
            window.location.hash = vs.getHash();
        }, id: id++,
        name: "Notes"
    })
    retval.push({
        category: 'Jump',
        command: () => {
            let vs = connect.state.viewState.deepCopy();
            vs.appSection = model.AppSections.settings
            window.location.hash = vs.getHash();
        }, id: id++,
        name: "Settings"
    })
    retval.push({
        category: 'Jump',
        command: () => {
            let vs = connect.state.viewState.deepCopy();
            vs.appSection = model.AppSections.bydate
            window.location.hash = vs.getHash();
        }, id: id++,
        name: "By Date"
    })
    let activeCommands = hotkey.HotKeyEngine.instance().getActiveCommands();
    activeCommands.forEach(x => {
        let searchItem = {
            category: 'Command',
            command: x.action,
            id: id++,
            name: x.description,
            text: x.description + ' ' + hotkey.HotKeyEngine.instance().getKey(x.actionType),
            shortcut: x.actionType ? hotkey.HotKeyEngine.instance().getKey(x.actionType) : null
        }

        retval.push(searchItem)
    })
    if (connect.state.tags) {
        connect.state.tags.forEach(x => {
            let searchItem = {
                category: 'Tag',
                command: () => {
                    redirectToTagWithSting(x.name);
                },
                id: id++,
                name: x.name,

            }

            retval.push(searchItem)
        })
    }
    return retval;
}
