import * as React from "react";
import * as material from "material-ui";
import * as pApi from "../../../shared/papi/papi-core";
import { RecoccuringPicker } from "./ReoccuringPicker";
import * as hotkey from "../../lib/hotkeyClient";



import { TextParseEvent, RichTodoInput } from "./RichTodoInput";

import * as model from '../../lib/model';
import * as actions from '../../lib/ActionReducer';
import { SnoozeTyper } from "../SnoozeTyper"
import CheckIcon from "material-ui/svg-icons/navigation/check";
import ChipInput from "material-ui-chip-input";
//icons
import AlarmOn from "material-ui/svg-icons/action/alarm-on";
import AlarmOff from "material-ui/svg-icons/action/alarm-off";
import RepeatIcon from "material-ui/svg-icons/av/repeat";
import StarOn from "material-ui/svg-icons/toggle/star";
import StarOff from "material-ui/svg-icons/toggle/star-border";
import TodayIcon from "material-ui/svg-icons/action/today";
import UnWatch from "../CustomSvg/UnWatch";
import LinkIcon from "material-ui/svg-icons/content/link";
import NotesIcon from "material-ui/svg-icons/content/content-paste";
import Hash from "../CustomSvg/Hash";
import HighlightOff from "material-ui/svg-icons/action/highlight-off";
import { SvgIcon } from "material-ui";
import { ErrorObj } from "../../../shared/track";

import { ToDoItem } from "../../../shared/papi/papi-core";
import FlatButton from "material-ui/FlatButton";

import Dialog from 'material-ui/Dialog';//'../CustomDialog';// 'material-ui/Dialog';// 'hacked-custom-dialog/lib/CustomDialog';
import moment from 'moment';
//let Dialog = require('hacked-custom-dialog')
var colors = require("material-ui/styles/colors");

/*

 let subtextConponent = (
      
    );*/
interface TodoSummaryProps {
  todos: Array<pApi.ToDoItem>;
  showAllTags: boolean;
  onDateClick: () => void;
  onTagClick: () => void;
  onReoccuringClick: () => void;
}
interface TodoSummaryState { }

export class TodoSummary extends React.Component<
  TodoSummaryProps,
  TodoSummaryState
  > {
  firstTodo(): pApi.ToDoItem {
    return this.props.todos[0];
  }
  hasMultiple(): boolean {
    return this.props.todos.length > 1;
  }
  getTagText(): string {
    let retval = "";
    if (this.props.showAllTags) {
      let tags = new Array<string>();
      for (var i in this.props.todos) {
        let todo = this.props.todos[i];
        if (todo.tags != null) {
          for (var ii in todo.tags) {
            if (tags.indexOf(todo.tags[ii]) == -1) {
              tags.push(todo.tags[ii]);
            }
          }
        }
      }
      if (tags.length > 0) retval = "#" + tags.join(", #");
    } else {
      if (this.firstTodo().tags && this.firstTodo().tags.length > 0) {
        retval = "#" + this.firstTodo().tags[0];
      }
    }

    return retval;
  }
  render() {
    var dateText = this.firstTodo().due_date
      ? moment(this.firstTodo().due_date).calendar(null, {
        lastDay: "[Yesterday]",
        sameDay: "[Today] h:mm a",
        nextDay: "[Tomorrow] h:mm a",
        lastWeek: "[last] dddd",
        nextWeek: "dddd",
        sameElse: "L"
      })
      : "";
    var tagText = this.getTagText();
    return (
      <div className="todo-subtext-with-wrap ">
        <span onClick={this.props.onDateClick} className="todo-subtext-action">
          {dateText}
        </span>
        {dateText ? " - " : ""}
        {tagText != "" && (
          <span onClick={this.props.onTagClick} className="todo-subtext-action">
            {tagText}
          </span>
        )}
        <span style={{ marginLeft: "10px", paddingTop: 2 }} />
        {!this.hasMultiple() && this.firstTodo().recoccuring_settings && (
          <span
            onClick={this.props.onReoccuringClick.bind(this)}
            className="todo-subtext-action"
          >
            <RepeatIcon style={{ width: "16px", height: "16px" }} />
          </span>
        )}
        {!this.hasMultiple() && this.firstTodo().alarm && (
          <AlarmOn style={{ width: "16px", height: "16px" }} />
        )}
        {!this.hasMultiple() && this.firstTodo().hide_from_inbox && (
          <span>
            <UnWatch style={{ width: "16px", height: "16px" }} /> hidden from
            inbox
          </span>
        )}
      </div>
    );
  }
}

interface TagSelectorProps {
  connect: model.ConnectProps;
  tags: Array<string>;
  autoFocus: boolean;
  hideLabel: boolean;
  onUpdate: (tags: Array<string>) => void;
  onCancel: () => void;
  onReturnWithNoTag?: () => void
}
interface TagSelectorState {
  //tags: Array<string>;
}
export class TagSelector extends React.Component<
  TagSelectorProps,
  TagSelectorState
  > {
  constructor(props: TagSelectorProps) {
    super(props);
    this.state = {
      tags: props.tags ? props.tags : []
    };
  }
  componentDidMount() {
    if (this.props.autoFocus) {
      //@ts-ignore
      this.refs.chip.focus()
    }
  }
  handleAddChip(chip: string) {
    console.log("handleAddChip " + chip)
    var state = {
      tags: this.props.tags
    }; //{ ...this.state };
    var chip = chip.toLocaleLowerCase().replace("#", "");
    state.tags = state.tags ? state.tags : [];

    state.tags.push(chip.toLocaleLowerCase().replace("#", ""));
    this.props.onUpdate(state.tags);
    // this.setState(state);
  }
  handleBeforeAddChip(chip: string) {
    console.log("handleBeforeAddChip")
    if (!chip) {
      if (this.props.onReturnWithNoTag) {
        this.props.onReturnWithNoTag();
        return false;
      }
    }
    //var state = { ...this.state };
    var state = {
      tags: this.props.tags
    }; //{ ...this.state };
    var chip = chip.toLocaleLowerCase().replace("#", "");
    let found = false;
    for (var i in state.tags) {
      if (state.tags[i] == chip) {
        return false;
      }
    }
    return true;
  }
  handleDeleteChip(chip: any, index: number) {
    // var state = { ...this.state };
    var state = {
      tags: this.props.tags
    };
    state.tags.splice(index, 1);
    this.props.onUpdate(state.tags);
    //this.setState(state);
  }
  handleChipChange(chips: [string]) {
    console.log("CHIP")
    // var state = { ...this.state };
    var state = {
      tags: this.props.tags
    };
    state.tags = chips;
    this.props.onUpdate(state.tags);
    // this.setState(state);
  }
  onChipClick(e) { }
  render() {
    var tagNames = [];
    if (this.props.connect.state.TagsState == model.LoadingState.Completed) {
      //this.props.connect.state.tags.map(x => tagNames.push(x.name));
      this.props.connect.state.tags.map(x => {
        return {
          count: x.name.split('/').length,
          name: x.name
        }
      }).sort((a, b) => {
        if (a.count == b.count) {
          if (a.name > b.name)
            return 1;
          if (a.name < b.name)
            return -1;
          return 0;
        }
        if (a.count > b.count)
          return 1;
        if (a.count < b.count)
          return -1;
        return 0;

      }).forEach(x=>tagNames.push(x.name))
   
    }
    let me = this;

    return (
      <ChipInput
        style={{
          background: "transparent",
          border: "none",
          width: "100%",
          margin: "0px"
        }}
        
        autoFocus={this.props.autoFocus}
        maxSearchResults={7}
        fullWidthInput={false}
        value={this.props.tags}
        onClick={this.onChipClick.bind(this)}
        floatingLabelText={this.props.hideLabel ? null : "# Hashtags"}
        ref="chip"
        className="joyride-edit-tags custom-nomargin"
        chipRenderer={(value: any, key) => {
          if (
            value.isFocused &&
            this.props.tags &&
            this.props.tags.length > 0
          ) {
            if (this.props.tags[0] != value.text) {
              let newArray = [];
              newArray.push(value.text);
              for (var i in this.props.tags) {
                if (this.props.tags[i] != value.text) {
                  newArray.push(this.props.tags[i]);
                }
              }

              /// var s = { ...this.state };
              //s.tags = newArray;
              me.props.onUpdate(newArray);
              // this.setState(s);
            }
          }
          let chip = new material.Chip(value);

          return (
            <material.Chip
              key={key}
              style={{
                ...value.defaultStyle,
                pointerEvents: value.isDisabled ? "none" : undefined
              }}
              backgroundColor={
                value.isFocused || key == 0 ? colors.grey400 : null
              }
              onClick={value.handleClick}
              onRequestDelete={value.handleRequestDelete}
            >
              {value.text}
            </material.Chip>
          );
        }}
        allowDuplicates={false}
        onRequestAdd={this.handleAddChip.bind(this)}
        onBeforeRequestAdd={this.handleBeforeAddChip.bind(this)}
        onRequestDelete={this.handleDeleteChip.bind(this)}
        onChange={this.handleChipChange.bind(this)}

        newChipKeyCodes={[9, 13]}
        onUpdateInput={(searchText: any, dataSource: any, params: any) => {
          //allows for autocorrection on iphone
          console.log("-" + searchText + "-");
          if (searchText[searchText.length - 1] == " ") {
            me.handleAddChip(searchText.trim());

            this.refs.chip["autoComplete"].handleBlur();
            this.refs.chip["clearInput"]();
          }

          return;
          /* if(searchText[searchText.length-1]==' ')
             {
                var s = {...this.state};
                s.todo.tags.push(searchText);
                this.setState(s);
             }*/
        }}
        dataSource={tagNames}
      />
    );
  }
}

interface TagDialogProps {
  connect: model.ConnectProps;
  tags: Array<string>;
  onUpdate: (tags: Array<string>) => void;
  onCancel: () => void;
}
interface TagDialogState {
  tags: Array<string>;
}

export class TagSelectorDialog extends React.Component<
  TagDialogProps,
  TagDialogState
  > {
  constructor(props: TagDialogProps) {
    super(props);
    this.state = {
      tags: props.tags
    };
  }

  onSubmit(tags: Array<string>) {
    this.props.onUpdate(tags);
  }
  onClear() {
    this.props.onUpdate([]);
  }
  render() {
    const actionsBtn = [
      <material.FlatButton
        label="Cancel"
        onClick={this.props.onCancel.bind(this)}
      />,
      <material.FlatButton
        label="Clear Tags"
        onClick={this.onClear.bind(this)}
      />,
      <material.FlatButton
        label="Update Tags"
        onClick={this.onSubmit.bind(this)}
      />
    ];

    let defaults = {
      beforeStyle: {
        paddindTop: "0px",
        opacity: 0
      } as any,
      afterStyle: {
        opacity: 1,
        paddindTop: "230px"
      } as any,
      root: { padding: "0px" } as any,
      content: {} as any,
      actionsContainer: {},
      overlay: {},
      title: {} as any,
      bodyStyle: {
        padding: "15px"
      }
    };

    if (
      this.props.connect.getPresentationMode() == model.PresentationMode.mobile
    ) {
      defaults.beforeStyle = {
        opacity: 1
        /* position: "absolute",
        bottom: "-600px",
        transform: "none"*/
      };
      defaults.afterStyle = {
        opacity: 1
        /*  position: "absolute",
        bottom: "0px",
        transform: "none"*/
      };
      defaults.content = {
        width: "100%"
        /*  position: "absolute",*/
      };
      defaults.bodyStyle = {
        padding: "0px"
      };
    }
    let me = this;

    return (
      <Dialog
        title="Tags"
        titleStyle={{ display: "none" }}
        overlayStyle={actions.getOverlayStyle()}

        contentStyle={defaults.content}
        actions={actionsBtn}
        onRequestClose={e => {
          me.props.onCancel()
        }}
        open={true}
      >
        <TagSelector
          connect={this.props.connect}
          tags={this.props.tags}
          autoFocus={true}
          hideLabel={false}
          onCancel={this.props.onCancel.bind(this)}
          onUpdate={this.onSubmit.bind(this)}
        />
      </Dialog>
    );
  }
}

interface TodoActionProps {
  disabled: boolean;
  activated: boolean;
  className: string;
  tooltip: string;
  iconOn: JSX.Element;
  iconOff: JSX.Element;
  onClick: () => void;
}
interface TodoActionState { }

export class TodoAction extends React.Component<
  TodoActionProps,
  TodoActionState
  > {
  render() {
    return (
      <div
        className={this.props.className}
        style={{ padding: "0px", textAlign: "center" }}
      >
        <material.IconButton
          tooltip={this.props.tooltip}
          disabled={this.props.disabled}
          tooltipPosition="bottom-center"
          onClick={this.props.onClick.bind(this)}
        >
          {this.props.activated && <div>{this.props.iconOn}</div>}
          {!this.props.activated && <div>{this.props.iconOff}</div>}
        </material.IconButton>
      </div>
    );
  }
}

interface TodoEditProps {
  connect: model.ConnectProps;
  todo: model.LoadingObject<pApi.ToDoItem>
}
interface TodoEditState {
  todo: pApi.ToDoItem;
  lastParseEvent: TextParseEvent;
  snoozePopup: boolean;
  recoccuringShow: boolean;
  tagPopup: boolean;
}

export class TodoEdit extends React.Component<TodoEditProps, TodoEditState> {
  constructor(props: TodoEditProps, context) {
    super(props, context);

    this.state = {
      todo: props.todo.val(),
      lastParseEvent: null,
      snoozePopup: false,
      recoccuringShow: false,
      tagPopup: false
    };

  }
  componentDidMount() {
    var me = this;

    let instance = hotkey.HotKeyEngine.instance();
    let handlers = new Map<hotkey.HotKeyActionType, () => boolean>();
    handlers.set(
      hotkey.HotKeyActionType.esc,
      (): boolean => {
        me.props.connect.dispatch<any>(actions.closeTodoModal());
        return true;
      }
    );
    handlers.set(
      hotkey.HotKeyActionType.optionComplete,
      (): boolean => {
        me.completeTodo();
        return true;
      }
    );
    handlers.set(
      hotkey.HotKeyActionType.optionHide,
      (): boolean => {

        me.onHideFromInboxToggle({})
        return true;
      }
    );
    handlers.set(
      hotkey.HotKeyActionType.optionStar,
      (): boolean => {
        me.priorityToggle()
        return true;
      }
    );
    handlers.set(
      hotkey.HotKeyActionType.optionSnooze,
      (): boolean => {
        me.scheduleClick();
        return true;
      }
    );

    /*  handlers.set(
      hotkey.HotKeyActionType.complete,
      (): boolean => {
        let state = me.state;
        state.todo.checked = !state.todo.checked;
        me.setState(state);
        return true;
      }
    );*/
    if (!this.state.todo && (this.props.connect.state.viewState.todoId || (this.props.connect.state.viewState.todoId && this.state.todo.id != this.props.connect.state.viewState.todoId))) {
      this.props.connect.dispatch(actions.ar_todoLoadAndOpen(this.props.connect.state.viewState.todoId));
    }
    instance.registerHandlers("todoedit", "todoedit", true, handlers);
  }
  componentWillUnmount() {
    let instance = hotkey.HotKeyEngine.instance();
    instance.unregisterHandlers("todoedit");
  }
  componentWillReceiveProps(nextProps: TodoEditProps) {
    /*  if (
        (!this.props.todo.val() && !nextProps.todo.val())  ||
        (this.props.todo. && !nextProps.todo) ||
        ((!this.props.todo && nextProps.todo) ||
          (this.props.todo &&
            nextProps.todo &&
            this.props.todo.id != nextProps.todo.id))
      ) {*/

    let thisTodo = this.state.todo
    let lastTodo = nextProps.todo
    if (
      (this.props.todo.version != nextProps.todo.version) ||
      (!thisTodo && lastTodo) ||
      (thisTodo && !lastTodo.val()) ||
      (thisTodo && lastTodo.val() && thisTodo.id != lastTodo.val().id)) {
      var nameText = "";

      this.setState({
        todo: nextProps.todo.val()
      });
      return true;
    }
  }
  async saveAndClose(todo: pApi.ToDoItem) {
    let vs = this.props.connect.state.viewState.deepCopy();
    vs.todoId = null;
    window.location.hash = vs.getHash();
    this.props.connect.dispatch<any>(actions.closeTodoModal());
    try {
      await this.props.connect.state.context.Todos.save(todo as pApi.ToDoItem);
      if (todo.checked) {
        this.props.connect.dispatch<any>(
          actions.ar_showUndoTodoCheckSnackBar([todo.id])
        );
      }
    } catch (e) {
      console.log(e);
      let err = new ErrorObj();
      err.showModal = true;
      err.exception = e;
      err.userMessage = "There was a problem updating the todo";
      actions.ar_setError(err);
    }
  }
  reopenTodo() {
    let todo = this.state.todo;
    todo.checked = false;
    this.saveAndClose(todo);
  }
  completeTodo() {

    let todo = this.state.todo;
    todo.checked = true;
    this.saveAndClose(todo);
  }
  async saveTodo(e) {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    if (this.state.todo.description.trim() != "") {
      let todo = this.state.todo;

      this.saveAndClose(todo);
    }
    return false;
  }
  priorityToggle() {
    var todo = { ...this.state.todo };
    todo.priority =
      todo.priority == pApi.ToDoItemPriority.High
        ? pApi.ToDoItemPriority.Normal
        : pApi.ToDoItemPriority.High;
    this.setState({ todo: todo });
  }
  alert(message: string) {
    let sn = new model.SnackMessage();
    sn.message = message;
    sn.autoHideDuration = 4000;
    this.props.connect.state.context.dispatch(actions.ar_snackBarShow(sn));
  }
  alarmAlert() {
    let t = moment(this.state.todo.due_date).calendar();
    this.alert("Alarm set for " + t);
  }
  alarmToggle() {
    let s = { ...this.state };

    s.todo.alarm = !s.todo.alarm;
    if (this.state.todo.due_date == null) {
      s.snoozePopup = true;
      //this.refs.datePicker["openDialog"]();
    } else {
      if (s.todo.alarm) {
        this.alarmAlert();
      }
    }

    this.setState(s);
  }

  reoccuringClick() {
    var state = { ...this.state };

    state.recoccuringShow = true;
    this.setState(state);
  }
  recoccuringChange(settings: pApi.ReoccuringSettings) {
    let state = { ...this.state };
    state.recoccuringShow = false;
    state.todo.recoccuring_settings = settings;
    this.setState(state);
  }
  reoccuringClose() {
    let state = { ...this.state };
    state.recoccuringShow = false;
    this.setState(state);
  }
  onHideFromInboxToggle(e) {
    if (!this.state.todo.hide_from_inbox) {
      if (!this.state.todo.tags || this.state.todo.tags.length == 0) {
        alert(
          "To hide to-do from your Inbox you must supply at least one hashtag."
        );
        return;
      }
      if (this.state.todo.priority == pApi.ToDoItemPriority.High) {
        alert(
          "Note: This to-do will still show up in your Inbox because it is stared.  Un-star this to-do to hide it from your Inbox."
        );
      }
      if (this.state.todo.due_date != null) {
        alert(
          "Note: This to-do will still show up in your Inbox when it is scheduled. Remove the scheduled date to hide it from your Inbox."
        );
      }
    }
    let state = { ...this.state };
    state.todo.hide_from_inbox = !state.todo.hide_from_inbox;
    this.setState(state);
  }
  async setSnoozeDate(snoozeAction: pApi.SnoozeAction, date?: Date) {
    let s = { ...this.state };
    s.snoozePopup = false;
    s.todo.due_date = pApi.getSnoozeDate(snoozeAction, date);

    this.setState(s);
  }
  onClose(e) {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    this.props.connect.dispatch<any>(actions.closeTodoModal());
    let vs = this.props.connect.state.viewState.deepCopy();
    vs.todoId = null;
    window.location.hash = vs.getHash();

    //  this.props.connect.dispatch<any>(this.props.closeTodoModal());
  }
  tagClick() {
    let s = { ...this.state };
    s.tagPopup = true;
    this.setState(s);
  }
  tagCancel() {
    let s = { ...this.state };
    s.tagPopup = false;
    this.setState(s);
  }
  tagUpdate(tags: Array<string>) {
    let s = { ...this.state };
    s.tagPopup = false;
    s.todo.tags = tags;
    this.setState(s);
  }
  checkToggle() {
    let s = { ...this.state };
    s.todo.checked = !s.todo.checked;
    this.setState(s);
  }
  scheduleClick() {
    let s = { ...this.state };
    s.snoozePopup = true;
    this.setState(s);
  }
  clearDate(e) {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    let s = { ...this.state };
    s.todo.due_date = null;
    this.setState(s);
  }
  render() {
    let me = this;
    let defaults = {
      beforeStyle: {
        paddindTop: "0px",
        opacity: 0
      } as any,
      afterStyle: {
        opacity: 1,
        paddindTop: "230px"
      } as any,
      root: { padding: "0px" } as any,
      content: {} as any,
      actionsContainer: {},
      overlay: {},
      title: {} as any,
      bodyStyle: {
        padding: "0px"
      }
    };
    if (!this.state.todo) {

      return (<div>no todo</div>)
    }
    if (
      this.props.connect.getPresentationMode() == model.PresentationMode.mobile
    ) {
      defaults.beforeStyle = {
        opacity: 1
        /* position: "absolute",
        bottom: "-600px",
        transform: "none"*/
      };
      defaults.afterStyle = {
        opacity: 1
        /* position: "absolute",
        bottom: "0px",
        transform: "none"*/
      };
      defaults.content = {
        width: "100%"
        /*position: "absolute",*/
      };
      defaults.bodyStyle = {
        padding: "0px"
      };
    }
    let title = (
      <div>
        Snooze until...
        <div
          style={{
            padding: "0px",
            margin: "0px",
            position: "absolute",
            right: "10px",
            top: "0px"
          }}
        >
          <material.FlatButton
            label="Cancel"
            primary
            onClick={e => {
              me.onClose(e);
            }}
          />
        </div>
      </div>
    );
    let SaveText = "Save";
    let SaveColor = null;

    if (!this.state.todo.checked) {
      SaveText = "Complete";
      SaveColor = "green";
    } else if (this.state.todo.checked) {
      SaveText = "Reopen";
    }
    const actionsBtn = [];
    var noteLink = model.TodoLinkHelper.getNoteLink(
      this.state.todo.note_id,
      this.props.connect.state.viewState
    );
    var todoLink = model.TodoLinkHelper.getLink(this.state.todo);
    if (
      this.props.connect.getPresentationMode() == model.PresentationMode.web
    ) {
      if (noteLink) {
        actionsBtn.push(
          <material.FlatButton
            onClick={e => {
              this.props.connect.dispatch<any>(actions.closeTodoModal());
              window.location.hash = noteLink;
              e.preventDefault();
              e.stopPropagation();
            }}
            icon={<NotesIcon />}
            label="Open Note"
          />
        );
      }
      if (todoLink) {
        actionsBtn.push(
          <material.FlatButton
            onClick={e => {

              //var win = window.open(todoLink, "_system");
              window["openLink"](todoLink);//

              e.preventDefault();
              e.stopPropagation();
            }}
            icon={<LinkIcon />}
            label="Open Link"
          />
        );
      }
    }
    actionsBtn.push(
      <material.FlatButton label="Cancel" onClick={this.onClose.bind(this)} />
    );
    if (this.state.todo.modified != null) {
      if (this.state.todo.checked) {
        actionsBtn.push(
          <material.FlatButton
            label={'Reopen'}
            style={{ color: 'green' }}
            onClick={this.reopenTodo.bind(this)}
          />
        );
      }
      else {
        actionsBtn.push(
          <material.FlatButton
            label={'Complete'}
            style={{ color: 'green' }}
            onClick={this.completeTodo.bind(this)}
          />
        );
      }
    }
    actionsBtn.push(
      <material.FlatButton
        label="Save"
        style={{
          color: SaveColor
        }}

        disabled={
          !this.state.todo.description || this.state.todo.description == ""
        }
        onClick={this.saveTodo.bind(this)}
      />
    );
    let buttonRowStyle = {};
    if (
      this.props.connect.getPresentationMode() == model.PresentationMode.mobile
    ) {
      buttonRowStyle = { padding: "0px" };
    }
    let hideDisable =
      this.state.todo.tags && this.state.todo.tags.length > 0 ? false : true;
    let hideFromInbox =
      this.state.todo.tags && this.state.todo.tags.length > 0
        ? this.state.todo.hide_from_inbox
        : false;

    let rowClass =
      this.props.connect.getPresentationMode() == model.PresentationMode.mobile
        ? "row no-gutters "
        : "row ";
    let dynamicPadding =
      this.props.connect.getPresentationMode() != model.PresentationMode.mobile
        ? { paddingTop: "15px" }
        : { paddingTop: "10px" };

    return (
      <Dialog
        title="asdf"
        titleStyle={{ display: "none" }}
        overlayStyle={actions.getOverlayStyle()}
        contentStyle={defaults.content}
        contentClassName={"content-class"}
        actions={actionsBtn}
        autoDetectWindowHeight={false}
        onRequestClose={e => {
          me.onClose(e);
        }}
        style={{       "margin-top": "-40px "}}
        open={true}
      >
        <div className="container">
          <div className={rowClass}>
            <div className="col-12 joyride-holder">
              <div style={{ paddingBottom: 10 }}>
                <RichTodoInput
                  connect={this.props.connect}
                  todo={this.state.todo}
                  onParse={(e: TextParseEvent) => {
                    let todo = { ...this.state.todo };
                    let update = false;
                    for (var i in e.addTags) {
                      let eTag = e.addTags[i];
                      if (!todo.tags || todo.tags.indexOf(eTag) == -1) {
                        if (!todo.tags) {
                          todo.tags = [];
                        }
                        todo.tags.push(eTag);
                        update = true;
                      }
                    }
                    if (
                      me.state.lastParseEvent &&
                      me.state.lastParseEvent.addTags.length > e.addTags.length
                    ) {
                      let oldTags = me.state.lastParseEvent.addTags;
                      for (var i in oldTags) {
                        if (e.addTags.indexOf(oldTags[i]) == -1) {
                          todo.tags = todo.tags.filter(x => x != oldTags[i]);
                        }
                      }
                    }

                    if (e.schedule) {
                      todo.due_date = e.schedule;
                      update = true;
                    }
                    todo.description = e.description;
                    let s = { ...me.state };
                    s.todo = todo;
                    s.lastParseEvent = e;
                    me.setState(s);
                  }}
                  onTab={() => {
                    // me.refs.starbutton["focus"]();

                    // @ts-ignore
                    me.refs.starbutton.refs.enhancedSwitch.refs.checkbox.focus();
                  }}
                  onEnter={() => {
                    me.saveTodo(null);
                  }}
                  onEsc={() => {
                    me.props.connect.dispatch<any>(actions.closeTodoModal());
                  }}
                />
              </div>
            </div>
          </div>{" "}
          <div className={rowClass}>
            <div className="col-12 hidden-md-up">
              <TodoSummary
                onDateClick={this.scheduleClick.bind(this)}
                onTagClick={this.tagClick.bind(this)}
                onReoccuringClick={this.reoccuringClick.bind(this)}
                showAllTags={true}
                todos={[this.state.todo]}
              />
            </div>
            {this.props.connect.getPresentationMode() !=
              model.PresentationMode.mobile && (
                <div className="col-md-6 hidden-sm-down">
                  <div onClick={this.scheduleClick.bind(this)} className="">
                    <material.TextField
                      onFocus={this.scheduleClick.bind(this)}
                      className="joyride-edit-schedule"
                      floatingLabelText="# Schedule"
                      style={{ width: "100%" }}
                      value={
                        this.state.todo.due_date
                          ? moment(this.state.todo.due_date).calendar()
                          : ""
                      }
                    />
                    {this.state.todo.due_date && (
                      <material.IconButton
                        style={{
                          width: "20px",
                          position: "absolute",

                          right: "40px",
                          bottom: "7px"
                        }}
                        onClick={this.clearDate.bind(this)}
                      >
                        <HighlightOff color="gray" />
                      </material.IconButton>
                    )}
                  </div>
                </div>
              )}
            <div className="col-md-6 col-12">
              <div className={rowClass + ""} style={dynamicPadding}>
                <TodoAction
                  tooltip="Schedule"
                  className="col joyride-edit-schedule hidden-md-up"
                  onClick={this.scheduleClick.bind(this)}
                  disabled={false}
                  activated={this.state.todo.due_date != null}
                  iconOn={(<TodayIcon color="rgb(49, 27, 146)" />)}
                  iconOff={(<TodayIcon color="gray" />)}
                />
                <TodoAction
                  tooltip="Alarm"
                  className="col joyride-edit-alarm"
                  onClick={this.alarmToggle.bind(this)}
                  disabled={false}
                  activated={this.state.todo.alarm}
                  iconOn={<AlarmOn color="rgb(49, 27, 146)" />}
                  iconOff={<AlarmOff color="gray" />}
                />
                <TodoAction
                  tooltip="Reoccuring"
                  className="col joyride-edit-repeat"
                  onClick={this.reoccuringClick.bind(this)}
                  disabled={false}
                  activated={this.state.todo.recoccuring_settings != null}
                  iconOn={<RepeatIcon color="rgb(49, 27, 146)" />}
                  iconOff={<RepeatIcon color="gray" />}
                />
                {this.props.connect.getPresentationMode() ==
                  model.PresentationMode.mobile && (
                    <TodoAction
                      tooltip="HashTags"
                      className="col joyride-edit-tags hidden-md-up"
                      onClick={this.tagClick.bind(this)}
                      disabled={false}
                      activated={
                        this.state.todo.tags && this.state.todo.tags.length > 0
                      }
                      iconOn={<Hash color="rgb(49, 27, 146)" />}
                      iconOff={<Hash color="gray" />}
                    />
                  )}
                <TodoAction
                  tooltip="Hide from Inbox"
                  className="col joyride-edit-hideInbox"
                  onClick={this.onHideFromInboxToggle.bind(this)}
                  disabled={false}
                  activated={hideFromInbox}
                  iconOn={<UnWatch color="rgb(49, 27, 146)" />}
                  iconOff={<UnWatch color="gray" />}
                />
                <TodoAction
                  tooltip="Star"
                  className="col joyride-edit-star"
                  onClick={this.priorityToggle.bind(this)}
                  disabled={false}
                  activated={
                    this.state.todo.priority == pApi.ToDoItemPriority.High
                  }
                  iconOn={<StarOn color="orangered" />}
                  iconOff={<StarOff color="gray" />}
                />
              </div>
            </div>
          </div>
          {!this.state.tagPopup && (
            <div className="row no-gutters">
              <div className="col-md-12 hidden-sm-down">
                <TagSelector
                  connect={this.props.connect}
                  tags={this.state.todo.tags}
                  autoFocus={false}
                  hideLabel={false}
                  onCancel={this.tagCancel.bind(this)}
                  onUpdate={this.tagUpdate.bind(this)}
                />
              </div>
            </div>
          )}
          {(noteLink || todoLink) &&
            this.props.connect.getPresentationMode() ==
            model.PresentationMode.mobile && (
              <div className={rowClass + " align-items-center"}>
                {todoLink && (
                  <div className="col" style={{ textAlign: "center" }}>
                    <material.FlatButton
                      onClick={e => {
                        //var win = window.open(todoLink, "_system");
                        window["openLink"](todoLink);//
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                      icon={<LinkIcon />}
                      labelPosition="before"
                      style={{ width: "90%" }}
                    >
                      Open Link{" "}
                    </material.FlatButton>
                  </div>
                )}

                {noteLink && (
                  <div className="col" style={{ textAlign: "center" }}>
                    <material.FlatButton
                      onClick={e => {
                        window.location.hash = noteLink;
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                      style={{ width: "90%" }}
                      icon={<NotesIcon />}
                      labelPosition="before"
                    >
                      Open Note
                    </material.FlatButton>
                  </div>
                )}
              </div>
            )}
        </div>
        {this.state.snoozePopup && (
          <SnoozeTyper
            connect={this.props.connect}
            defaultDate={this.state.todo.due_date}
            snoozed={this.setSnoozeDate.bind(this)}
            onClose={() => {
              let state = { ...me.state };
              state.snoozePopup = false;
              me.setState(state);
            }}
          />
        )}
        <RecoccuringPicker
          connect={this.props.connect}
          open={this.state.recoccuringShow}
          settings={this.state.todo.recoccuring_settings}
          onChange={this.recoccuringChange.bind(this)}
          onClose={this.reoccuringClose.bind(this)}
        />
        {this.state.tagPopup && (
          <TagSelectorDialog
            connect={this.props.connect}
            tags={this.state.todo.tags}
            onCancel={this.tagCancel.bind(this)}
            onUpdate={this.tagUpdate.bind(this)}
          />
        )}
      </Dialog>
    );
  }
}
