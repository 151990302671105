import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Store, createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';

//import App from './main/components/App';
import MainRouter from './app/route/RouterComp';
import rootReducer from './app/route/reducer';
import './styles/bootstrap.scss';
// custom
import './styles/layout.scss';
import './styles/theme.scss';
import './styles/ui.scss';
import './styles/loader.scss';
import './styles/app.scss';
import * as actions from './app/lib/ActionReducer'
import newTheme from './themes/darkTheme';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';



window['tz_Reload'] = ()=>{
  window.location.reload();
}
switch (process.env.REACT_APP_TARGET) {
  case ('ios'):

    window["ios"] = true;

    var script = document.createElement('script');
    script.onload = function () {
      console.log("cordova loaded");

    };
    script.src = 'setupCordova.js';
    document.head.appendChild(script);
    window['openLink'] = (url:string)=>{
      var win = window.open(url, "_system");
    }
    break;
  case ('electron'):

    
      window["electron"] = true;
      if(localStorage.getItem("debugElectron")=="true")
      {
      
        window['api'].send('tz_openDebugger', '')
      }
      window['openLink'] = (url:string)=>{
        var win = window.open(url, "_blank");
      }
      window['tz_Reload'] = ()=>{
          window['api'].send('tz_Reload', '')
      }
      window['tz_turnOnTop'] = ()=>{
        window['api'].send('tz_turnOnTop', '')
      }
      window['tz_turnOffTop'] = ()=>{
        window['api'].send('tz_turnOffTop', '')
      }

      /*
      window['openLink'] = (url:string)=>{
        let r  = (path:any)=>{
          return require(path);
        }
        //@ts-ignore
        r("shell").openExternal(url)
      }*/
      
      break;
  default:
    window['openLink'] = (url:string)=>{
      var win = window.open(url, "_blank");
    }
}

//injectTapEventPlugin(); 
var $ = require('jquery');
window['jQuery'] = $;
window['$'] = $;
window['startedApp'] = true;
// = styles =
// 3rd



//require('dojo48.png')
/*
require ('../../public/dojo.svg')
require(  '../../public/dojo48.svg');
require ( '../../public/dojo72.svg');
require ( '../../public/dojo96.svg');
require ( '../../public/dojo120.svg');
require ( '../../public/dojo152.svg');
require ( '../../public/dojo192.svg');
require ( '../../public/favicon.ico');
require ( '../../public/manifest.ico');
require ( '../../public/worker.js' );*/
/*
import lightTheme from './themes/darkTheme';
import darkTheme from './themes/darkTheme';
import grayTheme from './themes/darkTheme';
*/

// Needed for onClick
// http://stackoverflow.com/a/34015469/988941

const initialState = actions.initialState;//{};

/*
if ('serviceWorker' in navigator) {
  window.addEventListener('load', function() {
    navigator.serviceWorker.register('/worker.js').then(function(registration) {
      // Registration was successful
      console.log('ServiceWorker registration successful with scope: ', registration.scope);
    }, function(err) {
      // registration failed :(
      console.log('ServiceWorker registration failed: ', err);
    });
  });
}
*/
const isPromise = action => action && typeof action.then == 'function'

const promiseMiddleware = ({ dispatch }) => next => action => {
  const success = result => {
    dispatch({ ...action, payload: result })
    return result
  }
  const failure = error => {
    dispatch({ ...action, payload: error, error: true })
    return Promise.reject(error)
  }
  if (!action) {
    return null;
  }
  const promisy = action && action.payload && isPromise(action.payload)
  return promisy ? action.payload.then(success, failure) : next(action)
}

const thunkMiddleware = ({ dispatch }) => next => action => {

  if (action && typeof action.payload == 'function') {
    const payload = action.payload(dispatch)
    if (payload) {
      return next({
        ...action,
        payload
      })
    }
    throw new Error('thunk returned nothing')
  }

  return next(action)
}

const middleware = applyMiddleware(
  thunkMiddleware,
  promiseMiddleware
  // ReduxThunk
)
  ;
const store: Store<any> = createStore(rootReducer, initialState, middleware);

//import MyAwesomeReactComponent from './MyAwesomeReactComponent';

const App = () => (
  <MuiThemeProvider muiTheme={getMuiTheme(newTheme)}  >
    <Provider store={store}>
      <MainRouter />
    </Provider>
  </MuiThemeProvider>
);

ReactDOM.render(
  <App />,
  document.getElementById('app-container')
);
/*
ReactDOM.render(
  <Provider store={store}>
    <MainRouter />
  </Provider>,
  document.getElementById('app')
);*/