
import restHelper from "../../shared/papi/providers/restHelper";
import * as pApi from "../../shared/papi/papi-core";
import * as model from "./model";

var rest = new restHelper();
export function populate<T>(type: { new (): T }, values: any): T {
  var obj = new type();
  for (var i in values) {
    obj[i] = values[i];
  }

  return obj;
}
export function populateArray<T>(type: { new (): T }, values: any): Array<T> {
  var retVal = new Array<T>();
  for (var i in values) {
    retVal.push(populate<T>(type, values[i]));
  }

  return retVal;
}
export class RestToDoStore /*(implements pApi.ITodoListStore*/ {
  //ctx: FirstPhaseContext;
  private ctx: pApi.ICtx;
  constructor(ctx: pApi.ICtx) {
    this.ctx = ctx;
  }
  getType(): string {
    return "RestTodoStore";
  }
  registerListner(callback: (notes: Array<pApi.ToDoItem>) => void): void {
    //doesn't do anything for this one
  }
  load(id: any): Promise<pApi.ToDoItem> {
    var todo = this.findInState(id);
    if (todo) {
      this.updateUI(todo, model.LoadingState.Loading);
    }
    return new Promise<pApi.ToDoItem>(async (resolve, reject) => {
      try {
        var res = await rest.get(`api/todo/${id}`);
        var retval = populate<pApi.ToDoItem>(pApi.ToDoItem, res);
        resolve(retval);
      } catch (e) {
        reject(e);
      }
    });
  }
  /*(private updateSnooze(
    lastSnooze : model.LastSnooze
  ) {
    
    this.ctx["dispatch"](actions.ar_UpdateLastSnoozed(lastSnooze));
  }*/
  private updateUI(
    todo: pApi.ToDoItem,
    loadingState?: model.LoadingState,
    errorMessage?: string
  ) {
    var td = todo as pApi.ToDoItem;
    if (loadingState) {
      /*  var ref = td.state ? td.state.ref : null;
      td.state = new model.EntityState(loadingState, todo, errorMessage);
      if (ref) td.state.ref = ref;*/
    }
    // this.ctx["dispatch"](actions.ar_UpdateTodo(td));
  }
  private dispatch(func): any {
    return this.ctx["dispatch"](func);
  }
  save(item: pApi.ToDoItem): Promise<any> {
    var castItem = item as pApi.ToDoItem;
    return new Promise<any>(async (resolve, reject) => {
      try {
        if (!castItem.id || castItem.id == -1) {
          //castItem.state.ref = Math.floor(Math.random() * 99999999 + 1);
          //this.dispatch(actions.ar_AddTodo(castItem));
          let retval = populate<pApi.ToDoItem>(
            pApi.ToDoItem,
            await rest.post(`api/todo`, item)
          );
          //retval.state = castItem.state;
          this.updateUI(retval, model.LoadingState.Completed);
          resolve(retval);
        } else {
          this.updateUI(item, model.LoadingState.Loading);
          let retval = populate<pApi.ToDoItem>(
            pApi.ToDoItem,
            await rest.put(`api/todo/${castItem.id}`, castItem)
          );
          //retval.state = castItem.state;
          this.updateUI(retval, model.LoadingState.Completed);
          resolve(retval);
        }
      } catch (ex) {
        reject(ex);
      }
    });
  }

  getSnoozedItems(): Promise<Array<pApi.ToDoItem>> {
    return new Promise<Array<pApi.ToDoItem>>(async (resolve, reject) => {
      try {
        resolve(
          populateArray<pApi.ToDoItem>(
            pApi.ToDoItem,
            await rest.get(`api/todo/?sq=snoozed`)
          )
        );
      } catch (ex) {
        reject(ex);
      }
    });
  }
  getAllOutstandingItems(): Promise<Array<pApi.ToDoItem>> {
    return new Promise<Array<pApi.ToDoItem>>(async (resolve, reject) => {
      try {
        resolve(
          populateArray<pApi.ToDoItem>(
            pApi.ToDoItem,
            await rest.get(`api/todo/?sq=inbox`)
          )
        );
      } catch (ex) {
        reject(ex);
      }
    });
  }
  listByTag(tag: string): Promise<Array<pApi.ToDoItem>> {
    return new Promise<Array<pApi.ToDoItem>>(async (resolve, reject) => {
      try {
        resolve(
          populateArray<pApi.ToDoItem>(
            pApi.ToDoItem,
            await rest.get(`api/todo?sq=tag&q=${tag}`)
          )
        );
      } catch (ex) {
        reject(ex);
      }
    });
  }
  listByTagAndNotes(
    tag: string,
    noteIds: Array<any>
  ): Promise<Array<pApi.ToDoItem>> {
    return new Promise<Array<pApi.ToDoItem>>(async (resolve, reject) => {
      try {
        resolve(
          populateArray<pApi.ToDoItem>(
            pApi.ToDoItem,
            await rest.get(
              `api/todo?sq=tagAndNotes&q=${tag}&noteIds=${noteIds.join(",")}`
            )
          )
        );
      } catch (ex) {
        reject(ex);
      }
    });
  }

  getByNote(note_id: any): Promise<Array<pApi.ToDoItem>> {
    return new Promise<Array<pApi.ToDoItem>>(async (resolve, reject) => {
      try {
        resolve(
          populateArray<pApi.ToDoItem>(
            pApi.ToDoItem,
            await rest.get(`api/todo?sq=note&q=${note_id}`)
          )
        );
      } catch (ex) {
        reject(ex);
      }
    });
  }
  search(text: string): Promise<Array<pApi.ToDoItem>> {
    return new Promise<Array<pApi.ToDoItem>>(async (resolve, reject) => {
      try {
        resolve(
          populateArray<pApi.ToDoItem>(
            pApi.ToDoItem,
            await rest.get(`api/todo?q=${text}`)
          )
        );
      } catch (ex) {
        reject(ex);
      }
    });
  }
  getByProjectType(
    projectType: pApi.ProjectType
  ): Promise<Array<pApi.ToDoItem>> {
    return new Promise<Array<pApi.ToDoItem>>(async (resolve, reject) => {
      try {
        return reject("NOT IMPLEMENTED");
        /*
        resolve(
          populateArray<pApi.ToDoItem>(
            pApi.ToDoItem,
            await rest.get(`api/project/${project_id}/todos`)
          )
        );*/
      } catch (ex) {
        reject(ex);
      }
    });
  }
  private findInState(id: any): pApi.ToDoItem {
    /* var todo = (this.ctx as RestContext).state.todos.find(x => x.id == id);
    if (todo) return todo as pApi.ToDoItem;*/
    return null;
  }
  complete(id: any): Promise<void> {
    var todo = this.findInState(id);
    if (todo) {
      todo.checked = true;
      this.updateUI(todo, model.LoadingState.Loading);
    }
    return new Promise<void>(async (resolve, reject) => {
      try {
        await rest.get(`api/todo/${id}/complete`);

        if (todo) {
          this.updateUI(todo, model.LoadingState.Completed);
        }
        resolve(null);
      } catch (ex) {
        if (todo) {
          todo.checked = false;
          this.updateUI(todo, model.LoadingState.Error, ex);
        }
        reject(ex);
      }
    });
  }
  delete(id: any): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      try {
        resolve(null);
      } catch (ex) {
        reject(ex);
      }
    });
  }
  snooze(id: any, snooze: pApi.SnoozeAction, custom?: Date): Promise<Date> {
    var todo = this.findInState(id);
    if (todo) this.updateUI(todo, model.LoadingState.Loading);
    return new Promise<Date>(async (resolve, reject) => {
      try {
        let snoozedItem = await rest.get(
          `api/todo/${todo.id}/snooze/${
            snooze == pApi.SnoozeAction.custom
              ? custom
              : pApi.SnoozeAction[snooze]
          }`
        );
        let retval = populate<pApi.ToDoItem>(pApi.ToDoItem, snoozedItem);

        this.updateUI(retval, model.LoadingState.Completed);
        /* var lSnooze = new model.LastSnooze();
        lSnooze.action = snooze;
        lSnooze.date = custom;
        this.updateSnooze(lSnooze)*/
        resolve(retval.due_date);
      } catch (ex) {
        if (todo) this.updateUI(todo, model.LoadingState.Error, ex);
        reject(ex);
      }
    });
  }
}

export class RestObjectiveStore {
  getType(): string {
    return "RestProjectStore";
  }
  private ctx: pApi.ICtx;
  constructor(ctx: pApi.ICtx) {
    this.ctx = ctx;
  }
  load(id: any): Promise<pApi.Objective> {
    return new Promise<pApi.Objective>(async (resolve, reject) => {
      try {
        var res = await rest.get(`api/objective/${id}`);
        resolve(populate<pApi.Objective>(pApi.Objective, res));
      } catch (e) {
        reject(e);
      }
    });
  }
  search(text: string): Promise<Array<pApi.Objective>> {
    return new Promise<Array<pApi.Objective>>(async (resolve, reject) => {
      try {
        resolve(
          populateArray<pApi.Objective>(
            pApi.Objective,
            await rest.get(`api/objective?q=${text}`)
          )
        );
      } catch (ex) {
        reject(ex);
      }
    });
  }
  save(item: pApi.Objective): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      try {
        if (item.id) {
          resolve(
            populate<pApi.Objective>(
              pApi.Objective,
              await rest.post(`api/objective`, item)
            )
          );
        } else {
          resolve(
            populate<pApi.Objective>(
              pApi.Objective,
              await rest.patch(`api/objective/${item.id}`, item)
            )
          );
        }
      } catch (ex) {
        reject(ex);
      }
    });
  }
  findObjectiveByProject(id: any): Promise<pApi.Objective> {
    return new Promise<pApi.Objective>((resolve, reject) => {
      reject("not implemented");
    });
  }

  checkIn(id: any, checkin: pApi.ObjectiveCheckIn): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      try {
        var res = await rest.post(`api/objective/${id}/checkin`, checkin);
        resolve(null);
      } catch (e) {
        reject(e);
      }
    });
  }
  getCheckInHistory(
    id: any,
    since: Date
  ): Promise<Array<pApi.ObjectiveCheckIn>> {
    return new Promise<Array<pApi.ObjectiveCheckIn>>(
      async (resolve, reject) => {
        try {
          resolve(
            populateArray(
              pApi.ObjectiveCheckIn,
              await rest.get(`api/objective/${id}/checkin`)
            )
          );
        } catch (e) {
          reject(e);
        }
      }
    );
  }
  list(includeClosed: boolean): Promise<Array<pApi.Objective>> {
    return new Promise<Array<pApi.Objective>>(async (resolve, reject) => {
      try {
        var inc = includeClosed ? "?includeClosed=true" : "";
        resolve(
          populateArray(pApi.Objective, await rest.get(`api/objective${inc}`))
        );
      } catch (e) {
        reject(e);
      }
    });
  }
}
export class RestProfileStore implements pApi.IProfileProvider {
  private ctx: pApi.ICtx;
  constructor(ctx: pApi.ICtx) {
    this.ctx = ctx;
  }
  authenticate(email: string, password: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      reject("not implemented");
    });
  }
  setToken(token: string): Promise<pApi.Profile> {
    return new Promise<pApi.Profile>((resolve, reject) => {});
  }
  authenticateWithGoogle(googleToken: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      reject("not implemented");
    });
  }
  private _cache: pApi.Profile;
  getProfile(): pApi.Profile {
    if(!window["user"])
      return null;
    if (!this._cache) {
      var p = new pApi.Profile();
      p.account_id = window["user"].meta.uid;
      p.display_name = window["user"].displayName;
      p.email = window["user"].email;
      p.uuid = window["user"].meta.uid;
      p.id = window["user"].meta.uid;
      p.profile_URL = window["user"].photoURL;
      this._cache = p;
    }
    return this._cache;
  }
}
export class RestContext {
  Todos: pApi.ITodoListStore;

  Objectives: pApi.IObjectiveStore;
  ProfileProvider: pApi.IProfileProvider;
  Notes: pApi.INoteStore;
  Cache: pApi.ICacheProvider;
  // KeyResults:IKeyResultStore;
  dispatch: any;

  state: model.IState;
  setDispatchAndState(dispatch: any, state: model.IState) {
    this.dispatch = dispatch;
    this.state = state;
  }
  constructor(Cache: pApi.ICacheProvider) {
    //this.Todos = new RestToDoStore(this);

    // this.Objectives = new RestObjectiveStore(this);
    // this.ProfileProvider = new RestProfileStore(this);
    //this.Notes = new FireClientNoteStore(this);
    this.Cache = Cache;
  }
}

export class TodoUIStore {
  registerListner(callback: (notes: Array<pApi.ToDoItem>) => void): void {
    //doesn't do anything for this one
  }
  private ctx: pApi.ICtx;
  private proxy: pApi.ITodoListStore;
  constructor(ctx: pApi.ICtx, proxy: pApi.ITodoListStore) {
    this.ctx = ctx;
    this.proxy = proxy;
  }
  getType(): string {
    return "TodoUIStore";
  }
  load(id: any): Promise<pApi.ToDoItem> {
    /* var todo = this.findInState(id);
    if (todo) {
      this.updateUI(todo, model.LoadingState.Loading);
    }*/
    return new Promise<pApi.ToDoItem>(async (resolve, reject) => {
      try {
        var todo = await this.proxy.load(id);
        this.updateUI(todo);
        resolve(todo);
      } catch (e) {
        reject(e);
      }
    });
  }

  private updateUI(
    todo: pApi.ToDoItem,
    loadingState?: model.LoadingState,
    errorMessage?: string
  ) {
    /*var td = todo as pApi.ToDoItem;
    if (loadingState) {
      var ref = td.state ? td.state.ref : null;
      td.state = new model.EntityState(loadingState, todo, errorMessage);
      if (ref) td.state.ref = ref;
    }*/
    //  this.ctx["dispatch"](actions.ar_UpdateTodo(todo));
  }
  private dispatch(func): any {
    return this.ctx["dispatch"](func);
  }
  save(item: pApi.ToDoItem): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      try {
        if (!item.id || item.id == -1) {
          var savedItem = await this.proxy.save(item);
     //     this.dispatch(actions.ar_AddTodo(savedItem as pApi.ToDoItem));
          resolve(savedItem);
        } else {
          var savedItem = await this.proxy.save(item);
          ;
          this.updateUI(savedItem, model.LoadingState.Completed);
          resolve(savedItem);
        }
      } catch (ex) {
        this.updateUI(item, model.LoadingState.Error, ex);
        reject(ex);
      }
    });
  }

  getSnoozedItems(): Promise<Array<pApi.ToDoItem>> {
    return this.proxy.getSnoozedItems();
  }
  getAllOutstandingItems(): Promise<Array<pApi.ToDoItem>> {
    return this.proxy.getAllOutstandingItems();
  }
  listByTag(tag: string): Promise<Array<pApi.ToDoItem>> {
    return this.proxy.listByTag(tag);
  }
  /*
  listByTagAndNotes(
    tag: string,
    noteIds: Array<any>
  ): Promise<Array<pApi.ToDoItem>> {
    return this.proxy.listByTagAndNotes(tag,noteIds);
  }*/

  getByNote(note_id: any): Promise<Array<pApi.ToDoItem>> {
    return this.proxy.getByNote(note_id);
  }
  search(text: string): Promise<Array<pApi.ToDoItem>> {
    return this.proxy.search(text);
  }
  getByProjectType(
    projectType: pApi.ProjectType
  ): Promise<Array<pApi.ToDoItem>> {
    return this.proxy.getByProjectType(projectType);
  }

  complete(id: any): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      try {
        var item = await this.proxy.load(id);
        await this.proxy.complete(item.id);
        item.checked = true;
        this.updateUI(item);

        resolve(null);
      } catch (ex) {
        if (item) {
          item.checked = false;
          this.updateUI(item, model.LoadingState.Error, ex);
        }
        reject(ex);
      }
    });
  }
  delete(id: any): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      try {
        resolve(null);
      } catch (ex) {
        reject(ex);
      }
    });
  }
  snooze(id: any, snooze: pApi.SnoozeAction, custom?: Date): Promise<Date> {
    return new Promise<Date>(async (resolve, reject) => {
      try {
        var date = await this.proxy.snooze(id, snooze, custom);
        var todo = await this.proxy.load(id);
        todo.due_date = date;
        this.updateUI(todo, model.LoadingState.Completed);

        resolve(date);
      } catch (ex) {
        reject(ex);
      }
    });
  }
}
