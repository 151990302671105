/*Object.defineProperty(exports, "__esModule", {
  value: true
});*/

var _extends =
  Object.assign ||
  function(target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];
      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }
    return target;
  };

var _createClass = (function() {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }
  return function(Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
})();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _AutoComplete = require("material-ui/AutoComplete");

var _AutoComplete2 = _interopRequireDefault(_AutoComplete);

var _IconButton = require("material-ui/IconButton");

var _IconButton2 = _interopRequireDefault(_IconButton);

var _Paper = require("material-ui/Paper");

var _Paper2 = _interopRequireDefault(_Paper);

var _search = require("material-ui/svg-icons/action/search");

var _search2 = _interopRequireDefault(_search);

var _close = require("material-ui/svg-icons/navigation/close");

var _close2 = _interopRequireDefault(_close);

var _colors = require("material-ui/styles/colors");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj };
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError(
      "this hasn't been initialised - super() hasn't been called"
    );
  }
  return call && (typeof call === "object" || typeof call === "function")
    ? call
    : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError(
      "Super expression must either be null or a function, not " +
        typeof superClass
    );
  }
  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass)
    Object.setPrototypeOf
      ? Object.setPrototypeOf(subClass, superClass)
      : (subClass.__proto__ = superClass);
}

var getStyles = function getStyles(props, state) {
  var disabled = props.disabled;
  var value = state.value;

  var nonEmpty = value.length > 0;

  return {
    root: {
      height: 40,
      display: "flex",

      justifyContent: "space-between",
      "background-color": "rgba(255, 255, 255,0.70)"
    },
    rootFocus: {
      height: 40,
      display: "flex",

      justifyContent: "space-between",
      "background-color": "rgba(255, 255, 255,0.99)"
    },
    iconButtonClose: {
      style: {
        opacity: !disabled ? 0.54 : 0.38,
        transform: nonEmpty ? "scale(1, 1)" : "scale(0, 0)",
        transition: "transform 200ms cubic-bezier(0.4, 0.0, 0.2, 1)"
      },
      iconStyle: {
        opacity: nonEmpty ? 1 : 0,
        transition: "opacity 200ms cubic-bezier(0.4, 0.0, 0.2, 1)",
        height: "20px"
      }
    },
    iconButtonSearch: {
      style: {
        opacity: !disabled ? 0.54 : 0.38,

        transform: nonEmpty ? "scale(0, 0)" : "scale(1, 1)",
        transition: "transform 200ms cubic-bezier(0.4, 0.0, 0.2, 1)",
        marginRight: -48
      },
      iconStyle: {
        opacity: nonEmpty ? 0 : 1,
        transition: "opacity 200ms cubic-bezier(0.4, 0.0, 0.2, 1)",
        height: "20px"
      }
    },
    input: {
      width: "100%"
    },
    textFieldStyle: {
      height: "45px"
    },
    searchContainer: {
      margin: "auto 16px",
      width: "100%"
    }
  };
};

/**
 * Material design search bar
 * @see [Search patterns](https://material.io/guidelines/patterns/search.html)
 */

var SearchBar = (function(_Component) {
  _inherits(SearchBar, _Component);

  function SearchBar(props) {
    _classCallCheck(this, SearchBar);

    // var _this = _possibleConstructorReturn(this, (SearchBar.__proto__ || Object.getPrototypeOf(SearchBar)).call(this, props));
    var _this = _possibleConstructorReturn(
      this,
      Object.getPrototypeOf(SearchBar).call(this, props)
    );

    _this.state = {
      focus: false,
      value: _this.props.value,
      active: false
    };
    return _this;
  }

  _createClass(
    SearchBar,
    [
      {
        key: "componentWillReceiveProps",
        value: function componentWillReceiveProps(nextProps) {
          if (this.props.value !== nextProps.value) {
            this.setState(_extends({}, this.state, { value: nextProps.value }));
          }
        }
      },
      {
        key: "handleFocus",
        value: function handleFocus() {
          this.setState({ focus: true });
          this.props.onFocus();
        }
      },
      {
        key: "handleUnFocus",
        value: function handleUnFocus() {
          this.setState({ focus: true });
          this.props.onUnFocus();
        }
      },
      {
        key: "handleBlur",
        value: function handleBlur() {
          this.setState({ focus: false });
          if (this.state.value.trim().length === 0) {
            this.setState({ value: "" });
          }
          this.props.onBlur();
        }
      },
      {
        key: "handleInput",
        value: function handleInput(e, index) {
          this.setState({ value: e });

          this.props.onChange(e);
        }
      },
      {
        key: "handleCancel",
        value: function handleCancel() {
          this.setState({ active: false, value: "" });
          this.props.onCancel("");
        }
      },
      {
        key: "handleKeyPressed",
        value: function handleKeyPressed(e) {
          if (e.charCode === 13) {
            //this.props.onRequestSearch();
            // this.setState({ active: false, value: '' });
          }
        }
      },
      {
        key: "componentDidMount",
        value: function handleKeyPressed(e) {
          var me = this;
       
          setTimeout(() => {
          
            try {
              me.props.onInputMounted(me.refs.autocomplete.refs.searchTextField.input)
             // me.refs.autocomplete.refs.searchTextField.input.click()
             // me.refs.autocomplete.refs.searchTextField.input.focus()
             
            } catch (e) {
             // alert(e);
            }
          }, 400);
         
         
        }
      },
      {
        key: "render",
        value: function render() {
          var _this2 = this;

          var styles = getStyles(this.props, this.state);
          var value = this.state.value;

          return _react2.default.createElement(
            _Paper2.default,
            {
              style: _extends(
                {},
                this.state.focus ? styles.rootFocus : styles.root,
                this.props.style
              )
            },
            _react2.default.createElement(
              "div",
              { style: styles.searchContainer },
              _react2.default.createElement(_AutoComplete2.default, {
                hintText: this.props.hintText,
                ref: "autocomplete",
                key:'searchField',
                name:'searchField',
                onBlur: function onBlur() {
                  return _this2.handleBlur();
                },
                searchText: value,
                onUpdateInput: function onUpdateInput(e) {
                  return _this2.handleInput(e);
                },
                onKeyPress: function onKeyPress(e) {
                  return _this2.handleKeyPressed(e);
                },
                onFocus: function onFocus() {
                  return _this2.handleFocus();
                },
               
                onNewRequest: function(chosenRequest: string, index: number) {
                  //_this2.handleInput(chosenRequest['name'],index);
                  // _this2.props.onRequestSearch();
                  // _this2.setState({ active: false, value: '' });
                },
                fullWidth: true,
                style: styles.input,
                textFieldStyle: styles.textFieldStyle,
                underlineShow: false,
                dataSource: this.props.dataSource,
                dataSourceConfig: this.props.dataSourceConfig,
                filter: this.props.filter,
                disabled: this.props.disabled
              })
            ),
            _react2.default.createElement(
              _IconButton2.default,
              {
                /* onClick: this.props.onRequestSearch,*/
                iconStyle: styles.iconButtonSearch.iconStyle,
                style: styles.iconButtonSearch.style,
                disabled: this.props.disabled
              },
              this.props.searchIcon
            ),
            _react2.default.createElement(
              _IconButton2.default,
              {
                onClick: function onClick() {
                  return _this2.handleCancel();
                },
                iconStyle: styles.iconButtonClose.iconStyle,
                style: styles.iconButtonClose.style,
                disabled: this.props.disabled
              },
              this.props.closeIcon
            )
          );
        }
      }
    ],
    undefined
  );

  return SearchBar;
})(_react.Component);

SearchBar["defaultProps"] = {
  closeIcon: _react2.default.createElement(_close2.default, {
    color: _colors.grey500
  }),
  dataSource: [],
  dataSourceConfig: { text: "text", value: "value" },
  disabled: false,
  hintText: "Search",
  searchIcon: _react2.default.createElement(_search2.default, {
    color: _colors.grey500
  }),
  style: null,
  value: ""
};

SearchBar["propType"] = {
  /** Override the close icon. */
  closeIcon: _propTypes2.default.node,
  /** Array of strings or nodes used to populate the list. */
  dataSource: _propTypes2.default.array,
  /** Config for objects list dataSource. */
  dataSourceConfig: _propTypes2.default.object,
  /** Disables text field. */
  disabled: _propTypes2.default.bool,
  /** Sets hintText for the embedded text field. */
  hintText: _propTypes2.default.string,
  /** Fired when the text value changes. */
  onChange: _propTypes2.default.func.isRequired,
  onCancel: _propTypes2.default.func.isRequired,
  onFocus: _propTypes2.default.func.isRequired,
  onUnFocus: _propTypes2.default.func.isRequired,
  onBlur: _propTypes2.default.func.isRequired,
  onInputMounted: _propTypes2.default.func.isRequired,
  /** Fired when the search icon is clicked. */
  onRequestSearch: _propTypes2.default.func.isRequired,
  /** Override the search icon. */
  searchIcon: _propTypes2.default.node,
  /** Override the inline-styles of the root element. */
  style: _propTypes2.default.object,
  /** The value of the text field. */
  value: _propTypes2.default.string
};

//exports.default = SearchBar;
export default SearchBar;
