import * as React from "react";
import * as classNames from "classnames";
import * as material from "material-ui";
import ContentAdd from "material-ui/svg-icons/content/add";
import ToDoAdd from "material-ui/svg-icons/action/alarm-add";
import NoteAdd from "material-ui/svg-icons/action/note-add";
import AddPhoto from "material-ui/svg-icons/image/add-a-photo";
import SearchIcon from "material-ui/svg-icons/action/search";
import ObjectiveAdd from "../../components/CustomSvg/Target";
import * as hotkey from "../../lib/hotkeyClient"
import * as model from "../../lib/model"
import * as actions from "../../lib/ActionReducer"
import * as pApi from "../../../shared/papi/papi-core"

import uuidV4 from "uuid/v4"
import { ImageHandler } from "../../components/Editor/ImageHandler"
import { Track } from "../../../shared/track"
import NavigationIcon from "material-ui/svg-icons/navigation/menu";
var uuid = require("uuid");


interface AddObjectiveProps {
  connect: model.ConnectProps;
}
interface AddObjectiveState {
  showingButtons: Boolean;
  timedShow: Boolean;
  delayCancel: Boolean;
  touchHoverMode: Boolean;
}

export class AddButton extends React.Component<
  AddObjectiveProps,
  AddObjectiveState
> {
  constructor(props, context) {
    super(props, context);

    this.state = {
      delayCancel: false,
      timedShow: false,
      showingButtons: false,
      touchHoverMode: false
    };
  }
  componentDidMount() {
    let me = this;
    let instance = hotkey.HotKeyEngine.instance();
    let handlers = new Map<hotkey.HotKeyActionType, () => boolean>();
    handlers.set(
      hotkey.HotKeyActionType.createTodo,
      (): boolean => {
        me.createNewTodo();
        return true;
      }
    );
    handlers.set(
      hotkey.HotKeyActionType.createNote,
      (): boolean => {
        let me = this;
        let f = async function() {
          var tags = me.getTagsFromUrl();
          var ih = new ImageHandler(
            me.props.connect.dispatch,
            me.props.connect.state.context.getId()
          );
          var note = await ih.createNoteFromImage(
            null,
            tags,
            pApi.newId(),
            me.props.connect.state.context
          );
          me.navToNote(note.id);
        };

        f();
        return true;
      }
    );

    instance.registerHandlers("addButton", "inbox", false, handlers);
  }
  componentWillUnmount() {
    let instance = hotkey.HotKeyEngine.instance();
    instance.unregisterHandlers("addButton");
  }

  layoutMouseEnter(e: any) {
    console.log("mouse enter " + this.state.delayCancel);
    this.setState({
      showingButtons: true,
      timedShow: true,
      delayCancel: true,
      touchHoverMode:
        this.props.connect.getPresentationMode() ==
        model.PresentationMode.mobile
          ? true
          : false
    });
  }

  layoutLeave(e: any) {
    var me = this;
    console.log("leave");
    if (this.state.timedShow) {
      setTimeout(function(e) {
        console.log("leave timeout " + me.state.delayCancel);
        if (me.state.timedShow && !me.state.delayCancel) {
          me.setState({
            showingButtons: false,
            delayCancel: false,
            timedShow: false,
            touchHoverMode: me.state.touchHoverMode
          });
        } else {
          me.setState({
            showingButtons: me.state.showingButtons,
            delayCancel: false,
            timedShow: me.state.timedShow,
            touchHoverMode: me.state.touchHoverMode
          });
        }
      }, 1000);
    }
    me.setState({
      showingButtons: me.state.showingButtons,
      delayCancel: false,
      timedShow: me.state.timedShow,
      touchHoverMode: me.state.touchHoverMode
    });
  }
  hideMe(e) {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    this.setState({
      showingButtons: false,
      delayCancel: false,
      timedShow: false,
      touchHoverMode: false
    });
    return false;
  }
  onboardingCheck() {
    try {
      if (!this.props.connect.state.dojoSettings.onboarding.addHelp) {
        let onboarding = {
          ...this.props.connect.state.dojoSettings.onboarding
        };
        onboarding.addHelp = true;
        this.props.connect.dispatch<any>(
          actions.ar_updateOnboardingState(onboarding)
        );
      }
    } catch (e) {
      console.error(e);
    }
  }
  onboardingCheckNote() {
    try {
      if (!this.props.connect.state.dojoSettings.onboarding.addNote) {
        let onboarding = {
          ...this.props.connect.state.dojoSettings.onboarding
        };
        onboarding.addNote = true;
        this.props.connect.dispatch<any>(
          actions.ar_updateOnboardingState(onboarding)
        );
      }
    } catch (e) {
      console.error(e);
    }
  }
 /* addObjectiveTap = () => {
    this.onboardingCheck();
    let obj = new pApi.Objective();
    //obj.project_id = this.props.project.id;
    obj.checkin_interval = new pApi.ReoccuringSettings();
    obj.checkin_interval.reoccuring_interval =
      pApi.RecoccuringIntervalEnum.kiWeekly;

    this.props.connect.dispatch<any>(
      actions.showObjectiveModal(obj as pApi.Objective)
    );
  };*/
  addNoteTap = async e => {
    try {
      if (!this.state.showingButtons) {
        return this.newTodoTap(e);
      }
      this.onboardingCheck();
      this.onboardingCheckNote();
      e.preventDefault();
      e.stopPropagation();
      this.hideMe(null);
      var ih = new ImageHandler(
        this.props.connect.dispatch,
        this.props.connect.state.context.getId()
      );

      var tags = this.getTagsFromUrl();

      var note = await ih.createNoteFromImage(
        null,
        tags,
        pApi.newId(),
        this.props.connect.state.context
      );
      this.navToNote(note.id);
    } catch (e) {
      Track.reportError(
        e,
        "Error adding Note",
        false,
        this.props.connect.dispatch
      );
      this.props.connect.dispatch<any>(actions.ar_setProgress(null));
    }
    return false;
  };
  getTagsFromUrl() {
    var tags = [];
    let vs = this.props.connect.state.viewState.noteViewState;
    if (vs) {
      if (vs.functionType == model.NoteFunctionType.tag) {
        tags.push(vs.query);
      }
    }
    return tags;
  }
  addPhotoTap = async e => {
    try {
      if (!this.state.showingButtons) {
        return this.newTodoTap(e);
      }
      this.onboardingCheck();
      e.preventDefault();
      e.stopPropagation();
      this.hideMe(null);
      var me = this;
      var ih = new ImageHandler(
        this.props.connect.dispatch,
        this.props.connect.state.context.getId()
      );
      let newId = pApi.newId();
      var n = await ih.collectImage(
        this.props.connect.state.context.getId() + "/quickAdd/" + uuidV4(),newId
      );
      var tags = this.getTagsFromUrl();

      var note = await ih.createNoteFromImage(
        n,
        tags,newId,
        this.props.connect.state.context
      );
      this.navToNote(note.id);
    } catch (e) {
      Track.reportError(
        e,
        "Error adding photo",
        false,
        this.props.connect.dispatch
      );
      this.props.connect.dispatch<any>(actions.ar_setProgress(null));
    }
    return false;
  };
  navToNote(noteId: any) {
    var newNS = this.props.connect.state.viewState.deepCopy();
    if (!newNS.noteViewState) {
      newNS.noteViewState = new model.NoteViewState();
    }
    newNS.noteViewState.noteId = noteId;
    newNS.appSection = model.AppSections.notes;
    window.location.hash = newNS.getHash();
  }
  newTodoTap = async e => {
    this.onboardingCheck();
    if (
      this.props.connect.getPresentationMode() ==
        model.PresentationMode.mobile &&
      !this.state.showingButtons
    ) {
      e.preventDefault();
      e.stopPropagation();
      this.setState({
        showingButtons: true,
        delayCancel: false,
        timedShow: false,
        touchHoverMode: true
      }); 
      return false;
    }
    else{
      this.createNewTodo();
    }
    this.hideMe(null);
    /*
    if (
      this.props.connect.state.viewState.appSection ==
      model.AppSections.objectives
    ) {
      let obj = new pApi.Objective();
      //obj.project_id = this.props.project.id;
      obj.checkin_interval = new pApi.ReoccuringSettings();
      obj.checkin_interval.reoccuring_interval =
        pApi.RecoccuringIntervalEnum.kiWeekly;
      this.props.connect.dispatch<any>(actions.showObjectiveModal(obj));
    } else {
      this.createNewTodo();
    }*/
  };
  createNewTodo() {
    var todoa = new pApi.ToDoItem();
    {
      todoa.id = -1;
      todoa.checked = false;
      todoa.description = "";
      todoa.priority = 0;
      todoa.project_type = this.props.connect.state.viewState.inAggregateToDoView()
        ? pApi.ProjectType.ptInbox
        : pApi.ProjectType.ptDojo;
      todoa.note_id = null;
      todoa.due_date = null;
      todoa.objective_id = null;
      todoa.recoccuring = null;
      todoa.tags = this.props.connect.state.viewState.getTag()
        ? [this.props.connect.state.viewState.getTag()]
        : null;
    }

    // this.setState({ todo: todoa });
    this.props.connect.dispatch<any>(actions.showTodoModal(todoa));
  }
  render() {
    /*console.log(
      "button render " + this.state.hoverLayout + " " + this.props.project
    );*/

    /*if (!this.props.project) {
            return (<div></div>);
        }*/
    var AddIcon = <ContentAdd color="#311b92" />;
    var showingHoverMenu = this.state.showingButtons; // || this.state.timedShow;
    switch (this.props.connect.state.viewState.appSection) {
      case model.AppSections.objectives:
        AddIcon = <ObjectiveAdd />;
        break;
      default:
        if (showingHoverMenu) {
          AddIcon = <ToDoAdd />;
        }
    }
    if (
      (this.props.connect.getPresentationMode() ==
        model.PresentationMode.mobile &&
        this.props.connect.state.viewState.noteOpen()) ||
      (this.props.connect.state.editTodo.val() && this.props.connect.state.editTodo.val().id)
    ) {
      return <div />;
    }
    if (
      this.props.connect.state.viewState.appSection ==
        model.AppSections.settings ||
      this.props.connect.state.viewState.appSection == model.AppSections.help
    ) {
      return <div />;
    }
    let objectCss = showingHoverMenu
      ? "fab-layout-objective fav-layout-objective-transition"
      : "fab-layout-objective";
    function getCss(n: string): string {
      if (showingHoverMenu)
        return "fab-layout-extra-button" + " " + n + "-transition";
      else return "fab-layout-extra-button";
    }
    function getTexCss(n: string): string {
      if (showingHoverMenu) return n + " " + n + "-transition";
      else return n;
    }
    var hoverStyle = {};
    var buttonStyle = {
      borderRadius: "2px",
      boxShadow: "0 1px 2px rgba(0,0,0,.15)",
      color: "#ececec",
      display: "inline-block",
      lineHeight: "1em",
      maxWidth: "200px",
      padding: "10px",
      "margin-right": "46px",
      textOverflow: "ellipsis",
      verticallign: "middle",
      background: "rgba(0,0,0,0.8)"
    };
    var bigButtonStyle = {
      display: showingHoverMenu ? "block" : "none"
    };
    let onboardingStyle = {
      display: "none"
    };
    let AddHelp = false;
    let AddNote = false;
    if (
      this.props.connect.state.dojoSettings &&
      this.props.connect.state.dojoSettings.onboarding
    ) {
      onboardingStyle.display =
        (!this.props.connect.state.dojoSettings.onboarding.addHelp ||
          !this.props.connect.state.dojoSettings.onboarding.addNote) &&
        !showingHoverMenu
          ? "block"
          : "none";
      AddHelp =
        !this.props.connect.state.dojoSettings.onboarding.addHelp &&
        this.props.connect.state.dojoSettings.onboarding.welcomePopup;

      AddNote =
        !AddHelp &&
        !this.props.connect.state.dojoSettings.onboarding.addNote &&
        this.props.connect.state.editTodo == null &&
        this.props.connect.state.dojoSettings.onboarding.welcomePopup;
    }

    let raiseForSnap =
      this.props.connect.state.snackMessage != null &&
      !showingHoverMenu &&
      this.props.connect.getPresentationMode() == model.PresentationMode.mobile;
    if (
      this.props.connect.state.dojoSettings &&
      this.props.connect.state.dojoSettings.onboarding &&
      !this.props.connect.state.dojoSettings.onboarding.welcomePopup
    ) {
      return <div />;
    }
    if (
     5 == 5*7 && this.props.connect.getPresentationMode() == model.PresentationMode.mobile
    ) {
      const styles = {
        smallIcon: {
          width: 24,
          height: 24,
          color:'white'
        },
        mediumIcon: {
           width:'auto',
           height:'auto',
           color:'white'
        },
        largeIcon: {
          width: 60,
          height: 60
        },
        small: {
          width: 30,
          height: 30,
          padding: 0
        },
        medium: {
          padding: '5px',
          borderRadius: '50%',
          height: '50px',
          width:'50px',
          background: '#311b92',
          top:'-10px'
        },
        large: {
          width: 120,
          height: 120,
          padding: 30
        }
      };
      return (
        <div className="custom-bottom-bar">
         <div className="container"  style={{'backgroundColor':'#5d5d5d'}}>
          <div className="row no-gutter align-items-center">
            <div className="col" style={{ padding: "0px", textAlign:'center' }}>
              <material.IconButton
                iconStyle={styles.smallIcon}
                style={styles.small}
                onClick={this.addNoteTap.bind(this)}
              >
                <NavigationIcon  />
              </material.IconButton>
              </div>
            <div className="col" style={{ padding: "0px", textAlign:'center' }}>
         
              <material.IconButton
                iconStyle={styles.smallIcon}
                style={styles.small}
                onClick={this.addPhotoTap.bind(this)}
              >
                <AddPhoto />
              </material.IconButton>
            </div>
            <div className="col" style={{ padding: "0px", textAlign:'center' }}>
            <div style={{ backgroundColor:'transparent',   borderRadius: '50%;'}}>
              <material.IconButton
                iconStyle={styles.mediumIcon}
                style={styles.medium}
              
                onClick={this.newTodoTap.bind(this)}
              >
                <ContentAdd  />;
              </material.IconButton>
              </div>
            </div>
            <div className="col" style={{ padding: "0px", textAlign:'center' }}>
              <material.IconButton
                iconStyle={styles.smallIcon}
                style={styles.small}
                onClick={this.addNoteTap.bind(this)}
              >
                <NoteAdd  />
              </material.IconButton>
            </div>
            <div className="col" style={{ padding: "0px", textAlign:'center' }}>
              <material.IconButton
                iconStyle={styles.smallIcon}
                style={styles.small}
                onClick={this.addNoteTap.bind(this)}
              >
                <SearchIcon  />
              </material.IconButton>
            </div>
           
          </div>
        </div>
        <div className='custom-black-bottom'>
        </div>
        </div>
      );
    }
    return (
      <div id="addbuttondiv"
        className={
          showingHoverMenu && this.state.touchHoverMode
            ? "fab-mobile-hover"
            : "joyride-add-button"
        }
        onClick={this.hideMe.bind(this)}
      >
        <div
          onMouseEnter={this.layoutMouseEnter.bind(this)}
          onMouseLeave={this.layoutLeave.bind(this)}
        >
          <div
            style={bigButtonStyle}
            onClick={this.newTodoTap.bind(this)}
            className={getTexCss("fab-add-text")}
          >
            Add To-Do
          </div>
          {AddHelp && (
            <div
              style={onboardingStyle}
              className={getTexCss("fab-add-start-text")}
            >
              {this.props.connect.getPresentationMode() ==
                model.PresentationMode.web && <div>Click Me --&gt;</div>}
              {this.props.connect.getPresentationMode() ==
                model.PresentationMode.mobile && <div>Tap Me --&gt;</div>}
            </div>
          )}
          {AddNote && (
            <div
              style={onboardingStyle}
              className={getTexCss("fab-add-start-text")}
            >
              <div>Add a Note --&gt;</div>
            </div>
          )}
          <material.FloatingActionButton
            className={raiseForSnap ? "fab-snack" : "fab joyride-add-button"}
            onClick={this.newTodoTap.bind(this)}
          >
            {AddIcon}
          </material.FloatingActionButton>

          <div className={getCss("fab-layout-note")}>
            <material.FlatButton
              label="Note"
              onMouseEnter={this.layoutMouseEnter.bind(this)}
              labelPosition="before"
              labelStyle={buttonStyle}
              onClick={this.addNoteTap.bind(this)}
              icon={<NoteAdd color="blue" />}
            />
          </div>
          <div className={getCss("fab-layout-photo")}>
            <material.FlatButton
              onMouseEnter={this.layoutMouseEnter.bind(this)}
              label="Photo"
              labelPosition="before"
              labelStyle={buttonStyle}
              onClick={this.addPhotoTap.bind(this)}
              icon={<AddPhoto color="gray" />}
            />
          </div>
        </div>
      </div>
    );
  }
}
