
import * as pApi from "../../shared/papi/papi-core";

import * as model from "./model";
import * as rest from "./rest";
import * as fireClient from '../../shared/papi/providers/fireClient';
import { FireStoreDateConverter } from "../../shared/dateConverter"
import firebase from 'firebase/app';

export class HybridContext implements fireClient.IFireCtx {

  getDateConverter(): FireStoreDateConverter {

    return new FireStoreDateConverter(
      (t:any)=>{
       
        return t instanceof firebase.firestore.Timestamp
      },
      (d: Date): any => {
        ;
        return firebase.firestore.Timestamp.fromDate(d)
      },
      (sec:number, nano:number):Date => {
        return new firebase.firestore.Timestamp(sec, nano).toDate()
      },)
      
  }
  Todos: pApi.ITodoListStore;

  Objectives: pApi.IObjectiveStore;
  ProfileProvider: pApi.IProfileProvider;
  Notes: pApi.INoteStore;
  Cache: pApi.ICacheProvider;
  Tags: pApi.ITagStore;
  Settings: pApi.ISettingStore;
  Devices: pApi.IDeviceStore;
  ApiKeys: pApi.IApiKeyStore;
  WebHooks: pApi.IWebHookStore;
  // KeyResults:IKeyResultStore;
  dispatch: any;
  private _db: any;
  _syncid: any;
  online: boolean = true;
  inBulkOperation: boolean = false
  bulkupdate(func: Promise<any>): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {

      try {
        this.inBulkOperation = true;
        if(localStorage.getItem("DisableFastUpdate")!="true")
        {
           await firebase.firestore().disableNetwork()
        }
        let retval = await func;

        this.inBulkOperation = false;
        if(localStorage.getItem("DisableFastUpdate")!="true")
        {
          firebase.firestore().enableNetwork()
        }

        resolve(retval);
      }
      catch (e) {
        this.inBulkOperation = false;
        firebase.firestore().enableNetwork();
        reject(e)
      }
    })
  }
  fireStoreUpdate(func: Promise<any>): any {
    return new Promise<any>(async (resolve, reject) => {
      try {

        if (this.online && !this.inBulkOperation) {
          let done = false;
          // let retval = await func;
          func.then((e) => {
            console.log('firestore updated')
            done = true;
            resolve(e);
          }).catch((e) => {
            done = true;
            console.error('firestore offline error' + e)
            reject(e)
          });
       
        }
        else {
          func.then(() => {
            console.log('firestore Updated succesfully')
          }).catch((e) => {
            console.error('firestore offline error' + e)
          });
          resolve(true);//firebase blocks when offline when doing updates.  this breaking the flow of things
        }
      }
      catch (e) {
        reject(e);
      }
    })
  };

  getSyncId(): any {
    if (!this._syncid) {
      this._syncid = Math.floor(Math.random() * 100000000 + 1);
    }
    return this._syncid;
  }
  getDb(): any {
    if (!this._db) {
      this._db = firebase.firestore();
    }
    return this._db;
  }
  getDocumentFieldId():any{
    return firebase.firestore.FieldPath.documentId();  
  }
  getId(): any {
    return this.ProfileProvider.getProfile().uuid;
  }

  state: model.IState;
  setDispatchAndState(dispatch: any, state: model.IState) {
    this.dispatch = dispatch;
    this.state = state;
  }
  getSnapShot(query: any, cb: any): any {
    return query.onSnapshot(
      { includeMetadataChanges: false },
      cb
      , function (error) {
        console.error('snapshot error', error)
      })
  }
  constructor(Cache: pApi.ICacheProvider) {
    this.Cache = Cache;
    this.Todos = new fireClient.FireClientTodoStore(this);
    this.Devices = new fireClient.FireClientDeviceStore(this);
    this.Tags = new fireClient.FireClientTagStore(this);
    this.Objectives = new fireClient.FireObjectiveStore(this);
    this.ProfileProvider = new rest.RestProfileStore(this);
    this.Notes = new fireClient.FireClientNoteStore(this);
    this.Settings = new fireClient.FireSettingStore(this);
    this.ApiKeys = new fireClient.FireApiKeyStore(this);
    this.WebHooks = new fireClient.FireWebHookStore(this);
  }
}