import * as React from "react";
import * as material from "material-ui";
import * as model from "../../lib/model";
import * as pApi from "../../../shared/papi/papi-core";
import * as hotkey from "../../lib/hotkeyClient";
import { merge } from "lodash";
import { ParseDate } from "../../lib/parseData"
let cnode = require("chrono-node")// ../../../../../hacked_modules/chrono-node/chrono");
const Quill = require("quill");
let Delta = Quill.import("delta");
let $ = require('jquery')
//var ImgCache = require("imgcache.js");s
var Clipboard = Quill.import("modules/clipboard");
//var chips = require("./QuillChip");



export class TextParseEvent {
  description: string;
  addTags: Array<string>;
  schedule: Date;
}
interface RichTodoInputProp {
  connect: model.ConnectProps;
  todo: pApi.ToDoItem;
  onParse(e: TextParseEvent);
  onEnter: () => void;
  onEsc: () => void;
  onTab: () => void;
}
interface RichTodoInputState {
  description: string;
  todo: pApi.ToDoItem;
  focused: boolean;
}

class PlainClipboard extends Clipboard {
  static HandleTextPaste(node, delta) {
    return new Delta().insert(node.data);
  }
  convert(html = null) {
    if (typeof html === "string") {
      this.container.innerHTML = html;
    }

    return super.convert(html);
  }
}
let container;
export class RichTodoInput extends React.Component<
  RichTodoInputProp,
  RichTodoInputState
  > {
  quillEditor: any;
  constructor(props: RichTodoInputProp, context) {
    super(props, context);

    this.state = {
      description: props.todo.description,
      focused: false,
      todo: props.todo
    };

    let mentionShortcust = require("../Editor/MarkdownShortcuts")
      .MentionShortcuts;
    Quill.register("modules/mentionShortcust", mentionShortcust);
  }
  getTags(ops: any[]): Array<string> {
    var retval = new Array<string>();
    for (var i in ops) {
      if (ops[i].insert && ops[i].insert.mention) {
        retval.push(ops[i].insert.mention.value);
      }
    }
    var uniqueNames = [];
    $.each(retval, function (i, el) {
      if ($.inArray(el, uniqueNames) === -1) uniqueNames.push(el);
    });
    return uniqueNames;
  }
  updating: boolean = false;
  componentDidMount() {
    var me = this;

    var modules = {
      toolbar: false,
      history: {
        delay: 2000,
        maxStack: 500,
        userOnly: true
      },
      keyboard: {
        bindings: {
          // This will overwrite the default binding also named 'tab'
          tab: {
            key: 9,
            handler: function () {
              me.props.onTab();
            }
          },
          star: {
            key: 'S',
            ctrlKey: true,
            handler: function () {

              hotkey.HotKeyEngine.instance().invokeActionType(hotkey.HotKeyActionType.optionStar)
            }
          },
          hide: {
            key: 'U',
            ctrlKey: true,
            handler: function () {

              hotkey.HotKeyEngine.instance().invokeActionType(hotkey.HotKeyActionType.optionHide)
            }
          },
          snooze: {
            key: 'H',
            ctrlKey: true,
            handler: function () {

              hotkey.HotKeyEngine.instance().invokeActionType(hotkey.HotKeyActionType.optionSnooze)
            }
          },
          complete: {
            key: 'E',
            shortKey: true,
            handler: function () {

              hotkey.HotKeyEngine.instance().invokeActionType(hotkey.HotKeyActionType.optionComplete)
            }
          },
          esc: {
            key: 27,
            handler: function () {
              me.props.onTab();
            }
          }
        }
      },
      clipboard: {
        matchers: [[Node.TEXT_NODE, PlainClipboard.HandleTextPaste]]
      },
      mentionShortcust: {},
      mention: {
        allowedChars: /^[A-Za-z\/-_ÅÄÖåäö]*$/,
        source: function (searchTerm) {
          var tags = [];
          let depth = searchTerm.split("/").length;
          var tagStr = [];
          for (var x in me.props.connect.state.tags) {
            let tag = me.props.connect.state.tags[x].name;
            let tagParts = tag.split("/");
            let tagToAdd = tagParts.splice(0, depth + 1).join("/");
            tagStr.push(tagToAdd);
          }
          var uniqueNames = [];
          $.each(tagStr, function (i, el) {
            if ($.inArray(el, uniqueNames) === -1) uniqueNames.push(el);
          });

          for (var xx in uniqueNames.sort()) {
            tags.push({
              id: uniqueNames[xx],
              value: uniqueNames[xx]
            });
          }
          if (searchTerm.length === 0) {
            //this.renderList( tags,searchTerm);
          } else {
            let matches = [];

            for (var i = 0; i < tags.length; i++)
              if (~tags[i].value.toLowerCase().indexOf(searchTerm))
                matches.push(tags[i]);
            matches = matches.sort((a, b) => {
              return a.value > b.value ? 1 : -1;
            });
            this.renderList(matches, searchTerm);
          }
        }
      }
    };

    var formats = ["chip", "clear"];

    this.quillEditor = new Quill("#quick-editor", {
      modules: modules,
      theme: "bubble",
      placeholder: "example: Take out the trash next tuesday",
      scrollingContainer: "#todoedit-scrolling-container"
    });
    //this.quillEditor.keyboard.addBinding({ key: 9 },  me.props.onTab);
    //this.quillEditor.keyboard.addBinding({ key: 27 },  me.props.onTab);
    this.quillEditor.keyboard.bindings[27] = [
      {
        key: 27,
        handler: e => {
          me.props.onEsc();
        }
      }
    ];

    this.quillEditor.setText(this.props.todo.description);
    if (!this.props.todo.description) {
      this.quillEditor.focus();
      let s = { ...me.state };
      s.focused = true;
      me.setState(s);
    }
    var me = this;

    $("#quick-editor")
      .find(".ql-editor")
      .addClass("ql-todo-custom");
    this.quillEditor.on("selection-change", function (range, oldRange, source) {
      let s = { ...me.state };

      if (range === null && oldRange !== null) {
        s.focused = false;
        me.setState(s);
      } else if (range !== null && oldRange === null) {
        s.focused = true;
        me.setState(s);
      }
    });
    this.quillEditor.on("text-change", function (delta, oldDelta, source) {
      console.log("text-change " + me.updating);
      if (!me.updating) {
        try {
          me.updating = true;
          console.log("text-change");
          if (delta.ops[delta.ops.length - 1].insert == "\n") {
            me.props.onEnter();
            return false;
          }
          let getDate = function (results: any): Date {
            //results[i].text
            let date = ParseDate(results.text, -1)
            return date;
            /*
            //let results = cnode.parse(text, new Date(), { forwardDate: true });
            //if (results.length > 0) {
            results.start.imply("hour", 8);
            return results.start.date();*/
          };

          let quill = me.quillEditor;
          let contents = quill.getContents();
          let customGetText = function () {

            return quill.getContents().filter(function (op) {
              return typeof op.insert === 'string' 
            }).map(function (op) {
              if(typeof op.insert === 'string')
              {
                return op.insert;
              }
              else
              {
                 return ""
              }
      
            }).join('');
          }
          var text = customGetText();//quill.getText();
            
          let e: TextParseEvent = new TextParseEvent();
          e.addTags = me.getTags(contents.ops);
         

          e.description = text;
          quill.formatText(0, text, "bold", false);

          let results = cnode.parse(text.replace(/(?:https?|ftp):\/\/[\n\S]+/g, ''), new Date(), { forwardDate: true });

          var mL = [
            "january",
            "february",
            "march",
            "april",
            "may",
            "june",
            "july",
            "august",
            "september",
            "october",
            "november",
            "december"
          ];
          var mS = [
            "jan",
            "feb",
            "mar",
            "apr",
            "may",
            "june",
            "july",
            "aug",
            "sept",
            "cct",
            "bov",
            "dec"
          ];
          let date = null;
          let first = true;
          for (var i in results) {
            let s = results[i].text
              .toLowerCase()
              .trim()
              .replace("\n", "");
            //don't set a date if its just the month name - Get birthday present from April
            if (mL.indexOf(s) == -1 && mS.indexOf(s) == -1) {
              if (first) {

                date = getDate(results[i]);
                e.description = e.description.replace(results[i].text, "");
                let format = quill.getFormat(results[i].index, 1);
                if (!format.bold) {
                  ;
                  quill.formatText(
                    results[i].index,
                    results[i].text.length,
                    "bold",
                    true
                  );
                  /* quill.formatText(
                     results[i].index+results[i].text.length,
                     1,
                     "bold",
                     false
                   );*/
                  quill.format("bold", false);
                }
              }
              first = false;
            }
          }

          e.schedule = date;
          me.props.onParse(e);
        } catch (e) {
          console.error(e);
        }
        me.updating = false;
      }
    });
  }
  componentWillUnmount() { }
  componentWillReceiveProps(nextProps: RichTodoInputProp) {
    if (
      (this.props.todo && !nextProps.todo) ||
      (!this.props.todo && nextProps.todo) ||
      (this.props.todo &&
        nextProps.todo &&
        this.props.todo.id != nextProps.todo.id)
    ) {
      var nameText = "";

      this.setState({
        description: nextProps.todo.description,
        todo: nextProps.todo
      });

      return true;
    }
  }
  handleChange(e, newValue, newPlainTextValue, mentions) {
    let s = { ...this.state };
    s.description = newPlainTextValue;
    this.setState(s);
  }
  render() {
    let me = this;
    const actions = [
      <material.FlatButton
        label="Cancel"
        primary
        onClick={() => {
          me.props.onEnter();
        }}
      />,
      <material.FlatButton
        label="Save"
        primary
        disabled={me.state.description != ""}
        onClick={() => { }}
      />
    ];

    return (
      <div className="container" id="todoedit-scrolling-container" style={{}}>
        <div className="row">
          <div
            id="quick-editor"
            className="quick-editor joyride-edit-description"
          />
        </div>
        <div className="row">
          <hr
            aria-hidden="true"
            className={
              this.state.focused
                ? "quick-editor-focused "
                : "quick-editor-notfocused "
            }
          />
        </div>
      </div>
    );
  }
}
