import * as React from "react";
import * as material from "material-ui";
import * as model from "../lib/model";
import * as pApi from "../../shared/papi/papi-core";
import * as hotkey from "../lib/hotkeyClient"; 
let cnode = require("chrono-node");

 

class SnoozeChoices {
  value: string;
  valueToShow: string;

  display: any;
  action: pApi.SnoozeAction;
  alwaysShow: boolean;
  constructor(value, action: pApi.SnoozeAction, valueToShow, alwaysShow) {
    this.value = value;
    this.valueToShow = valueToShow;
    this.display = (
      <material.MenuItem primaryText={valueToShow} secondaryText="&#9786;" />
    );
    this.action = action;
    this.alwaysShow = alwaysShow;
  }
}

function getDefaultChoices(alwaysShow: boolean, includeClear:boolean): Array<SnoozeChoices> {
  let retval =  new Array<SnoozeChoices>(
  
    new SnoozeChoices(
      "Tomorrow",
      pApi.SnoozeAction.tomorrow,
      "Tomorrow",
      alwaysShow
    ),
    new SnoozeChoices(
      "This Weekend",
      pApi.SnoozeAction.weekend,
      "This Weekend",
      alwaysShow
    ),
    new SnoozeChoices(
      "Next Week",
      pApi.SnoozeAction.nextWeek,
      "Next Week",
      alwaysShow
    )

  );
  if(new Date()<pApi.getSnoozeDate(pApi.SnoozeAction.laterToday,null))
  {
    //only push tonight if its later in the day
    retval.push( new SnoozeChoices(
      "Tonight",
      pApi.SnoozeAction.laterToday,
      "Tonight",
      alwaysShow
    ));
  }
  if(includeClear)
  {
      retval.push( new SnoozeChoices('Clear Date',pApi.SnoozeAction.custom,'Clear Date',alwaysShow));
  }
  return retval;
}
let daysOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",

];
var longMonth = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];
var shortMonth = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec"
];

function generateChoices(choice: string): Array<SnoozeChoices> {
  let currentDate = new Date();
  // if (window["generateChoices"]) return window["generateChoices"];
  let retval = getDefaultChoices(false,true); //defaultChoices.map(x => x);
  let reg = /(\b\d+(?:[\.,]\d+)?\b(?!(?:[\.,]\d+)|(?:\s*(?:%|percent))))/.exec(
    choice
  );
  if (reg && reg.length > 0) {
    let counter = reg[0];

    retval.push(
      new SnoozeChoices(
        counter + "h",
        pApi.SnoozeAction.custom,
        "in " + counter + " hours",
        false
      )
    );
    retval.push(
      new SnoozeChoices(
        counter + " hours",
        pApi.SnoozeAction.custom,
        "in " + counter + " hours",
        false
      )
    );
    retval.push(
      new SnoozeChoices(
        counter + "m",
        pApi.SnoozeAction.custom,
        "in " + counter + " minutes",
        false
      )
    );
    retval.push(
      new SnoozeChoices(
        counter + "min",
        pApi.SnoozeAction.custom,
        "in " + counter + " minutes",
        false
      )
    );
    retval.push(
      new SnoozeChoices(
        counter + "d",
        pApi.SnoozeAction.custom,
        "in " + counter + " days",
        false
      )
    );
    retval.push(
      new SnoozeChoices(
        counter + " day",
        pApi.SnoozeAction.custom,
        "in " + counter + " days",
        false
      )
    );
    retval.push(
      new SnoozeChoices(
        counter + "d",
        pApi.SnoozeAction.custom,
        "in " + counter + " days",
        false
      )
    );
    retval.push(
      new SnoozeChoices(
        counter + " day",
        pApi.SnoozeAction.custom,
        "in " + counter + " days",
        false
      )
    );
    retval.push(
      new SnoozeChoices(
        counter + " month",
        pApi.SnoozeAction.custom,
        "in " + counter + " month",
        false
      )
    );
  }
  reg = /\d[d|h|m]/.exec(choice);
  if (reg && reg.length > 0) {
    let counter = reg[0];
    if (counter.indexOf("h") > -1) {
      retval.push(
        new SnoozeChoices(
          counter,
          pApi.SnoozeAction.custom,
          "in " + counter.replace("h", "") + " hours",
          false
        )
      );
    }
    if (counter.indexOf("m") > -1) {
      retval.push(
        new SnoozeChoices(
          counter,
          pApi.SnoozeAction.custom,
          "in " + counter.replace("m", "") + " mintues",
          false
        )
      );
    }
    if (counter.indexOf("d") > -1) {
      retval.push(
        new SnoozeChoices(
          counter,
          pApi.SnoozeAction.custom,
          "in " + counter.replace("d", "") + " days",
          false
        )
      );
    }
  }
  /*longMonth.forEach(x => {
    retval.push(new SnoozeChoices(x, pApi.SnoozeAction.custom, x, true));
    let dayofMonth = 1;
    while (dayofMonth < 32) {
      retval.push(
        new SnoozeChoices(
          x + " " + dayofMonth,
          pApi.SnoozeAction.custom,
          x + " " + dayofMonth,
          false
        )
      );
      dayofMonth++;
    }
  });
  shortMonth.forEach(x => {
    retval.push(new SnoozeChoices(x, pApi.SnoozeAction.custom, x, true));
    let dayofMonth = 1;
    while (dayofMonth < 32) {
      retval.push(
        new SnoozeChoices(
          x + " " + dayofMonth,
          pApi.SnoozeAction.custom,
          x + " " + dayofMonth,
          false
        )
      );
      dayofMonth++;
    }
  });*/
  for(let i in daysOfWeek)
  {
    let x = daysOfWeek[i];
    retval.push(
      new SnoozeChoices(x, pApi.SnoozeAction.custom, "Next " + x, true)
    );

    //skip the current day to force it to next week
    let index = -1;
    try
    {
    
      index = Number.parseInt(i);
      if(currentDate.getDay() != index )
      {
        retval.push(new SnoozeChoices(x, pApi.SnoozeAction.custom, x, false));
      }
      else{
      
      }
    }
    catch{
      retval.push(new SnoozeChoices(x, pApi.SnoozeAction.custom, x, false));
    } 
  }

  /*
  var counter = 1;
  while (counter < 24) {
    retval.push(
      new SnoozeChoices(
        counter + "h",
        pApi.SnoozeAction.custom,
        "in " + counter + " hours",
        false
      )
    );
    retval.push(
      new SnoozeChoices(
        counter + " hours",
        pApi.SnoozeAction.custom,
        "in " + counter + " hours",
        false
      )
    );
  }
  counter = 1;
  while (counter < 60) {
    retval.push(
      new SnoozeChoices(
        counter + "m",
        pApi.SnoozeAction.custom,
        "in " + counter + " minutes",
        false
      )
    );
    retval.push(
      new SnoozeChoices(
        counter + "min",
        pApi.SnoozeAction.custom,
        "in " + counter + " minutes",
        false
      )
    );
  }
  counter = 1;
  while (counter < 60) {
    retval.push(
      new SnoozeChoices(
        counter + "d",
        pApi.SnoozeAction.custom,
        "in " + counter + " days",
        false
      )
    );
    retval.push(
      new SnoozeChoices(
        counter + " day",
        pApi.SnoozeAction.custom,
        "in " + counter + " days",
        false
      )
    );
  }
  counter = 1;
  while (counter < 12) {
    retval.push(
      new SnoozeChoices(
        counter + " month",
        pApi.SnoozeAction.custom,
        "in " + counter + " month",
        false
      )
    );
    counter++;
  }*/

  retval = retval.sort((a: SnoozeChoices, b: SnoozeChoices) => {
    return a.valueToShow < b.valueToShow ? 1 : -1;
  });
  
  window["generateChoices"] = retval;
  return retval;
}

interface SnoozeAutoCompleteProps {
  connect: model.ConnectProps;
  autofocus: boolean;
  includeClear:boolean;
  snoozed: (snoozeAction: pApi.SnoozeAction, date: Date) => void;
  onSuggestion: (date?: Date, dateText?: string) => void;
  onFocus: () => void;
  onBlur: () => void;
  onEsc: () => void;
}
interface SnoozeAutoCompleteState {
  dateQuery: string;
  parsedDate?: Date;
}

export class SnoozeAutoComplete extends React.Component<
  SnoozeAutoCompleteProps,
  SnoozeAutoCompleteState
> {
  constructor(props: SnoozeAutoCompleteProps, context) {
    super(props, context);

    this.state = {
      dateQuery: "",
      parsedDate: null
    };
  }
  componentDidMount() {
    let me = this;
    let instance = hotkey.HotKeyEngine.instance();
    let handlers = new Map<hotkey.HotKeyActionType, () => boolean>();
    handlers.set(
      hotkey.HotKeyActionType.esc,
      (): boolean => {
        me.props.onEsc();
        return true;
      }
    );
    instance.registerHandlers(
      "snoozeautocomplete",
      "snoozeautocomplete",
      true,
      handlers
    );
  }
  componentWillUnmount() {
    let instance = hotkey.HotKeyEngine.instance();
    instance.unregisterHandlers("snoozeautocomplete");
  }
  parseDate(
    datasource: Array<SnoozeChoices>,
    chosen: any,
    index: number
  ): Date {
    function parseCnode(text: string): Date {
      text = text?text.replace("@", "at"):text;
      let results = cnode.parse(text, new Date(), { forwardDate: true });
      if (results.length > 0) {
        results[0].start.imply("hour", 8);
        return results[0].start.date();
      }
      return null;
    }
    let choice: SnoozeChoices = null;
    if (index != -1) {
      
      choice = datasource[index];
    } else {
      /*choice = datasource.find(x =>
        x.valueToShow.toLowerCase().startsWith(chosen.toLowerCase())
      );*/
      choice = datasource.find(x =>
        chosen !== "" &&
        x.valueToShow.toLowerCase().indexOf(chosen.toLowerCase()) !== -1
      );


      
    }
    if (choice != null) {
      if (choice.action == pApi.SnoozeAction.custom) {
          if(choice.value == 'Clear Date')
          {
            return new Date(0);
          }
        let date = parseCnode(choice.valueToShow);
        if(!date)
        {
          date = parseCnode(choice.value);
        }
       
        if (date) {
          if (date.getHours() == 0) {
            date.setHours(8);
          }
          return pApi.getSnoozeDate(pApi.SnoozeAction.custom, date);
        }
      } else {
        return pApi.getSnoozeDate(choice.action, null);
      }
    } else {
      if (chosen) {
        let date = parseCnode(chosen);
        if (date) {
          if (date.getHours() == 0) {
            date.setHours(8);
          }
          return pApi.getSnoozeDate(pApi.SnoozeAction.custom, date);
        }
      }
    }
    return null;
  }
  render() {
    let me = this;
    let datasource = [];

    if (this.state.parsedDate != null) {
    }
    datasource =
      this.state.dateQuery == ""
        ? getDefaultChoices(true, this.props.includeClear)
        : generateChoices(this.state.dateQuery);
    let choice = this.state.dateQuery.toLowerCase().replace("in", "");
    datasource = datasource.filter(
      x => x.value.toLowerCase().indexOf(choice) == 0
    );
    if (this.state.parsedDate != null) {
      datasource.push(
        new SnoozeChoices(
          this.state.dateQuery,
          pApi.SnoozeAction.custom,
          model.formatRelativeDateWithTime(this.state.parsedDate),
          true
        )
      );
    }
    console.log("choices = " + this.state.parsedDate);
    return (
      <material.AutoComplete
        key="quickSnooze"
        dataSource={datasource}
        dataSourceConfig={{
          text: "valueToShow",
          value: "value"
        }}
        fullWidth
        autoFocus={this.props.autofocus}
        filter={(searchText, itemText, item) => {
          if (item.alwaysShow === true) return true;
          return (
            searchText !== "" &&
            item.value.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
          );
        }}
        onUpdateInput={e => {
        
          let date: Date = null;
          if (e.length > 0) {
            date = me.parseDate(datasource, e, -1);
          }
          me.setState({
            dateQuery: e,
            parsedDate: date
          });
          me.props.onSuggestion(
            date,
            date ? model.formatRelativeDateWithTime(date) : null
          );
        }}
        onNewRequest={(chosen, index) => {
          
          let date = me.parseDate(datasource, chosen, index);
          if (date) {
            me.props.snoozed(pApi.SnoozeAction.custom, date.getTime()==0?null:date);
          }
        }}
        value={this.state.dateQuery}
        hintText="Try: In 2 days ..."
      />
    );
  }
}
