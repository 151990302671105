import * as model from "../../lib/model";

import * as pApi from "../../../shared/papi/papi-core";
import { HybridContext } from "../../lib/frontEndContext"

const Quill = require("quill");


var markshortcut = require("./MarkdownShortcuts");
var _ = require("lodash");
let Delta = Quill.import("delta");
var enhancedListItem = require("./ListItem");
const { List, ListItem } = enhancedListItem;
let $ = require('jquery')

export function deduplicateTags(tags: Array<string>): Array<string> {
  var uniqueNames = new Array<string>();
  $.each(tags, function (i, el) {
    if ($.inArray(el, uniqueNames) === -1) uniqueNames.push(el);
  });
  //console.log("TAGS " + uniqueNames.join(','))
  return uniqueNames;
}
export function getTagsFromOps(ops: any[]): Array<string> {
  var retval = new Array<string>();
  for (var i in ops) {
    if (ops[i].insert && ops[i].insert.mention) {
      retval.push(ops[i].insert.mention.value);
    }
  }
  return retval;
}
class QuillTodoUpdate {
  todo: pApi.ToDoItem;
  replaceDescription: boolean;
  oldId?: any;
}
export default class TodoSync {
  quill: any;
  documentId: any;
  connect: model.ConnectProps;
  context: HybridContext;
  getTags: () => Array<string>
  private todoCache: any = {};
  isUpdating: boolean;
  constructor(quill, documentId, connect: model.ConnectProps) {
    this.quill = quill;
    this.context = connect.state.context as HybridContext;
    this.connect = connect;
    this.documentId = documentId;
    this.isUpdating = false;
  }
  sync_id: number;
  init(getTags: () => Array<string>) {
    this._dbTodos = null;
    this.getTags = getTags
    this.tags = getTags();

    this.syncTodos(true);
  }
  updateTags(tags: Array<string>) {
    this.tags = tags;
  }
  _dbTodos: Array<pApi.ToDoItem>;
  _addCache: any = {};
  private compareTodoArray(
    arr1: Array<pApi.ToDoItem>,
    arr2: Array<pApi.ToDoItem>
  ): boolean {
    if ((arr1 && !arr2) || (!arr1 && arr2)) return false;
    if (arr1.length != arr2.length) return false;
    // arr1 = arr1.filter(x=>x.sync_id != this.context.getSyncId())
    // arr2 = arr2.filter(x=>x.sync_id != this.context.getSyncId())
    var diff = new Array<pApi.ToDoItem>();
    for (var i in arr2) {
      var item1 = arr2[i];
      var find = arr1.filter(x => x.id == item1.id);
      if (find.length == 0) {
        return false;
      }
      let item2 = find[0];
      if (
        item1.description == item2.description &&
        item1.due_date == item2.due_date &&
        item1.priority == item2.priority &&
        item1.checked == item2.checked &&
        item1.sync_id == item2.sync_id &&
        this.compareArray(item1.tags, item2.tags)
      ) {
        //diff.push(item1.modified>item2.modified?item1:item2);
      } else {
        diff.push(item1.modified > item2.modified ? item1 : item2);
      }
    }
    return diff.length == 0;
  }
  private processDbUpdate(todos: Array<pApi.ToDoItem>) {
    console.log("SYNC:Db Updated");
    debugger
    if (!this.compareTodoArray(this._dbTodos, todos)) {
      console.log("SYNC:Db Changes Detected");
      this._dbTodos = todos;
      this.syncTodos(true);
    }
  }
  __lastUpdate: Array<pApi.ToDoItem>;
  private dbUpdate(todos: Array<pApi.ToDoItem>) {
    var me = this;
    console.log("SYNC:Change Event");
    this.tags = this.getTags();
    me.processDbUpdate(todos);
    /*
    console.log("SYNC:Change Event");
    var updateContent1 = _.debounce(() => {
      console.log("SYNC:Change Call");
      me.processDbUpdate(this.__lastUpdate);
    }, 2000);
    this.__lastUpdate= todos;
    updateContent1();*/
  }
  getTodosInDB(): Promise<Array<pApi.ToDoItem>> {
    return new Promise<Array<pApi.ToDoItem>>(async (resolve, reject) => {
      try {
        var debouceUpdate = _.debounce(this.dbUpdate, 1000);
        if (!this._dbTodos) {
          let updater = new pApi.UpdateCallback<pApi.ToDoItem>(
            "note",
            null,
            debouceUpdate.bind(this)
          );
          this._dbTodos = await this.context.Todos.getByNote(
            this.documentId,
            updater
          );
        }
        resolve(this._dbTodos);
      } catch (e) {
        reject(e);
      }
    });
  }
  private tagsChanged(tag1: Array<string>, tag2: Array<string>): boolean {

    //tag1 = tag1.filter(x => this.tags.findIndex(y => y != x) != -1).sort()
    //tag2 = tag2.filter(x => this.tags.findIndex(y => y != x) != -1).sort()

    //
    return !this.compareArray(tag1, tag2);
  }
  private compareArray(arr1: Array<string>, arr2: Array<string>): boolean {
    if ((arr1 && !arr2) || (!arr1 && arr2)) return false;
    if (arr1.length != arr2.length) return false;

    for (var i in arr1) {
      /* if(arr1[i]!=arr2[i])
        return false*/

      if (arr2.filter(x => x == arr1[i]).length == 0) {
        return false;
      }
    }
    return true;
  }
  private saveTodo(todo: pApi.ToDoItem): Promise<pApi.ToDoItem> {
    return new Promise<pApi.ToDoItem>(async (resolve, reject) => {
      try {
        todo.sync_id = this.sync_id;

        var todoWithId = (await this.context.Todos.save(todo)) as pApi.ToDoItem;
        if (this._dbTodos) {
          var index = this._dbTodos.findIndex(x => x.id == todoWithId.id);
          if (index == -1) this._dbTodos.push(todoWithId);
          else this._dbTodos[index] = todoWithId;
        }
        resolve(todoWithId);
      } catch (e) {
        reject(e);
      }
    });
  }
  lastTextChange: any = new Date();
  startSync: any = new Date();
  checkAbort() {
    var retval = this.lastTextChange > this.startSync;
    if (retval) {
      console.warn("text changed abort sync");
    }
    return retval;
  }
  private lastSync = new Date();
  private callCount = 0;
  private async syncTodos(fromDb: boolean): Promise<Boolean> {
    ;
    this.startSync = new Date();

    return new Promise<Boolean>(async (resolve, reject) => {
      if (this.isUpdating) {
        console.log("Sync:Is Updating do not resync");
        resolve(true);
      }

      try {
        this.isUpdating = true;
        var quillUpdates = new Array<QuillTodoUpdate>();
        console.log("Sync:Start enter");
        var docTodos = this.getTodosFromDoc();

        var dbTodos = await this.getTodosInDB();
        
        // await this.context.Todos.startBatch();
        var newTodos = new Array<pApi.ToDoItem>(); //docTodos.filter(n => n.id.indexOf("new") == 0);
        for (var ni in docTodos) {
          if (dbTodos.filter(n => n.id == docTodos[ni].id).length == 0) {
            newTodos.push(docTodos[ni]);
          }
        }
        if (newTodos.length > 0 && fromDb) {
          //when we are still adding todos ignore db updates from other sources
          resolve(true);
        }
        console.log(
          "Sync:Start Update " + newTodos.length + " from DB " + fromDb
        );
        var didAdd = false;
        let me = this;
        let keepGoing = await me.context.bulkupdate(
          new Promise<boolean>(async (resolve, reject) => {
            try {
              for (var i in newTodos) {
                var oldId = newTodos[i].id;
                if (!newTodos[i].description.trim()) {
                  console.log(
                    `SYNC:new${newTodos[i].id
                    } new Todo was found BUT emptry description. abort`
                  );
                } else if (this._addCache[oldId]) {
                  console.log(
                    `SYNC:${newTodos[i].id} ${newTodos[i].description
                    } new Todo was found BUT it was already tried to be added.abort`
                  );
                } else {
                  console.log(
                    `SYNC:${newTodos[i].id} ${newTodos[i].description
                    } new Todo was found. add it to the db`
                  );
                  if (me.checkAbort()) {
                    return resolve(false);
                  }
                  me._addCache[oldId] = oldId;
                  newTodos[i].project_type = 3; //note task
                  newTodos[i].note_id = me.documentId;
                  //newTodos[i].id = null;
                  newTodos[i].is_new = true;
                  // newTodos[i].tags = me.tags;  These should be good line and fron the line
                  newTodos[i].priority =
                    newTodos[i].description.indexOf("!!") == -1 ? pApi.ToDoItemPriority.Normal : pApi.ToDoItemPriority.High;
                  newTodos[i] = await me.context.Todos.save(newTodos[i]);
                  me._dbTodos.push(newTodos[i]);
                  // let todoWithId = await this.saveTodo(newTodos[i]);
                  //if (this._dbTodos) this._dbTodos.push(todoWithId);

                  //this._dbTodos = null;
                  //this.updateTodoInQuill(quillUpdates, newTodos[i], fromDb, oldId);
                  didAdd = true;
                }
              }
              resolve(true)
            } catch (e) {
              reject(e)
            }
          }));

        if (!keepGoing) {
          reject(false)
        }
        /*if (didAdd) {
          docTodos = this.getTodosFromDoc();
        }*/
        // await this.context.Todos.getByNote(this.documentId);
        var diffLog = [];

        function log(note: pApi.ToDoItem, db: pApi.ToDoItem) {
          if (!note && db)
            diffLog.push(
              "null --- " +
              db.id +
              " " +
              db.checked +
              " " +
              db.priority +
              " [" +
              db.description +
              "]"
            );
          else if (note && !db) {
            diffLog.push(
              note.id +
              " " +
              note.checked +
              " " +
              note.priority +
              " " +
              " [" +
              note.description +
              "] --- null"
            );
          } else {

            diffLog.push(
              note.id +
              " " +
              note.checked +
              " " +
              note.priority +
              " " +
              Array(me.tags).join(",") +
              " [" +
              note.description +
              "] --- " +
              db.id +
              " " +
              db.checked +
              " " +
              db.priority +
              " " +
              Array(db.tags).join(",") +
              " [" +
              db.description +
              "]"
            );
          }
        }

        let continueProcessing = await this.context.bulkupdate(new Promise<boolean>(async (resolve, reject) => {
          try {
            for (var i in dbTodos) {
              let dbTodo = dbTodos[i];
              let noteTodo = docTodos.find(td => td.id == dbTodo.id);
              if (!noteTodo) {
                //TODO - how should I handle when you remove it from the document
                /*
                log(noteTodo, dbTodo);
                if (!dbTodo.checked) continue; // Was already checked in memory
                //then the Todo was removed from the doc.  check it unless there is a due date
                if (this.checkAbort()) {
                  return resolve(false);
                }
                console.log(
                  `SYNC:${dbTodo.id} ${
                    dbTodo.description
                  } not found in document mark as complete in DB`
                );
                //if you added a due date then you need to close it in the app
    
                let todoAwait = await this.context.Todos.complete(dbTodo.id);
                var index = this._dbTodos.findIndex(x => x.id == todoAwait.id);
                this._dbTodos[index] = todoAwait;
    */
                console.warn(
                  "hmm a todo might have been removed. " +
                  dbTodo.description +
                  ":" +
                  dbTodo.id
                );
                continue;
              }
              log(noteTodo, dbTodo);

              if (fromDb) {
                var needToUpdate = false;

                if (
                  noteTodo.description.trim() != dbTodo.description.trim() ||
                  noteTodo.checked != dbTodo.checked ||
                  noteTodo.priority != dbTodo.priority ||

                  me.tagsChanged(noteTodo.tags, dbTodo.tags)
                ) {

                  needToUpdate = true;

                  noteTodo.checked = dbTodo.checked;
                  noteTodo.priority = dbTodo.priority;
                  noteTodo.description = dbTodo.description;

                  noteTodo.tags = dbTodo.tags;// this.tags;
                }
                if (me.checkAbort()) {
                  return resolve(false);
                }
                if (needToUpdate) {
                  console.log(
                    `SYNC:${dbTodo.id} ${dbTodo.description
                    } changed in DB update Note`
                  );

                  me.updateTodoInQuill(quillUpdates, noteTodo, fromDb);
                } else {
                  console.log(
                    `SYNC:[${dbTodo.id} ${noteTodo.description
                    }] no changes detected`
                  );

                }
              } else {
                var needToUpdate = false;

                let dbDoesntHaveNoteTags = me.tags.filter(x => dbTodo.tags.find(y => y == x)).length != me.tags.length;
                if (
                  noteTodo.description.trim() != dbTodo.description.trim() ||
                  noteTodo.checked != dbTodo.checked ||

                  noteTodo.priority != dbTodo.priority ||
                  me.tagsChanged(dbTodo.tags, noteTodo.tags) ||
                  dbDoesntHaveNoteTags
                  /*!me.compareArray(me.tags, dbTodo.tags)*/
                ) {

                  needToUpdate = true;

                  dbTodo.description = noteTodo.description;
                  dbTodo.checked = noteTodo.checked;
                  dbTodo.priority = noteTodo.priority;

                  dbTodo.tags = noteTodo.tags;//me.tags;
                }

                if (needToUpdate) {
                  if (!dbTodo.checked) {
                    console.log(
                      `SYNC:[${dbTodo.id} ${dbTodo.description
                      } ]changed in Note update DB`
                    );
                    dbTodo = await me.saveTodo(dbTodo);
                  } else {
                    console.log(
                      `SYNC:[${dbTodo.id} ${dbTodo.description
                      }] Complted Note update DB as complete`
                    );
                    await me.context.Todos.complete(dbTodo.id);
                    dbTodo.checked = true;
                    dbTodo.modified = new Date();
                  }
                } else {
                  console.log(
                    `SYNC:[${dbTodo.id} ${dbTodo.description}] no changes detected`
                  );
                }
              }
            }
            resolve(true)
          }
          catch (e) {
            reject(e)
          }
        }));

        if (!continueProcessing) {
          return resolve(false);
        }


        console.log("---");
        for (var i in diffLog) {
          console.info("SYNC:" + diffLog[i]);
        }

        //await this.context.Todos.commitBatch();

        this.processQuillUpdates(quillUpdates);
        for (var i in dbTodos) {
          let todo = dbTodos[i];
          var ele = $('.ql-editor').find('ul[data-id=' + todo.id + ']');//.attr('data-checked',false)
          if (ele && ele.length > 0) {
            
            let description = ""
            if (todo.priority) {
              description += "priority "
            }
            if (todo.due_date) {
              description += model.formatRelativeDateWithTime(todo.due_date) + " "
            }
            if (todo.hide_from_inbox) {
              description += "hiden from inbox"
            }
            console.log("SYNC: update data before "+description);
            ele.attr('data-before', description);
          }
        }
        console.log("Sync:End Update quillUpdates" + quillUpdates.length);
        resolve(true);
        this.isUpdating = false;
      } catch (e) {
        console.warn("Sync:Error" + e);
        reject(e);
      }
    });
  }
  private async syncTodosx(fromDb: boolean) { }

  private getTodoFromLine(line) {
    var todo = new pApi.ToDoItem();
    let customGetText = function (ops: []) {

      return ops.map(function (op: any) {
        if (typeof op.insert === 'string') {
          return op.insert;
        }
        else {
          return " "
        }

      }).join('');
    }

    let ops = line.delta().ops;
    todo.description = customGetText(ops);//lghh˙hh663ine.domNode.innerText;
    todo.note_id = this.documentId;
    todo.sync_id = line.parent.domNode.getAttribute("data-sync-id");
    todo.checked = line.parent.domNode.getAttribute("data-checked") == "true";

    /*todo.tags = line.parent.domNode.getAttribute("data-tags")
      ? line.parent.domNode.getAttribute("data-tags").split(",")
      : [];*/
    todo.tags = getTagsFromOps(ops)
    todo.tags = deduplicateTags(todo.tags.concat(this.tags))

      ;
    var pExisted = false;
    var p = line.parent.domNode.getAttribute("data-priority");
    if (p) {
      p = parseInt(p);
      if (!isNaN(p)) {
        todo.priority = p;
      } else todo.priority = pApi.ToDoItemPriority.Normal;
    }

    todo.id = line.parent.domNode.getAttribute("data-id");

    return todo;
  }

  private updateTodoInQuill(
    updates: Array<QuillTodoUpdate>,
    todo: pApi.ToDoItem,
    replaceDescription: boolean,
    oldId?: any
  ) {
    var update = new QuillTodoUpdate();
    update.oldId = oldId;
    update.replaceDescription = replaceDescription;
    update.todo = todo;
    updates.push(update);
  }
  private processQuillUpdatesNew(updates: Array<QuillTodoUpdate>) {
    let ops: any[] = this.quill.getContents().ops
    let selection = this.quill.getSelection();
    let skipUpdateId = null
    if (selection) {
      let format = this.quill.getFormat(selection);
      if (format.list && format.list.id) {
        skipUpdateId = format.list.id;
      }
    }
    var lines = this.quill.getLines();
    var index = 0;
    for (var i in lines) {
      var l = lines[i].cache.length;
      if (lines[i] instanceof ListItem) {
        var id = lines[i].parent.domNode.getAttribute("data-id");
        if (id && id != skipUpdateId) {


          /*
[{"insert":"test "},{"insert":{"mention":{"id":"test","value":"test"}}},{"insert":" "},{"insert":"\n","attributes":{"list":{"mode":"null","priority":"null","checked":false,"id":"MC2ZzHEEFj_nPCaJ4mT"}}}]
          */
          let update = updates.find(x => x.todo.id == id);
          if (update) {
            let orginalDelta: any[] = lines[i].delta().ops;

            let newDelta: any[] = [
              { "insert": update.todo.description.replace("\n", "").trim() }
            ]
            let tags = update.todo.tags.filter(x => this.tags.findIndex(y => y == x) == -1)

            tags.forEach(x => {
              newDelta.push({
                "insert": " "
              })
              newDelta.push({ "insert": { "mention": { "id": x, "value": x } } })
            })
            // lines[3].remove(0,4)
            newDelta.push({
              "insert": "\n",
              "attributes": {
                "list": {
                  "mode": "null",
                  "priority": update.todo.priority == pApi.ToDoItemPriority.High ? 1 : 0,
                  "checked": update.todo.checked,
                  "id": update.todo.id
                }
              }
            })
            let findMatch = (startAt: number): number => {

              let index = ops.findIndex((x, index: number) => {
                return index >= startAt && model.deepCompare(x, orginalDelta[0])
              });

              if (index > -1) {
                let checkIndex = 0
                while (checkIndex < orginalDelta.length) {
                  if (!model.deepCompare(ops[index + checkIndex], orginalDelta[checkIndex])) {
                    return -1;
                  }
                  checkIndex++;
                }

              }
              return index;

            }

            let index = findMatch(0);

            if (index != -1) {
              ops.splice(index, orginalDelta.length)
              let counter = 0;
              for (var i in newDelta) {
                ops.splice(index + counter, 0, newDelta[counter]);
                counter++;
              }

            }

            //ops.splice(index,orginalDelta.length, newDelta);
            //this.quill.setContents(ops,"silent")

            /*
            lines[i].remove(0, lines[i].length)
            lines[i].insertAt(0, "list", {
              "mode": "null",
              "priority": update.todo.priority == pApi.ToDoItemPriority.High ? 1 : 0,
              "checked": update.todo.checked,
              "id": update.todo.id
            })
            tags.forEach(x => {
              lines[i].insertAt(0, "mention", { "id": x, "value": x })
              // newDelta.push({ "insert": { "mention": { "id": x, "value": x } } })
            })
            lines[i].insertAt(0, update.todo.description)
          
            */


          }
        }






      }
    }

    this.quill.setContents(ops, "silent");
  }
  private processQuillUpdates(updates: Array<QuillTodoUpdate>) {
    let selection = this.quill.getSelection();
    let skipUpdateId = null
    if (selection) {
      let format = this.quill.getFormat(selection);
      if (format.list && format.list.id) {
        skipUpdateId = format.list.id;
      }
    }

    for (var x in updates) {

      var todo = updates[x].todo;
      if (skipUpdateId && todo.id == skipUpdateId) {
        continue;
      }
      var ele = $('.ql-editor').find('ul[data-id=' + todo.id + ']');//.attr('data-checked',false)
      if (ele && ele.length > 0) {

        ele.attr('data-checked', todo.checked ? todo.checked.toString() : false);
        ele.attr('data-priority', todo.priority ? todo.priority.toString() : pApi.ToDoItemPriority.Normal.toString());

        ele.attr('data-tags', todo.tags
          ? Array(todo.tags).join(",")
          : null);
        ele.attr('data-sync-id', todo.sync_id ? todo.sync_id.toString() : '');
        ele.attr('data-mode', 'ready');

        if (updates[x].replaceDescription) {

          let tags = todo.tags.filter(x => this.tags.findIndex(y => y == x) == -1)
          let description = todo.description.replace("\n", "").trim()
          let tagHtml = "";
          tags.forEach(x => {
            tagHtml += `<span class="mention" data-id="undefined" data-value="${x}">﻿<span contenteditable="false"><span class="ql-mention-at-sign">#</span>${x}</span>﻿</span> `
          })
          if (tagHtml.length > 0) {
            description += " " + tagHtml
          }
          else {

          }
          //description+="\n";

          ele.find('li').html(description)
        }

      }
    }
  }

  getTodosFromDoc(): Array<pApi.ToDoItem> {
    var todos = new Array<pApi.ToDoItem>();
    var lines = this.quill.getLines();
    var index = 0;
    for (var i in lines) {
      var l = lines[i].cache.length;
      if (lines[i] instanceof ListItem) {
        var id = lines[i].parent.domNode.getAttribute("data-id");
        if (id) {
          var todo = this.getTodoFromLine(lines[i]);

          index += l;
          todos.push(todo);
        }
      }
      index += l;
    }
    return todos;
  }
  _syncDebouce: any;
  tags: Array<string> = new Array<string>();
  async sync(tags: Array<string>, fromDB: boolean = false) {
    this.tags = tags;
    ;
    ;
    await this.syncTodos(fromDB);
  }
  processChangesx(delta, oldContents, source, tags: Array<string>) {
    var me = this;
    this.tags = tags;
    if (!this._syncDebouce) {
      this._syncDebouce = _.debounce(fromDb => {
        this.syncTodos(fromDb);
      }, 1000);
    }
    /*var todos = this.getTodosFromDoc();
    for(var i in todos)
    {
      var todo = todos[i];
      console.log(todo.id+' '+todo.checked+' ['+todo.description+']')
    }*/
    this._syncDebouce(false);
  }
  findLineForId(id: any): any {
    var lines = this.quill.getLines();
    for (var i in lines) {
      if (lines[i] instanceof ListItem) {
        let lid = lines[i].parent.domNode.getAttribute("data-id");
        if (lid == id) {
          return lines[i];
        }
      }
    }
  }
}
