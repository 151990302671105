import * as React from 'react';
import pure from 'recompose/pure';
import {SvgIcon} from 'material-ui';


let ThisWeek   = (props) => (
  <SvgIcon {...props}   
  version="1.1"
  id="svg2"
  width="24"
  viewBox="0 0 24 24"
  height="24"
 >

 
  <defs
     id="defs10" />
  <path
     id="path4"
     fill="none"
     d="M0 0h24v24H0z" />
  <path
     id="rect3413"
     d="m 6,12.5 2,0 0,2 -2,0 z"
    
      />
  <path
  
     id="path6"
     d="M 19,3 18,3 18,1 16,1 16,3 8,3 8,1 6,1 6,3 5,3 C 3.89,3 3.01,3.9 3.01,5 L 3,19 c 0,1.1 0.89,2 2,2 l 14,0 c 1.1,0 2,-0.9 2,-2 L 21,5 C 21,3.9 20.1,3 19,3 Z M 19,19 5,19 5,8 19,8 Z" />
 
  <path
       
     d="m 8.5,12.5 2,0 0,2 -2,0 z"
     id="path3558" />
  <path
     d="m 11,12.5 2,0 0,2 -2,0 z"
    />
  <path
    
     d="m 13.5,12.5 2,0 0,2 -2,0 z"
     id="path3562" />
  <path
     id="path3564"
     d="m 16,12.5 2,0 0,2 -2,0 z"
    />


</SvgIcon>
);
ThisWeek = pure(ThisWeek);
/*Target.displayName = 'ActionAccessibility';
Target.muiName = 'SvgIcon';*/

export default ThisWeek;