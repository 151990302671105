import * as React from "react";
import * as actions from "../../lib/ActionReducer";
import TodoSync, { getTagsFromOps, deduplicateTags } from "./TodoSync";
import * as pApi from "../../../shared/papi/papi-core";
import { Track, TrackEvent } from "../../../shared/track";
import convertDown from '../../../shared/markdown/fromDelta'
import { ImageHandler, NoteImage } from "./ImageHandler";
import * as model from "../../lib/model";
import NoteProcessor from "./NoteProcessor"
import Viewer from 'react-viewer';
import firebase from "firebase/app"

import Quill from "quill";
import hljs from 'highlight.js/lib/index';
import 'highlight.js/styles/github.css';
import javascript from 'highlight.js/lib/languages/javascript';
import Edit from "material-ui/svg-icons/image/edit";

let js = javascript;

hljs.addPlugin({
  'after:highlightBlock': ({ block, result }) => {
    // move the language from the result into the dataset

    block.dataset.language = result.language
  }
})
let highlight = function (text) {

  let format = hljs.highlightAuto(text)
    ;
  return format.value

}

let uuidV4 = require("uuid/v4");
var _ = require("lodash");
let Delta = Quill.import("delta");
var enhancedListItem = require("./ListItem");
var ImageLib = require("./Image");
var Clipboard = Quill.import("modules/clipboard");
let Inline = Quill.import('blots/inline');
class GrammarlyInline extends Inline { } GrammarlyInline.tagName = 'G'; GrammarlyInline.blotName = 'grammarly-inline'; GrammarlyInline.className = 'gr_';


const { List, ListItem } = enhancedListItem;
const { Image } = ImageLib
var $ = require("jquery");




export interface QuillProps {
  noteChange: (text: string) => any;
  onScroll: (e: any) => void;
  documentId: any;
  connect: model.ConnectProps;
  windowHeight: number;
  fullScreen: boolean;

}
interface StateProps {
  editorHtml: string;
  theme: string;
  setup: boolean;
  documentId: any;
  screenHeight: number;
  toolbarAtTop: boolean;
  presentationMode: model.PresentationMode;
  previewImage?: string;

}
export enum EditorState {
  notStarted,
  loadingContent,
  pendingSave,
  saving,
  error,
  done
}
class PlainClipboard extends Clipboard {
  static HandleTextPaste(node, delta) {
    return new Delta().insert(node.data);
  }
  convert(html = null) {

    if (typeof html === "string") {

      this.container.innerHTML = html;
    }
    function checkURL(url) {
      var reg = /(https?:\/\/.*?\.(?:png|jpe?g|gif)(.*))(\w|$)/gi;
      var m = reg.exec(url);
      if (m) {
        //only return if one url is in th paste
        var match2 = reg.exec(url);
        if (!match2) return m[0];
      } else return null;
    }
    var imageUrl = checkURL(this.container.innerText);

    if (imageUrl) {
      var delta = super.convert(html);

      return delta.insert({
        image: imageUrl
      });
    }
    if (this.container.innerText.toLowerCase().indexOf("data:image") == 0) {
      console.log(this.container.innerText);
      var text = this.container.innerText;
      this.container.innerHTML = "";

      return new Delta().insert({
        image: text
      });
    } else {


      return super.convert(html);


    }

  }
}
export class QuillEditor extends React.Component<QuillProps, StateProps> {
  quillEditor: any;
  inFocus: boolean;
  noteObj: pApi.Note;
  editorState: EditorState = EditorState.notStarted;

  getTags(): Array<string> {
    let tags = new Array<string>();

    let lines = this.quillEditor.getLines();

    for (var i in lines) {
      let line = lines[i];
      let addTags = true;
      let ops = new Array<any>()
      line.delta().ops.forEach(x => {
        ops.push(x);
      })

      if (ops && line instanceof ListItem) {
        addTags = ops.find(x => x.attributes && x.attributes.list) == null;

      }

      if (addTags) {
        tags = tags.concat(getTagsFromOps(ops));
      }



    }
    return deduplicateTags(tags)

  }

  tags: Array<string>;

  todoHash: any = {};
  findLineForId(quill: any, id: any): any {
    var lines = quill.getLines();
    for (var i in lines) {
      if (lines[i] instanceof ListItem) {
        var id = lines[i].parent.domNode.getAttribute("data-id");
        if (id == id) {
          return lines[i];
        }
      }
    }
  }
  updateTodo(
    quill: any,
    id: any,
    line: any,
    description: any,
    checked: boolean
  ) {
    if (!line) line = this.findLineForId(quill, id);
    var index = quill.getIndex(line);
    var length = quill.getLength(line.domNode);
    quill.formatLine(index, index, "list", { checked: checked, id: id });
  } todoSync: TodoSync;
  updating: boolean = false;
  changeState(es: EditorState) {
    this.editorState = es;
    if (es == EditorState.done && this.waitforSaveFunc) {
      this.waitforSaveFunc();
      this.waitforSaveFunc = null;
    }
  }
  async updateContent(quill: any, documentId: any, currentEditorValue) {

    if (documentId != this.documentId) return;

    var val = quill ? quill.getContents() : null;

    if (val) {
      try {
        //getDocumentProperties(val);

        if (this.updating) {
          console.log("skip updating");
          ;
          return;
        }
        ;
        this.updating = true;
        this.changeState(EditorState.saving);
        function _sleep(ms: number) {
          return new Promise(resolve => setTimeout(resolve, ms));
        }


        var text = quill.getText();

        var title = null;
        var lines: [string] = text.split(/(\r\n|\n|\r)/gm);
        if (quill.getFormat(0).header) {
          title = text.split("\n")[0];
        }

        if (lines.length > 2)
          this.noteObj.preview_text = lines.slice(1, 10).join("/n");

        this.tags = this.getTags()

        this.checkForPrivateTags(this.tags);
        try {

          await this.todoSync.sync(this.tags);
        } catch (e) {
          ;
          Track.reportError(
            e,
            "Error syncing todos from this note",
            true,
            this.props.connect.dispatch
          );
        }

        /* if (this.opsAppend.length > 0) {
           let addDelta = this.opsAppend
           var passedDelta = new Delta(addDelta);
           var existingDelta = this.quillEditor.getContents()
           var combinedDelta = existingDelta.concat(passedDelta);
           ops = combinedDelta.ops;
           // ;
         }
         else {*/
        let ops = val.ops;//quill.getContents().ops;
        // }
        ;
        this.queueBuffer = [];
        ops.map(obj => {
          return Object.assign({}, obj);
        });

        this.noteObj.tags = this.tags;
        this.noteObj.title = title;

        var content = new pApi.NoteContent();
        content.contentType = pApi.ContentType.quillJS;// = "quill";
        content.storageType = this.props.connect.state.context.Notes.getDefaultStorageType();//pApi.StorageType.snapshot; //:pApi.ContentType.embedded;

        if (this.noteObj.is_new) {
          // this.noteObj =  await this.props.connect.state.context.Notes.save(this.noteObj);
        }
        content.content = ops;
        let me = this;
        let promise = new Promise<any>(async (resolve, reject) => {
          try {
            me.noteObj = await this.props.connect.state.context.Notes.setContent(
              me.noteObj,
              content
            );
            console.log("SAVING DONE")
            resolve(true)
          }
          catch (e) {
            reject(e)
          }
        })
        await this.props.connect.state.context.fireStoreUpdate(promise);

        this.props.connect.dispatch<any>(
          actions.ar_setNoteLoadingState(model.LoadingState.Completed)
        );
        this.updating = false;
        this.dirty = false;
        if (this.editorState == EditorState.saving) {
          this.changeState(EditorState.done);
        }

        console.log("updated");
      } catch (ex) {
        this.updating = false;
        ;
        Track.reportError(
          ex,
          "Error saving note",
          true,
          this.props.connect.dispatch
        );
        this.changeState(EditorState.error);
        this.props.connect.dispatch<any>(
          actions.ar_setNoteLoadingState(model.LoadingState.Error)
        );
      }

    }
  }
  dirty: boolean = false;
  QueueSaved = _.debounce(async (documentId, delta, contents, newNote) => {
    console.log("NoteProcessor : WHa? ")
    await NoteProcessor.QueueSave(documentId, delta, contents, newNote);
    this.dirty = false
  }, 3000);
  async flushChanged() {

    if (this.dirty) {
      await NoteProcessor.QueueSave(this.documentId, [], this.quillEditor.getContents(), null);

    }
  }
  constructor(props) {
    super(props);
    console.log("Quill Startup********");
    var markshortcut = require("./MarkdownShortcuts").MarkdownShortcuts

    Quill.register("modules/markdownShortcuts", markshortcut);

    Quill.register("formats/list", List);
    Quill.register("formats/list/item", ListItem);
    Quill.register("formats/image", Image);

    var me = this;
    class FireBaseDelta {
      quill: any;
      options: any;
      matches: any;
      constructor(quill, options) {
        this.quill = quill;

        this.options = options;
        me.updateDocumentId = me.documentId;
        var updateContent = _.debounce(() => {
          me.updateContent(quill, me.updateDocumentId, me.currentEditorValue);
        }, 1000);

        window['updateContentQuill'] = () => {
          updateContent();
        }
        if (window["onQuillScroll"]) {

          //  $('.ql-editor')[0].addEventListener('scroll',  window['onQuillScroll']);
        }
        $(".ql-editor")[0].addEventListener("scroll", (e) => {

          me.props.onScroll(e)
        });
        $("#scrolling-container").on('scroll', function (e, scrollTop) {
          if (window["onQuillScroll"]) {
            window["onQuillScroll"](e.currentTarget.scrollHeight, e.currentTarget.scrollTop)
          }
          if (e.currentTarget.scrollHeight - e.currentTarget.scrollTop - Math.round($("#scrolling-container").height()) < 100) {

          }




        });



        // me.resizeEditorByHeight();
        quill.on("selection-change", function (range, oldRange, source) {
          if (range) {
            if (!me.inFocus) {
              me.inFocus = true;
              // me.resizeEditorByHeight();
            }
          } else {
            if (me.inFocus) {
              me.inFocus = false;
              // me.resizeEditorByHeight();
            }
          }
        });

        this.quill.on("text-change", (delta, oldContents, source) => {
          console.log("text changed " + source);// '+JSON.stringify(delta));
          console.log("NoteProcessor : text? ")
          if (source == "sync") {
            return;
          }
          me.dirty = true;

          me.QueueSaved(me.documentId, delta, me.quillEditor.getContents(), me.noteObj.is_new ? me.noteObj : null)



          if (me.startPublishingChanges) {
            _.defer(() => {
              me.queueRef
                .child(
                  Date.now().toString() +
                  ":" +
                  Math.random()
                    .toString()
                    .slice(2)
                )
                .set({
                  event: delta,
                  by: me.uid
                })
                .catch(function (e) {
                  Track.error(e);
                })
            }
            );

          } else {
            let msg = {
              time:
                Date.now().toString() +
                ":" +
                Math.random()
                  .toString()
                  .slice(2),
              event: {
                event: delta,
                by: me.uid
              }
            };
            me.queueBuffer.push(msg);
          }
        });





      }
    }

    var me = this;
    Quill.register("modules/clipboard", PlainClipboard, true);
    Quill.register("modules/firebase-delta", FireBaseDelta, true);
    //Quill.register(GrammarlyInline);

    if (!window["imageNoteClick"]) {
      window["imageNoteClick"] = (e: any) => {
        ;
        me.props.connect.dispatch(actions.ar_setPreviewImage(e.parentNode.attributes["external-src"].value))

        // var win = window.open( e.parentNode.attributes["external-src"].value+".png", '_system');
        // win.focus();


      }
    }
  }

  propTypes: {
    // @ts-ignore
    placeholder: React.PropTypes.string;
  };
  uid: any;
  currentEditorValue: any;
  queueRef: any;
  queueBuffer: Array<any> = [];
  applyingDeltas: any;
  editorDatabase: any;
  documentId: any;
  updateDocumentId: any;
  startPublishingChanges: boolean = false;
  listeners: any = {};
  fullScreen: boolean = true;
  mentionCache: any[]
  componentDidMount() {
    var me = this;

    var modules = {
      markdownShortcuts: {},
      syntax: {
        highlight: highlight /*hljs.highlight*/
      },
      history: {
        delay: 2000,
        maxStack: 500,
        userOnly: true
      },
      "firebase-delta": {},
      clipboard: {
        matchers: [[Node.TEXT_NODE, PlainClipboard.HandleTextPaste]]
      },
      mention: {
        allowedChars: /^[A-Za-z\/-_ÅÄÖåäö]*$/,
        syntax: true,
        source: function (searchTerm) {
          var tags = [];
          if (!me.mentionCache) {

            me.mentionCache = me.props.connect.state.tags.map(x => {
              return {
                count: x.name.split('/').length,
                name: x.name
              }
            }).sort((a, b) => {
              if (a.count == b.count) {
                if (a.name > b.name)
                  return 1;
                if (a.name < b.name)
                  return -1;
                return 0;
              }
              if (a.count > b.count)
                return 1;
              if (a.count < b.count)
                return -1;
              return 0;

            }).map(x => { return { id: x.name, value: x.name } })
          }
          let matches = [];

          if (searchTerm.length === 0) {
            // this.renderList(me.mentionCache.slice(0, 8), searchTerm);

          }
          else {

            let matches = me.mentionCache.filter(x => x.value.indexOf(searchTerm.toLowerCase()) != -1)
              ;
            this.renderList(matches.slice(0, 8), searchTerm);
          }
        }
      },


      toolbar: {
        container: "#toolbar", // Selector for toolbar container
        handlers: {
          undo: function () {
            me.quillEditor.history.undo();
          },
          redo: function () {
            me.quillEditor.history.redo();
          },
          hash:
            function () {

              let range = me.quillEditor.getSelection(true);
              if (!range || range.index != 0) {
                me.quillEditor.updateContents(
                  new Delta()
                    .retain(range.index)
                    .delete(range.length)
                    .insert('#'),
                  "user"
                );
              } else {
                me.quillEditor.updateContents(
                  new Delta().retain(me.quillEditor.getLength()).insert('#'),
                  "user"
                );
              }
              me.quillEditor.setSelection(range.index + 1, "silent");
            },
          image: async function () {
            try {
              console.log("collect image handler");
              var ih = new ImageHandler(
                me.props.connect.dispatch,
                me.props.connect.state.context.getId()
              );


              var image = await ih.collectImage(
                me.props.connect.state.context.getId() +
                "/" +
                me.documentId +
                "/" +
                uuidV4(), me.documentId
              );

              let range = me.quillEditor.getSelection(true);
              if (!range || range.index != 0) {
                me.quillEditor.updateContents(
                  new Delta()
                    .retain(range.index)
                    .delete(range.length)
                    .insert({
                      image: image.toQuillObject()
                    }),
                  "user"
                );
              } else {
                me.quillEditor.updateContents(
                  new Delta().retain(me.quillEditor.getLength()).insert({
                    image: image.toQuillObject()
                  }),
                  "user"
                );
              }
              me.quillEditor.setSelection(range.index + 1, "silent");
            } catch (e) {
              ;
              ;
              console.error(e)
              Track.reportError(
                e,
                "Error adding image",
                true,
                me.props.connect.dispatch
              );
            }
          }
        }
      }
    };
    var formats = [
      "header",
      "bold",
      "italic",
      "underline",
      "strike",
      "blockquote",
      "list",
      "bullet",
      "indent",
      "link",
      "image",
      "video",
      "chip",
      "clear"
    ];




    this.quillEditor = new Quill("#editor", {
      modules: modules,
      theme: "snow",
      scrollingContainer: "#scrolling-container"
    });

    //this.quillEditor.root.removeAttribute('data-gramm');
    if (this.documentId)
      this.setup(true);
  }
  componentWillUnmount() {
    //this.quillEditor.remove();try

    try {
      this.detachListenersFromFirebase();
    } catch (e) {
      console.warn(e);
    }
  }
  componentWillMount() {
    this.componentWillReceiveProps(this.props);
    return true;
  }

  getMarkdown() {

    let ops = this.quillEditor.getContents().ops
      ;
    if (this.opsAppend && this.opsAppend.length > 0) {
      ops = ops.concat(this.opsAppend)
    }

    let markdwon = convertDown(ops);
    return markdwon
  }

  resizeEditorByHeight(height?: number) {

    var h = height ? height : this.props.windowHeight;

    //h = window.innerHeight;
    console.log("height " + height);
    //alert(h)

    //$(".ql-editor").css("margin-bottom", "5px");
    let resize = function (h) {

      $("#scrolling-container").css("max-height", h);
      $("#scrolling-container").css("min-height", h);
      $("#scrolling-container").css("height", h + "px");


    };

    if (!this.props.connect.inMobileNoteView()) {
      if (!this.fullScreen) h = h * 0.7 - 64;
      // - 64 - 60
      else h = h - 100;
      $("#toolbar").css("display", "flex");
      resize(h);
    } else {
      $(".ql-editor").css("padding-top", 64);
      // h = h - 60;
      $("#toolbar").css("display", "flex");
      if (this.inFocus) {
        $("#toolbar").css("display", "flex");
      } else {
        //  $("#toolbar").css("display", "none");
      }
      if (window["mobileResize"]) {
        window["mobileResize"](h, this.inFocus);
      } else {
        resize(h);
      }
    }
  }
  waitforSaveFunc: any;
  waitForSave(): Promise<void> {
    var me = this;
    return new Promise<void>((resolve, reject) => {
      if (this.editorState == EditorState.done) return resolve();

      this.waitforSaveFunc = resolve;
    });
  }
  async componentWillReceiveProps(nextProps: QuillProps) {
    var change = !this.state || this.state.documentId != nextProps.documentId;
    var size = this.state ? this.state.screenHeight : nextProps.windowHeight;

    if (
      (this.state &&
        this.state.screenHeight != nextProps.connect.state.windowHeight) ||
      true
    ) {
      size = window.innerHeight + 42; //nextProps.connect.state.windowHeight;
      if (this.state) {
        this.resizeEditorByHeight(size);
      }
    }
    
    if (nextProps.fullScreen != this.fullScreen) {
      this.fullScreen = nextProps.fullScreen;
      this.resizeEditorByHeight(size);
    }
    if (change && nextProps.documentId) {
      if (this.props.connect.state.noteState == model.LoadingState.Loading && this.documentId) {
        console.log("still loading");
        return;
        // await this.waitForSave();
      }
      this.documentId = nextProps.documentId;
      this.setState({
        documentId: nextProps.documentId,
        theme: "snow",
        setup: this.state ? this.state.setup : false,
        screenHeight: size,
        presentationMode: this.props.connect.getPresentationMode(),
      
      });

      this.updateContent(
        this.quillEditor,
        this.props.documentId,
        this.currentEditorValue
      );
      this.setup(true);
    } else {
      this.setState({
        documentId: nextProps.documentId,
        theme: "snow",
        setup: this.state ? this.state.setup : false,
        screenHeight: size,

        presentationMode: this.state
          ? this.state.presentationMode
          : this.props.connect.getPresentationMode()
      });
    }
    return !this.props.documentId && nextProps.documentId;
  }
  async detachListenersFromFirebase() {
    if (this.currentEditorValue) {
      this.editorDatabase.off();
      if (this.queueRef) {
        this.queueRef
          .child(
            Date.now().toString() +
            ":" +
            Math.random()
              .toString()
              .slice(2)
          )
          .set({
            event: "leave",
            by: this.uid
          })
          .catch(function (e) {
            Track.error(e);
          });
      }
    }
  }
  async startListenForChanges(openPageTimeStamp: Date) {
    //listen for changes
    // Make a reference to the database

    var db = firebase.database();

    // Write the entries in the database
    if (this.editorDatabase) {
      this.editorDatabase.off();
    }
    this.editorDatabase = db.ref("editor_values");

    this.detachListenersFromFirebase();
    this.currentEditorValue = this.editorDatabase.child(this.documentId);

    // Store the current timestamp (when we opened the page)
    // It's quite useful to know that since we will
    // apply the changes in the future only

    var me = this;

    // Take the editor value on start and set it in the editor
    this.currentEditorValue
      .child("content")
      .once("value", function (contentRef) {
        // Get the queue reference
        me.queueRef = me.currentEditorValue.child("queue");
        // Get the editor document object

        // Listen for updates in the queue
        me.queueRef.on("child_added", function (ref) {
          // Get the timestamp
          var timestamp = ref.key.split(":")[0];
          if (!openPageTimeStamp) {
            openPageTimeStamp = new Date();
          }
          if (!openPageTimeStamp.getTime) {
            openPageTimeStamp = new Date(openPageTimeStamp)
          }
          if (

            openPageTimeStamp.getTime() > parseInt(timestamp) ||
            Math.floor(
              (new Date().getTime() - openPageTimeStamp.getTime()) / 1000 / 60
            ) > 1
          ) {
            return;
          }
          // Get the snapshot value

          var value = ref.val();

          // In case it's me who changed the value, I am
          // not interested to see twice what I'm writing.
          // So, if the update is made by me, it doesn't
          // make sense to apply the update
          if (value.by === me.uid) {
            return;
          }
          // Do not apply changes from the past
          if (value.by !== me.uid) {
            if (!me.startPublishingChanges) {
              let buffer = me.queueBuffer;
              me.queueBuffer = [];
              for (var x in buffer) {
                let m = buffer[x];
                me.queueRef
                  .child(m.time)
                  .set(m.event)
                  .catch(function (e) {
                    Track.error(e);
                  });
              }
            }
            me.startPublishingChanges = true;
          }
          // Stay that way until you leave the note
          me.listeners[value.by] = new Date();
          if (value.event == "enter") {
            me.queueRef
              .child(
                Date.now().toString() +
                ":" +
                Math.random()
                  .toString()
                  .slice(2)
              )
              .set({
                event: "ping",
                by: me.uid
              })
              .catch(function (e) {
                Track.error(e);
              });
            return; //
          }
          if (value.event == "ping") {
            return; //
          }
          if (value.event == "leave") {
            me.listeners[value.by] = null;
            let anyListeners = false;
            for (var i in me.listeners) {
              if (me.listeners[i]) {
                anyListeners = true;
              }
            }
            me.startPublishingChanges = anyListeners;
            return; //
          }
          // We're going to apply the changes by somebody else in our editor
          //  1. We turn applyingDeltas on
          me.applyingDeltas = true;
          //  2. Update the editor value with the event data

          me.quillEditor.updateContents(value.event, "sync");

          //  3. Turn off the applyingDeltas
          me.applyingDeltas = false;
        });
        me.queueRef
          .child(
            Date.now().toString() +
            ":" +
            Math.random()
              .toString()
              .slice(2)
          )
          .set({
            event: "enter",
            by: me.uid
          })
          .catch(function (e) {
            Track.error(e);
          });
      });
  }
  opsAppend: any = null;
  datagram: any;
  checkForPrivateTags(tags: Array<string>) {
    console.log("checkForPrivateTags")
    // 
    if (tags.filter(x => x.indexOf('secure') != -1).length > 0) {

      this.quillEditor.root.setAttribute('data-gramm', true);

      //    
    }
    else {
      this.quillEditor.root.removeAttribute('data-gramm');
      Quill.register(GrammarlyInline);

      //  
    }
  }
  async setup(force) {
    if (true || (this.quillEditor && (force || !this.state.setup))) {
      try {
        if (this.state && !this.state.setup) {
          this.setState({
            setup: true,
            theme: this.state.theme,
            documentId: this.state.documentId
          });
        }

        console.log("QUILLSETUP:set contents 0");
        //$('.editorArea').hide();
        if (!this.quillEditor) return;
        this.quillEditor.disable();
        this.props.connect.dispatch<any>(
          actions.ar_setNoteLoadingState(model.LoadingState.Loading)
        );
        console.log("QUILLSETUP:Quill Setup");

        this.applyingDeltas = true;
        // Get the editor id, using Url.js
        // The queryString method returns the value of the id querystring parameter
        // We default to "_", for users which do not use a custom id.
        var editorId = this.documentId; // + this.state.d //window['documentId'];//this.props.documentId

        // This is the local storage field name where we store the user theme
        // We set the theme per user, in the browser's local storage

        // Generate a pseudo user id
        // This will be used to know if it's me the one who updated
        // the code or not
        this.uid = window["quill-uid"]
          ? window["quill-uid"]
          : Math.random().toString();
        if (!window["quill-uid"]) {
          window["quill-uid"] = this.uid;
        }
        var newCache = window["note-" + this.documentId];
        console.log("QUILLSETUP:GetInPRocessConente")
        var inProcess = await NoteProcessor.GetInProcessContent(this.documentId);
        console.log("QUILLSETUP:GetInPRocessConente - done")
        if (!newCache) {
          if (inProcess && inProcess.newNote) {
            this.noteObj = inProcess.newNote
          }
          else {
            console.log("QUILLSETUP:Get Note")
            this.noteObj = this.props.connect.state.notes.val().find(x => x.id == this.props.documentId);
            if (!this.noteObj) {
              this.noteObj = await this.props.connect.state.context.Notes.load(this.documentId);
            }
            console.log("QUILLSETUP:Get Note Done " + this.noteObj.is_new)
          }
        }
        else {
          this.noteObj = newCache.note
        }
        this.todoSync = new TodoSync(
          this.quillEditor,
          this.documentId,
          this.props.connect
        );

        this.todoSync.isUpdating = true;
        console.log("QUILLSETUP:set contents 1 top");
        var blank = {
          ops: []
        };
        window["quill"] = this.quillEditor;
        $(".ql-editor").css("display", "none");

        this.quillEditor.history.clear();
        this.quillEditor.setContents([], "silent"); //.setContents(blank, "silent");
        this.quillEditor.history.clear();
        var ops = [];
        ;
        ;
        if (newCache) {
          for (var i in newCache.content.content) {
            ops.push(newCache.content.content[i]);
          }

        }
        else if (inProcess) {
          for (var i in inProcess.contents.ops) {
            ops.push(inProcess.contents.ops[i]);
          }
        }
        else {
          let timer = new Date();
          console.log("QUILLSETUP:GetContentStart")
          let content = await this.props.connect.state.context.Notes.getContent(
            this.noteObj
          )
          console.log("QUILLSETUP:GetContentEnd", new Date(new Date().getTime() - timer.getTime()).getTime());
          for (var i in content.content) {
            ops.push(content.content[i]);
          }


        }

        if (newCache) {
          delete window["note-" + this.documentId];
        }






        let enablePartialContent = false;


        console.log("QUILLSETUP:set contents 2 start");

        this.quillEditor.setContents(ops, "silent");
        ;
        this.quillEditor.history.clear();
        this.resizeEditorByHeight();
        console.log("QUILLSETUP:set contents 3 done");
        this.todoSync.isUpdating = false;
        this.applyingDeltas = false;
        console.log("QUILLSETUP:set contents 3.5 enable()");
        this.quillEditor.enable();

        console.log("QUILLSETUP:set contents 4 tag start");
        $(".ql-editor").css("display", "block");

        this.tags = this.getTags(); //this.quillEditor.getText());
        this.checkForPrivateTags(this.tags);
        ;
        if (inProcess == null) {
          this.todoSync.init(this.getTags.bind(this));
        }
        else {
          ///could be saving in the background
        }
        console.log("QUILLSETUP:set contents 5 tag end");
        this.props.connect.dispatch<any>(
          actions.ar_setNoteLoadingState(model.LoadingState.Completed)
        );
        this.startListenForChanges(this.noteObj.modified ? this.noteObj.modified : new Date());

        if (newCache) {
          this.quillEditor.focus();
          this.quillEditor.setSelection(0);
        }
        if (window["quillLoaded"]) {
          window["quillLoaded"](this.resizeEditorByHeight.bind(this));
        }
        var openPageTimestamp = Date.now();
        var me = this;
      } catch (e) {
        Track.reportError(
          e,
          "Error loading document",
          true,
          this.props.connect.dispatch
        );
      }
    }
  }

  handleThemeChange(newTheme) {
    if (newTheme === "core") newTheme = null;
    this.setState({ theme: newTheme });
  }
  getHeight(): string {
    return window.innerHeight - 20 + "px";
  }
  orginalPresentationMode: model.PresentationMode;
  render() {
    if (this.props.documentId) {

      let processTodoAction = async (e: any) => {
        try {

        } catch (error) {
          console.error(error)
        }
      }
      // Need to do this because of the toolbar can resize

      try {
        //  alert('render')
        console.log("RENDER");
        let me = this;
        return (
          <div id="scrolling-container">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",

              }}
            >
              {this.state.previewImage &&
                <Viewer
                  visible={true}
                  onClose={() => {
                    let s = { ...me.state };
                    s.previewImage = null;
                    me.setState(s);
                  }}
                  zIndex={900000}
                  images={[{ src: this.state.previewImage, alt: '' }]}
                />

              }
              <div id="editor" style={{ "maxWidth": "900px" }}>

              </div>
              <div id="todo-helper" className="todo-helper" onClick={async (e: any) => {
                try {
                  let todo = await this.props.connect.state.context.Todos.load($("#todo-helper").attr("data-id"))
                  if (todo) {
                    this.props.connect.dispatch<any>(actions.showTodoModal(todo));
                  }
                } catch (e) {
                  console.error(e)
                }

              }}>
                <Edit />
              </div>
            </div>
          </div>
        );


      } catch (e) {
        Track.reportError(
          e,
          "Error rending editor",
          true,
          this.props.connect.dispatch
        );
      }
    } else {
      return <div></div>;
    }
  }
}
