import * as React from "react";
import * as classNames from "classnames";
import * as material from "material-ui";
import * as quillComponents from "./QuillEditor";
import * as model from '../../lib/model';
import * as pApi from "../../../shared/papi/papi-core";
import MoveLeft from "material-ui/svg-icons/navigation/arrow-back";
import DeleteIcon from "material-ui/svg-icons/action/delete";
import * as actions from "../../lib/ActionReducer";
import NavigationClose from "material-ui/svg-icons/navigation/close";
import DownloadIcon from 'material-ui/svg-icons/navigation/arrow-downward'
import UndoIcon from "material-ui/svg-icons/content/undo";
import RedoIcon from "material-ui/svg-icons/content/redo";
import FullScreenIcon from "material-ui/svg-icons/navigation/fullscreen";
import PushPinOutlinedIcon from '@material-ui/icons/PinDropOutlined'
import PushPinSharpIcon from '@material-ui/icons/PinDropSharp'
import HashIcon from '../CustomSvg/Hash';
import FullScreenExitIcon from "material-ui/svg-icons/navigation/fullscreen-exit";
import { QuillEditor } from "./QuillEditor";

import AutoRenewIcon from "material-ui/svg-icons/action/autorenew";
import { connect } from "http2";
var uuid = require("uuid");
interface TodoTextInputProps {
  onSave: (text: string) => void;
  text?: string;
  placeholder?: string;
  editing?: boolean;
  newTodo?: boolean;
}
interface QuillContainerProps {
  noteChange: (text: string) => any;
  documentId: any;
  connect: model.ConnectProps;
}
enum MenuBarMode {
  fixed,
  hover,
  none
}
interface StateProps {
  presentationMode: model.PresentationMode;
  fullScreen: boolean
  menuMode: MenuBarMode;
  lastScrollTimeStamp: any;
  lastScrollY: any;
}
export class QuillContainer extends React.Component<
  QuillContainerProps,
  StateProps
> {
  constructor(props, context) {
    super(props, context);
    this.editorRef = React.createRef();
  }
  editorRef:any;
  componentWillMount() {
    this.componentWillReceiveProps(this.props);
    return true;
  }

  componentDidMount() {}
  componentWillReceiveProps(nextProps: QuillContainerProps) {
    var change = !this.state;
    if (change) {
      this.setState({
        presentationMode: this.props.connect.getPresentationMode(),
        menuMode: this.props.connect.inMobileNoteView()
          ? MenuBarMode.fixed
          : MenuBarMode.none,
        lastScrollTimeStamp: null,
        fullScreen: true,
        lastScrollY: 0
      });
    }
    return true;
  }
  onScroll(e) {
    // console.log(e.srcElement.scrollTop);
    let mode = this.state.menuMode;
    if (!this.props.connect.inMobileNoteView()) {
      mode = MenuBarMode.none;
    } else {
      if (e.srcElement.scrollTop < 40) {
        mode = MenuBarMode.fixed;
      } else {
        if (this.state.lastScrollY < e.srcElement.scrollTop) {
      
          actions.hideKeyboard();
          mode = MenuBarMode.none;
        } else {
          mode = MenuBarMode.hover;
        }
      }
    }
    this.setState({
      presentationMode: this.state.presentationMode,
      menuMode: mode,
      lastScrollY: e.srcElement.scrollTop,
      lastScrollTimeStamp: e.timeStamp,
      fullScreen: this.state.fullScreen
    });
    //console.log(e)
  }
  requestClose() {

    this.editorRef.current.flushChanged();
    /*
    
    if(this.props.connect.state.noteState == model.LoadingState.Loading)
    {
        let m = new model.ProgressState();
        m.text = "Saving Note";
        this.props.connect.dispatch<any>(actions.ar_setProgress(m));
        setTimeout(this.requestClose.bind(this),100);
    }
    else*/
    {
        if(this.props.connect.state.progressState)
          this.props.connect.dispatch<any>(actions.ar_setProgress(null));
        let vs = this.props.connect.state.viewState;
        if (vs.noteViewState) vs.noteViewState.noteId = null;
        window.location.hash = vs.getHash();
    }
  }
  async archiveNote(e) {
    e.preventDefault();
      e.stopPropagation(); 
   
    if (window.confirm(`Are you sure you want to archive this note?`)) {
      await this.props.connect.state.context.Notes.archive(
        this.props.documentId
      );
      let vs = this.props.connect.state.viewState;

      if (vs.noteViewState) vs.noteViewState.noteId = null;
      window.location.hash = vs.getHash();
    }
    return false;
  }
  fullScreen() {
    this.setState({
      fullScreen: !this.state.fullScreen,
      presentationMode: this.state.presentationMode,
      menuMode: this.state.menuMode,
      lastScrollY: this.state.lastScrollY,
      lastScrollTimeStamp: this.state.lastScrollTimeStamp
    });
  }
  turnOnTop(){
    this.props.connect.dispatch<any>(actions.ar_turnOnTop(null));
  }
  turnOffTop(){
    this.props.connect.dispatch<any>(actions.ar_turnOffTop(null));
  }
  downloadNote(){
   
    function download(filename, text) {
      var element = document.createElement('a');
      element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
      element.setAttribute('download', filename);
    
      element.style.display = 'none';
      document.body.appendChild(element);
    
      element.click();
    
      document.body.removeChild(element);
    }
    let markdown = this.editorRef.current.getMarkdown();
    let note = this.props.connect.state.notes.val().find(x=>x.id == this.props.documentId)
    download(note.title+'.md',markdown)
  }
  componentDidUpdate() {
    
    try
    {
    if (this.state.presentationMode != model.PresentationMode.mobile) {
      
      window["hackme"] = this.refs["hackme"];

      if (
        (window["hackme"] &&
          window["hackme"].parentNode &&
         ( this.state.fullScreen||
        window.innerWidth <= 720
      ))) {
        let height = window.outerHeight;// this.props.connect.state.windowHeight
        console.log('Window Outer' + height + '  '+ this.props.connect.state.windowHeight)
        //forgive me lord for i have sinned
        window["hackme"].parentNode.style.removeProperty("max-height");
        window["hackme"].parentNode.style.addProperty(
          "min-height",
          height
        );
        window["hackme"].parentNode.style.addProperty(
          "max-height",
          height
        );
      }
    }
  }catch(e)
  {
    console.warn(e)
  }
  }
  headerClick(e){
    e.stopPropagation();
    actions.hideKeyboard();
  }
  navBack(e) {
    var nv = this.props.connect.state.viewState.deepCopy();
    nv.noteViewState.noteId = null;
    nv.noteViewState.openFirstNote = false;
    if (
      this.props.connect.state.viewState.noteViewState.functionType ==
      model.NoteFunctionType.tag
    ) {
      nv.noteViewState.noteViewType = model.NoteViewType.noteList;
    }
    if (
      this.props.connect.state.viewState.noteViewState.functionType ==
      model.NoteFunctionType.all
    ) {
      nv.noteViewState.functionType = model.NoteFunctionType.all;
      nv.noteViewState.noteViewType = model.NoteViewType.noteList;
    }
    window.location.hash = nv.getHash();

    e.stopPropagation();
    actions.hideKeyboard();
  }
  render() {
    var toolbar = (
      <div
        id="toolbar"
        className="ql-small-toolbar"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        
        }}
      >
        <button className="ql-header" value="1" />
        <button className="ql-header" value="2" />
        <button className="ql-bold" />
        <button className="ql-italic" />
        <button className="ql-underline" />
        <button className="ql-strike" />
        <button className="ql-list" value="ordered" />
        <button className="ql-list" value="bullet" />
        <button className="ql-hash">
            <HashIcon/>
        </button>
        <button
          className="ql-list"
          value="unchecked"
          style={{
            "fontSize": "25px",
            "marginTop": "-4px",
            "fontWeight": "bold"
          }}
        >
          &#x2610;
        </button>
        <button className="ql-link" />
        <button className="ql-image" />
    
  
        <button
          className="ql-undo"
          value="undo"
          style={{
            "fontSize": "25px",
            "marginTop": "-4px",
            "fontWeight": "bold"
          }}
        >
          <UndoIcon />
        </button>
        <button
          className="ql-redo"
          value="redo"
          style={{
            "fontSize": "25px",
            "marginTop": "-4px",
            "fontWeight": "bold"
          }}
        >
          <RedoIcon />
        </button>
        <button className="ql-clean" />
      </div>
    );
    let quill = (
      <quillComponents.QuillEditor
        key="thequileditor"
        noteChange={() => {}}
        ref={this.editorRef}
        documentId={this.props.documentId}
        connect={this.props.connect}
        onScroll={this.onScroll.bind(this)}
        fullScreen={this.state.fullScreen}
        windowHeight={this.state.fullScreen ? window.innerHeight : null}
      
      />
    );
    if (this.state.presentationMode == model.PresentationMode.web) {
      var contentStyle = {};
      var style = {};
      var actionsContainerStyle: any = { padding: "0px" };
      if (this.state.fullScreen) {
        contentStyle = {
          width: "100%",
          
          "max-width": "100%",
          "margin-top": "-65px",
          padding: "0px",
          top: "0",
          position: "absolute",
    
        };
        actionsContainerStyle = {
          padding: "0px",
          "margin-top": this.props.connect.state.platform == pApi.Platform.ios? "-25px":"",
          
        };
        style = {
          padding: "0px",
       
        };
      }
      //this.props.connect.getPresentationMode()
    
      return (
        <material.Dialog
          key="editordialog"
          //@ts-ignore
          title="Dialog With Custom Width"
          titleStyle={{ display: "none" }}
          overlayStyle={actions.getOverlayStyle()}
          modal={false}
          ref="dialog"
          open={this.props.documentId}
          onRequestClose={this.requestClose.bind(this)}
          bodyStyle={{ padding: "0px", height: "100%" }}
          actions={[toolbar]}
          contentStyle={contentStyle}
          style={style}
          repositionOnUpdate={!this.state.fullScreen}
          autoDetectWindowHeight={!this.state.fullScreen}
          actionsContainerStyle={actionsContainerStyle}
        >
          <div ref="hackme" style={{"fontSize":14}} >
            <div className="notebuttons">
            {this.props.connect.state.noteState ==
                model.LoadingState.Loading && (
                           <material.IconButton>
                  <AutoRenewIcon className="ql-processing" color="lightgray" />
                </material.IconButton>
              )}
              {this.props.connect.state.canAlwaysTop && !this.props.connect.state.alwaysTopOn &&  (
                  <material.IconButton onClick={this.turnOnTop.bind(this)}>
                    < PushPinOutlinedIcon />
                  </material.IconButton>   
              )}
               {this.props.connect.state.canAlwaysTop && this.props.connect.state.alwaysTopOn &&  (
                  <material.IconButton onClick={this.turnOffTop.bind(this)}>
                    < PushPinSharpIcon />
                  </material.IconButton>   
              )}
               <material.IconButton onClick={this.downloadNote.bind(this)}>
                <DownloadIcon />
              </material.IconButton>
              <material.IconButton onClick={this.archiveNote.bind(this)}>
                <DeleteIcon />
              </material.IconButton>
              {this.state.fullScreen && (
                <material.IconButton onClick={this.fullScreen.bind(this)}>
                  <FullScreenIcon />
                </material.IconButton>
              )}
              {!this.state.fullScreen && (
                <material.IconButton onClick={this.fullScreen.bind(this)}>
                  <FullScreenIcon />
                </material.IconButton>
              )}
              <material.IconButton onClick={this.requestClose.bind(this)}>
                <NavigationClose />
              </material.IconButton>
            </div>
            <div style={{fontSize:"18px"}}>
            <quillComponents.QuillEditor
              key="thequileditor"
              noteChange={() => {}}
              ref={this.editorRef}
              documentId={this.props.documentId}
              connect={this.props.connect}
              onScroll={this.onScroll.bind(this)}
              fullScreen={this.state.fullScreen}
              windowHeight={this.state.fullScreen ? window.innerHeight : null}
             
            />
            </div>
          </div>
        </material.Dialog>
      );
    }
    let windowClass = "quill-mobile-window ";
    windowClass += this.props.connect.state.viewState.noteOpen()
      ? "quill-mobile-window-active "
      : "";
    var style = {};
    if(this.props.connect.state.viewState.todoId)
    {
       style = {'display':'none'};
    }
    return (
      <section style={style} className={windowClass + "box editorArea"}>
        <div>
          <section
            className="quill-mobile-header"
            style={{ "backgroundColor": "rgba(255,255,255,0.8)" }}
            onClick={this.headerClick.bind(this)}
          >
            <div className="float-left">
              <material.IconButton onClick={this.navBack.bind(this)}>
                <MoveLeft />
              </material.IconButton>
            </div>
            <div className='float-right'>
            {this.props.connect.state.canAlwaysTop && !this.props.connect.state.alwaysTopOn &&  (
                  <material.IconButton onClick={this.turnOnTop.bind(this)}>
                    < PushPinOutlinedIcon />
                  </material.IconButton>   
              )}
               {this.props.connect.state.canAlwaysTop && this.props.connect.state.alwaysTopOn &&  (
                  <material.IconButton onClick={this.turnOffTop.bind(this)}>
                    < PushPinSharpIcon />
                  </material.IconButton>   
              )}
            <material.IconButton onClick={this.archiveNote.bind(this)}>
                <DeleteIcon />
              </material.IconButton>
            </div>
          </section>
        </div>
        <div style={{fontSize:"1.2em"}}>
          {this.props.connect.state.viewState.noteOpen() && (
            <quillComponents.QuillEditor
              key="thequileditor2"
              noteChange={() => {}}
              documentId={this.props.documentId}
              connect={this.props.connect}
              onScroll={this.onScroll.bind(this)}
              fullScreen={this.state.fullScreen}
              windowHeight={window.innerHeight}
             
            />
          )}
        </div>
        {this.props.connect.state.viewState.noteOpen() &&
          this.state.presentationMode == model.PresentationMode.mobile && (
            <div id="toolbar" className="ql-small-toolbar"
            style={ {display: "flex",
            alignItems: "center",
            justifyContent: "center"}}>
              <button className="ql-header" value="1" />
              <button className="ql-header" value="2" />
           
        
              <button className="ql-list" value="bullet" />
              <button className="ql-hash">
            <HashIcon/>
        </button>
              <button
                className="ql-list joyride-todo"
                value="unchecked"
                style={{
                  "fontSize": "25px",
                  "marginTop": "-4px",
                  "fontWeight": "bold"
                }}
              >
                &#x2610;
              </button>
              <button className="ql-image" />
              <button
                className="ql-undo"
                value="undo"
                style={{
                  "fontSize": "25px",
                  "marginTop": "-4px",
                  "fontWeight": "bold"
                }}
              >
                <UndoIcon />
              </button>
              <button className="ql-indent" value="-1" />
              <button className="ql-indent" value="+1" />
            </div>
          )}
        
      </section>
    );
  }
}
