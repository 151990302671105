import * as Colors  from 'material-ui/styles/colors';
import {fade} from 'material-ui/utils/colorManipulator';
import spacing from 'material-ui/styles/spacing';

// $dark:                  #333C44 !default; // darken Blue 100 > #343E46
// $theme_dark_text_color:       rgba(255,255,255,.7);

export default {
  spacing,
  fontFamily: 'Roboto, sans-serif',
 
  palette: {
    "primary1Color": "#311b92",//Colors.purple800,
        "accent1Color": Colors.red700
  },
};
