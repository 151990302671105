import * as React from "react";
import * as material from "material-ui";
import * as model from "../../lib/model";
import { light as lightTheme, dark as darkTheme } from "./theme";
import * as pApi from "../../../shared/papi/papi-core";
import * as hotkey from "../../lib/hotkeyClient";
import convertDown from '../../../shared/markdown/fromDelta'
import DeleteIcon from "material-ui/svg-icons/action/delete";
import * as actions from "../../lib/ActionReducer";
import NavigationClose from "material-ui/svg-icons/navigation/close";
import DownloadIcon from 'material-ui/svg-icons/navigation/arrow-downward'
import FullScreenIcon from "material-ui/svg-icons/navigation/fullscreen";

//import Node from "rich-markdown-editor/dist/nodes/Node"
import AutoRenewIcon from "material-ui/svg-icons/action/autorenew";
import ModifiedEditor, { Extension } from "rich-markdown-editor"
import TagTrigger from "./TagTrigger"

//import CheckboxItem, {CheckboxList} from './Nodes/CheckboxItem'




const savedText = localStorage.getItem("saved");
const exampleText = `
# Welcome

This is example content. It is persisted between reloads in localStorage.
`;
const defaultValue = savedText || exampleText;
interface ProseEditorProps {
    documentId: any;
    connect: model.ConnectProps;
}
interface ProseEditorState {
    value: any;
    fullScreen: boolean;
    note: model.LoadingObject<pApi.Note>;
    documentId: any;
}
export class ProseEditor extends React.Component<
    ProseEditorProps,
    ProseEditorState
    > {
    constructor(props, context) {
        super(props, context);

        this.state = {
            value: defaultValue,
            fullScreen: true,
            note: new model.LoadingObject<pApi.Note>(),
            documentId: null
        }

    }
    editorRef: any;
    handleChange() {

    }
    downloadNote() {

    }
    archiveNote() {

    }
    fullScreenToggle() {

    }
    requestClose() {

    }
    getMarkdown(ops: Array<any>) {
        let array = []
        for(var i in ops)
        {
            array.push(ops[i])
        }
        return convertDown(array)
    }

    loadDocument(documentId: any) {
        let me = this;
        return new Promise<void>(async (resolve, reject) => {
           
            try {
              
                let note = await this.props.connect.state.context.Notes.load(documentId);
             
                let content = await this.props.connect.state.context.Notes.getContent(note);
                let md = ""
                ;
                switch (content.contentType) {
                    case (pApi.ContentType.quillJS):
                        md = this.getMarkdown(content.content);
                        break;
                    case (pApi.ContentType.markdown):
                        md = content.content[0];
                        break;
                }
                let s = { ...this.state };
                s.value = md;
              
                s.note.setCompleted(note);
                s.documentId = note.id;
                me.setState(s);
                resolve();
            }
            catch (e) {
                let s = { ...this.state };
                s.note.setError(e);
                this.setState(s);
                reject(e);
            }

        })
    }
    componentWillReceiveProps(nextProps: ProseEditorProps) {

        let me = this;
        let currentDocumentId = this.state.documentId;
        var change = !this.state || currentDocumentId != nextProps.documentId;

        if (change && nextProps.documentId) {
            let s = { ...this.state };
            s.documentId = nextProps.documentId;

            this.setState(s);
            setTimeout(()=>{me.loadDocument(s.documentId)},0)
            return true;

        }
        else {
            return false;
        }
    }
    render() {
        const { body } = document;

        let loadedDocumentId = this.state.note.val() ? this.state.note.val().id : "Nope"
        if (!this.props.documentId || !this.state || this.state.documentId != loadedDocumentId) {
            return (<div></div>)
        }
        console.log('render')
        let editor = (
            <div style={{
                "height": "100%", "overflow": "scroll", paddingTop: "20px",
                paddingLeft: "40px",
                paddingRight: "40px",
                alignItems: "center",
                justifyContent: "center",
            }}>
                <div className="notebuttons">
                    {this.props.connect.state.noteState ==
                        model.LoadingState.Loading && (
                            <material.IconButton>
                                <AutoRenewIcon className="ql-processing" color="lightgray" />
                            </material.IconButton>
                        )}
                    <material.IconButton onClick={this.downloadNote.bind(this)}>
                        <DownloadIcon />
                    </material.IconButton>
                    <material.IconButton onClick={this.archiveNote.bind(this)}>
                        <DeleteIcon />
                    </material.IconButton>
                    {this.state.fullScreen && (
                        <material.IconButton onClick={this.fullScreenToggle.bind(this)}>
                            <FullScreenIcon />
                        </material.IconButton>
                    )}
                    {!this.state.fullScreen && (
                        <material.IconButton onClick={this.fullScreenToggle.bind(this)}>
                            <FullScreenIcon />
                        </material.IconButton>
                    )}
                    <material.IconButton onClick={this.requestClose.bind(this)}>
                        <NavigationClose />
                    </material.IconButton>
                </div>


                <ModifiedEditor
                    id="example"
                    ref="editor"
                    theme={lightTheme}
                    extensions={[((new TagTrigger()) as unknown) as Extension, 
                     ]}
                    readOnly={false}
                    value={this.state ? this.state.value : ""}
                    defaultValue={this.state.value}
                    onSave={options => console.log("Save triggered", options)}
                    onCancel={() => console.log("Cancel triggered")}
                    onChange={this.handleChange.bind(this)}
                    onClickLink={href => console.log("Clicked link: ", href)}
                    onClickHashtag={tag => console.log("Clicked hashtag: ", tag)}
                    onShowToast={message => window.alert(message)}
                    onSearchLink={async term => {
                        console.log("Searched link: ", term);
                        return [
                            {
                                title: term,
                                url: "localhost",
                            },
                        ];
                    }}
                    uploadImage={file => {
                        console.log("File upload triggered: ", file);

                        // Delay to simulate time taken to upload
                        return new Promise(resolve => {
                            setTimeout(
                                () => resolve("https://loremflickr.com/1000/1000"),
                                1500
                            );
                        });
                    }}

                    dark={false}
                    autoFocus
                />
            </div>
        )
        return (<material.Dialog
            key="editordialog"
            //@ts-ignore
            title="Dialog With Custom Width"
            titleStyle={{ display: "none" }}
            modal={false}
            ref="dialog"
            open={this.props.documentId}
            bodyStyle={{ padding: "0px", height: "100%" }}

        >

            <div
                style={{

                    height: "100vh"
                }} >

                {editor}



            </div>


        </material.Dialog>)

    }
}

