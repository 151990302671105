import * as React from "react";

import * as model from '../lib/model';
import * as actions from '../lib/ActionReducer'
import { connect } from "react-redux";

import * as material from "material-ui";
//var firebaseui = require("../../../../node_modules/firebaseui-hack");

import { PublicHeader } from "./PublicHeader";
import { withRouter } from "react-router-dom";
import { setTimeout } from "timers";  
import firebase from "firebase/app"
//var firebase = require("firebase");
var firebaseui = require("firebaseui");
const Section = () => <div />;
var $ = require("jquery");
var _ = require("lodash"); 
var classnames = require("classnames");
interface LoginState {
  startedAuth: boolean;
}

class Login extends React.Component<model.ConnectProps, LoginState> {
  constructor(props: model.ConnectProps) {
    super(props);
  }
  componentDidMount() {
    this.setState({
      startedAuth: false
    });
  }
  componentWillMount() {
    this.componentWillReceiveProps(this.props);
    return true;
  }
  componentWillReceiveProps(nextProps: model.ConnectProps) {
    // var dispatchActions =actions;//

    //if (this.state && this.state.startedAuth) return false;
    /* if (nextProps.state.AuthState == model.LoadingState.Loading) {
      this.props.state.context.dispatch(
        actions.startApp(this.props.state.context.dispatch)
      );
      return;
    }*/
    this.setState({
      startedAuth: true
    });

    return true;
  }
  hasSetup: boolean = false;
  render() {
    var nv = model.ViewState.viewStateFromRoute(this.props.match);
    if (window["hidesplash"] && !window["hidesplash-login"]) {
      try {
        window["hidesplash"]();
        window["hidesplash-login"] = true;
      } catch (e) {
        console.error("hide splash login " + e);
      }
    }
    var setupAuth = function() {
    // The Firebase Auth instance.

      var ui = new firebaseui.auth.AuthUI(firebase.auth());

      //let x = firebaseui.auth.widget.dispatcher.getRedirectUrl
      let signinFlow = window["ios"] ? "redirect" : "redirect"; //"popup";
      let signInSuccessUrl = "/" + nv.redirect ? nv.redirect : "";

      // let signInSuccessUrl = '/'+nv.redirect;
      var uiConfig = {
        callbacks: {
          signInSuccess: function(currentUser, credential, redirectUrl) {
            // User successfully signed in.
            // Return type determines whether we continue the redirect automatically
            // or whether we leave that to developer to handle.

            window.location.hash = "#/inbox";
            return true;
          },
          uiShown: function() {
            // The widget is rendered.
            // Hide the loader.
            document.getElementById("loader").style.display = "none";
          }
        },

        // Will use popup for IDP Providers sign-in flow instead of the default, redirect.
        signInFlow: signinFlow,
        signInSuccessUrl: signInSuccessUrl,
        credentialHelper: firebaseui.auth.CredentialHelper.GOOGLE_YOLO,

        /*credentialHelper:firebaseui.auth.CredentialHelper.GOOGLE_YOLO,*/

        signInOptions: [
          // Leave the lines as is for the providers you want to offer your users.
          /*  firebase.auth.GoogleAuthProvider.PROVIDER_ID,*/
          firebase.auth.EmailAuthProvider.PROVIDER_ID
        ],
        // Terms of service url.
        tosUrl: "https://www.todozero.com/terms-and-conditions"
      };

      ui.start("#firebaseui-auth-container", uiConfig);
      window["authui"] = ui;
    };
   
    if (!this.hasSetup) {
      _.defer(setupAuth);
      this.hasSetup = true;
    }
    function iOS() {
      var iDevices = [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod"
      ];

      if (!!navigator.platform) {
        while (iDevices.length) {
          if (navigator.platform === iDevices.pop()) {
            return true;
          }
        }
      }

      return false;
    }
    
    return (
      <div id="app-inner">
        <div className="preloaderbar hide">
          <span className="bar" />
        </div>
        <div
          style={{ overflow: "hidden" }}
          className={classnames("app-main full-height fixed-header ", {
            "fixed-header": this.props.state.uiConifg.layout.fixedHeader,
            "nav-collapsed": this.props.state.uiConifg.layout.navCollapsed,
            "nav-behind": this.props.state.uiConifg.layout.navBehind,
            "layout-boxed": this.props.state.uiConifg.layout.layoutBoxed,
            "theme-gray": this.props.state.uiConifg.layout.theme === "gray",
            "theme-dark": this.props.state.uiConifg.layout.theme === "dark",
            "sidebar-sm":
              this.props.state.uiConifg.layout.sidebarWidth === "small",
            "sidebar-lg":
              this.props.state.uiConifg.layout.sidebarWidth === "large"
          })}
        >
          <div className="main-app-container">
            <section id="page-container" className="app-page-container">
              <PublicHeader uiConfig={this.props.state.uiConifg} />

              <div
                className="g-signin2"
                data-onsuccess="onSignIn"
                data-theme="dark"
              />
              <div className="login-area mobile-login mobile-scroll">
                <div className="">
                  <div id="firebaseui-auth-container" />
                  <div id="loader" />
                </div>
                {iOS() && !window["ios"] && (
                  <div style={{ textAlign: "center" }}>
                    <br />
                    <a
                      href="https://click.google-analytics.com/redirect?tid=UA-92222572-4&url=https%3A%2F%2Fitunes.apple.com%2Fus%2Fapp%2Fnotedojo%2Fid1377929655%3Fmt%3D8&aid=com.notedojo.app.manual&idfa={idfa}&cs=google&cm=cpc&anid=admob&hash=md5"
                      style={{
                        display: "inline-block",
                        overflow: "hidden",
                        background:
                          "url(https://linkmaker.itunes.apple.com/assets/shared/badges/en-us/appstore-lrg.svg) no-repeat",
                        width: "135px",
                        height: "40px",
                        backgroundSize: "contain"
                      }}
                    />
                    <br />
                    <div style={{ padding: "15px" }}>
                      Looks like your are on an iPhone. Download the our iPhone
                      app for a faster expereince.
                    </div>
                  </div>
                )}
              </div>
            </section>
          </div>
        </div>
      </div>
    );
  }
}

//export default fakeIt() as TodoItem;//
const mapStateToProps = function(thestate) {
  var state = thestate;

  return {
    state: state
  };
};


export default withRouter(connect(mapStateToProps)(Login));
