

import * as model from "../lib/model";
import * as pApi from "../../shared/papi/papi-core";
var algoliasearch = require("algoliasearch");


export class SearchResult {
    todoMatch: Array<pApi.ToDoItem>;
    tagResults: Array<string>;
    noteMatch: model.LoadingObject<Array<pApi.Note>>;
    orderBy: Array<string>;
    searchText: string
}

export function doSearch(connect: model.ConnectProps, searchText: string): Promise<SearchResult> {
    return new Promise<SearchResult>(async (resolve, reject) => {
        try {
            function finishSearch(
                todoMatch: Array<pApi.ToDoItem>,
                noteMatch: Array<pApi.Note>,
                orderBy: Array<string>,
                searchText: string
            ) {

                var tagResults = new Array<string>();
                for (var i in connect.state.tags) {
                    var t = connect.state.tags[i];
                    if (
                        searchText &&
                        t &&
                        t.name &&
                        t.name.toLocaleLowerCase().indexOf(searchText.toLowerCase()) > -1
                    ) {
                        tagResults.push(t.name);
                    }
                }
                let notematchLoadingObject = new model.LoadingObject<Array<pApi.Note>>();
                notematchLoadingObject.setCompleted(noteMatch);
                let sr = new SearchResult();
                sr.noteMatch = notematchLoadingObject;
                sr.todoMatch = todoMatch;
                sr.tagResults = tagResults;
                sr.searchText = searchText;
                sr.orderBy = orderBy;
                
                resolve(sr)


            }

            let todoMatch = new Array<pApi.ToDoItem>();
            let noteMatch = new Array<pApi.Note>();
            let orderBy = new Array<string>();
            if (!connect.state.isOnline) {
                if (connect.state.TodosState == model.LoadingState.Completed) {
                    let todos = await connect.state.todos;
                    for (var i in todos) {
                        let todo = todos[i];
                        if (
                            todo.description &&
                            todo.description
                                .toLowerCase()
                                .indexOf(searchText.toLocaleLowerCase()) != -1
                        ) {
                            todoMatch.push(todo)
                            orderBy.push('todo:' + todo.id);
                            /*
                            let ri = new model.SearchResultItem();
        
                            ri.type = "todo";
                            ri.modified = todo.modified;
                            ri.id = todo.id;
                            ri.tags = todo.tags;
                            ri.title = todo.description;
                            ri.sort = new Date().getTime() - todo.modified.getTime();
                            ri.todo = todo;
                            searchResults.push(ri);*/
                        }
                    }
                }
                if (
                    connect.state.notes.completed()
                ) {
                    let notes = connect.state.notes.val();
                    for (var i in notes) {
                        let note = notes[i];
                        var text = note.title ? note.title.toLocaleLowerCase() : "";
                        text += note.preview_text
                            ? note.preview_text.toLocaleLowerCase()
                            : "";
                        if (text.indexOf(searchText.toLocaleLowerCase()) != -1) {
                            noteMatch.push(note);
                            orderBy.push('note:' + note.id);
                            /*
                            let ri = new model.SearchResultItem();
                            ri.type = "note";
                            //if( note.modified is DateTime)
                            ri.modified = note.modified;// ? moment(note.modified).calendar() : "";
                            ri.id = note.id;
                            ri.tags = note.tags;
                            ri.title = note.title ? note.title : "Untitled";
                            ri.description = '';//note.preview_text ? note.preview_text : "";
                            ri.note = note;
                            ri.sort = note.modified
                                ? new Date().getTime() - note.modified.getTime()
                                : 999999999;*/
                            //searchResults.push(ri);
                        }
                    }
                }
                finishSearch(todoMatch, noteMatch, orderBy, searchText);
            } else {
                var datasource = [];

                var client = algoliasearch(
                    "642UGTREEM",
                    connect.state.searchKey
                );
                var query = searchText;
                var queries = [
                    {
                        indexName: "notes",
                        distinct: true,
                        query: query.trim(),
                        filters:
                            'owner_uid:"' + connect.state.context.getId() + '"',
                        params: {
                            hitsPerPage: 25,
                            distinct: true
                        }
                    },
                    {
                        indexName: "todos",
                        query: query.trim(),
                        distinct: true,
                        filters:
                            'owner_uid:"' + connect.state.context.getId() + '"',
                        params: {
                            hitsPerPage: 10,
                            distinct: true
                        }
                    }
                ];

                // perform 3 queries in a single API call:
                //  - 1st query targets index `categories`
                //  - 2nd and 3rd queries target index `products`
                var me = this;
                client.search(queries, { distinct: true }, function searchCallback(
                    err,
                    content
                ) {
                    if (err) throw err;
                    var rank = 100;
                    var searchResults = Array<model.SearchResultItem>();
                    for (var i in content.results) {
                        let result = content.results[i];
                        let type = result.index;
                        let sort = 1;

                        for (var ii in result.hits) {
                            var hit = result.hits[ii];

                            switch (type) {
                                case "todos":
                                    var todo = connect.state.todos.find(
                                        x => x.id == hit.todo_id
                                    );
                                    if (todo) {
                                        todoMatch.push(todo);
                                        orderBy.push('todo:' + todo.id);
                                    }
                                    /*
                                    let ri = new model.SearchResultItem();
                                    ri.type = "todo";
                                    ri.modified = todo.modified;
                                    ri.id = todo.id;
                                    ri.tags = todo.tags;
                                    ri.title = todo.description;
                                    ri.sort = sort++;
                                    ri.todo = todo;
                                    searchResults.push(ri);*/
                                    break;
                                case "notes":
                                    let note = connect.state.notes.val().find(x => x.id == hit.note_id);
                                    if (note) {
                                        noteMatch.push(note);
                                        orderBy.push('note:' + note.id);
                                    }
                                    else {


                                    }
                                /*
                                let ri = new model.SearchResultItem();
                                ri.type = "note";
                                ri.modified = hit.modified
                                    ? moment(hit.modified).calendar()
                                    : "";
                                ri.id = hit.note_id;
                                ri.tags = hit.tags;
                                ri.title = hit.title ? hit.title : "Untitled";
                                ri.description = hit.text ? hit.text : "";
                                ri.sort = sort++;
                                let note = new pApi.Note();
                                note.id = hit.note_id;
                                note.tags = hit.tags;
                                note.title =hit.title ? hit.title : "Untitled";
                                note.preview_text =  hit.text ? hit.text : "";
                                ri.note =note;
                                if(ri.id)
                                    searchResults.push(ri);
                                */
                            }
                        }
                    }
                    finishSearch(todoMatch, noteMatch, orderBy, searchText);
                });
            }
        }
        catch (e) {
            console.log(e)
            reject(e);
        }
    })
}
