import * as React from "react";
import * as material from "material-ui";
import * as model from "../../lib/model"

import * as actions  from "../../lib/ActionReducer";

import * as pApi from '../../../shared/papi/papi-core'
import TagNodeView from "./TagNodeView";
let _ = require("lodash");
var $ = require("jquery");
var classnames = require("classnames");
interface NoteSidebarContentProps {
  connect: model.ConnectProps;
  fullWidth?:boolean;
  selectedTag: string;
  onClick: (node: TagNode) => void;
}
interface SidebarState {
  lastTag: Array<pApi.Tag>;
  tree:any;
}
export class TagNode {
  tagName: string;
 
  children: Array<TagNode>;
  selected: boolean;
  parentNode: TagNode;
  getTagPath(): string {
    if (!this.parentNode) {
      return this.tagName ? this.tagName : "";
    } else {
      if (this.parentNode.tagName)
        return this.parentNode.getTagPath() + "/" + this.tagName;
      else return this.tagName;
    }
  }
}

export class NoteSidebarContent extends React.Component<
  NoteSidebarContentProps,
  SidebarState
> {
  nav: any;
  getTags(): Array<string> {
    var retval = new Array<string>();
    for (var i in this.props.connect.state.tags) {
      retval.push(this.props.connect.state.tags[i].name);
    }
    console.log("tags " + retval.length);
    return retval.sort();
    /* var arr = new Array<string>();
     arr.push('test');
     arr.push('test/test2')
     arr.push('test/test3')
     arr.push('B/B1')
     arr.push('B/B1')
     arr.push('B/B1/B4/B5');
     arr.push('C');
     arr.push('x/1/2/3/4/5');

     return arr;*/
  }

  constructor(props, context) {
    super(props, context);
  }
  componentWillMount() {
    this.componentWillReceiveProps(this.props);
    return true;
  }
  componentWillReceiveProps(nextProps: NoteSidebarContentProps) {
    
    if (nextProps.connect.state.TagsState == model.LoadingState.NotLoaded) {
      //this.props.connect.dispatch<any>(actions.ar_loadTags());
    }
    this.setState({
      lastTag: nextProps.connect.state.tags
    });
    return true;
  }
shouldComponentUpdate(nextProps:NoteSidebarContentProps){
  let receiveProps = true;
  
    if (nextProps.connect.state.tags && nextProps.connect.state.tags.length > 0 ) {
    
      receiveProps = !this.state.lastTag || 
        _.difference(this.state.lastTag, nextProps.connect.state.tags).length >
        0 
      
    }
   
    return receiveProps;
}

  findInTree(id: any, tree: any): any {
    if (!tree) return null;
    for (var i in tree) {
      if (tree[i]) {
        if (tree[i].id == id) return tree[i];
        for (var ii in tree[i].children) {
          var inTree = this.findInTree(id, tree[i].children[ii]);
          if (inTree) return inTree;
        }
      }
    }

    return null;
  }
  addNode(tag: string, tree: TagNode): void {
    var slackIndex = tag.indexOf("/");
    var children = [];

    if (slackIndex == -1) {
      var tagNode = new TagNode();
      tagNode.tagName = tag;

      tagNode.children = new Array<TagNode>();
      tagNode.selected = this.props.selectedTag == tag;
      tagNode.parentNode = tree;
      tree.children.push(tagNode);
      return;
    }
    var tagName = tag.substr(0, tag.indexOf("/"));
    var tagNode = tree.children.find(x => x.tagName == tagName);
    if (!tagNode) {
      var tagNode = new TagNode();
      tagNode.tagName = tagName;

      tagNode.children = new Array<TagNode>();
      tagNode.selected = this.props.selectedTag == tag;
      tagNode.parentNode = tree;
      tree.children.push(tagNode);
    }
    this.addNode(tag.substr(tag.indexOf("/") + 1), tagNode);
  }
  buildTree(): Array<TagNode> {
   
    console.log("build tree");
    const data = {
      name: "",
      toggled: true,
      children: []
    };

    var allTags = this.getTags();
    var Root = new TagNode();
    Root.children = new Array<TagNode>();
    for (var i in allTags) {
      this.addNode(allTags[i], Root);
    }

    //data = children;
  /*  this.setState({
      tree: Root.children,
      lastTag:this.state.lastTag
    })*/
    //this.state["tree"] = Root.children;
    return Root.children;
  }
  /*
  generateLinks(params: generateLinksParams): any {
    var me = this;
    var nestedItems = [];
    for (var i in params.node.children) {
      nestedItems.push(
        <params.generateLinks
          node={params.node.children[i]}
          key={"likch-" + params.node.tagName + "-" + i}
          onClick={e => {
            params.onClick(e);
          }}
          generateLinks={params.generateLinks}
        />
      );
    }
    return {
      render: () => (
        <material.ListItem
          primaryText={params.node.tagName}
          key={"lik-" + params.node.tagName + "-" + params.count}
          onClick={e => {
            //HHAAACCKKK@!!!!!

            if (e.target["tagName"] != "path" && e.target["tagName"] != "svg") {
              params.onClick(params.node);
            }
          }}
          onNestedListToggle={(e: any) => {}}
          leftIcon={<Note />}
          nestedListStyle={{
            "padding-left": "10px"
          }}
          nestedItems={nestedItems}
        />
      )
    };
  }*/
  state: any = {};
  render() {
    if (this.props.connect.state.TagsState == model.LoadingState.Completed) {
      var tree = this.buildTree();
      let style = {};
      if(this.props.fullWidth)
      {
        style = {width:'100%'}
      }
      var me = this;
      return (
        <div className="tag-bar" style={style}>
          <material.List>
            {tree.map(node => (
              <div key={node.getTagPath()}>
                <TagNodeView
                  connect={this.props.connect}
                  node={node}
                  onClick={(e:any) => {
                    this.props.onClick(e);
                  }}
                />
              </div>
            ))}
          </material.List>
        </div>
      );
    } else return <div />;
  }
}

//export  SidebarContent;

//module.exports = SidebarContent;
