export default  [
  { "insert": "Organize with Tags" },
  { "attributes": { "header": 1 }, "insert": "\n" },
  {
    "insert": {
      "image": {
        "external-src":
          "welcome/tags.png",
        "src":
            "welcome/tags.png"
      }
    }
  },
  { "insert": "\n\n" },
  {
    "attributes": { "background": "transparent", "color": "#000000" },
    "insert":
      "Tags are a powerful way to organize your Notes and to-dos.  Any note or to-do can contain as many tags as you want. To add a tag to a note, simply type # followed by a word, such as,  "
  },
  {
    "attributes": { "background": "transparent", "color": "#000000" },
    "insert": { "mention": { "id": "welcome", "value": "welcome" } }
  },
  {
    "attributes": { "background": "transparent", "color": "#000000" },
    "insert": "  .Voilà, the Tag will show up in the left navigation."
  },
  { "insert": "\n\nCreate a tag to organize notes and to-dos" },
  {
    "attributes": {
      "list": {
        "checked": false,
        "id": "$$id1",
        "mode": "none",
        "priority": "0"
      }
    },
    "insert": "\n"
  },
  { "insert": "\n" },
  {
    "attributes": {
      "background": "transparent",
      "bold": true,
      "color": "#000000"
    },
    "insert": "Other things to keep in mind around tags:"
  },
  { "insert": "\n" },
  {
    "attributes": { "background": "transparent" },
    "insert": "You can add a Tag anywhere on a Note"
  },
  { "attributes": { "list": "bullet" }, "insert": "\n" },
  {
    "attributes": { "background": "transparent" },
    "insert":
      "All To-Dos in a Note will be assigned to all the Tags in the Note."
  },
  { "attributes": { "list": "bullet" }, "insert": "\n" },
  {
    "attributes": { "background": "transparent" },
    "insert":
      "When viewing your To-Dos in a list view (like your inbox) they will be grouped by the first Tag in the Note."
  },
  { "attributes": { "list": "bullet" }, "insert": "\n" },
  {
    "attributes": { "background": "transparent", "color": "#000000" },
    "insert": " "
  },
  { "insert": "\n" },
  {
    "attributes": { "background": "transparent", "color": "#434343" },
    "insert": "Tag Folders"
  },
  { "attributes": { "header": 3 }, "insert": "\n" },
  {
    "attributes": { "background": "transparent", "color": "#000000" },
    "insert":
      "You can organize your Tags in a hierarchy (think folders) by separating a tag with a \"/\" (for example "
  },
  {
    "attributes": { "background": "transparent", "color": "#000000" },
    "insert": { "mention": { "id": "welcome/tags", "value": "welcome/tags" } }
  },
  {
    "attributes": { "background": "transparent", "color": "#000000" },
    "insert": ") . You can have as many nested tags as you like. "
  },
  { "insert": "\n\n\n\n\n" }
]
