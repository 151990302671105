
import * as React from 'react';
import * as model from "../../lib/model"
import * as pApi from '../../../shared/papi/papi-core'
import SuperList from '../../SuperList/SuperList'
interface ArchiveProps
{
    connect: model.ConnectProps;
}
interface ArchiveState
{
    todos:model.LoadingObject<Array<pApi.ToDoItem>>;
}
class Archive extends React.Component<ArchiveProps, ArchiveState> {

    constructor(props: ArchiveProps, context) {
       
        super(props, context);
        this.state = {
            todos:new model.LoadingObject<Array<pApi.ToDoItem>>(null)
        }
      
    }
    componentDidMount() {
     
        var me = this;
        let f = async function()
        {
            try
            {
               let todos =  await  me.props.connect.state.context.Todos.getClosedItems(null);
               let s= {...me.state};
               s.todos.setCompleted(todos)
                me.setState(s);
            }
            catch(e)
            {
                let s= {...me.state};
                s.todos.setError(e);
                me.setState(s);
            }
        }
        if(this.state.todos.notLoaded())
        {
            f();
        }
        
      }
    render()
    {
      
        if(this.state.todos.loading() || this.state.todos.notLoaded())
        {
            return (<div>Loading...</div>)
        }
        if(this.state.todos.error())
        {
            return (<div>Error : {this.state.todos.errorMessage}  </div>)
        }
    
        return (
         
            <div style={{ width: '100%' }}>
                  <SuperList  
                todos= {this.state.todos.val()}
                todoQueryId= {this.state.todos.version}
                notes={new model.LoadingObject<Array<pApi.Note>>([],'empty')}
                tag={null}
                mode={model.InboxMode.archive}
                connect={this.props.connect}
                sortByCreateDate={this.props.connect.state.viewState.sortByCreateDate}
                mobileView={this.props.connect.getPresentationMode() == model.PresentationMode.mobile} />
            </div>
        )
    }

}

export default Archive;