import * as React from 'react';

import * as model from "../lib/model";
import { TodoTagSelector } from './ListItem'
import * as sl from "../../shared/lib/superListTypes";

import moment from 'moment'

export interface NoteItemProps {
  connect: model.ConnectProps;
  row: sl.NoteRow;
  focused: boolean;
  selected: boolean;
  mobileView: boolean;
}
export interface NoteItemState {
  hovering: boolean;
}

export class NoteItem extends React.Component<NoteItemProps, NoteItemState> {
  constructor(props, context) {
    super(props, context);
    this.state = {
      hovering: false
    }
  }
  onMouseEnter(e) {
    console.log("enter");
    // if (this.state.snoozeMenu)
    //    return;
    //  this.checkAndUpdateStateBackedOnPosition(e);
    let state = { ...this.state };
    state.hovering = true;
    this.setState(state);
  }
  onClick(e) {

    e.preventDefault();
    e.stopPropagation();
    var nv: model.ViewState = this.props.connect.state.viewState;
    if (!nv.noteViewState) {
      nv.noteViewState = new model.NoteViewState();
      if (this.props.row.note.tags.length > 0) {
        nv.noteViewState.query = this.props.row.note.tags[0];
      }

    }
    nv.appSection = model.AppSections.notes
    nv.noteViewState.noteId = this.props.row.note.id;
    nv.noteViewState.noteViewType = model.NoteViewType.noteList;

    window.location.hash = nv.getHash();
  }

  render() {

    let me = this;
    let getTagForView = function (tags: Array<string>): string {
      if (!tags || tags.length == 0) return null;
      if (tags.length == 1) {
        return tags[0];
      }
      if (
        me.props.connect.state.viewState.noteViewState &&
        /*params.connect.state.viewState.noteViewState.noteViewType ==
            model.NoteViewType.todoList &&*/
        me.props.connect.state.viewState.noteViewState.functionType ==
        model.NoteFunctionType.tag
      ) {
        var filtered = tags.filter(
          x => x != me.props.connect.state.viewState.noteViewState.query
        );
        return filtered[0];
      } else {
        return tags[0];
      }
    };
    let tagDisplay = getTagForView(this.props.row.note.tags);
    let noteDescription = (
      <span>
        {this.props.row.note.title}
      </span>
    );
    if (!this.props.row.note.title) {
      noteDescription = (<span>
        Untitled <span style={{ 'color': 'gray' }}> - updated {moment(this.props.row.note.modified).calendar()} </span>
      </span>)
    }
    let dateStyle = {}

    let subtextConponent = (
      <span className="simple-todo-subtext">



        {moment(this.props.row.note.modified).fromNow()}
      </span>
    );
    const iconButtonElement = (
      <div className=''>


      </div>
    )

    let now = new Date();
    let classDiv = "simple-todo-listitem";
    let focusDiv = ""

    if (this.props.mobileView) {
      const customContentStyle = {
        width: "100%",
        margin: "0px",
        "padding-left": this.props.focused ? "1px" : '5px',
        "padding-right": "5px"
      };
      let showLeft = false;
      let selected = ""
      let tagText=tagDisplay?"#"+tagDisplay:""// #{this.firstTodo().tags[0]}
      return (
        <div
          key={"sswip1dd3" + this.props.row.id}
          ref="mainDiv"
          className={"no-hover joyride-inbox-todo" + selected}
          style={customContentStyle}
        >
          <div
            className="todo-listitem  "
            style={{} as React.StyleHTMLAttributes<String>}

            onClick={this.onClick.bind(this)}

          >
            <div className="container todo-no-padding">
              <div className="row no-gutters" style={{ overflow: "hidden" }}>

                
                <div
                  className={"col-10 no-gutters"}
                >
                  <div className="container todo-no-padding">
                    <div className="row no-gutters ">
                      <div className="col-12 no-gutters mobile-todo-description ">{noteDescription}</div>
                    </div>
                    <div className="row no-gutters ">
                      <div className="col-12 no-gutters mobile-todo-description ">
                      <div className="todo-subtext">{tagText}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={"col-2 no-gutters"}>
                    {subtextConponent}
                  </div>
                </div>

              {iconButtonElement}
            </div>

          </div>

        </div>
      )

    }

    return (
      <div

        style={{} as React.StyleHTMLAttributes<String>}

        onClick={this.onClick.bind(this)}

      >
        <div className="container-fluid simple-todo-container">
          <div className="row no-gutters"  >

            <div className="col-2 no-gutters " >
              {tagDisplay &&
                <TodoTagSelector
                  connect={this.props.connect}
                  group={this.props.row.group}
                  tagDisplay={tagDisplay}
                  selected={this.props.selected}
                  stared={false}
                  due={false}
                  note={true}
                />
              }
              {!tagDisplay &&
                <div></div>
              }
            </div>

            <div
              className={"col-8 no-gutters"}
            >
              <div className="simple-todo-description">{noteDescription} </div>
            </div>
            <div className="col-2 no-gutters " style={{ 'textAlign': 'right' }}>
              {(!this.props.selected) &&
                <div> {subtextConponent} </div>
              }
              {(this.props.selected) &&
                <div>
                  {iconButtonElement}
                </div>
              }
            </div>

          </div>
        </div>

      </div>
    );


  }
}