import * as React from "react";
import * as material from "material-ui";
import * as pApi from "../../shared/papi/papi-core";
import * as actions from "../lib/ActionReducer";
import * as model from "../lib/model";
import Snooze from "material-ui/svg-icons/av/snooze";
import PickDate from "material-ui/svg-icons/device/access-time";
import TomorrowIcon from "material-ui/svg-icons/image/wb-sunny";
import WeekendIcon from "material-ui/svg-icons/action/event-seat";
import NextWeek from "material-ui/svg-icons/content/next-week";
import LastIcon from "material-ui/svg-icons/action/history";
import RemoveIcon from "material-ui/svg-icons/content/remove-circle";
import * as hotkey from "../lib/hotkeyClient";
let _ = require('lodash');
interface SnoozePopupProps {
  snoozed: (snoozeAction: pApi.SnoozeAction, date: Date) => void;
  removeDueDate: () => void;
  onClosed: () => void;
  defaultDate?: Date;
  dropdownOpen: boolean;
  popupOpen: boolean;
  anchorEl: any;
  connect: model.ConnectProps;
}
interface SnoozePopupState  {
  pickDateMode: boolean;
  itemSelected: any;
}
let selections = [
  pApi.SnoozeAction.laterToday,
  pApi.SnoozeAction.tomorrow,
  pApi.SnoozeAction.weekend,
  pApi.SnoozeAction.nextWeek,
  "lastDate",
  pApi.SnoozeAction.custom,
  "clear"
];
export class SnoozePopover extends React.Component<
  SnoozePopupProps,
  SnoozePopupState
> {
  constructor(props: SnoozePopupProps, context) {
    super(props, context);
    this.state = {
      pickDateMode: false,
      itemSelected: null,
     
    };
  }
  /*
    esc: (
          globalState: model.IState,
          componentState: SnoozePopupState
        ): SnoozePopupState => {
            _.defer(()=>{
              me.onClosed();
            })
            
            return componentState;
        },
        next: (
          globalState: model.IState,
          componentState: SnoozePopupState
        ): SnoozePopupState => {
          let index = 0;
          if (me.state.itemSelected) {
            index = selections.indexOf(me.state.itemSelected);
            if (index == -1) {
              index = 0;
            } else {
              index++;
            }

            if (
              selections[index] == "lastDate" &&
              !me.props.connect.state.lastSnooze
            ) {
              index++;
            }
            if (index > selections.length) {
              index = 0;
            }
          } else {
            index = 0;
          }
          componentState.itemSelected = selections[index];
          return componentState;
        },
        prev: (
          globalState: model.IState,
          componentState: SnoozePopupState
        ): SnoozePopupState => {
          let index = selections.indexOf(me.state.itemSelected);
          index--;
          if (
            selections[index] == "lastDate" &&
            !me.props.connect.state.lastSnooze
          ) {
            index--;
          }
          if (index < 0) {
            index = 0;
          }
          componentState.itemSelected = selections[index];
          return componentState;
        }
      }
    );
    */
  componentDidMount() {
    let me = this;
    let instance = hotkey.HotKeyEngine.instance();
    let handlers = new Map<hotkey.HotKeyActionType, () => boolean>();
    handlers.set(
      
      hotkey.HotKeyActionType.esc,
      (): boolean => {
        
          me.onClosed();
        return true;
        
      })
    handlers.set(
      
      hotkey.HotKeyActionType.next,
      (): boolean => {
           
          let index = 0;
          if (me.state.itemSelected) {
            index = selections.indexOf(me.state.itemSelected);
            if (index == -1) {
              index = 0;
            } else {
              index++;
            }

            if (
              selections[index] == "lastDate" &&
              !me.props.connect.state.lastSnooze
            ) {
              index++;
            }
            if (index > selections.length) {
              index = 0;
            }
          } else {
            index = 0;
          }
          let state = {...me.state};
          state.itemSelected = selections[index];
          me.setState(state);
          return true;

      }
    );
  
    instance.registerHandlers("snoozePopupover", "snoozePopupover", true, handlers);
  }
  componentWillUnmount() {
    let instance = hotkey.HotKeyEngine.instance();
    instance.unregisterHandlers("snoozePopupover");
  }


  componentWillUpdate(props: SnoozePopupProps) {
    
  }
  openDatePicker(e) {
    e.preventDefault();
    var me = this;
    let state = { ...this.state };
    state.pickDateMode = true;
    this.setState(state);

    console.log("open targe " + e.target);
    setTimeout(() => {
      console.log("open me");
      me.refs.dp["openDialog"]();
    }, 100);
  }
  onClosed() {
    this.props.onClosed();
    let state = { ...this.state };
    this.props.connect.dispatch<any>(
      actions.ar_clearHotKeyFocus("snooze-popover")
    );
    state.pickDateMode = false;
    this.setState(state);
  }
  render() {
    const customContentStyle = {
      width: "100%",
      margin: "0px",
      "paddingLeft": "5px",
      "paddingRight": "5px"
    };
    const button = {
      /*  margin: 12,*/
      "textAlign": "left"
    };
    const buttonSelected = {
      /*  margin: 12,*/
      "textAlign": "right",
      "backgroundColor": "rgba(0,0,0,0.1)",
      
    };
    var me = this;

    return (
      <div>
        <div>
          <material.Dialog
            contentStyle={customContentStyle}
            overlayStyle={actions.getOverlayStyle()}
            onRequestClose={() => {
              me.onClosed();
            
            }}
            /*actions={actions}*/

            open={this.props.popupOpen && !this.state.pickDateMode}
          >
            <div className="container-fluid" style={{ padding: "0px" }}>
              {this.props.defaultDate && (
                <div className="row" style={{ "textAlign": "center" }}>
                  <div className="col">
                    <material.FlatButton
                      label="Clear Date"
                      primary={true}
                      labelPosition="after"
                      icon={<RemoveIcon />}
                      style={
                        this.state.itemSelected == "clear"
                          ? buttonSelected  as any
                          : button  as any
                      }
                      onClick={() => {
                        me.props.snoozed(pApi.SnoozeAction.custom, null);
                        me.onClosed();
                      }}
                    />
                  </div>
                </div>
              )}
              <div className="row">
                <div className="col">
                  <material.FlatButton
                    label="Tonight"
                    labelPosition="after"
                    primary={true}
                    style={
                      this.state.itemSelected == pApi.SnoozeAction.laterToday
                        ? buttonSelected  as any
                        : button  as any
                    }
                    onClick={() => {
                      me.props.snoozed(pApi.SnoozeAction.laterToday, null);
                      me.onClosed();
                    }}
                    icon={<Snooze />}
                  />
                </div>
                <div className="col">
                  <material.FlatButton
                    label="Tomorrow"
                    labelPosition="after"
                    primary={true}
                    onClick={() => {
                      me.props.snoozed(pApi.SnoozeAction.tomorrow, null);
                      me.onClosed();
                    }}
                    style={
                      this.state.itemSelected == pApi.SnoozeAction.tomorrow
                        ? buttonSelected  as any
                        : button  as any
                    }
                    icon={<TomorrowIcon />}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <material.FlatButton
                    label="Next Week"
                    labelPosition="after"
                    primary={true}
                    style={
                      this.state.itemSelected == pApi.SnoozeAction.nextWeek
                        ? buttonSelected as any
                        : button  as any
                    }
                    onClick={() => {
                      me.props.snoozed(pApi.SnoozeAction.nextWeek, null);
                      me.onClosed();
                    }}
                    icon={<NextWeek />}
                  />
                </div>
                <div className="col">
                  <material.FlatButton
                    label="Pick a Date"
                    labelPosition="after"
                    style={
                      this.state.itemSelected == pApi.SnoozeAction.custom
                        ? buttonSelected as any
                        : button as any
                    }
                    primary={true}
                    onClick={this.openDatePicker.bind(this)}
                    icon={<PickDate />}
                  />
                </div>
              </div>
              {this.props.connect.state.lastSnooze &&
                this.props.connect.state.lastSnooze.action ==
                  pApi.SnoozeAction.custom && (
                  <div className="row" style={{ "textAlign": "center" }}>
                    <div className="col">
                      <material.FlatButton
                        label={model.formatRelativeDate(
                          this.props.connect.state.lastSnooze.date
                        )}
                        labelPosition="after"
                        style={
                          this.state.itemSelected == "lastDate"
                            ? buttonSelected as any
                            : button as any
                        }
                        primary={true}
                        onClick={() => {
                          me.props.snoozed(
                            pApi.SnoozeAction.custom,
                            this.props.connect.state.lastSnooze.date
                          );
                          me.onClosed();
                        }}
                        icon={<LastIcon />}
                      />
                    </div>
                  </div>
                )}
            </div>
          </material.Dialog>
        </div>
        <material.Popover
          open={this.props.dropdownOpen && !this.state.pickDateMode}
          anchorEl={this.props.anchorEl}
          anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
          targetOrigin={{ horizontal: "left", vertical: "top" }}
          onRequestClose={this.onClosed.bind(this)}
        >
          <material.List>
            <material.Subheader>Snooze util ...</material.Subheader>
            <material.Divider />
            <material.ListItem
              leftIcon={<Snooze />}
              primaryText="Later Today"
              secondaryText="6 PM"
              onClick={() => {
                me.props.snoozed(pApi.SnoozeAction.laterToday, null);
                me.onClosed();
              }}
            />
            <material.ListItem
              leftIcon={<TomorrowIcon />}
              primaryText="Tomorrow"
              secondaryText="at 8am"
              onClick={() => {
                me.props.snoozed(pApi.SnoozeAction.tomorrow, null);
                me.onClosed();
              }}
            />
            <material.ListItem
              leftIcon={<WeekendIcon />}
              primaryText="This Weekend"
              secondaryText="at 8am"
              onClick={() => {
                me.props.snoozed(pApi.SnoozeAction.weekend, null);
                me.onClosed();
              }}
            />

            <material.ListItem
              leftIcon={<NextWeek />}
              primaryText="Next Monday"
              secondaryText="at 8am"
              onClick={() => {
                me.props.snoozed(pApi.SnoozeAction.nextWeek, null);
                me.onClosed();
              }}
            />
            {this.props.connect.state.lastSnooze &&
              this.props.connect.state.lastSnooze.action ==
                pApi.SnoozeAction.custom && (
                <material.ListItem
                  leftIcon={<LastIcon />}
                  primaryText="Last Date"
                  secondaryText={model.formatRelativeDate(
                    this.props.connect.state.lastSnooze.date
                  )}
                  onClick={() => {
                    me.props.snoozed(
                      pApi.SnoozeAction.custom,
                      me.props.connect.state.lastSnooze.date
                    );
                    me.onClosed();
                  }}
                />
              )}
          </material.List>
          <material.Divider />
          <material.List>
            <material.ListItem
              primaryText="Pick a date"
              leftIcon={<PickDate />}
              onClick={this.openDatePicker.bind(this)}
            />
            {this.props.defaultDate && (
              <material.ListItem
                primaryText="Clear Date"
                leftIcon={<RemoveIcon />}
                onClick={() => {
                  me.props.snoozed(pApi.SnoozeAction.custom, null);
                  me.onClosed();
                }}
              />
            )}
          </material.List>
        </material.Popover>
        <material.DatePicker
          firstDayOfWeek={0}
          defaultDate={
            this.props.defaultDate
              ? new Date(this.props.defaultDate)
              : new Date()
          }
          onDismiss={this.onClosed.bind(this)}
          autoOk
          onChange={e => {
            var d = me.refs.dp["getDate"]();

            if (d) {
              me.props.snoozed(
                pApi.SnoozeAction.custom,
                me.refs.dp["getDate"]()
              );
              me.onClosed();
            }
          }}
          style={{ display: "none" }}
          ref="dp"
        />
      </div>
    );
  }
}
