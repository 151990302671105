import * as React from "react";
import pure from "recompose/pure";
import { SvgIcon } from "material-ui";

let Hash = props => (
  <SvgIcon
    {...props}
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 270 270"
  >
  <g
     transform="matrix(0.62798492,-1.898851,1.898851,0.62798492,-218.41208,304.05341)"
     id="g5"><g
       id="g7"><path
         id="path9"
         d="m 145.64038,89.963898 c -1.89443,-5.701842 -6.09788,-9.429068 -9.44519,-8.407144 -3.33496,1.117834 -4.52247,6.585343 -2.69067,12.274825 l 28.27717,86.341411 c 1.84499,5.3182 5.84819,8.91243 9.09497,8.05761 3.4223,-0.91365 4.86033,-6.33172 2.97826,-11.93765 -0.0247,-0.19183 -28.21454,-86.329052 -28.21454,-86.329052 z" /><path
         id="path11"
         d="m 107.57803,102.26557 c -1.89443,-5.701846 -6.09788,-9.429072 -9.445189,-8.407149 -3.33495,1.117834 -4.522471,6.585349 -2.690669,12.274839 l 28.277168,86.3414 c 1.84499,5.31819 5.84819,8.91243 9.09497,8.05761 3.42229,-0.91365 4.86032,-6.33172 2.97825,-11.93765 -0.0247,-0.19182 -28.21453,-86.32905 -28.21453,-86.32905 z" /><path
         id="path13"
         d="m 187.20778,151.06421 c 5.70184,-1.89446 9.42651,-6.10558 8.40144,-9.46243 -1.121,-3.34454 -6.59108,-4.53981 -12.2806,-2.70812 l -86.449886,28.35153 c -5.318119,1.84522 -8.909945,5.8557 -8.052109,9.1116 0.916837,3.43193 6.337669,4.87832 11.943585,2.99618 0.19185,-0.0246 86.43757,-28.28876 86.43757,-28.28876 z" /><path
         id="path19"
         d="m 174.81596,113.03167 c 5.70184,-1.89443 9.42906,-6.09788 8.40714,-9.44519 -1.11784,-3.33496 -6.58534,-4.522471 -12.27483,-2.69068 l -86.341395,28.27718 c -5.318192,1.84498 -8.912426,5.84819 -8.057609,9.09496 0.91365,3.4223 6.331721,4.86034 11.937651,2.97827 0.191825,-0.0247 86.329043,-28.21454 86.329043,-28.21454 z" /></g></g>
  </SvgIcon>
);

Hash = pure(Hash);
/*Target.displayName = 'ActionAccessibility';
Target.muiName = 'SvgIcon';*/

export default Hash;
