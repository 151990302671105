
import { sanitize } from "quill/formats/link";
import { GetImageUrlResult, ImageResolver, ImageUploadTracker, ImageUploadTrackerEnum } from "./ImageHandler";
const Quill = require("quill");
const ATTRIBUTES = ["alt", "height", "width"];
//import { default } from '../../../themes/lightTheme';
let $ = require('jquery')
var ImgCache = require("hacked-imgcache");
////
//const { Quill, Mixin, Toolbar } = RQ;
if (!window["imageCacheLoadError"]) {
    window["imageCacheLoadError"] = me => {
      console.log("ImgCache error loading image "+me.src);
  
     
      if (me.src != "/no-image.jpg") {
         
          let resolver = new ImageResolver();
          //resolver.reportBadImage(me.parentNode.getAttribute("image-id"),me.src)
          if(me.parentNode.getAttribute("external-src") && me.parentNode.getAttribute("external-src") != me.src)
          {
                me.src = me.parentNode.getAttribute("external-src");
          }
          else
          {
                me.src = "/no-image.jpg";
          }
    };
  }
}

let uuidV4 = require("uuid/v4");
let Block = Quill.import("blots/block");
let Container = Quill.import("blots/container");
let Parchment = Quill.import("parchment");
let resolver = new ImageResolver();
class Image extends Parchment.Embed {
    static create(value) {

        let parent = super.create(value);
        let node = document.createElement("IMG");
        parent.appendChild(node);
      
        var me = this;
        var obj = {};
        
        if (typeof value === "string") {
           
            obj["external-src"] = value;
           // var sanvalue = this.sanitize(value);
          //  obj["external-src"] = sanvalue;
            /*
            if (value.indexOf("filesystem") == 0) {
                obj["internal-src"] = value;
            } else {
                var sanvalue = this.sanitize(value);
                obj["external-src"] = sanvalue;
            }*/
        } else obj = value;
        let imageId = obj["image-id"] ? obj["image-id"] : "unknown/" + uuidV4();
        node.setAttribute("src", '/loading.gif');
        node.setAttribute("onclick", 'window["imageNoteClick"](this)');
        resolver.getImageUrl(imageId, obj["external-src"]).then((res: GetImageUrlResult) => {
          
            
            node.setAttribute("src", res.url);
            if (res.externalUrl) {
                if(res.externalUrl != obj["external-src"])
                {
                    node.setAttribute("external-src", res.externalUrl);
                    parent.setAttribute("external-src", res.externalUrl);
                }
            
            }
            setSize(node);
        }).catch(e => {
            console.error("Error getting image" + e)
            node.setAttribute("src", "/no-image.jpg");
            setSize(node);
        })

        let setSize = function (node: any) {
            node.setAttribute("onerror", 'window["imageCacheLoadError"](this)');
        };
        console.log("ImgCache:Set " + JSON.stringify(obj));
        parent.setAttribute("class", "note-image darkmode-ignore");

        //let imageId = obj["image-id"];
        parent.setAttribute("image-id", imageId);
        if (obj["external-src"]) {
            parent.setAttribute("external-src", obj["external-src"]);
        }
        
        let progressBarHtml = `<div image-id="${imageId}" class="image-progress-container" style='display:none'>
              <div image-id="${imageId}"  class="image-progress-container-percentage" style="height:24px;width: 0%;transition: all 300ms;transition 2s: unset;"></div>
            <div image-id="${imageId}" class="image-progress-status"></div>
              </div>`;

        var progressBarDiv = document.createElement("div");
        progressBarDiv.innerHTML = progressBarHtml.trim();
        parent.appendChild(progressBarDiv);
        
        return parent;
    }

    static formats(domNode) {

        let x = ATTRIBUTES.reduce(function (formats, attribute) {
            if (domNode.hasAttribute(attribute)) {
                formats[attribute] = domNode.getAttribute(attribute);
            }
            return formats;
        }, {});
        return x;
    }

    static match(url) {

        return /\.(jpe?g|gif|png)$/.test(url) || /^data:image\/.+;base64/.test(url);
    }

    static sanitize(url) {
        return sanitize(url, ["http", "https", "data"]) ? url : "//:0";
    }

    static value(domNode) {
        var obj = {
            "external-src":
                domNode.getAttribute("external-src") ||
                    domNode.getAttribute("external-src") != "undefined"
                    ? domNode.getAttribute("external-src")
                    : null,
            "image-id":
                domNode.getAttribute("image-id") ||
                    domNode.getAttribute("image-id") != "undefined"
                    ? domNode.getAttribute("image-id")
                    : null
        };
     
        
        return obj; //domNode.getAttribute("external-src");
    }

    format(name, value) {
        if (ATTRIBUTES.indexOf(name) > -1) {
            if (value) {
                this.domNode.setAttribute(name, value);
            } else {
                this.domNode.removeAttribute(name);
            }
        } else {
            super.format(name, value);
        }
    }
}
Image.blotName = "image";
Image.tagName = "TODOIMAGE";

export { Image }