
import localForage from "localforage";
import * as pApi from "../../../shared/papi/papi-core";
import { HybridContext } from "../../lib/frontEndContext"
import * as actions from "../../lib/ActionReducer";
import * as model from "../../lib/model";
import {tagsChanged,getDocumentProperties}  from "../../../shared/markdown/deltaUtil"
const Quill = require("quill");
let Block = Quill.import("blots/block");
let BlockEmbed = Quill.import("blots/embed");
let ContainerBlot = Quill.import("blots/container");
let Delta = Quill.import("delta");
let $ = require('jquery')
var store = localForage.createInstance({
    name: "NoteProcessor",
    driver:localForage.LOCALSTORAGE
});

class SaveRequest {
    date: Date;
    trys: number = 0;
    lastTry: Date;
    savedNote: boolean;
    constructor(public documentId: any, public delta: any, public contents: any, public newNote: pApi.Note) {
        this.date = new Date();
    }
}


export default class NoteProcessor {
    static ProcessItems(ctx: HybridContext): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {

            try {
               // console.log("NoteProcessor: ProcessItems")
                let keys = await store.keys();
               // console.log("NoteProcessor: ProcessItems Keys "+keys.length)
                for (var i in keys) {
                    let key = keys[i]
                    console.log("NoteProcessor: found key ", key)
                    let keyParts = key.split(":");
                    if (keyParts[1] == "queue") {
                        let request = await store.getItem(key) as SaveRequest
                        if (!request) {
                            //This can actually happen when the key is 
                        }
                        try {
                            await this.ProcessQueueItem(request.documentId, ctx)
                        }
                        catch (e) {
                            console.error("NoteProcessor: error processing update for document " + request.documentId, e)
                            let updated = await store.getItem(key) as SaveRequest
                            if (!updated) {
                                updated = await store.getItem(request.documentId + ":processing") as SaveRequest
                            }

                            updated.trys++;
                            updated.lastTry = new Date();
                            if (updated.trys < 3) {
                                await store.setItem(key, updated);
                            }
                            else {
                                //leave it in processing
                            }
                        }
                    }
                }
            } catch (e) {
                reject(e)
            }
            resolve();
        })
    }
    static CTX: HybridContext;
    static Timer() {
        // console.log('NoteProcessor:Tick')
        setTimeout(async () => {
            try {
                await this.ProcessItems(this.CTX)
            }
            catch (e) {
                console.error("NoteProcessor: Timer error", e)
            }

            this.Timer();
        }, 500)
    }
    static StartProcessing(ctx: HybridContext): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            try {
                console.log("NoteProcessor: Start Processing ")
                let keys = await store.keys();
                console.log("NoteProcessor: Start Processing " + keys.length)
                for (var i in keys) {
                    let key = keys[i]
                    let keyParts = key.split(":");
                    console.log("NoteProcessor: found key " + key)
                    if (keyParts[1] == "processing") {
                        let request = await store.getItem(key) as SaveRequest
                        if (request.trys < 10) {
                            console.error("NoteProcessor:Send back to queue", key)
                            await store.setItem(request.documentId + ':queue', request)
                            await store.removeItem(key)
                        }
                        else {
                            console.error("NoteProcessor:Over 10 tries to save cancel", key)
                            await store.removeItem(key)
                        }
                    }
                }
            }
            catch (e) {
                console.error("NoteProcessor:Erroring in cleanup", e)
            }
            this.CTX = ctx;
            this.Timer();

        })


    }
    static QueueSave(documentId: any, delta: any, contents: any, newNote: pApi.Note): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            try {
                
                let newRequest = new SaveRequest(documentId, delta, contents, newNote);
                await store.setItem(newRequest.documentId + ':queue', newRequest)
                console.log("NoteProcessor: Queued", documentId)
                resolve();
            }
            catch (e) {
                console.error("NoteProcessor: Queue Error ", e)
                reject(e)
            }
        })
    }
    static GetInProcessContent(documentId: any): Promise<SaveRequest> {
        return new Promise<any>(async (resolve, reject) => {
            try {
                console.log("NoteProcessor: Request " + documentId);
                let request = await store.getItem(documentId + ":queue") as SaveRequest
                console.log("NoteProcessor: Request " + documentId +" done");
                if (request) {
                    console.log("NoteProcessor: Grabbing Queued Document " + request.documentId);
                    return resolve(request);
                }
                request = await store.getItem(documentId + ":processing") as SaveRequest
                if (request) {
                    console.log("NoteProcessor: Grabbing Pending Document " + request.documentId);
                    return resolve(request);
                }
                return resolve(null)
            }
            catch (e) {
                reject(e)
            }
        })
    }
    static ProcessQueueItem(documentId: any, ctx: HybridContext): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            try {
                ;
                console.log("NoteProcessor: ProcessQueueItem" + documentId)
                ctx.dispatch(
                    actions.ar_setNoteLoadingState(model.LoadingState.Loading)
                );
                let request = await store.getItem(documentId + ":queue") as SaveRequest
                request.date = new Date(request.date)
                await store.setItem(documentId + ":processing", request);
                await store.removeItem(documentId + ":queue");
                ;
                let savedNote = request.newNote ? request.newNote : await ctx.Notes.load(documentId);
                if (savedNote.modified > request.date) {
                    let err = "NoteProcessor: " + documentId + " saved date " + savedNote.modified + " > " + request.date + " - abort save";
                    await store.removeItem(documentId + ":processing")
                    console.warn(err);
                    return resolve();
                }

                let props = getDocumentProperties(request.contents);
                ;
                let dbTodos = await ctx.Todos.getByNote(documentId);
                var updateTodos = new Array<pApi.ToDoItem>(); //docTodos.filter(n => n.id.indexOf("new") == 0);
                for (var ni in props.todos) {
                    if (dbTodos.filter(n => n.id == props.todos[ni].id).length == 0) {
                        updateTodos.push(props.todos[ni]);
                    }
                }
                updateTodos.forEach(t => {
                    t.project_type = 3; //note task
                    t.note_id = request.documentId;
                    t.is_new = true;
                })
                dbTodos.forEach(dbTodo => {
                    
                    let dbDoesntHaveNoteTags = props.tags.filter(x => dbTodo.tags.find(y => y == x)).length != props.tags.length;
                    let noteTodo = props.todos.find(x => x.id == dbTodo.id)
                    if (noteTodo) {
                        if (
                            dbTodo.modified < request.date && (
                                noteTodo.description.trim() != dbTodo.description.trim() ||
                                noteTodo.checked != dbTodo.checked ||
                                noteTodo.priority != dbTodo.priority ||
                                tagsChanged(dbTodo.tags, noteTodo.tags) ||
                                dbDoesntHaveNoteTags)
                            /*!me.compareArray(me.tags, dbTodo.tags)*/
                        ) {


                            dbTodo.note_id = request.documentId
                            dbTodo.description = noteTodo.description;
                            dbTodo.checked = noteTodo.checked;
                            dbTodo.priority = noteTodo.priority;
                            dbTodo.tags = noteTodo.tags;//me.tags;
                            updateTodos.push(dbTodo);
                        }
                    }
                })

                let ops = request.contents.ops
                ops.map(obj => {
                    return Object.assign({}, obj);
                });

                savedNote.tags = props.tags;
                savedNote.title = props.title;
                var content = new pApi.NoteContent();
                content.contentType = pApi.ContentType.quillJS;// = "quill";
                content.storageType = ctx.Notes.getDefaultStorageType();// pApi.StorageType.snapshot; //:pApi.ContentType.embedded;
                content.content = ops;
                let promise = new Promise<any>(async (resolve, reject) => {
                    try {
                        await ctx.Notes.setContent(
                            savedNote,
                            content
                        );
                        let todoSaveError = null
                        console.log("NoteProcessor: updated todos ", updateTodos.length);
                        for (var i in updateTodos) {
                            try {


                                if (updateTodos[i].description != "") {
                                    await ctx.Todos.save(updateTodos[i]);
                                }
                            }
                            catch (e) {
                                console.error("NoteProcessor: could not save todo ", e);
                                todoSaveError = e;
                            }
                        }
                        if (todoSaveError) {
                            throw todoSaveError;
                        }
                        console.log("NoteProcessor:  SAVING DONE")
                        resolve(true)
                    }
                    catch (e) {
                        reject(e)
                    }
                })
                await ctx.fireStoreUpdate(promise);
                await store.removeItem(documentId + ":processing")
                console.log('NoteProcessor: updated document ' + documentId + ' todos ' + updateTodos.length)
                ctx.dispatch(
                    actions.ar_setNoteLoadingState(model.LoadingState.Completed)
                );
                resolve();
            }
            catch (e) {
                reject(e);
            }
        })
    }

}
