import * as React from 'react';
import * as model from "../lib/model";
import * as sl from "../../shared/lib/superListTypes";
import { List, WindowScroller, AutoSizer } from 'react-virtualized';
import ListItem from "./ListItem";
import 'react-virtualized/styles.css';
import * as hotkey from "../lib/hotkeyClient";
import * as pApi from '../../shared/papi/papi-core'
import * as material from 'material-ui';
import * as actions from '../lib/ActionReducer'
import Snooze from "material-ui/svg-icons/av/snooze";
import CheckAllIcon from "material-ui/svg-icons/action/done-all";
import UnWatch from "../components/CustomSvg/UnWatch";
import { NoteItem } from "./NoteItem"
import { SnoozeTyper } from "../components/SnoozeTyper";
import MobileItem from "./MobileItem";
import BulkTag, { TagChanges } from "../components/BulkTag"
import CellMeasurer, {
    CellMeasurerCache,
} from 'react-virtualized/dist/commonjs/CellMeasurer';
import { updateFor } from 'typescript';

import { Track } from "../../shared/track"

var ReactDOM = require('react-dom');
let _ = require('lodash')

interface ListWrapperProps {
    onBlur: (e) => void;
    onFocus: (e) => void;


}
class ListWrapper extends React.Component<any, any>  {
    _listRef: any;
    _list: any;
    constructor(props, context) {
        super(props, context);

        this._onListBlur = this._onListBlur.bind(this);
        this._onListFocus = this._onListFocus.bind(this);
        this._setListRef = this._setListRef.bind(this);
    }

    render() {
        const props = Object.assign({}, this.props, {
            ref: this._setListRef
        });

        return React.createElement(List, props);
    }

    _setListRef(listRef) {

        if (this._listRef) {
            this._listRef.removeEventListener('blur', this._onListBlur);
            this._listRef.removeEventListener('focus', this._onListFocus);
        }
        this._list = listRef
        this._listRef = listRef ? ReactDOM.findDOMNode(listRef) : null;

        if (this._listRef) {
            this._listRef.addEventListener('blur', this._onListBlur);
            this._listRef.addEventListener('focus', this._onListFocus);
        }
    }

    _onListBlur(e: any) {
        const { onBlur } = this.props;

        if (typeof onBlur === 'function') {
            onBlur(e);
        }
    }

    _onListFocus(e) {
        const { onFocus } = this.props;

        if (typeof onFocus === 'function') {
            onFocus(e);
        }
    }
}



const styles = theme => {
    let padding = {
        paddingLeft: theme.spacing(3),

        display: 'flex',
        alignItems: 'center'
    }
    let borderPadding = {
        paddingLeft: theme.spacing(2),

        borderLeftWidth: theme.spacing(1),
        borderLeftStyle: 'solid',
        display: 'flex',
        alignItems: 'center'
    }
    let ret = {
        row:
        {
            ...padding
        },
        rowHover:
        {
            ...padding,
            '&:hover': {
                background: theme.palette.action.hover,
            },
        },
        rowSelected: {
            ...borderPadding,
            backgroundColor: theme.palette.primary.light,
            borderLeft: theme.palette.primary.light,
            color: theme.palette.text.main,
        },
        rowFocused: {
            backgroundColor: theme.palette.action.selected,
            borderLeft: theme.palette.primary.main,
            ...borderPadding
        },
        rowFocusedSelected: {
            backgroundColor: theme.palette.primary.light,
            borderLeft: theme.palette.text.main,
            ...borderPadding
        },
        sectionHeader: {
            ...padding,
            paddingTop: '15px'

        },
        groupHeader: {
            ...padding,


        },
        clNoIconPadding: {
            paddingLeft: '23px'
        }
    }
    return ret;
};
interface SuperListProps {
    todos: Array<pApi.ToDoItem>;
    notes: model.LoadingObject<Array<pApi.Note>>
    orderBy?: Array<string>;
    mode: model.InboxMode;
    tag: string;
    todoQueryId: any;
    mobileView: boolean;
    connect: model.ConnectProps;
    mobileHeaderFix?:boolean;
    sortByCreateDate:boolean;
}

interface SuperListState {
    rows: Array<sl.BaseRow>;
    tag: string;
    todoQueryId: string;
    noteQueryId: string;
    mode: model.InboxMode;
    selectedState: sl.SuperListSelectedState;
    expandedGroups: Array<any>;
    scrollToIndex: number;
    updateTimeStamp: string;
    scrolledTime: Date;
    snoozePopup: boolean;
    snoozeDefaultDate?: Date;
    bulkTagPopup: boolean;
    actionTodos: Array<pApi.ToDoItem>;
    sortByCreateDate:boolean;
}
function getMobilePaddingTop(mobileHeaderFix:boolean)
{
    return mobileHeaderFix?60:0;
}
function getMobilePaddingBottom(mobileHeaderFix:boolean)
{
    return mobileHeaderFix?120:0;
}
class SuperList extends React.Component<SuperListProps, SuperListState> {

    constructor(props: SuperListProps, context) {
        super(props, context);
       
        this.state = {
            rows: props.todos || props.notes ? sl.getSuperListRows(props.todos, props.notes.val(), props.mode, props.tag, [], this.props.orderBy, this.props.sortByCreateDate,getMobilePaddingTop(this.props.mobileHeaderFix),getMobilePaddingBottom(this.props.mobileHeaderFix)) : null,
            tag: props.tag,
            selectedState: new sl.SuperListSelectedState('null'),
            expandedGroups: [],
            scrollToIndex: 0,
            todoQueryId: props.todos ? props.todoQueryId : 'null',
            noteQueryId: props.notes ? props.todoQueryId : 'null',
            updateTimeStamp: new Date().toISOString(),
            snoozePopup: false,
            snoozeDefaultDate: null,
            actionTodos: null,
            mode: null,
            scrolledTime: null,
            bulkTagPopup: false,
            sortByCreateDate:props.sortByCreateDate

        }


    }
    //Hack but need to figure
    mouseMoved: Date;
    updatedStateAndGlobalSelectedState(s: SuperListState, keyAction: boolean) {
        if (!keyAction) {
            s.scrolledTime = new Date();
        }
        else {
            s.scrolledTime = undefined;
        }
        this.setState(s);
        this.props.connect.dispatch<any>(actions.ar_updateSelectedState(s.selectedState));
    }
    deselectAll(keyAction: boolean) {
        let s = { ...this.state };
        s.selectedState.selectedRows = [];
        this.updatedStateAndGlobalSelectedState(s, keyAction);
        //@ts-ignore
        if (this.list)
            this.list.forceUpdate();

    }
    public getSelectedTodos(): Array<pApi.ToDoItem> {
        let retval = new Array<pApi.ToDoItem>();
        let selectedState = this.getSelectedState();
        for (var i in selectedState.selectedRows) {
            let row = this.state.rows.find(x => x.id == selectedState.selectedRows[i])
            if (row) {
                if (row instanceof sl.GroupRow) {
                    for (var ii in (row as sl.GroupRow).subRows) {
                        let subRow = (row as sl.GroupRow).subRows[ii];
                        retval.push(subRow.item);
                    }
                }
                if (row instanceof sl.ItemRow) {
                    retval.push((row as sl.ItemRow).item)
                }
            }
        }

        return retval;
    }
    public getFocusedOrSelectedTodos(): Array<pApi.ToDoItem> {

        let selectedState = this.getSelectedState();
        let selected = this.getSelectedTodos();
        if (selected.length > 0) {
            return selected;
        }
        let retval = new Array<pApi.ToDoItem>();
        if (selectedState.focused) {
            let focusedRow = this.state.rows.find(x => x.id == selectedState.getFocusedId());
            if (focusedRow instanceof sl.GroupRow) {
                for (var i in (focusedRow as sl.GroupRow).subRows) {
                    retval.push((focusedRow as sl.GroupRow).subRows[i].item)
                }
            }
            if (focusedRow instanceof sl.ItemRow) {
                retval.push((focusedRow as sl.ItemRow).item);
            }
        }
        return retval;
    }
    completeSelected(keyAction: boolean): boolean {

        let s = { ...this.state };
        let nextFocused = this.getNextFocused(true);

        let selected = this.getFocusedOrSelectedTodos();
        if (selected.length > 0) {
            actions.share_completeItems(true, this.props.connect.state, selected);
            this.setFocus(nextFocused, keyAction);
            return true;
        }
        return false;
    }
    bulkTagSelected(): boolean {
        let selected = this.getFocusedOrSelectedTodos();
        if (selected.length > 0) {
            let s = { ...this.state }
            s.actionTodos = selected;
            s.bulkTagPopup = true;
            this.setState(s);
            return true;
        }
        return false;
    }
    snoozeSelected(): boolean {

        let todos = this.getFocusedOrSelectedTodos();

        // me.changeFocus(true, true)
        if (todos.length > 0) {
            let s = { ...this.state }

            s.snoozePopup = true;
            let itemWithDueDate = todos.find(x => x.due_date != null);
            s.snoozeDefaultDate = itemWithDueDate ? itemWithDueDate.due_date : null;
            s.actionTodos = todos;
            this.setState(s);


            return true;
        } else {
            return false;
        }
    }

    clickRow(row: sl.BaseRow, focusedIndex: number = 0, setFocused: boolean = false) {
        let s = { ...this.state }
        if (row instanceof sl.GroupHeader) {
            s.expandedGroups.push(row.group);
        }
        if (row instanceof sl.GroupRow || row instanceof sl.GroupHeader) {
            ;
            if (row instanceof sl.GroupRow) {
                s.expandedGroups.push(row.group);

            }
            if (row instanceof sl.GroupHeader) {
                s.expandedGroups = s.expandedGroups.filter(x => x != row.group)
                focusedIndex = s.rows.findIndex(x => x.id == row.id);


            }
          
            s.rows = sl.getSuperListRows(this.props.todos, this.props.notes.val(), this.props.mode, this.props.tag, s.expandedGroups, this.props.orderBy,this.props.sortByCreateDate,getMobilePaddingTop(this.props.mobileHeaderFix),getMobilePaddingBottom(this.props.mobileHeaderFix));
            if (row instanceof sl.GroupRow && setFocused) {
                //console.log('Change Focus : ClickRowGroup')
                s.selectedState.focused = s.rows.find(x => x.id == row.subRows[0].id);
                s.selectedState.focusedIndex = s.rows.findIndex(x => x.id == s.selectedState.focused.id)

            }
            if (row instanceof sl.GroupHeader && setFocused) {
                //console.log('Change Focus : ClickRowHeader')
                s.selectedState.focused = s.rows[focusedIndex]
                s.selectedState.focusedIndex = s.rows.findIndex(x => x.id == s.selectedState.focused.id)

            }
            this._cache.clearAll();
            s.updateTimeStamp = new Date().toString()
            this.updatedStateAndGlobalSelectedState(s, false);
            this.list._list.recomputeRowHeights();
            this.list.forceUpdate();
        }
        if (row instanceof sl.ItemRow) {

            this.props.connect.dispatch<any>(
                actions.ar_todoLoadAndOpen((row as sl.ItemRow).item.id)
                //actions.showTodoModal((row as sl.ItemRow).item)
            );
        }
        if (row instanceof sl.NoteRow) {

            let link = model.TodoLinkHelper.getNoteLink((row as sl.NoteRow).note.id, this.props.connect.state.viewState)
            window.location.hash = link
        }
    }
    async setSnoozeDate(snoozeAction: pApi.SnoozeAction, date?: Date) {
        let s = { ...this.state };
        let me = this;
        s.snoozePopup = false;
        s.snoozeDefaultDate = null;
        this.setState(s);
        let nextFocused = this.getNextFocused(true);//this.state.actionTodos ? null : this.getNextFocused(true);
        let selected = this.state.actionTodos ? this.state.actionTodos : this.getFocusedOrSelectedTodos();

        await actions.shared_snoozeItems(
            false,
            this.props.connect,
            selected,
            snoozeAction,
            date
        );

        this.setFocus(nextFocused, this.state.actionTodos != null);
    }
    async setTagsOnSelected(changes: TagChanges) {
        let s = { ...this.state };
        let me = this;
        s.bulkTagPopup = false;
        this.setState(s);
        let nextFocused = this.getNextFocused(true);//this.state.actionTodos ? null : this.getNextFocused(true);
        let selected = this.state.actionTodos ? this.state.actionTodos : this.getFocusedOrSelectedTodos();
        for (var i in selected) {
            if (!selected[i].tags) {
                selected[i].tags = [];
            }
            for (var ii in changes.addedTags) {
                let addTag = changes.addedTags[ii];

                if (!selected[i].tags.find(x => x == addTag)) {
                    selected[i].tags.push(addTag)
                }
            }
            for (var ii in changes.removedTags) {

                let removeTag = changes.removedTags[ii];
                selected[i].tags = selected[i].tags.filter(x => x != removeTag)

            }
            if (selected[i].tags.length == 0) {
                selected[i].tags = null;
            }
        }
        await actions.shared_bulkUpdate(this.props.connect, selected)

        this.setFocus(nextFocused, this.state.actionTodos != null);
    }
    findNext(forward: boolean, index: number) {
        let s = { ...this.state };
        let rows = this.state.rows;
        if (rows.length == 0) {
            return null;
        }

        let newIndex = 0;
        if (index > -1) {
            newIndex = forward ? index + 1 : index - 1;
        }


        let found = false;
        let count = rows.length
        while (count > 0) {
            if (newIndex >= this.state.rows.length) {
                if (index == -1)
                    return 0;
                else
                    return index;

            }
            else if (newIndex < 0) {
                return index;;//newIndex = this.state.rows.length - 1;
            } else if (rows[newIndex] instanceof sl.SelectableRow) {

                return newIndex
            }
            newIndex = forward ? newIndex + 1 : newIndex - 1;

            count--;
        }
        return -1;
    }
    onClickPlaceholder(e) {
        alert('not implemented')
    }
    getSelectedState(): sl.SuperListSelectedState {
        return this.state.selectedState;
    }
    getNextFocusedById(focusId: any, skipSelected: boolean): any {

        let s = { ...this.state };

        let index = s.rows.findIndex(x => x.id == focusId)
        if (index == -1) {
            return -1;
        }
        if (skipSelected) {
            if (s.selectedState.hasSelections()) {


                let highestIdex = -1;
                let highestRow: sl.BaseRow = null;
                for (var i in s.selectedState.selectedRows) {
                    let item = s.selectedState.selectedRows[i];
                    let localIndex = s.rows.findIndex(x => x.id == item);
                    if (localIndex > highestIdex) {
                        highestIdex = localIndex;
                        highestRow = item;
                    }

                }

                if (highestRow) {
                    ;
                    index = highestIdex
                }
                /*
                if (s.selectedState.selectedRows.find(x => x == s.rows[next].id)) {
                    if (index < s.rows.length - 1) {
                        console.log('Debug Range '+ index+ " "+s.rows.length)
                        return this.getNextFocusedById(s.rows[next].id, skipSelected)
                    }

                }*/
            }
        }

        let next = this.findNext(true, index);

        if (next == -1) {
            next = this.findNext(false, index);
        }
        if (next > -1) {


            return s.rows[next].id;
        }
        ;
        return null;
    }
    getNextFocused(skipSelected: boolean): any {

        let s = { ...this.state };
        return this.getNextFocusedById(this.state.selectedState.getFocusedId(), skipSelected)

    }
    setFocus(id, keyAction: boolean, flushActionTodos?: boolean) {
        //console.log("Mouse:SetFocus")
        let s = { ...this.state };
        let index = s.rows.findIndex(x => x.id == id);

        s.actionTodos = flushActionTodos ? null : s.actionTodos;
        if (index > -1) {
            s.selectedState.selectedRows = [];
            //console.log('Change Focus : setFocus')
            s.selectedState.focused = s.rows[index]
            s.selectedState.focusedIndex = index;
            this.updatedStateAndGlobalSelectedState(s, keyAction);
        }
        else {
            this.setState(s);
        }


    }
    changeFocus(foward: boolean, select: boolean, keyAction: boolean) {
        let s = { ...this.state };

        // s.rows = new Array<sl.BaseRow>(...this.state.rows);
        let index = s.rows.findIndex(x => x.id == this.state.selectedState.getFocusedId())
        let currentRow = s.rows[index];
        let next = this.findNext(foward, index);
        if (next > -1) {
        
            s.selectedState.focused = s.rows[next];
            s.selectedState.focusedIndex = next;
            if (select) {
                if (!s.selectedState.selectedRows.find(x => x == s.selectedState.getFocusedId())) {
                    if (!(s.rows[next] instanceof sl.NoteRow))
                        s.selectedState.selectedRows.push(s.rows[next].id)
                }
                if (currentRow && !s.selectedState.selectedRows.find(x => x == currentRow.id)) {
                    if (!(currentRow instanceof sl.NoteRow))
                        s.selectedState.selectedRows.push(currentRow.id)
                }
            }
            /* (s.rows[index] as sl.SelectableRow).focused = false;
             (s.rows[next] as sl.SelectableRow).focused = true;*/
            this.updatedStateAndGlobalSelectedState(s, keyAction);
            //@ts-ignore
            // this.list.forceUpdate();
        }
    }
    addRowToSelected(row: sl.BaseRow) {
        let s = { ...this.state };
        let findRow = s.selectedState.selectedRows.find(x => x == row.id);
        if (!findRow) {
            if (!(row instanceof sl.NoteRow))
                s.selectedState.selectedRows.push(row.id)
        }
        else {
            s.selectedState.selectedRows = s.selectedState.selectedRows.filter(x => x.id != row.id)
        }
        this.updatedStateAndGlobalSelectedState(s, false);

    }
    componentDidMount() {

        let me = this;
        let instance = hotkey.HotKeyEngine.instance();
        let handlers = new Map<hotkey.HotKeyActionType, () => boolean>();
        let HotKeyDescriptions = new Array<hotkey.HotKeyActionDescription>();
        HotKeyDescriptions.push(new hotkey.HotKeyActionDescription(
            hotkey.HotKeyActionType.esc,
            (): boolean => {
                
                if (me.props.mode == model.InboxMode.search) {
                    return false;

                }
                if(me.getSelectedTodos().length > 0)
                {
                    me.deselectAll(true);
                    return true;
                }
                else {
                    let focused = me.state.selectedState.focused;
                    let rows = sl.getSuperListRows(this.props.todos, this.props.notes.val(), this.props.mode, this.props.tag, [], this.props.orderBy,this.props.sortByCreateDate,getMobilePaddingTop(this.props.mobileHeaderFix),getMobilePaddingBottom(this.props.mobileHeaderFix));
                    let hasRowAtTop = focused && rows.find(x=>x.id==focused.id);
                  
                    if(!hasRowAtTop && focused)
                    {
                        //in a group;
                        let group= rows.find(x=>x instanceof sl.GroupRow && x.group == focused.group)
                        if(group)
                        {
                       
                            let s = {...me.state}
                            s.expandedGroups = s.expandedGroups?s.expandedGroups.filter(x=>x != group.group):[]// as sl.GroupRow).group):[];
                            s.rows = sl.getSuperListRows(this.props.todos, this.props.notes.val(), this.props.mode, this.props.tag,  s.expandedGroups , this.props.orderBy,this.props.sortByCreateDate,getMobilePaddingTop(this.props.mobileHeaderFix),getMobilePaddingBottom(this.props.mobileHeaderFix));
                            s.selectedState.focusedIndex = s.rows.findIndex(x=>x.id == group.id)
                            s.selectedState.focused = s.rows.find(x=>x.id == group.id)
                            me.updatedStateAndGlobalSelectedState(s, true);
                            //@ts-ignore
                            if (me.list)
                                me.list.forceUpdate();
                            return true;
                        }
                        
                    }
                }

                return false;
            }, false
        ))

      
        HotKeyDescriptions.push(new hotkey.HotKeyActionDescription(
            hotkey.HotKeyActionType.next,
            (): boolean => {
                me.changeFocus(true, false, true);

                return true;
            }, 
            false,
            "Move Next",
            "Navigation"
        ))
       

        HotKeyDescriptions.push(new hotkey.HotKeyActionDescription(
            hotkey.HotKeyActionType.prev,
            (): boolean => {
                me.changeFocus(false, false, true);
                return true;
            }, false,
            "Move Previous",
            "Navigation"
        ))
       


        HotKeyDescriptions.push(new hotkey.HotKeyActionDescription(
            hotkey.HotKeyActionType.nextSelect,
            (): boolean => {
                me.changeFocus(true, true, true)
                return true;
            }, false,
            "Select Next",
            "Navigation"
        ))
       

        HotKeyDescriptions.push(new hotkey.HotKeyActionDescription(
            hotkey.HotKeyActionType.prevSelect,
            (): boolean => {
                me.changeFocus(false, true, true)
                return true;
            }, false,
            "Select Previous",
            "Navigation"
        ))
        
        function selectionsOrFocusAction():boolean
        {
            let selected = me.state.selectedState.selectedRows;// []// this.props.connect.state.inboxData.getSelectedTodos();
            let focused = me.state.selectedState.focused;// this.props.connect.state.inboxData.focused;
        
            return  (selected.length > 0 || (focused && !(focused instanceof sl.NoteRow)) ) ;
        }
        HotKeyDescriptions.push(new hotkey.HotKeyActionDescription(
            hotkey.HotKeyActionType.snooze,
            (): boolean => {
                return me.snoozeSelected();
            }, true,
            "Snooze",
            "Action",null,
            selectionsOrFocusAction
            
        ))
       

        HotKeyDescriptions.push(new hotkey.HotKeyActionDescription(
            hotkey.HotKeyActionType.star,
            (): boolean => {

                let next = me.getNextFocused(true);
                let todos = me.getFocusedOrSelectedTodos()
                if(todos.length > 0)
                {
                    actions.shared_starOrUnstarItems(this.props.connect, todos);
                    me.setFocus(next, true);
                }
                return true
            }, true,
            "Toggle Star","Action",null,selectionsOrFocusAction
        ))
        
        //
        HotKeyDescriptions.push(new hotkey.HotKeyActionDescription(
            hotkey.HotKeyActionType.hideFromInbox,
            (): boolean => {

                let todos = me.getFocusedOrSelectedTodos()
                if (todos.length == todos.filter(x => x.hide_from_inbox).length) {
                    actions.shared_unHideItems(this.props.connect, todos)
                }
                else {
                    let canHide = todos.filter(x=>pApi.CanHideToDo(x))
                    if(canHide.length == todos.length)
                    {
                        actions.shared_hideItems(this.props.connect, todos);
                    }
                    else
                    {
                        alert("Some of the selected to-dos can not be hidden because they have a due date, star or do not contain a tag.")
                    }
                }
                me.deselectAll(true);

                return true;
            }, true,
        
            "Hide from Inbox","Action",null,selectionsOrFocusAction
        ))
        HotKeyDescriptions.push(new hotkey.HotKeyActionDescription(
            hotkey.HotKeyActionType.forceHideFromInbox,
            (): boolean => {

                let todos = me.getFocusedOrSelectedTodos()
                actions.shared_hideItems(this.props.connect, todos,true);
                me.deselectAll(true);

                return true;
            }, true,
        
            "Force Hide from Inbox (remove Due Date and Star)git c","Action",null,selectionsOrFocusAction
        ))
        
        
        HotKeyDescriptions.push(new hotkey.HotKeyActionDescription(
            hotkey.HotKeyActionType.enter,
            (): boolean => {

                let s = { ...this.state };
                // s.rows = new Array<sl.BaseRow>(...this.state.rows);
                let focusedIndex = s.rows.findIndex(x => x.id == this.state.selectedState.getFocusedId());
                let row = s.rows[focusedIndex];
                if (focusedIndex > -1) {
                    me.clickRow(row, focusedIndex,true)


                }

                return true;
            }, true,
            "Open","Action",null,
            ():boolean=>{
                let selected = me.state.selectedState.selectedRows;// []// this.props.connect.state.inboxData.getSelectedTodos();
                let focused = me.state.selectedState.focused;// this.props.connect.state.inboxData.focused;
            
                return (selected.length == 0 && focused && focused instanceof sl.NoteRow)

            }
        ))
       
        HotKeyDescriptions.push(new hotkey.HotKeyActionDescription(
            hotkey.HotKeyActionType.openLink,
            (): boolean => {
                if (me.state.selectedState.focused && me.state.selectedState.focused instanceof sl.NoteRow) {
                    window.location.hash = model.TodoLinkHelper.getNoteLink((me.state.selectedState.focused as sl.NoteRow).note.id, this.props.connect.state.viewState)
                }
                else {
                    let todos = me.getFocusedOrSelectedTodos()
                    let redirectLink = null;
                    let redirectNoteId = null;
                    for (var i in todos) {
                        let todo = todos[i];
                        let link = model.TodoLinkHelper.getLink(todo);
                        if (link) {
                            if (redirectLink && redirectLink != link)
                                return;
                            redirectLink = link;
                            redirectNoteId = null
                            break;
                        }
                        link = model.TodoLinkHelper.getNoteLink(todo.note_id, this.props.connect.state.viewState)
                        if (link) {
                            if (redirectLink && redirectLink != link)
                                return;
                            redirectLink = link;
                            redirectNoteId = todo.note_id
                        }
                        else {
                            return;
                        }


                    }
                    if (redirectLink) {
                        if (redirectNoteId) {
                            window.location.hash = redirectLink;
                        }
                        else {
                            //var win = window.open(redirectLink, '_system');
                            window["openLink"](redirectLink);//
                           // win.focus();

                        }
                    }


                }





                //  me.props.connect.dispatch<any>(actions.ar_updateInboxState(inboxData));

                return true;
            }, true,
            "Open Link","Action",null,
            ():boolean=>{
                let selected = me.state.selectedState.selectedRows;// []// this.props.connect.state.inboxData.getSelectedTodos();
                let focused = me.state.selectedState.focused;// this.props.connect.state.inboxData.focused;
            
                return (selected.length == 0 && focused && focused instanceof sl.NoteRow)

            }
        ))
       
        HotKeyDescriptions.push(new hotkey.HotKeyActionDescription(
            hotkey.HotKeyActionType.complete,
            (): boolean => {

                return me.completeSelected(true);
            }, true,
            "Complete","Action",null,selectionsOrFocusAction
        ))
      

        HotKeyDescriptions.push(new hotkey.HotKeyActionDescription(
            hotkey.HotKeyActionType.tag,
            (): boolean => {

                return this.bulkTagSelected()
            }, true,
            "Tag","Action",null,selectionsOrFocusAction
        ))

        HotKeyDescriptions.push(new hotkey.HotKeyActionDescription(
            hotkey.HotKeyActionType.copy,
            (): boolean => {
                let todos = me.getFocusedOrSelectedTodos()
                let text = "";
                todos.forEach(x=>{
                    text+=`<ul data-checked="${x.checked}" data-id="${x.id}" data-mode="ready" data-priority="${x.priority == pApi.ToDoItemPriority.High?1:0}" data-sync-id="undefined" data-tags="undefined" style="margin-right: 0px; margin-bottom: 0px; margin-left: -2.2em; letter-spacing: normal; cursor: text; padding: 0px 0em 0em; counter-reset: list-1 0 list-2 0 list-3 0 list-4 0 list-5 0 list-6 0 list-7 0 list-8 0 list-9 0; list-style: none; text-decoration-line: line-through; color: rgb(154, 154, 154); vertical-align: top; pointer-events: none; white-space: pre-wrap;"><li style="font-size: 1em; padding-left: 0.6em;">${x.description}</li></ul>`;
                })
                if(todos.length==1)
                {
                    text+="<ul><li></li></ul>"
                }
                me.copyFormatted(text);
                return true;
            }, true,
            "Action","Copy",null,selectionsOrFocusAction
        ))
      


    

 
        instance.registerHandlersWithActions("inbox", "inbox", false, HotKeyDescriptions);
        //  instance.registerHandlers("inbox", "inbox", false, handlers);
    }
    copyToClipboard = str => {
        const el = document.createElement('textarea');
        el.value = str;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
      };
      copyFormatted (html) {
        // Create container for the HTML
        // [1]
        var container = document.createElement('div')
        container.innerHTML = html
      
        // Hide element
        // [2]
        container.style.position = 'fixed'
        container.style.pointerEvents = 'none'
        container.style.opacity = "0"
      
        // Detect all style sheets of the page
        var activeSheets = Array.prototype.slice.call(document.styleSheets)
          .filter(function (sheet) {
            return !sheet.disabled
          })
      
        // Mount the container to the DOM to make `contentWindow` available
        // [3]
        document.body.appendChild(container)
      
        // Copy to clipboard
        // [4]
        window.getSelection().removeAllRanges()
      
        var range = document.createRange()
        range.selectNode(container)
        window.getSelection().addRange(range)
      
        // [5.1]
        document.execCommand('copy')
      
        // [5.2]
        for (var i = 0; i < activeSheets.length; i++) activeSheets[i].disabled = true
      
        // [5.3]
        document.execCommand('copy')
      
        // [5.4]
        for (var i = 0; i < activeSheets.length; i++) activeSheets[i].disabled = false
      
        // Remove the container
        // [6]
        document.body.removeChild(container)
      }
      
    componentWillUnmount() {
        let instance = hotkey.HotKeyEngine.instance();
        instance.unregisterHandlers("inbox");
    }
    /*
    shouldComponentUpdate(nextProps:SuperListProps, nextState:SuperListState)
    {
     
        if(nextProps.connect.state.viewState.noteOpen())
        {   
           
            console.log("Should Update  false Not Open")
            return false;
        }
        let shouldUpdate =  shallowCompare(this, nextProps, nextState);
        
        console.log("Should Update  "+shouldUpdate)
        return shouldUpdate 
    }*/
    componentDidUpdate(prevProps: SuperListProps, prevState: SuperListState) {
        if (
            this._resizeAllFlag ||
            SuperList.checkIfUpdateNeed(prevProps, prevState)
        ) {

            this._resizeAllFlag = false;
            this._cache.clearAll();
            if (this.list) {


                this.list._list.recomputeRowHeights();
                //this.list.forceUpdate();
              
                return;
            }
        }
      //  console.log('ROW:skipcomponentDidUpdate')
    }
    rowMouseEnter(e, row) {
        let me = this;
        let s = { ...me.state };
      
        s.selectedState.hoverRow = row;
        me.setState(s);
        me.list.forceUpdate();
        /*
        if (s.scrolledTime) {
           // s.scrolledTime = null;
            //me.setState(s);
        }
        else {
            s.selectedState.focused = row;

            me.list.forceUpdate();
        }*/
    }
    rowMouseExit(e, row) {
        let me = this;
       
        
        let s = { ...me.state };
        s.selectedState.hoverRow = null;
        me.setState(s);
        me.list.forceUpdate();
        /*
        let me = this;
        let s = { ...me.state };
        if (s.selectedState.getFocusedId() == row.id) {
            s.selectedState.focused = null;
            me.setState(s);
            me.list.forceUpdate();
        }
        else if (s.scrolledTime) {
            s.scrolledTime = null;
            me.setState(s);
            me.list.forceUpdate();
        }*/
    }

    _rowRenderer = ({ index, isScrolling, isVisible, key, style }) => {
       

        const { list } = this.context;

        //style.background = "white"
        let me = this;
        let row = this.state.rows[index];
        if (!row) {
            return (<div>Unknown</div>)

        }

        let enableHideFromInbox = true;//this.props.mode == model.InboxMode.
      
        //let baseRow = this.props.classes.row;
        if (row instanceof sl.SelectableRow) {

            let focused = row.id == this.state.selectedState.getFocusedId();
            let hovered = row.id == this.state.selectedState.getHoverId();
            let selected = this.state.selectedState.selectedRows.find(x => row.id == x) != null
            //let className = this.state.selectedState.selectedRows.length > 0 || this.state.selectedState.focused ? this.props.classes.row : this.props.classes.rowHover;

            let classDiv = "superlist-todo-listitem";
            let focusDiv = ""
            if (focused || selected || hovered) {
                if (focused) {
                    classDiv += " superlist-todo-listitem-focused";
                }else if(hovered)
                {
                    classDiv += " superlist-todo-listitem-hovering"
                }
                if (selected) {
                    classDiv += " superlist-todo-listitem-selected";
                }
                if (window['darkmode'].isActivated()) {
                    classDiv += "-dark"
                }
            }
            /*else if (this.state.hovering) {
              classDiv += " simple-todo-listitem-hovering";
            }*/
            let todos = new Array<pApi.ToDoItem>();

            if (row instanceof sl.ItemRow) {
                todos.push((row as sl.ItemRow).item)
            }
            if (row instanceof sl.GroupRow) {
                (row as sl.GroupRow).subRows.forEach(x => todos.push(x.item));


            }
            if (!window['co']) {
                window['co'] = 0
            }
            window['co']++;
          
            return (
                <div key={this.state.updateTimeStamp + ':' + row.id}
                    style={style}
                    className={classDiv}

                    onMouseEnter={(e) => {
                        me.rowMouseEnter(e, row)
                    }} onMouseLeave={(e) => {
                        me.rowMouseExit(e, row)
                    }}
                >
                    {row instanceof sl.NoteRow &&
                        <NoteItem mobileView={false} connect={this.props.connect} row={(row as sl.NoteRow)} focused={focused} selected={selected} />
                    }
                    {!(row instanceof sl.NoteRow) &&
                        <ListItem
                            key={'groupend' + this.state.updateTimeStamp + ':' + row.id}
                            row={row as sl.SelectableRow}
                            todos={todos}
                            selected={selected}
                            queryId={this.state.todoQueryId}
                            focused={focused}
                            connect={this.props.connect}
                            multiSelect={this.getSelectedState().selectedRows.length > 1}
                            onSnooze={() => {
                                let s = { ...me.state }
                                let todo = (row as sl.ItemBaseRow);
                                s.snoozePopup = true;
                                let itemWithDueDate = todo.getTodos().find(x => x.due_date != null);
                                s.snoozeDefaultDate = itemWithDueDate ? itemWithDueDate.due_date : null;
                                s.actionTodos = todo.getTodos();
                                this.setState(s);
                            }}
                            onComplete={() => {
                                let todo = (row as sl.ItemBaseRow);
                                actions.share_completeItems(true, me.props.connect.state, todo.getTodos())
                            }}
                            onClick={() => {

                                //let nextSelect = focused?me.getNextFocused()

                                me.clickRow(row);

                            }}

                        />
                    }
                </div>
            )
        }
        if (row instanceof sl.SectionHeader) {
            return (
                <div key={'grid' + this.state.updateTimeStamp + ':' + row.id} style={style} className=''
                    onMouseEnter={(e) => {
                        me.rowMouseEnter(e, row)
                    }} onMouseLeave={(e) => {
                        me.rowMouseExit(e, row)
                    }}
                >

                    <div className='superlist-section-heading'>{row.section.displayText}</div>
                </div>
            )
        }
        if (row instanceof sl.GroupEnd) {
            return (<div key={'groupend' + this.state.updateTimeStamp + ':' + row.id} style={style}
                onMouseEnter={(e) => {
                    me.rowMouseEnter(e, row)
                }} onMouseLeave={(e) => {
                    me.rowMouseExit(e, row)
                }} >
                <div className='superlist-expanded-group-end' />
            </div>)
        }
        if (row instanceof sl.GroupHeader) {
            return (
                <div key={'groupheader' + this.state.updateTimeStamp + ':' + row.id} style={style}
                    onMouseEnter={(e) => {
                        me.rowMouseEnter(e, row)
                    }} onMouseLeave={(e) => {
                        me.rowMouseExit(e, row)
                    }} >
                    <hr className='superlist-expanded-group-line' />
                    <div className='superlist-expanded-group-heading'>
                      #{row.groupDisplayName}

                        <div
                            className="float-right"
                            style={{
                                "paddingRight": "0px",
                                "paddingTop": "0px",
                                'display': 'none'
                            }}
                        >
                            <material.IconButton onClick={this.completeSelected.bind(this)}>
                                <CheckAllIcon />
                            </material.IconButton>
                        </div>
                        <div
                            key={"menu-holderx" + row.group}
                            className="float-right"
                            ref={"menuholderx"}
                            style={{
                                "paddingRight": "0px",
                                "paddingTop": "0px",
                                'display': 'none'
                            }}
                        >
                            <material.IconButton onClick={() => {

                            }}>
                                <Snooze />
                            </material.IconButton>
                        </div>
                        {enableHideFromInbox && (
                            <div
                                className="float-right"
                                ref={"menuholder"}
                                style={{
                                    "paddingRight": "0px",
                                    "paddingTop": "0px",
                                    'display': 'none'
                                }}
                            >
                                <material.IconButton onClick={this.onClickPlaceholder.bind(this)}>
                                    <UnWatch />
                                </material.IconButton>
                            </div>
                        )}
                        <div
                            className="float-right"
                            style={{
                                "paddingRight": "0px",
                                "paddingTop": "0px",
                                'display': 'none'
                            }}
                        />
                        <div />
                    </div>
                </div>
            )

        }
        return (
            <div>Unknown</div>)

    };
    _cache: CellMeasurerCache = new CellMeasurerCache({ defaultHeight: 85, fixedWidth: true });
    _mostRecentWidth: number = 0;
    _resizeAllFlag: boolean = false;
    _registerList: any;
    _resizeAll = () => {
        /*this._resizeAllFlag = false;
        this._cache.clearAll();
        if (this.list) {
            this.list._list.recomputeRowHeights();
            console.log('ROW:recomputeRowHeights')
            //this.list.forceUpdate();//this.list.recomputeRowHeights();
        }
        else {
            console.log('ROW:skip_resizeAll')
        }*/
    };
    _mobileRowRenders = ({ index, isScrolling, parent, isVisible, key, style }) => {
       
        const { list } = this.context;


        let me = this;
        let row = this.state.rows[index];
        if (!row) {
            return (<div>Unknown</div>)

        }
        let enableHideFromInbox = true;//this.props.mode == model.InboxMode.
     
        //let baseRow = this.props.classes.row;
        let RowBody = function () {
            if (row instanceof sl.SelectableRow) {

                let focused = row.id == me.state.selectedState.getFocusedId();
                let selected = me.state.selectedState.selectedRows.find(x => row.id == x) != null
                //let className = this.state.selectedState.selectedRows.length > 0 || this.state.selectedState.focused ? this.props.classes.row : this.props.classes.rowHover;
                let classDiv = "";

                let todos = new Array<pApi.ToDoItem>();

                if (row instanceof sl.ItemRow) {
                    todos.push((row as sl.ItemRow).item)
                }
                if (row instanceof sl.GroupRow) {
                    (row as sl.GroupRow).subRows.forEach(x => todos.push(x.item));


                }
                if (!window['co']) {
                    window['co'] = 0
                }
                window['co']++;
              

                return (

                    <div className={classDiv} >
                        {row instanceof sl.NoteRow &&
                            <NoteItem mobileView={true} connect={me.props.connect} row={(row as sl.NoteRow)} focused={focused} selected={selected} />
                        }
                        {!(row instanceof sl.NoteRow) &&
                            <div>

                                <MobileItem
                                    key={'groupend' + me.state.updateTimeStamp + ':' + row.id}
                                    row={row as sl.SelectableRow}
                                    todos={todos}
                                    selected={selected}
                                    queryId={me.state.todoQueryId}
                                    focused={focused}
                                    connect={me.props.connect}
                                    multiSelect={me.getSelectedState().selectedRows.length > 1}
                                    onSnooze={() => {
                                        let s = { ...me.state }
                                        let todo = (row as sl.ItemBaseRow);
                                        s.snoozePopup = true;
                                        let itemWithDueDate = todo.getTodos().find(x => x.due_date != null);
                                        s.snoozeDefaultDate = itemWithDueDate ? itemWithDueDate.due_date : null;
                                        s.actionTodos = todo.getTodos();
                                        me.setState(s);
                                    }}
                                    onComplete={() => {
                                        let todo = (row as sl.ItemBaseRow);
                                        actions.share_completeItems(true, me.props.connect.state, todo.getTodos())
                                    }}
                                    onSelect={() => {
                                        me.addRowToSelected(row)
                                    }}
                                    onClick={() => {

                                        //let nextSelect = focused?me.getNextFocused()
                                        me.clickRow(row, index);

                                    }}

                                />
                            </div>
                        }</div>
                )
            }
            if (row instanceof sl.SectionHeader) {
                let style = {}
                
                return (
                    <div

                        className={''}


                        onMouseEnter={(e) => {
                            me.rowMouseEnter(e, row)
                        }} onMouseLeave={(e) => {
                            me.rowMouseExit(e, row)
                        }}

                    >

                       
                        <div className='superlist-mobile-section-heading'
                        style={style}
                         >{row.section.displayText }</div>
                    </div>
                )
            }
            if (row instanceof sl.GroupEnd) {
                return (<div
                    className={''}



                    onMouseEnter={(e) => {
                        me.rowMouseEnter(e, row)
                    }} onMouseLeave={(e) => {
                        me.rowMouseExit(e, row)
                    }}

                >
                    <div className='superlist-expanded-mobile-group-end' />
                </div>)
            }
            if (row instanceof sl.GroupHeader) {
                return (
                    <div
                        className={''}

                        onClick={(e) => {
                            me.clickRow(row);
                        }}
                        onMouseEnter={(e) => {
                            me.rowMouseEnter(e, row)
                        }} onMouseLeave={(e) => {
                            me.rowMouseExit(e, row)
                        }}>

                        <div className='superlist-mobile-group-heading'>
                            {row.groupDisplayName}

                            <div
                                className="float-right"
                                style={{
                                    "paddingRight": "0px",
                                    "paddingTop": "0px",
                                    "marginTop": "-15px"
                                }}
                            >
                                <material.IconButton onClick={me.completeSelected.bind(me)}>
                                    <CheckAllIcon />
                                </material.IconButton>
                            </div>
                            <div
                                key={"menu-holderx" + row.group}
                                className="float-right"
                                ref={"menuholderx"}
                                style={{
                                    "paddingRight": "0px",
                                    "paddingTop": "0px",
                                    "marginTop": "-15px"

                                }}
                            >
                                <material.IconButton onClick={() => {

                                }}>
                                    <Snooze />
                                </material.IconButton>
                            </div>
                            {enableHideFromInbox && (
                                <div
                                    className="float-right"
                                    ref={"menuholder"}
                                    style={{
                                        "paddingRight": "0px",
                                        "paddingTop": "0px",
                                        "marginTop": "-15px"
                                    }}
                                >
                                    <material.IconButton onClick={me.onClickPlaceholder.bind(me)}>
                                        <UnWatch />
                                    </material.IconButton>
                                </div>
                            )}
                            <div
                                className="float-right"
                                style={{
                                    "paddingRight": "0px",
                                    "paddingTop": "0px",
                                    "marginTop": "-15px",
                                    'display': 'none'
                                }}
                            />
                            <div />
                        </div>
                    </div>
                )

            }
            if(row instanceof sl.PaddingRow){
              
                return (<div className="superlist-mobile-paddingrow" style={{height:(row as sl.PaddingRow).padding}}></div>)
            }
            return (
                <div>Unknown</div>)
        }
        let keyName = this.state.updateTimeStamp + ':' + row.id + '-' + row.constructor.name
        let wrapperClass = "superlist-todo-mobilelistitme";//
        if (row instanceof sl.SelectableRow) {
            wrapperClass += " superlist-todo-mobilelistitem-boarder";//
        }
        return (
            <CellMeasurer key={'cm' + keyName}
                cache={this._cache}

                parent={parent}
                rowIndex={index}
                onMouseEnter={(e) => {
                    me.rowMouseEnter(e, row)
                }} onMouseLeave={(e) => {
                    me.rowMouseExit(e, row)
                }}>
                {({ measure, registerChild }) => (
                    <div ref={registerChild} style={style} className={wrapperClass + ' kn-' + keyName} key={'div-' + keyName}>
                        {RowBody()}
                    </div>
                )}

            </CellMeasurer>
        )


    };
    static checkIfUpdateNeed(props: SuperListProps, state: SuperListState): boolean {
        return (props.todos && ((state.todoQueryId != props.todoQueryId || state.noteQueryId != props.notes.version) || props.mode != state.mode || props.tag != state.tag || props.sortByCreateDate != state.sortByCreateDate ));

    }
    static getDerivedStateFromProps(props: SuperListProps, state: SuperListState) {
        //console.log((state.queryId != props.queryResult.id)+" "+state.queryId+' '+props.queryResult.id+' getDerivedStateFromProps')
        let f = async (s: sl.SuperListSelectedState) => {

            props.connect.dispatch(actions.ar_updateSelectedState(state.selectedState));
        }
        if (SuperList.checkIfUpdateNeed(props, state)) {
            console.log('update query')
            ;
            state.rows = sl.getSuperListRows(props.todos, props.notes.val(), props.mode, props.tag, state.expandedGroups, props.orderBy,props.sortByCreateDate,getMobilePaddingTop(props.mobileHeaderFix),getMobilePaddingBottom(props.mobileHeaderFix));
            state.todoQueryId = props.todoQueryId;
            state.noteQueryId = props.notes.version;
            state.sortByCreateDate = props.sortByCreateDate
            state.updateTimeStamp = new Date().toISOString()

           
            if (props.mode != state.mode) {

                state.selectedState = new sl.SuperListSelectedState(new Date().toString())
                state.expandedGroups = [];
                state.scrollToIndex = 0;
            }
            state.tag = props.tag;
            state.mode = props.mode

            return state;
        }
        if (props.connect.state.selectedState.focusFirst) {
          
            let selectRow = state.rows.find(x=>x instanceof sl.SelectableRow)
            state.selectedState.focused = selectRow;//state.rows.length > 2 ? state.rows[1] : null;
            state.selectedState.focusFirst = false;

            f(state.selectedState);
            return state;


        }

        return null;
    }
    _windowScroller: any;
    _setRef = windowScroller => {
        this._windowScroller = windowScroller;
    };

    _onScrollToRowChange(event) {
        const { rowCount } = this.state.rows.length as any
        let scrollToIndex = Math.min(
            rowCount - 1,
            parseInt(event.target.value, 10),
        );

        if (isNaN(scrollToIndex)) {
            scrollToIndex = undefined;
        }

        this.setState({ scrollToIndex });
    }
    list: any;

    render() {

        let me = this;

        if (!this.state.rows) { //if (!this.state.rows || this.props.connect.state.viewState.noteOpen()) {
            return (<div></div>);
        }
      

        if (this.state.rows.filter(x=>!(x instanceof sl.PaddingRow )).length == 0 && this.props.connect.state.tags && this.props.connect.state.TodosState == model.LoadingState.Completed && this.props.connect.state.notes.completed()) {

            let subTags = [];
            if (this.props.tag && this.props.connect.state.viewState.appSection == model.AppSections.notes) {
                ;
                subTags = this.props.connect.state.tags
                    .filter(
                        x => x.name != this.props.tag && x.name.startsWith(this.props.tag) && this.props.tag.split('/').length < x.name.split('/').length
                    )
                    .sort((a: pApi.Tag, b: pApi.Tag) => {
                        return a.name > b.name ? 1 : -1;
                    });
            }
            var emptyHeight = this.props.mobileView?'calc(100vh)':0;// window.innerHeight - 64 
            let emptyStyle : any = {
                backgroundAttachment: "fixed",
                                    backgroundPosition: "center",
                                    height: emptyHeight,
                                    margin: "5%",
                                    color: "darkgray",
                                    textAlign: "center",
            }
            if(this.props.mobileView)
            {
                emptyStyle= {
                    paddingTop: 40,
                    ...emptyStyle,
                }
            }
            
            
            switch (this.props.connect.state.viewState.appSection) {
                case model.AppSections.inbox:
                case model.AppSections.bydate:
                    if (
                        this.props.connect.state.dojoSettings.onboarding.inboxHover ||
                        this.props.connect.state.dojoSettings.onboarding.inboxSwipe
                    ) {

                        return (
                            <div
                                style={{
                                    backgroundImage: "url(/japan-mod-gray.svg)",
                                    backgroundRepeat: "no-repeat",
                                    ...emptyStyle
                                }}
                            >
                                <h2>Enjoy life.</h2>
                                <h3>You have no To-Dos in your inbox.</h3>
                            </div>
                        );
                    } else {
                        return (
                            <div
                                style={emptyStyle}
                            >
                                <h3>You have no To-Dos in your inbox.</h3>
                            </div>
                        );
                    }
                case model.AppSections.today:
                    return (
                        <div
                            style={emptyStyle}
                
                        >
                            <h3>You have no To-Dos scheduled today.</h3>
                        </div>
                    );
                case model.AppSections.thisweek:
                    return (
                        <div
                        style={emptyStyle}
                        >
                            <h3>You have no To-Dos scheduled this week.</h3>
                        </div>
                    );
                case model.AppSections.snoozed:
                    return (
                        <div
                        style={emptyStyle}
                        >
                            <h3>You have no snoozed To-Dos.</h3>
                        </div>
                    );
                case model.AppSections.notes:
                    return (
                        <div
                        style={emptyStyle}
                        >
                            <h3>
                                You have no To-Dos for #
                        {this.props.connect.state.viewState.getTag()}
                            </h3>
                            {subTags.length == 0 && (
                                <div
                                    style={{
                                        width: "100%",

                                        "textAlign": "center"
                                    }}
                                >
                                    <material.RaisedButton
                                        onClick={async e => {
                                            try {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                await me.props.connect.state.context.Tags.checkForRemoval(
                                                    this.props.tag
                                                );
                                                var tag = this.props.tag
                                                var tagParts = tag.split("/");
                                                let vs = this.props.connect.state.viewState.deepCopy();
                                                if (tagParts.length > 1) {
                                                    vs.noteViewState.query = tagParts
                                                        .splice(0, tagParts.length - 1)
                                                        .join("/");
                                                } else {
                                                    vs.appSection = model.AppSections.inbox;
                                                }
                                                window.location.hash = vs.getHash();
                                            } catch (e) {
                                                Track.reportError(
                                                    e,
                                                    "There was a problem removing your tag.",
                                                    true,
                                                    this.props.connect.dispatch
                                                );
                                            }
                                        }}
                                        primary
                                        label={`Remove #${this.props.connect.state.viewState.getTag()}`}
                                    />
                                </div>
                            )}
                        </div>

                    );
                default:
                    return (
                        <div
                        style={emptyStyle}
                        >
                            <h3>No To-Dos</h3>
                        </div>
                    );
            }


        }

        let rows = this.state.rows;//this.getRows(this.props.items,this.state.expandedGroups);
        let renderer = this._rowRenderer.bind(this);
        let cache = null
        let first = false;
        let rowHeightFunction = (params: any): any => {
            let row = rows[params.index];

            if (row instanceof sl.GroupHeader) {
                
                return 30;
            }
            if (row instanceof sl.GroupEnd) {
                return 10;
            }
            if (row instanceof sl.SectionHeader) {
                
                return 40;
            }

            return 38;
        }
        if (this.props.mobileView) {
           
            renderer = this._mobileRowRenders.bind(this);
            rowHeightFunction = this._cache.rowHeight
            cache = this._cache
            /*(params: any):any => {
                let row = rows[params.index];
    
                if (row instanceof sl.GroupHeader) {
                    return 30;
                }
                if (row instanceof sl.GroupEnd) {
                    return 10;
                }
                if (row instanceof sl.SectionHeader) {
    
                    return 40;
                }
    
                return 66;
            }*/
        }
     //   console.log('Focus Index ' + this.state.selectedState.focusedIndex)
       // let containerHeight = this.props.mobileView?'100vh':"calc(100vh-61)";
        return (
            <div style={{ display: 'flex' }}>
  <div className={this.props.mobileView?"superlist-container-mobile":"superlist-container-web"}         >
 
                <AutoSizer >
                    {({ width, height }) => {
                        let h = this.props.mobileView?height:height
                        return (
                            <ListWrapper
                                ref={(ref) => {
                                    me.list = ref;
                                    
                                    if (ref && me.props.connect.state.scrollingTarget != ref._listRef) {
                                        me.props.connect.dispatch(actions.ar_setScrollingTarget(ref._listRef))
                                    }
                                
                                    //me._registerList(ref._list);

                                }}

                                style={{ outline: 'none'}}
                                overscanRowCount={0}
                                onScroll={(e) => {
                                    let s = { ...me.state };
                                    s.scrolledTime = new Date();
                                    s.selectedState.focusedIndex = -1;
                                    //console.log("scroll "+JSON.stringify(e))
                                    me.setState(s)
                                }}

                                rowCount={rows.length}
                                deferredMeasurementCache={cache}
                                rowHeight={rowHeightFunction}
                                rowRenderer={renderer}
                                cacheBuster={this.state.todoQueryId + ':' + this.state.rows.length + ':' + this.state.mode+":"+this.state.sortByCreateDate}
                                scrollToIndex={this.state.selectedState.focusedIndex && !this.state.scrolledTime ? this.state.selectedState.focusedIndex : undefined}
                                width={width}
                                height={h}
                                onBlur={(e) => {
                                    me.deselectAll(false)
                                }}
                                onFocus={(e) => {

                                    // me.changeFocus(true, false);
                                }}
                            />
                        )
                    }}
                </AutoSizer>
                {this.state.snoozePopup && (
                    <SnoozeTyper
                        connect={this.props.connect}
                        defaultDate={this.state.snoozeDefaultDate}
                        snoozed={this.setSnoozeDate.bind(this)}
                        onClose={() => {
                            let state = { ...me.state };
                            state.snoozePopup = false;
                            state.snoozeDefaultDate = null;
                            me.setState(state);
                        }}
                    />
                )}
                {this.state.bulkTagPopup && (
                    <BulkTag
                        connect={this.props.connect}
                        todos={this.getFocusedOrSelectedTodos()}
                        onDone={(changes: TagChanges) => {
                            me.setTagsOnSelected(changes);
                        }}
                        onClose={() => {
                            let state = { ...me.state };
                            state.bulkTagPopup = false;
                            me.setState(state);
                        }}
                    />
                )}
            </div>
            </div>

        );






    }
}

export function mapStateToProps(state: SuperListProps) {
    //withStyles(loginPageStyle)(LoginPage)
    return {
        //   connect: state

    }



}
/*
export default connect(
    mapStateToProps,
    null
)(withStyles(styles as any)(SuperList));
*/
//@ts-ignore
export default SuperList