import React from 'react';
import { fade, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';

import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import * as model from "../lib/model"
import NavigationIcon from '@material-ui/icons/Navigation';
import * as actions from "../lib/ActionReducer"
import * as material from "material-ui";
import * as pApi from "../../shared/papi/papi-core";// "../../../server/papi/papi-core";
const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
    },


    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },

    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
}));

export interface TopMenuProps{
    onJump:()=>void
   
}
export default function TomMenu(props:TopMenuProps) {
    let me = this;
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };


    let inputRef
   
    return (
 
                <Toolbar>
                     <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="open drawer"
          >
            <MenuIcon />
          </IconButton>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <NavigationIcon />
            </div>
            <InputBase
              placeholder="Jump to"
              onFocusCapture={(e)=>{
                e.stopPropagation();
                e.preventDefault();
                return false
              }}
              
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              onClick={(e)=>{
                
                e.stopPropagation();
                e.preventDefault();

                //@ts-ignore
                e.target.blur()
                //me.inputRef.current.blur()
                window['lastPageBeforeSearch'] = window.location.hash;
                props.onJump();
                return false
              }}
              onFocus={(e)=>{
                e.stopPropagation();
                e.preventDefault();

              }}
              inputProps={{ 'aria-label': 'search' }}
            />
           
          </div>
                    <div className={classes.grow} />
                    
                        <IconButton aria-label="show 17 new notifications" color="inherit">
                           
                                <SearchIcon onClick={(e)=>{
                                        
                                    e.stopPropagation();
                                    e.preventDefault();
                                    window['lastPageBeforeSearch'] = window.location.hash;
                                    window.location.hash =model.ViewState.getDefaultHash(model.AppSections.search,this.props.connect.state.viewState);
                                }} />
                         
                        </IconButton>

                   

                </Toolbar>
           

    );
}
