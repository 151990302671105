

const fetch = require('node-fetch');
class restHelper {
  fetchLocal(url: string, action: string, obj?: any): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      try {
        let token = null;
        let root = "";
        try {
          token = window === undefined ? null : window["token"];
          root =
            window === undefined && window["apiRootUrl"]
              ? window["apiRootUrl"]
              : "";
        } catch (e) {}
        var body = {
          method: action,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            token: token
          },
          body: obj ? JSON.stringify(obj) : null
        };
        // let root =window['apiRootUrl']?window['apiRootUrl']:'';
        var response = await fetch(root + url, body);
        if (response.status > 299)
          return reject(response.status + " " + response.statusText);
        try {
          let responseJson = await response.json();
          resolve(responseJson);
        } catch (e) {
          resolve(null);
        }
        // resolve(responseJson)
      } catch (e) {
        reject(e);
      }
    });
  }
  get(url): Promise<any> {
    return this.fetchLocal(url, "GET");
  }
  delete(url): Promise<any> {
    return this.fetchLocal(url, "DELETE");
  }
  post(url, body: any): Promise<any> {
    return this.fetchLocal(url, "POST", body);
  }
  put(url, body: any): Promise<any> {
    return this.fetchLocal(url, "PUT", body);
  }
  patch(url, body: any): Promise<any> {
    return this.fetchLocal(url, "PATCH", body);
  }
}

export default restHelper;
