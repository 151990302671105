
import * as pApi from "../papi/papi-core"
import markdownToDelta from "./markdownToDelta"
const matchAll = require("match-all");
//const Quill =  require("quill");
/*let Block = Quill.import("blots/block");
let BlockEmbed = Quill.import("blots/embed");
let ContainerBlot = Quill.import("blots/container");
let Delta = Quill.import("delta");
*/

//let Parchment = require('quill/modules/') as any
//let BlockEmbed = require('quill/blots/embed') as any
//let ContainerBlot = require('quill/blots/container') as any
let Delta = require('quill-delta') as any
//let Block = require('quill/blots/block') as any
//let $ = require('jquery')
class DocumentProperties {
    title: string;
    shortDescription: string;
    tags: Array<string>;
    todos: Array<pApi.ToDoItem> = new Array<pApi.ToDoItem>();
}
/*
function isLine(blot) {

    return blot instanceof Block || blot instanceof BlockEmbed;
}*/
export function getTagsFromOps(ops: any[]): Array<string> {
    var retval = new Array<string>();
    for (var i in ops) {
        if (ops[i].insert && ops[i].insert.mention) {
            retval.push(ops[i].insert.mention.value);
        }
    }
    return retval;
}
export function tagsChanged(tag1: Array<string>, tag2: Array<string>): boolean {

    //tag1 = tag1.filter(x => this.tags.findIndex(y => y != x) != -1).sort()
    //tag2 = tag2.filter(x => this.tags.findIndex(y => y != x) != -1).sort()

    //
    return !compareArray(tag1, tag2);
}
function compareArray(arr1: Array<string>, arr2: Array<string>): boolean {
    if ((arr1 && !arr2) || (!arr1 && arr2)) return false;
    if (arr1.length != arr2.length) return false;

    for (var i in arr1) {
        /* if(arr1[i]!=arr2[i])
          return false*/

        if (arr2.filter(x => x == arr1[i]).length == 0) {
            return false;
        }
    }
    return true;
}
/*
const getLines = (blot, blotIndex, blotLength) => {
    let lines = [];
    let lengthLeft = blotLength;
    blot.children.forEachAt(
        blotIndex,
        blotLength,
        (child, childIndex, childLength) => {
            if (isLine(child)) {
                lines.push(child);
            } else if (child instanceof ContainerBlot) {
                lines = lines.concat(getLines(child, childIndex, lengthLeft));
            }
          

            lengthLeft -= childLength;
        },
    );
    return lines;
};*/
export function deduplicateTags(tags: Array<string>): Array<string> {
    var uniqueNames = new Array<string>();
    
    tags.forEach((el,index) =>{
        
        let exists = uniqueNames.find(x=>x == el);
        if(!exists)
        {
            uniqueNames.push(el);
        }
    })
 
    /*
    $.each(tags, function (i, el) {
        if ($.inArray(el, uniqueNames) === -1) uniqueNames.push(el);
    });*/
    //console.log("TAGS " + uniqueNames.join(','))
    return uniqueNames;
}
function getTodoFromLine(delta, list) {
    var todo = new pApi.ToDoItem();
    let customGetText = function (ops: []) {
        ;
        return ops.map(function (op: any) {
            if (typeof op.insert === 'string') {
                return op.insert;
            }
            else {
                return " "
            }

        }).join('');
    }
    /* list:
checked: false
id: "MD5_O4pTRdM9BfvN8yL"
mode: null
priority: null*/

    todo.description = customGetText(delta.ops);//line.domNode.innerText;
    todo.checked = list.checked

    todo.tags = getTagsFromOps(delta.ops)
    var pExisted = false;
    var p = list.priority
    if (p) {
        p = parseInt(p);
        if (!isNaN(p)) {
            todo.priority = p;
        } else todo.priority = pApi.ToDoItemPriority.Normal;
    }

    todo.id = list.id

    return todo;
}
export function getDocumentProperties(contents: any): DocumentProperties {
    let props = new DocumentProperties();
    props.tags = [];
    props.shortDescription = ""
    let delta = new Delta(contents)
    let index = 0;
    
    delta.eachLine((line, format) => {
        
        if (index == 0 && format.header) {
            props.title = getText(line);
        }
        if (index > 0 && index < 3) {
            let previewText = getText(line);
            if (props.shortDescription.length < 10) {
                props.shortDescription += previewText.substr(0, 10 - props.shortDescription.length);
            }
        }
        /*
        list:
checked: false
id: "MD5_O4pTRdM9BfvN8yL"
mode: null
priority: null
        */
        if (format.list && format.list.id) {
            props.todos.push(getTodoFromLine(line, format.list))
        }
        else {

            props.tags = props.tags.concat(getTagsFromOps(line.ops))
        }
        index++;
    })
    props.tags = deduplicateTags(props.tags);
    props.todos.forEach(todo => {
        todo.tags = deduplicateTags(props.tags.concat(todo.tags));
    })

    return props;
}
let getText = function (contents): string {
    return contents.filter(op => typeof op.insert === 'string')
        .map(op => op.insert)
        .join('');
}

export function NoteFromMarkdown(ctx: pApi.ICtx, markdown: string, createTodo: boolean,exitingNote:pApi.Note): Promise<pApi.Note> {

    return new Promise<pApi.Note>(async (resolve, reject) => {
        try {
            ;
            const ops = markdownToDelta(markdown);
            ops.map(obj => {
                return Object.assign({}, obj);
              });
            const text = getText(ops)

            let props = getDocumentProperties(ops);
            let note = new pApi.Note();
            if(exitingNote)
            {
                note.id = exitingNote.id;
                note.created = exitingNote.created;
                note.is_new=false;
            }

            note.title = props.title;
            note.tags = props.tags;


            let match = matchAll(text,/(\s|^)(\#[A-za-z0-9\/]+)/g);
            let tags = new Array<string>();
            var cont :boolean = true;
            while(cont)
            {
                let raw = match.nextRaw();
                if(raw)
                    tags.push(raw[2].replace("#",""));
                cont = raw;
            }
            note.tags = tags;
            note = await ctx.Notes.save(note);
            var newContent = new pApi.NoteContent();
            newContent.contentType = pApi.ContentType.quillJS;
            newContent.storageType = ctx.Notes.getDefaultStorageType();// pApi.StorageType.snapshot;
            newContent.content = ops;
            tags.forEach(tag => {
                newContent.content.push({
                    insert: {
                        mention: {
                            key: tag,
                            value: tag
                        }
                    }
                })
            });


            note = await ctx.Notes.setContent(note, newContent)

            if (createTodo) {
                let newTodo = new pApi.ToDoItem();
                newTodo.description = note.title ? "review " + note.title : "review Note";
                newTodo.note_id = note.id;
                newTodo.tags = tags;
                await ctx.Todos.save(newTodo);
            }
            resolve(note);
        }
        catch (e) {
            reject(e);
        }
    })
}