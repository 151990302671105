import * as model from "./model";

import { Dictionary } from "lodash";
import hotkeys from 'hotkeys-js'
let _ = require("lodash");

export interface IHotKeyState {
  hotkey: model.HotkeyPress;
}

export function processHotKey<T extends IHotKeyState>(
  target: string,
  globalState: model.IState,
  currentComponentStateCopy: T,
  event: Dictionary<((globalState: model.IState, componentState: T) => T)>
): T {
 
  let hk = globalState.hotkey;
  if (hk.compare(currentComponentStateCopy.hotkey) == false) {
    console.log("hotkey:it changed");
    let newState = currentComponentStateCopy;
    newState.hotkey = hk;
    if (hk.currentFocus == target) {
      if (event[hk.hotkey]) {
        newState = event[hk.hotkey](globalState, newState);
        console.log("hotkey:HandleKey " + hk.hotkey);
      }
    }
    return newState;
  }
  return null;
}
interface Command {
  name: string;
  buttons: Array<string>;
}

export class hotKeyAction {
  key: string;
  type:HotKeyActionType
  constructor(
    key: string,
    type:HotKeyActionType
  ) {
    this.key = key;
    this.type = type;

  }
}
let editingTodo = function(state: model.IState): boolean {
  return state.editTodo != null;
};
let inListView = function(state: model.IState): boolean {
  if (state.editTodo != null) return false;
  switch (state.viewState.appSection) {
    case model.AppSections.notes:
    case model.AppSections.inbox:
    case model.AppSections.today:
    case model.AppSections.snoozed:
    case model.AppSections.thisweek:
    case model.AppSections.bydate:
      if (state.viewState.todoId || state.viewState.noteOpen || state.editTodo)
        return false;
      else {
        return true;
      }
    default:
      return false;
  }
};
function callDispatch(dispatch: any, func: any) {
  _.delay(() => {
    dispatch(func);
  }, 0);
}


export class HotKeyMapping {
  key: string;
  action: HotKeyActionType;
  constructor(key: string, action: HotKeyActionType) {
    this.key = key;
    this.action = action;
  }
}
export class HotKeyHandler {
  component: string;
  focus: string;
  exclusive:boolean;
  actions: Map<HotKeyActionType, () => boolean>;
}
export class HotKeyActionDescription
{
    //actionType:HotKeyActionType;
    public component:string;
    constructor(public actionType:HotKeyActionType,
      public action:()=>boolean,
      public showInCommand:boolean = false,
      public description?:string,
      public category?:string,
      public searchTerms?:Array<string>,
      public enabled?:()=>boolean,
    )
    {} 
}
export enum HotKeyActionType {
  esc = "esc",
  createTodo= "createTodo",
  next = "next",
  prev ="prev",
  space = "space",
  enter="enter",
  openLink="openLink",
  complete="complete",
  snooze='snooze',
  search='search',
  jumpTag='jumpTag',
  createNote="createNote",
  undo="undo",
  nextSelect="nextSelect",
  prevSelect="prevSelect",
  star="star",
  tag='tag',
  nextTab='nextTab',
  prevTab='prevTab',
  hideFromInbox='hideFromInbox',
  forceHideFromInbox="forceHideFromInbox",
  optionSnooze="optionSnooze",
  optionComplete="optionComplete",
  optionStar="optionStar",
  optionHide="optionHide",
  optionCreateTodo="optionCreateTodo",
  copy="copy"
}
export class HotKeyEngine {
  actions: any = {};
  HotKeyActionDescriptions:Array<HotKeyActionDescription> = new Array<HotKeyActionDescription>();
  hotkeys: Array<HotKeyMapping> = [
    new HotKeyMapping("esc", HotKeyActionType.esc),
    new HotKeyMapping("c", HotKeyActionType.createTodo),
    new HotKeyMapping("s", HotKeyActionType.star),
    new HotKeyMapping("t", HotKeyActionType.tag),
    new HotKeyMapping("j", HotKeyActionType.next),
    new HotKeyMapping("k", HotKeyActionType.prev),
    new HotKeyMapping("down", HotKeyActionType.next),
    new HotKeyMapping("up", HotKeyActionType.prev),
    new HotKeyMapping("space", HotKeyActionType.space),
    new HotKeyMapping("enter",HotKeyActionType.enter),
    new HotKeyMapping("e",HotKeyActionType.complete),
    new HotKeyMapping("h", HotKeyActionType.snooze),
    new HotKeyMapping("/",HotKeyActionType.search),
    new HotKeyMapping("g",HotKeyActionType.jumpTag),
    new HotKeyMapping("z",HotKeyActionType.undo),
    new HotKeyMapping('u',HotKeyActionType.hideFromInbox),
    new HotKeyMapping('shift+u',HotKeyActionType.forceHideFromInbox),
    new HotKeyMapping("shift+c",HotKeyActionType.createNote),
    new HotKeyMapping("shift+j",HotKeyActionType.nextSelect),
    new HotKeyMapping("shift+k",HotKeyActionType.prevSelect),
    new HotKeyMapping("shift+down",HotKeyActionType.nextSelect),
    new HotKeyMapping("shift+up",HotKeyActionType.prevSelect),
    new HotKeyMapping("tab",HotKeyActionType.nextTab),
    new HotKeyMapping("shift+tab",HotKeyActionType.prevTab),
    new HotKeyMapping('o',HotKeyActionType.openLink),
    new HotKeyMapping('ctrl+h',HotKeyActionType.optionSnooze),
    new HotKeyMapping('ctrl+e',HotKeyActionType.optionComplete),
    new HotKeyMapping('ctrl+s',HotKeyActionType.optionStar),
    new HotKeyMapping('ctrl+u',HotKeyActionType.optionHide),
    new HotKeyMapping('ctrl+c',HotKeyActionType.optionCreateTodo),
    new HotKeyMapping('command+c',HotKeyActionType.copy)
    //option

  ];
  static instance():HotKeyEngine
  {
      if(!window['hotkeyengine'])
      {
          window['hotkeyengine'] = new HotKeyEngine();
      }
      return window['hotkeyengine']
  }
  handlers: Array<HotKeyHandler> = new Array<HotKeyHandler>();
  invokeActionType(actionType:HotKeyActionType){
      let handler = this.handlers.find(x=>x.actions.has(actionType))
 
      if(handler)
      {
          let at = handler.actions.get(actionType);
          if(at)
          {
            at();
          }
      }
  }
  getKey(actionType:HotKeyActionType):string{
    return this.hotkeys.find(x=>x.action == actionType).key;
  }
  getActiveCommands():Array<HotKeyActionDescription>
  {
     return this.HotKeyActionDescriptions.filter(x=>x.showInCommand && (!x.enabled || x.enabled()));
  }
  registerHandlersWithActions(
    component: string,
    focus: string,
    exclusive:boolean,
    actionDescriptions: Array<HotKeyActionDescription>
  )
  {
      actionDescriptions.forEach(x=>x.component = component)
      this.HotKeyActionDescriptions = this.HotKeyActionDescriptions.concat(actionDescriptions);
      let hm = new Map<HotKeyActionType, () => boolean>();
      actionDescriptions.forEach(element => {
          
          hm.set(element.actionType,element.action);
      });
      this.registerHandlers(component,focus,exclusive,hm);
  }
  registerHandlers(
    component: string,
    focus: string,
    exclusive:boolean,
    actions: Map<HotKeyActionType, () => boolean>
  ) {
    console.log('hotkey-register',component+' focus:'+focus+' exclusive:'+exclusive)
    if (this.handlers.find(x => x.component == component)) {
      
      throw "hotkey:"+component+" already registered.";
    }
    let hkm = new HotKeyHandler();
    hkm.actions = actions;
    hkm.component = component;
    hkm.focus = focus;
    hkm.exclusive = exclusive;
    this.handlers.push(hkm);
  }
  unregisterHandlers(component: string) {
    this.handlers = this.handlers.filter(x => x.component != component);
    this.HotKeyActionDescriptions = this.HotKeyActionDescriptions.filter(x=>x.component != component)
  }
  init() {
    let me = this;
    for (var i in this.hotkeys) {
      let hotkey = this.hotkeys[i];
      function generate(hk: HotKeyMapping) {
        return function(event, handler) {
          event.preventDefault();
         
          let index = me.handlers.length - 1;
          while (index > -1) {
            let h =  me.handlers[index];
            let handler = h.actions.get(hk.action);
            if (handler) {
              let handled= handler();
              if (handled) {
                console.log('hotkey:'+hk.action+':'+h.component);
                return;
              } else {
                console.log('hotkey:SKIP:'+hk.action+':'+h.component);
              }
            }
            if(h.exclusive)
            {
                return;
            }
            index--;
          }
        };
      }
      hotkeys(hotkey.key, generate(hotkey));
    }
  }

  constructor() {
      this.init();
  }
}

