



export class FireStoreDateConverter
{
 
    constructor(private isInstance:(item:any)=>boolean,private fromDate:(d:Date)=>any,private toDate:(ms:number,ns:number)=>Date)
    {

    }
    convertToFireStoreDate(date: any ): any {
    if (!date)
      return null;
    if(this.isInstance(date)){
   // if (date instanceof FireStoreDate) {
      return date;
    }
    if (date instanceof Date) {
      return this.fromDate(date as Date);
  
    }
    throw "Convert To Unknown Date " + date;
  }
  convertFromFireStoreDate(date: any): Date {
   
    if (!date)
      return null;
    if (this.isInstance(date)){//date instanceof firebase.firestore.Timestamp) {
      let d = date;
      return d.toDate();
    }
    if (date.nanoseconds != null) {
      return this.toDate(date.seconds as number, date.nanoseconds as number)
    }
  
    if (date._nanoseconds != null) {
      return this.toDate(date._seconds as number, date._nanoseconds as number)
    }
    if (date instanceof Date)
      return date;
    if (typeof date == 'string') {
      return new Date(date as string);
    }
    throw "Convert From Unknown Date " + date;
  }
   toPureJavascriptWithFireStoreDate(obj: any): any {
    if (obj === null || typeof obj !== "object" || "isActiveClone" in obj)
      return obj;
  
    var temp = {};
    if (obj instanceof Date) temp = this.toPureJavascriptWithFireStoreDate(this.fromDate(obj))
    else
      for (var key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          obj["isActiveClone"] = null;
          var r = this.toPureJavascriptWithFireStoreDate(obj[key]);
          if (typeof r != "undefined") temp[key] = r;
          delete obj["isActiveClone"];
        }
      }
  
    return temp;
  }
}
