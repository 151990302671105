import { handleActions, Action, ActionFunction1, ActionFunction2, ActionFunction3, createAction } from 'redux-actions';
//import { assign } from 'lodash';
import { Dispatch, applyMiddleware, createStore } from 'redux';
import * as model from './model';


import { createAction as createThunkAction } from 'redux-actions-helper';
import { createAction as createActionNonThunk } from 'redux-actions';
var uuid = require('uuid') 


export function CreateQuickReducer(reducers:any,funcName: string, f: (model: model.IState) => model.IState): () => ActionFunction1<void, Action<void>> {
    var x = createThunkAction<void>(funcName, (): void => {
        return null;
    });
    reducers[funcName]=(state: model.IState, action: Action<void>): model.IState => {
            var newState = { ...state };
            return f(newState)
        }
    return x;
}
export function CreateQuickReducer0<T>(reducers:any,funcName: string, f: (model: model.IState, value: T) => model.IState): () => ActionFunction1<T, Action<T>> {
    var x = createThunkAction<T>(funcName, (): T => {
        return null;
    });
    reducers[funcName]=(state: model.IState, action: Action<T>): model.IState => {
            var newState = { ...state };
            return f(newState, action.payload)
        }
    return x;
}
export function CreateQuickReducerNonThunk0<T>(reducers:any,funcName: string, f: (model: model.IState, value: T) => model.IState): () => any{
    var x = createAction<T>(funcName, (): T => {
        return null;
    });
    reducers[funcName]=(state: model.IState, action: Action<T>): model.IState => {
            var newState = { ...state };
            return f(newState, action.payload)
        }
    return x;
}
export function CreateQuickReducer1<T>(reducers:any,funcName: string, f: (model: model.IState, value: T) => model.IState): (o: T) => ActionFunction1<T, Action<T>> {
    var x = createThunkAction<T>(funcName, (obj: T): T => {
        
        return obj;
    });
    reducers[funcName]=(state: model.IState, action: Action<T>): model.IState => {
            var newState = { ...state };
            return f(newState, action.payload)
        }
    return x;
}


export function CreateSimpleActionReducer1<T, L>(reducers:any,funcName: string,
    action: (obj: T) => L,
    reducer: (state: model.IState, paylot: L) => model.IState): ActionFunction1<T, Action<L>> {
    var x = createThunkAction<T, L>(funcName, action);
    reducers[funcName] = (state: model.IState, action: Action<L>): model.IState => {
       
        var newState = { ...state };
        return reducer(newState, action.payload)
    }
    return x;
}

export function CreateSimpleActionReducer2<T,Y, L>(reducers:any,funcName: string,
    action: (obj1: T,obj2:Y) => L,
    reducer: (state: model.IState, payload: L) => model.IState): ActionFunction2<T,Y, Action<L>> {
    var x = createThunkAction<T,Y, L>(funcName, action);
    reducers[funcName] = (state: model.IState, action: Action<L>): model.IState => {
        
        var newState = { ...state };
        return reducer(newState, action.payload)
    }
    return x;
}
export function CreateSimpleActionReducer3<T,Y,U, L>(reducers:any,funcName: string,
    action: (obj1: T,obj2:Y,obj3:U) => L,
    reducer: (state: model.IState, payload: L) => model.IState): ActionFunction3<T,Y,U, Action<L>> {
        
    var x = createThunkAction<T,Y,U,L>(funcName, action);
    reducers[funcName] = (state: model.IState, action: Action<L>): model.IState => {
       
        var newState = { ...state };
        return reducer(newState, action.payload)
    }
    return x;
}

