import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Toolbar from '@material-ui/core/Toolbar';
import * as model from "../lib/model"
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
/*
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
*/
function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,

  },toolbar: {
   
    paddingBottom: theme.spacing(4),
  },
}));
interface BottomMenuProps
{
    viewState:model.ViewState
}
export default function ScrollableTabsButtonAuto(props:BottomMenuProps) {
  const classes = useStyles();
 // const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    let newHash;
    switch(newValue)
    {
        case(0):newHash=model.ViewState.getDefaultHash(model.AppSections.inbox,props.viewState);break;
        case(1):newHash=model.ViewState.getDefaultHash(model.AppSections.today,props.viewState);break;
        case(2):newHash=model.ViewState.getDefaultHash(model.AppSections.snoozed,props.viewState);break;
        case(3):newHash=model.ViewState.getDefaultHash(model.AppSections.notes,props.viewState);break;

    }
    if(newHash)
    {
        window.location.hash = newHash;
    }
  };
  let index = 0;
  switch(props.viewState.appSection)
  {
      case(model.AppSections.inbox): index = 0;break;
      case(model.AppSections.today): index = 1;break;
      case(model.AppSections.snoozed): index = 2;break;
      case(model.AppSections.notes): index = 3;break;
      default:index = -1
  }
  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
      <Toolbar className={classes.toolbar}>
        <Tabs
          value={index}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab label="Inbox" {...a11yProps(0)} />
          <Tab label="Today" {...a11yProps(1)} />
          <Tab label="Upcoming" {...a11yProps(2)} />
          <Tab label="Notes" {...a11yProps(3)} />

        </Tabs>
       </Toolbar>
      </AppBar>
    
    </div>
  );
}


