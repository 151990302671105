import * as React from "react";
import { SimpleShell } from "../Shell";
import OldMobileShell from "../Mobile/ShellMaterial"
import MobileShell from "../Mobile/ShellFast";
import Login from "../Public/Login";
import { HashRouter as Router, Route, Link, Switch } from "react-router-dom";
import * as model from '../lib/model'
import * as actions from "../lib/ActionReducer";
import { Track } from "../../shared/track";
import { connect } from "react-redux";

interface RouterPropes { }
interface RouterState { }
const NoMatch = ({ location }) => (
  <div>
    <h3>
      No match for <code>{location.pathname}</code>
    </h3>
  </div>
);

class ShellWrapperState {

}

function getHash() {
  let urlParams = {};

  let split = window.location.hash.split("?");
  if (split.length == 1) {
    return {};
  }
  let match,
    pl = /\+/g,  // Regex for replacing addition symbol with a space
    search = /([^&=]+)=?([^&]*)/g,
    decode = function (s) {
      return decodeURIComponent(s.replace(pl, " "));
    },
    query = split[1];

  while (match = search.exec(query)) {
    if (decode(match[1]) in urlParams) {
      if (!Array.isArray(urlParams[decode(match[1])])) {
        urlParams[decode(match[1])] = [urlParams[decode(match[1])]];
      }
      urlParams[decode(match[1])].push(decode(match[2]));
    } else {
      urlParams[decode(match[1])] = decode(match[2]);
    }
  }
  //this._hashCache = urlParams;
  return urlParams


}
function extendMatcH(match: any) {

  let hash = getHash()
    ;
  for (var i in hash) {
    match.params[i] = hash[i]
  }
  return match
}
function populateStateFromHash(state: model.IState, match): model.IState {
  var newState = { ...state };
  var nv = model.ViewState.viewStateFromRoute(match);
  newState.viewState = nv;
  return newState;
}



let Shell = (props: model.ConnectProps) => {
  const [connect, setConnect] = React.useState<model.ConnectProps>(new model.ConnectProps());
  React.useEffect(() => {
    var newState: model.ConnectProps = { ...connect } as model.ConnectProps
    newState.getPresentationMode = connect.getPresentationMode;
    newState.inMobileNoteView = connect.inMobileNoteView;
    //hackky mc hack hack

    newState.dispatch = props.dispatch;
    newState.match = props.match;
    let match = extendMatcH(props.match)
    newState.state = populateStateFromHash(
      props.state,
      match
    );
   
    setConnect(newState)

  }, [props.match])
  if (!connect.state) {
    return (<div>{JSON.stringify(props.match)}</div>)
  }
  if (
    !connect.state.user ||
    connect.state.dojoSettingsState != model.LoadingState.Completed
  ) {
    if (
      connect.state.user &&
      connect.state.dojoSettingsState == model.LoadingState.NotLoaded
    ) {
      connect.dispatch<any>(actions.ar_loadSettings());
    }
    {
      return <div />;
    }
  }

  if (window["hidesplash"] && !window["hidesplash-shell"]) {
    try {
      window["hidesplash"]();
      window["hidesplash-shell"] = true;
    } catch (e) {
      console.error("hide splash shell " + e);
    }
  }

  window["loaded"] = true;
  var shell = null;

  //return <SimpleShell connect={connect} />


  if (connect.state.dojoSettings && connect.getPresentationMode() != model.PresentationMode.mobile) {
    return <SimpleShell connect={connect} />
  }
  else {
    if (connect.state.dojoSettings) {
      if (connect.state.dojoSettings.newMobileMode) {
        return <MobileShell connect={connect} />
      }
      else {
        return <OldMobileShell connect={connect} />
      }
    }

  }

}

/*
const DefaultPage = ({ location:any,connect:model.ConnectProps }) => {
if (
  localStorage.getItem("lastHash") &&
  localStorage.getItem("lastHash") != "/" &&
  localStorage.getItem("lastHash") != "#/" &&
  localStorage.getItem("lastHash") != "" &&
  localStorage.getItem("lastHash") != "undefined" &&
  localStorage.getItem("lastHash") != "null" &&
  localStorage.getItem("lastHash") != "#/null" &&
  localStorage.getItem("lastHash") != "#/inbox"
) {
  var h = localStorage.getItem("lastHash");
  localStorage.removeItem("lastHash");
  window.location.hash = h;
} else {
  return <Shell2 match="" state={state} dispatch={}  />;
}
return <div />;
};*/
const IsDefaultPage = function () {
  if (
    localStorage.getItem("lastHash") &&
    localStorage.getItem("lastHash") != "/" &&
    localStorage.getItem("lastHash") != "#/" &&
    localStorage.getItem("lastHash") != "" &&
    localStorage.getItem("lastHash") != "undefined" &&
    localStorage.getItem("lastHash") != "null" &&
    localStorage.getItem("lastHash") != "#/null" &&
    localStorage.getItem("lastHash") != "#/inbox"
  ) {
    var h = localStorage.getItem("lastHash");
    localStorage.removeItem("lastHash");
    window.location.hash = h;
  } else {
    return <div />;
  }
  return null;
};
const mapStateToProps = function (thestate) {

  var state = thestate;
  return {
    state: state
  };
};
let ShellWrapper = connect(mapStateToProps)(Shell);
class MainRouter extends React.Component<model.ConnectProps, RouterState> {


  lastRoute: any;
  render() {
    const { dispatch } = this.props;



    // var dispatchActions =actions;//
    var stateWithHash = this.props.state.context.setDispatchAndState(
      dispatch,
      this.props.state
    );
    var dispatchActions = { ...actions };

    //console.log(pApi.(this.props.match.params.filter));
    //var boundActionCreators2 = bindActionCreators([actions.handleToggleCollapsedNav,, dispatch);

    switch (this.props.state.AuthState) {
      case model.AuthState.Uninitialized:
        dispatch<any>(actions.startApp(dispatch));
        break;
      //todo splash screen
      //return this.dummyRoute(<div>Starting Up</div>);
      case model.AuthState.Initializing:
        break;
      //return this.dummyRoute(<div>Loading</div>);
      case model.AuthState.Unauthenticated:
        //return this.dummyRoute(<Login />);

        if (window.location.hash.indexOf("#/login") == -1) {
          var vs = new model.ViewState();
          vs.appSection = model.AppSections.login;
          vs.redirect = window.location.hash;
          window.location.hash = vs.getHash();
        }
        return (
          <Router>
            <Switch>

              <Route component={Login} />
            </Switch>
          </Router>
        );
        break;
      case model.AuthState.Authenticated:

        break;
    }

    if (window.location.hash != this.lastRoute) {
      Track.PageView(window.location.hash);
      this.lastRoute = window.location.hash;
    }
    //let ShellWrapper = (<Shell {...this.props} />)
    return (
      <Router>
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/inbox/note/:noteId" component={ShellWrapper} />
          <Route path="/inbox/:todoId" component={ShellWrapper} />
          <Route path="/inbox" component={ShellWrapper} />

          <Route path="#/inbox" component={ShellWrapper} />
          <Route path="#/inbox/:todoId" component={ShellWrapper} />
          <Route path="/today/note/:noteId" component={ShellWrapper} />
          <Route path="/today" component={ShellWrapper} />
          <Route path="/bydate/note/:noteId" component={ShellWrapper} />
          <Route path="/bydate" component={ShellWrapper} />

          <Route path="/thisweek/note/:noteId" component={ShellWrapper} />
          <Route path="/thisweek" component={ShellWrapper} />
          <Route path="/upcoming/note/:noteId" component={ShellWrapper} />
          <Route path="/archived" component={ShellWrapper} />
          <Route path="/upcoming" component={ShellWrapper} />

          <Route path="/objectives" component={ShellWrapper} />
          <Route path="/search/note/:noteId" component={ShellWrapper} />

          <Route path="/search/:query" component={ShellWrapper} />
          <Route path="/search" component={ShellWrapper} />
          <Route path="/setup" component={ShellWrapper} />

          <Route path="/settings/:settingSection" component={ShellWrapper} />
          <Route path="/settings" component={ShellWrapper} />
          <Route path="/help" component={ShellWrapper} />
          <Route
            path="/notes/f/:function/q/:query/:noteViewType/:noteId"
            component={ShellWrapper}
          />
          <Route
            path="/notes/f/:function/q/:query/:noteViewType"
            component={ShellWrapper}
          />
          <Route path="/notes/f/:function/q/:query" component={ShellWrapper} />
          <Route path="/notes/all/:noteViewType/:noteId" component={ShellWrapper} />
          <Route path="/notes/all" component={ShellWrapper} />
          <Route path="/" component={ShellWrapper} />
          <Route component={NoMatch} />
        </Switch>
      </Router>
    );

    ///#/notes/f/tag/q/work/show/
  }

}


export default connect(mapStateToProps)(MainRouter);
