import * as pApi from "../../../shared/papi/papi-core";


import * as actions from "../../lib/ActionReducer";
import * as model from "../../lib/model";
import { Dispatch } from "redux";
import { Track, TrackCategory, TrackEvent } from "../../../shared/track"
import moment from 'moment'
import firebase from "firebase/app"
var uuidV4 = require("uuid/v4");
var ImgCache = require("hacked-imgcache");



var $ = require('jquery')

export class NoteImage {
  internalUrl: string;
  externalUrl: string;
  src: string;
  id: any;
  toQuillObject() {
    var obj = {};
    if (this.externalUrl) obj["external-src"] = this.externalUrl;

    if (this.internalUrl) obj["internal-src"] = this.internalUrl;
    if (this.id) obj["image-id"] = this.id;

    return obj;
  }
}

export enum ImageUploadTrackerEnum 
{ utPendingUpload = 0,
   utError = 1, utUploading =2,
    utCached = 3, 
    utNotCached = 4, 
    utUploadedNotStored =5, 
    utDone =6 }
export class ImageTrackerRecord {
  imageId: string;
  noteId: any;
  cacheUrl: string;
  localUrl: string;
  externalUrl: string;
  status: ImageUploadTrackerEnum;
  updated: Date = new Date();
}
export class ImageUploadTracker {
  static getBag(): any {
    if (window['imageUploadTracker']) {
      return window['imageUploadTracker'];
    }
    let bag = this.getStorageBag()
    if (bag) {
      window['imageUploadTracker'] = bag;
    }
    else {
      window['imageUploadTracker'] = {};
    }
    return window['imageUploadTracker'];
  }
  static getStorageBag(): any {
   
    let local = localStorage.getItem("imageUploadTracker");
    if (!local) {
      local = "{}"
      localStorage.setItem("imageUploadTracker", local)

    }
    return JSON.parse(local);
  }
  static setValue(value: ImageTrackerRecord) {
  
    let bag = this.getBag();
    value.updated = new Date();
    bag[value.imageId] = value;
    window['imageUploadTracker'] = bag;
    //we don't want to persite uploading;
    if (value.status != ImageUploadTrackerEnum.utUploading) {
      let storageBag = this.getStorageBag();
      storageBag[value.imageId] = value;
      localStorage.setItem("imageUploadTracker", JSON.stringify(storageBag));
    }
  }
  static getValue(imageId: any): ImageTrackerRecord {
    let bag = this.getBag();
    return bag[imageId];
  }
  static removeValue(imageId: any) {
    let bag = this.getBag();
    delete bag[imageId]
    window['imageUploadTracker'] = bag;
    let storageBag = this.getStorageBag();
    delete storageBag[imageId]
    localStorage.setItem("imageUploadTracker", JSON.stringify(storageBag));
  }

}


export class GetImageUrlResult {
  url: string;
  externalUrl: string;
  constructor(url: string, externalUrl: string) {
    this.url = url;
    this.externalUrl = externalUrl;
  }
}
function checkImageExists(imageUrl):Promise<boolean> {
  return new Promise<boolean>((resolve,reject)=>{
    var imageData = new Image();
    imageData.onload = function () {
      
      resolve(true);
    };
    imageData.onerror = function (err) {
      
      console.error(err);
      resolve(false);
    };
    imageData.src = imageUrl;
  })
 
}
export class ImageResolver {
  getImageUrl(imageId: any, externalUrl: any): Promise<GetImageUrlResult> {
    let me = this;
    externalUrl = (externalUrl == "undefined") || !externalUrl || externalUrl==undefined ? null : externalUrl
    return new Promise<GetImageUrlResult>((resolve, reject) => {
      try {
        let imageData = ImageUploadTracker.getValue(imageId);

        let updateExternalUrl = null;
        ;
        if (!externalUrl && imageData) {
          //if (imageData.status == ImageUploadTrackerEnum.utUploadedNotStored) {
          updateExternalUrl = imageData.externalUrl
          externalUrl = imageData.externalUrl
          // }
        }

        //Normal flow - there is an external url and nothing pending
        if ((!imageData || imageData.status == ImageUploadTrackerEnum.utUploadedNotStored || imageData.status == ImageUploadTrackerEnum.utDone) && externalUrl) {
          //Cache Image
          if (!ImgCache.ready) {
            console.log("ImgCache:Not Ready use external " + externalUrl);
            return resolve(new GetImageUrlResult(externalUrl, updateExternalUrl))
          }
       
          ImgCache.isCached(externalUrl, async function (path, success) {
            ;
            if (success) {
              // already cached
              console.log("ImgCache:InCache " + externalUrl);
              ImgCache.getCachedFileURL(path, (url, entry) => {
                console.log("ImgCache:localurl " + entry);

                if (window["cordovaUrlFixer"]) {
                  entry = window["cordovaUrlFixer"](entry);
                  console.log("ImgCache:updated " + entry);
                  //@ts-ignore
                }
                if(imageData && imageData.status == ImageUploadTrackerEnum.utDone)
                {
                  console.log("ImgCache:Confirmation External Url in Document and cached.  Cleanup " + externalUrl);
                 /* ImgCache.removeFile(imageData.localUrl, ()=>{
                      console.log("ImgCache:Successfully cleaned up cache for "+imageData.localUrl );
                     // ImageUploadTracker.removeValue(imageData.imageId)
                  },(err)=>{
                    console.log("ImgCache:Could not remove from cache  "+imageData.localUrl+ " "+err );
                  })*/

                }
                return resolve(new GetImageUrlResult(entry, updateExternalUrl))

              });
            } else {
              console.log("ImgCache:Not incache set it to source " + externalUrl);
              // not there, need to cache the image
              ImgCache.cacheFile(externalUrl, function (path,url) {
                console.log("ImgCache:Cache " + externalUrl);
                ;
                return resolve(new GetImageUrlResult(url, updateExternalUrl))
                // ImgCache.useCachedFile(target);
              }, async function (err) {
                
                console.error("ImgCache:Cache  err" + err + " return " + externalUrl);
                
                let imageBeGood = await checkImageExists(externalUrl);
                  if (imageBeGood) {
                    console.log("ImgCache:Image does exists exterenally so return (probably cache problem) " + externalUrl);
                    return resolve(new GetImageUrlResult(externalUrl, updateExternalUrl))
                  }
                  else {
                    console.log("ImgCache:Image does NOT exists externally so remove it");
                    return resolve(new GetImageUrlResult(externalUrl, null))
                  }
                

              });
            }
          });
          return;
        }
        if (imageData) {
          if (imageData.status == ImageUploadTrackerEnum.utPendingUpload && imageData.localUrl) {
            console.log("ImgCache:Local File found. Pending Upload return " + imageData.localUrl)
            me.uploadFile(imageData)
            return resolve(new GetImageUrlResult(imageData.localUrl, null))
          }
          else {
            if (imageData.localUrl) {

              console.log("ImgCache:Local file found  but not pending upload return " + imageData.localUrl);
              resolve(new GetImageUrlResult(imageData.localUrl, updateExternalUrl));
              if (updateExternalUrl && imageData.status == ImageUploadTrackerEnum.utUploadedNotStored) {
                imageData.status = ImageUploadTrackerEnum.utDone;
                ImageUploadTracker.setValue( imageData)
                // ImageUploadTracker.removeValue(imageData.imageId);
              }
              return;
            }
            else {
              console.log("ImgCache:No public or local file reject "+JSON.stringify(imageData));
              return reject("No public or local file");
            }
          }
        }
        if (imageData && imageData.status == ImageUploadTrackerEnum.utDone) {
          console.log("ImgCache: Revert to done image to see if thaht helps");
          if (imageData.externalUrl) {
            return resolve(new GetImageUrlResult(imageData.localUrl, imageData.externalUrl));
          }
          if (imageData.localUrl) {
            return resolve(new GetImageUrlResult(imageData.localUrl, imageData.externalUrl));
          }
        }
        
        console.log("ImgCache:No public, local file or local record reject "+JSON.stringify(imageData));
        reject("No public, local file or local record reject");

      }
      catch (err) {
        console.log("ImgCache: getImageUrl "+err);
        reject(err);

      }
    })

  }
  setExternalUrlInNoteImage(state: model.IState, record: ImageTrackerRecord): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      try {
        let n = await state.context.Notes.load(record.noteId);
        let nc = await state.context.Notes.getContent(n);
        let found = false;
        for (var i in nc.content) {
          let ops = nc.content[i];
          if (ops.insert && ops.insert.image && ops.insert.image['image-id'] == record.imageId) {
            let imgrecord = ops.insert.image['image-id'];
            if (!imgrecord["external-src"]) {
              console.log("ImgCache: Found in content.  try to update")
              ops.insert.image["external-src"] = record.externalUrl;
              found = true;
              break;
            }
          }
        }
        if (found) {
          nc.contentType = pApi.ContentType.quillJS
          nc.storageType = state.context.Notes.getDefaultStorageType();// pApi.StorageType.snapshot;
          await state.context.Notes.setContent(n, nc);
          console.log("ImgCache: Found in content. updated!")
          return resolve()
        }


        reject("Unable to find image in note");
      }
      catch (e) {
        reject(e);
      }
    });

  }
  cleanUp(state: model.IState): Promise<void> {

    return new Promise<void>(async (resolve, reject) => {
      var one_day = 1000 * 60 * 60 * 24
      let d = ImageUploadTracker.getBag();
      console.log("ImgCache:Clean up Started ");
      let resolver = new ImageResolver();
      for (var i in d) {
        let record = d[i] as ImageTrackerRecord;
        try {
          ;
          if (record.status == ImageUploadTrackerEnum.utDone) {
           
            let lastUpdate = record.updated ? new Date(record.updated).getTime() : (one_day * 31);
            if ((new Date().getTime() - lastUpdate) > (one_day * 30)) {
              console.log("ImgCache:Delete Image - Skipped");
              //ImageUploadTracker.removeValue(record.imageId)


            }
           
          }
          else {
            ;
            console.log("ImgCache", JSON.stringify(record))
            if (record.status == ImageUploadTrackerEnum.utError) {
              console.log("ImgCache:Found problem upload" + record.localUrl);
              await resolver.uploadFile(record);
              ;
              record = ImageUploadTracker.getValue(record.imageId);
              ImageUploadTracker.setValue( record)
            }  
            if ((record.status == ImageUploadTrackerEnum.utUploadedNotStored || record.status == ImageUploadTrackerEnum.utPendingUpload) && record.noteId) {


              if (record.status == ImageUploadTrackerEnum.utUploadedNotStored) {
                  if(!await checkImageExists(record.externalUrl))
                  {
                    console.log("ImgCache: bad Image set it back to pending upload");
                    record.status = ImageUploadTrackerEnum.utPendingUpload
                  }
              }
              if (record.status == ImageUploadTrackerEnum.utPendingUpload) {
                console.log("ImgCache: resuming pending upload  " + record.imageId + " for note " + record.noteId);
                record.status = ImageUploadTrackerEnum.utUploading;
                await resolver.uploadFile(record);
                record.status = ImageUploadTrackerEnum.utUploadedNotStored;
                ImageUploadTracker.setValue( record)
              }
              if (record.status == ImageUploadTrackerEnum.utUploadedNotStored) {
                console.log("ImgCache: Checking to upload image " + record.imageId + " for note " + record.noteId);
              /*  if (this.checkAndSetDocumentIdInEditor(record, record.externalUrl)) {
                  record.status = ImageUploadTrackerEnum.utDone;
                  ImageUploadTracker.setValue( record)


                }
                else */{
                  await this.setExternalUrlInNoteImage(state, record)
                  record.status = ImageUploadTrackerEnum.utDone;
                  ImageUploadTracker.setValue( record)
                }

              }
            }
          }

        }
        catch (ex) {
          console.error("ImgCache:Unable to clean up " + record.imageId + ' ' + ex)
        }
      }
    })
  }

  reportBadImage(imageId: string, src: string) {
    let image = ImageUploadTracker.getValue(imageId);
    ;
    console.log("ImgCache:fix bad image "+src)
    if (image) {
      //orgin from the device
      if (image.externalUrl == src && image.localUrl) {
        //maybe problem with uploading
        console.log("ImgCache: problem with external url " + src + " market it as error and try again")
        image.externalUrl = null;
      }
      if (image.externalUrl) {
        ImgCache.removeFile(src, () => {
          console.log("ImgCache : remove file from cache" + src)
        }, (err) => {
          console.log("ImgCache : remove file from cache err " + err)
        })
      }
      image.externalUrl = null;
      image.status = ImageUploadTrackerEnum.utError
      ImageUploadTracker.setValue( image);
    }
    ImgCache.removeFile(src, () => {
      console.log("ImgCache : remove file from cache" + src)
    }, (err) => {
      console.log("ImgCache : remove file from cache err " + err)
    })

  }
  checkAndSetDocumentIdInEditor(imageRecord: ImageTrackerRecord, externalUrl: string): boolean {
    if ($(".note-image[image-id='" + imageRecord.imageId + "']").length > 0) {
      console.log(
        "ImgCache:Found Image element update external source"
      );
      try {

        $(".note-image[image-id='" + imageRecord.imageId + "']")[0].setAttribute("external-src", externalUrl)
        return true

      }
      catch (e) {
        console.log(
          "ImgCache:Error setting Attribute  Save status so clean up can happen"
        );
        return false

      }


    }
    return false;
  }
  uploadFile(imageRecord: ImageTrackerRecord):Promise<void> {
    imageRecord.status = ImageUploadTrackerEnum.utUploading;
    ImageUploadTracker.setValue( imageRecord);
    return new Promise<void>(async (resolve, reject) => {
      try {



        function previewFile(url): Promise<any> {
          return new Promise<any>((resolve, reject) => {
            var request = new XMLHttpRequest();
            request.open("GET", url, true);
            request.responseType = "blob";
            request.onload = function () {
              var reader = new FileReader();
              reader.readAsArrayBuffer(request.response);
              // reader.readAsDataURL(request.response);
              reader.onload = function (e) {
                console.log("ImgCache:Success read internal file");
                resolve(e.target["result"]); //
                // console.log("DataURL:", e.target.result);
              };
              reader.onerror = function (e) {
                Track.reportError(e, "Error reading internal image file", false);
                reject(e);
              };
            };
            request.send();
          });
        }

        var f = previewFile(imageRecord.localUrl)
          .then(file => {
            var bfh = new ImageHandler(null, "no-id");

            bfh
              .uploadFile(imageRecord.imageId, file, false)
              .then(url => {
                console.log(
                  "ImgCache:Uploaded file update attributes" + JSON.stringify(url)
                );
                imageRecord.externalUrl = url.externalUrl
                imageRecord.status = ImageUploadTrackerEnum.utUploadedNotStored;
                ImageUploadTracker.setValue(imageRecord);
                if (this.checkAndSetDocumentIdInEditor(imageRecord, url.externalUrl)) {
                  imageRecord.status = ImageUploadTrackerEnum.utDone;
                  ImageUploadTracker.setValue( imageRecord);
                  //ImageUploadTracker.removeValue(imageRecord.imageId);
                }
                else {
                  console.log(
                    "ImgCache:Error setting Attribute  Save status so clean up can happen"
                  );
                  imageRecord.status = ImageUploadTrackerEnum.utUploadedNotStored;
                  ImageUploadTracker.setValue( imageRecord);
                
                }
                resolve();
                /*
                if ($(".note-image[image-id='" + imageRecord.imageId + "']").length > 0) {
                  console.log(
                    "ImgCache:Found Image element update external source"
                  );
                  try {
                   
                    $(".note-image[image-id='" + imageRecord.imageId + "']")[0].setAttribute("external-src", url.externalUrl)
                    ImageUploadTracker.removeValue(imageRecord.imageId);

                  }
                  catch (e) {
                    console.log(
                      "ImgCache:Error setting Attribute  Save status so clean up can happen"
                    );
                    imageRecord.externalUrl = url.externalUrl
                    imageRecord.status = ImageUploadTrackerEnum.utUploadedNotStored;
                    ImageUploadTracker.setValue(imageRecord.imageId, imageRecord);
                  }

                  return resolve();
                }
                else {
                  console.log(
                    "ImgCache:Could not find Image element but finished upload. Save status so clean up can happen"
                  );
                  imageRecord.externalUrl = url.externalUrl
                  imageRecord.status = ImageUploadTrackerEnum.utUploadedNotStored;
                  ImageUploadTracker.setValue(imageRecord.imageId, imageRecord);
                  return resolve();
                }*/
                //node.removeAttribute("internal-src");
              })
              .catch(e => {
                console.log("ImgCache:error storing file " + e);
                imageRecord.status = ImageUploadTrackerEnum.utError;
                ImageUploadTracker.setValue( imageRecord);
                Track.reportError(e, "error storing file", false);
                reject()
              });
          })
          .catch(e => {
            console.log("ImgCache:error fetting file " + e);
            imageRecord.status = ImageUploadTrackerEnum.utError;
            ImageUploadTracker.setValue( imageRecord);
            Track.reportError(e, "error fetching file", false);
            reject(e);
          });
      }
      catch (e) {
        imageRecord.status = ImageUploadTrackerEnum.utError;
        ImageUploadTracker.setValue( imageRecord);
        console.log("ImgCache:Error uploading " + imageRecord.localUrl)
        reject(e)
      }
    })
  }
}


export class ImageHandler {
  storage: any;
  dispatch: any;
  profileId: any;
  constructor(dispatch: any, profileId: any) {
    ;
    this.storage = firebase.storage();
    this.dispatch = dispatch;
    this.profileId = profileId;
  }
  showProgess(description: string, progress: number, path: string) {
    if (this.dispatch) {
      var p = new model.ProgressState();
      p.progress = progress;
      p.text = description;
      this.dispatch(actions.ar_setProgress(p));
    }
    $(`.image-progress-container[image-id="${path}"]`).show()

    $(`.image-progress-container-percentage[image-id="${path}"]`).width(Math.floor(progress) + '%')
    $(`.image-progress-status[image-id="${path}"]`).text(Math.floor(progress) + '%')
  }
  closeProgess(path: string) {
    if (this.dispatch) this.dispatch(actions.ar_setProgress(null));
    $(`.image-progress-container[image-id="${path}"]`).hide()
  }
  createNoteFromImage(
    noteImage: NoteImage,
    tags: Array<string>,
    noteId: any,
    ctx: pApi.ICtx,
    noteName?: string
  ): Promise<pApi.Note> {
    return new Promise<pApi.Note>(async (resolve, reject) => {
      try {
        this.showProgess("Setup Note", 50, "unknown");
        let name = noteName ? noteName : "";
        //if (!name && noteImage) name = "Image " + moment().format("lll");
        var newNote = new pApi.Note();
        var newContent = new pApi.NoteContent();
        newContent.contentType = pApi.ContentType.quillJS;
        newContent.storageType = ctx.Notes.getDefaultStorageType();//pApi.StorageType.embedded;
        var tagsSections = [];
        function getTagEle(tagName) {
          return {
            insert: {
              mention: {
                key: tagName,
                value: tagName
              }
            }
          }
        }
        for (var i in tags) {
          if (tags[i] != "images") {
            tagsSections.push(getTagEle(tags[i]))
            tagsSections.push({ insert: "\n" });
          }

        }
        if (noteImage) {
          tagsSections.push(getTagEle('images'))
          tagsSections.push({ insert: "\n" });
        }

        newContent.content = [
          { insert: name },
          { attributes: { header: 1 }, insert: "\n" },
          { insert: "\n" },
          { insert: moment().format("lll") + "\n" }
        ];
        newContent.content = newContent.content.concat(tagsSections);

        if (noteImage) {
          newContent.content.push({
            insert: { image: noteImage.toQuillObject() }
          });
        }
        newContent.content.push({
          insert: "\n"
        });
        newNote.title = name;
        newNote.tags = noteImage ? ["images"] : [];
        if (tags) newNote.tags.concat(tags);
        newNote.id = noteId;//pApi.newId();
        newNote.is_new = true;
        var noteCache = {
          note: newNote,
          content: newContent
        }
        window['note-' + newNote.id] = noteCache;
        /*
        var newNote = await ctx.Notes.save(newNote);
        this.showProgess("Setup Note", 90);
        await ctx.Notes.setContent(newNote, newContent);
        */
        this.closeProgess("unknown");

        resolve(newNote);
      } catch (e) {
        Track.reportError(
          e,
          "Unable to create a note.",
          true,
          this.dispatch
        );
        this.closeProgess("unknown");
      }
    });
  }
  collectImage(id: string, noteId: any): Promise<NoteImage> {
    var me = this;
    return new Promise<NoteImage>((resolve, reject) => {
      if (window["nativeImagaHandler"]) {
        window["nativeImagaHandler"]((err, path) => {
          try {
            console.log("nativeImagaHandler callback");
            if (err) {

              Track.reportError(err, "error getting image", false);
              reject(err);
            }
            
            var nimage = new NoteImage();
                
            nimage.internalUrl = path;
            nimage.id = id;
            console.log("got image " + path);

            let imageRecord = new ImageTrackerRecord();
            imageRecord.imageId = id;
            imageRecord.localUrl = path;
            imageRecord.noteId = noteId;
            imageRecord.status = ImageUploadTrackerEnum.utPendingUpload
            ImageUploadTracker.setValue( imageRecord);
            resolve(nimage);
            
            /*
            ImgCache.cacheFile(
              path,
              url => {
                var nimage = new NoteImage();

                nimage.internalUrl = url;
                nimage.id = path;
                console.log("got image " + url);

                let imageRecord = new ImageTrackerRecord();
                imageRecord.imageId = path;
                imageRecord.localUrl = url;
                imageRecord.noteId = noteId;
                imageRecord.status = ImageUploadTrackerEnum.utPendingUpload
                ImageUploadTracker.setValue( imageRecord);
                resolve(nimage);
                Track.Event(TrackCategory.objectAction, TrackEvent.addImage);

              },
              err => {
                ;
                Track.reportError(err, "error caching image.", false);
                reject(err);
              }
            );*/
          } catch (e) {
            Track.reportError(e, "error caching image.", false);
            reject(e);
          }
        });
        return;
      }
      let fileInput: any = null //$("input.image-upload[type=file]")[0];
      $("input.image-upload[type=file]").remove();
      if (fileInput == null) {
        fileInput = document.createElement("input");
        fileInput.setAttribute("type", "file");
        fileInput.setAttribute(
          "accept",
          "image/png, image/gif, image/jpeg, image/bmp, image/x-icon"
        );
        fileInput.classList.add("image-upload");
        fileInput.addEventListener("change", async () => {
          try {
            if (fileInput.files != null && fileInput.files[0] != null) {
              let reader = new FileReader();
              if (ImgCache.ready) {
                reader.onload = e => {
                  
                  ImgCache.cacheFile(
                    e.target["result"],
                    function () {
                      ImgCache.getCachedFileURL(
                        e.target["result"],
                        (url, entry) => {
                          console.log("cache url "+url,entry)
                          let imageRecord = new ImageTrackerRecord();
                          imageRecord.imageId = id
                          imageRecord.localUrl = entry;
                          imageRecord.cacheUrl = entry;
                          imageRecord.noteId = noteId;
                          imageRecord.status = ImageUploadTrackerEnum.utPendingUpload
                          ImageUploadTracker.setValue( imageRecord);

                          var nimage = new NoteImage();
                          nimage.internalUrl = entry;
                          nimage.id = id;
                          //localStorage.setItem('NeedToUpload-' + nimage.id, "pending")

                          resolve(nimage);
                          fileInput.value = "";
                        }
                      );
                    },
                    function (err) {
                      reject(err);
                      Track.reportError(err, "error uploading image.", false);

                    }
                  );
                };
                reader.readAsDataURL(fileInput.files[0]);
              } else {
                var image = await me.uploadFile(
                  id,
                  fileInput.files[0],
                  false
                );
                fileInput.value = "";

                resolve(image);
              }
            }
          } catch (e) {
            Track.reportError(e, "error uploading image.", false);
          }
        });
        $(".upload-container")[0].appendChild(fileInput);
      }
      fileInput.click();
    });
  }
  uploadFile(id: any, file: any, base64: boolean): Promise<NoteImage> {
    var me = this;
    return new Promise<NoteImage>(async (resolve, reject) => {
      try {
        var storageRef = this.storage.ref();
        var spaceRef = storageRef.child("images/" + id);

        me.showProgess("uploading file", 0, id);
        let uploadTask; //
        if (base64) {
          uploadTask = spaceRef.putString(file, "base64");
        } else {
          uploadTask = spaceRef.put(file);
        }
        // var uploadTask = storageRef.child("images/rivers.jpg").put(file);

        // Register three observers:
        // 1. 'state_changed' observer, called any time the state changes
        // 2. Error observer, called on failure
        // 3. Completion observer, called on successful completion
        uploadTask.on(
          "state_changed",
          function (snapshot) {
            // Observe state change events such as progress, pause, and resume
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded

            var progress =
              snapshot.bytesTransferred / snapshot.totalBytes * 100;
            console.log("Upload is " + progress + "% done");
            switch (snapshot.state) {
              case firebase.storage.TaskState.PAUSED: // or 'paused'
                me.showProgess("uploading file - Paused", progress, id);
                break;
              case firebase.storage.TaskState.RUNNING: // or 'running'
                console.log("Upload is running");
                me.showProgess("uploading file", progress, id);
                break;
            }
          },
          function (error) {
            me.closeProgess(id);
            $(`.image-progress-status[image-id="${id}"]`).text(error)
            reject(error);
            // Handle unsuccessful uploads
          },
          async function () {
            me.closeProgess(id);
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            let noteImage = new NoteImage();
            ;
            noteImage.externalUrl = await uploadTask.snapshot.ref.getDownloadURL();//uploadTask.snapshot.downloadURL;
            //noteImage.internalUrl = id;
            noteImage.id = id;
            resolve(noteImage);
            // var downloadURL = uploadTask.snapshot.downloadURL;
          }
        );
      } catch (e) {
        this.closeProgess(id);
        $(`.image-progress-status[image-id="${id}"]`).text("Error")
        reject(e);
      }
    });
  }
}
