import * as l from './listTypes';

import * as pApi from '../papi/papi-core'
import { InboxMode } from '../lib/sharedModel'
import moment from 'moment'
import Interpreter from 'js-interpreter';
//const moment = require("moment").default; 
export class BaseRow {
    id: any;
    section: SectionDescription;
    group: string;
    sort: Array<any>;
    static sort(a: BaseRow, b: BaseRow): number {
        let index = 0;
        let done = false
        while (!done) {
            let aNull = a.sort[index] == null || a.sort[index] == undefined;
            let bNull = b.sort[index] == null || b.sort[index] == undefined;
            if (aNull && bNull) {
                return 0;
            }
            if (aNull && b.sort[index]) {
                return -1;
            }
            if (a.sort[index] && bNull) {
                return 1;
            }
            if (a.sort[index] > b.sort[index]) {
                return 1;
            }
            if (a.sort[index] < b.sort[index]) {
                return -1;
            }
            index++;
        }
    }
    static reverse(a: BaseRow, b: BaseRow): number {
        let index = 0;
        let done = false
        while (!done) {
            let aNull = a.sort[index] == null || a.sort[index] == undefined;
            let bNull = b.sort[index] == null || b.sort[index] == undefined;
            if (aNull && bNull) {
                return 0;
            }
            if (aNull && b.sort[index]) {
                return -1;
            }
            if (a.sort[index] && bNull) {
                return 1;
            }
            if (a.sort[index] > b.sort[index]) {
                return -1;
            }
            if (a.sort[index] < b.sort[index]) {
                return 1;
            }
            index++;
        }
    }
}
export class SelectableRow extends BaseRow {
    selected: boolean;
    focused: boolean;
}
export abstract class ItemBaseRow extends SelectableRow {
    abstract getTodos(): Array<pApi.ToDoItem>
}
export class ItemRow extends SelectableRow implements ItemBaseRow {
    item: pApi.ToDoItem;
    getTodos(): Array<pApi.ToDoItem> {
        let retval = new Array();
        retval.push(this.item);
        return retval;
    }
}
export class GroupRow extends SelectableRow implements ItemBaseRow {
    subRows: Array<ItemRow> = new Array<ItemRow>();
    getTodos(): Array<pApi.ToDoItem> {
        let retval = new Array();
        for (var i in this.subRows) {
            retval.push(this.subRows[i].item)
        }
        return retval;
    }
}
export class SectionHeader extends BaseRow {
    displayText: string;
    constructor(section: SectionDescription, sort: Array<any>) {
        super();
        this.section = section;

        this.sort = sort;


    }
}
export class GroupHeader extends BaseRow {

    groupDisplayName: string;
}
export class GroupEnd extends BaseRow {

    groupDisplayName: string;
}
export class NoteRow extends SelectableRow {
    note: pApi.Note
}
export class PaddingRow extends BaseRow {
    padding: number
}

class SectionDescription {
    displayText: string;
    sortKey: string;
    constructor(sortKey: string, displayText: string) {
        this.sortKey = sortKey;
        this.displayText = displayText;
    }
}
function getGroupName(group: string) {
    let x = group.split(':');


    if (x.length > 1) {
        return x[x.length - 1]
    }
    else {
        return group;
    }
    //return x.join(':').substr(1);

}
function getGroup(li: pApi.ToDoItem, selectedTag: string, sectionSort: string) {

    let tags = li.tags ? li.tags : [];
    if (selectedTag) {
        tags = tags.filter(x => x != selectedTag)
    }
    ;
    let priorityString = li.priority == pApi.ToDoItemPriority.High ? '(s)' : ''
    if (!li.due_date) {
        return (tags.length > 0) ? priorityString + sectionSort + ":" + tags[0] : '';
    }
    let tagstr = (tags.length > 0) ? tags[0] : null;
    if (!tagstr)
        return '';//priorityString+sectionSort;
    return sectionSort + priorityString + li.due_date.getTime() + ':' + tagstr;
}

export class SuperListSelectedState {
    id: any = new Date();
    selectedRows: Array<BaseRow> = new Array<BaseRow>()
    focused: BaseRow;
    focusedIndex: number;
    focusFirst: boolean = false;
    hoverRow: BaseRow;
    getHoverId(): any {
        if (this.hoverRow)
            return this.hoverRow.id;
        return null;
    }
    getFocusedId(): any {
        if (this.focused)
            return this.focused.id;
        return null;
    }
    hasSelections() {
        return this.selectedRows.length > 0;
    }
    constructor(id: any) {
        this.id = id;
    }


}
export function getSuperListRows(allTodos: Array<pApi.ToDoItem>, allNotes: Array<pApi.Note>, mode: InboxMode, tag: string, expandedGroups: Array<any>, orderBy: Array<string>,sortByCreatedDate:boolean, paddingTop: number = 0, paddingBottom: number = 0) {
    let items = new Array<pApi.ToDoItem>();
    let notes = new Array<pApi.Note>();
    function hasTags(t: pApi.ToDoItem): boolean {
        if (!t.tags) return false;
        if (t.tags.length == 0) return false;
        return true;
    }
    function hasTag(tags: Array<string>, tag: string) {
        let searchParts = tag.split('/')

        for (var i in tags) {
            let tagParts = tags[i].split('/');
            if (searchParts.length <= tagParts.length) {
                let found = false;
                for (var ii in searchParts) {
                    if (searchParts[ii] != tagParts[ii])
                        found = true;
                }
                if (!found)
                    return true;

            }

            /*
            
                if (tags[i].startsWith(tag)) {
                  return true;
                }*/
        }
        return false;
    }

    ;


    switch (mode) {
        case InboxMode.inbox:
        case InboxMode.bydate:
            items = allTodos.filter(
                todo =>
                    !todo.checked &&
                    (
                        (todo.priority == pApi.ToDoItemPriority.High &&
                            (!todo.due_date || todo.due_date < new Date()))
                        ||
                        (todo.due_date && todo.due_date < new Date())
                        ||
                        (!todo.due_date && !todo.hide_from_inbox)
                        ||
                        (todo.hide_from_inbox && ( !todo.tags || todo.tags.length ==0) && !todo.due_date)
                    )
            );

            break;
        case InboxMode.allNotes:


            items = []
            notes = allNotes ? allNotes : []
            break;
        case InboxMode.bytag:
            let thisTag = tag;

            if (tag && tag[0] == '~') {
                let query = tag.substr(1);
                let func = `${window['query'] ? window['query'] : false}`

                items = allTodos ? allTodos.filter(item => {
                    try {
                        const myInterpreter = new Interpreter(func);
                        myInterpreter.setValueToScope('todo', myInterpreter.nativeToPseudo(item));
                        myInterpreter.run()
                        let val = myInterpreter.pseudoToNative(myInterpreter.value)
                        console.log("Val = " + JSON.stringify(val))
                        return val;
                    }
                    catch (e) {
                        console.log("Val = " + e)
                    }

                }) : [];
                notes = []
            }
            else {
                items = allTodos ? allTodos.filter(item => hasTags(item) && hasTag(item.tags, thisTag)) : [];
                notes = allNotes ? allNotes.filter(item => (item.tags && hasTag(item.tags, thisTag))) : [];
            }
            break;
        case InboxMode.today:
            let fromDate = moment(new Date()).toDate();
            fromDate.setHours(0);
            fromDate.setMinutes(0);
            fromDate.setSeconds(0);
            fromDate.setMilliseconds(0);
            let toDate = moment(fromDate);
            toDate.add("day", 1);

            let noon = moment(fromDate);
            noon.set('hour', 12);
            let evening = moment(fromDate);
            evening.set('hour', 12 + 6)

            items = allTodos.filter(
                todo =>
                    !todo.checked &&
                    ((todo.due_date && todo.due_date < toDate.toDate()) ||
                        !todo.hide_from_inbox && !todo.due_date)
            );
            break;
      
        case InboxMode.onlysnoozed:

            items = allTodos.filter(x => x.due_date > new Date())

            break
        case InboxMode.search:
            items = allTodos;
            notes = allNotes;
        case InboxMode.archive:
            items = allTodos;
    }

    let startOfDay = moment(new Date()).toDate();
    startOfDay.setHours(0);
    startOfDay.setMinutes(0);
    startOfDay.setSeconds(0);
    startOfDay.setMilliseconds(0);
    let endOfDay = moment(startOfDay);
    endOfDay.add("day", 1);

    let noon = moment(startOfDay);
    noon.set('hour', 12)
    let evening = moment(startOfDay);
    evening.set('hour', 12 + 6)
    let dateHash = {}
    let now = new Date()
    function getInboxSection(todo: pApi.ToDoItem): SectionDescription {
        let localMode = sortByCreatedDate? InboxMode.bydate:mode;
        switch (localMode) {
            case InboxMode.inbox:
            case InboxMode.bytag:
                if (todo.priority == pApi.ToDoItemPriority.High) {
                    return new SectionDescription('1-starred', 'Starred');
                }
                if (todo.due_date && todo.due_date < new Date()) {
                    return new SectionDescription('2-due', 'Due');
                }
                return new SectionDescription('3-todos', 'Todos');
                break;
            case InboxMode.today:
                if (todo.due_date &&
                    todo.due_date < startOfDay)
                    return new SectionDescription('1-pastDue', 'Over Due');
                if (todo.due_date &&
                    (todo.due_date >= startOfDay && todo.due_date < noon.toDate()))
                    return new SectionDescription('2-morning', 'Morning')
                if (todo.due_date &&
                    (todo.due_date >= noon.toDate()) && todo.due_date < evening.toDate()) {
                    return new SectionDescription('3-Afternoon', 'Afternoon')
                }
                if (
                    todo.due_date &&
                    (todo.due_date >= evening.toDate() && todo.due_date < endOfDay.toDate())) {
                    return new SectionDescription('3-Evening', 'Evening')
                }
                return new SectionDescription('5-should not happen', 'Todos');
            case InboxMode.archive:

                let sectionArchive = todo.modified
                    ? moment(todo.modified).calendar(null, {
                        sameDay: "[Today]",
                        nextDay: "[Tomorrow]",
                        nextWeek: "dddd",
                        lastDay: "[Yesterday]",
                        lastWeek: "[Last] dddd",
                        sameElse: "MM/YYYY"
                    })
                    : "To-Dos";


                let startOfDueDateArchive = new Date(now.getTime() - todo.modified.getTime());
                startOfDueDateArchive.setHours(0);
                startOfDueDateArchive.setMinutes(0);
                startOfDueDateArchive.setSeconds(0);
                startOfDueDateArchive.setMilliseconds(0);
                if (!dateHash[sectionArchive]) {
                    dateHash[sectionArchive] = startOfDueDateArchive;
                }
                else {
                    startOfDueDateArchive = dateHash[sectionArchive];
                }

                return new SectionDescription(startOfDueDateArchive.toISOString(), sectionArchive)

            case InboxMode.onlysnoozed:
            case InboxMode.bydate:
                let sortDate = (mode == InboxMode.onlysnoozed) ? todo.due_date : todo.created;
                let section = sortDate
                    ? moment(sortDate).calendar(null, {
                        sameDay: "[Today]",
                        nextDay: "[Tomorrow]",
                        nextWeek: "dddd",
                        lastDay: "[Yesterday]",
                        lastWeek: "[Last] dddd",
                        sameElse: "MM/YYYY"
                    })
                    : "To-Dos";


                let startOfDueDate = new Date(sortDate);
                startOfDueDate.setHours(0);
                startOfDueDate.setMinutes(0);
                startOfDueDate.setSeconds(0);
                startOfDueDate.setMilliseconds(0);
                if (!dateHash[section]) {
                    dateHash[section] = startOfDueDate;
                }
                else {
                    startOfDueDate = dateHash[section];
                }

                return new SectionDescription(startOfDueDate.toISOString(), section)
            case InboxMode.search:
                return new SectionDescription("Search", "Search")
            default:
                throw 'not implemented'
        }
    }
    let rows = new Array<BaseRow>();

    let itemRows = new Array<ItemRow>();
    let sectionHash = {};
    items.forEach(item => {
        let row = new ItemRow();
        row.section = getInboxSection(item)
        if (sectionHash[row.section.sortKey] == null) {
            let sectionRow = new SectionHeader(row.section, [row.section.sortKey]);
            sectionRow.id = 'section' + row.section.displayText + ':' + mode.toString();
            rows.push(sectionRow);
            sectionHash[row.section.sortKey] = 0;
        }
        else {
            sectionHash[row.section.sortKey] = sectionHash[row.section.sortKey] + 1;

        }
        row.group = (mode != InboxMode.search) ? getGroup(item, tag, row.section.sortKey) : ""

        row.item = item;
        let orderByValue = "";
        if (orderBy) {
            orderByValue = orderBy.findIndex(x => x == "todo:" + item.id).toString();
            if (orderByValue == "-1")
                orderByValue = ""
        }
        ;
        row.sort = [row.section.sortKey, orderByValue, row.item.priority == pApi.ToDoItemPriority.High ? 0 : 1, row.item.due_date, row.group, row.item.description]

        row.id = item.id
        rows.push(row);
    })

    if (notes.length > 0) {
        let noteSection = new SectionDescription("Search", "Search")
        if (mode != InboxMode.search) {
            noteSection = new SectionDescription('9-notes', 'Notes');
            let notesHeaderRow = new SectionHeader(noteSection, [noteSection.sortKey]);
            notesHeaderRow.id = 'section' + noteSection.sortKey + ':' + mode.toString();
            rows.push(notesHeaderRow);
        }
        sectionHash[noteSection.sortKey] = sectionHash[noteSection.sortKey] ? sectionHash[noteSection.sortKey] + 1 : 1;
        for (var i in notes) {
            let note = notes[i];
            let row = new NoteRow()
            row.id = note.id;
            row.note = note;
            let orderByValue = "";
            if (orderBy) {
                orderByValue = orderBy.findIndex(x => x == "note:" + note.id).toString();
                if (orderByValue == "-1")
                    orderByValue = ""
            }
            row.section = noteSection;
            row.sort = [row.section.sortKey, orderByValue, 0 - note.modified.getTime(), note.title]

            rows.push(row);
        }

    }

    
    if(mode == InboxMode.bydate || sortByCreatedDate)
    {
        rows = rows.sort(BaseRow.reverse);
    }
    else
    {
        rows = rows.sort(BaseRow.sort);
    }
    let finalRows = new Array<BaseRow>();
    let currentGroupRow: BaseRow = null
    let currentSection: string = null
    let currentIndex = 0;
    let currentSectionItems: number = 0;
    let nextRow = function (): BaseRow {
        if (rows.length < (currentIndex + 1)) {
            return rows[currentIndex + 1];
        }
        return null
    }
    let isExpandedGroupRow = function (row: BaseRow): boolean {

        return row && row instanceof GroupRow && expandedGroups.find(x => x == row.group);
    }

    let endGroup = function () {
        if (currentGroupRow) {
            if (currentGroupRow instanceof GroupRow) {
                let gr = currentGroupRow as GroupRow;
                if ((gr as GroupRow).subRows.length == 1) {
                    finalRows.push((gr as GroupRow).subRows[0]);
                }
                else {
                    let isExpanded = expandedGroups.find(x => x == currentGroupRow.group) != null;
                    if (isExpanded) {

                        if (!isExpandedGroupRow(nextRow())) {
                            let finalRow = new GroupEnd()
                            finalRow.section = currentGroupRow.section;
                            finalRow.group = currentGroupRow.group;
                            finalRow.sort = [finalRow.section + '1'];
                            finalRows.push(finalRow);
                        }
                    }
                    else {
                        finalRows.push(currentGroupRow);
                    }

                }
            }
            if (currentGroupRow instanceof GroupHeader) {
                let isExpanded = expandedGroups.find(x => x == currentGroupRow.group) != null;
                let nextR = nextRow();
                if (isExpanded) {
                    if (!isExpandedGroupRow(nextR)) {
                        let finalRow = new GroupEnd()
                        finalRow.section = currentGroupRow.section;
                        finalRow.group = currentGroupRow.group;
                        finalRow.sort = [finalRow.section + '1'];
                        finalRows.push(finalRow);
                    }
                }
            }
        }
        currentGroupRow = null;
    }

    if (paddingTop > 0) {
        let paddingRow = new PaddingRow()
        paddingRow.padding = paddingTop
        finalRows.push(paddingRow)
    }
    rows.forEach(row => {
        if (row.section.sortKey != currentSection) {
            endGroup();
        }
        currentSection = row.section.sortKey
        if (row.group) {

            if (!currentGroupRow || row.group != currentGroupRow.group) {
                //the group has changed
                endGroup();
                let isExpanded = expandedGroups.find(x => x == row.group) != null;
                if (isExpanded) {
                    let gh = new GroupHeader();
                    gh.group = row.group;
                    gh.id = row.section.sortKey + ':' + row.group
                    gh.section = row.section
                    gh.sort = row.sort;
                    gh.groupDisplayName = getGroupName(gh.group)

                    finalRows.push(gh);
                    finalRows.push(row);
                    currentGroupRow = gh;
                }
                else {
                    let gr = new GroupRow();
                    gr.group = row.group;
                    gr.id = row.section.sortKey + ':' + row.group + ":" + (row as ItemRow).id
                    gr.section = row.section
                    gr.sort = row.sort;
                    gr.subRows.push(row as ItemRow)
                    currentGroupRow = gr;
                }
            }
            else { //If the group hasn't changed
                if (currentGroupRow instanceof GroupRow) {
                    currentGroupRow.subRows.push(row as ItemRow)
                }
                if (currentGroupRow instanceof GroupHeader) {
                    finalRows.push(row);
                }
            }
        }
        else {
            //if the previous group row is only has one then push a item row
            if (currentGroupRow) {
                endGroup()
            }
            currentGroupRow = null;
            finalRows.push(row);

        }
        currentIndex++;
    });
    endGroup();

    if (paddingBottom > 0) {
        let paddingRow = new PaddingRow()
        paddingRow.padding = paddingBottom
        finalRows.push(paddingRow)
    }


    return finalRows;
}
