import * as React from "react";
import { deflateSync } from "zlib";
import { DateTime } from "aws-sdk/clients/opsworks";
import { BlockDeviceEbsDeleteOnTermination } from "aws-sdk/clients/autoscaling";
import * as material from 'material-ui'
class aDay {
  date: Date;
  dayOfMonth: number;
  monthText: string;
}
interface QuickDayPickerProps {
  onSelect: (date: Date) => void;
  selected?: Date;
}
interface QuickDayPickerState {
  datePopup: boolean;
}
 

export class QuickDatePicker extends React.Component<
  QuickDayPickerProps,
  QuickDayPickerState
> {
  constructor(props: QuickDayPickerProps, context) {
    super(props, context);
    this.state = {
      datePopup: false
    };
  }
  render() {
    let colStyle = {
      width: "14.2%",
      "text-align": "center"
    };
    var months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ];
    let dayOfWeek = ["S", "M", "T", "W", "T", "F", "S"];
    function getSunday(d) {
      d = new Date(d);
      var day = d.getDay(),
        diff = d.getDate() - day + (day == 0 ? -7 : 0);
      let date = new Date(d.setDate(diff));
      date.setHours(8);
      date.setMinutes(0);
      date.setSeconds(0);
      date.setMilliseconds(0);

      return date;
    }
    let numberOfWeeks = 4;
    let today = new Date();
    today.setHours(8);
    today.setMinutes(0);
    today.setSeconds(0);
    today.setMilliseconds(0);
    let currentDay = getSunday(today);
    let lastDate;
    let weeks = new Array<Array<Date>>();
    for (var i = 0; i < 4; i++) {
      let week = new Array<Date>();
      for (var ii = 0; ii < 7; ii++) {
        let newDate = new Date(currentDay.getTime());
        week.push(newDate);
        
        if(i==3 && ii==6)
        {
            lastDate = newDate
        }
        currentDay.setDate(currentDay.getDate() + 1);
      }
      weeks.push(week);
    }

    let me = this;
    return (
      <div className="container">
        <div className="row">
          {dayOfWeek.map(day => (
            <div style={colStyle}>{day}</div>
          ))}
        </div>

        {weeks.map(week => (
          <div key={week[0].toString()} className="row quick-date-picker-row">
            {week.map(day => (
              <div
                key={"d" + day.toString()}
                className="quick-date-picker-cell"
             
                onClick={e => {
                  if(day != lastDate)
                  {
                    me.props.onSelect(day);
                  }
                  else{
                    setTimeout(() => {
                        console.log("open me");
                        me.refs.dp["openDialog"]();
                      }, 100);
                  }
                }}
              >
                { day == lastDate && (
                    <div>></div>   
                )}
                {day != lastDate && day >= today && day.getDate() == 1 && (
                  <div className="quick-date-picker-cell-month">
                    <div>{months[day.getMonth()]}</div>
                    <div>{day.getDate()}</div>
                  </div>
                )}
                {day != lastDate && day >= today && day.getDate() > 1 && (
                  <div>{day.getDate()}</div>
                )}
              </div>
            ))}
          </div>
        ))}
         <material.DatePicker
          firstDayOfWeek={0}
          defaultDate={
            this.props.selected
              ? new Date(this.props.selected)
              : new Date()
          }
          onDismiss={()=>{

          }}
          autoOk
          onChange={e => {
            var d = me.refs.dp["getDate"]();
            this.props.onSelect(d);

            }
          }
          style={{ display: "none" }}
          ref="dp"
        />
      </div>
    );
  }
}
