import { DescribeSuggestersResponse } from "aws-sdk/clients/cloudsearch";
import { idList } from "aws-sdk/clients/datapipeline";
import * as React from "react";
import { connect } from "react-redux";

import { Link } from "react-router";

import * as material from "material-ui";
import LayoutIcon from "material-ui/svg-icons/action/chrome-reader-mode";
import AutoRenewIcon from "material-ui/svg-icons/action/autorenew";
import IconButton from "material-ui/IconButton";
import ListIcon from "material-ui/svg-icons/action/list";
import TodoListIcon from "material-ui/svg-icons/action/assignment-turned-in";
import NoteIcon from "material-ui/svg-icons/av/note";
import NavigationIcon from "material-ui/svg-icons/navigation/menu";
import MoveLeft from "material-ui/svg-icons/navigation/arrow-back";
import * as pApi from "../../shared/papi/papi-core";

import * as model from '../lib/model'
var classnames = require("classnames");
interface HeaderProps {
  
  uiConfig: model.UIConfig;

}

export class PublicHeader extends React.Component<HeaderProps, null> {
  constructor(props, context) {
    super(props, context);
    
  }
  
  
  render() {
    const { fixedHeader, colorOption } = this.props.uiConfig.layout;
    const dataSourceConfig = {
      text: "name",
      value: "id"
    };

    var headerstring = "TodoZero";
    
    return (
      <section className="app-header" >
      <div
        style={{
          height: "64px"
        }}
        className={classnames("app-header-inner", {
          "bg-color-light":
            ["11", "12", "13", "14", "15", "16", "21"].indexOf(colorOption) >=
            0,
          "bg-color-dark": colorOption === "31",
          "bg-color-primary": ["22", "32"].indexOf(colorOption) >= 0,
          "bg-color-success": ["23", "33"].indexOf(colorOption) >= 0,
          "bg-color-info": ["24", "34"].indexOf(colorOption) >= 0,
          "bg-color-warning": ["25", "35"].indexOf(colorOption) >= 0,
          "bg-color-danger": ["26", "36"].indexOf(colorOption) >= 0
        })}
      >
        <div className="fluid-container">
          <div className="row">
            <div className="col-8 col-md-3 " >
             <div className='float-left' style={{"paddingLeft": "4px"}} >
              <material.IconButton
                className=""
                style={{ "paddingTop": "19px" }}
      
              >
               <NavigationIcon color="white" />
                
              </material.IconButton>
            </div>
           
            <div
              className={classnames("float-left", {
                "bg-color-light":
                  ["11", "12", "13", "14", "15", "16", "21"].indexOf(colorOption) >=
                  0,
                "bg-color-dark": colorOption === "31",
                "bg-color-primary": ["22", "32"].indexOf(colorOption) >= 0,
                "bg-color-success": ["23", "33"].indexOf(colorOption) >= 0,
                "bg-color-info": ["24", "34"].indexOf(colorOption) >= 0,
                "bg-color-warning": ["25", "35"].indexOf(colorOption) >= 0,
                "bg-color-danger": ["26", "36"].indexOf(colorOption) >= 0
              })}
              style={{
                "paddingTop": "22px",
                "paddingLeft": "16px",
                "fontSize": "1.8em",

                overflow: "hidden",
                "textOverflow": "ellipsis",

                "textAlign": "left",
                height: "49px",
                "whiteSpace": "nowrap"
              }}
            >
              {headerstring}
            </div>
            </div>
          <div
            className="col-md-7 hidden-sm-down"
            style={{ "paddingTop": "13px", "paddingBottom": "0px" }}
          >
            <div className=''>
           
            </div>
          </div>
            <div className="col-4 col-md-2">
              
            </div>
          </div>
        </div>
        </div>
      </section>
    );

  }
}
