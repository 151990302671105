import * as React from "react";
import * as classNames from "classnames";
import * as material from "material-ui";

import NavigationClose from "material-ui/svg-icons/navigation/close";
import LayoutIcon from "material-ui/svg-icons/action/chrome-reader-mode";
import IconButton from "material-ui/IconButton";
import * as actions from "../../lib/ActionReducer"
import * as model  from "../../lib/model";
import { ErrorObj } from '../../../shared/track';
var classnames = require("classnames");



var uuid = require("uuid");
interface AddButtonInputProps {
  connect: model.ConnectProps;
}
interface AddButtonEditState {

}

export class ErrorBox extends React.Component<
  AddButtonInputProps,
  AddButtonEditState
> {
  constructor(props, context) {
    super(props, context);
   
    
    this.state = {
     
    };
  }

 
  async handleClose() {
    this.props.connect.dispatch<any>(actions.ar_closeErrorModal());
  }
  render() {
    let show = this.props.connect.state.errorShow || ( this.props.connect.state.lastError && this.props.connect.state.lastError.showModal);
    if (!show) {
      return <div />;
    }
    const actionsBtn = [
      <material.FlatButton
      label="Reload"
      secondary
      onClick={()=>{
         //window.location.reload();
         window['tz_Reload']();
      }}
    />,
      <material.FlatButton
        label="Close"
        primary
        onClick={this.handleClose.bind(this)}
      />
    ];
    
    var errors = this.props.connect.state.errorArray.sort( 
        (a:ErrorObj, b:ErrorObj) =>{
            return a.timestamp>b.timestamp?-1:1;
        }
    ).slice(0,10);
    if(this.props.connect.state.lastError && this.props.connect.state.lastError.showModal)
    {
        errors = [this.props.connect.state.lastError];
    }

    let Form = (
      <div className="container">
        <material.List>
        
            {errors.map((x) => (

                <material.ListItem primaryText={x.userMessage} secondaryText={x.exception.message} />
              
            ))}
        </material.List>
     </div>
    );
    
    return (
      <div id="hey">
       
        <material.Dialog
          title="Error"
          overlayStyle={actions.getOverlayStyle()}
          actions={actionsBtn}
          modal
          open={show }
        >
        <div className='mobile-form-with-header'>
          {Form}
        </div>
        </material.Dialog>
      </div>
    );
  }
}
