import * as React from "react";
import * as material from "material-ui";
import * as model from "../lib/model";
import * as actions from "../lib/ActionReducer";
import * as pApi from "../../shared/papi/papi-core";
import {GenerateChoices,ParseDate,DefaultChoices} from '../lib/parseData'

import Snooze from "material-ui/svg-icons/av/snooze";
import PickDate from "material-ui/svg-icons/device/access-time";
import TomorrowIcon from "material-ui/svg-icons/image/wb-sunny";
import WeekendIcon from "material-ui/svg-icons/action/event-seat";
import NextWeek from "material-ui/svg-icons/content/next-week";
import LastIcon from "material-ui/svg-icons/action/history";
import RemoveIcon from "material-ui/svg-icons/content/remove-circle";
import * as hotkey from "../lib/hotkeyClient";
import { QuickDatePicker } from "./QuickDayPicker";
import { SnoozeAutoComplete } from "./SnoozeAutoComplete";

interface SnoozerTyperProps {
  connect: model.ConnectProps;
  defaultDate?: Date;
  snoozed: (snoozeAction: pApi.SnoozeAction, date: Date) => void;
  onClose: () => void;
 
}
interface SnoozeTyperState {
  dateQuery: string;
  parsedDate?: Date;
  pickDateMode: boolean;
}

export class SnoozeTyper extends React.Component<
  SnoozerTyperProps,
  SnoozeTyperState
> {
  constructor(props: SnoozerTyperProps, context) {
    super(props, context);

    this.state = {
      dateQuery: "",
      parsedDate: null,
      pickDateMode: false
    };
  }
  componentDidMount() {
    let me = this;
    let instance = hotkey.HotKeyEngine.instance();
    let handlers = new Map<hotkey.HotKeyActionType, () => boolean>();
    handlers.set(
      hotkey.HotKeyActionType.esc,
      (): boolean => {
        me.props.onClose();
        return true;
      }
    );
    instance.registerHandlers("snoozetyper", "snoozetyper", true, handlers);
  }
  componentWillUnmount() {
    let instance = hotkey.HotKeyEngine.instance();
    instance.unregisterHandlers("snoozetyper");
  }
  onClosed() {}
  openDatePicker(e) {
    e.preventDefault();
    var me = this;
    let state = { ...this.state };
    state.pickDateMode = true;
    this.setState(state);

    console.log("open targe " + e.target);
    setTimeout(() => {
      console.log("open me");
      me.refs.dp["openDialog"]();
    }, 100);
  }
  render() {
    
    let datasource =
      this.state.dateQuery == ""
        ? DefaultChoices()
        : GenerateChoices(this.state.dateQuery);
    let choice = this.state.dateQuery.toLowerCase().replace("in", "");
    datasource = datasource.filter(
      x => x.text.toLowerCase().indexOf(choice) == 0
    );

    let me = this;
    const actionsBtn = [
      <material.FlatButton
        label="Cancel"
        primary
        onClick={() => {
          me.props.onClose();
        }}
      />,
      <material.FlatButton
        label="Snooze"
        primary
        disabled={me.state.parsedDate == null}
        onClick={() => {
          if (me.state.parsedDate) {
            me.props.snoozed(pApi.SnoozeAction.custom, me.state.parsedDate);
          }
        }}
      />
    ];
    let defaults = {
      beforeStyle: {
        paddindTop: "0px",
        opacity: 0
      } as any,
      afterStyle: {
        opacity: 1,
        paddindTop: "230px"
      } as any,
      root: {} as any,
      content: {
        maxWidth: "500px"
      } as any,
      actionsContainer: {},
      overlay: {},
      title: {
        textAlign: "center",
        margin: "0px",
        padding: "15px 0px 0px 0px",
        color: "rgba(0, 0, 0, 0.87)",
        fontSize: "20px",
        lineHeight: "12px",
        fontWeight: "400"
      } as any,
      body: {}
    };

    if (
      this.props.connect.getPresentationMode() == model.PresentationMode.mobile
    ) {
      defaults.beforeStyle = {
        opacity: 1,
       /* position: "absolute",
        bottom: "-600px",
        transform: "none"*/
      };
      defaults.afterStyle = {
        opacity: 1,
       /* position: "absolute",
        bottom: "0px",
        transform: "none"*/
      };
      defaults.content = {
        width: "100%",
       /* position: "absolute"*/
      };
    }
    let title = (
      <div>
        Snooze until...
        <div style={{    padding: '0px',
    margin: '0px',
    position: 'absolute',
    right: '10px',
    top: '0px'}}>
          <material.FlatButton
            label="Cancel"
            primary
            onClick={() => {
              me.props.onClose();
            }}
          />
        </div>
      </div>
    );
   
    return (
      <material.Dialog
      
        title={title}
        titleStyle={defaults.title}
        overlayStyle={actions.getOverlayStyle()}
        contentStyle={defaults.content}
        onRequestClose={e => {
          me.props.onClose();
        }}
        open={true}
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <SnoozeAutoComplete
                autofocus={
                  this.props.connect.getPresentationMode() ==
                  model.PresentationMode.web
                }
                includeClear={this.props.defaultDate != null}
                connect={this.props.connect}
                onFocus={() => {}}
                onBlur={() => {}}
                onSuggestion={(date: Date, dateString: string) => {}}
                onEsc={() => {}}
                snoozed={me.props.snoozed}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-8">
              <QuickDatePicker
                onSelect={d => {
                  me.props.snoozed(pApi.SnoozeAction.custom, d);
                }}
              />
            </div>
            <div className="col-12 col-md-4" >
           
                <div className="">
                 

                  <div className="row">
                    <div className="col-6 col-md-12 " style={{padding:0}} >
                    <material.FlatButton
                      label="Tonight"
                      labelPosition="after"
                      onClick={() => {
                        me.props.snoozed(pApi.SnoozeAction.laterToday, null);
                       // me.onClosed();
                      }}
                      icon={<Snooze />}
                    />
                  </div>
                  <div className="col-6 col-md-12" style={{padding:0}}>
                    <material.FlatButton
                      label="Tomorrow"
                      labelPosition="after"
                      onClick={() => {
                        me.props.snoozed(pApi.SnoozeAction.tomorrow, null);
                       // me.onClosed();
                      }}
                      icon={<TomorrowIcon />}
                    />
                  </div>

                       <div className="col-6 col-md-12" style={{padding:0}}>
                    <material.FlatButton
                      label="Next Week"
                      labelPosition="after"
                      onClick={() => {
                        me.props.snoozed(pApi.SnoozeAction.nextWeek, null);
                       // me.onClosed();
                      }}
                      icon={<NextWeek />}
                    />
                  </div>

                  {this.props.connect.state.lastSnooze &&
                    this.props.connect.state.lastSnooze.action ==
                      pApi.SnoozeAction.custom && (
                        <div className="col-6 col-md-12" style={{padding:0}} >
                        <material.FlatButton
                          label={model.formatRelativeDate(
                            this.props.connect.state.lastSnooze.date
                          )}
                          labelPosition="after"
                          onClick={() => {
                            me.props.snoozed(
                              pApi.SnoozeAction.custom,
                              this.props.connect.state.lastSnooze.date
                            );
                            //me.onClosed();
                          }}
                          icon={<LastIcon />}
                        />
                      </div>
                    )}
                </div>
             
              </div>
            </div>
            </div>
            <div className="row">
            {this.props.defaultDate && (
                    <material.FlatButton
                      label="Clear Date"
                      labelPosition="after"
                      style={{width: '100%'}}
                      icon={<RemoveIcon />}
                      onClick={() => {
                        me.props.snoozed(pApi.SnoozeAction.custom, null);
                        //me.onClosed();
                      }}
                    />
                  )}
</div>
            <material.DatePicker
              firstDayOfWeek={0}
              defaultDate={
                this.props.defaultDate
                  ? new Date(this.props.defaultDate)
                  : new Date()
              }
              onDismiss={this.onClosed.bind(this)}
              autoOk
              onChange={e => {
                var d = me.refs.dp["getDate"]();

                if (d) {
                  me.props.snoozed(
                    pApi.SnoozeAction.custom,
                    me.refs.dp["getDate"]()
                  );
                  //me.onClosed();
                }
              }}
              style={{ display: "none" }}
              ref="dp"
            />
          
        </div>
      </material.Dialog>
    );
         
  }
}
