import * as React from "react";
import { connect } from "react-redux";


import * as model  from "../../lib/model";


import { Link, hashHistory } from "react-router";
import { List, ListItem } from "material-ui/List";
import ContentInbox from "material-ui/svg-icons/content/inbox";
import ActionGrade from "material-ui/svg-icons/action/grade";
import ContentSend from "material-ui/svg-icons/content/send";
import NotesIcon from "material-ui/svg-icons/content/content-paste";
import HelpIcon from "material-ui/svg-icons/action/help";
import SettingsIcon from "material-ui/svg-icons/action/settings";
import ContentDrafts from "material-ui/svg-icons/content/drafts";
import TodayIcon from "material-ui/svg-icons/action/today"
import ThisWeekIcon from "../CustomSvg/ThisWeek";
import Divider from "material-ui/Divider";
import ActionInfo from "material-ui/svg-icons/action/info";
import Snooze from "material-ui/svg-icons/av/snooze";
import Archive from "material-ui/svg-icons/content/archive";
import Target from "../CustomSvg/Target";
import { NoteSidebarContent, TagNode } from "./NoteSidebarContent";
import FlatButton from "material-ui/FlatButton";
import { AvatarButton } from "../AvatarButton";
import * as material from "material-ui";
import * as Material from "material-ui";
import * as classNames from 'classnames';
var $ = require("jquery");
var classnames = require("classnames");

interface NoteSidebarProps {
  handleToggleCollapsedNav: any;
  uiConfig: model.UIConfig;
  loadingState: model.LoadingState;
  connect: model.ConnectProps;
}

export class NoteSidebar extends React.Component<NoteSidebarProps, null> {
  componentDidMount() {
    // AutoCloseMobileNav
    const $body = $("#body");

    $(".app-sidebar");
    /*
    if (this.props.uiConfig.AutoCloseMobileNav) {
      hashHistory.listen((location) => {
        setTimeout(() => {
          $body.removeClass('sidebar-mobile-open');
        }, 0);
      });
    }*/
  }

  onToggleCollapsedNav = e => {
    var val = !this.props.uiConfig.layout.navCollapsed;
    //const { handleToggleCollapsedNav } =
    this.props.handleToggleCollapsedNav(val);
  };
  onTagChange(node: TagNode) {
    var nav = this.props.connect.state.viewState.noteViewState
      ? this.props.connect.state.viewState.noteViewState
      : new model.NoteViewState();

    nav.functionType = model.NoteFunctionType.tag;
    nav.query = node.getTagPath().replace("/", ">");
    //nav.noteViewType = nav.noteViewType
    nav.noteId = null;
    console.log($(window).width());
    if ($(window).width() < 768) {
      nav.openFirstNote = false;
    } else {
      nav.openFirstNote = true;
    }
    this.onSelect("#" + nav.getHash());
    // window.location.hash = nav.getHash();
  }

  onSelect(url) {
    
    if (!url) return false;
    const $body = $("#body");
;
    //if(this.props.uiConfig.layout.navCollapsed)
    {
      console.log($(window).width());
      if ($(window).width() < 768) {
        //$body.toggleClass("sidebar-mobile-open");
        this.props.handleToggleCollapsedNav(true);
        
      }
    }
   
    window.location.href = url; //e.currentTarget.attributes['href']
    // e.preventDefault();
  }
  render() {
    let { navCollapsed, colorOption } = this.props.uiConfig.layout;
 
    let toggleIcon = null;
    if (navCollapsed) {
      toggleIcon = <i className="material-icons">radio_button_unchecked</i>;
    } else {
      toggleIcon = <i className="material-icons">radio_button_checked</i>;
    }
    var t = true;
    
    var collapsed = this.props.uiConfig.layout.navCollapsed;

    /*
    if(this.props.loadingState != model.LoadingState.Completed)
    {
      console.log('loading')
      return (<div>loading</div>)
    }
    if(this.props.loadingState == model.LoadingState.Completed)
    {*/

    /*var inboxProject = this.props.projects.filter(p=>p.isInbox())[0];
      var otherProjects = this.props.projects.filter(p=>!p.isInbox());
      */
    return (
      <nav
        style={{
          overflowY:'scroll',
          
          backgroundColor: "#f5f5f5",
          boxShadow: "initial"
        }}
        className={classnames("app-sidebar " + collapsed, {
          "bg-color-light":
            ["31", "32", "33", "34", "35", "36"].indexOf(colorOption) >= 0,
          "bg-color-dark":
            ["31", "32", "33", "34", "35", "36"].indexOf(colorOption) < 0
        },"color-left-nav")}
      >
        <section
          className={classnames("sidebar-header ", {
            "bg-color-dark": ["11", "31"].indexOf(colorOption) >= 0,
            "bg-color-light": colorOption === "21",
            "bg-color-primary": ["12", "22", "32"].indexOf(colorOption) >= 0,
            "bg-color-success": ["13", "23", "33"].indexOf(colorOption) >= 0,
            "bg-color-info": ["14", "24", "34"].indexOf(colorOption) >= 0,
            "bg-color-warning": ["15", "25", "35"].indexOf(colorOption) >= 0,
            "bg-color-danger": ["16", "26", "36"].indexOf(colorOption) >= 0
          })}
        >
          <div style={{ "lineHeight": "15px" }}>
            <AvatarButton connect={this.props.connect} showName={true} />
          </div>
        </section>
       
        <List >
          <div       className="joyride-inbox">
          <ListItem
            primaryText="Inbox"
      
            leftIcon={<ContentInbox />}
            onClick={e =>
              this.onSelect(
                "#" + model.ViewState.getDefaultHash(model.AppSections.inbox,this.props.connect.state.viewState)
              )
            }
            onTouchStart={e => {
              e.preventDefault();

              this.onSelect(
                "#" + model.ViewState.getDefaultHash(model.AppSections.inbox,this.props.connect.state.viewState)
              );
            }}
          />
          </div>
          <ListItem
            primaryText="Today"
            className="joyride-today"
            leftIcon={<TodayIcon />}
            onClick={e =>
              this.onSelect(
                "#" + model.ViewState.getDefaultHash(model.AppSections.today,this.props.connect.state.viewState)
              )
            }
          />
          <ListItem
            primaryText="Upcoming"
            className="joyride-upcoming"
            leftIcon={<ThisWeekIcon />}
            onClick={e =>
              this.onSelect(
                "#" + model.ViewState.getDefaultHash(model.AppSections.snoozed,this.props.connect.state.viewState)
              )
            }
          />

          <ListItem
            primaryText="Objectives"
            leftIcon={<Target />}
            style={{'display':'none'}}
            onClick={e =>
              this.onSelect(
                "#" +
                  model.ViewState.getDefaultHash(model.AppSections.objectives,this.props.connect.state.viewState)
              )
            }
          />

          <ListItem
            primaryText="Notes"
            leftIcon={<NotesIcon />}
            className='joyride-notes'
            onClick={e =>
              this.onSelect(
                "#" + model.ViewState.getDefaultHash(model.AppSections.notes,this.props.connect.state.viewState)
              )
            }
          />
          <Divider />
            
          <material.Subheader  ><div className='joyride-tags'>Hashtags</div></material.Subheader>
          <section className="sidebar-content " style={{ overflow: "auto" }}>
          <NoteSidebarContent
    selectedTag=""
    connect={this.props.connect}
    onClick={this.onTagChange.bind(this)}
  />
          </section>

          <Divider />
          <ListItem
            primaryText="Settings"
          
            leftIcon={<SettingsIcon />}
            onClick={e =>
              this.onSelect(
                "#" + model.ViewState.getDefaultHash(model.AppSections.settings,this.props.connect.state.viewState)
              )
            }
        
          />
          <ListItem
            primaryText="Help & Feedback"
          
            leftIcon={<HelpIcon />}
            onClick={e =>
              this.onSelect(
                "#" + model.ViewState.getDefaultHash(model.AppSections.help,this.props.connect.state.viewState)
              )
            }
        
          />
          <ListItem
            primaryText="Archived"
            className='joyride-archive'
            leftIcon={<Archive />}
            onClick={e =>
              this.onSelect(
                "#" + model.ViewState.getDefaultHash(model.AppSections.archived,this.props.connect.state.viewState)
              )
            }
            key={`#/not_done`}
          />
        </List>
      </nav>
    );
  }
}
/*
const mapStateToProps = state => ({
  navCollapsed: state.settings.navCollapsed,
  colorOption: state.settings.colorOption
});

const mapDispatchToProps = dispatch => ({
  handleToggleCollapsedNav: (isNavCollapsed) => {
    dispatch(toggleCollapsedNav(isNavCollapsed));
  },
});
module.exports = connect(
  mapStateToProps,
  mapDispatchToProps
)(Sidebar);*/
