
import * as pApi from "../papi-core";
import { Track, TrackCategory, TrackEvent } from "../../track";

import moment from 'moment'
import { FireStoreDateConverter } from "../../dateConverter"
import uuidV4 from 'uuid/v4';


function getServerDate() {
  return new Date();
}



export interface IFireCtx extends pApi.ICtx {
  getDb(): any;
  getSnapShot(query: any, cb: any): any;
  fireStoreUpdate(func: any): Promise<any>;
  getDateConverter():FireStoreDateConverter
}
const tagify = function (obj: any): any {
  if (!obj.tags) return obj;
  obj["tags_ordered"] = new Array<string>();

  let tags: Array<string> = obj.tags;
  var ret = {};

  for (var i in tags) {
    ret[tags[i].split("/").join(">")] = true;
    obj["tags_ordered"].push(obj.tags[i]);
  }
  obj.tags = ret;

  return obj;
};

const populateToData = function <T extends pApi.baseItem<T>>(
  ctx: IFireCtx,
  data: pApi.baseItem<T>
): any {
  ;
  var now = new Date();
  if (!data.id || data.id == -1 || data.is_new) {
    data.owner_uid = ctx.getId();
    ;
    data.created = now;
  }
  data.modified_uid = ctx.getId();
  data.modified = getServerDate(); //firebase.database.ServerValue.TIMESTAMP;

  var retval = ctx.getDateConverter().toPureJavascriptWithFireStoreDate(data);
  retval.fixedModifiedDate = data.modified;
  delete retval["id"];

  if (retval.tags) retval = tagify(retval);
  return retval;

  //return toPureJavascriptWithFireStoreDate(data);

};

const populateBaseFromData = function <T extends pApi.baseItem<T>>(
  id: any,
  d: any,
  type: { new(): T },
  ctx:IFireCtx
): T {
  var retval: T = new type();
  if (!d.id && !id) {
    throw `could not populateBaseFromData because id not found`;
  }
  if (id) retval.id = id;
  else retval.id = d.id;

  try {
    ;
    ;
    retval.created = ctx.getDateConverter().convertFromFireStoreDate(d.created);
    retval.modified = ctx.getDateConverter().convertFromFireStoreDate(d.modified);
  }
  catch (e) {
    ;
    console.log('error getting date for ', d)
  }
  retval.is_new=false;
  retval.modified_uid = d.modified_uid;
  retval.owner_uid = d.owner_uid;
  retval.meta = d.meta;
  if (d["tags"]) {
    var tags = [];
    if (
      d["tags_ordered"] &&
      d["tags_ordered"].length == Object.keys(d["tags"]).length
    ) {
      tags = d["tags_ordered"];
    } else {
      for (var i in d["tags"]) {
        tags.push(i.split(">").join("/"));
      }
    }
    retval["tags"] = tags;
  }
  return retval;
};

abstract class BaseFireStore<T extends pApi.baseItem<T>> {
  protected ctx: IFireCtx;
  protected _db: any;
  protected _queryManager: QueryListenerManager<T> = new QueryListenerManager<
    T
  >();
  protected getDb(): any {
    //doing this because if you try to get firestore before it inits you get an error
    return this.ctx.getDb();
  }

  protected populateToData(data: pApi.baseItem<T>): any {
    return populateToData<T>(this.ctx, data);
  }
  abstract populateFromData(ctx:IFireCtx,data: any, id?: any):T 
  constructor(ctx: IFireCtx) {
    this.ctx = ctx;
  }
}

export class FireClientTagStore extends BaseFireStore<pApi.Tag>
  implements pApi.ITagStore {
  getType(): string {
    return "FireClientTagStore";
  }

  private DATABASE = "tags";
  updateTag(tag: pApi.Tag): Promise<pApi.Tag> {
    return new Promise<pApi.Tag>(async (resolve, reject) => {
      try {
        var update = this.populateToData(tag);
        await this.ctx.fireStoreUpdate(
          this.getDb()
            .collection(this.DATABASE)
            .doc(tag.id)
            .update(update)
        );
        resolve(tag);
      } catch (error) {
        reject(error);
      }
    });
  }
  getTags(
    updateCallback?: pApi.UpdateCallback<pApi.Tag>
  ): Promise<Array<pApi.Tag>> {
    return new Promise<Array<pApi.Tag>>((resolve, reject) => {
      var map = new Map<string, any>();
      map.set(
        "tags",
        this.getDb()
          .collection(this.DATABASE)
          .where("owner_uid", "==", this.ctx.getId())
      );
      this._queryManager.execute(
        this.ctx,
        map,
        this.sort,
        this.populateFromData.bind(this),
        resolve,
        reject,
        updateCallback
      );
    });
  }
  _cache: Map<string, boolean> = new Map<string, boolean>();
  addTag(name: string): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      try {
        if (this._cache.get(name)) resolve();
        var ref = await this.getDb()
          .collection(this.DATABASE)
          .where("owner_uid", "==", this.ctx.getId())
          .where("name", "==", name.toLocaleLowerCase())
          .get();
        if (ref.size == 0) {
          var tag = new pApi.Tag();

          tag.name = name.toLocaleLowerCase();
          // tag["uid"] = this.ctx.ProfileProvider.getProfile().uuid;
          var update = this.populateToData(tag);
          await this.getDb()
            .collection(this.DATABASE)
            .add(update);
        }
        this._cache.set(name, true);
        resolve();
      } catch (e) {
        reject(e);
      }
    });
  }

  addTags(tags: Array<string>): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      try {
        var cbs = new Array<any>();
        for (var i in tags) {
          var parts = tags[i].split("/");
          let skip = false; // try to remove bad tags - HACK
          for (var ii in parts) {
            if (!parts[ii] || parts[ii].indexOf("#") != -1) {
              skip = true;
            }
          }
          if (!skip) cbs.push(this.addTag(tags[i]));
        }
        await Promise.all(cbs);
        resolve();
      } catch (e) {
        reject(e);
      }
    });
  }
  _removing: boolean = false;
  checkForRemoval(name: string): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      try {
        if (this._removing) return resolve();
        console.log("check remove tag " + name);
        this._removing = true;
        var n = await this.ctx.Todos.listByTag(name);
        if (n.length > 0) return resolve();
        var d = await this.ctx.Notes.searchWithTag(null, [name]);
        if (d.length > 0) return resolve();
        console.log("check remove tag checkdb " + name);
        var snapshot = await this.getDb()
          .collection(this.DATABASE)
          .where("owner_uid", "==", this.ctx.getId())
          .where("name", "==", name.toLocaleLowerCase())
          .get();

        snapshot.forEach(async doc => {
          console.log("delete tag " + name);

          await doc.ref.delete();
          console.log("delete done");
        });
        this._removing = false;
        resolve();
      } catch (e) {
        this._removing = false;
        reject(e);
      }
    });
  }
  populateFromData(ctx:IFireCtx, data: any, id?: any): pApi.Tag {
    var tag = populateBaseFromData<pApi.Tag>(id, data, pApi.Tag,ctx);
    tag.name = data.name;
    return tag;
  }

  lastSnapShot: any;
  async listenForChanges(
    query: any,
    listener: (notes: Array<pApi.Tag>) => void
  ) {
    if (this.lastSnapShot) {
      console.log("unsubscribe");
      await this.lastSnapShot();
    }
    var me = this;

    this.lastSnapShot = this.ctx.getSnapShot(query, function (querySnapshot) {
      var docs = new Array<pApi.Tag>();
      var ex = 0;

      querySnapshot.forEach(function (doc) {
        docs.push(me.populateFromData(me.ctx,doc.data(), doc.id));
      });

      console.log("STAPSHOT UPDATE " + docs.length);
      docs = docs.sort(me.sort);
      listener(docs);
    });
  }

  private sort(n1: pApi.Tag, n2: pApi.Tag) {
    var a = n1.name
    var b = n2.name
    var retval = a > b ? -1 : a < b ? 1 : 0;
    return retval;
  }
}

export class FireClientDeviceStore extends BaseFireStore<pApi.Device>
  implements pApi.IDeviceStore {
  getType(): string {
    return "FireClientDeviceStore";
  }

  private DATABASE = "devices";

  populateFromData(ctx:IFireCtx, data: any, id?: any): pApi.Device {
    var device = populateBaseFromData<pApi.Device>(id, data, pApi.Device,ctx);
    device.allow_push = data.allow_push;
    device.platform = data.platform;
    device.platform_details = data.platform_details;
    device.archive_date = data.archive_date;
    device.token = data.token;
    device.last_used = data.last_used;
    return device;
  }
  list(): Promise<Array<pApi.Device>> {
    return new Promise<Array<pApi.Device>>((resolve, reject) => {
      var map = new Map<string, any>();

      map.set(
        "devices",
        this.getDb()
          .collection(this.DATABASE)
          .where("owner_uid", "==", this.ctx.getId())
      );
      var dxd = this.getDb()
        .collection(this.DATABASE)
        .where("owner_uid", "==", this.ctx.getId());
      this._queryManager.execute(
        this.ctx,
        map,
        (a: pApi.Device, b: pApi.Device) => {
          return a.created > b.created ? 1 : -1;
        },
        this.populateFromData.bind(this),
        resolve,
        reject,
        null
      );
    });
  }
  getByToken(token: any): Promise<pApi.Device> {
    return new Promise<pApi.Device>(async (resolve, reject) => {
      try {
        var devices = await this.list();
        var found = devices.find(x => x.token == token);
        resolve(found);
      } catch (e) {
        reject(e);
      }
    });
  }
  load(id: any): Promise<pApi.Device> {
    return new Promise<pApi.Device>(async (resolve, reject) => {
      try {
        var devices = await this.list();
        var found = devices.find(x => x.id == id);
        resolve(found);
      } catch (e) {
        reject(e);
      }
    });
  }
  save(device: pApi.Device): Promise<pApi.Device> {
    return new Promise<pApi.Device>(async (resolve, reject) => {
      try {
        var modified = getServerDate(); //firebase.database.ServerValue.TIMESTAMP;
        if (device.archive_date === undefined) {
          device.archive_date = null;
        }

        if (device.id && device.id != -1 && !device.is_new) {
          var deviceRef = this.getDb()
            .collection(this.DATABASE)
            .doc(device.id);

          var doc = await deviceRef.get();
          if (!doc.exists) throw `${device.id} not found.`;

          var update = this.populateToData(device);
          device.is_new = false;
          await this.ctx.fireStoreUpdate(deviceRef.update(update));
          resolve(device);
          Track.Event(TrackCategory.objectAction, TrackEvent.updateDevice);
        } else {
          var update = this.populateToData(device);

          var deviceRef;
          if (!device.id || device.id == -1) {
            deviceRef = await this.getDb()
              .collection(this.DATABASE)
              .doc();
          } else {
            deviceRef = await this.getDb()
              .collection(this.DATABASE)
              .doc(device.id);
          }

          await this.ctx.fireStoreUpdate(deviceRef.set(update));
          device.is_new = false;
          device.id = deviceRef.id;

          resolve(device);
          Track.Event(TrackCategory.objectAction, TrackEvent.addDevice);
        }
      } catch (er) {
        Track.reportError(er, "FireClient:Save Note", false);

        reject(er);
      }
    });
  }
  archive(id: any): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      try {
        var devices = await this.list();
        var found = devices.find(x => x.id == id);
        if (found) {
          found.archive_date = new Date();
          await this.save(found);
        } else {
          reject(id + " not found or is already archived");
        }

        resolve();
      } catch (e) {
        reject(e);
      }
    });
  }
}

export class FireClientNoteStore extends BaseFireStore<pApi.Note>
  implements pApi.INoteStore {
  getType(): string {
    return "FireClientNoteStore";
  }
  public DefaultStorageType:pApi.StorageType = pApi.StorageType.snapshot
  setDefaultStorageType(storageType:pApi.StorageType)
  {
    this.DefaultStorageType = storageType
  }
  getDefaultStorageType():pApi.StorageType
  {
    return this.DefaultStorageType
  }
  private DATABASE = "notes2";
  populateFromData(ctx:IFireCtx, data: any, id?: any): pApi.Note {
    var note = populateBaseFromData<pApi.Note>(id, data, pApi.Note,ctx);

    note.title = data.title;
    note.preview_text = data.preview_text;
    note.archive_date = data.archive_date;

    return note;
  }
  load(id: any): Promise<pApi.Note> {
    return new Promise<pApi.Note>(async (resolve, reject) => {
      try {
        var noteRef = this.getDb()
          .collection(this.DATABASE)
          .doc(id);

        var doc = await noteRef.get();
        if (!doc.exists) throw `${id} not found.`;
  
        let retval = this.populateFromData(this.ctx,doc.data(), noteRef.id);
        retval.is_new = false;
        resolve(retval);
      } catch (ex) {
        Track.reportError(ex, "FireClient:Error loading todo", false);
        reject(ex);
      }
    });
  }



  lastSnapShot: any;
  lastContentSnapShot:any;
  async listenForChanges(
    query: any,
    listener: (notes: Array<pApi.Note>) => void
  ) {
    if (this.lastSnapShot) {
      console.log("unsubscribe");
      await this.lastSnapShot();
    }
    
    
    var me = this;

    this.lastSnapShot = this.ctx.getSnapShot(query, function (querySnapshot) {
      var docs = new Array<pApi.Note>();
      var ex = 0;

      querySnapshot.forEach(function (doc) {
        docs.push(me.populateFromData(me.ctx,doc.data(), doc.id));
      });

      console.log("STAPSHOT UPDATE " + docs.length);
      docs = docs.sort(me.sort);
      listener(docs);
    });
  
  }

  setContent(note: pApi.Note, content: pApi.NoteContent): Promise<pApi.Note> {
    //todo make in transaction
    return new Promise<pApi.Note>(async (resolve, reject) => {
      try {
        switch (content.storageType) {
          case pApi.StorageType.embedded:
            note.meta = note.meta ? note.meta : {};
          //  note.meta["format"] = "embedded";
            note.meta["contentType"] = content.contentType.toString();
            note.meta["storageType"] = content.storageType.toString();
            note.meta["format"]  =  note.meta["contentType"]; //legacy
           
            note.meta["embedded_content"] = content;
            this.save(note);
            return resolve(note);
         

          case pApi.StorageType.snapshot:
            note.meta = note.meta ? note.meta : {};
            note.meta["contentType"] = content.contentType.toString();
            note.meta["storageType"] = content.storageType.toString();
            note.meta["format"]  =  note.meta["contentType"]; //legacy
            note.meta["embedded_content"] = null;
     
            var update = populateToData<pApi.NoteContent>(this.ctx, content);
            if (note.is_new) {
              note = await this.save(note);
            }

            if (!note.meta["format_snapshot_id"]) {


              let snapShotId = pApi.newId();
              var snapRef = await this.getDb()
                .collection(this.DATABASE)
                .doc(note.id)
                .collection("snapshot").doc(snapShotId)

              await this.ctx.fireStoreUpdate(snapRef.set(update));
              note.meta["format_snapshot_id"] = snapRef.id;
            } else {
              await this.ctx.fireStoreUpdate(
                this.getDb()
                  .collection(this.DATABASE)
                  .doc(note.id)
                  .collection("snapshot")
                  .doc(note.meta["format_snapshot_id"])
                  .update(update)
              );
            }
            await this.save(note);
            return resolve(note);

          default:
            reject("did not understand storage type of " + content.storageType);
            break;
        }
      } catch (e) {
        reject(e);
      }
    });
  }
  getContent(note: pApi.Note): Promise<pApi.NoteContent> {
    return new Promise<pApi.NoteContent>(async (resolve, reject) => {
      try {
      
        function fixContent(content:pApi.NoteContent):pApi.NoteContent
        {
        
            if(!content.storageType)
            {
                content.storageType = pApi.StorageType.embedded
               // delete content['format']
               
            }
            if(! content.contentType )
            {
              content.contentType = pApi.ContentType.quillJS;
            }
            return content;
        } 
       
        let storageType:pApi.StorageType = note.meta.storageType?pApi.StorageType[note.meta.storageType]:pApi.StorageType[note.meta.format];
        switch (storageType) {
          case pApi.StorageType.embedded:
            var nc: pApi.NoteContent = note.meta[
              "embedded_content"
            ] as pApi.NoteContent;

            return resolve(fixContent(nc));
          case pApi.StorageType.snapshot:
            var ref = await this.getDb()
              .collection(this.DATABASE)
              .doc(note.id)
              .collection("snapshot")
              .doc(note.meta["format_snapshot_id"])
              .get();
        
           /*  this.getDb
              //where(firebase.firestore.FieldPath.documentId(), '==', 'fK3ddutEpD2qQqRMXNW5').get()
              var ref = await this.getDb().collectionGroup("snapshot").where(this.ctx.getDocumentFieldId(), '==', note.meta["format_snapshot_id"]).where('uid','==',this.ctx.ProfileProvider.getProfile().uuid)
              .get();
           */
            var data = await ref.data();
            ;
            var ret = populateBaseFromData<pApi.NoteContent>(
              ref.id,
              data,
              pApi.NoteContent,
              this.ctx
            );
            ret.content = data.content;

            return resolve(fixContent(ret));
        }
        reject("unknow document format");
      } catch (e) {
        //throw e;

        Track.reportError(e, "FireClient:Error GetContent", false);
        reject(e);
      }
    });
  }
  archive(id: any): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      try {
        var note = await this.load(id);
        note.archive_date = new Date();
        await this.save(note);

        resolve();
        Track.Event(TrackCategory.objectAction, TrackEvent.deleteNote);
      } catch (e) {
        reject(e);
      }
    });
  }
  save(item: pApi.Note): Promise<pApi.Note> {
    return new Promise<pApi.Note>(async (resolve, reject) => {
      try {
        var modified = getServerDate(); //firebase.database.ServerValue.TIMESTAMP;
        if (item.archive_date === undefined) {
          item.archive_date = null;
        }
        //!data.id || data.id == -1 || data.is_new
        if (item.id && item.id != -1 && !item.is_new) {
          var noteRef = this.getDb()
            .collection(this.DATABASE)
            .doc(item.id);
          ;
          var doc = await noteRef.get();
          if (!doc.exists) throw `${item.id} not found.`;
         
          var update = this.populateToData(item);
         
          // await noteRef.update(update);
          await this.ctx.fireStoreUpdate(noteRef.update(update));
          item.is_new = false;
          resolve(item);
          this.ctx.Tags.addTags(item.tags);
          if (!this["lastUpdateNote"] || this["lastUpdateNote"] != item.id) {
            //prevent duplicate tracking
            Track.Event(TrackCategory.objectAction, TrackEvent.updateNote);
            this["lastUpdateNote"] = item.id;
          }
        } else {
          var update = this.populateToData(item);

          var noteRef;
          if (!item.id || item.id == -1) {
            noteRef = await this.getDb()
              .collection(this.DATABASE)
              .doc();
          } else {
            noteRef = await this.getDb()
              .collection(this.DATABASE)
              .doc(item.id);
          }

          await this.ctx.fireStoreUpdate(noteRef.set(update));
          item.is_new = false;
          item.id = noteRef.id;

          resolve(item);
          this.ctx.Tags.addTags(item.tags);
          Track.Event(TrackCategory.objectAction, TrackEvent.createNote);
        }
      } catch (er) {
        Track.reportError(er, "FireClient:Save Note", false);

        reject(er);
      }
    });
  }
  search(text: string): Promise<Array<pApi.Note>> {
    return this.searchWithTag(text, null);
  }
  copyDatabase(source: string, dest: string): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        let me = this;
        var query = this.getDb().collection("notes"); //.where('uid','==',this.ctx.ProfileProvider.getProfile().uuid)
        var retVal = new Array<pApi.Note>();
        var results = await query.get();
        for (var i in results.docs) {
          var res = results.docs[i];
          var data = await res.data();
          var note: pApi.Note = this.populateFromData(me.ctx, data, res.id);
          if (note.tags.indexOf("archive") == -1) {
            note.meta = {};
            note.owner_uid = this.ctx.getId();
            note.modified_uid = this.ctx.getId();

            note.meta["embedded_content"] = data.content;
            retVal.push(note);
          }
        }
        var d = new FireClientNoteStore(this.ctx);

        for (var i in retVal) {
          var newItem = retVal[i];
          var content = newItem.meta["embedded_content"];
          delete newItem.meta["embedded_content"];
          delete newItem.id;
          console.log("Saving " + newItem.title);
          newItem = await d.save(newItem);
          var c = new pApi.NoteContent();
          c.content = content;
          c.storageType = pApi.StorageType.snapshot;
          await d.setContent(newItem, c);
          //  await d.save(newItem);
        }
        resolve();
      } catch (e) {
        reject(e);
      }
    });
  };
  searchWithTag(
    queryText: string,
    tags?: Array<string>,
    listener?: (notes: Array<pApi.Note>) => void
  ): Promise<Array<pApi.Note>> {
    return new Promise<Array<pApi.Note>>(async (resolve, reject) => {
      try {
        console.log("search" + tags ? JSON.stringify(tags) : queryText);

        var query = this.getDb().collection(this.DATABASE); //.where('uid','==',this.ctx.ProfileProvider.getProfile().uuid)
        query = query.where("owner_uid", "==", this.ctx.getId());
        query = query.where("archive_date", "==", null);
        if (tags) {
          for (var i in tags) {
            query = query.where(
              "tags." + tags[i].split("/").join(">"),
              "==",
              true
            );
          }
        }
        if (!tags || tags.length == 0) {
          query = query.limit(10000);
          query = query.orderBy("modified", "desc");
        }
        if (queryText) {
        }
        var retVal = new Array<pApi.Note>();
        if (listener) {
          this.listenForChanges(query, listener);
          return resolve(retVal);
        }

        var results = await query.get();
        for (var i in results.docs) {
          var res = results.docs[i];

          var data = await res.data();
          if (queryText && data.title) {
            if (data.title.toLowerCase().indexOf(queryText) != -1)
              retVal.push(this.populateFromData(this.ctx,data, res.id));
          } else retVal.push(this.populateFromData(this.ctx,data, res.id));
        }
        /*
        if (retVal.length == 0 && tags) {
          for (var i in tags) {
            await this.ctx.Tags.checkForRemoval(tags[i]);
          }
        }*/
        resolve(retVal.sort(this.sort));
      } catch (ex) {
        Track.reportError(ex, "FireClient:Search With Tag", false);
        reject(ex);
      }
    });
  }
  private sort(n1: pApi.Note, n2: pApi.Note) {
    var a = new Date(n1.modified);
    var b = new Date(n2.modified);
    var retval = a > b ? -1 : a < b ? 1 : 0;
    //console.log(n1.title+' '+a + ' '+n2.title+' '+b+' '+retval)
    return retval;
    // return a>b ? -1 : a<b ? 1 : 0;
    /*
    if (new Date(n1.modified)> new Date(n2.modified)){
      return 1;
    }
    if (new Date(n1.modified)<new Date(n2.modified)) return -1;
    if(n1.content > n2.content)
      return -1
   */
  }
  updateContent(id: any, content: Array<any>) {
    return new Promise<void>((resolve, reject) => {
      reject("not implemented");
    });
  }

  setTags(id: any, tags: Array<string>): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      reject("not implemented");
    });
  }
  list(
    listener?: (notes: Array<pApi.Note>) => void
  ): Promise<Array<pApi.Note>> {
    return this.searchWithTag(null, [], listener);
  }
}
class FireWhere {
  col: any;
  operator: any;
  value: any;
  constructor(col: any, operator: any, value: any) {
    this.col = col;
    this.operator = operator;
    this.value = value;
  }
}

class QuerySnapshot {
  name: string;
  snapshot: any;
}
export class QueryListener<T> {
  private updatesCallback: (notes: Array<T>) => void;
  private resultsCallback: (notes: Array<T>) => void;
  private snapshots: Map<string, any> = new Map<string, any>();
  private datasets: Map<string, boolean> = new Map<string, boolean>();
  private receivedFirst: boolean = false;
  private cache: Map<string, Array<T>> = new Map<string, Array<T>>();
  private sort: (n1: T, n2: T) => number;
  private receiveData(queryName: any, items: Array<T>) {
    this.cache.set(queryName, items);
    console.log(">> Recevied " + queryName + " " + items.length);
    var resultCache = new Map<any, T>();

    this.cache.forEach((arr, key) => {
      if (key != queryName) {
        for (var k in arr) {
          resultCache.set(arr[k]["id"], arr[k]);
        }
      }
    });
    for (var i in items) {
      resultCache.set(items[i]["id"], items[i]);
    }

    this.datasets.set(queryName, true);
    let results = new Array<T>();
    resultCache.forEach((value, key) => {
      results.push(value);
    });
    results = results.sort(this.sort);
    console.log(">> " + results.length);
    if (!this.receivedFirst) {
      let done: boolean = true;
      this.datasets.forEach((value, key) => {
        if (!value) {
          done = false;
        }
      });

      this.receivedFirst = done;
      if (done) {
        console.log(">> done " + results.length);
        this.resultsCallback(results);
        if (!this.updatesCallback) {
          this.stopListening();
        }
      } else {
        console.log(">> notdone " + results.length);
      }
    } else {
      console.log(">> update " + queryName + " " + results.length);

      this.updatesCallback(results);
    }
  }
  public stopListening(): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      try {
        this.snapshots.forEach(async (value, key) => {
          await value();
        });

        resolve();
      } catch (e) {
        reject(e);
      }
    });
  }
  public constructor(
    ctx: IFireCtx,
    queries: Map<string, any>,
    sort: (n1: T, n2: T) => number,
    deserializer: (ctx:IFireCtx,item: any, id?: any) => T,
    resultsCallback: (notes: Array<T>) => void,
    errorCallback: (e: any) => void,
    updateCallback: (notes: Array<T>) => void
  ) {
    this.updatesCallback = updateCallback;
    this.resultsCallback = resultsCallback;
    var me = this;

    this.sort = sort;

    queries.forEach((value, key) => {
      this.datasets.set(key, false);
      console.log("query " + key);
      me.snapshots.set(
        key,
        ctx.getSnapShot(value, function (querySnapshot) {
          console.log('Got Results for ' + key + ' ' + querySnapshot.length)
          var docs = new Array<T>();
          var ex = 0;

          querySnapshot.forEach(function (doc) {
            var d = doc.data();
            d.id = doc.id;
            docs.push(deserializer(ctx,d));
          });
          me.receiveData(key, docs);
        })
      );
    });
  }
}
export class QueryListenerManager<T> {
  listeners: Map<any, QueryListener<T>> = new Map<any, QueryListener<T>>();
  async execute(
    ctx: IFireCtx,
    callbackqueries: Map<string, any>,
    sort: (n1: T, n2: T) => number,
    deserializer: (ctx:IFireCtx,item: any) => T,
    resultsCallback: (notes: Array<T>) => void,
    errorCallback: (e: any) => void,
    updateCallback?: pApi.UpdateCallback<T>
  ) {
    var listenKey = "main";
    if (updateCallback) {
      listenKey = updateCallback.key;
      var l = await this.listeners.get(updateCallback.key);
      if (l) {
        console.log("stop listening to " + updateCallback.key);
        await l.stopListening();
      }
    }

    this.listeners.set(
      listenKey,
      new QueryListener<T>(
        ctx,
        callbackqueries,
        sort,
        deserializer,
        resultsCallback,
        errorCallback,
        updateCallback ? updateCallback.callback : null
      )
    );
  }
}

export class FireClientTodoStore extends BaseFireStore<pApi.ToDoItem>
  implements pApi.ITodoListStore {
  private DATABASE = "todos";
  private batch: any = null;
  getType(): string {
    return "FireClientTodoStore";
  }

  populateFromData(ctx:IFireCtx,data: any, id?: any): pApi.ToDoItem {
    var todo = populateBaseFromData<pApi.ToDoItem>(id, data, pApi.ToDoItem,ctx);
    todo.checked = data.checked;
    todo.description = data.description;
    if (!todo.description) {
      todo.description = "";
    }
   
    todo.due_date = this.ctx.getDateConverter().convertFromFireStoreDate(data.due_date);
    todo.note_id = data.note_id;
    todo.objective_id = data.objective_id;
    todo.priority = data.priority;
    todo.sync_id = data.sync_id;
    todo.hide_from_inbox = data.hide_from_inbox;
    todo.alarm = data.alarm;
    todo.is_new = false;

    todo.project_type = data.project_type;
    todo.recoccuring = data.recoccuring;
    todo.snooze_count = data.snooze_count ? data.snooze_count : 0;
    if (data.recoccuring_settings) {
      todo.recoccuring_settings = new pApi.ReoccuringSettings();
      todo.recoccuring_settings.reoccuring_day_of_month =
        data.recoccuring_settings.reoccuring_day_of_month;
      todo.recoccuring_settings.reoccuring_day_of_week =
        data.recoccuring_settings.reoccuring_day_of_week;
      todo.recoccuring_settings.reoccuring_day_of_month =
        data.recoccuring_settings.reoccuring_day_of_month;
      todo.recoccuring_settings.reoccuring_interval =
        data.recoccuring_settings.reoccuring_interval;
      todo.recoccuring_settings.time_of_day =
      this.ctx.getDateConverter().convertFromFireStoreDate(data.recoccuring_settings.time_of_day)
    }
    /*
    todo.tags = [];
    for (var i in data.tags) {
      todo.tags.push(i.replace(">", "/"));
    }*/

    return todo;
  }

  load(id: any): Promise<pApi.ToDoItem> {
    return new Promise<pApi.ToDoItem>(async (resolve, reject) => {
      try {
        var noteRef = this.getDb()
          .collection(this.DATABASE)
          .doc(id);
        var doc = await noteRef.get();
        if (!doc.exists) throw `${id} not found.`;
        var data = doc.data();
        resolve(this.populateFromData(this.ctx,data, id));
      } catch (ex) {
        Track.reportError(ex, "FireClient:Load Todo", false);
        reject(ex);
      }
    });
  }
  save(todo: pApi.ToDoItem): Promise<pApi.ToDoItem> {
    return new Promise<pApi.ToDoItem>(async (resolve, reject) => {
      try {
        var modified = getServerDate(); //firebase.database.ServerValue.TIMESTAMP;
      
        
        if (!todo.description) throw "A to-do must have a description.";
        if (todo.due_date && typeof todo.due_date == "string") {
          todo.due_date = new Date(todo.due_date);
        }
        if (todo.recoccuring_settings && todo.checked) {
          todo.due_date = this.getNextReoccuringDate(todo.recoccuring_settings);
        }
        todo.project_type = todo.project_type
          ? todo.project_type
          : pApi.ProjectType.ptInbox;

        todo.sync_id = this.ctx.getSyncId();
        if (todo.id && todo.id != -1 && !todo.is_new) {
          var noteRef = this.getDb()
            .collection(this.DATABASE)
            .doc(todo.id);
          var doc = await noteRef.get();
          if (!doc.exists) throw `${todo.id} not found.`;

          if (!todo.recoccuring_settings && todo.due_date && (!doc.data().due_date || this.ctx.getDateConverter().convertFromFireStoreDate(doc.data().due_date).getTime() != todo.due_date.getTime())) {
            todo.snooze_count = ((!doc.data().snooze_count) ? 0 : doc.data().snooze_count) + 1;
          }
          var update = this.populateToData(todo);
          update.checked = update.checked ? true : false;
          if (this.batch) {
            try {
              await this.batch.update(noteRef, update);
              this.batch.tags = (this.batch.tags ? this.batch.tags : []).push(
                todo.tags
              );
            } catch (warn) {
              console.warn("batch not working revert to one commit");
              await this.ctx.fireStoreUpdate(noteRef.update(update));
              this.ctx.Tags.addTags(todo.tags);
            }
          } else {
            await this.ctx.fireStoreUpdate(noteRef.update(update));
          }
          todo.is_new = false;
          this.ctx.Tags.addTags(todo.tags);
          resolve(todo);
        } else {
          var update = this.populateToData(todo);
          update.checked = update.checked ? true : false;
          let noteRef;
          if (!todo.id || todo.id == -1) {
            noteRef = await this.getDb()
              .collection(this.DATABASE)
              .doc();
          } else {
            noteRef = await this.getDb()
              .collection(this.DATABASE)
              .doc(todo.id);
          }
          if (this.batch) {
            try {
              await this.batch.set(noteRef, update);
              this.batch.tags = (this.batch.tags ? this.batch.tags : []).push(
                todo.tags
              );
            } catch (e) {
              console.warn("batch not working revert to one commit");
              await this.ctx.fireStoreUpdate(noteRef.set(update));
              this.ctx.Tags.addTags(todo.tags);
            }
          } else {
            console.log('update ' + JSON.stringify(update))
            await this.ctx.fireStoreUpdate(noteRef.set(update));
            this.ctx.Tags.addTags(todo.tags);
          }

          todo.id = noteRef.id;
          todo.is_new = false;

          resolve(todo);
        }
      } catch (er) {
        Track.reportError(er, "FireClient:Save Todo", false);
        reject(er);
      }
    });
  }
  fbOneSearch(fireWhere: FireWhere): Promise<Array<pApi.ToDoItem>> {
    var arr = Array<FireWhere>();
    arr.push(fireWhere);
    return this.fbSearch(false, arr, null);
  }
  fireOneQuery(fireWhere: FireWhere): any {
    var arr = Array<FireWhere>();
    arr.push(fireWhere);
    return this.fireQuery(false, arr, null);
  }
  fireQuery(
    includChecked: boolean,
    fireWhere: Array<FireWhere>,
    tags?: Array<string>
  ): any {
    var query = this.getDb()
      .collection(this.DATABASE)
      .where("owner_uid", "==", this.ctx.getId());
    if (tags) {
      for (var i in tags) {
        query = query.where("tags." + tags[i].split("/").join(">"), "==", true);
      }
    }
    if (!includChecked) query = query.where("checked", "==", false);

    if (FireWhere) {
      for (var i in fireWhere) {
        query = query.where(
          fireWhere[i].col,
          fireWhere[i].operator,
          fireWhere[i].value
        );
      }
    }

    return query;
  }
  fbSearch(
    includeChecked: boolean,
    fireWhere: Array<FireWhere>,
    tags?: Array<string>
  ): Promise<Array<pApi.ToDoItem>> {
    return new Promise<Array<pApi.ToDoItem>>(async (resolve, reject) => {
      try {
        var query = this.getDb().collection(this.DATABASE);
        if (tags) {
          for (var i in tags) {
            query = query.where(
              "tags." + tags[i].split("/").join(">"),
              "==",
              true
            );
          }
        }
        if (includeChecked) query = query.where("checked", "==", false);

        if (FireWhere) {
          for (var i in fireWhere) {
            query = query.where(
              fireWhere[i].col,
              fireWhere[i].operator,
              fireWhere[i].value
            );
          }
        }

        var retVal = new Array<pApi.ToDoItem>();
        /*if(listener)
        {
          
          this.listenForChanges(query,listener);
          return resolve(retVal);
        }
*/

        var results = await query.get();
        for (var i in results.docs) {
          var res = results.docs[i];

          var data = await res.data();

          retVal.push(this.populateFromData(this.ctx,data, res.id));
        }
        resolve(retVal);
      } catch (ex) {
        console.error(ex);
        reject(ex);
      }
    });
  }
  private qListner: Map<any, QueryListener<pApi.ToDoItem>> = new Map<
    any,
    QueryListener<pApi.ToDoItem>
  >();
  private listenerCallback: (notes: Array<pApi.ToDoItem>) => void;

  unregisterCallback(cb: pApi.UpdateCallback<pApi.ToDoItem>): Promise<void> {
    return this._queryManager.listeners.get(cb.key).stopListening();
  }

  getAllOutstandingItems(
    updateCallback?: pApi.UpdateCallback<pApi.ToDoItem>
  ): Promise<Array<pApi.ToDoItem>> {
    return new Promise<Array<pApi.ToDoItem>>(async (resolve, reject) => {
      try {
        var map = new Map<string, any>();
        /*map.set(
          "due",
          this.fireOneQuery(new FireWhere("due_date", "<", new Date()))
        );
        map.set(
          "inbox",
          this.fireOneQuery(
            new FireWhere("project_type", "==", pApi.ProjectType.ptInbox)
          )
        );
        map.set(
          "important",
          this.fireOneQuery(
            new FireWhere("priority", "==", pApi.ToDoItemPriority.High)
          )
        );*/
        map.set("allOpen", this.fireQuery(false, []));
        this._queryManager.execute(
          this.ctx,
          map,
          this.sort,
          this.populateFromData.bind(this),
          resolve,
          reject,
          updateCallback
        );
      } catch (e) {
        reject(e);
      }
    });
  }
  private sortByModified(n1: pApi.ToDoItem, n2: pApi.ToDoItem) {
    return n1.modified < n2.modified ? 1 : -2;
  }
  private sort(n1: pApi.ToDoItem, n2: pApi.ToDoItem) {
    return pApi.TodoSort(n1, n2);
  }
  private getCallback(
    updateCallback?: pApi.UpdateCallback<pApi.ToDoItem>
  ): pApi.UpdateCallback<pApi.ToDoItem> {
    if (updateCallback) return updateCallback;
    var m = this.qListner.get("main");
    if (!m) {
    }
  }
  /*getChangedSince(date:Date): Promise<Array<pApi.ToDoItem>>
  {
    return new Promise<Array<pApi.ToDoItem>>(async (resolve, reject) => {
      try {
        var wheres = new Array<FireWhere>();
        console.log('search since date '+date)
       // let d = convertToFireStoreDate(date).nanoseconds
       // wheres.push(new FireWhere("modified", ">=",date));
        let fq = this.fireQuery(true, wheres);
        fq = fq.orderBy("modified", "asc").limit(10);
        var map = new Map<string, any>();
        map.set("getChangedSince", fq);
        this._queryManager.execute(
          this.ctx,
          map,
          this.sortByModified, 
          this.populateFromData,
          resolve,
          reject,
          null
        );
      } catch (e) {
        reject(e);
      }
    });
  }*/
  getClosedItems(startAt?: any,
    updateCallback?: pApi.UpdateCallback<pApi.ToDoItem>
  ): Promise<Array<pApi.ToDoItem>> {
    return new Promise<Array<pApi.ToDoItem>>(async (resolve, reject) => {
      try {
        var query = this.getDb().collection(this.DATABASE); //.where('uid','==',this.ctx.ProfileProvider.getProfile().uuid)
        query = query.where("owner_uid", "==", this.ctx.getId());
        if (startAt) {
          console.log('Start at ' + startAt)
          query = query.where("archived_date", ">", startAt);
        }
        //query = query.where("modified", "<",new Date(2030,5,5));
        query = query.where("checked", "==", true).limit(250).orderBy("modified", "desc")

        let results = await query.get();
        console.log(results)
        let retval = new Array<pApi.ToDoItem>();
        for (var i in results.docs) {
          var res = results.docs[i];

          var data = await res.data();
          retval.push(this.populateFromData(this.ctx,data, res.id));

        }
        resolve(retval);
        /*
       // fq =fq.limit(250);
        var map = new Map<string, any>();
        map.set("archived", fq);
        this._queryManager.execute(
          this.ctx,
          map,
          this.sortByModified,
          this.populateFromData,
          resolve,
          reject,
          updateCallback
        );*/
      } catch (e) {
        reject(e);
      }
    });
  }
  getSnoozedItems(
    updateCallback?: pApi.UpdateCallback<pApi.ToDoItem>
  ): Promise<Array<pApi.ToDoItem>> {
    return new Promise<Array<pApi.ToDoItem>>(async (resolve, reject) => {
      try {
        var wheres = new Array<FireWhere>();
        wheres.push(new FireWhere("due_date", ">", new Date()));
        /*wheres.push(new FireWhere("due_date", ">", ''));*/
        var listenKey = "main";
        if (updateCallback) {
          listenKey = updateCallback.key;
          var l = await this.qListner.get(updateCallback.key);
          if (l) {
            console.log("stoplistening " + updateCallback.key);
            await l.stopListening();
          }
        }
        var map = new Map<string, any>();
        map.set("snooze", this.fireQuery(false, wheres));
        this._queryManager.execute(
          this.ctx,
          map,
          this.sort,
          this.populateFromData.bind(this),
          resolve,
          reject,
          updateCallback
        );
      } catch (e) {
        reject(e);
      }
    });
  }
  //getDueItems(project_id: any): Promise<Array<pApi.ToDoItem>>;
  getByProjectType(
    projectType: pApi.ProjectType
  ): Promise<Array<pApi.ToDoItem>> {
    return this.fbOneSearch(new FireWhere("project_type", "==", projectType));
  }
  getByNote(
    note_id: any,
    updateCallback?: pApi.UpdateCallback<pApi.ToDoItem>
  ): Promise<Array<pApi.ToDoItem>> {
    return new Promise<Array<pApi.ToDoItem>>(async (resolve, reject) => {
      var map = new Map<string, any>();
      map.set(
        "note",
        this.fireQuery(true, [new FireWhere("note_id", "==", note_id)])
      );
      this._queryManager.execute(
        this.ctx,
        map,
        this.sort,
        this.populateFromData.bind(this),
        resolve,
        reject,
        updateCallback
      );
      //return this.fbOneSearch(new FireWhere("note_id", "==", note_id));
    });
  }
  listByTag(
    tag: string,
    updateCallback?: pApi.UpdateCallback<pApi.ToDoItem>
  ): Promise<Array<pApi.ToDoItem>> {
    return new Promise<Array<pApi.ToDoItem>>(async (resolve, reject) => {
      var query = this.fireQuery(false, null, [tag]);
      var results = (results: Array<pApi.ToDoItem>) => {
        return resolve(results);
      };
      var map = new Map<string, any>();
      map.set("bytag", this.fireQuery(false, null, [tag]));
      var me = this;
      var beforeResolve = async function (results: Array<pApi.ToDoItem>) {
        /* if (results.length == 0) {
          await me.ctx.Tags.checkForRemoval(tag);
        }*/
        resolve(results);
      };
      this._queryManager.execute(
        this.ctx,
        map,
        this.sort,
        this.populateFromData.bind(this),
        beforeResolve,
        reject,
        updateCallback
      );
    });
  }
  search(text: string): Promise<Array<pApi.ToDoItem>> {
    return new Promise<Array<pApi.ToDoItem>>(async (resolve, reject) => {
      try {
        resolve(new Array<pApi.ToDoItem>());
      } catch (ex) {
        reject(ex);
      }
    });
  }
  /*
  listByTagAndNotes(
    tag: string,
    noteIds: Array<any>
  ): Promise<Array<pApi.ToDoItem>> {
    return new Promise<Array<pApi.ToDoItem>>(async (resolve, reject) => {
      try {
        var retval = await this.listByTag(tag);
        var notes = await this.ctx.Notes.searchWithTag(null, [tag]);
        for (var i in notes) {
          retval.concat(await this.getByNote(notes[i].id));
        }
        resolve(retval);
      } catch (e) {
        reject(e);
      }
    });
  }*/
  getNextReoccuringDate(rs: pApi.ReoccuringSettings): Date {
    let timeOfDate = rs.time_of_day ? rs.time_of_day : new Date();

    switch (rs.reoccuring_interval) {
      case pApi.RecoccuringIntervalEnum.kiDaily:
        return pApi.getSnoozeDate(pApi.SnoozeAction.tomorrow, null);
      case pApi.RecoccuringIntervalEnum.kiWeekday:
        let isWeekend = !(new Date().getDay() % 6);
        if (isWeekend)
          return pApi.getSnoozeDate(pApi.SnoozeAction.nextWeek);
        return pApi.getSnoozeDate(pApi.SnoozeAction.tomorrow, null);


      case pApi.RecoccuringIntervalEnum.kiMonthy:

        var d = moment()
          .date(rs.reoccuring_day_of_month as number)
          .hour(timeOfDate.getHours())
          .minute(timeOfDate.getMinutes())
          .second(0)
          .millisecond(0);
        if (d < moment()) {
          d = d.add("month", 1);
        }
        return d.toDate();
      case pApi.RecoccuringIntervalEnum.kiWeekly:

        var d = moment()
          .day((rs.reoccuring_day_of_week as number) + 7)
          .hour(timeOfDate.getHours())
          .minute(timeOfDate.getMinutes())
          .second(0)
          .millisecond(0);
        return d.toDate();
    }
  }
  complete(id: any): Promise<pApi.ToDoItem> {
    return new Promise<pApi.ToDoItem>(async (resolve, reject) => {
      try {
        let t = await this.load(id);
        if (t.recoccuring_settings) {
          t.due_date = this.getNextReoccuringDate(t.recoccuring_settings);
        } else {
          t.checked = true;
        }
        t = await this.save(t);
        resolve(t);
        Track.Event(TrackCategory.objectAction, TrackEvent.completeTodo);
      } catch (e) {
        reject(e);
      }
    });
  }

  snooze(id: any, snooze: pApi.SnoozeAction, custom?: Date): Promise<Date> {
    return new Promise<Date>(async (resolve, reject) => {
      try {
        var t = await this.load(id);
        var d = pApi.getSnoozeDate(snooze, custom);
        t.due_date = d;

        this.save(t);
        Track.Event(TrackCategory.objectAction, TrackEvent.snoozeTodo);
        resolve(d);
      } catch (e) {
        reject(e);
      }
    });
  }
  delete(id: any): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      reject("delete not implemented");
    });
  }
  startBatch(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      try {
        this.batch = this.getDb().batch();
        resolve();
      } catch (e) {
        reject(e);
      }
    });
  }
  commitBatch(): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      try {
        await this.batch.commit();
        if (this.batch.tags) {
          this.ctx.Tags.addTags(this.batch.tags);
        }
        this.batch = null;
        resolve();
      } catch (e) {
        reject(e);
      }
    });
  }
  cancelBatch(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      try {
        this.batch = null;
        resolve();
      } catch (e) {
        reject(e);
      }
    });
  }
}
class dataTOCheckInConverter {
  static dataToCheckin(data: any): pApi.ObjectiveCheckIn {
    var checkIn = new pApi.ObjectiveCheckIn();
    checkIn.objective_id = data.objective_id;
    checkIn.content = data.content;
    checkIn.close = data.close;
    checkIn.check_in_date = data.check_in_date;
    checkIn.objective_progress_status = data.objective_progress_status;
    if (data.key_results) {
      let keyResults = new Array<pApi.KeyResultCheckIn>();
      for (var i in data.key_results) {
        var dkr = data.key_results[i];
        var kr = new pApi.KeyResultCheckIn();
        kr.completed = dkr.completed;
        kr.key_result_id = dkr.key_result_id;
        kr.metric_type = dkr.metric_type;
        kr.percentage_done = dkr.percentage_done;
        kr.value = dkr.value;

        keyResults.push(kr);
      }
      checkIn.key_results = keyResults;
    }
    return checkIn;
  }
}
export class FireObjectiveStore extends BaseFireStore<pApi.Objective>
  implements pApi.IObjectiveStore {
  search(text: string): Promise<Array<pApi.Objective>> {
    return new Promise<Array<pApi.Objective>>((resolve, reject) => {
      reject("not implemented");
    });
  }

  private DATABASE = "objectives";
  private CHECKINDB = "checkin";

  getType(): string {
    return "FireClientObjectivesStore";
  }
  fireOneQuery(fireWhere: FireWhere): any {
    var arr = Array<FireWhere>();
    arr.push(fireWhere);
    return this.fireQuery(arr, null);
  }
  fireQuery(fireWhere: Array<FireWhere>, tags?: Array<string>): any {
    var query = this.getDb().collection(this.DATABASE);
    if (tags) {
      for (var i in tags) {
        query = query.where("tags." + tags[i].split("/").join(">"), "==", true);
      }
    }
    query = query.where("owner_uid", "==", this.ctx.getId());

    if (FireWhere) {
      for (var i in fireWhere) {
        query = query.where(
          fireWhere[i].col,
          fireWhere[i].operator,
          fireWhere[i].value
        );
      }
    }

    return query;
  }

   populateFromData(ctx:IFireCtx,data: any, id?: any): pApi.Objective {
    var objective = populateBaseFromData<pApi.Objective>(
      id,
      data,
      pApi.Objective,ctx
    );
    objective.checkin_interval = data.checkin_interval;
    objective.close_date = data.close_date;
    objective.content = data.content;
    objective.due_date = data.due_date;
    objective.id = data.id;

    if (data.key_results) {
      objective.key_results = new Array<pApi.KeyResult>();
      for (var i in data.key_results) {
        var dkr = data.key_results[i];
        var kr = new pApi.KeyResult();
        kr.id = dkr.id;
        kr.deactive = dkr.deactive;
        kr.content = dkr.content;
        kr.compleated = dkr.compleated;
        kr.metric_type = dkr.metric_type;
        kr.numerical_goal = dkr.numerical_goal;
        kr.objective_id = dkr.objective_id;
        objective.key_results.push(dkr);
      }
    }
    if (data.last_checkin) {
      objective.last_checkin = dataTOCheckInConverter.dataToCheckin(
        data.last_checkin
      );
    }
    //todo.last_checkin = data.last_checkin;
    objective.progress_status = data.progress_status;
    objective.reminder_todo_id = data.reminder_todo_id;
    return objective;
  }
  load(id: any): Promise<pApi.Objective> {
    return new Promise<pApi.Objective>(async (resolve, reject) => {
      try {
        var noteRef = this.getDb()
          .collection(this.DATABASE)
          .doc(id);
        var doc = await noteRef.get();
        if (!doc.exists) throw `${id} not found.`;
        var data = doc.data();

        data.id = id;
        resolve(this.populateFromData(this.ctx,data));
      } catch (ex) {
        Track.reportError(ex, "FireClient:Load Objective", false);
        reject(ex);
      }
    });
  }
  private _closeObjective(objective: pApi.Objective): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      try {
        if (objective.reminder_todo_id) {
          var findTodo = await this.ctx.Todos.load(objective.reminder_todo_id);
          if (findTodo) {
            findTodo.checked = true;
            findTodo.checked = true;
            findTodo.recoccuring = false;
            delete findTodo.recoccuring_settings;
            await this.ctx.Todos.save(findTodo);
          }
        }
        resolve();
      } catch (e) {
        reject(e);
      }
    });
  }
  save(objective: pApi.Objective): Promise<pApi.Objective> {
    return new Promise<pApi.Objective>(async (resolve, reject) => {
      try {
        var note = new pApi.Objective();
        var modified = getServerDate(); //firebase.database.ServerValue.TIMESTAMP;
        if (objective.key_results) {
          // add UID to key results
          for (var i in objective.key_results) {
            if (!objective.key_results[i].id) {
              objective.key_results[i].id = uuidV4();
            }
          }
        }
        if (objective.id && objective.id != -1) {
          var noteRef = this.getDb()
            .collection(this.DATABASE)
            .doc(objective.id);
          var doc = await noteRef.get();
          if (!doc.exists) throw `${objective.id} not found.`;

          var update = this.populateToData(objective);

          update.checked = update.checked ? true : false;
          await this.ctx.fireStoreUpdate(noteRef.update(update));
          // resolve(objective);
        } else {
          var update = this.populateToData(objective);
          update.checked = update.checked ? true : false;
          var docRef = await this.getDb()
            .collection(this.DATABASE)
            .add(update);
          objective.id = docRef.id;
          //resolve(objective);
        }
        var checkInTodo = new pApi.ToDoItem();
        if (!objective.close_date) {
          checkInTodo.description = "Objective Check-In : " + objective.content;
          checkInTodo.recoccuring_settings = objective.checkin_interval;
          checkInTodo.recoccuring = true;
          checkInTodo.project_type = pApi.ProjectType.ptInbox;
          checkInTodo.objective_id = objective.id;

          var checkInTodo = await this.ctx.Todos.save(checkInTodo);
          if (!objective.reminder_todo_id) {
            var noteRef = this.getDb()
              .collection(this.DATABASE)
              .doc(objective.id);
            await this.ctx.fireStoreUpdate(
              noteRef.update({
                reminder_todo_id: checkInTodo.id
              })
            );
            objective.reminder_todo_id = checkInTodo.id;
          }
        } else {
          await this._closeObjective(objective);
        }
        resolve(objective);
      } catch (er) {
        Track.reportError(er, "FireClient:SaveObjective", false);
        reject(er);
      }
    });
  }
  /*updateContent(id: any, content: String): Promise<void>;*/
  checkIn(id: any, checkin: pApi.ObjectiveCheckIn): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      try {
        var objective = await this.load(id);
        checkin.objective_id = id;

        //TODO - Add Batch
        var db = this.getDb();
        var batch = db.batch();
        checkin.check_in_date = new Date();

        let update = populateToData<pApi.ObjectiveCheckIn>(this.ctx, checkin); // pApi.toPureJavascript(checkin);
        update["owner_uid"] = this.ctx.ProfileProvider.getProfile().uuid;
        var docRef = await db.collection(this.CHECKINDB).doc();
        batch.set(docRef, update);

        var objRef = db.collection(this.DATABASE).doc(id);
        objective.close_date = checkin.close ? new Date() : null;

        await batch.update(objRef, {
          last_checkin: update,
          modified: new Date(),
          close_date: objective.close_date
        });

        await batch.commit();
        if (objective.close_date) {
          await this._closeObjective(objective);
        } else {
          var todo = await this.ctx.Todos.load(objective.reminder_todo_id);
          if (todo.due_date < new Date()) {
            await this.ctx.Todos.complete(objective.reminder_todo_id);
          }
        }
        resolve();
      } catch (e) {
        reject(e);
      }
    });
  }

  getCheckInHistory(
    id: any,
    since: Date
  ): Promise<Array<pApi.ObjectiveCheckIn>> {
    return new Promise<Array<pApi.ObjectiveCheckIn>>(
      async (resolve, reject) => {
        try {
          let retval = new Array<pApi.ObjectiveCheckIn>();
          var results = await this.getDb()
            .collection(this.CHECKINDB)
            .where(
              "owner_uid",
              "==",
              this.ctx.ProfileProvider.getProfile().uuid
            )
            .where("objective_id", "==", id)
            .get();
          for (var i in results.docs) {
            var res = results.docs[i];

            var data = await res.data();
            for (var ii in data) {
              retval.push(dataTOCheckInConverter.dataToCheckin(data));
            }
          }
          resolve(retval);
        } catch (e) {
          Track.reportError(e, "FireClient:CheckInHistory", false);
          reject(e);
        }
      }
    );
  }
  private sort(n1: pApi.Objective, n2: pApi.Objective) {
    if (n1.content.toLocaleLowerCase() > n2.content.toLocaleLowerCase())
      return 1;
    else return -1;
  }
  list(
    includeClosed: boolean,
    updateCallback?: pApi.UpdateCallback<pApi.Objective>
  ): Promise<Array<pApi.Objective>> {
    return new Promise<Array<pApi.Objective>>(async (resolve, reject) => {
      var query = await this.getDb()
        .collection(this.DATABASE)
        .where("owner_uid", "==", this.ctx.ProfileProvider.getProfile().uuid);
      if (!includeClosed) {
        query = query.where("closed", "==", false);
      }
      var map = new Map<string, any>();
      map.set("list", query);
      this._queryManager.execute(
        this.ctx,
        map,
        this.sort,
        this.populateFromData.bind(this),
        resolve,
        reject,
        updateCallback
      );
    });
  }
  unregisterCallback(cb: pApi.UpdateCallback<pApi.Objective>): Promise<void> {
    return this._queryManager.listeners.get(cb.key).stopListening();
  }
}
export class FireSettingStore extends BaseFireStore<pApi.DojoSettings>
  implements pApi.ISettingStore {
  private DATABASE: string = "Settings";
  lastSnapShot: any;
  populateFromData(ctx:IFireCtx,data: any, id: any): pApi.DojoSettings {
    var settings = populateBaseFromData<pApi.DojoSettings>(
      id,
      data,
      pApi.DojoSettings,
ctx
    );
    settings.onboarding = data.onboarding;
    settings.minimalUIMode = data.minimalUIMode;
    settings.newMobileMode = data.newMobileMode
    settings.embedNoteContent = data.embedNoteContent;
    return settings;
  }
  async listenForChanges(
    query: any,
    listener: pApi.UpdateCallback<pApi.DojoSettings>
  ) {
    if (this.lastSnapShot) {
      console.log("unsubscribe");
      await this.lastSnapShot();
    }
    var me = this;

    this.lastSnapShot = this.ctx.getSnapShot(query, function (querySnapshot) {
      var docs = new Array<pApi.DojoSettings>();
      var ex = 0;

      querySnapshot.forEach(function (doc) {
        docs.push(me.populateFromData(me.ctx,doc.data(), doc.id));
      });

      if (docs.length > 0) {
        listener.callback(docs);
      } else {
        docs.push(new pApi.DojoSettings());
        listener.callback(docs);
      }
    });
  }
  get(
    listener?: pApi.UpdateCallback<pApi.DojoSettings>
  ): Promise<pApi.DojoSettings> {
    return new Promise<pApi.DojoSettings>(async (resolve, reject) => {
      try {
        var query = this.getDb().collection(this.DATABASE); //.where('uid','==',this.ctx.ProfileProvider.getProfile().uuid)
        query = query.where("owner_uid", "==", this.ctx.getId());
        var retVal = new Array<pApi.DojoSettings>();
        if (listener) {
          this.listenForChanges(query, listener);
          return resolve(null);
        }
        var results = await query.get();
        for (var i in results.docs) {
          var res = results.docs[i];

          var data = await res.data();
          retVal.push(this.populateFromData(this.ctx,data, res.id));
        }
;
        resolve(retVal.length > 0 ? retVal[0] : null);
      } catch (e) {
        reject(e);
      }
    });
  }
  set(settings: pApi.DojoSettings): Promise<pApi.DojoSettings> {
    return new Promise<pApi.DojoSettings>(async (resolve, reject) => {
      try {
        // ;
        var oldSettings = await this.get();
        var update = this.populateToData(settings);
        ;
        if (!oldSettings) {
          settings.is_new = false;
          var update = this.populateToData(settings);
          var docRef = await this.getDb()
            .collection(this.DATABASE)
            .add(update);
          settings.id = docRef.id;
          settings.is_new = false;
        } else {
          var update = this.populateToData(settings);
          await this.ctx.fireStoreUpdate(
            this.getDb()
              .collection(this.DATABASE)
              .doc(oldSettings.id)
              .update(update)
          );
        }
        resolve(settings);
      } catch (e) {
        reject(e);
      }
    });
  }
}

export class FireApiKeyStore extends BaseFireStore<pApi.ApiKey>
  implements pApi.IApiKeyStore {
  private DATABASE: string = "apiKeys";
  populateFromData(ctx:IFireCtx, data: any, id: any): pApi.ApiKey {
    var settings = populateBaseFromData<pApi.ApiKey>(id, data, pApi.ApiKey,ctx);
    settings.token = data.token;
    return settings;
  }
  get(): Promise<pApi.ApiKey> {
    return new Promise<pApi.ApiKey>(async (resolve, reject) => {
      try {
        var query = this.getDb().collection(this.DATABASE); //.where('uid','==',this.ctx.ProfileProvider.getProfile().uuid)
        query = query.where("owner_uid", "==", this.ctx.getId());
        var retVal = new Array<pApi.ApiKey>();

        var results = await query.get();
        for (var i in results.docs) {
          var res = results.docs[i];

          var data = await res.data();
          retVal.push(this.populateFromData(this.ctx,data, res.id));
        }

        resolve(retVal.length > 0 ? retVal[0] : null);
      } catch (e) {
        reject(e);
      }
    });
  }
  set(settings: pApi.ApiKey): Promise<pApi.ApiKey> {
    return new Promise<pApi.ApiKey>(async (resolve, reject) => {
      try {
        var oldSettings = await this.get();
        var update = this.populateToData(settings);
        if (!oldSettings) {
          settings.is_new = false;
          var update = this.populateToData(settings);
          var docRef = await this.getDb()
            .collection(this.DATABASE)
            .add(update);
          settings.id = docRef.id;
          settings.is_new = false;
        } else {
          var update = this.populateToData(settings);
          await this.ctx.fireStoreUpdate(
            this.getDb()
              .collection(this.DATABASE)
              .doc(oldSettings.id)
              .update(update)
          );
        }
        resolve(settings);
      } catch (e) {
        reject(e);
      }
    });
  }
}

export class FireWebHookStore extends BaseFireStore<pApi.WebHook>
  implements pApi.IWebHookStore {
  private DATABASE: string = "webHook";
  populateFromData(ctx:IFireCtx, data: any, id: any): pApi.WebHook {
    var webhook = populateBaseFromData<pApi.WebHook>(id, data, pApi.WebHook,ctx);
    webhook.name = data.name;
    webhook.url = data.url;
    webhook.action = data.action;
    webhook.trigger = data.trigger;
    return webhook;
  }
  load(id: any): Promise<pApi.WebHook> {
    return new Promise<pApi.WebHook>(async (resolve, reject) => {
      try {
        var hooks = await this.list();
        var found = hooks.find(x => x.id == id);
        resolve(found);
      } catch (e) {
        reject(e);
      }
    });
  }
  list(): Promise<Array<pApi.WebHook>> {
    return new Promise<Array<pApi.WebHook>>(async (resolve, reject) => {
      try {
        var query = this.getDb().collection(this.DATABASE); //.where('uid','==',this.ctx.ProfileProvider.getProfile().uuid)
        query = query.where("owner_uid", "==", this.ctx.getId());
        var retVal = new Array<pApi.WebHook>();
        var results = await query.get();
        for (var i in results.docs) {
          var res = results.docs[i];
          var data = await res.data();
          retVal.push(this.populateFromData(this.ctx,data, res.id));
        }

        resolve(retVal);
      } catch (e) {
        reject(e);
      }
    });
  }
  archive(id: any): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      try {
        var snapshot = await this.getDb()
          .collection(this.DATABASE)
          .where("owner_uid", "==", this.ctx.getId())
          .where("id", "==", id)
          .get();
        let found = false;
        snapshot.forEach(async doc => {
          await doc.ref.delete();
          found = true;
        });
        if (!found) {
          throw `${id} not found.`;
        }
        resolve();
      } catch (e) {
        reject(e);
      }
    });
  }
  save(hook: pApi.WebHook): Promise<pApi.WebHook> {
    return new Promise<pApi.WebHook>(async (resolve, reject) => {
      try {
        var update = this.populateToData(hook);
        if (!hook.id) {
          hook.is_new = false;

          var docRef = await this.getDb()
            .collection(this.DATABASE)
            .add(update);
          hook.id = docRef.id;
          hook.is_new = false;
        } else {
          await this.ctx.fireStoreUpdate(
            this.getDb()
              .collection(this.DATABASE)
              .doc(hook.id)
              .update(update)
          );
        }
        resolve(hook);
      } catch (e) {
        reject(e);
      }
    });
  }
}
