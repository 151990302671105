import * as React from 'react';

import * as model from "../lib/model";
import * as actions from "../lib/ActionReducer";
import NavigationIcon from "material-ui/svg-icons/navigation/menu";
import Divider from "material-ui/Divider";
import HelpIcon from "material-ui/svg-icons/action/help";
import SettingsIcon from "material-ui/svg-icons/action/settings";
import Archive from "material-ui/svg-icons/content/archive";
import SignOut from  "material-ui/svg-icons/action/lock-open";
import { TagNode, NoteSidebarContent } from "../components/NoteSideNav/NoteSidebarContent"
import { List, ListItem } from "material-ui/List";

let $ = require('jquery')

interface SidebarProps {
    connect: model.ConnectProps
}
interface SidebarState {

}



export class SideBar extends React.Component<SidebarProps, SidebarState> {
    constructor(props, context) {
        super(props, context);
        this.state = {}

    }
    onSelect(url) {

        if (!url) return false;
        const $body = $("#body");
        ;
        //if(this.props.uiConfig.layout.navCollapsed)
        {
            console.log($(window).width());
            this.props.connect.dispatch<any>(actions.handleToggleCollapsedNav(!this.props.connect.state.uiConifg.layout.navCollapsed));
  


            
        }

        window.location.href = url; //e.currentTarget.attributes['href']
        // e.preventDefault();
    }
    onTagChange(node: TagNode) {
        var nav = this.props.connect.state.viewState.noteViewState
            ? this.props.connect.state.viewState.noteViewState
            : new model.NoteViewState();

        nav.functionType = model.NoteFunctionType.tag;
        nav.query = node.getTagPath().replace("/", ">");
        //nav.noteViewType = nav.noteViewType
        nav.noteId = null;
        this.props.connect.dispatch<any>(actions.handleToggleCollapsedNav(false));

        window.location.hash = '#' + nav.getHash();

        // window.location.hash = nav.getHash();
    }
    render() {
        let me = this;
        let sidebarStyle = "simple-sidebar ";
      
        sidebarStyle += !this.props.connect.state.uiConifg.layout.navCollapsed ? 'simple-sidebar-hidden' : 'simple-sidebar-show'
        return (
            <div className={sidebarStyle}>
                <div className='simple-header simple-nav' style={{ width: '100%', 'overflow':'scroll', height:  this.props.connect.state.windowHeight-30 }} >
                    <div className="container-fluid simple-todo-container"  >
                        <div className="row no-gutters float-right"  >
                            <div onClick={(e) => {

                                me.props.connect.dispatch<any>(actions.handleToggleCollapsedNav(!this.props.connect.state.uiConifg.layout.navCollapsed));
                            }}> <NavigationIcon /> </div>
                        </div>
                        <div className="row no-gutters" style={{ paddingTop: '15px' }} >
                            <NoteSidebarContent
                                selectedTag=""
                                fullWidth={true}
                                connect={this.props.connect}
                                onClick={this.onTagChange.bind(this)}
                            />
                          
                            <List       style={{width:'100%'}}>
                            <Divider />
                            <ListItem
                                primaryText="Settings"
                          
                                leftIcon={<SettingsIcon />}
                                onClick={e =>
                                    this.onSelect(
                                        "#" + model.ViewState.getDefaultHash(model.AppSections.settings,this.props.connect.state.viewState)
                                    )
                                }

                            />
                            <ListItem
                                primaryText="Help & Feedback"

                                leftIcon={<HelpIcon />}
                                onClick={e =>
                                    this.onSelect(
                                        "#" + model.ViewState.getDefaultHash(model.AppSections.help,this.props.connect.state.viewState)
                                    )
                                }

                            />
                            <ListItem
                                primaryText="Archived"
                                className='joyride-archive'
                                leftIcon={<Archive />}
                                onClick={e =>
                                    this.onSelect(
                                        "#" + model.ViewState.getDefaultHash(model.AppSections.archived,this.props.connect.state.viewState)
                                    )
                                }
                                key={`#/not_done`}
                            />
                             <ListItem
                                primaryText="Logout"
                              
                                leftIcon={<SignOut />}
                                onClick={e => {
                                    if(window.confirm("Are you sure you want to logout?"))
                                    {
                                        me.props.connect.dispatch<any>(actions.ar_signout())         
                                    }
                                         
                                }}
                                key={`#/logout`}
                            />
                            </List>
                           
                           
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}



