import React from 'react';
import * as model from "../lib/model"
import {SearchResult} from '../lib/search'
import SuperList from "../SuperList/SuperList"
export interface JumpToProps {
    connect: model.ConnectProps
    searchResults: SearchResult
  
}
export  default function SearchGrid(props: JumpToProps) {
   // const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    return (
            <div style={{ width: '100%' }}>
                {!props.connect.state.isOnline && (
                    <div
                        style={{
                            "textAlign": "center",
                            color: "orange"
                        }}
                    >
                        <b> You are offline. Search results for notes are limited.</b>
                    </div>
                )}
               
               
{props.searchResults.orderBy && props.searchResults.orderBy.length > 0 &&
                    <SuperList  
           todos= {props.searchResults.todoMatch}
           todoQueryId= {props.searchResults.searchText}
           notes={props.searchResults.noteMatch}
           tag={null}
           orderBy={props.searchResults.orderBy}
           mode={model.InboxMode.search}
           connect={props.connect}
           sortByCreateDate={false}
           mobileView={props.connect.getPresentationMode() == model.PresentationMode.mobile} />
}
                     
</div>
               
        );
    
}

