import * as model from "../lib/model";
import * as actions from "../lib/ActionReducer";
import * as pApi from "../../shared/papi/papi-core";
let cnode = require(   "chrono-node") 
   
class SnoozeChoices {
  text: string;
  match: string;
  action: pApi.SnoozeAction;
  partial: boolean;
  constructor(text, action: pApi.SnoozeAction, match, partial) {
    this.text = text;
    this.match = match;
    this.action = action;
    this.partial = partial;
  }
}
export function DefaultChoices()
{
    let defaultChoices: Array<SnoozeChoices> = [
        new SnoozeChoices("Tonight", pApi.SnoozeAction.laterToday, "Tonight", false),
        new SnoozeChoices("Tomorrow", pApi.SnoozeAction.tomorrow, "Tomorrow", false),
        new SnoozeChoices(
          "This Weekend",
          pApi.SnoozeAction.weekend,
          "This Weekend",
          false
        ),
        new SnoozeChoices("Next Week", pApi.SnoozeAction.nextWeek, "Next Week", false)
      ];
    return defaultChoices;
}

let daysOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday"
];
var longMonth = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];
var shortMonth = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec"];

export function GenerateChoices(choice: string): Array<SnoozeChoices> {
  // if (window["generateChoices"]) return window["generateChoices"];
  let retval = DefaultChoices().map(x => x);
  let reg = /(\b\d+(?:[\.,]\d+)?\b(?!(?:[\.,]\d+)|(?:\s*(?:%|percent))))/.exec(
    choice
  );
  if (reg && reg.length > 0) {
    let counter = reg[0];

    retval.push(
      new SnoozeChoices(
        counter + "h",
        pApi.SnoozeAction.custom,
        "in " + counter + " hours",
        false
      )
    );
    retval.push(
      new SnoozeChoices(
        counter + " hours",
        pApi.SnoozeAction.custom,
        "in " + counter + " hours",
        false
      )
    );
    retval.push(
      new SnoozeChoices(
        counter + "m",
        pApi.SnoozeAction.custom,
        "in " + counter + " minutes",
        false
      )
    );
    retval.push(
      new SnoozeChoices(
        counter + "min",
        pApi.SnoozeAction.custom,
        "in " + counter + " minutes",
        false
      )
    );
    retval.push(
      new SnoozeChoices(
        counter + "d",
        pApi.SnoozeAction.custom,
        "in " + counter + " days",
        false
      )
    );
    retval.push(
      new SnoozeChoices(
        counter + " day",
        pApi.SnoozeAction.custom,
        "in " + counter + " days",
        false
      )
    );
    retval.push(
      new SnoozeChoices(
        counter + "d",
        pApi.SnoozeAction.custom,
        "in " + counter + " days",
        false
      )
    );
    retval.push(
      new SnoozeChoices(
        counter + " day",
        pApi.SnoozeAction.custom,
        "in " + counter + " days",
        false
      )
    );
    retval.push(
      new SnoozeChoices(
        counter + " month",
        pApi.SnoozeAction.custom,
        "in " + counter + " month",
        false
      )
    );
  }
  reg = /\d[d|h|m]/.exec(choice);
  if (reg && reg.length > 0) {
    let counter = reg[0];
    if (counter.indexOf("h") > -1) {
      retval.push(
        new SnoozeChoices(
          counter,
          pApi.SnoozeAction.custom,
          "in " + counter.replace("h", "") + " hours",
          false
        )
      );
    }
    if (counter.indexOf("m") > -1) {
      retval.push(
        new SnoozeChoices(
          counter,
          pApi.SnoozeAction.custom,
          "in " + counter.replace("m", "") + " mintues",
          false
        )
      );
    }
    if (counter.indexOf("d") > -1) {
      retval.push(
        new SnoozeChoices(
          counter,
          pApi.SnoozeAction.custom,
          "in " + counter.replace("d", "") + " days",
          false
        )
      );
    }
  }
  /*longMonth.forEach(x => {
    retval.push(new SnoozeChoices(x, pApi.SnoozeAction.custom, x, true));
    let dayofMonth = 1;
    while (dayofMonth < 32) {
      retval.push(
        new SnoozeChoices(
          x + " " + dayofMonth,
          pApi.SnoozeAction.custom,
          x + " " + dayofMonth,
          false
        )
      );
      dayofMonth++;
    }
  });
  shortMonth.forEach(x => {
    retval.push(new SnoozeChoices(x, pApi.SnoozeAction.custom, x, true));
    let dayofMonth = 1;
    while (dayofMonth < 32) {
      retval.push(
        new SnoozeChoices(
          x + " " + dayofMonth,
          pApi.SnoozeAction.custom,
          x + " " + dayofMonth,
          false
        )
      );
      dayofMonth++;
    }
  });*/
  daysOfWeek.forEach(x => {
    retval.push(
      new SnoozeChoices(x, pApi.SnoozeAction.custom, "Next " + x, true)
    );
    retval.push(new SnoozeChoices(x, pApi.SnoozeAction.custom, x, true));
  });
  /*
  var counter = 1;
  while (counter < 24) {
    retval.push(
      new SnoozeChoices(
        counter + "h",
        pApi.SnoozeAction.custom,
        "in " + counter + " hours",
        false
      )
    );
    retval.push(
      new SnoozeChoices(
        counter + " hours",
        pApi.SnoozeAction.custom,
        "in " + counter + " hours",
        false
      )
    );
  }
  counter = 1;
  while (counter < 60) {
    retval.push(
      new SnoozeChoices(
        counter + "m",
        pApi.SnoozeAction.custom,
        "in " + counter + " minutes",
        false
      )
    );
    retval.push(
      new SnoozeChoices(
        counter + "min",
        pApi.SnoozeAction.custom,
        "in " + counter + " minutes",
        false
      )
    );
  }
  counter = 1;
  while (counter < 60) {
    retval.push(
      new SnoozeChoices(
        counter + "d",
        pApi.SnoozeAction.custom,
        "in " + counter + " days",
        false
      )
    );
    retval.push(
      new SnoozeChoices(
        counter + " day",
        pApi.SnoozeAction.custom,
        "in " + counter + " days",
        false
      )
    );
  }
  counter = 1;
  while (counter < 12) {
    retval.push(
      new SnoozeChoices(
        counter + " month",
        pApi.SnoozeAction.custom,
        "in " + counter + " month",
        false
      )
    );
    counter++;
  }*/

  retval = retval.sort((a: SnoozeChoices, b: SnoozeChoices) => {
    return a.text < b.text ? 1 : -1;
  });
  window["generateChoices"] = retval;
  return retval;
}

export function ParseDate(
    chosen: any,
    index: number,
    datasource?: Array<SnoozeChoices>,
  ): Date {
    function parseCnode(text: string): Date {
      let results = cnode.parse(text, new Date(), { forwardDate: true });
      if (results.length > 0) {
        results[0].start.imply("hour", 8);
        return results[0].start.date();
      }
      return null;
    }
    if(!datasource)
    {
        datasource = GenerateChoices(chosen);
    }
    let choice: SnoozeChoices = null;
    if (index != -1) {
      choice = datasource[index];
    } else {
      choice = datasource.find(x =>
        x.text.toLowerCase().startsWith(chosen.toLowerCase())
      );
    }
    if (choice != null) {
      if (choice.action == pApi.SnoozeAction.custom) {
        let date = parseCnode(choice.match);

        if (date.getHours() == 0) {
          date.setHours(8);
        }
        if (date) {
          return pApi.getSnoozeDate(pApi.SnoozeAction.custom, date);
        }
      } else {
        return pApi.getSnoozeDate(choice.action, null);
      }
    } else {
      if (chosen) {
        let date = parseCnode(chosen);
        if (date) {
          if (date.getHours() == 0) {
            date.setHours(8);
          }
          return pApi.getSnoozeDate(pApi.SnoozeAction.custom, date);
        }
      }
    }
    return null;
  }