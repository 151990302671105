
import  * as pApi from './papi-core'
export class MemoryCacheProvider implements pApi.ICacheProvider
{
    data:any
    get(key:any):Promise<any>
    {
        return new Promise<any>((resolve,reject)=>{
            resolve(this.data[key]);
        })
    }
    set(key:any, value:any):Promise<void>
    {
        return new Promise<void>((resolve,reject)=>{
            this.data[key]=value;
            resolve();
        })
    }
    constructor()
    {
        //super();
        this.data = {};
    }
}