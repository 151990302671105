import * as React from "react";
import * as classNames from "classnames";
import * as material from "material-ui";
import * as model from "../../lib/model";
import * as actions from "../../lib/ActionReducer";
import * as pApi from "../../../shared/papi/papi-core";
import NotesIcon from "material-ui/svg-icons/content/content-paste";
import { red50 } from "material-ui/styles/colors";

var uuid = require("uuid");
interface RecoccuringPickerProps {
  settings: pApi.ReoccuringSettings;
  connect: model.ConnectProps;
  open: boolean;
  onChange: (reocuuringSettings: pApi.ReoccuringSettings) => void;
  onClose: () => void;
}
interface RecoccuringPickerState {
  settings: pApi.ReoccuringSettings;
}

export class RecoccuringPicker extends React.Component<
  RecoccuringPickerProps,
  RecoccuringPickerState
> {
  constructor(props, context) {
    super(props, context);
    this.state = {
      settings: new pApi.ReoccuringSettings()
    };
  }
  componentWillReceiveProps(nextProps: RecoccuringPickerProps) {
    if (
      nextProps.open && (nextProps.open != this.props.open))
    {
       
        let settings = nextProps.settings;
        if(!settings){
            settings = new pApi.ReoccuringSettings();
            settings.reoccuring_interval =0 ;
            settings.reoccuring_day_of_week = 1;
            if(!settings.time_of_day)
            {
                settings.time_of_day = new Date();
                settings.time_of_day.setHours(8);
                settings.time_of_day.setMinutes(0);
                settings.time_of_day.setSeconds(0);
                settings.time_of_day.setMilliseconds(0);
            }
        }
        this.setState({
            settings:settings
        })
    }
    return true;
  }
  CheckInIntervalChange(event, index, value) {
    var newState = { ...this.state };
    var settings = { ...this.state.settings };

    settings.reoccuring_interval = value; //this.refs.checkinInterval['props']['value']

    this.setState({
      settings: settings
    });
  }
  CheckInIntervalDayOfWeekChange(event, index, value) {
    var newState = { ...this.state };
    var settings = { ...this.state.settings };
    settings.reoccuring_day_of_week = value; //this.refs.checkinDayOfWeek['props']['value']
    this.setState({
      settings: settings
    });
  }
  CheckInIntervalDayOfMonthChange(event, index, value) {
    var newState = { ...this.state };
    var settings = { ...this.state.settings };
    settings.reoccuring_day_of_month = value; //this.refs.checkinDayOfMonth['props']['value']
    //newState.objective = objective;
    this.setState({
      settings: settings
    });
  }
  handleSelect(){
      this.props.onChange(this.state.settings);
  }
  handleRemove(){
   
    this.props.onChange(null);
  
}
  handleClose()
  {
      this.props.onClose();
  }
  timeChange(event, value) {
    console.log(JSON.stringify(arguments));
    var state = { ...this.state };
    state.settings.time_of_day = value;
    this.setState(state);
  }

  render() {
    var me = this;
    
    const customContentStyle = {
     
    };
    if(!this.state.settings)
    {
        return (<div></div>)
    }
    const actionsBtn = [
        
        <material.FlatButton
          label="Remove Interval"
          style={{
              color:'red'
          }}
          onClick={this.handleRemove.bind(this)}
        />,
        <material.FlatButton
          label="Cancel"
          
          onClick={this.handleClose.bind(this)}
        />,
        <material.FlatButton
          label="Set Interval"
          
          onClick={this.handleSelect.bind(this)}
        />
      ];
    return (
      <div>
        <material.Dialog
        title="Reoccurring Options"
          contentStyle={customContentStyle}
          onRequestClose={() => {
            me.props.onClose();
          }}
          overlayStyle={actions.getOverlayStyle()}
          actions={actionsBtn}

          open={this.props.open}
        >
          <div className="container-fluid" style={{ padding: "0px" }}>
            <div className="row">
              <div style={{'paddingTop':'15px'}} className={(!this.state.settings.reoccuring_interval||this.state.settings.reoccuring_interval == 1 )?"col-md-6 col-12":"col-md-4 col-12"} >
                <material.DropDownMenu
                  ref="checkinInterval"
                  value={this.state.settings.reoccuring_interval?this.state.settings.reoccuring_interval:1}
                  onChange={this.CheckInIntervalChange.bind(this)}
                >
                  <material.MenuItem value={1} primaryText="Check-In Daily" />
                  <material.MenuItem value={4} primaryText="Check-In Weekday" />
                  <material.MenuItem value={2} primaryText="Check-In Weekly" />
                  <material.MenuItem value={3} primaryText="Check-In Monthly" />
                </material.DropDownMenu>
              </div>
             
              {this.state.settings.reoccuring_interval == 2 && (
                <div className="col-md-4 col-12" style={{'paddingTop':'15px'}} >
                  <material.DropDownMenu
                    ref="checkinDayOfWeek"
                    onChange={this.CheckInIntervalDayOfWeekChange.bind(this)}
                    value={this.state.settings.reoccuring_day_of_week?this.state.settings.reoccuring_day_of_week:0}
                
                  >
                    <material.MenuItem value={0} primaryText="on Sunday" />
                    <material.MenuItem value={1} primaryText="on Monday" />
                    <material.MenuItem value={2} primaryText="on Tuesday" />
                    <material.MenuItem value={3} primaryText="on Wednesday" />
                    <material.MenuItem value={4} primaryText="on Thursday" />
                    <material.MenuItem value={5} primaryText="on Friday" />
                    <material.MenuItem value={6} primaryText="on Saturday" />
                  </material.DropDownMenu>
                </div>
              )}
              {this.state.settings.reoccuring_interval == 3 && (
                <div style={{'paddingTop':'15px'}}  className="col-md-4 col-12" >
                  <material.DropDownMenu
                    ref="checkinDayOfMonth"
                    onChange={this.CheckInIntervalDayOfMonthChange.bind(this)}
                    value={
                      this.state.settings.reoccuring_day_of_month
                        ? 1
                        : this.state.settings.reoccuring_day_of_month
                    }
                  >
                    <material.MenuItem value={1} primaryText="1st day" />
                    <material.MenuItem value={15} primaryText="15th day" />
                  </material.DropDownMenu>
                </div>
              )}
              <div className="col-md-4 col-12" >
                    <material.TimePicker 
                        value={this.state.settings.time_of_day}
                        autoOk={true}
                        textFieldStyle={{
                            width: "80%"
                          }}
                        floatingLabelText="Time of Day"
                        hintText="Time of Day"
                        onChange={this.timeChange.bind(this)}
                    />
              </div>
            </div>
          </div>
        </material.Dialog>
      </div>
    );
  }
}
