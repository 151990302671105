
import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';


export class ErrorObj {
  userMessage: string;
  exception: any;
  showModal: boolean;
  timestamp: Date;
}
//testasdf

export class UserContext {
  id: any;
  username: any;
  email: any;
}
export enum TrackCategory {
  objectAction = "objectAction",
  note = "note",
  onboarding = "onboarding"
}
export enum TrackEvent {
  createTodo = "createTodo",
  createNote = "createNote",
  addImage = "addImage",
  deleteNote = "deleteNote",
  updateNote = "updateNote",
  search = "search",
  completeTodo = "completeTodo",
  snoozeTodo = "snoozeTodo",
  addDevice = "addDevice",
  updateDevice = "updateDevice"
}
let ReactGA = {
  pageview(e) {
    console.info(e);
  },
  modalview(e) {
    console.info(e);
  },
  set(e) {
    console.info(e);
  },
  exception(e) {
    console.error(e);
  },
  event(e) {
    console.info(e);
  }
};
/*
let Raven = {
  setUserContext: function (e) { },
  setExtraContext: function (e) { },
  captureException: function (e) { }
};*/

let GitInfo = require("../shared/gitinfo").default;
if (typeof window !== "undefined") {
   // Raven = require("raven-js");
  /* if (window["ios"] && window["FirebasePlugin"]) {
    ReactGA = {
      pageview(e) {
        window["FirebasePlugin"].setScreenName(e);
      },
      modalview(e) {
        window["FirebasePlugin"].setScreenName("modal:" + e);
      },
      set(e) {
        for (var i in e) {
          if (i == "firebaseUid") {
            window["FirebasePlugin"].setUserId(e[i]);
          } else {
            window["FirebasePlugin"].setUserProperty(i, e[i]);
          }
        }
        console.info(e);
      },
      exception(e) {
        window["FirebasePlugin"].logEvent("exception", e);
      },
      event(e) {
        window["FirebasePlugin"].logEvent(e.category, { action: e.type });
        console.info(e);
      }
    };
  } else {*/
  var useSentry =  window.location.hostname == "localhost" 
  if (useSentry) {

    Sentry.init({
      dsn: 'https://d14d4fe32cef4394b38f6595008b56b0@sentry.io/1236122',
      release: 'notedojo-web@' +  GitInfo.VERSION,
      integrations: [
        new Integrations.BrowserTracing(),
      ],
    });
    /*
    //@ts-ignore
    Raven.config("https://d14d4fe32cef4394b38f6595008b56b0@sentry.io/1236122", {
      release: GitInfo.VERSION
    }).install();
    */
  }

  ReactGA = require("react-ga");
  ReactGA["ga"]("require", "linker");
  //@ts-ignore
  ReactGA.initialize("UA-92222572-3", "auto", {
    allowLinker: true
  });
  ReactGA.exception = err => {
    if(useSentry)
    {
      Sentry.captureException(err);
    }
    //Raven.captureException(err);
    ReactGA["ga"]("send", "exception", {
      exDescription: err.userMessage ? err.userMessage : err.exception.message,
      exRawMessage: err.exception.message,
      exFatal: false
    }).bind(ReactGA);
  };
  // }
}
export class Track {
  static onError: any;
  static Init(onError: any) {
    Track.onError = onError;

    /*  console["log_old"] = console.log;
    console["info_old"] = console.info;
    console["warn_old"] = console.warn;
    console["error_old"] = console.error;
    console["debug_old"] = console.debug;*
    console.log = Track.log;
    console.error = Track.error;
    console.warn = Track.warn;
    console.debug = Track.debug;
    console.info = Track.info;*/
  }
  static PageView(page) {
    ReactGA.pageview(page);
  }
  static ModelView(modalName) {
    ReactGA.modalview(modalName);
  }
  static SetUserContext(user: UserContext) {
    /*Track.SetDimension({
      email: user.username,
      userId: user.username,
      firebaseUid: user.id
    });*/
    ReactGA.set({
      email: user.username,
      userId: user.username,
      firebaseUid: user.id
    });
    if(useSentry)
    {
      Sentry.setUser(user);
    }
    //Raven.setUserContext(user);
  }
  static SetDimension(obj) {
    
   
    ReactGA.set(obj);

    if(useSentry)
    {
      Sentry.setContext("Dimension", obj);
    }
   
  }
  static Event(category: TrackCategory, type: TrackEvent) {
    ReactGA.event({
      category: TrackCategory[category],
      action: TrackEvent[type]
    });
  }
  static EventString(category: TrackCategory, text: string) {
    ReactGA.event({
      category: TrackCategory[category],
      action: text
    });
  }

  static reportError(
    exception: any,
    userMessage: string,
    showModal: boolean,
    dispatch?: any
  ) {
    Track.error(exception);
    try {
      let err = new ErrorObj();
      err.exception = exception;
      err.showModal = showModal;
      err.userMessage = userMessage;
      //@ts-ignore
      ReactGA.exception(err);
      console.error(err);
      if (dispatch) {
        err.timestamp = err.timestamp ? err.timestamp : new Date();
        if (Track.onError) dispatch(Track.onError(err));
      }
    } catch (e) { }
  }
  private static consoleLog(type, value) {
    try {
      return console[`${type}_old`]
        ? console[`${type}_old`](value)
        : console[type](value);
    } catch (e) {
      console.error(e);
    }
  }
  static debug(value) {
    return Track.consoleLog("debug", value);
  }
  static log(value) {
    return Track.consoleLog("log", value);
  }
  static warn(value) {
    return Track.consoleLog("warn", value);
  }
  static error(value) {
    return Track.consoleLog("error", value);
  }
  static info(value) {
    return Track.consoleLog("info", value);
  }
}
