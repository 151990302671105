import * as React from "react";
import * as material from "material-ui";
import * as model from "../lib/model";
import * as pApi from "../../shared/papi/papi-core";
import RepeatIcon from "material-ui/svg-icons/av/repeat";
import AlarmOn from "material-ui/svg-icons/action/alarm-on";
import StarOn from "material-ui/svg-icons/toggle/star";
import StarOff from "material-ui/svg-icons/toggle/star-border";
import SwipeableViews from "react-swipeable-views";
import PickDate from "material-ui/svg-icons/device/access-time";
import { MobileTodoItemPage } from "./MobileTodoItemPage"
import { SnoozeTyper } from "../components/SnoozeTyper";
import CheckIcon from "material-ui/svg-icons/navigation/check";
import CheckAllIcon from "material-ui/svg-icons/action/done-all";
import LinkIcon from "material-ui/svg-icons/content/link";
import NoteIcon from "material-ui/svg-icons/content/content-paste";
import Snooze from "material-ui/svg-icons/av/snooze";
import * as sl from "../../shared/lib/superListTypes";

import * as actions from "../lib/ActionReducer";
import { SimpleIconButton } from '../components/SimpleIconButton'
import moment from 'moment'


export class TodoTagSelectorProps {

  selected: boolean;
  connect: model.ConnectProps;
  tagDisplay: string;
  group: any;
  stared: boolean;
  due: boolean;



}
export class TodoTagSelectorState { }
export class TodoTagSelector extends React.Component<
  TodoTagSelectorProps,
  TodoTagSelectorState
> {
  constructor(props, context) {
    super(props, context);
    this.state = {
      hovering: false
    };
  }

  render() {
    let me = this;



    return (


      <div className='superlist-todo-tagname-container'>
        <div className='superlist-todo-tagname-icon-container'>
          {this.props.stared && (
            <div className='simple-nav-icon-position'>
              <StarOn
                color="orangered"
                style={{
                  width: '12px',
                  height: '12px'
                }}
              />
            </div>
          )}
          {!this.props.stared && this.props.due && (
            <span className='simple-nav-icon-position'>
              <AlarmOn
                color="red"
                style={{
                  width: '12px',
                  height: '12px'
                }}

              />
            </span>
          )}
          {!this.props.stared && !this.props.due && (
            <span style={{ paddingLeft: '12px' }}>



            </span>
          )}
        </div>
        {this.props.tagDisplay && (
          <div className='superlist-todo-tag-name'>
            #{this.props.tagDisplay.split("/").join("/")}
          </div>
        )}
        {!this.props.tagDisplay && (
          <div className='superlist-todo-tag-name'>Inbox</div>
        )}
      </div>


    );
  }
}



interface ListRowProps {
  focused: boolean;
  selected: boolean;
  row: sl.SelectableRow;
  todos: Array<pApi.ToDoItem>;
  queryId: any;
  classes?: any;
  multiSelect: boolean;
  onClick: () => void;
  onSnooze: () => void;
  onComplete: () => void;
  onSelect: () => void;
  connect: model.ConnectProps;
}
interface ListRowState {
  CompletedOrDelayed: boolean;
  swiping: boolean;
  hovering: boolean;
  snoozeMenu: boolean;
  anchorEl: any;
  isFocused: boolean;
}
class MobileItem extends React.Component<
  ListRowProps,
  ListRowState
> {
  constructor(props, context) {
    super(props, context);
    this.state = {
      CompletedOrDelayed: false,
      swiping: false,
      hovering: false,
      snoozeMenu: false,
      anchorEl: null,
      isFocused: false
    };
  }

  shouldComponentUpdate(nextProps, nextState) {

    if (nextProps.focused === this.props.focused &&
      nextProps.selected === this.props.selected &&
      nextProps.queryId === this.props.queryId &&
      nextProps.multiSelect == this.props.multiSelect

    ) {
      /// console.log(' skip render')
      return false;
    }
    else {
      console.log(' render ')
      return true;
    }
  }
  firstTodo(): pApi.ToDoItem {

    return this.props.todos[0];
  }
  hasMultiple(): boolean {
    return this.props.todos.length > 1;
  }
  /*shouldComponentUpdate(nextProps:TodoItemPageProps, nextState:TodoItemPageState):boolean
  {
      if(!model.deepCompare(this.state, nextState ))
        return true;
        
      if(!model.deepCompare(this.props, nextProps ))
          return true;
      if(!model.deepCompare(this.props.connect.state.inboxData,nextProps.connect.state.inboxData))
          return true;
      console.log('skip update')
      return true;
  }*/
  async unWatchToggle(e: any) {
    e.preventDefault();
    e.stopPropagation();
    if (
      window.confirm(
        `Are you sure you want to hide ${this.props.todos.length
        } from your inbox?`
      )
    ) {
      //;
      for (var i in this.props.todos) {
        this.props.todos[i].hide_from_inbox = true;
      }
      await actions.shared_bulkUpdate(this.props.connect, this.props.todos);
    }
  }
  async setSnoozeDate(snoozeAction: pApi.SnoozeAction, date?: Date) {
    actions.shared_snoozeItems(
      false,
      this.props.connect,
      this.props.todos,
      snoozeAction,
      date
    );


  }


  getLink(s: string): string {
    if (!this.hasMultiple()) {
      return model.TodoLinkHelper.getLink(this.firstTodo());
    } else return null;
  }
  getNoteLink(): string {
    var noteId = this.firstTodo().note_id;
    if (noteId && this.hasMultiple()) {
      for (var i in this.props.todos) {
        if (this.props.todos[i].note_id != noteId) return;
      }
    }
    if (noteId) {
      return model.TodoLinkHelper.getNoteLink(
        this.firstTodo().note_id,
        this.props.connect.state.viewState
      );
    } else return null;
  }
  async docLinkClick(e) {

    e.preventDefault();
    e.stopPropagation();
    if (this.getNoteLink()) {
      window.location.hash = this.getNoteLink();

    }

  }
  async linkClick(e) {
    var link = this.getLink(this.firstTodo().description);
    // @ts-ignore
    // navigator.app.loadUrl(link, {openExternal : true});
    //var win = window.open(link, "_system");
    window["openLink"](link);//
    // @ts-ignore
    // win.addEventListener('loadstart', ()=>{win.show()});;

    //win.focus();
    e.preventDefault();
    e.stopPropagation();
  }



  async onDoubleClick(e) {
    console.log("onDoubleClick");
    e.preventDefault();
    e.stopPropagation();
    this.props.onClick();
    /* if (this.props.connect.state.inboxData.hasSelections()) {
       if (this.props.selected)
         this.props.connect.dispatch(actions.ar_clearSelect(this.props.row.group));
       else this.props.connect.dispatch(actions.ar_setSelect(this.props.row.group));
       return;
     }
     if (this.props.todos.length > 1) {
         alert('onselect')
         this.props.onSelect();
       
       return true;
     }*/
    // this.props.connect.dispatch<any>(actions.showTodoModal(this.firstTodo()));

  }
  lastSwitch: number = 1.0;
  onSwitch(index, type) {
    var me = this;
    console.log('onSwitch ' + type + ' ' + index)
    if (type == 'move') {

      if ((index > 1 && this.lastSwitch <= 1.0 || index < 1.0 && this.lastSwitch >= 1)) {

        actions.vibrate();
      }
      me.lastSwitch = index
    }
    if (type == "end" && index != 1) {
      me.lastSwitch = 1.0;
      if (index == 0) {
        this.props.onComplete();

        // this.checkClick(null);
        // this.doComplete(item);
        // me.props.completeTodo(item, true, this.props.dispatch)
        //    me.refs.swip['setState']({ indexCurrent: 1 })
      } else {
        this.props.onSnooze()
        /* me.setState({
           hovering: this.state.hovering,
           swiping: this.state.swiping,
           CompletedOrDelayed: true,
           snoozeMenu: false,
           anchorEl: this.state.swiping
         });*/
      }

      //this.props.completeTodo(item)
      // this.setState({ swiping: true, editing: false });
    }
    //
  }
  onSwitchDone(index, type) {
    var me = this;
    if (index == 0) {
      var item = { ...this.firstTodo() }; //.find(todo => todo.id == e.key);
      console.log(index + " " + type);
      this.props.onComplete()

      // this.doComplete(item);
      // me.props.completeTodo(item, true, this.props.dispatch)
      //    me.refs.swip['setState']({ indexCurrent: 1 })
    } else {
      if (index == 2) {
        this.props.onSnooze()
      }
      else {
        me.setState({
          CompletedOrDelayed: index == 2,
          swiping: false
        });
        console.log("done " + index);
      }
    }
  }
  render() {
    var me = this;
    var color = this.firstTodo().priority == 1 ? "red" : "";
    if (this.props.todos.length > 1) {
      color = "blue";
    }
    var labelStyle = {};
    var iconStyle = {};
    //if (this.firstTodo().priority == 1) {
    labelStyle["color"] = color; // = {{color: 'red'}};
    iconStyle["fill"] = color;
    //  }


    const customContentStyle = {
      width: "100%",
      margin: "0px",
      "padding-left": this.props.focused ? "1px" : '5px',
      "padding-right": "5px"
    };


    // alert( this.firstTodo().tags.length)

    var me = this;



    let selected = this.props.selected ? " todo-selected" : "";
    selected += this.props.focused ? " todo-focused" : "";
    /*if (this.props.focused != this.state.isFocused) {
      _.delay(() => {
        let state = { ...me.state };
        state.isFocused = me.props.focused;
        if (state.isFocused) {
          //@ts-ignore
          $(".todo-focused")[0].scrollIntoViewIfNeeded();
        }
        this.setState(state);
      });
    }*/


    return (
      <div
        key={"sswip1dd3" + this.props.row.id}
        ref="mainDiv"
        className={"no-hover joyride-inbox-todo" + selected}
        style={customContentStyle}
      >
        <SwipeableViews
          ref="swip"
          key={"swip14" + this.props.row.id}
          index={1}
          onChange={this.onSwitchDone.bind(this)}
          onSwitching={this.onSwitch.bind(this)}
          style={{}}
          slideStyle={{}}
          threshold={5}
          hysteresis={0.6}
          resistance={false}
        >
          <div
            className="color dark "
            style={{
              backgroundColor: "#66BB6A",
              color: "#66BB6A",
              height: "100%",
              margin: "0px",
              padding: "0px"
            }}
          >
            <material.ListItem
              key={"swipx999-li" + this.props.row.id}
              innerDivStyle={{ wordWrap: "break-word" }}
              rightIcon={this.hasMultiple() ? <CheckAllIcon /> : <CheckIcon />}
            />
          </div>

          <MobileTodoItemPage
            connect={this.props.connect}
            todos={this.props.todos}
            bundleName={this.props.row.id}
            enableHideFromInbox={false}
            selected={this.props.selected}
            focused={this.props.focused}
            group={this.props.row.id}
            onClick={() => {
              me.props.onClick();
            }}
            onSelect={() => {
              me.props.onSelect();

              //window.alert('not implemented')
            }}
            onComplete={() => {
              me.props.onComplete();
              //me.checkClick(null)
            }}
          />



          <div
            className="color dark "
            style={{
              backgroundColor: "#FFA726",
              color: "#FFA726",
              height: "100%",
              margin: "0px",
              padding: "0px"
            }}
          >
            <material.ListItem
              style={{}}
              key={"swipx999d" + this.props.row.id}
              innerDivStyle={{ wordWrap: "break-word" }}
              leftIcon={<PickDate />}
            />
          </div>
        </SwipeableViews>
        {(this.state.CompletedOrDelayed) && (
          <SnoozeTyper
            connect={this.props.connect}
            defaultDate={this.firstTodo().due_date ? this.firstTodo().due_date : null}
            snoozed={this.setSnoozeDate.bind(this)}
            onClose={() => {
              me.props.onSnooze();


            }}
          />

        )}
      </div>
    );
  }

  



}

//@ts-ignore
export default MobileItem
