import * as React from 'react';
import pure from 'recompose/pure';
import {SvgIcon} from 'material-ui';


let Target   = (props) => (
  <SvgIcon {...props}  version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
  viewBox="0 0 489.25 489.25"  >

    <path d="M240.25,216.55c-4-1.1-8.1-1.7-12.4-1.7c-25.7,0-46.6,20.9-46.6,46.6c0,25.7,20.9,46.6,46.6,46.6s46.6-20.9,46.6-46.6c0-4.3-0.6-8.5-1.7-12.4l129.8-129.8l21,2.8l65.7-65.7l-49.7-6.6l-6.6-49.7l-65.7,65.7l2.8,21L240.25,216.55z"/>
		<path d="M232.85,171.95l38.4-38.4c-47.1-16-101.4-5.2-138.9,32.4c-52.7,52.7-52.7,138.3,0,191s138.3,52.7,191,0c37.6-37.6,48.4-91.8,32.4-139l-38.4,38.4c1.4,24.6-7.3,49.7-26.1,68.5c-35,35-91.8,35-126.8,0s-35-91.8,0-126.8C183.25,179.25,208.25,170.55,232.85,171.95z"/>
		<path d="M388.85,184.95c31.5,66.2,19.8,147.7-35,202.5c-69.6,69.6-182.4,69.6-252,0s-69.6-182.4,0-252c54.8-54.8,136.3-66.4,202.5-35l36.7-36.7c-87-49.9-200-37.7-274.3,36.6c-89,89-89,233.2,0,322.1c89,89,233.2,89,322.1,0c74.3-74.3,86.5-187.3,36.6-274.3L388.85,184.95z"/>
  </SvgIcon>
);
Target = pure(Target);
/*Target.displayName = 'ActionAccessibility';
Target.muiName = 'SvgIcon';*/

export default Target;