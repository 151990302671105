import * as React from "react";

import { InboxMode } from "./lib/model"
import { Header } from "./Header/Header"

import * as model from './lib/model';
import * as actions from './lib/ActionReducer'
import { Progress } from "./components/Progress/Progress";
import { ErrorBox } from "./components/ErrorBox/ErrorBox";
import  Search from './Search/Search'
import {SearchBar} from './Search/SearchBar';
import {RightBar} from './RightBar/RightBar'

import ImageViewer from './components/ImageViewer'
import * as material from "material-ui";
import { TodoEdit } from "./components/TodoEdit/TodoEdit";
import { Settings } from "./components/Settings/Settings";
import { Help } from "./components/Help/Help";
import { QuillContainer } from  "./components/Editor/QuillContainer"
import {ProseEditor}from "./components/ProseEditor/ProseEditor"
import * as hotkey from "./lib/hotkeyClient";
import { TagSearch } from "./components/Command";
import {SideBar} from './SideBar/SideBar'
import SuperList from './SuperList/SuperList'
import Archive from './components/Archive/Archive'
import * as pApi from '../shared/papi/papi-core'

let $ = require('jquery')
interface SimpleShellProps {
    connect: model.ConnectProps;
 
}
interface SimpleShellState {
    tagsearchPopup: boolean;
}
export class SimpleShell extends React.Component<SimpleShellProps, SimpleShellState> {
    constructor(props, context) {
        super(props, context);
        this.state = {
            tagsearchPopup: false
        }
    }

    componentDidMount() {
        let me = this;
    

 
         // darkmode.toggle();

        
        $(window).resize("resize", () => {
            me.props.connect.dispatch<any>(actions.ar_windowResize());
        });
        let instance = hotkey.HotKeyEngine.instance();
        //let handlers = new Map<hotkey.HotKeyActionType, () => boolean>();
        let HotKeyDescriptions = new Array<hotkey.HotKeyActionDescription>();
        HotKeyDescriptions.push(new hotkey.HotKeyActionDescription(
            hotkey.HotKeyActionType.undo,
            (): boolean => {
                if (
                    me.props.connect.state.snackMessage &&
                    me.props.connect.state.snackMessage.actionText == "undo"
                ) {
                    me.props.connect.dispatch<any>(actions.ar_snackBarAction(null));
                    return true;
                }
                return false;
            }, true,
            "Undo",
            "Action",null,
            ()=>{
               return ( me.props.connect.state.snackMessage &&
                me.props.connect.state.snackMessage.actionText == "undo")
            })
            
        )

        HotKeyDescriptions.push(new hotkey.HotKeyActionDescription(
            hotkey.HotKeyActionType.jumpTag,
            (): boolean => {
                let s = { ...me.state };
                s.tagsearchPopup = true;
                me.setState(s);
                return true;
            }, true,
            "Command Palette ",
            "Action",null,
            ()=>{
               return ( me.props.connect.state.snackMessage &&
                me.props.connect.state.snackMessage.actionText == "undo")
            })
            
        )
        
        this.props.connect.dispatch<any>(actions.ar_loadInbox())
        this.props.connect.dispatch<any>(actions.ar_loadNotes())
        this.props.connect.dispatch<any>(actions.ar_loadTags());
        instance.registerHandlersWithActions("Shell", "inbox", false, HotKeyDescriptions);
    }
    componentWillUnmount() {
        let instance = hotkey.HotKeyEngine.instance();
        instance.unregisterHandlers("Shell");
    }
    render() {
        let me = this;
        let connect = this.props.connect;
        let viewState = this.props.connect.state.viewState;
        let tag = viewState.getTag();
        ;
        let t = this.props.connect.state.TodosState == model.LoadingState.Completed && this.props.connect.state.notes.completed() && (this.props.connect.state.editTodo.completed() || viewState.todoId);
       
                      
        function getInboxSetting() {
            switch (connect.state.viewState.appSection) {
                case model.AppSections.inbox:
                    return InboxMode.inbox;
                case model.AppSections.today:
                    return InboxMode.today;
                case model.AppSections.thisweek:
                    return InboxMode.thisWeek;
                 case model.AppSections.bydate:
                        return InboxMode.bydate;
                case model.AppSections.snoozed:
                    return InboxMode.onlysnoozed;
                case model.AppSections.archived:
                    return InboxMode.archive;
                case model.AppSections.notes:
                    if(tag)
                        return InboxMode.bytag;
                    else
                        return InboxMode.allNotes;
            }
            return InboxMode.inbox;
        }
        return (
            <div className="container-fluid simple-main" >

                <div className="row no-gutters" style={{
                    height: '100%'
                }} >
                    <div className='col-lg-9 col-12 no-gutters simple-body' >
                        {(viewState.appSection as model.AppSections) !=
                                model.AppSections.search &&
                                <Header connect={this.props.connect} />

                        }
                         {(viewState.appSection as model.AppSections) ==
                                model.AppSections.search &&
                                <SearchBar connect={this.props.connect} />

                        }
                        < div className='row no-gutters simple-boby-container'  >
                            
                             {
                                viewState.inAggregateToDoView() && true && (
                                    <div style={{ width: '100%' }}>
                                         <SuperList  
                todos= {this.props.connect.state.todos}
                todoQueryId= {this.props.connect.state.todoQueryId}
                notes={this.props.connect.state.notes}
                tag={tag}
                mode={getInboxSetting()}
                connect={this.props.connect}
                sortByCreateDate={this.props.connect.state.viewState.sortByCreateDate}
                mobileView={false} />
                                    </div>
                                )
                            }
                          
                           
                           
                            {viewState.appSection == model.AppSections.search && (
                                     <Search connect={connect}   searchText={connect.state.searchText} />
                            )}
                            {viewState.appSection == model.AppSections.help && (
                                <Help connect={connect} />
                            )}
                            {viewState.appSection == model.AppSections.archived && (

                                <Archive connect={connect} />
                            )}



                        </div>


                    </div>
                    <div  className='col-3 hidden-md-down no-gutters simple-infobar' >
                        <RightBar connect={connect} />
                 </div>
                </div>
               
                <SideBar connect={this.props.connect} />
                <div>
                    {viewState.appSection == model.AppSections.settings && (
                        <Settings connect={connect} />
                    )}
                    {viewState.appSection == model.AppSections.help && (
                        <Help connect={connect} />
                    )}

                    {(this.props.connect.state.TodosState == model.LoadingState.Completed && this.props.connect.state.notes.completed() && (this.props.connect.state.editTodo.completed() || viewState.todoId)) && (
                        <TodoEdit
                            todo={connect.state.editTodo}

                            connect={connect}
                        />
                    )}
                    {false &&
                <ProseEditor
                         documentId={
                            viewState.noteViewState
                                ? viewState.noteViewState.noteId
                                : null
                        }
                        connect={connect}
                        />
    }
                {true &&
                    <QuillContainer
                        key="thequileditor"
                        noteChange={() => { }}
                        documentId={
                            viewState.noteViewState
                                ? viewState.noteViewState.noteId
                                : null
                        }
                        connect={connect}
                    />

                    }


                    <material.Dialog
                        title="Offline"
                        ref="dialog"
                        modal
                        contentStyle={{
                            width: "50%",
                            maxWidth: "none"
                        }} 
                         overlayStyle={actions.getOverlayStyle()}
                        
                        open={
                            !this.props.connect.state.isOnline &&
                            this.props.connect.state.offlinePersistanceMode !=
                            model.OfflinePersistanceMode.goodToGo
                        }
                    >
                        {this.props.connect.state.offlinePersistanceMode ==
                            model.OfflinePersistanceMode.multipleTabs && (
                                <div>
                                    <p>
                                        We don't support having multiple tabs open while
                                        offline. To fix please open the first TodoZero tab or
                                        reconnect to the internet.
                        </p>
                                </div>
                            )}
                        {this.props.connect.state.offlinePersistanceMode ==
                            model.OfflinePersistanceMode.notSupported && (
                                <div>
                                    <p>
                                        You seems to be offline and we don't support offline
                                        mode for your browser. Sorry about that.
                        </p>
                                </div>
                            )}
                    </material.Dialog>
                    <Progress connect={connect} />

                    <ErrorBox connect={connect} />
                    {connect.state.snackMessage && (
                        <material.Snackbar
                            open={connect.state.snackMessage != null}
                            message={connect.state.snackMessage.message}
                            action={connect.state.snackMessage.actionText}
                            autoHideDuration={
                                connect.state.snackMessage.autoHideDuration
                            }
                            onRequestClose={e => {
                                connect.dispatch<any>(actions.ar_snackBarHide());
                            }}
                            onActionClick={e => {
                                connect.dispatch<any>(actions.ar_snackBarAction(null));
                            }}
                        />
                    )}
                    {this.state && this.state.tagsearchPopup && (
                        <TagSearch
                            connect={connect}
                            onClose={() => {
                                let state = { ...me.state };
                                state.tagsearchPopup = false;
                                me.setState(state);
                            }}
                        />
                    )}
                    {this.state && connect.state.previewImage && (
                    <ImageViewer previewImage={connect.state.previewImage} dispatch={connect.dispatch} />
                    )}
                    <div style={{ display: "none" }}>
                        <iframe className={"trackingIframe"} />
                        <div className='upload-container'></div>
    
    
                    </div>

                </div>
            </div>
        )
    }
}