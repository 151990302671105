import * as React from "react";
import * as material from "material-ui";
import Tooltip from './CustomTooltip'
interface SimpleIconButtonProps{
    icon:material.SvgIcon
    fill?:string
    onClick:(e)=>void;
    size?:number ;
    style?:any;
    alt:string;
  }
  interface SimpleIconButtonState{
  
  }
  export class SimpleIconButton extends React.Component<
    SimpleIconButtonProps,
    SimpleIconButtonState
  >
  { 
    constructor(props, context) {
      super(props, context);
      this.state = {
     
      };
    }
    render(){ 
     
      //@ts-ignore


      let style = {width:this.props.size+'px', height:this.props.size+'px'};
      if(this.props.fill)
      {
        style["fill"] = this.props.fill
      }
      
      return (
        <div className='simple-icon-button-container' style={this.props.style?this.props.style:{}} onClick={this.props.onClick}>
           <Tooltip title={this.props.alt} >
           <this.props.icon  style={style} onClick={(e)=>{
                   e.stopPropagation()
              this.props.onClick(e);
         
           }} className='simple-icon-button-icon'/>
           </Tooltip>
        </div>
      )
    }
  }
   