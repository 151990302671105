import * as React from "react";
import * as material from "material-ui";
import * as hotkey from "../lib/hotkeyClient";
import * as pApi from '../../shared/papi/papi-core'
import * as actions from '../lib/ActionReducer'
import * as model from '../lib/model'
import RepeatIcon from "material-ui/svg-icons/av/repeat";
import AlarmOn from "material-ui/svg-icons/action/alarm-on";
import StarOn from "material-ui/svg-icons/toggle/star";
import StarOff from "material-ui/svg-icons/toggle/star-border";
import UnWatch from "../components/CustomSvg/UnWatch";
import { SnoozePopover } from "../components/SnoozePopover";
import PickDate from "material-ui/svg-icons/device/access-time";
import CheckIcon from "material-ui/svg-icons/navigation/check";
import CheckAllIcon from "material-ui/svg-icons/action/done-all";
import LinkIcon from "material-ui/svg-icons/content/link";
import NoteIcon from "material-ui/svg-icons/content/content-paste";
import Snooze from "material-ui/svg-icons/av/snooze";

import moment from 'moment'
export class TodoTagSelectorProps {
  selected: boolean;
  hasSelections: boolean;
  connect: model.ConnectProps;
  tagDisplay: string;
  group: any;
  hovering: boolean;
  onSelect: () => any
}
export class TodoTagSelectorState { }
export class TodoTagSelector extends React.Component<
  TodoTagSelectorProps,
  TodoTagSelectorState
> {
  constructor(props, context) {
    super(props, context);
    this.state = {
      hovering: false
    };
  }

  render() {
    let me = this;
    let showSelected =
      this.props.selected || this.props.hovering || this.props.hasSelections;
    return (
      <div>
        {showSelected && (
          <material.Checkbox
            checked={this.props.selected}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              me.props.onSelect();
              return false;
              /*
              if (this.props.selected)
             
              /*
                me.props.connect.dispatch(
                  actions.ar_clearSelect(me.props.group)
                );*/
              /*
            else
          // me.props.connect.dispatch(actions.ar_setSelect(me.props.group));
            return false;*/
            }}
          />
        )}
        {!showSelected && (
          <div>
            {this.props.tagDisplay && (
              <span style={{ fontWeight: "bold", wordSpacing: "-5px" }}>
                #{this.props.tagDisplay.split("/").join("/ ")}
              </span>
            )}
            {!this.props.tagDisplay && (
              <span style={{ color: "#757575" }}>Inbox</span>
            )}
          </div>
        )}
      </div>
    );
  }
}

interface TodoItemPageProps {
  todos: Array<pApi.ToDoItem>;
  connect: model.ConnectProps;
  bundleName?: string;
  enableHideFromInbox: boolean;
  selected: boolean;
  focused: boolean;
  group: any;
  onClick: () => any;
  onSelect: () => any;
  onComplete: () => any;
}
interface TodoItemPageState {
  hovering: boolean;
  selectHovering: boolean;
  snoozeMenu: boolean;
  anchorEl: any;
}

export class MobileTodoItemPage extends React.Component<
  TodoItemPageProps,
  TodoItemPageState
> {
  constructor(props: TodoItemPageProps, context) {
    super(props, context);

    this.state = {
      hovering: false,
      snoozeMenu: false,
      selectHovering: false,
      anchorEl: null
    };
  }
  onComponentDidMount() {
    setTimeout(this.checkHover.bind(this), 10);
  }
  firstTodo(): pApi.ToDoItem {
    return this.props.todos[0];
  }
  hasMultiple(): boolean {
    return this.props.todos.length > 1;
  }
  /*shouldComponentUpdate(nextProps:TodoItemPageProps, nextState:TodoItemPageState):boolean
  {
      if(!model.deepCompare(this.state, nextState ))
        return true;
        
      if(!model.deepCompare(this.props, nextProps ))
          return true;
      if(!model.deepCompare(this.props.connect.state.inboxData,nextProps.connect.state.inboxData))
          return true;
      console.log('skip update')
      return true;
  }*/
  async unWatchToggle(e: any) {
    e.preventDefault();
    e.stopPropagation();
    if (
      window.confirm(
        `Are you sure you want to hide ${this.props.todos.length
        } from your inbox?`
      )
    ) {
      //;
      for (var i in this.props.todos) {
        this.props.todos[i].hide_from_inbox = true;
      }
      await actions.shared_bulkUpdate(this.props.connect, this.props.todos);
    }
  }
  async setSnoozeDate(snoozeAction: pApi.SnoozeAction, date?: Date) {
    actions.shared_snoozeItems(
      false,
      this.props.connect,
      this.props.todos,
      snoozeAction,
      date
    );

    this.resetSnooze();
  }

  resetSnooze() {
    let state = { ...this.state };
    state.snoozeMenu = false;
    this.setState(state);
  }
  onSnoozeSelect(e) {
    e.preventDefault();
    e.stopPropagation();
    //;
    let state = { ...this.state };
    state.snoozeMenu = true;
    this.setState(state);
  }
  onSnoozeClose(e) {
    // e.preventDefault();
    // e.stopPropagation();
    //;
    let state = { ...this.state };
    state.snoozeMenu = false;
    state.anchorEl = null;
    this.setState(state);
  }
  getLink(s: string): string {
    if (!this.hasMultiple()) {
      return model.TodoLinkHelper.getLink(this.firstTodo());
    } else return null;
  }
  getNoteLink(): string {
    var noteId = this.firstTodo().note_id;
    if (noteId && this.hasMultiple()) {
      for (var i in this.props.todos) {
        if (this.props.todos[i].note_id != noteId) return;
      }
    }
    if (noteId) {
      return model.TodoLinkHelper.getNoteLink(
        this.firstTodo().id,
        this.props.connect.state.viewState
      );
    } else return null;
  }
  async linkClick(e) {
    var link = this.getLink(this.firstTodo().description);
    // @ts-ignore
    // navigator.app.loadUrl(link, {openExternal : true});
    //var win = window.open(link, "_system");
    window["openLink"](link);//
    // @ts-ignore
    // win.addEventListener('loadstart', ()=>{win.show()});;

    //win.focus();
    e.preventDefault();
    e.stopPropagation();
  }
  priorityClick(e) {
    e.preventDefault();
    e.stopPropagation();
    var t = { ...this.firstTodo() };
    t.priority = t.priority == 0 ? 1 : 0;
    t.sync_id = this.props.connect.state.client_id;
    this.props.connect.state.context.Todos.save(t as pApi.ToDoItem);
    return false;
    //  this.props.editTodo(this.firstTodo(), t, this.props.dispatch);
  }
  checkAndUpdateStateBackedOnPosition(e: any) {
    let relativeX = e.clientX - e.currentTarget.getClientRects()[0].x;
    let hovering = true;
    let isSelectHovering = relativeX > 0 && relativeX < 100;
    if (
      this.state.hovering != hovering ||
      this.state.selectHovering != isSelectHovering
    ) {
      let state = { ...this.state };
      state.hovering = hovering;
      state.selectHovering = isSelectHovering;
      this.setState(state);
    }
  }
  onMouseEnter(e) {
    console.log("enter");
    // if (this.state.snoozeMenu)
    //    return;
    this.checkAndUpdateStateBackedOnPosition(e);
    /* let state = { ...this.state };
    state.hovering = true;
    this.setState(state);*/
  }
  onMouseMove(e) {
    // if (this.state.snoozeMenu)
    //    return;
    this.checkAndUpdateStateBackedOnPosition(e);
    /*
    let relativeX =  e.clientX - e.currentTarget.getClientRects()[0].x ;
    console.log(
      "move " +relativeX+' '+
        e.clientX +
        " " +
        e.currentTarget.getClientRects()[0].x +
        " " +
        e.target.getAttribute("class")
    );
    if (!this.state.hovering) {
      let state = { ...this.state };
      state.hovering = true;
      this.setState(state);
    }
    
    if (!this.state.selectHovering && relativeX > 0 && relativeX < 100) {
      let state = { ...this.state };
      state.hovering = true;
      state.selectHovering = true;
      this.setState(state);
    } else {
      if (this.state.selectHovering) {
        let state = { ...this.state };
        state.selectHovering = false;
        this.setState(state);
      }
    }*/
  }
  onMouseLeave(e) {
    console.log("leave");
    let state = { ...this.state };
    state.hovering = false;
    state.selectHovering = false;
    this.setState(state);
  }

  touchStartDate: Date = null;
  preventTouchClick = false;
  clientX?: Number;
  clientY?: Number;
  onTouchMove(e) {
    this.touchStartDate = null;
  }

  onTouchStart(e) {
    this.preventTouchClick = false;
    let me = this;
    this.touchStartDate = new Date();
    this.clientX = e.clientX;
    this.clientY = e.clientY;
    let localTouchStartDate = this.touchStartDate;

    setTimeout(() => {
      console.log("touchEnd");
      if (
        me.touchStartDate != null &&
        localTouchStartDate == me.touchStartDate
      ) {
        console.log("select");
        me.preventTouchClick = true;
        me.touchStartDate = null;
        me.props.onSelect();
        //me.props.connect.dispatch(fakeActions.ar_setSelect(me.props.group));
        actions.vibrate();

        //me.setState(me.state);
      }
    }, 1000);
    // return false;
    //e.preventDefault();
    //e.stopPropagation();
  }
  onTouchEnd(e) {
    console.log("ontouchend" + this.touchStartDate);

    if (e.target.type == "checkbox") {
      this.touchStartDate = null;

      return false;
    }
    this.touchStartDate = null;

    // this.onDoubleClick(e);




    //  e.preventDefault();
    // e.stopPropagation();
    //this.onDoubleClick(e);
  }
  async onDoubleClick(e) {
    console.log("onDoubleClick");
    e.preventDefault();
    e.stopPropagation();
    if (this.preventTouchClick)
      return;
    //alert('not implemented')

    if (this.props.connect.state.selectedState.hasSelections()) {
      this.props.onSelect();
      /*
      if (this.props.selected)
        this.props.connect.dispatch(actions.ar_clearSelect(this.props.group));
      else this.props.connect.dispatch(actions.ar_setSelect(this.props.group));*/
      return;
    }
    if (this.state.snoozeMenu) return;
    this.props.onClick();
    /*if (this.props.todos.length > 1) {
      this.props.onClick();
      return true;
    }*/
    // if (e.nativeEvent.offsetX > 45) {
    console.log("double click");

    //this.setState({ editing: true });
    /*if (!this.firstTodo().objective_id)
      this.props.connect.dispatch<any>(actions.showTodoModal(this.firstTodo()));
    else {
      var obj = this.props.connect.state.objectives.find(
        x => x.id == this.firstTodo().objective_id
      );
      if (obj) {
        this.props.connect.dispatch<any>(
          actions.showCheckInModal(obj, this.props.connect.dispatch)
        );
      }
    }*/
  }
  checkHover() {
    if (this.state.hovering) return;
    var myNode = this.refs.mainDiv;
    //    console.log(myNode['parentElement'].querySelector(':hover'))
    //  console.log(myNode['querySelector'](':hover'))
    if (
      myNode &&
      myNode["parentElement"] &&
      myNode["parentElement"].querySelector(":hover") === myNode
    ) {
      this.onMouseEnter(null); //.onMouseEnter();
    }
  }

  render() {
    let isMobile =
      this.props.connect.getPresentationMode() == model.PresentationMode.mobile;
    let hasSelections = this.props.connect.state.selectedState.hasSelections();// .inboxData.hasSelections();
    let showLeft = !isMobile || hasSelections;
    var link = this.getLink(this.firstTodo().description);
    var noteLink = this.getNoteLink();
    var dateText = this.firstTodo().due_date
      ? moment(this.firstTodo().due_date).calendar(null, {
        lastDay: "[Yesterday]",
        sameDay: "[Today] h:mm a",
        nextDay: "[Tomorrow] h:mm a",
        lastWeek: "[last] dddd",
        nextWeek: "dddd",
        sameElse: "L"
      })
      : "";
    let me = this;
    let getTagForView = function (tags: Array<string>): string {
      if (!tags || tags.length == 0) return null;
      if (tags.length == 1) {
        return tags[0];
      }
      if (
        me.props.connect.state.viewState.noteViewState &&
        /*params.connect.state.viewState.noteViewState.noteViewType ==
            model.NoteViewType.todoList &&*/
        me.props.connect.state.viewState.noteViewState.functionType ==
        model.NoteFunctionType.tag
      ) {
        var filtered = tags.filter(
          x => x != me.props.connect.state.viewState.noteViewState.query
        );
        return filtered[0];
      } else {
        return tags[0];
      }
    };
    let tagDisplay = getTagForView(this.firstTodo().tags);
    let noteDescription = (
      <div>
        {this.hasMultiple() && (
          <span className="bundle-number">
            {this.props.todos.length} to-dos
          </span>
        )}
        {this.hasMultiple() && (
          <span style={{ color: "#757575", lineHeight: "1.5em" }}>
            {this.firstTodo().description}
          </span>
        )}
        {!this.hasMultiple() && (
          <div style={{ fontWeight: "bold", lineHeight: "1.5em" }}>
            {this.firstTodo().description}
          </div>
        )}
      </div>
    );
    let iconStyle = {
      width: "12px", height: "12px"
    }
    let snoozeCountStyle = iconStyle;
    let dateStyle = {};
    let reoccuring = !this.hasMultiple() && this.firstTodo().recoccuring_settings;
    let alarm = !this.hasMultiple() && this.firstTodo().alarm;
    let hidden = !this.hasMultiple() && this.firstTodo().hide_from_inbox;
    let snoozed = !this.hasMultiple() && this.firstTodo().snooze_count > 2
    let hasNote = !this.hasMultiple() && this.firstTodo().note_id != null
    let hasLink =   link != null && noteLink == null;
    let subtextConponent = (<div className="todo-subtext">
     
     
      {this.firstTodo().tags && this.firstTodo().tags.length > 0 && (
        <span>
         #{this.firstTodo().tags[0]} {(reoccuring || alarm || snoozed || hasNote ||  hasLink || dateText) ? " - " : ""}
        </span>
      )}
       <span style={dateStyle}>{dateText}

</span>
{snoozed && (
  <div className='simple-nav-icon-position' title={"Snoozed " + this.firstTodo().snooze_count + " times."}>
    <Snooze style={iconStyle} />
    <span style={snoozeCountStyle}>{this.firstTodo().snooze_count}</span>

  </div>
)}

{hasNote && (
  <div className='simple-nav-icon-position' title="In a note">
    <NoteIcon style={iconStyle} />

  </div>
)}
{hasLink && (
  <div className='simple-nav-icon-position' title="In a note">
    <LinkIcon style={iconStyle} />

  </div>
)}
{hidden && (
  <div className='simple-nav-icon-position' title="Hidden from inbox">
    <UnWatch style={iconStyle} />

  </div>
)}
{reoccuring && (
  <div className='simple-nav-icon-position' title="Repeats">
    <RepeatIcon style={iconStyle} />

  </div>
)}
{alarm && (
  <div className='simple-nav-icon-position' title="Alarm Set">
    <AlarmOn style={iconStyle} />
  </div>
)}



    </div>);
    let subtextConponent2 = (
      <div className="todo-subtext">
        {dateText}

        {!this.hasMultiple() && this.firstTodo().snooze_count > 2 && (
          <span >
            {"\u00a0"}<div className='simple-nav-icon-position'>
              <Snooze style={{ width: "12px", height: "12px" }} />
            </div>
            {this.firstTodo().snooze_count}{"\u00a0"}
          </span>
        )}
        {this.firstTodo().tags && this.firstTodo().tags.length > 0 && (
          <span>
            {dateText ? " - " : ""}#{this.firstTodo().tags[0]}
          </span>
        )}
        <span style={{ marginLeft: "10px", paddingTop: 2 }} />
        {!this.hasMultiple() && this.firstTodo().recoccuring_settings && (
          <RepeatIcon style={{ width: "16px", height: "16px" }} />
        )}
        {!this.hasMultiple() && this.firstTodo().alarm && (
          <AlarmOn style={{ width: "16px", height: "16px" }} />
        )}
      </div>
    );
    const iconButtonElement = (
      <div
        className="lookatme"

        style={{
          width: "100%",
          margin: "0px",
          padding: "0px",
          position: 'absolute',
          right: -5,
          top: '-15px'
        }}
      >
        {this.state.snoozeMenu && (
          <SnoozePopover
            snoozed={this.setSnoozeDate.bind(this)}
            removeDueDate={() => { }}
            onClosed={this.resetSnooze.bind(this)}
            dropdownOpen={this.state.snoozeMenu}
            popupOpen={false}
            anchorEl={this.refs.menuholder}
            connect={this.props.connect}
            defaultDate={
              this.firstTodo().due_date ? this.firstTodo().due_date : null
            }
          />
        )}
        <div
          className="hidden-sm-down"
          style={{
            display: this.state.hovering ? "block" : "none",
            backgroundColor: "white",
            borderStyle: "none"
          }}
        >
          <div
            className="float-right"
            style={{
              paddingRight: "0",
              paddingTop: "10px",

              background: "white"
            }}
          >
            <material.IconButton
              touch={true}
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                me.props.onComplete();
              }}
            >
              {this.hasMultiple() && <CheckAllIcon hoverColor="#66BB6A" />}
              {!this.hasMultiple() && <CheckIcon hoverColor="#66BB6A" />}
            </material.IconButton>
          </div>
          <div
            className="float-right"
            style={{
              paddingRight: "0",
              paddingTop: "10px",

              background: "white"
            }}
          >
            <material.IconButton
              onMouseEnter={e => {
                console.log("snooz onMouseEnter");
              }}
              onClick={this.onSnoozeSelect.bind(this)}
            >
              <Snooze hoverColor="#FFA726" />
            </material.IconButton>
          </div>
          {(link != null || noteLink != null) && (
            <div>
              {noteLink != null && (
                <div
                  className="float-right"
                  style={{
                    paddingRight: "0",
                    paddingTop: "10px",
                    background: "white"
                  }}
                >
                  <material.IconButton
                    touch={true}
                    onClick={e => {
                      window.location.hash = noteLink;
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  >
                    <NoteIcon hoverColor="blue" />
                  </material.IconButton>
                </div>
              )}
              {link != null && noteLink == null && (
                <div
                  className="float-right"
                  style={{
                    paddingRight: "0",
                    paddingTop: "10px",

                    background: "white"
                  }}
                >
                  <material.IconButton
                    touch={true}
                    onClick={this.linkClick.bind(this)}
                  >
                    <LinkIcon hoverColor="blue" />
                  </material.IconButton>
                </div>
              )}
            </div>
          )}
          {!this.hasMultiple() && (
            <div
              className="float-right"
              style={{
                paddingRight: "0",
                paddingTop: "10px",

                background: "white"
              }}
            >
              <material.IconButton
                touch={true}
                onClick={this.priorityClick.bind(this)}
              >
                {this.firstTodo().priority == pApi.ToDoItemPriority.High && (
                  <StarOn
                    color="orangered"
                    style={{ display: !this.state.hovering ? "block" : "none" }}
                  />
                )}
                {this.firstTodo().priority != pApi.ToDoItemPriority.High && (
                  <StarOff />
                )}
              </material.IconButton>
            </div>
          )}
          {this.firstTodo().tags &&
            this.firstTodo().tags.length > 0 &&
            this.props.enableHideFromInbox && (
              <div
                className="float-right"
                style={{
                  paddingRight: "0",
                  paddingTop: "10px",

                  background: "white"
                }}
              >
                <material.IconButton
                  touch={true}
                  onClick={this.unWatchToggle.bind(this)}
                >
                  <UnWatch
                    style={{ display: !this.state.hovering ? "block" : "none" }}
                  />
                  
                </material.IconButton>
              </div>
            )}
        </div>
      </div>
    );
    return (
      <div
        className="todo-listitem  "
        style={{} as React.StyleHTMLAttributes<String>}
        onMouseEnter={this.onMouseEnter.bind(this)}
        onMouseLeave={this.onMouseLeave.bind(this)}
        onMouseMove={this.onMouseMove.bind(this)}
        onClick={this.onDoubleClick.bind(this)}
        onTouchStart={this.onTouchStart.bind(this)}
        onTouchMove={this.onTouchMove.bind(this)}
        onTouchEnd={this.onTouchEnd.bind(this)}
      >
        <div className="container todo-no-padding">
          <div className="row no-gutters" style={{ overflow: "hidden" }}>
            {showLeft && (
              <div className="col-2 no-gutters ">
                <TodoTagSelector
                  connect={this.props.connect}
                  onSelect={this.props.onSelect}
                  group={this.props.group}
                  tagDisplay={tagDisplay}
                  selected={this.props.selected}
                  hasSelections={hasSelections}
                  hovering={this.state.selectHovering}
                />
              </div>
            )}
            <div
              className={showLeft ? "col-8 no-gutters" : "col-10 no-gutters"}
            >
              <div className="container todo-no-padding">
                <div className="row no-gutters ">
                  <div className="col-12 no-gutters mobile-todo-description ">{noteDescription}</div>
                </div>
                <div className="row no-gutters ">
                  <div className="col-12 no-gutters mobile-todo-description ">{subtextConponent}</div>
                </div>
              </div>
            </div>
            <div className="col-2 no-gutters ">
              <div
                ref={"menuholder"}
                className="float-right "
                style={{
                  paddingTop: 5,
                  paddingRight: 5
                }}
              >
                {this.firstTodo().priority == pApi.ToDoItemPriority.High && (
                  <StarOn
                    color="orangered"
                    style={{
                      display: !this.state.hovering || isMobile ? "block" : "none"
                    }}
                  />
                )}
              </div>
            </div>

            {iconButtonElement}
          </div>
        </div>
      </div>
    );
  }
}
