import * as React from "react";

import { Dispatch } from "redux";
import { List, ListItem } from "material-ui/List";
import Subheader from "material-ui/Subheader";
import * as Material from "material-ui";
import * as actions from "../../lib/ActionReducer";
import * as model from "../../lib/model";

import { ApiKey } from "../../../shared/papi/papi-core";
import { Track, ErrorObj } from "../../../shared/track";

import * as pApi from '../../../shared/papi/papi-core';

import { getPresentationMode, PresentationMode } from "../../lib/model";
import moment from 'moment'
let GitInfo = require( '../../../shared/gitinfo').default;


interface HelpProps {
  connect: model.ConnectProps;
}
class HelpState {
  resetBecons: boolean = false;
  messageSent: boolean = false;
  messageError: string = null;
  messageText: string = "";
}

export class Help extends React.Component<HelpProps, HelpState> {
  constructor(props, context) {
    super(props, context);

    this.state = new HelpState();
  }
  async resetHelpClick(e) {
    this.props.connect.dispatch<any>(
      actions.ar_updateOnboardingState(new pApi.OnboardingState())
    );
    let s = { ...this.state };
    s.resetBecons = true;
    this.setState(s);
  }
  submitAnother()
  {
    let s = {...this.state};
    s.messageText = "";
    s.messageSent=false;
    this.setState(s);
  }
  async submitFeedback() {
    try {
      
      let text = "Message was sent by "+this.props.connect.state.user.displayName+"\n";

      text += "*****************\n"+this.state.messageText+'\n'+"*****************\n";
      
      try {
        let platform =this.props.connect.state.user.email;
          text+="System Information\n"+
                  "version: "+GitInfo.VERSION+'\n'+platform+
                  "agent: "+navigator.userAgent+"\n\n"
   
                
      }
      catch(e)
      {
          text+='Error getting system info '+e
      }
      this.props.connect.dispatch<any>(
        actions.ar_sendMessage({
          to: "support@todozero.com",
          subject:'User submitted feedback - '+this.props.connect.state.user.displayName,
          from:this.props.connect.state.user.email,
          body: text
        })
      );

      let s = { ...this.state };
      s.messageSent = true;
      this.setState(s);
    } catch (e) {
      let s = { ...this.state };
      s.messageError =
        "There was a problem sending the message.  Sorry we logged the error and will take a look (" +
        e +
        ")";
      this.setState(s);
      Track.reportError(e, s.messageError, false);
    }
  }
  render() {
    let me = this;
   
    return (
      <div className="container-fluid  chapter  ">
   
          <div className="row">
            <h4>Ask a question or give feedback</h4>
          </div>
          {this.state.messageSent &&
            <div className="col-12">
            <div style={{ textAlign: "center" }}>
               <h3>Thank You! Will get back to you shortly.</h3>
               <Material.RaisedButton
               label="Send another message"
               primary
               onClick={this.submitAnother.bind(this)}
             />
             </div>
             </div>
          }
         {!this.state.messageSent &&
         <div>
          { !this.props.connect.state.isOnline &&
            <b>You must be connected to the internet to send a message.</b>
          }
          { this.props.connect.state.isOnline &&
          <div>
          <div className="row">
            <Material.TextField
              multiLine
              hintText="Enter your message here."
              rows={7}
              style={{
                "backgroundColor": "white",
                width: "100%"
              }}
              value={this.state.messageText}
              onChange={(e, newValue) => {
                let state = { ...me.state };
                state.messageText = newValue;
                me.setState(state);
              }}
            />
          </div>
          <div className="row" >
         
          <div className="col-12">
            <div style={{ textAlign: "right" }}>
              <Material.RaisedButton
                label="Send Message to the TodoZero Team!"
                primary
                onClick={this.submitFeedback.bind(this)}
              />
            </div>
          </div>
        </div>
        </div>
          }
          </div>
         }
        <div className="row" style={{padding:'15px'}}>
        </div>
     
     
      </div>
    );
  }
}
