import * as React from "react";
import { InputRule } from "prosemirror-inputrules";
import { Decoration, DecorationSet } from "prosemirror-view";
import { findParentNode } from "prosemirror-utils";
import { Schema, Plugin } from "prosemirror-model"
import { schema as basicSchema } from "prosemirror-schema-basic";
import Node from "rich-markdown-editor/dist/lib/nodes/Node"
//import Node from ""
import { addMentionNodes, addTagNodes, getMentionsPlugin } from './Mentions/index'
const MAX_MATCH = 500;
const OPEN_REGEX_OLD = /^\/(\w+)?$/;
const CLOSE_REGEX = /(^(?!\/(\w+)?)(.*)$|^\/((\w+)\s.*|\s)$)/;
const OPEN_REGEX = "/\B(\#[a-zA-Z\/0-9_-]+(?!\/))/g,"
// based on the input rules code in Prosemirror, here:
// https://github.com/ProseMirror/prosemirror-inputrules/blob/master/src/inputrules.js
function run(view, from, to, regex, handler) {
  if (view.composing) {
    return false;
  }
  const state = view.state;
  const $from = state.doc.resolve(from);
  if ($from.parent.type.spec.code) {
    return false;
  }

  const textBefore = $from.parent.textBetween(
    Math.max(0, $from.parentOffset - MAX_MATCH),
    $from.parentOffset,
    null,
    "\ufffc"
  );

  const match = regex.exec(textBefore);
  const tr = handler(state, match, match ? from - match[0].length : from, to);
  if (!tr) return false;
  return true;
}

//import TagTrigger from "./TagTrigger"

var schema = new Schema({
  nodes: addTagNodes(addMentionNodes(basicSchema.spec.nodes)),
  marks: ["#", "@"]
});

var getMentionSuggestionsHTML = items =>
  '<div class="suggestion-item-list">' +
  items.map(i => '<div class="suggestion-item">' + i.name + "</div>").join("") +
  "</div>";

var getTagSuggestionsHTML = items =>
  '<div class="suggestion-item-list">' +
  items.map(i => '<div class="suggestion-item">' + i.tag + "</div>").join("") +
  "</div>";

var mentionPlugin = getMentionsPlugin({
  getSuggestions: (type, text, done) => {
    
    setTimeout(() => {
      if (type === "mention") {
        // pass dummy mention suggestions
        done([
          { name: "John Doe", id: "101", email: "joe@gmail.com" },
          { name: "Joe Lewis", id: "102", email: "lewis@gmail.com" }
        ]);
      } else {
        // pass dummy tag suggestions
        done([{ tag: "WikiLeaks" }, { tag: "NetNeutrality" }]);
      }
    }, 0);
  },
  getSuggestionsHTML: (items, type) => {
    if (type === "mention") {
      return getMentionSuggestionsHTML(items);
    } else if (type === "tag") {
      
      return getTagSuggestionsHTML(items);
    }
  }
});

 export  class TagTriggerx extends Node {
  get name() {

    return "tag";

  }
  get schema() {
      return {
        nodes: addTagNodes(addMentionNodes(basicSchema.spec.nodes)),
        marks: ["#", "@"]
      };
  }
  get plugins() {
    return [
      mentionPlugin
    ];
  }
}
export default class TagTrigger extends Node {
  
  get name() {

    return "tag";

  }
  get plugins() {
    return [
      mentionPlugin
    ];
  }

  get schema() {
   
    return {
    
      content: "text*",
     
      inline: true,
      group: "inline",
      attrs: {
        tag: {},
      },
      parseDOM: [
        {
          tag: "span[class=mention]",
          getAttrs: (dom: HTMLElement) => {
            const img = dom.getElementsByTagName("d")[0];
            const caption = dom.getElementsByTagName("p")[0];

            return {
              tag: dom.innerText.substr(1)
            };
          },
        },
      ],
      toDOM: node => {
        return [
          "span",
          {
            class: "mention",
          },
          "#",node.attrs.tag
        ];
      },
    };
  }

  component({ isEditable, isSelected, theme, node }) {
    const Component = node.attrs.component;

    return (
      <span className={"mention"} >
        #{node.attrs.tag}
      </span>
    );
  }

  commands({ type }) {
    return attrs => (state, dispatch) => {
      dispatch(
        state.tr.replaceSelectionWith(type.create(attrs)).scrollIntoView()
      );
      return true;
    };
  }

  toMarkdown(state, node) {
    state.ensureNewLine();
    state.write(
      "#HEYBUDDY"
    );
    state.write("\n\n");
  }

  parseMarkdown() {
    return {
      node: "tag",
      getAttrs: token => {
        ;
        return {
        tag: token.attrGet("tag"),
        }
      },
    };
  }
}


