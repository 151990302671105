import * as pApi from "../../shared/papi/papi-core";
import firebase from 'firebase/app'

import 'firebase/firebase-messaging';
export class PushClient {
  device: pApi.Device;
  ctx: pApi.ICtx;

  messaging: any;
  setupWebWorker(cb: any): Promise<void> {
    var me = this;
    return new Promise<void>((resolve, reject) => {
      try {
        if ("serviceWorker" in navigator) {
          navigator.serviceWorker
            .register("/firebase-messaging-sw.js", { scope: "/" })
            .then(function (worker) {
              console.log("Service Worker Registered");

              if (me.messaging) {
                me.messaging.useServiceWorker(worker);
              }
              resolve();
            });
          navigator.serviceWorker.ready.then(function (worker) {
            console.log("Service Worker Ready");
            worker.addEventListener(
              "message",
              function (e) {
                console.log("Worker said: ", e);
                cb(e);
              },
              false
            );
          });
        } else {
          resolve();
        }
      } catch (e) {
        console.log("warn - this browser does not support web messaging", e);
        reject(e);
      }
    });
  }
  getToken(): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      try {
        if (window["ios"]) {
          //@ts-ignore
          window.FirebasePlugin.getToken(function (token) {
            // save this server-side and use it to push notifications to this device
            console.log('PUSH:Got Token ' + token)
            resolve(token);
          }, function (error) {
            reject(error)
          });
        } else {
        
          resolve(await this.messaging.getToken());
        }
      } catch (e) {
        reject(e);
      }
    });
  }
  startup(cb: any): Promise<pApi.Device> {
    return new Promise<pApi.Device>(async (resolve, reject) => {
      try {
        let deviceId = localStorage.getItem("deviceId");
        let me = this;
        if (deviceId) {
          this.device = await this.ctx.Devices.load(deviceId);
        }

        if (!this.device) {
          let newDevice = new pApi.Device();
          newDevice.platform = window["ios"]
            ? pApi.Platform.ios
            : pApi.Platform.web;
          newDevice.platform_details = navigator.userAgent;
          this.device = await this.ctx.Devices.save(newDevice);
          localStorage.setItem("deviceId", this.device.id);
        }
        await this.setupWebWorker(cb);
        let token = null;
        try {
          token = await this.getToken();
        } catch (e) {
          console.error("PUSH:error getting token", e);
        }

        if (
          this.device.allow_push === undefined ||
          token != this.device.token
        ) {
          try {
            if (window["ios"]) {
              console.log('PUSH:request Permission')
              //@ts-ignore
              window.FirebasePlugin.grantPermission();
            } else {
              await this.messaging.requestPermission();
            }
            this.device.allow_push = true;
            let token = await this.getToken();

            this.device.token = token;
            this.device = await this.ctx.Devices.save(this.device);
            console.log('PUSH:save davie ' + this.device.id)
          } catch (e) {
            this.device.allow_push = false;
            console.log('PUSH:Error Registering device ', e)
            console.warn(e);
          }
        }
        this.device.last_used = new Date();
        if (this.device.allow_push) {
          if (window["ios"]) {
            //@ts-ignore
            window.FirebasePlugin.onNotificationOpen(
              function (payload) {
                console.log("PUSH:Message onNotificationOpen.")
                console.log("PUSH:Message onNotificationOpen. ", payload);

                cb(payload);
              },
              function (error) {
                console.error("PUSH:Notify Open Error", error);
              }
            );

            //@ts-ignore
            window.FirebasePlugin.onTokenRefresh(
              async function (token) {
                console.log('PUSH:token refresh', token)
                let newToken = await me.getToken();
                me.device.token = newToken;
                me.device = await me.ctx.Devices.save(me.device);
              },
              function (error) {
                console.error("PUSH:error get token ", error); //TODO Track error
              }
            );
          } else {
            this.messaging.onTokenRefresh(async function () {
              try {

                let newToken = await me.messaging.getToken();
                me.device.token = newToken;
                me.device = await me.ctx.Devices.save(me.device);
              } catch (e) {
                console.error("error get token " + e); //TODO Track error
              }
            });
            this.messaging.onMessage(function (payload) {
              console.log("PUSH:Message received. ", JSON.stringify(payload))

              cb(payload);
              // ...
            });
          }
        }
        console.log("PUSH:done", this.device.id);
        resolve(this.device);
        await this.ctx.Devices.save(this.device);
      } catch (e) {
        console.error("PUSH:error setting up push", e);
      }
    });
  }
  constructor(ctx: pApi.ICtx) {
    this.ctx = ctx;

    try {
    
      this.messaging = firebase.messaging()
      this.messaging.usePublicVapidKey(
        "BHZ4Susyzr-qF9dAXpAUz1PkxVGSAdpqnZ1xhZYDJ2rIiOl6iL9MR5L0hzxug1P_Os1brY50ixB1uFQa0kpA2zQ"
      );
    } catch (e) {
      console.error(e)
      //todo - check to see if browser supports messaging instead of doing this
    }
  }
}
