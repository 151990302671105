import {
  handleActions,

} from "redux-actions";
import { Track, ErrorObj } from "../../shared/track";
import * as model from "./model";
import * as pApi from "../../shared/papi/papi-core";
import * as rhelp from "./ReducerHelper";
import NoteProcessor from "../components/Editor/NoteProcessor"
import { HybridContext } from "./frontEndContext"
import { MemoryCacheProvider } from "../../shared/papi/memoryCache";
import { ImageHandler, ImageResolver } from "../components/Editor/ImageHandler";
import { PushClient } from "./PushClient";
import { HotKeyEngine } from "./hotkeyClient";
import * as sl from '../../shared/lib/superListTypes'
import firebase from 'firebase/app';
import 'firebase/firebase-auth';
import 'firebase/firebase-firestore';
import 'firebase/firebase-messaging';
import 'firebase/firebase-storage';
import 'firebase/database';

import { NoteImage } from "../components/Editor/ImageHandler"
import Darkmode from 'darkmode-js';
import { setInterval } from "timers";
import { LoadingState, IState, ViewState, AppSections } from "./model";

import { AnalysisSchemeStatus } from "aws-sdk/clients/cloudsearch";
import moment from 'moment';
import 'moment/locale/fr';

let uuidV4 = require("uuid/v4");

var $ = require("jquery");


let _ = require('lodash')
var ImgCache = require("hacked-imgcache");

window["ImgCache"] = ImgCache;
// write log to console
ImgCache.options.debug = true;
ImgCache.options.skipURIencoding = true;
// increase allocated space on Chrome to 50MB, default was 10MB
ImgCache.options.chromeQuota = 50 * 1024 * 1024;
if (!window["ios"]) {
  console.log("imgcache:init non mobile");
  ImgCache.init();
}
//UA-92222572-3


//@ts-ignore
if (!Element.prototype.scrollIntoViewIfNeeded) {
  //@ts-ignore
  Element.prototype.scrollIntoViewIfNeeded = function (centerIfNeeded) {
    centerIfNeeded = arguments.length === 0 ? true : !!centerIfNeeded;

    var parent = this.parentNode,
      parentComputedStyle = window.getComputedStyle(parent, null),
      parentBorderTopWidth = parseInt(
        parentComputedStyle.getPropertyValue("border-top-width")
      ),
      parentBorderLeftWidth = parseInt(
        parentComputedStyle.getPropertyValue("border-left-width")
      ),
      overTop = this.offsetTop - parent.offsetTop < parent.scrollTop,
      overBottom =
        this.offsetTop -
        parent.offsetTop +
        this.clientHeight -
        parentBorderTopWidth >
        parent.scrollTop + parent.clientHeight,
      overLeft = this.offsetLeft - parent.offsetLeft < parent.scrollLeft,
      overRight =
        this.offsetLeft -
        parent.offsetLeft +
        this.clientWidth -
        parentBorderLeftWidth >
        parent.scrollLeft + parent.clientWidth,
      alignWithTop = overTop && !overBottom;

    if ((overTop || overBottom) && centerIfNeeded) {
      parent.scrollTop =
        this.offsetTop -
        parent.offsetTop -
        parent.clientHeight / 2 -
        parentBorderTopWidth +
        this.clientHeight / 2;
    }

    if ((overLeft || overRight) && centerIfNeeded) {
      parent.scrollLeft =
        this.offsetLeft -
        parent.offsetLeft -
        parent.clientWidth / 2 -
        parentBorderLeftWidth +
        this.clientWidth / 2;
    }

    if ((overTop || overBottom || overLeft || overRight) && !centerIfNeeded) {
      this.scrollIntoView(alignWithTop);
    }
  };
}

moment.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s",
    s: function (number, withoutSuffix, key, isFuture) {
      return "<1m";
    },
    m: "1m",
    mm: function (number, withoutSuffix, key, isFuture) {
      return number + "m";
    },
    h: "1h",
    hh: "%dh",
    d: "1D",
    dd: "%dD",
    M: "1M",
    MM: "%dM",
    y: "1Y",
    yy: "%dY"
  }
});
// Instead of using the PERSISTENT or TEMPORARY filesystems, use one of the
// Cordova File plugin's app directories
// (https://github.com/apache/cordova-plugin-file#where-to-store-files).
// This is friendlier in a mobile application environment as we are able to store
// files in the correct platform-recommended/enforced directories.
// WARNING: Make sure this points to a __directory__!
// NOTE: Only has effect when running in a Cordova environment



var reducersList = {};

function qFetch(url: string, action: string, obj?: any): Promise<any> {
  return new Promise<any>(async (resolve, reject) => {
    try {
      var body = {
        method: action,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          token: window["token"] ? window["token"] : ""
        },
        body: obj ? JSON.stringify(obj) : null
      };
      let root = window["apiRootUrl"] ? window["apiRootUrl"] : "";

      var response = await fetch(root + url, body);
      if (response.status > 299)
        return reject(response.status + " " + response.statusText);
      try {
        let responseJson = await response.json();
        resolve(responseJson);
      } catch (e) {
        resolve(null);
      }
    } catch (e) {
      reject(e);
    }
  });
}
export function qGet(url): Promise<any> {
  return qFetch(url, "GET");
}
function qPost(url, body: any): Promise<any> {
  return qFetch(url, "POST", body);
}
function qPut(url, body: any): Promise<any> {
  return qFetch(url, "PUT", body);
}
function qPatch(url, body: any): Promise<any> {
  return qFetch(url, "PATCH", body);
}

export const initialState: model.IState = {
  todos: new Array<pApi.ToDoItem>(),
  todoQueryId: 'anythingbutadate',
  todoView: model.TodoView.Inbox,
  TodosState: model.LoadingState.NotLoaded,
  objectiveListState: model.LoadingState.NotLoaded,
  TagsState: model.LoadingState.NotLoaded,
  editTodo: new model.LoadingObject<pApi.ToDoItem>(null),
  editingProject: false,
  uiConifg: new model.UIConfig(),
  AuthState: model.AuthState.Uninitialized,
  checkInModal: false,
  objectiveModal: false,
  notes: new model.LoadingObject<Array<pApi.Note>>(null),
  noteState: model.LoadingState.NotLoaded,
  context: new HybridContext(new MemoryCacheProvider()),
  viewState: null,
  windowHeight: window.innerHeight,
  lastSnooze: null,
  client_id: Math.floor(Math.random() * 1000000 + 1),
  platform: window["ios"] ? pApi.Platform.ios : pApi.Platform.web,
  searchKeyState: model.LoadingState.NotLoaded,
  searchKey: null,
  searchResults: [],
  searchText: null,
  lastError: null,
  errorArray: new Array<ErrorObj>(),
  errorShow: false,
  dojoSettings: null,
  dojoSettingsState: model.LoadingState.NotLoaded,
  snackMessage: null,
  isOnline: true,
  offlinePersistanceMode: model.OfflinePersistanceMode.goodToGo,
  archiveTodos: null,
  archiveTodoState: model.LoadingState.NotLoaded,
  archiveNotes: null,
  archiveNotesState: model.LoadingState.NotLoaded,
  apiKeyState: model.LoadingState.NotLoaded,
  apiKey: null,
  hotkey: new model.HotkeyPress(),
  selectedState: new sl.SuperListSelectedState('null'),
  canAlwaysTop: window["electron"]?true:false,
  alwaysTopOn: false
};

function prepareState(state: model.IState) {
  var newState: model.IState = { ...state };
  newState.todos = new Array<pApi.ToDoItem>();

  for (var i in state.todos) {
    newState.todos.push(state.todos[i]);
  }

  return newState;
  // return assign<model.IState>(state);
}
export function hideKeyboard() {
  //@ts-ignore
  console.log("hide keyboard");
  //@ts-ignore
  if (typeof cordova == "object") {
    setTimeout(() => {
      //@ts-ignore
      Keyboard.hide();
    }, 1);
  } else {
    //  alert('cordova not found')
  }
}
export function showKeyboard() {
  //@ts-ignore
  console.log("show keyboard");
  //@ts-ignore
  if (typeof cordova == "object") {
    setTimeout(() => {
      //@ts-ignore
      Keyboard.show();
    }, 1);
  } else {
    //  alert('cordova not found')
  }
}
let appstarted = false;
const startApp = rhelp.CreateSimpleActionReducer1<any, boolean>(
  reducersList,
  "startApp",
  (dispatch: any): boolean => {
    var func = async (dispatch: any) => {
      try {
        if (appstarted)
          throw 'app already started';
        appstarted = true;

        var config = {
          apiKey: "AIzaSyC-AjRE2q4Mqj6GGvJVfvZC3347knE1Tkg",
          authDomain: "botdojo-2dd70.firebaseapp.com",
          databaseURL: "https://botdojo-2dd70.firebaseio.com",
          projectId: "botdojo-2dd70",
          storageBucket: "botdojo-2dd70.appspot.com",
          messagingSenderId: "195149464905",
          appId: "1:195149464905:ios:8fb891b434ba99fe",

        }

        //hijack indexdb
        let open_wrapped = window.indexedDB.open;
        //@ts-ignore
        window.indexedDB['open'] = function (name, version, upgradeCallback) {
          console.log("INDEXDB " + name + ' ' + version);
          return open_wrapped.apply(window.indexedDB, arguments);
        }

        var firebasex = firebase.initializeApp(config);
        firebase.firestore().settings({
          cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED
        })


       firebase
          .firestore()
          .enablePersistence({ synchronizeTabs: true })
          .then(function () {
            ;
            dispatch(ar_setPersistance(model.OfflinePersistanceMode.goodToGo));
          
          })
          .catch(function (err) {
            console.error('firebase persistance ' + err)
            
            if (err.code == "failed-precondition") {
              // Multiple tabs open, persistence can only be enabled
              // in one tab at a a time.
              dispatch(
                ar_setPersistance(model.OfflinePersistanceMode.multipleTabs)
              );
            } else if (err.code == "unimplemented") {
              // The current browser does not support all of the
              // features required to enable persistence
              dispatch(
                ar_setPersistance(model.OfflinePersistanceMode.notSupported)
              );
            } else
              dispatch(
                ar_setPersistance(model.OfflinePersistanceMode.notSupported)
              );
          });
      
        var provider = new firebase.auth.GoogleAuthProvider();
        await firebase
          .auth()
       

        window["signInWithCustomToken"] = function (token) {
          window["signInCallback"](firebase.auth(), provider, token);
        };
        firebase.auth().onIdTokenChanged(user => { });
      
        firebase.auth().onAuthStateChanged(async (user) => {
          try {
            ;

            if (user) {
              let currentUser = await firebase.auth().currentUser;
              let idToken = null;
              try {
                idToken = await currentUser.getIdToken(true);
              } catch (e) {
                console.log("couldn't get token ", e)
              }
              user['token'] = idToken;
              localStorage.setItem("user", JSON.stringify(user));
              dispatch(authUser(user, idToken));
            }
            else {
              dispatch(ar_needLogin());


              return;
            }
          } catch (e) {
            console.error(e)
          }
        });

      
        firebase
          .database()
          .ref(".info/connected")
          .on("value", function (snapshot) {
            var val = snapshot.val();
            console.log("ONLINE " + val);
            if (val) {
              dispatch(ar_setOnline());
            } else {
              dispatch(ar_setOffline());
            }
          });
      } catch (e) {
        console.error(e);
      }
    };

    func(dispatch);
    return true;
  },
  (state: model.IState, payload: boolean): model.IState => {
    state.AuthState = model.AuthState.Initializing;
    window["gotIntent"] = function (e) {
      console.log(arguments);
      console.log(e);
      gotIntent(state, e);
    };

    return state;
  }
);
export { startApp };

const ar_setOffline = rhelp.CreateQuickReducer0<boolean>(
  reducersList,
  "ar_setOffline",
  (state: model.IState, payload: boolean): model.IState => {
    state.isOnline = false;
    state.context.online = false;
    return state;
  }
);
const ar_setOnline = rhelp.CreateQuickReducer0<boolean>(
  reducersList,
  "ar_setOnline",
  (state: model.IState, payload: boolean): model.IState => {
    state.isOnline = true;
    state.context.online = true;
    return state;
  }
);

const ar_setPersistance = rhelp.CreateQuickReducer1<
  model.OfflinePersistanceMode
>(
  reducersList,
  "ar_setPersistance",
  (
    state: model.IState,
    payload: model.OfflinePersistanceMode
  ): model.IState => {
    state.offlinePersistanceMode = payload;
    return state;
  }
);
const ar_loadSettings = rhelp.CreateQuickReducer0<boolean>(
  reducersList,
  "ar_ar_loadSettings",
  (state: model.IState, payload: boolean): model.IState => {
    var f = async (state: model.IState, payload: boolean) => {
      let updateCallback = new pApi.UpdateCallback<pApi.DojoSettings>(
        "loadSettings",
        state.context.dispatch,
        ar_loadSettingsComplete
      );
      let settings = await state.context.Settings.get();
      let redirectToSetup = false;
      // if (!settings && state.isOnline) {
      if (!settings) {
        redirectToSetup = true;
        settings = new pApi.DojoSettings();
      }
      let dummyArray = new Array<pApi.DojoSettings>();
      dummyArray.push(settings);


      //this should trigger an objective reload
    };
    state.dojoSettingsState = model.LoadingState.Loading;

    f(state, payload);
    return prepareState(state);
  }
);

export { ar_loadSettings };
const ar_loadSettingsComplete = rhelp.CreateQuickReducer1<
  Array<pApi.DojoSettings>
>(
  reducersList,
  "ar_loadSettingsComplete",
  (state: model.IState, payload: Array<pApi.DojoSettings>): model.IState => {
    //damn this is crap

    if (!payload[0].id && !state.dojoSettings && state.isOnline) {
      try {
        setTimeout(() => {
          $(".trackingIframe").attr(
            "src",
            "https://www.todozero.com/trackaccountcreation"
          );
        }, 100);
      } catch (e) {
        Track.error("error setting creation string" + e);
      }
    }
    state.dojoSettingsState = model.LoadingState.Completed;
    state.dojoSettings = payload[0];
    state.context.Notes.setDefaultStorageType(payload[0].embedNoteContent?pApi.StorageType.embedded:pApi.StorageType.embedded)
    return prepareState(state);
  }
);
export { ar_loadSettingsComplete };

const ar_updateOnboardingState = rhelp.CreateQuickReducer1<
  pApi.OnboardingState
>(
  reducersList,
  "ar_updateOnboardingState",
  (state: model.IState, payload: pApi.OnboardingState): model.IState => {
    //state.onboardingState = payload;// = payload[0];
    state.dojoSettings.onboarding = payload;
    state.context.Settings.set(state.dojoSettings);
    return prepareState(state);
  }
);
export { ar_updateOnboardingState };

const ar_generateNewApiKey = rhelp.CreateQuickReducer0<boolean>(
  reducersList,
  "ar_generateNewApiKey",
  (state: model.IState, payload: boolean): model.IState => {
    var f = async (state: model.IState, payload: boolean) => {
      let key = new pApi.ApiKey();
      key.token = uuidV4();
      let apiKey = await state.context.ApiKeys.set(key);

      state.context.dispatch(ar_loadApiComplete(apiKey));

      //this should trigger an objective reload
    };
    state.apiKeyState = model.LoadingState.Loading;

    f(state, payload);
    return prepareState(state);
  }
);

export { ar_generateNewApiKey };

const ar_loadApiKeys = rhelp.CreateQuickReducer0<boolean>(
  reducersList,
  "ar_loadApiKeys",
  (state: model.IState, payload: boolean): model.IState => {
    var f = async (state: model.IState, payload: boolean) => {
      let apiKey = await state.context.ApiKeys.get();

      state.context.dispatch(ar_loadApiComplete(apiKey));

      //this should trigger an objective reload
    };
    state.apiKeyState = model.LoadingState.Loading;

    f(state, payload);
    return prepareState(state);
  }
);
export { ar_loadApiKeys };
const ar_loadApiComplete = rhelp.CreateQuickReducer1<pApi.ApiKey>(
  reducersList,
  "ar_loadApiComplete",
  (state: model.IState, payload: pApi.ApiKey): model.IState => {
    state.apiKeyState = model.LoadingState.Completed;
    state.apiKey = payload;
    return prepareState(state);
  }
);
export { ar_loadApiComplete };

let gotIntent = (state: IState, intent: any) => {
  return new Promise<void>(async (resolve, reject) => {
    try {
      console.log(JSON.stringify(intent));
      switch (intent.uti) {
        case "public.text":
        case "public.url":
          ;
          if (intent.data.match(/\.(jpeg|jpg|gif|png)$/) != null) {

            var h = new ImageHandler(
              state.context.dispatch,
              state.context.getId()
            );
            let newId = pApi.newId();
            let file = window["cordovaUrlFixer"](intent.data)
            var nimage = new NoteImage();
            nimage.internalUrl = file
            nimage.id = newId + "/quickUpload/" + uuidV4();
            nimage.src = file
            var newNote = await h.createNoteFromImage(nimage, [], newId, state.context);

            var newNS = new model.ViewState();
            newNS.noteViewState = new model.NoteViewState();
            newNS.noteViewState.noteId = newNote.id;
            newNS.appSection = model.AppSections.notes;
            setTimeout(() => {
              window.location.hash = newNS.getHash();
            }, 100);
            return resolve();
          }



          var todoa = new pApi.ToDoItem();
          {
            todoa.is_new = true;
            todoa.checked = false;
            todoa.description = intent.data;
            todoa.priority = 0;
            todoa.project_type = pApi.ProjectType.ptInbox;
            todoa.note_id = null;
            todoa.due_date = null;
            todoa.objective_id = null;
            todoa.recoccuring = null;
            todoa.tags = null;
          }
          state.context.dispatch(showTodoModal(todoa));
          return resolve();

        case "public.image":
          /* var newNote = new pApi.Note();
          var newContent = new pApi.NoteContent();
          newContent.format = "quill";
          newContent.type = pApi.ContentType.snapshot;
          newContent.content = [
            { insert: "" },
            { attributes: { header: 1 }, insert: "\n" }
          ];
          newNote.title = intent.name;
          newNote.tags = ["images"];
          var newNote = await state.context.Notes.save(newNote);
*/

          var h = new ImageHandler(
            state.context.dispatch,
            state.context.getId()
          );
          let newId = pApi.newId()
          var image = await h.uploadFile(
            state.context.getId() + "/quickAdd/" + uuidV4(),
            intent.data,
            true
          );

          var newNote = await h.createNoteFromImage(image, [], newId, state.context);

          var newNS = new model.ViewState();
          newNS.noteViewState = new model.NoteViewState();
          newNS.noteViewState.noteId = newNote.id;
          newNS.appSection = model.AppSections.notes;
          setTimeout(() => {
            window.location.hash = newNS.getHash();
          }, 100);

          return resolve();
      }

      reject("unknow format " + intent.uti);
    } catch (e) {
      console.error("error in getting content" + e);
      Track.reportError("Error gotIntent", e, false);
      reject(e);
    }
  });
};

const ar_snackBarHide = rhelp.CreateQuickReducer0<any>(
  reducersList,
  "ar_snackBarHide",
  (state: model.IState, payload: AnalysisSchemeStatus): model.IState => {
    state.snackMessage = null;
    return prepareState(state);
  }
);
export { ar_snackBarHide };

class snackActions {
  static async undoTodoComplete(
    state: IState,
    dispatch: any,
    todoIds: Array<any>
  ) {
    try {
      state.context.bulkupdate(new Promise<void>(async (resolve, reject) => {
        try {
          for (var i in todoIds) {
            var t = await state.context.Todos.load(todoIds[i]);
            t.checked = false;
            await state.context.Todos.save(t);
          }
        }
        catch (e) {
          reject(e);
        }
      }))

    } catch (e) {
      console.error(e);
    }
  }
  static async openTodo(state: IState, dispatch: any, todoId: any) {
    try {
      let todo = await state.context.Todos.load(todoId);
      dispatch(showTodoModal(todo));
    } catch (e) {
      console.error(e);
      //todo - handle error
    }
  }
}
const ar_snackBarAction = rhelp.CreateQuickReducer1<model.SnackMessage>(
  reducersList,
  "ar_snackBarAction",
  (state: model.IState, payload: model.SnackMessage): model.IState => {
    let snack = payload ? payload : state.snackMessage;
    switch (snack.actionCommand) {
      case model.SnackCommandAction.openTodo:
        snackActions.openTodo(
          state,
          state.context.dispatch,
          snack.actionParameter
        );
        break;
      case model.SnackCommandAction.undoTodoCheck:
        snackActions.undoTodoComplete(
          state,
          state.context.dispatch,
          snack.actionParameter
        );
        break;
        case model.SnackCommandAction.restartUpdate:
          window['api'].send("restart_app")
          break;
      default:
        alert("unknown action");
    }
    state.snackMessage = payload ? state.snackMessage : null;
    return prepareState(state);
  }
);
export { ar_snackBarAction };

const ar_snackBarShow = rhelp.CreateQuickReducer1<model.SnackMessage>(
  reducersList,
  "ar_snackBarShow",
  (state: model.IState, payload: model.SnackMessage): model.IState => {
    state.snackMessage = payload;
    return prepareState(state);
  }
);
export { ar_snackBarShow };

const ar_updateSelectedState = rhelp.CreateQuickReducer1<sl.SuperListSelectedState>(
  reducersList,
  "ar_updateSelectedState",
  (state: model.IState, payload: sl.SuperListSelectedState): model.IState => {
    state.selectedState = payload;
    return prepareState(state);
  }
);
export { ar_updateSelectedState };

const ar_searchEnter = rhelp.CreateQuickReducer0<void>(
  reducersList,
  "ar_searchEnter",
  (state: model.IState): model.IState => {
    state.selectedState.focusFirst = true;
    return prepareState(state);
  }
);
export { ar_searchEnter };



const ar_setHotKeyFocus = rhelp.CreateQuickReducer1<string>(
  reducersList,
  "ar_setHotKeyFocus",
  (state: model.IState, payload: string): model.IState => {
    state.hotkey = state.hotkey.deepCopy();
    state.hotkey.setFocus(payload);
    return prepareState(state);
  }
);
export { ar_setHotKeyFocus };

const ar_clearHotKeyFocus = rhelp.CreateQuickReducer1<string>(
  reducersList,
  "ar_clearHotKeyFocus",
  (state: model.IState, payload: string): model.IState => {
    state.hotkey = state.hotkey.deepCopy();
    state.hotkey.clearFocus(payload);
    return prepareState(state);
  }
);
export { ar_clearHotKeyFocus };

const ar_needLogin = rhelp.CreateQuickReducer0(
  reducersList,
  "ar_needLogin",
  (state: model.IState, payload: any): model.IState => {
    state.AuthState = model.AuthState.Unauthenticated;
    return prepareState(state);
  }
);

const ar_clearImagePreview = rhelp.CreateQuickReducer0(
  reducersList,
  "ar_clearImagePreview",
  (state: model.IState, payload: any): model.IState => {
    state.previewImage = null;///
    return prepareState(state);
  }
);
export { ar_clearImagePreview };
const ar_setPreviewImage = rhelp.CreateQuickReducer1<string>(
  reducersList,
  "ar_setPreviewImage",
  (state: model.IState, payload: string): model.IState => {
    state.previewImage = payload;///

    return prepareState(state);
  }
);
export { ar_setPreviewImage };
let ranInit = false
const authUser = rhelp.CreateSimpleActionReducer2<any, any, model.User>(
  reducersList,
  "AUTH_USER",
  (user: any, token: any): model.User => {
    var p = new pApi.Profile();

    window["token"] = "fb:" + token;
    var userObj = new model.User();
    userObj.displayName = user.displayName;
    userObj.email = user.email;
    userObj.token = user.token;
    userObj.photoURL = user.providerData[0].photoURL;
    userObj.meta = user;
    Track.SetUserContext({
      email: user.email,
      id: user.uid,
      username: user.email
    });



    return userObj;
  },
  (newState: model.IState, payload: model.User): model.IState => {

    if (payload) {
      newState.user = payload;
      newState.AuthState = model.AuthState.Authenticated;
      window["user"] = payload;
    } else {
      newState.AuthState = model.AuthState.Unauthenticated;
    }
    if (ranInit) {
      return newState;
    }
    ranInit = true;
    //Setup The Push Client
    function handlePushMessage(payload: any) {
      console.log("PUSH:Handle Message");
      let sn = new model.SnackMessage();
      if (payload.aps) {
        if (payload.aps && payload.aps.alert && payload.aps.alert.data) {
          let onlyFirstHackDontJudge = true;
          for (var i in payload.aps.alert.data) {
            if (onlyFirstHackDontJudge) {
              switch (i) {
                case "openTodo":
                  sn.actionCommand = model.SnackCommandAction.openTodo;
                  sn.actionParameter =
                    payload.aps.alert.data["openTodo"].todoId;
                  sn.actionText = "Open";
                  break;
                case "undoTodoCheck":
                  sn.actionCommand = model.SnackCommandAction.undoTodoCheck;
                  break;
              }

              onlyFirstHackDontJudge = true;
            }
          }

          sn.message = payload.aps.alert.title;
          sn.autoHideDuration = null;
          console.log(JSON.stringify(sn));
          if (payload.tap != 0) {
            newState.context.dispatch(ar_snackBarAction(sn));
          } else {
            newState.context.dispatch(ar_snackBarShow(sn));
          }
        }
        console.log("PUSH:Handle Messsage");
        return;
      }
      if (payload.notification) {
        let parts = null;

        if (payload.notification.action) {
          sn.actionText = payload.notification.actions[0].title;
          parts = payload.notification.actions[0].action.split(":");
        } else if (
          payload.notification.actions &&
          payload.notification.actions.length > 0
        ) {
          sn.actionText = payload.notification.actions[0].title;
          parts = payload.notification.actions[0].action.split(":");
        }

        if (parts) {
          switch (parts[0]) {
            case "openTodo":
              sn.actionCommand = model.SnackCommandAction.openTodo;
              break;
            case "undoTodoCheck":
              sn.actionCommand = model.SnackCommandAction.undoTodoCheck;
              break;
          }
          sn.actionParameter = parts[1];
          sn.actionCommand = model.SnackCommandAction.openTodo;
        }
        sn.message = payload.notification.title;
        sn.autoHideDuration = null;
        if (payload.notification.action) {
          newState.context.dispatch(ar_snackBarAction(sn));
        } else {
          newState.context.dispatch(ar_snackBarShow(sn));
        }
      }

    }
    var loadSettings = async (state: model.IState) => {
      let updateCallback = new pApi.UpdateCallback<pApi.DojoSettings>(
        "loadSettings",
        state.context.dispatch,
        ar_loadSettingsComplete
      );
      let settings = await state.context.Settings.get();
      let redirectToSetup = false;
  
      if (!settings) {
        redirectToSetup = true;
        settings = new pApi.DojoSettings();
      }
      let dummyArray = new Array<pApi.DojoSettings>();
      dummyArray.push(settings);
      state.context.dispatch(ar_loadSettingsComplete(dummyArray));


      //this should trigger an objective reload
    };
    newState.dojoSettingsState = model.LoadingState.Loading;
    newState.canAlwaysTop =  window["electron"]?true:false;
    newState.alwaysTopOn =  false;
    loadSettings(newState);
    var options = {
      bottom: '-64px', // default: '32px'
      right: 'unset', // default: '32px'
      left: '-32px', // default: 'unset'
      time: '0.0s', // default: '0.3s'
      mixColor: '#fff', // default: '#fff'
      backgroundColor: '#fff',  // default: '#fff'
      buttonColorDark: '#100f2c',  // default: '#100f2c'
      buttonColorLight: '#fff', // default: '#fff'
      saveInCookies: true, // default: true,
      label: '', // default: ''
      autoMatchOsTheme: true // default: true

    }

    const darkmode = new Darkmode(options);
    darkmode.showWidget()
    window['darkmode'] = darkmode
    new PushClient(newState.context).startup(handlePushMessage);

    try {

      //this will register and create the hotkey handler
      HotKeyEngine.instance();
    } catch (e) {
      console.error(e);
    }


    setTimeout(() => {
      newState.context.dispatch(
        ar_getSearchKey());
    }, 0)

    setInterval(() => {
      newState.context.dispatch(
        ar_imageCleanup());
    }, 30000);
    setTimeout(() => {

      newState.context.dispatch(
        ar_imageCleanup());
    }, 10000);
    
    setTimeout(() => {
    if(window["electron"])
     { 
        
        window['api'].send('app_version')
        window['api'].receive('update_available', ()=>{
            
            console.log("Update Avaiable")
            let sn = new model.SnackMessage() ;
            sn.message="downloading new version"
            sn.actionCommand = model.SnackCommandAction.updateAvailable
            sn.autoHideDuration = 5000;
            newState.context.dispatch(ar_snackBarShow(sn));
        })
        window['api'].receive('update_downloaded', ()=>{
          
          console.log("Update Avaiable & Downloading")
          let sn = new model.SnackMessage() ;
          sn.message="Update Downloaded."
          sn.actionCommand = model.SnackCommandAction.restartUpdate
          sn.actionText="Install Now"
          sn.actionParameter = ["NOW"]
          sn.autoHideDuration = 10000;
        
          newState.context.dispatch(ar_snackBarShow(sn));
      })
      }
    }, 1000);
    NoteProcessor.StartProcessing(newState.context)

   
    window['onPause'] = async ()=>{
      try
      {
          //await firebase.firestore().disableNetwork();
          console.log('cordova:OnPause - Disconnected Network')
      }
      catch(e)
      {
        console.error ('cordova:OnPause - Error Disconnected Network '+e)
        console.error(e);
      }
    }
    window['onResume'] = async ()=>{
      try
      {
        console.log('cordova:onResume Detected')
          setTimeout(async()=>{
            try{
               // await firebase.firestore().enableNetwork()
                console.log('cordova:onResume enableNetwork')
            }
            catch(e)
            {
              console.error('cordova:onResume error '+e)
              console.error(e);
            }
          },5000)
        
      }
      catch(e)
      {
        console.error(e);
      }
    }
    return prepareState(newState);
  }
);
const ar_imageCleanup = rhelp.CreateQuickReducer0(
  reducersList,
  "ar_imageCleanup",
  (state: model.IState, payload: any): model.IState => {
    state.noteState = payload;
    let mr = new ImageResolver()
    mr.cleanUp(state);
    return state;
  }
);
export function getOverlayStyle() {
  if (window['darkmode'].isActivated()) {
    return {
      backgroundColor: 'rgba(255,255,255,0.67)'
    }
  } else {
    return {};
  }
}

const ar_setNoteLoadingState = rhelp.CreateQuickReducer1<model.LoadingState>(
  reducersList,
  "setNoteLoadingState",
  (state: model.IState, payload: model.LoadingState): model.IState => {
    state.noteState = payload;

    return prepareState(state);
  }
);

export { ar_setNoteLoadingState };
const vibrate = function () {
  console.log("vibrate");
  if (window["vibrate"]) {
    window["vibrate"]();
  }
};
export { vibrate };


const shared_bulkUpdate = function (
  connect: model.ConnectProps,
  todos: Array<pApi.ToDoItem>
): Promise<void> {
  return new Promise<void>(async (resolve, reject) => {
    try {
      await connect.state.context.bulkupdate(
        new Promise<void>(async (resolve, reject) => {
          try {
            for (var i in todos) {
              var t = todos[i];
              await connect.state.context.Todos.save(t);
            }
            resolve();
          }
          catch (e) {
            reject(e);
          }
        })
      )
      resolve();
    }
    catch (e) {
      reject(e);
    }
  })

};
export { shared_bulkUpdate };

const shared_snoozeItems = function (
  prompt: boolean,
  connect: model.ConnectProps,
  todos: Array<pApi.ToDoItem>,
  snoozeAction: pApi.SnoozeAction,
  custom?: Date
): Promise<void> {
  return new Promise<void>(async (resolve, reject) => {
    try {
      await connect.state.context.bulkupdate(
        new Promise<void>(async (resolve, reject) => {
          try {
            for (var i in todos) {
              var t = todos[i];

              await connect.state.context.Todos.snooze(t.id, snoozeAction, custom);
            }
            resolve();
          }
          catch (e) {
            reject(e);
          }
        })

      )
      if (custom) {
        var ls = new model.LastSnooze();
        ls.action = pApi.SnoozeAction.custom;
        ls.date = custom;
        connect.dispatch<any>(ar_UpdateLastSnoozed(ls));
      }
      resolve()
    }
    catch (e) {
      reject(e)
    }

  });
};
export { shared_snoozeItems };

const shared_starOrUnstarItems = function (
  connect: model.ConnectProps,
  todos: Array<pApi.ToDoItem>
): Promise<void> {
  return new Promise<void>(async (resolve, reject) => {
    try {
      //connect.state.context.Todos.startBatch();
      let unstar =
        todos.filter(x => x.priority == pApi.ToDoItemPriority.High).length ==
        todos.length;
      if (unstar) {
        for (var i in todos) {
          todos[i].priority = pApi.ToDoItemPriority.Normal;
        }

        await shared_bulkUpdate(connect, todos);
        resolve();
      } else {
        for (var i in todos) {
          todos[i].priority = pApi.ToDoItemPriority.High;
        }
        await shared_bulkUpdate(connect, todos);
      }

      resolve();
    } catch (e) {
      console.log(e);

      reject(e);
      // await connect.state.context.Todos.cancelBatch();
    }
  });
};
export { shared_starOrUnstarItems };

const shared_hideItems = function (
  connect: model.ConnectProps,
  todos: Array<pApi.ToDoItem>,
  force: boolean = false
): Promise<void> {
  if (window.confirm(`Are you sure you want to hide ${todos.length} to-do${todos.length > 1 ? 's' : ''} from your inbox?  They will still show up if you search or view by tags.`)) {
    return new Promise<void>(async (resolve, reject) => {
      try {
        //connect.state.context.Todos.startBatch();
        if (force) {
          for (var i in todos) {
            todos[i].priority = pApi.ToDoItemPriority.Normal;
            todos[i].due_date = null;
          }

        }
        let hideItems =
          todos.filter(x => !x.hide_from_inbox && pApi.CanHideToDo(x));

        for (var i in hideItems) {
          hideItems[i].hide_from_inbox = true;
        }

        await shared_bulkUpdate(connect, hideItems);
        resolve();

      } catch (e) {
        console.log(e);

        reject(e);
        // await connect.state.context.Todos.cancelBatch();
      }
    });
  }
  return null;
};
export { shared_hideItems };
const shared_unHideItems = function (
  connect: model.ConnectProps,
  todos: Array<pApi.ToDoItem>
): Promise<void> {
  return new Promise<void>(async (resolve, reject) => {
    try {
      //connect.state.context.Todos.startBatch();
      let showItems =
        todos.filter(x => x.hide_from_inbox);

      for (var i in showItems) {
        showItems[i].hide_from_inbox = false;
      }

      await shared_bulkUpdate(connect, showItems);
      resolve();

    } catch (e) {
      console.log(e);

      reject(e);
      // await connect.state.context.Todos.cancelBatch();
    }
  });
};
export { shared_unHideItems };

const share_completeItems = function (
  prompt: boolean,
  state: model.IState,
  todos: Array<pApi.ToDoItem>
): Promise<void> {
  if (
    !prompt ||
    todos.length == 1 ||
    window.confirm(`Are you sure you want to complete ${todos.length} to-dos?`)
  ) {
   
    return new Promise<void>(async (resolve, reject) => {
      await state.context.bulkupdate(
        new Promise<void>(async (resolve, reject) => {
          try {
            let undo = new Array<any>();
            for (var i in todos) {
              var t = todos[i];
              await state.context.Todos.complete(t.id);
              undo.push(t.id);
            }
            if (undo.length > 0) {
              state.context.dispatch(ar_showUndoTodoCheckSnackBar(undo));
            }
            resolve();
          }
          catch (e) {
            reject(e);
          }
        })
      )
      resolve();



    });
  }
  return null;
};
export { share_completeItems };

const ar_showUndoTodoCheckSnackBar = rhelp.CreateQuickReducer1<Array<any>>(
  reducersList,
  "ar_showUndoTodoCheckSnackBar",
  (state: model.IState, payload: Array<any>): model.IState => {
    let snackMessage = new model.SnackMessage();
    snackMessage.message =
      payload.length == 1
        ? "completed todo"
        : "completed " + payload.length + " todos";
    snackMessage.actionText = "undo";
    snackMessage.actionCommand = model.SnackCommandAction.undoTodoCheck;
    snackMessage.actionParameter = payload;
    snackMessage.autoHideDuration = payload.length == 0 ? 3000 : 5000;
    state.snackMessage = snackMessage;
    return prepareState(state);
  }
);
export { ar_showUndoTodoCheckSnackBar };


const closeTodoModal = rhelp.CreateQuickReducer0<void>(
  reducersList,
  "CLOSETODOMODAL",
  (state: model.IState, payload: any): model.IState => {
    state.editTodo.reset()
    return prepareState(state);
  }
);

export { closeTodoModal };

const ar_showErrorModal = rhelp.CreateQuickReducer0<void>(
  reducersList,
  "ar_showErrorModal",
  (state: model.IState, payload: any): model.IState => {
    state.errorShow = true;
    return state;
  }
);

export { ar_showErrorModal };

const ar_closeErrorModal = rhelp.CreateQuickReducer0<void>(
  reducersList,
  "ar_closeErrorModal",
  (state: model.IState, payload: any): model.IState => {
    state.errorShow = false;
    state.lastError.showModal = false;
    return state;
  }
);

export { ar_closeErrorModal };

const ar_windowResize = rhelp.CreateQuickReducer0<void>(
  reducersList,
  "ar_windowResize",
  (state: model.IState, payload: any): model.IState => {
    state.windowHeight = window.innerHeight;

    return prepareState(state);
  }
);
export { ar_windowResize };

const ar_setError = rhelp.CreateQuickReducer1<ErrorObj>(
  reducersList,
  "ar_setError",
  (state: model.IState, payload: ErrorObj): model.IState => {
    state.errorArray.push(payload);
    state.lastError = payload;
    return state;
  }
);
export { ar_setError };
Track.Init(ar_setError);
if (!window["ios"]) {
  Track.SetDimension({ platform: "ios" });
} else {
  Track.SetDimension({ platform: "web" });
}

const hideCheckInModal = rhelp.CreateQuickReducer0<boolean>(
  reducersList,
  "hideCheckInModal",
  (state: model.IState, payload: any): model.IState => {
    state.checkInModal = false;
    return prepareState(state);
  }
);
export { hideCheckInModal };

const ar_setProgress = rhelp.CreateQuickReducer1<model.ProgressState>(
  reducersList,
  "ar_setProgress",
  (state: model.IState, payload: model.ProgressState): model.IState => {
    state.progressState = payload;
    return prepareState(state);
  }
);
export { ar_setProgress };
//#endregion
///

const ar_todoLoadAndOpen = rhelp.CreateQuickReducer1<any>(
  reducersList,
  "ar_todoLoadAndOpen",
  (state: model.IState, id: any): model.IState => {
   
    var f = async function (state: model.IState) {
      try {
       
        let todo = await state.context.Todos.load(id);
        state.context.dispatch(showTodoModal(todo));
      } catch (e) {
        Track.reportError(e, e, true, state.context.dispatch);
      }
    };
    let todo = state.todos.find(x => x.id == id);

    if (!todo) {
      state.editTodo.setLoading();
      f(state);
    }
    else {
      state.editTodo.setCompleted(todo)
    }
    return state;
  }
);
export { ar_todoLoadAndOpen };
const showTodoModal = rhelp.CreateQuickReducer1<pApi.ToDoItem>(
  reducersList,
  "editTodo",
  (state: model.IState, todo?: pApi.ToDoItem): model.IState => {

    if (!todo) {
      todo = new pApi.ToDoItem();
      //todo.id = -1;
      todo.is_new = true;
      todo.checked = false;
      todo.description = "";
      todo.priority = 0;
      todo.objective_id = null;
    }
;
    state.editTodo.setCompleted(todo);

    return state;
  }
);
export { showTodoModal };

const ar_checkIn = rhelp.CreateQuickReducer1<pApi.ObjectiveCheckIn>(
  reducersList,
  "ar_checkIn",
  (state: model.IState, payload: pApi.ObjectiveCheckIn): model.IState => {
    var f = async (state: model.IState, payload: pApi.ObjectiveCheckIn) => {
      await state.context.Objectives.checkIn(payload.objective_id, payload);
      //this should trigger an objective reload
    };
    state.checkInModal = false;

    f(state, payload);
    return prepareState(state);
  }
);
export { ar_checkIn };

const ar_getSearchKey = rhelp.CreateQuickReducer0(
  reducersList,
  "ar_getSearchKey",
  (state: model.IState, payload: any): model.IState => {

    var f = async (state: model.IState, payload: any) => {
      try {

        var result = await qGet("/api/v1/searchKey");
        localStorage.setItem("searchKey", result.searchKey);
        state.context.dispatch(ar_getSearchKeyDone(result.searchKey));
      } catch (e) {

        Track.reportError(e, "Error getting search key", false);
        state.context.dispatch(
          ar_getSearchKeyDone(localStorage.getItem("searchKey"))
        );
      }
      //this should trigger an objective reload
    };
    state.searchKeyState = model.LoadingState.Loading;

    f(state, payload);
    return prepareState(state);
  }
);
export { ar_getSearchKey };
const ar_sendMessage = rhelp.CreateQuickReducer1(
  reducersList,
  "ar_sendMessage",
  (state: model.IState, payload: any): model.IState => {
    var f = async (state: model.IState, payload: any) => {
      try {
        var result = await qPost("/api/help/email", payload);
      } catch (e) {
        Track.reportError(
          e,
          "Error sending message " + e,
          true,
          state.context.dispatch
        );
      }
      //this should trigger an objective reload
    };

    f(state, payload);
    return prepareState(state);
  }
);
export { ar_sendMessage };
const ar_getSearchKeyDone = rhelp.CreateQuickReducer1<string>(
  reducersList,
  "ar_getSearchKeyDone",
  (state: model.IState, payload: string): model.IState => {
    state.searchKeyState = payload
      ? model.LoadingState.Completed
      : model.LoadingState.Error;
    state.searchKey = payload;

    return prepareState(state);
  }
);
export { ar_getSearchKeyDone };
const ar_search = rhelp.CreateQuickReducer1<string>(
  reducersList,
  "ar_search",
  (state: model.IState, payload: string): model.IState => {
    if (!state.searchText) {
      //state.viewState.appSection = model.AppSections.search;
      //state.TodosState = model.LoadingState.NotLoaded;
    }
    state.searchText = payload;
    return state;
  }
);
export { ar_search };
///
const ar_loadCheckInHistory = rhelp.CreateQuickReducer1<any>(
  reducersList,
  "ar_loadCheckInHistory",
  (state: model.IState, payload: any): model.IState => {
    let f = async function (state: model.IState, id: any) {
      var r = await state.context.Objectives.getCheckInHistory(id, null);
      var history = new model.ObjectiveHistory();
      history.since = null;
      history.objective_id = payload;
      history.objectiveCheckin = r;
      state.context.dispatch(loadedHistoryComplete(history));
    };
    f(state, payload);
    return prepareState(state);
  }
);
export { ar_loadCheckInHistory };
var loadedHistoryComplete = rhelp.CreateQuickReducer1<model.ObjectiveHistory>(
  reducersList,
  "loadedHistoryComplete",
  (state: model.IState, payload: model.ObjectiveHistory): model.IState => {
    var newState = { ...state };
    var history = { ...state.objectiveHistory };
    if (!history) {
      history = payload;
    } else {
      if (payload.objective_id == history.objective_id) {
        for (var i in state.objectiveHistory.objectiveCheckin) {
          var e = payload.objectiveCheckin.find(
            x =>
              x.check_in_date ==
              state.objectiveHistory.objectiveCheckin[i].check_in_date
          );
          if (e) {
            payload.objectiveCheckin.push(
              state.objectiveHistory.objectiveCheckin[i]
            );
          }
        }
        history = payload;
      } else {
        history = payload;
      }
    }

    newState.objectiveHistory = { ...history };
    return newState;
  }
);

function convertTodos(
  data: Array<any>,
  loadingState?: model.LoadingState
): Array<pApi.ToDoItem> {
  var retval = new Array<pApi.ToDoItem>();
  for (var i in data) {
    retval.push(convertTodo(data[i], loadingState));
  }
  return retval;
}
function convertTodo(
  todo: any,
  loadingState?: model.LoadingState,
  errorMessage?: string
): pApi.ToDoItem {
  var td = todo as pApi.ToDoItem;
  if (loadingState) {
    /*  var ref = td.state ? td.state.ref : null;
    td.state = new model.EntityState(loadingState, todo, errorMessage);
    if (ref) td.state.ref = ref;*/
  }
  td.due_date = td.due_date ? new Date(td.due_date) : null;
  return td;
}

function buffer(func, wait, scope) {
  var timer = null;
  return function () {
    let time = new Date().getTime()
    console.log('buffer ' + time)
    console.log(arguments)
    if (timer) clearTimeout(timer);
    var args = arguments;
    timer = setTimeout(function () {
      console.log('buffer call' + time)

      timer = null;
      func.apply(scope, args);
    }, wait);
  };
};
var timer = null;
function bufferPromise(func, wait, scope): Promise<any> {

  return new Promise<any>((resolve, reject) => {
    let time = new Date().getTime()
    console.log('buffer ' + time)
    console.log(arguments)
    if (timer) clearTimeout(timer);
    var args = arguments;
    timer = setTimeout(function () {
      console.log('buffer call' + time)
      timer = null;
      resolve(func.apply(scope, args))
    }, wait);
  })
}
let debouceUpdate = function (dispatch: any) {
  let lauchUpdate = function (todos: Array<pApi.ToDoItem>) {

    console.log("Debouce Update Callback " + new Date())
   
    dispatch(ar_loadTodoComplete(todos))
  }
  return buffer(lauchUpdate, 250, this);
}
let loadInbox = async function (state: model.IState) {

  let updateCallback = new pApi.UpdateCallback<pApi.ToDoItem>(
    "grid",
    state.context.dispatch,
    debouceUpdate(state.context.dispatch)
  );
  var results = await state.context.Todos.getAllOutstandingItems(
    updateCallback
  );
  state.context.dispatch(ar_loadTodoComplete(results as Array<pApi.ToDoItem>));
};
var ar_loadInbox = rhelp.CreateQuickReducer(
  reducersList,
  "ar_loadInbox",
  (state: model.IState): model.IState => {
    console.log("get tags");

    loadInbox(state);
    state.TodosState = model.LoadingState.Loading;
    return prepareState(state);
  }
);
export { ar_loadInbox };

var ar_loadNotes = rhelp.CreateQuickReducer(
  reducersList,
  "ar_loadNotes",
  (state: model.IState): model.IState => {
    console.log("get notes");
    state.notes.setLoading();
    let f = async function () {
      try {
        let lauchUpdate = function (notes: Array<pApi.Note>) {
          state.context.dispatch(ar_LoadNoteComplete(notes))
        }
        let debouceUpdate = buffer(lauchUpdate, 250, this);

        let notes = await state.context.Notes.list(debouceUpdate)
        state.context.dispatch(ar_LoadNoteComplete(notes))
      }
      catch (e) {

      }
    }
    f();
    state.TodosState = model.LoadingState.Loading;
    return prepareState(state);
  }
);
export { ar_loadNotes };

var ar_LoadNoteComplete = rhelp.CreateQuickReducer1<Array<pApi.Note>>(
  reducersList,
  "ar_LoadNoteComplete",
  (state: model.IState, payload: Array<pApi.Note>): model.IState => {

    state.notes.setCompleted(payload);
    return prepareState(state);
  }
);
export { ar_LoadNoteComplete };

var ar_loadSnooze = rhelp.CreateQuickReducer(
  reducersList,
  "ar_loadSnooze",
  (state: model.IState): model.IState => {
    console.log("get tags.......");
    var f = async (state: model.IState) => {
      let updateCallback = new pApi.UpdateCallback<pApi.ToDoItem>(
        "grid",
        state.context.dispatch,
        debouceUpdate(state.context.dispatch)
      );
      var results = await state.context.Todos.getSnoozedItems(updateCallback);
      state.context.dispatch(
        ar_loadTodoComplete(results as Array<pApi.ToDoItem>)
      );
    };

    f(state);
    state.TodosState = model.LoadingState.Loading;
    return prepareState(state);
  }
);
export { ar_loadSnooze };

var ar_loadClosed = rhelp.CreateQuickReducer(
  reducersList,
  "ar_loadClosed",
  (state: model.IState): model.IState => {
    var f = async (state: model.IState) => {
      let updateCallback = new pApi.UpdateCallback<pApi.ToDoItem>(
        "grid",
        state.context.dispatch,
        debouceUpdate(state.context.dispatch)
      );
      var results = await state.context.Todos.getClosedItems(0, updateCallback);
      state.context.dispatch(
        ar_loadTodoComplete(results as Array<pApi.ToDoItem>)
      );
    };
    f(state);
    state.TodosState = model.LoadingState.Loading;
    return prepareState(state);
  }
);
export { ar_loadClosed };

var ar_loadItemsByTag = rhelp.CreateQuickReducer1<string>(
  reducersList,
  "ar_loadItemsByTag",
  (state: model.IState, payload: string): model.IState => {
    console.log("get tags..");
    var f = async (state: model.IState) => {
      let updateCallback = new pApi.UpdateCallback<pApi.ToDoItem>(
        "grid",
        state.context.dispatch,
        debouceUpdate(state.context.dispatch)
      );
      var results = await state.context.Todos.listByTag(
        payload,
        updateCallback
      );
      state.context.dispatch(
        ar_loadTodoComplete(results as Array<pApi.ToDoItem>)
      );
    };
    f(state);
    state.TodosState = model.LoadingState.Loading;
    return prepareState(state);
  }
);
export { ar_loadItemsByTag };

var ar_loadTodoComplete = rhelp.CreateQuickReducer1<Array<pApi.ToDoItem>>(
  reducersList,
  "ar_loadTodoComplete",
  (state: model.IState, payload: Array<pApi.ToDoItem>): model.IState => {
    state.TodosState = model.LoadingState.Completed;
 
    state.todoQueryId = new Date().toISOString();
    state.todos = payload;
    if (window["cordova"]) {
      try {
        let count = payload.filter(
          todo =>
            !todo.checked &&
            ((!todo.due_date && todo.hide_from_inbox != true) ||
              (todo.priority == 1 &&
                (!todo.due_date || todo.due_date < new Date())) ||
              (todo.due_date && todo.due_date < new Date()))
        ).length;

        // @ts-ignore
        window["cordova"].plugins.notification.badge.set(count);

        console.log("set badge " + count);
      } catch (e) {
        console.error("error updating badge", e);
      }
    }

   

    return prepareState(state);
  }
);
export { ar_loadTodoComplete };
var ar_signout = rhelp.CreateQuickReducer0(
  reducersList,
  "ar_signout",
  (state: model.IState, payload: any): model.IState => {
    function clearFireStoreDB(databaseName: string) {
      var req = indexedDB.deleteDatabase(databaseName);
      req.onsuccess = function () {
        console.log("Deleted database successfully");
      };
      req.onerror = function () {
        console.error("Couldn't delete database");
      };
      req.onblocked = function () {
        console.error(
          "Couldn't delete database due to the operation being blocked"
        );
      };
    }

    firebase.auth().signOut();
    localStorage.clear();
    clearFireStoreDB("firestore/[DEFAULT]/botdojo-2dd70/main");
    clearFireStoreDB("firebaseLocalStorageDb");
    let vs = new model.ViewState();
    vs.appSection = model.AppSections.login;
    window.location.hash = vs.getHash();
    ImgCache.clearCache(() => {
      //window.location.reload();
      window['tz_Reload']();
    },
      (err) => {
        //window.location.reload();
        window['tz_Reload']();
        console.log('ImgCache : Error clearing cache' + err)
      })

    state = { ...initialState };
    state.uiConifg.layout.navCollapsed = false;


    return state;
  }
);
export { ar_signout };

var ar_loadTags = rhelp.CreateQuickReducer0<any>(
  reducersList,
  "loadTags",
  (state: model.IState, payload: any): model.IState => {
    console.log("get tags");
    let f = async (state: model.IState) => {
      let updateCallback = new pApi.UpdateCallback<pApi.Tag>(
        "grid",
        state.context.dispatch,
        ar_tagsUpdated
      );
      var tags = await state.context.Tags.getTags(updateCallback);
      /*  for (var i in tags) {
       //   console.log("TAG: " + tags[i].id + " [" + tags[i].name + "]");
        }*/

      state.context.dispatch(ar_tagsUpdated(tags));
    };
    f(state);
    state.TagsState = model.LoadingState.Loading;
    return prepareState(state);
  }
);
export { ar_loadTags };

var ar_tagsUpdated = rhelp.CreateQuickReducer1<Array<pApi.Tag>>(
  reducersList,
  "tagsUpdated",
  (state: model.IState, payload: Array<pApi.Tag>): model.IState => {
    var newState = { ...state };
    newState.tags = payload;
    newState.TagsState = model.LoadingState.Completed;
    return newState;
  }
);
export { ar_tagsUpdated };

var ar_setScrollingTarget = rhelp.CreateQuickReducer1<any>(
  reducersList,
  "ar_setScrollingTarget",
  (state: model.IState, payload: any): model.IState => {
    var newState = { ...state };
    newState.scrollingTarget = payload;

    return newState;
  }
);
export { ar_setScrollingTarget };

var ar_notesLoaded = rhelp.CreateQuickReducer1<Array<pApi.Note>>(
  reducersList,
  "ar_notesLoaded",
  (state: model.IState, payload: Array<pApi.Note>): model.IState => {
    var newState = { ...state };

    newState.notes.setCompleted(payload);

    return newState;
  }
);
export { ar_notesLoaded };
var ar_loadObjectives = rhelp.CreateQuickReducer1<boolean>(
  reducersList,
  "loadObjectiveList",
  (state: model.IState, payload: boolean): model.IState => {
    state.objectiveListState = model.LoadingState.Loading;
    var f = async (state: model.IState) => {
      {
        let s = new pApi.UpdateCallback<pApi.Objective>(
          "objective-list",
          state.context.dispatch,
          objectivesLoaded
        );
        let r = await state.context.Objectives.list(payload, s);

        state.context.dispatch(objectivesLoaded(r));
      }
    };
    f(state);

    return state;
  }
);
export { ar_loadObjectives };
var objectivesLoaded = rhelp.CreateQuickReducer1<Array<pApi.Objective>>(
  reducersList,
  "objectivesLoaded",
  (state: model.IState, payload: Array<pApi.Objective>): model.IState => {
    var newState = { ...state };
    newState.objectiveListState = model.LoadingState.Completed;
    newState.objectives = payload;
    return newState;
  }
);

var ar_saveObjective = rhelp.CreateQuickReducer1<pApi.Objective>(
  reducersList,
  "ar_saveObjective",

  (state: model.IState, payload: pApi.Objective): model.IState => {
    let f = async function (state: model.IState, payload: pApi.Objective) {
      await state.context.Objectives.save(payload);
      //this should update the list
    };
    f(state, payload);
    state.currentObjective = payload;

    state.objectiveModal = false;

    return state;
  }
);
export { ar_saveObjective };

var objectiveSaveComplete = rhelp.CreateQuickReducer1<pApi.Objective>(
  reducersList,
  "objectiveSaveComplete",
  (state: model.IState, payload: pApi.Objective): model.IState => {
    state.currentObjective = payload;
    state.objectives = state.objectives.map(obj =>
      obj.id === payload.id ? { ...payload } : obj
    );
    state.objectiveModal = false;
    return state;
  }
);

///
const handleToggleCollapsedNav = rhelp.CreateQuickReducer1<boolean>(
  reducersList,
  "handleToggleCollapsedNav",
  (state: model.IState, value: boolean): model.IState => {
  
    state.uiConifg.layout.navCollapsed = value;

    return prepareState(state);
  }
);
export { handleToggleCollapsedNav };
const ar_UpdateLastSnoozed = rhelp.CreateQuickReducer1<model.LastSnooze>(
  reducersList,
  "ar_UpdateLastSnoozed",
  (state: model.IState, lastSnooze: model.LastSnooze): model.IState => {
    state.lastSnooze = lastSnooze;
    return prepareState(state);
  }
);
export { ar_UpdateLastSnoozed };
const ar_AddTodo = rhelp.CreateQuickReducer1<pApi.ToDoItem>(
  reducersList,
  "ar_AddTodo",
  (state: model.IState, updatedTodo: pApi.ToDoItem): model.IState => {
    state.todos = [...state.todos, updatedTodo];
    return state;
  }
);

export { ar_AddTodo };

const ar_turnOnTop = rhelp.CreateQuickReducer1<pApi.ToDoItem>(
  reducersList,
  "ar_turnOnTop",
  (state: model.IState, updatedTodo: pApi.ToDoItem): model.IState => {
    state.alwaysTopOn=true;
    window['api']?window['api'].send('tz_alwaysTopOn', ''):console.error("always on Top Toggle called but not available for the env");

    return state;
  }
);

export { ar_turnOnTop };
const ar_turnOffTop = rhelp.CreateQuickReducer1<pApi.ToDoItem>(
  reducersList,
  "ar_turnOffTop",
  (state: model.IState, updatedTodo: pApi.ToDoItem): model.IState => {
    state.alwaysTopOn=false;
    window['api']?window['api'].send('tz_alwaysTopOff', ''):console.error("always on Top Toggle called but not available for the env");

    return state;
  }
);

export { ar_turnOffTop };




var reducer = handleActions<model.IState, any>(reducersList, initialState);

export { reducer };
