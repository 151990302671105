import * as React from "react";
import NavigationClose from "material-ui/svg-icons/navigation/close";
import LayoutIcon from "material-ui/svg-icons/action/chrome-reader-mode";
import IconButton from "material-ui/IconButton";
import * as material from "material-ui";
import { Card, CardActions, CardHeader, CardText } from "material-ui/Card";
import FlatButton from "material-ui/FlatButton";
import * as model  from "../../lib/model";
import * as actions  from "../../lib/ActionReducer";

import * as pApi from "../../../shared/papi/papi-core";

import moment from 'moment'

var uuid = require("uuid");

export class ProgressProps { 
  connect: model.ConnectProps;
}
export class ProgressState {

}
export class Progress extends React.Component<ProgressProps, ProgressState> {
  constructor(props, context) {
    super(props, context);
    
  }
  
  
  render() {
    if (!this.props.connect.state.progressState) {
      return <div />;
    }
   
  
    
    return (
      <div key={"modal-progress"}>
       
            <material.Dialog
              title={this.props.connect.state.progressState.text}
              ref="dialog"
              overlayStyle={actions.getOverlayStyle()}
              modal
              contentStyle={{
                width: "50%",
                maxWidth: "none"
              }}
              open={this.props.connect.state.progressState?true:false}
              autoScrollBodyContent={true}
            >
                  <material.LinearProgress mode="determinate" value={this.props.connect.state.progressState.progress} />
            </material.Dialog>
          
      </div>
    );
  }
}
export default Progress;
