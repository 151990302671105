import * as P from "parchment";



import * as pApi from "../../../shared/papi/papi-core";
import * as model from "../../lib/model"
import * as actions from "../../lib/ActionReducer";


import { Track } from "../../../shared/track";
import { sanitize } from "quill/formats/link";
import { ImageHandler, NoteImage, ImageUploadTracker, ImageUploadTrackerEnum } from "./ImageHandler";
const Quill = require("quill");
var $ = require("jquery");

//import { default } from '../../../themes/lightTheme';
var ImgCache = require("hacked-imgcache");
////
//const { Quill, Mixin, Toolbar } = RQ;

let uuidV4 = require("uuid/v4");
let Block = Quill.import("blots/block");
let Container = Quill.import("blots/container");
let Parchment = Quill.import("parchment");
class ListItem extends Block {
  static formats(domNode) {
    return domNode.tagName === this.tagName
      ? undefined
      : super.formats(domNode);
  }
  static create(value) {
    let tagName = "LI";
    let node = super.create(tagName);
    return node;
    /*
   
    if(!value)
    {
      node.setAttribute("data-checked", false)
      node.setAttribute("data-id","newx:"+Math.floor(Math.random() * 1000));
    }
    else if (value["id"]) {
      node.setAttribute("data-checked", value["checked"]);
      node.setAttribute("data-id", value["id"]);
    }
    return node;*/
  }
  clone(obj) {
    var obj = super.clone(obj);
    //obj.domNode.setAttribute('data-id',"newx:"+(Math.floor(Math.random() * 1000)));
    //obj.domNode.setAttribute('data-checked', false);//;//..
    return obj;
  }
  format(name, value) {
    //console.log("format value" + name + " " + JSON.stringify(value));
    if (name === List.blotName && !value) {
      //added null

      this.replaceWith(Parchment.create(this.statics.scope), null);
      //this.replaceWith(ListItem.create(this.statics.scope),value);
    } else {
      super.format(name, value);
    }
  }

  remove() {
    if (this.prev == null && this.next == null) {
      this.parent.remove();
    } else {
      super.remove();
    }
  }

  replaceWith(name, value) {
    this.parent.isolate(this.offset(this.parent), this.length());
    if (name === this.parent.statics.blotName) {
      this.parent.replaceWith(name, value);
      return this;
    } else {
      this.parent.unwrap();
      return super.replaceWith(name, value);
    }
  }
}
ListItem.blotName = "list-item";
ListItem.tagName = "LI";
window["checkHover"] = (target: any) => {
  // let pos = $(target).position();
  var offset = $(target).offset();
  var posY = offset.top - $(window).scrollTop();
  var posX = offset.left - $(window).scrollLeft();

  $("#todo-helper").css({ "top": posY + 5, "left": posX - 55 });
 
  ;
  //$(target).appendChild($(target))
  $("#todo-helper").attr("data-id", target.attributes.getNamedItem("data-id").value);
  $("#todo-helper").show();
  setTimeout(()=>{
    $("#todo-helper").hide();
  },6000)

  //  alert("hey")
}
class List extends Container {
  static create(value) {
    let tagName = value === "ordered" ? "OL" : "UL";
    let node = super.create(tagName);

    if (value === "checked" || value === "unchecked") {
      node.setAttribute("data-checked", value === "checked" ? true : false);
      node.setAttribute("data-id", pApi.newId()); //":"+Math.floor(Math.random() * 1000) );"
    } else if (value["id"]) {
      node.setAttribute("data-checked", value["checked"]);
      node.setAttribute("data-id", value["id"]);
      node.setAttribute("data-mode", value["mode"]);
      node.setAttribute("data-priority", value["priority"]);
      node.setAttribute("data-sync-id", value["sync-id"]);
      node.setAttribute("data-tags", value["tags"]);
     
      node.setAttribute("onmouseover", 'window["checkHover"](this)');
      
      if (value["id"].toString().indexOf("new") == -1) {
        node.style.fontWeight = 400;
      } else {
        node.setAttribute("data-mode", "loading");
       // before = "loading..."
        node.style.fontWeight = 100;
      }
      
    }
    /*
      if (value === 'checked' || value === 'unchecked') {
        node.setAttribute('data-checked', value === 'checked');
        node.setAttribute('data-id', 'new');
      }*/

    return node;
  }

  static formats(domNode) {
    if (domNode.tagName === "OL") return "ordered";
    if (domNode.tagName === "UL") {
      if (domNode.hasAttribute("data-id")) {
        return {
          mode: domNode.getAttribute("data-mode"),
          priority: domNode.getAttribute("data-priority"),
          checked:
            domNode.getAttribute("data-checked") === "true" ? true : false,
          id: domNode.getAttribute("data-id")
            ? domNode.getAttribute("data-id")
            : pApi.newId()
        };
      }
      if (domNode.hasAttribute("data-checked")) {
        return {
          checked:
            domNode.getAttribute("data-checked") === "true" ? true : false,
          id: domNode.getAttribute("data-id")
            ? domNode.getAttribute("data-id")
            : pApi.newId()
        };

        // domNode.getAttribute('data-checked') === 'true' ? 'checked' : 'unchecked';
      } else {
        return "bullet";
      }
    }
    return undefined;
  }

  constructor(domNode) {
    // @ts-ignore

    super(domNode);
    const listEventHandler = e => {
      console.log("listitem click");
      if (e.target.parentNode !== domNode) return;

      let format = this.statics.formats(domNode);

      let blot = Parchment.find(e.target);
      console.log("listitem click MORE");
      console.log(format);
      if (format === "checked") {
        //blot.format('list', 'unchecked');
        blot.format("list", { checked: false, id: "new", mode: "loading" });
        e.preventDefault();
        e.stopPropagation();
      } else if (format === "unchecked") {
        //blot.format('list', 'checked');
        blot.format("list", { checked: false, id: "new", mode: "loading" });
        e.preventDefault();
        e.stopPropagation();
      } else if (format["id"]) {
        //blot.format('list', 'unchecked');
        blot.format("list", {
          checked: !format["checked"],
          id: format["id"],
          mode: format["mode"],
          tags: format["tags"]
        });
        e.preventDefault();
        e.stopPropagation();
      }
    };

    domNode.addEventListener("touchstart", e => {
      console.log("touchstart");
      listEventHandler(e);
    });
    domNode.addEventListener("mousedown", e => {
      console.log("mousedown");
      listEventHandler(e);
    });
  }

  format(name, value) {
    if (this.children.length > 0) {
      this.children.tail.format(name, value);
    }
  }

  formats() {
    // We don't inherit from FormatBlot

    return { [this.statics.blotName]: this.statics.formats(this.domNode) };
  }
  clone(obj) {
    var obj = super.clone(obj);
    if (
      obj.domNode &&
      obj.domNode.getAttribute &&
      obj.domNode.getAttribute("data-id")
    ) {
      //obj.domNode.setAttribute('data-id',"new:"+(Math.floor(Math.random() * 1000)));
      obj.domNode.setAttribute("data-id", pApi.newId());
      obj.domNode.setAttribute("data-checked", false); //;//..
      obj.domNode.setAttribute("data-priority", 0);
      obj.domNode.setAttribute("data-mode", "none");
    }

    return obj;
  }
  insertBefore(blot, ref) {
    if (blot instanceof ListItem) {
      super.insertBefore(blot, ref);
    } else {
      let index = ref == null ? this.length() : ref.offset(this);
      let after = this.split(index);
      after.parent.insertBefore(blot, after);
    }
  }
  /*
  split(index: number, force?: boolean):any
  {
     
     var s = super.split(index,force);
     s.domNode.attributes.removeNamedItem('data-checked')
     s.domNode.attributes.removeNamedItem('data-id')
    
      return s;
  }*/

  optimize(context) {
    //return super.optimize(context);
    /*

    let next = this.next;
    if (
      next != null &&
      next.prev === this &&
      next.statics.blotName === this.statics.blotName &&
      next.domNode.tagName === this.domNode.tagName  &&
      (
        !(next.prev && next.prev.getAttribute && !next.prev.getAttribute("data-id")) ||
        !next.domNode.getAttribute("data-id")
      )
    ) {
      
      next.moveChildren(this);
      next.remove();
    }
    return;*/

    super.optimize(context);

    let next = this.next;
    if (
      next != null &&
      next.prev === this &&
      next.statics.blotName === this.statics.blotName &&
      next.domNode.tagName === this.domNode.tagName &&
      !next.domNode.getAttribute("data-checked") &&
      !this.domNode.getAttribute("data-checked")
    ) {
      next.moveChildren(this);
      next.remove();
    }
    /*
    let next = this.next;
    if (
      next != null &&
      next.prev === this &&
      next.statics.blotName === this.statics.blotName &&
      next.domNode.tagName === this.domNode.tagName &&
      next.domNode.getAttribute("data-checked") ===
        this.domNode.getAttribute("data-checked")
    ) {
      next.moveChildren(this);
      next.remove();
    }*/
  }

  replace(target) {
    if (target.statics.blotName !== this.statics.blotName) {
      let item = Parchment.create(this.statics.defaultChild);
      target.moveChildren(item);
      this.appendChild(item);
    }
    super.replace(target);
  }
}
List.blotName = "list";
List.scope = Parchment.Scope.BLOCK_BLOT;
List.tagName = ["OL", "UL"];
List.defaultChild = "list-item";
List.allowedChildren = [ListItem];

//export { ListItem, List as default };

const ATTRIBUTES = ["alt", "height", "width"];
if (!window["imageCache"]) window["imageCache"] = {};

/*
class Image extends Parchment.Embed {
  static create(value) {

    let parent = super.create(value);
    let node = document.createElement("IMG");
    parent.appendChild(node);

    var me = this;
    var obj = {};
    if (typeof value === "string") {
      if (value.indexOf("filesystem") == 0) {
        obj["internal-src"] = value;
      } else {
        var sanvalue = this.sanitize(value);
        obj["external-src"] = sanvalue;
      }
    } else obj = value;
    let setSize = function (node: any) {
      // if(obj["external-src"] != obj['src'])
      //{
      node.setAttribute("onerror", 'window["imageCacheLoadError"](this)');
      //}

    
    };
    console.log("ImgCache:Set " + JSON.stringify(obj));
    parent.setAttribute("external-src", obj["external-src"]);
    parent.setAttribute("internal-src", obj["internal-src"]);
    parent.setAttribute("class", "note-image darkmode-ignore");

    if (!obj["image-id"]) {
      obj["image-id"] = "unknown/" + uuidV4();
    }
    let imageId = obj["image-id"];
    parent.setAttribute("image-id", obj["image-id"]);
    let progressBarHtml = `<div image-id="${imageId}" class="image-progress-container" style='display:none'>
            <div image-id="${imageId}"  class="image-progress-container-percentage" style="height:24px;width: 0%;transition: all 300ms;transition 2s: unset;"></div>
          <div image-id="${imageId}" class="image-progress-status"></div>
            </div>`;

    var progressBarDiv = document.createElement("div");
    progressBarDiv.innerHTML = progressBarHtml.trim();
    parent.appendChild(progressBarDiv);


    if (!obj["external-src"] || obj["external-src"] == "undefined") {
      if (ImageUploadTracker.getValue(  obj["image-id"])==ImageUploadTrackerEnum.utPending) {
        console.log(
          "ImgCache:Set no external-url assume that we need to upload setting to  " +
          obj["internal-src"]
        );
        ImageUploadTracker.setValue( obj["image-id"],ImageUploadTrackerEnum.utInprogress)
        var internalSource = obj["internal-src"];
        if (window["cordovaUrlFixer"]) {
          internalSource = window["cordovaUrlFixer"](internalSource);
          console.log("ImgCache:updated " + internalSource);
          //@ts-ignore
        }
        node.setAttribute("src", internalSource);
        node.setAttribute("class", "mix-blend-mode: difference")
        window["imageCache"][obj["image-id"]] = internalSource;
        function previewFile(url): Promise<any> {
          return new Promise<any>((resolve, reject) => {
            var request = new XMLHttpRequest();
            request.open("GET", url, true);
            request.responseType = "blob";
            request.onload = function () {
              var reader = new FileReader();
              reader.readAsArrayBuffer(request.response);
              // reader.readAsDataURL(request.response);
              reader.onload = function (e) {
                console.log("ImgCache:Success read internal file");
                resolve(e.target["result"]); //
                // console.log("DataURL:", e.target.result);
              };
              reader.onerror = function (e) {
                Track.reportError(e, "Error reading internal image file", false);
                reject(e);
              };
            };
            request.send();
          });
        }

        var f = previewFile(internalSource)
          .then(file => {
            var bfh = new ImageHandler(null, "no-id");

            bfh
              .uploadFile(obj["image-id"], file, false)
              .then(url => {
                console.log(
                  "ImgCache:Uploaded file update attributes" + JSON.stringify(url)
                );
                parent.setAttribute("external-src", url.externalUrl);
                ImageUploadTracker.removeValue( obj["image-id"])
                //node.removeAttribute("internal-src");
              })
              .catch(e => {
                console.log("ImgCache:error storing file " + e);
                Track.reportError(e, "error storing file", false);
              });
          })
          .catch(e => {
            console.log("ImgCache:error fetting file " + e);
            Track.reportError(e, "error fetching file", false);
          });
      }
      else {
        console.log("Image")
      }
      setSize(node);
      return parent;
    }
    if (window["imageCache"][imageId]) {
      setSize(node);
      node.setAttribute("src", window["imageCache"][imageId]);
      return parent;
    }
    try {
      var sanvalue = obj["external-src"];
      if (!ImgCache.ready) {
        node.setAttribute("src", sanvalue);
        setSize(node);
        return parent;
      }
      if (window["imageCache"] && window["imageCache"][obj["image-id"]]) {
        node.setAttribute("src", window["imageCache"][obj["image-id"]]);
        setSize(node);
      }
      ImgCache.isCached(sanvalue, async function (path, success) {
        if (success) {
          // already cached
          console.log("ImgCache:InCache " + sanvalue);
          ImgCache.getCachedFileURL(path, (url, entry) => {
            console.log("ImgCache:localurl " + entry);

            if (window["cordovaUrlFixer"]) {
              entry = window["cordovaUrlFixer"](entry);
              console.log("ImgCache:updated " + entry);
              //@ts-ignore
            }

            //localServerFileUrl contains the loadable url
            if (obj["internal-src"]) {
              console.log("ImgCache:found old internal source cleanup");
              ImgCache.removeFile(
                obj["internal-src"],
                e => {
                  parent.removeAttribute("internal-src");
                  console.log("ImgCache:old source cleaned up");
                },
                e => {
                  Track.reportError(
                    e,
                    "could not remove old image file.",
                    false
                  );
                  console.log("ImgCache:could not remove old cached file " + e);
                }
              );
            }
            node.setAttribute("src", entry);
            window["imageCache"][obj["image-id"]] = entry;
          });
        } else {
          console.log("ImgCache:Not incache set it to source " + sanvalue);
          node.setAttribute("src", sanvalue);

          // not there, need to cache the image
          ImgCache.cacheFile(sanvalue, function () {
            console.log("ImgCache:Cache " + sanvalue);
            // ImgCache.useCachedFile(target);
          });
        }
      });
    } catch (e) {
      console.log("ImgCache:Error " + e);
      Track.reportError(e, "image cache error.", false);

      node.setAttribute("src", sanvalue);
    }
    setSize(node);

    return parent;
  }

  static formats(domNode) {

    let x = ATTRIBUTES.reduce(function (formats, attribute) {
      if (domNode.hasAttribute(attribute)) {
        formats[attribute] = domNode.getAttribute(attribute);
      }
      return formats;
    }, {});
    return x;
  }

  static match(url) {

    return /\.(jpe?g|gif|png)$/.test(url) || /^data:image\/.+;base64/.test(url);
  }

  static sanitize(url) {
    return sanitize(url, ["http", "https", "data"]) ? url : "//:0";
  }

  static value(domNode) {
    var obj = {
      "external-src":
        domNode.getAttribute("external-src") ||
          domNode.getAttribute("external-src") != "undefined"
          ? domNode.getAttribute("external-src")
          : null,
      "internal-src":
        domNode.getAttribute("internal-src") ||
          domNode.getAttribute("internal-src") != "undefined"
          ? domNode.getAttribute("internal-src")
          : null,
      "image-id":
        domNode.getAttribute("image-id") ||
          domNode.getAttribute("image-id") != "undefined"
          ? domNode.getAttribute("image-id")
          : null,
      height: domNode.clientHeight,
      width: domNode.clientWidth
    };

    return obj; //domNode.getAttribute("external-src");
  }

  format(name, value) {
    if (ATTRIBUTES.indexOf(name) > -1) {
      if (value) {
        this.domNode.setAttribute(name, value);
      } else {
        this.domNode.removeAttribute(name);
      }
    } else {
      super.format(name, value);
    }
  }
}
Image.blotName = "image";
Image.tagName = "TODOIMAGE";
*/
export { ListItem, List };