import React, { useState, useEffect } from 'react';
import Viewer from 'react-viewer';
import * as hotkey from "../lib/hotkeyClient";
import * as actions from "../lib/ActionReducer";
class ImageViewProps {
    previewImage?: string;
    dispatch: any
}
export default (props: ImageViewProps, dispatch: any) => {
    const [closedPreviewImage, setClosed] = React.useState(null);
    ;
    useEffect(() => {

        let me = this;
        let instance = hotkey.HotKeyEngine.instance();
        let HotKeyDescriptions = new Array<hotkey.HotKeyActionDescription>();
        let handlers = new Map<hotkey.HotKeyActionType, () => boolean>();
        handlers.set(
          hotkey.HotKeyActionType.esc,
          (): boolean => {
            props.dispatch(actions.ar_clearImagePreview())
            return true;
          }
        );
        instance.registerHandlers("image-viewer", "image-viewer", true, handlers);
          
        return function cleanup() {
           
            let instance = hotkey.HotKeyEngine.instance();
            instance.unregisterHandlers("image-viewer");
        };
    }, [props.previewImage]);
    return (
        <div>

            <Viewer
                visible={props.previewImage != null}
                onClose={() => {
                    props.dispatch(actions.ar_clearImagePreview())
                }}
                images={[{ src: props.previewImage, alt: '' }]}
                zIndex={100000}
                showTotal={false}
                noToolbar={true}
                noFooter={true}
                changeable={false}
                disableKeyboardSupport={true}
            />
        </div>
    );
}