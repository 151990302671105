import * as React from "react";
import * as material from "material-ui";
import * as model from "../lib/model";
import * as actions from "../lib/ActionReducer";
import CommandPalette from 'react-command-palette';
import {getCommands,TagSearchItem } from '../lib/commands'
import * as pApi from "../../shared/papi/papi-core";

import * as hotkey from "../lib/hotkeyClient";
import { NoteFunctionType } from "../lib/model";
import { HardwareDesktopWindows } from "material-ui/svg-icons";
// import the theme from those provided ...
import chrome from "react-command-palette/themes/atom-theme";
//import sampleChromeCommandCss from "react-command-palette/examples/sampleChromeCommand

// then import the CSS
import "react-command-palette/themes/atom.css";


let x = chrome

interface TagSearchProp {
  connect: model.ConnectProps;

  onClose: () => void;
}
interface TagSearchState {
  tagQuery: String;
  parsedTag: TagSearchItem;
}

export class TagSearch extends React.Component<TagSearchProp, TagSearchState> {
  constructor(props: TagSearchProp, context) {
    super(props, context);

    this.state = {
      tagQuery: "",
      parsedTag: null
    };
  }
  componentDidMount() {
    let me = this;
    let instance = hotkey.HotKeyEngine.instance();
    let handlers = new Map<hotkey.HotKeyActionType, () => boolean>();
    handlers.set(
      hotkey.HotKeyActionType.esc,
      (): boolean => {
        me.props.onClose();
        return true;
      }
    );
    instance.registerHandlers("tagsearch", "tagsearch", true, handlers);
  }
  componentWillUnmount() {
    let instance = hotkey.HotKeyEngine.instance();
    instance.unregisterHandlers("tagsearch");
  }
  getTagSection(datasource: Array<TagSearchItem>, chosen: any, index: number): TagSearchItem {
    let choice: TagSearchItem = null;

    if (index != -1) {
      choice = datasource[index];
    } else {
      choice = datasource.find(
        x => x.text.toLowerCase() == chosen.toLowerCase()
      );
      if (!choice) {
        choice = datasource.find(
          x => x.text.toLowerCase().indexOf(chosen.toLowerCase()) != -1
        );
      }
    }
    if (choice != null) {
      return choice;
    } else {
      return null;
    }
  }
  
  redirectToTag(tag: TagSearchItem) {
    let me = this;
    let vs = me.props.connect.state.viewState.deepCopy();
    if (tag.section != null) {
      vs.appSection = tag.section;
      me.props.onClose();
      window.location.hash = vs.getHash();
      return;

    }
    vs.appSection = model.AppSections.notes;
    vs.noteViewState = new model.NoteViewState();
    vs.noteViewState.functionType = model.NoteFunctionType.tag;
    vs.noteViewState.query = tag.text
    me.props.onClose();
    window.location.hash = vs.getHash();
  }
 
  cached_tags: Array<TagSearchItem>
  getTags(): Array<TagSearchItem> {
    let retval = new Array<TagSearchItem>();
    let activeCommands = hotkey.HotKeyEngine.instance().getActiveCommands();
    activeCommands.forEach(x => {
      let searchItem = new TagSearchItem({
        text: x.description + ' ' + hotkey.HotKeyEngine.instance().getKey(x.actionType)
      }
      )
      retval.push(searchItem)
    })
    return retval;
  }
  getTagsOld(): Array<TagSearchItem> {
    if (this.cached_tags && this.props.connect.state.tags.length == (this.cached_tags.length + 4)) {
      return this.cached_tags;
    }
    let retval = new Array<TagSearchItem>();
    retval.push(new TagSearchItem({ text: 'inbox', section: model.AppSections.inbox }))
    retval.push(new TagSearchItem({ text: 'today', section: model.AppSections.today }))
    retval.push(new TagSearchItem({ text: 'upcoming', section: model.AppSections.snoozed }))
    retval.push(new TagSearchItem({ text: 'notes', section: model.AppSections.notes }))
    for (var i in this.props.connect.state.tags) {
      let t = this.props.connect.state.tags[i];
      retval.push(new TagSearchItem({ text: t.name }))
    }
    this.cached_tags = retval;
    return retval;
  }
 
  render() {
    const commands = [{
      name: "Foo",
      command() { }
    }, {
      name: "Bar",
      command() { }
    }

    ];
    console.log('render commnad')
    return (<CommandPalette
      alwaysRenderCommands
      closeOnSelect={true}
      commands={getCommands(this.props.connect)}
      open
      defaultInputValue=""
      display="modal"
      header={null}
      highlightFirstSuggestion
  
      maxDisplayed={10}
      onAfterOpen={function noRefCheck() { }}
      onChange={function noRefCheck() { }}
      onHighlight={function noRefCheck() { }}
      onRequestClose={this.props.onClose}
      onSelect={function noRefCheck() { }}

      options={{
        allowTypo: true,
        key: 'name',
        keys: [
          'name'
        ],
        limit: 7,
        scoreFn: null,
        threshold: -Infinity
      }}
      placeholder="Type a command"
      reactModalParentSelector="body"
      renderCommand={function noRefCheck(suggestion) {
        
        
        const { name, highlight, shortcut,category } = suggestion;
        return (
          <div className="atom-item">
            {category == "Tag" && (
              <span>#</span>
              )}
            
            {highlight ? (
              <span dangerouslySetInnerHTML={{ __html: highlight }} />
            ) : (
              <span>{name}</span>
            )}
            <kbd className="atom-shortcut">{shortcut}</kbd>
          </div>
        );
      }}
      resetInputOnClose={false}
      shouldReturnFocusAfterClose
      showSpinnerOnSelect
     theme={chrome}
    />)
  }
  renderx() {
    let datasource = this.getTags();

    let me = this;
    const actionsBtn = [
      <material.FlatButton
        label="Cancel"
        primary
        onClick={() => {
          me.props.onClose();
        }}
      />,
      <material.FlatButton
        label="Go"
        primary
        disabled={me.state.parsedTag == null}
        onClick={() => {
          me.redirectToTag(me.state.parsedTag);
        }}
      />
    ];
    return (
      <material.Dialog
        title="todoZero Command"
        actions={actionsBtn}
        overlayStyle={actions.getOverlayStyle()}
        onRequestClose={e => {
          me.props.onClose();
        }}
        open={true}
      >
        <div className="row">
          <div className="col-8">
            <material.AutoComplete
              key="quickSnooze"
              dataSource={datasource}
              dataSourceConfig={{
                text: "text",
                value: "text"
              }}
              fullWidth
              autoFocus={true}
              filter={material.AutoComplete.caseInsensitiveFilter}
              onUpdateInput={e => {
                let tag: TagSearchItem = null;
                if (e.length > 0) {
                  tag = me.getTagSection(datasource, e, -1);
                }
                me.setState({
                  tagQuery: e,
                  parsedTag: tag
                });
              }}
              onNewRequest={(chosen, index) => {
                let tag = me.getTagSection(datasource, chosen, index);
                if (tag) {
                  me.redirectToTag(tag);
                }
              }}
              value={this.state.tagQuery as string}
              hintText={
                "Try: today"
              }
            />
          </div>
          <div className="col-4">
            {this.state.parsedTag == null &&
              this.state.tagQuery.length > 1 && <span>no match</span>}
            {this.state.parsedTag != null && this.state.parsedTag.section == null && (
              <span>#{this.state.parsedTag.text}</span>
            )}
            {this.state.parsedTag != null && this.state.parsedTag.section != null && (
              <span><b>{this.state.parsedTag.text}</b></span>
            )}
          </div>
        </div>
      </material.Dialog>
    );
  }
}
