import NavigationIcon from "material-ui/svg-icons/navigation/menu";
import * as React from "react";
import * as model from "../lib/model";
import * as pApi from "../../shared/papi/papi-core";
import { SimpleIconButton } from '../components/SimpleIconButton'
import AddTodo from "material-ui/svg-icons/content/add";
import NoteAdd from "material-ui/svg-icons/action/note-add";

import SearchIcon from "material-ui/svg-icons/action/search";
import SortIcon from "material-ui/svg-icons/content/sort"
import * as hotkey from "../lib/hotkeyClient";
import * as actions from "../lib/ActionReducer";
import { ImageHandler } from "../components/Editor/ImageHandler"
import { Track } from "../../shared/track";
interface HeaderProps {
    connect: model.ConnectProps;
}
interface HeaderState {
    lastTag:string
}

export class Header extends React.Component<
    HeaderProps,
    HeaderState
    > {
    constructor(props: HeaderProps, context) {
        super(props, context);
        if(props.connect.state.viewState.getTag())
        {
            this.state ={
                lastTag : props.connect.state.viewState.getTag()
            }
        }
    }
    static getDerivedStateFromProps(props: HeaderProps, state: HeaderState) {
        if(props.connect.state.viewState.getTag())
        {
            if(props.connect.state.viewState.getTag() != state.lastTag)
            {
                return {
                    lastTag : props.connect.state.viewState.getTag()
                }
            }
        }
        return {};
    }
    async createNewNote(e) {
        try {


            // this.onboardingCheck();
            //this.onboardingCheckNote();
            e.preventDefault();
            e.stopPropagation();

            var ih = new ImageHandler(
                this.props.connect.dispatch,
                this.props.connect.state.context.getId()
            );

            var tags = this.getTagsFromUrl();
            let newNoteId = pApi.newId();
            var note = await ih.createNoteFromImage(
                null,
                tags,
                newNoteId,
                this.props.connect.state.context
            );
            this.navToNote(note.id);
        } catch (e) {
            Track.reportError(
                e,
                "Error adding Note",
                false,
                this.props.connect.dispatch
            );
            this.props.connect.dispatch<any>(actions.ar_setProgress(null));
        }
        return false;

    }
    searchClick() {
        //forgive me for i have sinned
        window['lastPageBeforeSearch'] = window.location.hash;

        let viewState= new model.ViewState()
        viewState.appSection = model.AppSections.search;
        window.location.hash = viewState.getHash();
        this.props.connect.dispatch<any>(actions.ar_search(""))
    }
    createNewTodo() {
        var todoa = new pApi.ToDoItem();
        {
            todoa.id = -1;
            todoa.checked = false;
            todoa.description = "";
            todoa.priority = 0;
            todoa.project_type = this.props.connect.state.viewState.inAggregateToDoView()
                ? pApi.ProjectType.ptInbox
                : pApi.ProjectType.ptDojo;
            todoa.note_id = null;
            todoa.due_date = null;
            todoa.objective_id = null;
            todoa.recoccuring = null;
            todoa.tags = this.props.connect.state.viewState.getTag()
                ? [this.props.connect.state.viewState.getTag()]
                : null;
        }

        // this.setState({ todo: todoa });
        this.props.connect.dispatch<any>(actions.showTodoModal(todoa));
    }
    getUrlForTag(tag: string) {
        let me = this;
        let vs = me.props.connect.state.viewState.deepCopy();
        vs.appSection = model.AppSections.notes;
        vs.noteViewState = new model.NoteViewState();
        vs.noteViewState.functionType = model.NoteFunctionType.tag;
        vs.noteViewState.query = tag
        return vs.getHash();
       
      }
    getTagsFromUrl() {
        var tags = [];
        let vs = this.props.connect.state.viewState.noteViewState;
        if (vs) {
            if (vs.functionType == model.NoteFunctionType.tag) {
                tags.push(vs.query);
            }
        }
        return tags;
    }
    componentDidMount() {
        let me = this;
        
        let instance = hotkey.HotKeyEngine.instance();
       // let handlers = new Map<hotkey.HotKeyActionType, () => boolean>();
        let HotKeyDescriptions = new Array<hotkey.HotKeyActionDescription>();
     
        HotKeyDescriptions.push(new hotkey.HotKeyActionDescription(
            hotkey.HotKeyActionType.createTodo,
            (): boolean => {
                me.createNewTodo();
                return true;
            },true,
            "Create To-Do",
            "Action"
            
        ))
       
        
        HotKeyDescriptions.push(new hotkey.HotKeyActionDescription(
            hotkey.HotKeyActionType.search,
            (): boolean => {
                me.searchClick();
                return true;
            }, true,
            "Search",
            "Jump"
            
        ));

        HotKeyDescriptions.push(new hotkey.HotKeyActionDescription(
            hotkey.HotKeyActionType.createNote,
            (): boolean => {
                let me = this;
                let f = async function () {
                    var tags = me.getTagsFromUrl();
                    var ih = new ImageHandler(
                        me.props.connect.dispatch,
                        me.props.connect.state.context.getId()
                    );
                    let newNoteId = pApi.newId()
                    var note = await ih.createNoteFromImage(
                        null,
                        tags,
                        newNoteId,
                        me.props.connect.state.context
                    );
                    me.navToNote(note.id);
                };

                f();
                return true;
            }, true,
            "Create Note",
            "Action"
            
        ))
      
        HotKeyDescriptions.push(new hotkey.HotKeyActionDescription(
            hotkey.HotKeyActionType.nextTab,
            (): boolean => {
                switch (me.props.connect.state.viewState.appSection) {
                    case (model.AppSections.inbox):
                        window.location.hash = model.ViewState.getDefaultHash(model.AppSections.today,this.props.connect.state.viewState);
                        break;
                    case (model.AppSections.today):
                        window.location.hash =  model.ViewState.getDefaultHash(model.AppSections.snoozed,this.props.connect.state.viewState);
                        break;
                    case (model.AppSections.snoozed):
                        window.location.hash = model.ViewState.getDefaultHash(model.AppSections.notes,this.props.connect.state.viewState);
                        break;
                    case (model.AppSections.bydate):
                        window.location.hash = model.ViewState.getDefaultHash(model.AppSections.inbox,this.props.connect.state.viewState);
                        break;
                    case (model.AppSections.notes):
                        
                        if(me.state.lastTag && me.props.connect.state.viewState.noteViewState.functionType == model.NoteFunctionType.all)
                        {
                            window.location.hash =  me.getUrlForTag(me.state.lastTag)
                           
                        }
                        else
                        {
                            
                            window.location.hash = model.ViewState.getDefaultHash(model.AppSections.inbox,this.props.connect.state.viewState);
                        }
                        break;

                    default:
                        return false;
                }
                return true;
            }, true,
            "Next Tab",
            "Jump"
            
        ));
        HotKeyDescriptions.push(new hotkey.HotKeyActionDescription(
            hotkey.HotKeyActionType.prevTab,
            (): boolean => {
                switch (me.props.connect.state.viewState.appSection) {
                    case (model.AppSections.inbox):
                        if(me.state.lastTag )
                        {
                            window.location.hash = me.getUrlForTag(me.state.lastTag)
                           
                        }
                        else
                        {
                            
                            window.location.hash = model.ViewState.getDefaultHash(model.AppSections.notes,this.props.connect.state.viewState);
                        }
                        break;
                    
                    case (model.AppSections.today):
                        window.location.hash = model.ViewState.getDefaultHash(model.AppSections.inbox,this.props.connect.state.viewState);
                        break;
                    case (model.AppSections.snoozed):
                        window.location.hash = model.ViewState.getDefaultHash(model.AppSections.today,this.props.connect.state.viewState);
                        break;
                    case (model.AppSections.bydate):
                        window.location.hash = model.ViewState.getDefaultHash(model.AppSections.snoozed,this.props.connect.state.viewState);
                    case (model.AppSections.notes):
                        if(me.props.connect.state.viewState.noteViewState.functionType == model.NoteFunctionType.all)
                        {
                            window.location.hash = model.ViewState.getDefaultHash(model.AppSections.snoozed,this.props.connect.state.viewState);
                        }
                        else
                        {
                            window.location.hash = model.ViewState.getDefaultHash(model.AppSections.notes,this.props.connect.state.viewState);
                        }
                     
                        break;

                    default:
                        return false;
                }
                return true;
            }, true,
            "Previous Tab",
            "Jump"
            
        ));

        instance.registerHandlersWithActions("header", "inbox", false, HotKeyDescriptions);
    }
    navToNote(noteId: any) {
        var newNS = this.props.connect.state.viewState.deepCopy();
        if (!newNS.noteViewState) {
            newNS.noteViewState = new model.NoteViewState();
        }
        newNS.noteViewState.noteId = noteId;
        //newNS.appSection = model.AppSections.notes;
        window.location.hash = newNS.getHash();
    }
    componentWillUnmount() {
        let instance = hotkey.HotKeyEngine.instance();
        instance.unregisterHandlers("header");
    }
    render() {
        let me = this;
        function getNavItemClass(section: model.AppSections, funcType:model.NoteFunctionType): string {
            if(section == model.AppSections.notes && me.props.connect.state.viewState.appSection == section)
            {

                let cnvs = me.props.connect.state.viewState.noteViewState
                return (cnvs && cnvs.functionType == funcType) ? "simple-nav-item simple-nav-item-selected" : "simple-nav-item";
     
                
            }
            return me.props.connect.state.viewState.appSection == section ? "simple-nav-item simple-nav-item-selected" : "simple-nav-item";
        };
    
        return (
            <div className='row  no-gutters simple-header' >
                <div className="col-10 no-gutters simple-nav">
                    <div className='simple-nav-item simple-nav-item-icon' onClick={(e) => {

                        me.props.connect.dispatch<any>(actions.handleToggleCollapsedNav(!this.props.connect.state.uiConifg.layout.navCollapsed));
                    }}> <NavigationIcon /> </div>
                    <div className={getNavItemClass(model.AppSections.inbox,null)}
                        onClick={(e) => {
                            window.location.hash = model.ViewState.getDefaultHash(model.AppSections.inbox,this.props.connect.state.viewState)
                        }} >Inbox</div>
                    <div className={getNavItemClass(model.AppSections.today,null)}
                        onClick={(e) => {
                            window.location.hash = model.ViewState.getDefaultHash(model.AppSections.today,this.props.connect.state.viewState)
                        }}>Today </div>
                    <div className={getNavItemClass(model.AppSections.snoozed,null)}
                        onClick={(e) => {
                            window.location.hash = model.ViewState.getDefaultHash(model.AppSections.snoozed,this.props.connect.state.viewState)
                        }}> Upcoming </div>
                    <div className={getNavItemClass(model.AppSections.notes,model.NoteFunctionType.all)} onClick={(e) => {
                        window.location.hash = model.ViewState.getDefaultHash(model.AppSections.notes,this.props.connect.state.viewState)
                    }} > Notes </div>
                     {this.props.connect.state.viewState.appSection == model.AppSections.bydate &&
                     (
                        <div className={getNavItemClass(model.AppSections.bydate,model.NoteFunctionType.all)} onClick={(e) => {
                            window.location.hash = model.ViewState.getDefaultHash(model.AppSections.bydate,this.props.connect.state.viewState)
                        }} > By Date </div>
                     )

    }
                    {this.props.connect.state.viewState.appSection != model.AppSections.bydate && me.state && me.state.lastTag &&
                        <div className={getNavItemClass(model.AppSections.notes,model.NoteFunctionType.tag)} onClick={(e) => {
                            window.location.hash = me.getUrlForTag(me.state.lastTag)
                        }} > #{this.state.lastTag} </div>
                    }

                </div>
                <div className="col-2 no-gutters simple-nav-action-button-section">

                    <SimpleIconButton alt={this.props.connect.state.viewState.sortByCreateDate?"Default Sort":"Sort by Newest first"}  fill={(this.props.connect.state.viewState.sortByCreateDate)? "green":""} icon={SortIcon} onClick={()=>{
                        let vs = this.props.connect.state.viewState.deepCopy();
                        ;
                        vs.sortByCreateDate = !vs.sortByCreateDate;
                        
                        window.location.hash = vs.getHash();
                    }} />
                    <SimpleIconButton icon={SearchIcon} alt="Search" onClick={this.searchClick.bind(this)} />
                    <SimpleIconButton icon={NoteAdd} alt="New Note"  onClick={this.createNewNote.bind(this)} />
                    <SimpleIconButton icon={AddTodo} alt="New to-do" onClick={this.createNewTodo.bind(this)} />
                </div>
            </div>
        )
    }
}