// Quill.js Plugin - Markdown Shortcuts
// This is a module for the Quill.js WYSIWYG editor (https://quilljs.com/)
// which converts text entered as markdown to rich text.
//
// v0.0.3
//
// Author: Patrick Lee (me@patricklee.nyc)
//
// (c) Copyright 2017 Patrick Lee (me@patricklee.nyc).
// Permission is hereby granted, free of charge, to any person obtaining a copy
// of this software and associated documentation files (the "Software"), to deal
// in the Software without restriction, including without limitation the rights
// to use, copy, modify, merge, publish, distribute, sublicense, and/or sell
// copies of the Software, and to permit persons to whom the Software is
// furnished to do so, subject to the following conditions:
//
// The above copyright notice and this permission notice shall be included in
// all copies or substantial portions of the Software.
//
// THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
// IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
// FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE
// AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
// LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM,
// OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN
// THE SOFTWARE.
//
let Quill = require("quill");


export class MarkdownShortcuts {
  quill: any;
  options: any;
  matches: any;

  constructor(quill, options) {
    this.quill = quill;
    this.options = options;

    this.matches = [
      /*   {
          name:'list autofill',
          key: ' ',
          collapsed: true,
          format: { list: false },
          // /^(\*|\+)\s$/gz
          pattern1: /^\[ ?\]|\s$/g,
          pattern: /^(\*|\+)\s$/g,
          action: (text, selection, pattern) => {
            setTimeout(() => {
              this.quill.formatLine(selection.index, 1, 'todo-list',   {id:'new:ddd', checked:false})
              this.quill.deleteText(selection.index - 2, 2)
            }, 0)
          },
          handlerx: function(range, context) {
            ;
            let length = context.prefix.length;
            let [line, offset] = this.quill.getLine(range.index);
            if (offset > length) return true;
            let value;
            var insertValue;
            switch (context.prefix.trim()) {
              case '[]': case '[ ]':
                insertValue = {
                  value : {id:'new:ddd', checked:false}
                }
                
                break;
              case '[x]':
                insertValue = {
                  value : {id:'new:ddd', checked:false}
                }
              
                break;
              case '-': case '*':
                value = 'bullet';
                break;
              default:
                value = 'ordered';
            }
           
            this.quill.insertText(range.index, ' ', Quill.sources.USER);
            this.quill.history.cutoff();
            let delta = new Delta().retain(range.index - offset)
                                   .delete(length + 1)
                                   .retain(line.length() - 2 - offset)
                                   .retain(1, insertValue?insertValue:{ list: value });
            this.quill.updateContents(delta, Quill.sources.USER);
            this.quill.history.cutoff();
            this.quill.setSelection(range.index - length, Quill.sources.SILENT);
          }
        },*/
      {
        name: "chip",
        patternx: /\B(\#[a-zA-Z\/0-9_-]+(?!\/))/g,
        pattern: /(\s|^)(\#[A-za-z0-9\/]+)/g,
        action: (text, selection, pattern, lineStart) => {
          //let match = pattern.exec(text)
          var me = this;
          let match = pattern.exec(text);
          var currIndex = 0;
          var subtext = text;
          while (match) {
            let annotatedText = match[0];
            
            annotatedText = annotatedText.substring(annotatedText.indexOf("#")+1)
            if (annotatedText.length == 0) break;
            //const matchedText = match[1]
            const startIndex = lineStart + match.index + currIndex;

            function format(start, length) {
              me.quill.formatText(start, length, {
                chip: true
              });
              me.quill.format("chip", false);
            }

            this.quill.deleteText(startIndex, match[0].length , "api");
            this.quill.insertEmbed(
              startIndex,
              "mention",
              { key: annotatedText, value: annotatedText },
              "api"
            );
            this.quill.insertText(startIndex + 1, " ", "api");
            this.quill.setSelection(startIndex + 2, "api");
            //setTimeout(format.bind(null, startIndex, annotatedText.length), 0);
            currIndex += match[0].length ;//annotatedText.length;
            subtext = subtext.substr(match.index + annotatedText.length);
            match = pattern.exec(subtext);
            // if (text.match(/^([*_ \n]+)$/g)) return

            /* setTimeout(() => {
            
              this.quill.deleteText(startIndex, annotatedText.length)
              this.quill.insertText(startIndex, text, {chip: text})
              this.quill.format('chip', false)
            }, 0)
  
            setTimeout(() => {
              ;
              this.quill.formatText(startIndex, annotatedText.length, {chip:true});  
              this.quill.format('chip', false)
            }, 0)*/
          }
        }
      },
      {
        name: 'header',
        pattern: /^(#){1,6}\s/g,
        action: (text, selection, pattern) => {
          var match = pattern.exec(text)
          if (!match) return
          const size = match[0].length
          // Need to defer this action https://github.com/quilljs/quill/issues/1134
          setTimeout(() => {
            this.quill.formatLine(selection.index, 0, 'header', size - 1)
            this.quill.deleteText(selection.index - size, size)
          }, 0)
        }
      },
      {
        name: 'blockquote',
        pattern: /^(>)\s/g,
        action: (text, selection) => {
          // Need to defer this action https://github.com/quilljs/quill/issues/1134
          setTimeout(() => {
            this.quill.formatLine(selection.index, 1, 'blockquote', true)
            this.quill.deleteText(selection.index - 2, 2)
          }, 0)
        }
      },
      {
        name: 'code-block-modified',
        pattern: /^`{3}(?:\s|\n)/g,
        action: (text, selection) => {
          // Need to defer this action https://github.com/quilljs/quill/issues/1134
          setTimeout(() => {
         
            
            this.quill.formatLine(selection.index, 1, 'code-block', true)
            this.quill.deleteText(selection.index - 4, 4)
          }, 0)
        }
      },
      {
        name: 'bolditalic',
        pattern: /(?:\*|_){3}(.+?)(?:\*|_){3}/g,
        action: (text, selection, pattern, lineStart) => {
          let match = pattern.exec(text)

          const annotatedText = match[0]
          const matchedText = match[1]
          const startIndex = lineStart + match.index

          if (text.match(/^([*_ \n]+)$/g)) return

          setTimeout(() => {
            this.quill.deleteText(startIndex, annotatedText.length)
            this.quill.insertText(startIndex, matchedText, {bold: true, italic: true})
            this.quill.format('bold', false)
          }, 0)
        }
      },
      {
        name: 'bold',
        pattern: /(?:\*|_){2}(.+?)(?:\*|_){2}/g,
        action: (text, selection, pattern, lineStart) => {
          let match = pattern.exec(text)

          const annotatedText = match[0]
          const matchedText = match[1]
          const startIndex = lineStart + match.index

          if (text.match(/^([*_ \n]+)$/g)) return

          setTimeout(() => {
            this.quill.deleteText(startIndex, annotatedText.length)
            this.quill.insertText(startIndex, matchedText, {bold: true})
            this.quill.format('bold', false)
          }, 0)
        }
      },
      {
        name: 'italic',
        pattern: /(?:\*|_){1}(.+?)(?:\*|_){1}/g,
        action: (text, selection, pattern, lineStart) => {
          let match = pattern.exec(text)

          const annotatedText = match[0]
          const matchedText = match[1]
          const startIndex = lineStart + match.index

          if (text.match(/^([*_ \n]+)$/g)) return

          setTimeout(() => {
            this.quill.deleteText(startIndex, annotatedText.length)
            this.quill.insertText(startIndex, matchedText, {italic: true})
            this.quill.format('italic', false)
          }, 0)
        }
      },
      {
        name: 'strikethrough',
        pattern: /(?:~~)(.+?)(?:~~)/g,
        action: (text, selection, pattern, lineStart) => {
          let match = pattern.exec(text)

          const annotatedText = match[0]
          const matchedText = match[1]
          const startIndex = lineStart + match.index

          if (text.match(/^([*_ \n]+)$/g)) return

          setTimeout(() => {
            this.quill.deleteText(startIndex, annotatedText.length)
            this.quill.insertText(startIndex, matchedText, {strike: true})
            this.quill.format('strike', false)
          }, 0)
        }
      },
      {
        name: 'code',
        pattern: /(?:`)(.+?)(?:`)/g,
        action: (text, selection, pattern, lineStart) => {
          let match = pattern.exec(text)

          const annotatedText = match[0]
          const matchedText = match[1]
          const startIndex = lineStart + match.index

          if (text.match(/^([*_ \n]+)$/g)) return

          setTimeout(() => {
            this.quill.deleteText(startIndex, annotatedText.length)
            this.quill.insertText(startIndex, matchedText, {code: true})
            this.quill.format('code', false)
            this.quill.insertText(this.quill.getSelection(), ' ')
          }, 0)
        }
      },
      {
        name: "asterisk-ul",
        pattern: /^(\*|\+)\s$/g,
        action: (text, selection, pattern) => {
          setTimeout(() => {
            //this.quill.formatLine(selection.index, 1, "list", "unordered");
            //this.quill.deleteText(selection.index - 2, 2);
          }, 0);
        }
      },
      {
        name: "image",
        pattern: /(?:!\[(.+?)\])(?:\((.+?)\))/g,
        action: (text, selection, pattern) => {
          const startIndex = text.search(pattern);
          const matchedText = text.match(pattern)[0];
          // const hrefText = text.match(/(?:!\[(.*?)\])/g)[0]
          const hrefLink = text.match(/(?:\((.*?)\))/g)[0];
          const start = selection.index - matchedText.length - 1;
          if (startIndex !== -1) {
            setTimeout(() => {
              this.quill.deleteText(start, matchedText.length);
              this.quill.insertEmbed(
                start,
                "image",
                hrefLink.slice(1, hrefLink.length - 1)
              );
            }, 0);
          }
        }
      },
      {
        name: 'link',
        pattern: /(?:\[(.+?)\])(?:\((.+?)\))/g,
        action: (text, selection, pattern) => {
          const startIndex = text.search(pattern)
          const matchedText = text.match(pattern)[0]
          const hrefText = text.match(/(?:\[(.*?)\])/g)[0]
          const hrefLink = text.match(/(?:\((.*?)\))/g)[0]
          const start = selection.index - matchedText.length - 1
          if (startIndex !== -1) {
            setTimeout(() => {
              this.quill.deleteText(start, matchedText.length)
              this.quill.insertText(start, hrefText.slice(1, hrefText.length - 1), 'link', hrefLink.slice(1, hrefLink.length - 1))
            }, 0)
          
        }
      }
    }
      
    ];
    let me = this;
    // Handler that looks for insert deltas that match specific characters
    this.quill.on('text-change', (delta, oldContents, source) => {
      for (let i = 0; i < delta.ops.length; i++) {
        if (delta.ops[i].hasOwnProperty('insert')) {
          if (delta.ops[i].insert === ' ') {
            me.onSpace()
          } else if (delta.ops[i].insert === '\n') {
            me.onEnter()
          }
        }
      }
    });
  }

  onSpace() {
    const selection = this.quill.getSelection();
    if(!selection)
    {
       return;
    }
    const [line, offset] = this.quill.getLine(selection.index);
    let text = line.domNode.textContent;

    let newText = "";
    var head = line.children.head;
    let hasMention = false;
    while (head) {
      if (head.domNode.className != "mention") {
        newText += head.domNode.textContent;

      }
      else {
        hasMention = true;
        newText += " "
      }

      head = head.next;

      //  text += " ";
    }
    text = hasMention ? newText + ' ' : text;
    const lineStart = selection.index - offset;
  //  console.log(text);
    if (typeof text !== "undefined" && text) {
      for (let match of this.matches) {
        if (match) {

          const matchedText = text.match(match.pattern);
          if (matchedText) {
           // console.log("matched", match.name, text);
            if (match.action)
              match.action(text, selection, match.pattern, lineStart);
            return;
          }
        }
      }
    }
  }

  onEnter() {

    let selection = this.quill.getSelection();
    if (selection) {
      const [line, offset] = this.quill.getLine(selection.index);
      let text = line.domNode.textContent + ' '
      let newText = '';
      var head = line.children.head;
      let hasMention = false;
      while (head) {
        if (head.domNode.className != "mention") {
          newText += head.domNode.textContent;

        }
        else {
          hasMention = true;
          newText += " "
        }

        head = head.next;

        //  text += " ";
      }
      text = hasMention ? newText + ' ' : text + ' ';

      // const text = line.domNode.textContent + " ";
      const lineStart = selection.index - offset;
      selection.length = selection.index++;
      if (typeof text !== "undefined" && text) {
        for (let match of this.matches) {
          if (match) {
            const matchedText = text.match(match.pattern);
            if (matchedText) {
             // console.log("matched", match.name, text);
              match.action(text, selection, match.pattern, lineStart);
              return;
            }
          }
        }
      }
    }
  }
}
//MarkdownShortcuts;
export class MentionShortcuts {
  quill: any;
  options: any;
  matches: any;

  constructor(quill, options) {
    this.quill = quill;
    this.options = options;

    this.matches = [
      {
        name: "chip",
        pattern: /\B(\#[a-zA-Z\/0-9_-]+(?!\/))/g,
        action: (text, selection, pattern, lineStart) => {
          //let match = pattern.exec(text)
          var me = this;
          let match = pattern.exec(text);
          var currIndex = 0;
          var subtext = text;
          while (match) {
            let annotatedText = match[0];
            annotatedText = annotatedText.substring(1);
            if (annotatedText.length == 0) break;
            //const matchedText = match[1]
            const startIndex = lineStart + match.index + currIndex;

            function format(start, length) {
              me.quill.formatText(start, length, {
                chip: true
              });
              me.quill.format("chip", false);
            }

            this.quill.deleteText(startIndex, annotatedText.length + 1, "api");
            this.quill.insertEmbed(
              startIndex,
              "mention",
              { key: annotatedText, value: annotatedText },
              "api"
            );
            this.quill.insertText(startIndex + 1, "", "api");
            this.quill.setSelection(startIndex + 2, "api");
            //setTimeout(format.bind(null, startIndex, annotatedText.length), 0);
            currIndex += annotatedText.length;
            subtext = subtext.substr(match.index + annotatedText.length);
            match = pattern.exec(subtext);
            // if (text.match(/^([*_ \n]+)$/g)) return

            /* setTimeout(() => {
            
              this.quill.deleteText(startIndex, annotatedText.length)
              this.quill.insertText(startIndex, text, {chip: text})
              this.quill.format('chip', false)
            }, 0)
  
            setTimeout(() => {
              ;
              this.quill.formatText(startIndex, annotatedText.length, {chip:true});  
              this.quill.format('chip', false)
            }, 0)*/
          }
        }
      },

    ];

    // Handler that looks for insert deltas that match specific characters
    this.quill.on("text-change", (delta, oldContents, source) => {
      {
        if (source == "api") return;
        for (let i = 0; i < delta.ops.length; i++) {
          if (delta.ops[i]["insert"]) {
            if (delta.ops[i].insert === " ") {
              this.onSpace();
            } else if (delta.ops[i].insert === "\n") {
              this.onEnter();
            }
          }
        }
      }
    });
  }

  onSpace() {
    const selection = this.quill.getSelection();
    const [line, offset] = this.quill.getLine(selection.index);
    let text = line.domNode.textContent;
  
    let newText = "";
    var head = line.children.head;
    let hasMention = false;
    while (head) {
      if (head.domNode.className != "mention") {
        newText += head.domNode.textContent

      }
      else {
        hasMention = true;
        newText += " "
      }

      head = head.next;

      //  text += " ";
    }
    
    text = hasMention ? newText + ' ' : text;
    const lineStart = selection.index - offset;
    //console.log(text);
    if (typeof text !== "undefined" && text) {
      for (let match of this.matches) {
        if (match) {
          const matchedText = text.match(match.pattern);
          if (matchedText) {
            console.log("matched", match.name, text);
            if (match.action)
              match.action(text, selection, match.pattern, lineStart);
            return;
          }
        }
      }
    }
  }

  onEnter() {

    let selection = this.quill.getSelection();
    if (selection) {
      const [line, offset] = this.quill.getLine(selection.index);
      let text = line.domNode.textContent + ' '
      let newText = '';
      var head = line.children.head;
      let hasMention = false;
      while (head) {
        if (head.domNode.className != "mention") {
          newText += head.domNode.textContent;

        }
        else {
          hasMention = true;
          newText += " "
        }

        head = head.next;

        //  text += " ";
      }
      text = hasMention ? newText + ' ' : text + ' ';

      // const text = line.domNode.textContent + " ";
      const lineStart = selection.index - offset;
      selection.length = selection.index++;
      if (typeof text !== "undefined" && text) {
        for (let match of this.matches) {
          if (match) {
            const matchedText = text.match(match.pattern);
            if (matchedText) {
              console.log("matched", match.name, text);
              match.action(text, selection, match.pattern, lineStart);
              return;
            }
          }
        }
      }
    }
  }
}

const Keys = {
  TAB: 9,
  ENTER: 13,
  ESCAPE: 27,
  UP: 38,
  DOWN: 40
};

//@ts-ignore
const Embed = Quill.import("blots/embed");

export class MentionBlot extends Embed {
  static create(data) {
    const node = super.create();
    const atSign = document.createElement("span");
    atSign.className = "ql-mention-at-sign";
    atSign.innerHTML = "#";
  
    node.appendChild(atSign);
    node.innerHTML += data.value;
    node.dataset.id = data.id;
    node.dataset.value = data.value;
    return node;
  }

  static value(domNode) {
    return {
      id: domNode.dataset.id,
      value: domNode.dataset.value
    };
  }
}
//@ts-ignore
MentionBlot.blotName = "mention";
//@ts-ignore
MentionBlot.tagName = "span";
//@ts-ignore
MentionBlot.className = "mention";
//@ts-ignore
Quill.register(MentionBlot);

export class Mention {
  isOpen: boolean;
  itemIndex: number;
  atPos?: number;
  cursorPos?: number;
  values: any[];
  quill: any;
  options: any;
  mentionContainer: any;
  mentionList: any;

  constructor(quill, options) {
    this.isOpen = false;
    this.itemIndex = 0;
    this.atPos = null;
    this.cursorPos = null;
    this.values = [];

    this.quill = quill;

    this.options = {
      source: null,
      renderItem(item) {
        return `${item.value}`;
      },
      allowedChars: /^[a-zA-Z0-9_]*$/,
      minChars: 0,
      maxChars: 31,
      offsetTop: 2,
      offsetLeft: 0,
      renderList: this.renderList.bind(this)
    };

    Object.assign(this.options, options);

    this.mentionContainer = document.createElement("div");
    this.mentionContainer.className = "ql-mention-list-container";
    this.mentionContainer.style.cssText = "display: none; position: fixed;";

    this.mentionList = document.createElement("ul");
    this.mentionList.className = "ql-mention-list";
    this.mentionContainer.appendChild(this.mentionList);

    document.body.appendChild(this.mentionContainer);

    quill.on("text-change", this.onTextChange.bind(this));
    quill.on("selection-change", this.onSelectionChange.bind(this));

    quill.keyboard.addBinding(
      {
        key: Keys.TAB
      },
      this.selectHandler.bind(this)
    );
    quill.keyboard.bindings[9].unshift(quill.keyboard.bindings[9].pop());

    quill.keyboard.addBinding(
      {
        key: Keys.ENTER
      },
      this.selectHandler.bind(this)
    );
    quill.keyboard.bindings[13].unshift(quill.keyboard.bindings[13].pop());

    quill.keyboard.addBinding(
      {
        key: Keys.ESCAPE
      },
      this.escapeHandler.bind(this)
    );

    quill.keyboard.addBinding(
      {
        key: Keys.UP
      },
      this.upHandler.bind(this)
    );

    quill.keyboard.addBinding(
      {
        key: Keys.DOWN
      },
      this.downHandler.bind(this)
    );
  }

  selectHandler() {
    if (this.isOpen) {
      this.selectItem();
      return false;
    }
    return true;
  }

  escapeHandler() {
    if (this.isOpen) {
      this.hideMentionList();
      return false;
    }
    return true;
  }

  upHandler() {
    if (this.isOpen) {
      this.prevItem();
      return false;
    }
    return true;
  }

  downHandler() {
    if (this.isOpen) {
      this.nextItem();
      return false;
    }
    return true;
  }

  showMentionList() {
    this.mentionContainer.style.visibility = "hidden";
    this.mentionContainer.style.display = "";
    this.setMentionContainerPosition();
    this.isOpen = true;
  }

  hideMentionList() {
    this.mentionContainer.style.display = "none";
    this.isOpen = false;
  }

  highlightItem() {
    for (let i = 0; i < this.mentionList.childNodes.length; i += 1) {
      this.mentionList.childNodes[i].classList.remove("selected");
    }
    this.mentionList.childNodes[this.itemIndex].classList.add("selected");
    const itemHeight = this.mentionList.childNodes[this.itemIndex].offsetHeight;
    this.mentionContainer.scrollTop = this.itemIndex * itemHeight;
  }

  getItemData() {
    return {
      id: this.mentionList.childNodes[this.itemIndex].dataset.id,
      value: this.mentionList.childNodes[this.itemIndex].dataset.value
    };
  }

  selectItem() {
    const data = this.getItemData();

    this.quill.deleteText(this.atPos, this.cursorPos - this.atPos, "api");
    this.quill.insertEmbed(this.atPos, "mention", data, "api");
    this.quill.insertText(this.atPos + 1, " ", "api");
    this.quill.setSelection(this.atPos + 2, "api");
    this.hideMentionList();
  }

  onItemClick(e) {
    e.stopImmediatePropagation();
    e.preventDefault();
    this.itemIndex = e.currentTarget.dataset.index;
    this.highlightItem();
    this.selectItem();
  }

  renderList(data, searchTerm) {
    if (data && data.length > 0) {
      this.values = data;
      this.mentionList.innerHTML = "";
      for (let i = 0; i < data.length; i += 1) {
        const li = document.createElement("li");
        li.className = "ql-mention-list-item";
        //@ts-ignore
        li.dataset.index = i;
        li.dataset.id = data[i].id;
        li.dataset.value = data[i].value;
        li.innerHTML = this.options.renderItem(data[i], searchTerm);
        li.onclick = this.onItemClick.bind(this);
        this.mentionList.appendChild(li);
      }
      this.itemIndex = 0;
      this.highlightItem();
      this.showMentionList();
    } else {
      this.hideMentionList();
    }
  }

  nextItem() {
    this.itemIndex = (this.itemIndex + 1) % this.values.length;
    this.highlightItem();
  }

  prevItem() {
    this.itemIndex =
      (this.itemIndex + this.values.length - 1) % this.values.length;
    this.highlightItem();
  }

  hasValidChars(s) {
    return this.options.allowedChars.test(s);
  }

  containerBottomIsNotVisible(topPos) {
    return (
      topPos + this.mentionContainer.offsetHeight >
      window.pageYOffset + window.innerHeight
    );
  }

  containerRightIsNotVisible(leftPos) {
    const rightPos = leftPos + this.mentionContainer.offsetWidth;
    const browserWidth =
      window.pageXOffset + document.documentElement.clientWidth;
    return rightPos > browserWidth;
  }

  setMentionContainerPosition() {
    const containerPos = this.quill.container.getBoundingClientRect();
    const atPos = this.quill.getBounds(this.atPos);
    let topPos =
      window.pageYOffset +
      containerPos.top +
      atPos.bottom +
      this.options.offsetTop;
    let leftPos =
      window.pageXOffset +
      containerPos.left +
      atPos.left +
      this.options.offsetLeft;
    if (this.containerBottomIsNotVisible(topPos)) {
      const overAtPos = window.pageYOffset + containerPos.top + atPos.top;
      const containerHeight =
        this.mentionContainer.offsetHeight + this.options.offsetTop;
      //PH
      if ((overAtPos - containerHeight) > 0) {
        topPos = overAtPos - containerHeight;
      }
    }
    if (this.containerRightIsNotVisible(leftPos)) {
      const containerWidth =
        this.mentionContainer.offsetWidth + this.options.offsetLeft;
      const browserWidth =
        window.pageXOffset + document.documentElement.clientWidth;
      leftPos = browserWidth - containerWidth;
    }
    this.mentionContainer.style.top = `${topPos}px`;
    this.mentionContainer.style.left = `${leftPos}px`;
    this.mentionContainer.style.visibility = "visible";
  }

  onSomethingChange() {
    const range = this.quill.getSelection();
    if (range == null) return;
    this.cursorPos = range.index;
    const startPos = Math.max(0, this.cursorPos - this.options.maxChars);
    const beforeCursorPos = this.quill.getText(
      startPos,
      this.cursorPos - startPos
    );
    //console.log('BEFORE TEXT ' + beforeCursorPos)
    const atSignIndex = beforeCursorPos.lastIndexOf("#");

    if (atSignIndex > 0) {

      if ([' ', '\n', '\t', "↵"].indexOf(beforeCursorPos[atSignIndex - 1]) == -1) {
        return this.hideMentionList();
      }
      if (beforeCursorPos[atSignIndex - 1] == '/') {
        return this.hideMentionList();
      }

      /*if(beforeCursorPos[atSignIndex-1] !=' ')
      {
         this.hideMentionList();
         return;
      }
      if(beforeCursorPos[atSignIndex-1] !='/')
      {
         this.hideMentionList();
         return;
      }*/
    }
    if (atSignIndex > -1) {
      const atPos = this.cursorPos - (beforeCursorPos.length - atSignIndex);
      this.atPos = atPos;
      const textAfterAtPos = beforeCursorPos.substring(atSignIndex + 1);
      if (
        textAfterAtPos.length >= this.options.minChars &&
        this.hasValidChars(textAfterAtPos)
      ) {
        this.options.source(textAfterAtPos, this.renderList);
      } else {
        this.hideMentionList();
      }
    } else {
      this.hideMentionList();
    }
  }

  onTextChange(delta, oldDelta, source) {
    if (source === "user") {
      this.onSomethingChange();
    }
  }

  onSelectionChange(range) {
    if (range && range.length === 0) {
      this.onSomethingChange();
    } else {
      this.hideMentionList();
    }
  }
}
// @ts-ignore
Quill.register("modules/mention", Mention);
