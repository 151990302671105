import * as React from "react";
import * as material from "material-ui";
import * as model from "../lib/model";
import * as actions from "../lib/ActionReducer";
import * as pApi from "../../shared/papi/papi-core";

import { TagSelector } from "../components/TodoEdit/TodoEdit"; 
import * as hotkey from "../lib/hotkeyClient";
import { NoteFunctionType } from "../lib/model";
import { HardwareDesktopWindows } from "material-ui/svg-icons";

export class TagChanges{
    addedTags:Array<string> = new Array<string>();
    removedTags:Array<string> = new Array<string>();
    changed()
    {
        return this.addedTags.length > 0 || this.removedTags.length > 0
    }
}
class TagSearchItem {
  text: string;
  section?: model.AppSections;
  constructor(data: Partial<TagSearchItem>){
    Object.assign(this, data);
}
}
interface BulkTagProp {
  connect: model.ConnectProps;
  todos:Array<pApi.ToDoItem>;
  onDone(changes:TagChanges);
  onClose: () => void;
}
interface BulkTagState {
  tags: Array<string>;
  orginalTags:Array<string>;
}

export default class BulkTag extends React.Component<BulkTagProp, BulkTagState> {
  constructor(props: BulkTagProp, context) {
    super(props, context);

    this.state = {
      tags: BulkTag.getTagsFromTodo(props.todos),
      orginalTags: BulkTag.getTagsFromTodo(props.todos),
      
    };
  }
  static getDerivedStateFromProps(props: BulkTagProp, state: BulkTagState) {
     let todoTags = BulkTag.getTagsFromTodo(props.todos)
     if(todoTags.join(',') != state.orginalTags.join(','))
     {
         return {
             tags :todoTags,
             orginalTags:todoTags
         }
     }
     return null
  }
  public static  getTagsFromTodo(todos:Array<pApi.ToDoItem>) : Array<string>
  {
      let tags = new Map<string,number>()
      for(var i in todos)
      {
          let todo = todos[i];
          if(todo.tags)
          {
                for(var ii in todo.tags)
                {
                    let key = todo.tags[ii];
                    let value = tags.has(key)?tags.get(key):0;
                    value++;
                    tags.set(key,value);
                }
          }
      }
      let retval = new  Array<string>()
      tags.forEach((value:number,key:string)=>{
          if(value == todos.length)
          {
            retval.push(key)
          }
      })
      return retval;
  }
  componentDidMount() {
    let me = this;
    let instance = hotkey.HotKeyEngine.instance();
    let handlers = new Map<hotkey.HotKeyActionType, () => boolean>();
    handlers.set(
      hotkey.HotKeyActionType.esc,
      (): boolean => {
        me.props.onClose();
        return true;
      }
    );
    instance.registerHandlers("bulktag", "bulktag", true, handlers);
  }
  componentWillUnmount() {
    let instance = hotkey.HotKeyEngine.instance();
    instance.unregisterHandlers("bulktag");
  }
  tagChanges():TagChanges
  {
      let tchange = new TagChanges();
      for(var i in this.state.orginalTags)
      {
          let tag = this.state.orginalTags[i];
          if(this.state.tags.find(x=>x==tag))
          {
                //nothing
          }
          else
          {
             tchange.removedTags.push(tag);
          }
      }
      for(var i in this.state.tags)
      {
          let tag = this.state.tags[i];
          if(this.state.orginalTags.find(x=>x==tag))
          {
                //nothing
          }
          else
          {
             tchange.addedTags.push(tag);
          }
      }
      return tchange;
  }
  render() {
   
    let tagChanges = this.tagChanges();
    let me = this;
    const actionsBtn = [
      <material.FlatButton
        label="Cancel"
        primary
        onClick={() => {
          me.props.onClose();
        }}
      />,
      <material.FlatButton
        label="Bulk Update"
        primary
        disabled={!tagChanges.changed()}
        onClick={() => {
          me.props.onDone(tagChanges);
         
        }}
      />
    ];
    return (
      <material.Dialog
        title="Bulk Tag"
        actions={actionsBtn}
        overlayStyle={actions.getOverlayStyle()}
        onRequestClose={e => {
          me.props.onClose();
        }}
        open={true}
      >
        <div className="row">
          <div className="col-12">
          <TagSelector
          autoFocus={true}
          connect={this.props.connect}
          tags={this.state.tags}
          hideLabel={true}
          onCancel={this.props.onClose.bind(this)}
          onUpdate={(tags: Array<string>)=>{
              let state = {...me.state};
              state.tags= tags;
              me.setState(state);
          }}
          onReturnWithNoTag={()=>{
              if(tagChanges.changed())
              {
                  this.props.onDone(tagChanges)
              }
          }}
        />
          </div>
          </div>
          <div className="row">
          <div className="col-12">
            {tagChanges.addedTags.length > 0 && (
              <span>Add&nbsp;
                  {tagChanges.addedTags.map(x=>(
                     <span>#{x}&nbsp;</span>
                  ))}
                 </span>
            )}
             {tagChanges.removedTags.length > 0 && (
              <span>Remove&nbsp;
                  {tagChanges.removedTags.map(x=>(
                     <span>#{x}&nbsp;</span>
                  ))}
                 </span>
            )}
          </div>
        </div>
      </material.Dialog>
    );
  }
}
