import * as React from 'react';

import * as model from "../lib/model";
import * as actions from "../lib/ActionReducer";

import * as pApi from "../../shared/papi/papi-core"
import * as sl from '../../shared/lib/superListTypes'
import Tooltip from '../components/CustomTooltip'
import SettingsIcon from "material-ui/svg-icons/action/settings";
import LogoUrl from '../../styles/todozero-logo.svg'
import { SimpleIconButton } from '../components/SimpleIconButton'
import SelectedSummary from "./SelectedSummary"
interface SidebarProps {
    connect: model.ConnectProps
    
}
interface SidebarState {
 
}

interface HotKeyRowProps {
    name: string;
    buttons: Array<string>;
}
class HotKeyRow extends React.Component<HotKeyRowProps>
{
    render() {
        return (<div className="row  no-gutters simple-action-shortcut-row ">
            <div className="col-6">
                <span className="simple-action-shortcut-name">{this.props.name}</span>
            </div>
            <div className="col-6">
                {this.props.buttons && this.props.buttons.map(x => (
                    <span key={this.props.name + ' ' + x}>
                        {(x == 'or' || x == '+') && (
                            <span>{x}</span>
                        )
                        }
                        {x != 'or' && x != '+' && (

                            <span className="hotkey-button">{x}</span>

                        )
                        }
                    </span>
                ))}

            </div>

        </div>)
    }
}

export class RightBar extends React.Component<SidebarProps, SidebarState> {
    constructor(props, context) {
        super(props, context);
        

    }
    
    settingsClick(e) {

    }
    render() {
        
        let selected = this.props.connect.state.selectedState.selectedRows;// []// this.props.connect.state.inboxData.getSelectedTodos();
        let focused = this.props.connect.state.selectedState.focused;// this.props.connect.state.inboxData.focused;
        let me = this;
        let Actions = [

        ]
     
        Actions.push({
            section: 'Navigation'
        });
        Actions.push({
            name: 'Go to',
            buttons: ['G']
        });
        Actions.push({
            name: 'Search',
            buttons: ['/']
        });
        Actions.push({
            name: 'Next Section',
            buttons: ["Tab"]
        });
        Actions.push({
            name: 'Previous Section',
            buttons: ["Shift", "+", "Tab"]
        });
        
            Actions.push({
                name: 'Next Item',
                buttons: ['J']
            });
            Actions.push({
                name: 'Previous Item',
                buttons: ['K']
            });
            Actions.push({
                name: 'Multiselect',
                buttons: ["Shift", "+", "J", "or", "K"]
            });
        

        /*
              <HotKeyRow name="Next" buttons={["K"]} />
                                        <HotKeyRow name="Previous" buttons={["J"]} />
                                        <HotKeyRow name="Multiselect" buttons={["Shift", "+", "J", "or", "K"]} />
                                        <HotKeyRow name="Go to" buttons={["G"]} />
                                        <HotKeyRow name="Search" buttons={["/"]} />
        */
        if (selected.length == 0 || !focused) {
            Actions.push({
                section: 'Create'
            });
            Actions.push({
                name: 'Create To-Do',
                buttons: ['c']
            });
            Actions.push({
                name: 'Create Note',
                buttons: ['C']
            });
        }
        if (selected.length == 0 && focused && focused instanceof sl.NoteRow) {
            let f = focused as sl.NoteRow
            let text = 'Actions'
            
                Actions.push({
                    section: text
                });
                Actions.push({
                    name: 'Open Note',
                    buttons: ['Enter','or','O']
                })
          
           
        }
        

        if (selected.length > 0 || (focused && !(focused instanceof sl.NoteRow)) ) {
            let text = 'Actions'
            if (selected.length > 1) {
                text = `Actions (${selected.length} selected)`
            }
            Actions.push({
                section: text
            });
            Actions.push({
                name: 'Complete',
                buttons: ['E']
            });
            Actions.push({
                name: 'Snooze',
                buttons: ['H']
            })
            Actions.push({
                name: 'Toggle Star',
                buttons: ['S']
            })
            Actions.push({
                name: 'Tag',
                buttons: ['T']
            })
           
            Actions.push({})

        }

        if (selected.length == 0 && focused && focused instanceof sl.GroupRow) {
            Actions.push({
                name: 'Expand Group',
                buttons: ['Enter']
            })
        }
        
        if (selected.length == 0 && focused && focused instanceof sl.ItemRow) {
            let f = focused as sl.ItemRow
            Actions.push({
                name: 'Open To-Do',
                buttons: ['Enter']
            })

            let link = model.TodoLinkHelper.getLink(f.item)
            if (link) {
                Actions.push({
                    name: 'Open Link',
                    buttons: ['O']
                })
            }
            else {

                if (f.item.note_id) {
                    Actions.push({
                        name: 'Open Note',
                        buttons: ['O']
                    })
                }

            }
            if (f.item.hide_from_inbox) {
                Actions.push({
                    name: 'Show in Inbox',
                    buttons: ['U']
                })
            }
            else if (pApi.CanHideToDo(f.item)) {
                Actions.push({
                    name: 'Hide From Inbox',
                    buttons: ['U']
                })
            } 


            let allSelectedTodos = new Array<pApi.ToDoItem>();
            for (var i in selected) {
                if (selected[i] instanceof sl.ItemRow) {
                    allSelectedTodos.push((selected[i] as sl.ItemRow).item);
                }
                else if (selected[i] instanceof sl.GroupRow) {
                    for (var ii in (selected[i] as sl.GroupRow).subRows) {
                        allSelectedTodos.push((selected[i] as sl.GroupRow).subRows[ii].item);
                    }
                }
            }
            if (allSelectedTodos.length > 1) {
                if (selected.length == allSelectedTodos.filter(x => x.hide_from_inbox).length) {
                    Actions.push({
                        name: 'Show in Inbox',
                        buttons: ['U']
                    })
                }
                else {
                    let hideCandidate = allSelectedTodos.filter(x => pApi.CanHideToDo(x));
                    if (hideCandidate.length > 0) {
                        Actions.push({
                            name: 'Hide From Inbox',
                            buttons: ['U']
                        })
                    }
                }
            }
        }
        let status = (<p></p>)
        if (!this.props.connect.state.isOnline) {
            status = (<Tooltip title="Changes will be synced when your back online."><p style={{cursor:"default"}}>Offline</p></Tooltip>)
        }
        if (this.props.connect.state.noteState == model.LoadingState.Loading) {
            status = (<Tooltip title="Syncing Contents."><p style={{cursor:"default"}}>Syncing</p></Tooltip>)
        }
        if (this.props.connect.state.lastError) {
            status = (<Tooltip title={this.props.connect.state.lastError.userMessage}><p style={{cursor:"default"}}>There was a problem :(</p></Tooltip>)
        }


        return (
            <div>

                <SelectedSummary connect={this.props.connect} />
                <div className='container   no-gutters simple-action-shortcut-row '>

                    {Actions.map(x => (
                        <div key={x.name}>

                            {x.section && (
                                <div className="row  no-gutters">
                                    <div className="simple-action-title">
                                        {x.section}
                                    </div>
                                </div>
                            )}
                            {!x.section && (
                                <HotKeyRow name={x.name} buttons={x.buttons} />
                            )}
                        </div>
                    ))}


                </div>
                <div className='logo-settings'>
                    <div className='container-fluid' >
                        <div className='row' style={{ 'height': '20px' }} >
                            <div className="col-6">
                                <img className='superlist-logo-image' src={LogoUrl} />
                            </div>
                            <div className="col-6">
                                <SimpleIconButton
                                    style={{
                                        position: 'absolute',
                                        right: 0
                                    }}
                                    alt="Settings"
                                    size={16}
                                    icon={SettingsIcon} onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        let vs = this.props.connect.state.viewState.deepCopy();
                                        vs.appSection = model.AppSections.settings
                                        window.location.hash = vs.getHash();

                                        return true;
                                    }
                                    } />
                            </div> </div>
                        <div className='row' style={{ 'height': '30px' }}>
                            <div className="col-12" style={{ 'textAlign': 'center' }}>
                                {status}

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}



