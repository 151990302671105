import { DescribeSuggestersResponse } from "aws-sdk/clients/cloudsearch";
import { idList } from "aws-sdk/clients/datapipeline";
import * as React from "react";
import { connect } from "react-redux";

import { Link } from "react-router";
import { AvatarButton } from "./AvatarButton";
import SearchBar from "./SearchBar";
import * as material from "material-ui";
import * as model from "../../lib/model"
import * as actions from "../../lib/ActionReducer"
import * as pApi from "../../../shared/papi/papi-core"
import * as hotkey from "../../lib/hotkeyClient";
import LayoutIcon from "material-ui/svg-icons/action/chrome-reader-mode";
import AutoRenewIcon from "material-ui/svg-icons/action/autorenew";
import InSyncIcon from "material-ui/svg-icons/file/cloud-done";
import IconButton from "material-ui/IconButton";
import ListIcon from "material-ui/svg-icons/action/list";
import OfflineIcon from "material-ui/svg-icons/file/cloud-off";
import TodoListIcon from "material-ui/svg-icons/action/assignment-turned-in";
import SearchIcon from "material-ui/svg-icons/action/search";
import NoteIcon from "material-ui/svg-icons/av/note";
import NavigationIcon from "material-ui/svg-icons/navigation/menu";
import WarningIcon from "material-ui/svg-icons/alert/warning";
import * as Material from "material-ui";


var classnames = require("classnames");
interface HeaderProps {
  handleToggleCollapsedNav: any;
  uiConfig: model.UIConfig;
  todoView: model.TodoView;
  connect: model.ConnectProps;
}
class KeyValueType {
  id: string;
  name: string;
  type?: string;
}
interface HeaderState {
  profileOpen: boolean;
  anchorEl: any;
  searchText: string;
  preSearchHash: any;
  mobileSearch: boolean;
}
export class Header extends React.Component<HeaderProps, HeaderState> {
  constructor(props, context) {
    super(props, context);
    this.state = {
      mobileSearch: false,
      searchText: "",
      profileOpen: false,
      anchorEl: null,
      preSearchHash: ""
    };
  }
  componentDidMount() {
    let me = this;
    let instance = hotkey.HotKeyEngine.instance();
    let handlers = new Map<hotkey.HotKeyActionType, () => boolean>();
    handlers.set(
      hotkey.HotKeyActionType.search,
      (): boolean => {
        if (me.searchInput) {
          me.searchInput.focus();
          me.searchInput.click();
          //me.searchInput.setFocus("click");
          return true;
        } else {
          return false;
        }
      }
    );
    instance.registerHandlers("header", "inbox", false, handlers);
  }
  componentWillUnmount() {
    let instance = hotkey.HotKeyEngine.instance();
    instance.unregisterHandlers("header");
  }
  componentWillReceiveProps(nextProps: HeaderProps) {
    if (
      nextProps.connect.state.searchKeyState == model.LoadingState.NotLoaded
    ) {
      this.props.connect.dispatch(actions.ar_getSearchKey());
    }
    if (!nextProps.connect.state.viewState.searchText) {
      this.setState({
        preSearchHash: this.state.preSearchHash,
        profileOpen: this.state.profileOpen,
        anchorEl: this.state.profileOpen,
        mobileSearch: this.state.mobileSearch,
        searchText: ""
      });
    }
    return true;
  }

  componentDidUpdate() {}
  onToggleCollapsedNav2 = e => {
    // actions.hideKeyboard();
    //TODO supper hack.  don't know why this doesn't work when i put it over the correct div

    if (this.props.connect.state.viewState.noteOpen()) {
      var nv = this.props.connect.state.viewState.deepCopy();
      nv.noteViewState.noteId = null;
      nv.noteViewState.openFirstNote = false;
      if (
        this.props.connect.state.viewState.noteViewState.functionType ==
        model.NoteFunctionType.tag
      ) {
        nv.noteViewState.noteViewType = model.NoteViewType.noteList;
      }
      if (
        this.props.connect.state.viewState.noteViewState.functionType ==
        model.NoteFunctionType.all
      ) {
        nv.noteViewState.functionType = model.NoteFunctionType.all;
        nv.noteViewState.noteViewType = model.NoteViewType.noteList;
      }
      window.location.hash = nv.getHash();

      e.stopPropagation();
      actions.hideKeyboard();
      return true;
    } else {
      //$body.toggleClass("sidebar-mobile-open");
      var val = !this.props.uiConfig.layout.navCollapsed;

      //const { handleToggleCollapsedNav } =
      this.props.handleToggleCollapsedNav(val);
      e.stopPropagation();
      actions.hideKeyboard();
      return true;
    }
  };

  search(value, e) {
    console.log("request" + this.refs.searchBar["state"].value);
    var arr = this.refs.searchBar["props"].dataSource.filter(
      x => x.name == value
    );
    var split;
    if (arr.length > 0) {
      split = arr[0].id.split(":");
    } else {
      let x = this.refs.searchBar["props"].dataSource.find(x =>
        this.refs.searchBar["props"].filter(
          this.refs.searchBar["state"].value,
          x.name
        )
      );
      if (x) split = x.id.split(":");
    }
    if (split) {
      var vs = this.props.connect.state.viewState.deepCopy();
      switch (split[0]) {
        case "note":
          vs.appSection = model.AppSections.notes;
          vs.noteViewState = vs.noteViewState
            ? vs.noteViewState
            : new model.NoteViewState();
          vs.noteViewState.noteId = split[1];
          return (window.location.hash = vs.getHash());
        case "tag":
          vs.appSection = model.AppSections.notes;
          vs.noteViewState = vs.noteViewState
            ? vs.noteViewState
            : new model.NoteViewState();
          vs.noteViewState.functionType = model.NoteFunctionType.tag;
          vs.noteViewState.query = split[1];
          vs.noteViewState.noteId = null;
          return (window.location.hash = vs.getHash());
        default:
          vs.appSection = model.AppSections.inbox;
          return (window.location.hash = vs.getHash());
      }
    }
  }
  handleProfileClick = event => {
    // This prevents ghost click.
    event.preventDefault();

    this.setState({
      preSearchHash: this.state.preSearchHash,
      profileOpen: true,
      anchorEl: event.currentTarget,
      mobileSearch: this.state.mobileSearch
    });
  };

  handleProfileClose = () => {
    this.setState({
      preSearchHash: this.state.preSearchHash,
      mobileSearch: this.state.mobileSearch,
      profileOpen: false,
      anchorEl: null
    });
  };
  showErrorModal = e => {
    e.preventDefault();
    e.stopPropagation();
    this.props.connect.dispatch<any>(actions.ar_showErrorModal());
  };
  signOut = () => {
    //;
    this.props.connect.dispatch(actions.ar_signout());
  };
  searchInput: any;
  render() {
    const { fixedHeader, colorOption } = this.props.uiConfig.layout;
    const dataSourceConfig = {
      text: "text",
      value: "value"
    };

    var headerstring = "";
    let showStar = false;
    var vs = this.props.connect.state.viewState;
    switch (vs.appSection) {
      case model.AppSections.inbox:
        headerstring = "Inbox";
        break;
      case model.AppSections.today:
        headerstring = "Today";
        break;
      case model.AppSections.thisweek:
        headerstring = "This Week";
        break;
      case model.AppSections.snoozed:
        headerstring = "Upcoming";
        break;
        case model.AppSections.bydate:
          headerstring = "By Date";
          break;
      case model.AppSections.objectives:
        headerstring = "Objectives";
        break;
      case model.AppSections.archived:
        headerstring = "Archived";
        break;
      case model.AppSections.search:
        headerstring = "Search";
        break;
      case model.AppSections.settings:
        headerstring = "Settings";
        break;
      case model.AppSections.help:
        headerstring = "Help";
        break;
      case model.AppSections.notes:
        //if (!vs.noteViewState.noteId) {
        if (vs.noteViewState.functionType == model.NoteFunctionType.tag) {
          showStar = true;
          headerstring = "#" + vs.noteViewState.query;
        } else {
          headerstring = "Notes";
        }
      //}
    }
    var notecount = null;
    if (this.props.connect.state.TodosState == model.LoadingState.Completed) {
      notecount = this.props.connect.state.todos.length;
    }
    var loadSpinner =
      this.props.connect.state.TodosState == model.LoadingState.Loading ||
      this.props.connect.state.notes.loading() 

    var mobileSearchMode =
      this.state.mobileSearch &&
      this.props.connect.getPresentationMode() == model.PresentationMode.mobile;
    var ToggleComponent = (
      <div className="float-left joyride-toggle" style={{ paddingLeft: "4px" }}>
        <material.IconButton
          className=""
          style={{ paddingTop: "19px" }}
          onClick={this.onToggleCollapsedNav2}
        >
          <NavigationIcon color="white" />
        </material.IconButton>
      </div>
    );
    var me = this;
    var searchBar =  (
      <SearchBar
        ref="searchBar"
         onFocus={ event => {
          if (
            this.props.connect.state.viewState.appSection !=
            model.AppSections.search
          ) {
            me.setState({
              preSearchHash: window.location.hash,
              profileOpen: true,
              anchorEl: event?event.currentTarget:null
            });
          }
          window.location.hash = "#/search/";
        }}
        onInputMounted={async input => {
          if (this.state.mobileSearch) {
            input.focus();
            input.click();
          //  input.setFocus("click");
          }
          // forgive me for i have sinned
          this.searchInput = input;
        }}
        onCancel={async event => {
          this.setState({
            preSearchHash: this.state.preSearchHash,
            profileOpen: true,
            searchText: event,
            anchorEl: event.currentTarget,
            mobileSearch: false
          });
          window.location.hash = this.state.preSearchHash
            ? this.state.preSearchHash
            : "";
        }}
        onChange={async event => {
          let mobileSearch = this.state.mobileSearch;
          if (event) {
            window.location.hash = "#/search/" + encodeURIComponent(event);
          } else {
            window.location.hash = "#/search/";
          }

          this.setState({
            preSearchHash: this.state.preSearchHash,
            profileOpen: true,
            searchText: event,
            anchorEl: event.currentTarget,
            mobileSearch: mobileSearch
          });
          
          if (event ) {
            me.props.connect.dispatch<any>(actions.ar_search(event))
            // window.location.hash = "#/search/" + encodeURIComponent(e.target.value);
          } else {
            //window.location.hash = "#/search/";
            me.props.connect.dispatch<any>(actions.ar_search(null))
          }
          //   ;
          // this.processProjectChange(this.refs.searchBar['state'].value, -1)
        }}
        onRequestSearch={e => {}}
        onBlur={e => {
          if (!this.state.searchText.trim()) {
            window.location.hash = this.state.preSearchHash;
          }
          this.setState({
            preSearchHash: this.state.preSearchHash,
            profileOpen: this.state.profileOpen,
            searchText: this.state.searchText,
            anchorEl: this.state.anchorEl,
            mobileSearch: false
          });
        }}
        hintText="Search..."
        filter={(searchText: string, key: any): boolean => {
          return true;
        
        }}
        value={this.state.searchText}
        style={{
          margin: "0 auto"
        }}
      />
    );
    let showWarning: any = this.props.connect.state.lastError;
    if (showWarning) {
      try {
        var dif = new Date().getTime() - showWarning.timestamp.getTime();

        var Seconds_from_T1_to_T2 = dif / 1000;
        showWarning = Seconds_from_T1_to_T2 < 10;
      } catch (e) {
        //ignore
      }
    }
    /*if(this.props.connect.inMobileNoteView()){
       return (
         <div style={{'display':'none'}}
         />
       )
    }*/

    let loadingTooltip = "Online";
    if (loadSpinner) {
      loadingTooltip = "Syncing";
    } else if (!this.props.connect.state.isOnline) {
      loadingTooltip = "Offline";
    }
    return (
      <section className="app-header">
        <div
          style={{
            height: "64px"
          }}
          className={classnames("app-header-inner", {
            "bg-color-light":
              ["11", "12", "13", "14", "15", "16", "21"].indexOf(colorOption) >=
              0,
            "bg-color-dark": colorOption === "31",
            "bg-color-primary": ["22", "32"].indexOf(colorOption) >= 0,
            "bg-color-success": ["23", "33"].indexOf(colorOption) >= 0,
            "bg-color-info": ["24", "34"].indexOf(colorOption) >= 0,
            "bg-color-warning": ["25", "35"].indexOf(colorOption) >= 0,
            "bg-color-danger": ["26", "36"].indexOf(colorOption) >= 0
          })}
        >
          <div className="fluid-container">
            {!mobileSearchMode && (
              <div className="row">
                <div
                  className="col-8 col-md-3 "
                
                >
                  {ToggleComponent}

                  <div
                    className={classnames("float-left", {
                      "bg-color-light":
                        ["11", "12", "13", "14", "15", "16", "21"].indexOf(
                          colorOption
                        ) >= 0,
                      "bg-color-dark": colorOption === "31",
                      "bg-color-primary":
                        ["22", "32"].indexOf(colorOption) >= 0,
                      "bg-color-success":
                        ["23", "33"].indexOf(colorOption) >= 0,
                      "bg-color-info": ["24", "34"].indexOf(colorOption) >= 0,
                      "bg-color-warning":
                        ["25", "35"].indexOf(colorOption) >= 0,
                      "bg-color-danger": ["26", "36"].indexOf(colorOption) >= 0
                    })}
                    style={{
                      paddingTop: "22px",
                      paddingLeft: "16px",
                      fontSize: "1.8em",

                      overflow: "hidden",
                      textOverflow: "ellipsis",

                      textAlign: "left",
                      height: "49px",
                      whiteSpace: "nowrap"
                    }}
                  >
                    {headerstring}
                  </div>
                </div>

                <div
                  className={"col-md-6 hidden-sm-down"}
                  style={{ paddingTop: "13px", paddingBottom: "0px" }}
                >
                  <div className="">{searchBar}</div>
                </div>

                <div className="col-4 col-md-3">
                  <div className="float-right   hidden-sm-down">
                    <AvatarButton connect={this.props.connect} />
                  </div>

                  <div className="float-right  hidden-md-up">
                    <material.IconButton
                      onClick={e => {
                        let mobileSearch = !this.state.mobileSearch;
                        this.setState({
                          preSearchHash: this.state.preSearchHash,
                          profileOpen: this.state.profileOpen,
                          anchorEl: this.state.profileOpen,
                          mobileSearch: mobileSearch,
                          searchText: this.state.searchText
                        });
                      }}
                      style={{ paddingTop: "19px" }}
                    >
                      <SearchIcon className="" color="white" />
                    </material.IconButton>
                  </div>

                  {!showWarning && (
                    <div className="float-right">
                      <material.IconButton
                        tooltip={loadingTooltip}
                        style={{ cursor: "default", paddingTop: "19px" }}
                      >
                        {loadSpinner && (
                          <AutoRenewIcon
                            className="ql-processing"
                            color="white"
                          />
                        )}
                        {!loadSpinner &&
                          !this.props.connect.state.isOnline && (
                            <OfflineIcon color="gray" />
                          )}
                        {!loadSpinner &&
                          this.props.connect.state.isOnline && (
                            <InSyncIcon className="" color="white" />
                          )}
                        }
                      </material.IconButton>
                    </div>
                  )}
                  {showWarning && (
                    <div className="float-right">
                      <material.IconButton
                        onClick={this.showErrorModal.bind(this)}
                        tooltip={this.props.connect.state.lastError.userMessage}
                        style={{ paddingTop: "19px" }}
                      >
                        <WarningIcon color="yellow" />
                      </material.IconButton>
                    </div>
                  )}
                </div>
              </div>
            )}
            {mobileSearchMode && (
              <div className="row">
                <div className="col-2 ">
                  {ToggleComponent}
                </div>
                <div
                  className={"col-9 "}
                  style={{ paddingTop: "13px", paddingBottom: "0px" }}
                >
                  <div className="">{searchBar}</div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    );
  }
}
