import * as React from 'react';
import pure from 'recompose/pure';
import {SvgIcon} from 'material-ui';


let Unwatch   = (props) => (
    
  <SvgIcon {...props}  version="1.1" id="svg2" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
viewBox="0 0 48 48"  >
<svg
 >
   <path d="M0 0h48v48h-48zm0 0h48v48h-48zm0 0h48v48h-48zm0 0h48v48h-48z" fill="none"/>
    <path d="M24 14c5.52 0 10 4.48 10 10 0 1.29-.26 2.52-.71 3.65l5.85 5.85c3.02-2.52 5.4-5.78 6.87-9.5-3.47-8.78-12-15-22.01-15-2.8 0-5.48.5-7.97 1.4l4.32 4.31c1.13-.44 2.36-.71 3.65-.71zm-20-5.45l4.56 4.56.91.91c-3.3 2.58-5.91 6.01-7.47 9.98 3.46 8.78 12 15 22 15 3.1 0 6.06-.6 8.77-1.69l.85.85 5.83 5.84 2.55-2.54-35.45-35.46-2.55 2.55zm11.06 11.05l3.09 3.09c-.09.43-.15.86-.15 1.31 0 3.31 2.69 6 6 6 .45 0 .88-.06 1.3-.15l3.09 3.09c-1.33.66-2.81 1.06-4.39 1.06-5.52 0-10-4.48-10-10 0-1.58.4-3.06 1.06-4.4zm8.61-1.57l6.3 6.3.03-.33c0-3.31-2.69-6-6-6l-.33.03z"/>
</svg>
</SvgIcon>
);
Unwatch = pure(Unwatch);
/*Target.displayName = 'ActionAccessibility';
Target.muiName = 'SvgIcon';*/

export default Unwatch;