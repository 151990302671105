import * as React from "react";

import * as actions from "../lib/ActionReducer";
import * as model from "../lib/model";
import * as pApi from "../../shared/papi/papi-core";
import SuperList from "../SuperList/SuperList"
import { NoteViewState } from "../lib/model";
import {doSearch, SearchResult} from '../lib/search'
import moment from 'moment'

var algoliasearch = require("algoliasearch");

interface SearchProps {
    searchText: string;
    connect: model.ConnectProps;
}
interface SearchState {
    started: boolean;
    todoMatch: Array<pApi.ToDoItem>;
    noteMatch: model.LoadingObject<Array<pApi.Note>>
    orderBy:Array<string>;
    tagsResult: Array<string>;
    searchText: string;
}

const styles = {
    checkbox: {
        maxWidth: 250,
        marginBottom: 16
    }
};

export default class Search extends React.Component<SearchProps, SearchState> {
    constructor(props:SearchProps)
    {
        super(props);
        this.state = {
            todoMatch: [],
            noteMatch:new model.LoadingObject<Array<pApi.Note>>([]),
            tagsResult: [],
            started:false,
            orderBy:[],
            searchText:''
          

        }
    }
    componentDidMount() {
        //this.componentWillReceiveProps(this.props);
        this.props.connect.dispatch<any>(actions.ar_search(''))
        return true;
    }
    /*
    static getDerivedStateFromProps(props: SearchProps, state:SearchState) {
        
        if(props.searchText != state.searchText)
        {
            if(state.started)
            {
                state.searchText ="";
            }
            else
            {state.searchText = props.searchText}
           state.started = true;
            
        }
        return state;

    }*/
    /*
        //console.log((state.queryId != props.queryResult.id)+" "+state.queryId+' '+props.queryResult.id+' getDerivedStateFromProps')
        if(nextProps.searchText != this.)
        if (props.todos && ((state.todoQueryId != props.todoQueryId || state.noteQueryId != props.notes.version) || props.mode != state.mode || props.tag != state.tag)) {

            console.log('update query')
            state.rows = sl.getSuperListRows(props.todos, props.notes.val(), props.mode, props.tag, state.expandedGroups,props.orderBy);
            state.todoQueryId = props.todoQueryId;
            state.noteQueryId = props.notes.version;
            state.mode = props.mode
            state.updateTimeStamp = new Date().toISOString()
            return state;
        }
        return null;*/
    
    componentWillReceiveProps(nextProps: SearchProps) {
        
        if(nextProps.searchText && nextProps.searchText != this.props.searchText)
        {
            doSearch(nextProps.connect,nextProps.searchText).then((sr:SearchResult)=>{
                    var s = {
                        todoMatch: sr.todoMatch,
                        noteMatch:sr.noteMatch,
                        started: true,
                        tagsResult: sr.tagResults,
                        searchText: sr.searchText,
                        orderBy:sr.orderBy
                    };
                    this.setState(s);
            })
            return true;
        }
        return true;

       
    }
  


    async onChange(e) { }

    toArr(t: pApi.ToDoItem): Array<pApi.ToDoItem> {
        var arr = new Array<pApi.ToDoItem>();
        arr.push(t);
        return arr;
    }

    render() {
        //cordova.plugins.notification.badge.set(10);

        if (this.props.connect.state.TodosState == model.LoadingState.Loading) {
            return (
                <div
                    style={{
                        height: window.innerHeight,
                        width: "120%"
                    }}
                />
            );
        }
        if (this.props.connect.state.TodosState == model.LoadingState.Error) {
            return (
                <div
                    style={{
                        height: window.innerHeight,
                        width: "120%"
                    }}
                >
                    error ...
        </div>
            );
        }
        if (this.props.connect.state.TodosState == model.LoadingState.NotLoaded) {
            return (
                <div
                    style={{
                        height: window.innerHeight,
                        width: "120%"
                    }}
                />
            );
        }

        
        if (!this.state || !this.state.orderBy || this.state.orderBy.length == 0) {
            return (
                <div
                    style={{
                       
                        "backgroundRepeat": "no-repeat",
                        "backgroundAttachment": "fixed",
                        "backgroundPosition": "center",
                        height: window.innerHeight,
                        width: "120%"
                    }}
                ></div>
            );
        }

        var now = new Date();
        var me = this;
        function hasTags(t: pApi.ToDoItem): boolean {
            if (!t.tags) return false;
            if (t.tags.length == 0) return false;
            return true;
        }
  
        var me = this;
        let getTagHash = function (tag) {
            var vs = me.props.connect.state.viewState.deepCopy();
            vs.appSection = model.AppSections.notes;
            vs.noteViewState = new NoteViewState();
            vs.noteViewState.functionType = model.NoteFunctionType.tag;
            vs.noteViewState.noteViewType = model.NoteViewType.noteList;
            vs.noteViewState.query = tag;
            return vs.getHash();
        };
        return (
            <div style={{ width: '100%' }}>
                {!this.props.connect.state.isOnline && (
                    <div
                        style={{
                            "textAlign": "center",
                            color: "orange"
                        }}
                    >
                        <b> You are offline. Search results for notes are limited.</b>
                    </div>
                )}
               
               
{this.state.orderBy && this.state.orderBy.length > 0 &&
                    <SuperList  
           todos= {this.state.todoMatch}
           todoQueryId= {this.state.searchText}
           notes={this.state.noteMatch}
           tag={null}
           orderBy={this.state.orderBy}
           mode={model.InboxMode.search}
           connect={this.props.connect}
           sortByCreateDate={false}
           mobileView={this.props.connect.getPresentationMode() == model.PresentationMode.mobile} />
}
                     
</div>
               
        );
    }
}
