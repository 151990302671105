import * as React from "react";
import ReactBody from "react-body";

import { Header } from "../Mobile/Header/NewHeader";
import { NoteSidebar } from "../components/NoteSideNav/NoteSidebar";//"../../Mobile/NoteSideNav/NoteSidebar";
//import { Search } from "../Mobile/Search/Search";

import { AccountSetup } from "../Mobile/AccountSetup/AccountSetup";
//import { TagView } from "../Mobile/TagView/TagView";
//import { Inbox } from "../Mobile/Inbox/Inbox";
//import * as inboxView from "../Mobile/Inbox/InboxView";
import ImageViewer from '../components/ImageViewer'
import { InboxMode } from "../lib/model"
import * as listTypes from "../../shared/lib/superListTypes"
import SuperList from '../SuperList/SuperList'
import { TodoEdit } from "../components/TodoEdit/TodoEdit";
import { Settings } from "../components/Settings/Settings"
import { Help } from "../components/Help/Help";
import { QuillContainer } from "../components/Editor/QuillContainer";
import * as actions from "../lib/ActionReducer"
import * as material from "material-ui";
import * as pApi from "../../shared/papi/papi-core";// "../../../server/papi/papi-core";
import { AddButton } from "../Mobile/LayoutButton/AddButton";
import { Progress } from "../components/Progress/Progress";
import { ErrorBox } from "../components/ErrorBox/ErrorBox";
import * as model from "../lib/model"

import * as hotkey from "../lib/hotkeyClient"
import { TagSearch } from "../components/Command";

import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Slide from '@material-ui/core/Slide';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ScrollRefresh from "./ScrollRefresh/ScrollRefresh"
import BottomMenu from "./BottomMenu"
import TopMenu from "./TopMenu"
import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

import PostAddIcon from '@material-ui/icons/PostAdd';
import { JumpTo, SearchMobile } from './JumpTo'
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import uuidV4 from "uuid/v4"
import { ImageHandler } from "../components/Editor/ImageHandler"
var $ = require("jquery");
var _ = require("lodash");
var classnames = require("classnames");

const useStyles = makeStyles((theme) => ({

    appBar: {
        top: 'auto',
        bottom: 0,

    },
    margin: {
        margin: theme.spacing(1),
    },
    topRoot: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    divBar: {
        position: "sticky",
        top: "auto",
        bottom: 0,
        background: 'white',

    },
    topBar: {
        position: "sticky",
        top: 0,

        background: 'white',
    },
    grow: {
        flexGrow: 1,
    },
    button: {
        focus: "outline: none !important;"

    },
    root: {
        '& > * + *': {
            marginLeft: theme.spacing(2),
        },
    },
    fabButton: {
        position: 'absolute',
        zIndex: 1,
        bottom: theme.spacing(12),

        right: 0,
        margin: '0 auto',
    },
    fabButtonNote: {
        position: 'absolute',
        zIndex: 1,
        bottom: theme.spacing(12),


        right: 30,
        margin: '0 auto',
    },
    fabLocation: {
        position: 'absolute',
        zIndex: 1,
        bottom: theme.spacing(12),


        right: 0,
        margin: '0 auto',
    }

}));

class BottomHeaderProps {
    scrollingTarget: any;
    direction: 'down'
        | 'left'
        | 'right'
        | 'up';
    children: any;

}
function BottomHeader(props: BottomHeaderProps) {
    const { children, direction, scrollingTarget } = props;
    const classes = useStyles();

    return (
        <HideOnScroll direction={direction} scrollingTarget={scrollingTarget}>

            <div className={classes.divBar}>
                <div className={classes.grow} >
                    {children}
                </div>
            </div>
        </HideOnScroll>)
}
function TopHeader(props: BottomHeaderProps) {
    const { children, direction, scrollingTarget } = props;
    const classes = useStyles();
    let me = this;

    return (
        <HideOnScroll direction={direction} scrollingTarget={scrollingTarget}>

            <AppBar>
                {children}
            </AppBar>


        </HideOnScroll>)
}

class HideOnScrollProps {
    scrollingTarget: any;
    direction: 'down'
        | 'left'
        | 'right'
        | 'up';
    children: any
}
function HideOnScroll(props: HideOnScrollProps) {
    const { children, scrollingTarget } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    let targetParams = props.scrollingTarget ? { target: props.scrollingTarget } : {
        target: window
    };
    const trigger = useScrollTrigger(targetParams);

    return (
        <Slide appear={false} direction={props.direction} in={!trigger}>
            {children}
        </Slide>
    );
}

//let classes  :any = useStyles();

interface ShellFastState {
    tagsearchPopup: Boolean;
}
interface ShellFastProps {
    connect: model.ConnectProps;
    classes: any
}
class ShellFast extends React.Component<ShellFastProps, ShellFastState> {
    nothing() { }

    getHeight(): string {
        return window.innerHeight - 20 + "px";
    }

    constructor(props: ShellFastProps) {
        super(props);
        this.setState({
            tagsearchPopup: false
        });


    }


    componentDidMount() {

        let me = this;

        $(window).resize("resize", () => {
            me.props.connect.dispatch<any>(actions.ar_windowResize());
        });

        let instance = hotkey.HotKeyEngine.instance();
        let handlers = new Map<hotkey.HotKeyActionType, () => boolean>();
        handlers.set(
            hotkey.HotKeyActionType.undo,
            (): boolean => {
                if (
                    me.props.connect.state.snackMessage &&
                    me.props.connect.state.snackMessage.actionText == "undo"
                ) {
                    me.props.connect.dispatch<any>(actions.ar_snackBarAction(null));
                    return true;
                }
                return false;
            }
        );
        handlers.set(
            hotkey.HotKeyActionType.jumpTag,
            (): boolean => {
                let s = { ...me.state };
                s.tagsearchPopup = true;
                me.setState(s);
                return true;
            }
        );

        this.props.connect.dispatch<any>(actions.ar_loadInbox())
        this.props.connect.dispatch<any>(actions.ar_loadNotes())
        this.props.connect.dispatch<any>(actions.ar_loadTags());
        // instance.registerHandlers("ShellFast", "inbox-m", false, handlers);

    }
    componentWillUnmount() {

        let instance = hotkey.HotKeyEngine.instance();
        instance.unregisterHandlers("ShellFast");

    }

    populateStateFromHash(state: model.IState, match): model.IState {
        var newState = { ...state };
        var nv = model.ViewState.viewStateFromRoute(match);

        newState.viewState = nv;
        return newState;
    }
    quote: string;
    navToNote(noteId: any) {
        var newNS = this.props.connect.state.viewState.deepCopy();
        if (!newNS.noteViewState) {
            newNS.noteViewState = new model.NoteViewState();
        }
        newNS.noteViewState.noteId = noteId;
        newNS.appSection = model.AppSections.notes;
        window.location.hash = newNS.getHash();
    }
    getTagsFromUrl() {
        var tags = [];
        let vs = this.props.connect.state.viewState.noteViewState;
        if (vs) {
            if (vs.functionType == model.NoteFunctionType.tag) {
                tags.push(vs.query);
            }
        }
        return tags;
    }
    render() {

        let me = this;
        let connect = this.props.connect
        let viewState = this.props.connect.state.viewState;
        let tag = viewState.getTag();
        
        function getInboxSetting() {
            switch (connect.state.viewState.appSection) {
                case model.AppSections.inbox:
                    return InboxMode.inbox;
                case model.AppSections.today:
                    return InboxMode.today;
                case model.AppSections.thisweek:
                    return InboxMode.thisWeek;
                case model.AppSections.bydate:
                    return InboxMode.bydate;
                case model.AppSections.snoozed:
                    return InboxMode.onlysnoozed;
                case model.AppSections.archived:
                    return InboxMode.archive;
                case model.AppSections.notes:
                    if (tag)
                        return InboxMode.bytag;
                    else
                        return InboxMode.allNotes;
            }
            return InboxMode.inbox;
        }
        /*function getInboxSetting() {
            switch (connect.state.viewState.appSection) {
              case model.AppSections.inbox:
                return listTypes.InboxMode.inbox;
              case model.AppSections.today:
                return inboxView.InboxMode.today;
              case model.AppSections.thisweek:
                return inboxView.InboxMode.thisWeek;
              case model.AppSections.snoozed:
                return inboxView.InboxMode.onlysnoozed;
              case model.AppSections.archived:
                return inboxView.InboxMode.archive;
            }
            return inboxView.InboxMode.inbox;
          }*/
        // let inboxData = this.cacheInboxData?this.cacheInboxData:inboxView.InboxBuilder(inboxView.InboxMode.inbox,null,connect.state.todos,null);
        // this.cacheInboxData = inboxData;
        var appContentWrapperClass = connect.inMobileNoteView()
            ? ""
            : "app-content-wrapper";

        const { dispatch } = this.props.connect;
        let section = this.props.connect.state.viewState.appSection
        let openNote = this.props.connect.state.viewState.noteOpen();

        let showMenus = (!openNote)

        /*
        return (
            <div id="app-inner">

            <div className="main-app-container">

                <section id="page-container" className="app-page-container">
            {viewState.inAggregateToDoView() && (


                <div >
                  
                    <SuperList
                        todos={this.props.connect.state.todos}
                        todoQueryId={this.props.connect.state.todoQueryId}
                        notes={this.props.connect.state.notes}
                        mobileHeaderFix={false}
                        tag={tag}
                        mode={getInboxSetting()}
                        connect={this.props.connect}
                        mobileView={this.props.connect.getPresentationMode() == model.PresentationMode.mobile} />
                </div>
            )}
            </section>
            </div>
            </div>
        )*/
        
        let shell = (
            <div id="app-inner">

                <div className="main-app-container">

                    <section>
                    
                        {showMenus &&
                            <TopHeader direction="down" scrollingTarget={this.props.connect.state.scrollingTarget}>
                                <TopMenu onJump={() => {
                                    let s = { ...me.state };
                                    s.tagsearchPopup = true;
                                    me.setState(s);
                                }} />

                            </TopHeader>
                        }

                        <div className={appContentWrapperClass}>
                            <div className="app-content">
                                <div className="full-height">

                                    {(viewState.appSection as model.AppSections) ==
                                        model.AppSections.setup && (
                                            <AccountSetup connect={this.props.connect} />
                                        )}



                                    {viewState.appSection == model.AppSections.search && (
                                        <SearchMobile connect={connect} searchText={viewState.searchText} onClose={() => {
                                            window.location.hash = window['lastPageBeforeSearch'] ? window['lastPageBeforeSearch'] : model.ViewState.getDefaultHash(model.AppSections.inbox,this.props.connect.state.viewState);
                                        }} />
                                    )}
                                    {viewState.inAggregateToDoView() && (


                                        <div >

                                            <SuperList
                                                todos={this.props.connect.state.todos}
                                                todoQueryId={this.props.connect.state.todoQueryId}
                                                notes={this.props.connect.state.notes}
                                                mobileHeaderFix={true}
                                                tag={tag}
                                                mode={getInboxSetting()}
                                                connect={this.props.connect}
                                                sortByCreateDate={this.props.connect.state.viewState.sortByCreateDate}
                                                mobileView={this.props.connect.getPresentationMode() == model.PresentationMode.mobile} />
                                        </div>
                                    )}
                                    {viewState.appSection == model.AppSections.settings && (
                                        <Settings connect={connect} />
                                    )}
                                    {viewState.appSection == model.AppSections.help && (
                                        <Help connect={connect} />
                                    )}
                                </div>
                            </div>
                            {(this.props.connect.state.TodosState == model.LoadingState.Completed && this.props.connect.state.notes.completed() && (this.props.connect.state.editTodo.completed() || viewState.todoId)) && (
                                <TodoEdit
                                    todo={connect.state.editTodo}

                                    connect={connect}
                                />
                            )}







                            <material.Dialog
                                title="Offline"
                                ref="dialog"
                                modal
                                contentStyle={{
                                    width: "50%",
                                    maxWidth: "none"
                                }}
                                open={
                                    !this.props.connect.state.isOnline &&
                                    this.props.connect.state.offlinePersistanceMode !=
                                    model.OfflinePersistanceMode.goodToGo
                                }
                            >
                                {this.props.connect.state.offlinePersistanceMode ==
                                    model.OfflinePersistanceMode.multipleTabs && (
                                        <div>
                                            <p>
                                                We don't support having multiple tabs open while
                                                offline. To fix please open the first TodoZero tab or
                                                reconnect to the internet.
                        </p>
                                        </div>
                                    )}
                                {this.props.connect.state.offlinePersistanceMode ==
                                    model.OfflinePersistanceMode.notSupported && (
                                        <div>
                                            <p>
                                                You seems to be offline and we don't support offline
                                                mode for your browser. Sorry about that.
                        </p>
                                        </div>
                                    )}
                            </material.Dialog>
                            <Progress connect={connect} />
                         
                            <ErrorBox connect={connect} />
                            {connect.state.snackMessage && (
                                <material.Snackbar
                                    open={connect.state.snackMessage != null}
                                    message={connect.state.snackMessage.message}
                                    action={connect.state.snackMessage.actionText}
                                    autoHideDuration={
                                        connect.state.snackMessage.autoHideDuration
                                    }
                                    onRequestClose={e => {
                                        connect.dispatch<any>(actions.ar_snackBarHide());
                                    }}
                                    onActionClick={e => {
                                        connect.dispatch<any>(actions.ar_snackBarAction(null));
                                    }}
                                />
                            )}
                            {this.state && this.state.tagsearchPopup && (
                                <JumpTo connect={this.props.connect} searchText=""  onClose={() => {
                                    let s = { ...me.state }
                                    s.tagsearchPopup = false;
                                    me.setState(s);
                                }} />
                            )}
                            
                         
                            <div style={{ display: "none" }}>
                                <iframe className={"trackingIframe"} />
                                <div className='upload-container'></div>
                            </div>
                        </div>


                        <BottomHeader direction='up' scrollingTarget={this.props.connect.state.scrollingTarget}>
                            <div className={this.props.classes.fabLocation}>
                                <Fab size="small" color="secondary" aria-label="add" className={this.props.classes.margin} onClick={async (e) => {


                                    var ih = new ImageHandler(
                                        this.props.connect.dispatch,
                                        this.props.connect.state.context.getId()
                                    );
                                    let newId = pApi.newId();
                                    var n = await ih.collectImage(
                                        this.props.connect.state.context.getId() + "/quickAdd/" + uuidV4(), newId
                                    );
                                    var tags = me.getTagsFromUrl();

                                    var note = await ih.createNoteFromImage(
                                        n,
                                        tags, newId,
                                        me.props.connect.state.context
                                    );
                                    me.navToNote(note.id);

                                }}>
                                    <PhotoCameraIcon />
                                </Fab>
                                <Fab size="small" color="secondary" aria-label="add" className={this.props.classes.margin} onClick={async (e) => {
                                    var tags = this.getTagsFromUrl();
                                    var ih = new ImageHandler(
                                        this.props.connect.dispatch,
                                        this.props.connect.state.context.getId()
                                    );
                                    var note = await ih.createNoteFromImage(
                                        null,
                                        tags,
                                        pApi.newId(),
                                        this.props.connect.state.context
                                    );
                                    this.navToNote(note.id);
                                }}>
                                    <PostAddIcon />
                                </Fab>
                                <Fab size="medium" color="secondary" aria-label="add" className={this.props.classes.margin} onClick={(e) => {
                                    var todoa = new pApi.ToDoItem();
                                    {
                                        todoa.id = -1;
                                        todoa.checked = false;
                                        todoa.description = "";
                                        todoa.priority = 0;
                                        todoa.project_type = me.props.connect.state.viewState.inAggregateToDoView()
                                            ? pApi.ProjectType.ptInbox
                                            : pApi.ProjectType.ptDojo;
                                        todoa.note_id = null;
                                        todoa.due_date = null;
                                        todoa.objective_id = null;
                                        todoa.recoccuring = null;
                                        todoa.tags = me.props.connect.state.viewState.getTag()
                                            ? [me.props.connect.state.viewState.getTag()]
                                            : null;
                                    }
                                    me.props.connect.dispatch<any>(actions.showTodoModal(todoa));

                                }}>
                                    <AddIcon />
                                </Fab>

                            </div>

                            <BottomMenu viewState={this.props.connect.state.viewState} />

                        </BottomHeader>



                    </section>

                    <QuillContainer
                        key="thequileditor"
                        noteChange={() => { }}
                        documentId={
                            viewState.noteViewState
                                ? viewState.noteViewState.noteId
                                : null
                        }
                        connect={connect}
                    />
                    {connect.state.previewImage  && (
                                <div id="image-viewer">
                                <ImageViewer previewImage={connect.state.previewImage} dispatch={connect.dispatch} />
                                </div>
                            )}


                </div>
            </div>


        );

        localStorage.setItem("lastHash", window.location.hash);
        return (shell);
    }

}
const mapStateToProps = function (thestate) {
    var state = thestate.dojo;

    if (!state) state = thestate;
    var newtodos = new Array<pApi.ToDoItem>();
    for (var i in state.todos.todos) {
        newtodos.push(state.todos.todos[i]);
    }

    return {
        state: state
    };
};
const mapDispatchToProps = function (dispatch) {
    var ret = {};
    for (var i in actions) {
        if (typeof actions[i] == "function") {
            ret[i] = dispatch(actions[i]);
        }
    }
    return ret;
};

function ShellFastWrapper(props) {
    const classes = useStyles();
    return <ShellFast classes={classes} {...props}></ShellFast>;
}

export default ShellFastWrapper
//export default ShellFast;// withRouter(connect(mapStateToProps)(Shell2));
