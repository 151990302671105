import * as React from "react";
import IconMenu from 'material-ui/IconMenu';
import IconButton from 'material-ui/IconButton';
import FontIcon from 'material-ui/FontIcon';
import NavigationExpandMoreIcon from 'material-ui/svg-icons/navigation/expand-more';
import MenuItem from 'material-ui/MenuItem';
import DropDownMenu from 'material-ui/DropDownMenu';
import RaisedButton from 'material-ui/RaisedButton';
import {Toolbar, ToolbarGroup, ToolbarSeparator, ToolbarTitle} from 'material-ui/Toolbar';
import * as model  from "../lib/model";

import * as pApi from "../../shared/papi/papi-core";
import * as material from "material-ui";
import * as actions from "../lib/ActionReducer";
interface TAvatarButtonProps {
    connect:model.ConnectProps;
    showName?:boolean; 
  }
  interface TAvatarButtonPropsStateProps {
    profileOpen:boolean;
    anchorEl:any
  }

export  class AvatarButton extends React.Component<TAvatarButtonProps, TAvatarButtonPropsStateProps>{

  constructor(props) {
    super(props);
    this.state = {
        profileOpen:false,
        anchorEl:null
    };
  }


  handleProfileClick = event => {
    // This prevents ghost click.
    event.preventDefault();

    this.setState({
      profileOpen: true,
      anchorEl: event.currentTarget
    });
  };
  signOut=  ()=>{
    //;
      this.props.connect.dispatch(actions.ar_signout());
  }
  myProfile = async event =>{
    //@ts-ignore
    if ($(window).width() < 768) {
      //$body.toggleClass("sidebar-mobile-open");
      this.props.connect.dispatch<any>(actions.handleToggleCollapsedNav(true));
      
    }
    this.setState({
      profileOpen: false,
      anchorEl: this.state.anchorEl
    });
    window.location.hash="#/settings/profile"
  }
  beaconReset = async event =>{
    this.props.connect.dispatch<any>(actions.ar_updateOnboardingState(new pApi.OnboardingState()));
    //@ts-ignore
    if ($(window).width() < 768) {
      //$body.toggleClass("sidebar-mobile-open");
      this.props.connect.dispatch<any>(actions.handleToggleCollapsedNav(true));
      
    }
    this.setState({
      profileOpen: false,
      anchorEl: this.state.anchorEl
    });
  }
  handleProfileClose = () => {
    this.setState({
      profileOpen: false,
      anchorEl: null
    });
  };
  render() {
    return (
       (<div>
         
            <IconButton
              style={{
                margin: "0px 16px -0px 0px",
               
                color: "#311b92",
                height:'55px',
                width:'55px'
              }}
            
              onClick={this.handleProfileClick.bind(this)}
            >
              <material.Avatar
               
                size={40}
             
                color='#311b92'
                
      
                src={
                   this.props.connect.state.context.ProfileProvider.getProfile()
                    .profile_URL
                }
              >
              {this.props.connect.state.context.ProfileProvider.getProfile()
                    .profile_URL?null:this.props.connect.state.context.ProfileProvider.getProfile().display_name[0]}
              </material.Avatar>
             
            </IconButton>
            {this.props.showName &&
               this.props.connect.state.context.ProfileProvider.getProfile().display_name
              }
            <material.Popover
              open={this.state.profileOpen}
            
              anchorEl={this.state.anchorEl}
              anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
              targetOrigin={{ horizontal: "left", vertical: "top" }}
              onRequestClose={this.handleProfileClose}
            >
              <material.Menu >  
                <material.MenuItem primaryText="My Profile" onClick={this.myProfile.bind(this)} />
                <material.MenuItem primaryText="Sign out" onClick={this.signOut.bind(this)} />
              </material.Menu>
            </material.Popover>
            </div>)
          
    );
  }
}