import * as React from "react";
import ReactBody from "react-body";
import { Header } from "../Mobile/Header/NewHeader";
import { NoteSidebar } from  "../components/NoteSideNav/NoteSidebar";//"../../Mobile/NoteSideNav/NoteSidebar";
//import { Search } from "../Mobile/Search/Search";
import Search from "../Search/Search"
import { AccountSetup } from "../Mobile/AccountSetup/AccountSetup";
//import { TagView } from "../Mobile/TagView/TagView";
//import { Inbox } from "../Mobile/Inbox/Inbox";
//import * as inboxView from "../Mobile/Inbox/InboxView";
import ImageViewer from '../components/ImageViewer'
import { InboxMode } from "../lib/model"
import * as listTypes from "../../shared/lib/superListTypes"
import SuperList from '../SuperList/SuperList'
import { TodoEdit } from "../components/TodoEdit/TodoEdit";
import { Settings } from "../components/Settings/Settings"
import { Help } from "../components/Help/Help";
import { QuillContainer } from "../components/Editor/QuillContainer";
import * as actions from "../lib/ActionReducer" 
import * as material from "material-ui";
import * as pApi from "../../shared/papi/papi-core";// "../../../server/papi/papi-core";
import { AddButton } from "../Mobile/LayoutButton/AddButton";
import { Progress } from "../components/Progress/Progress";
import { ErrorBox } from "../components/ErrorBox/ErrorBox";
import * as model from "../lib/model"

import * as hotkey from "../lib/hotkeyClient"
import { TagSearch } from "../components/TagSearch";

var $ = require("jquery");
var _ = require("lodash");
var classnames = require("classnames");

interface ShellState2 {
  tagsearchPopup: Boolean;
}
interface ShellProps2 {
    connect: model.ConnectProps;
  }
class Shell2 extends React.Component<ShellProps2, ShellState2> {
  nothing() { }
  getHeight(): string {
    return window.innerHeight - 20 + "px";
  }
  constructor(props: ShellProps2) {
    super(props);
     this.setState({
      tagsearchPopup: false
    });

  }
  

  componentDidMount() {
    
      let me = this;

      $(window).resize("resize", () => {
        me.props.connect.dispatch<any>(actions.ar_windowResize());
      });

      let instance = hotkey.HotKeyEngine.instance();
      let handlers = new Map<hotkey.HotKeyActionType, () => boolean>();
      handlers.set(
        hotkey.HotKeyActionType.undo,
        (): boolean => {
          if (
            me.props.connect.state.snackMessage &&
            me.props.connect.state.snackMessage.actionText == "undo"
          ) {
            me.props.connect.dispatch<any>(actions.ar_snackBarAction(null));
            return true;
          }
          return false;
        }
      );
      handlers.set(
        hotkey.HotKeyActionType.jumpTag,
        (): boolean => {
          let s = { ...me.state };
          s.tagsearchPopup = true;
          me.setState(s);
          return true;
        }
      );

      this.props.connect.dispatch<any>(actions.ar_loadInbox())
      this.props.connect.dispatch<any>(actions.ar_loadNotes())
      instance.registerHandlers("ShellMaterial", "inbox-m", false, handlers);
    
  }
  componentWillUnmount() {
   
      let instance = hotkey.HotKeyEngine.instance();
      instance.unregisterHandlers("ShellMaterial");
    
  }

  populateStateFromHash(state: model.IState, match): model.IState {
    var newState = { ...state };
    var nv = model.ViewState.viewStateFromRoute(match);

    newState.viewState = nv;
    return newState;
  }
  quote: string;

  render() {
     
    let me = this;
    let connect = this.props.connect
    let viewState = this.props.connect.state.viewState;
    let tag = viewState.getTag();
   
    function getInboxSetting() {
      switch (connect.state.viewState.appSection) {
          case model.AppSections.inbox:
              return InboxMode.inbox;
          case model.AppSections.today:
              return InboxMode.today;
          case model.AppSections.thisweek:
              return InboxMode.thisWeek;
              case model.AppSections.bydate:
                return InboxMode.bydate;
          case model.AppSections.snoozed:
              return InboxMode.onlysnoozed;
          case model.AppSections.archived:
              return InboxMode.archive;
          case model.AppSections.notes:
              if(tag)
                  return InboxMode.bytag;
              else
                  return InboxMode.allNotes;
      }
      return InboxMode.inbox;
  }
    /*function getInboxSetting() {
        switch (connect.state.viewState.appSection) {
          case model.AppSections.inbox:
            return listTypes.InboxMode.inbox;
          case model.AppSections.today:
            return inboxView.InboxMode.today;
          case model.AppSections.thisweek:
            return inboxView.InboxMode.thisWeek;
          case model.AppSections.snoozed:
            return inboxView.InboxMode.onlysnoozed;
          case model.AppSections.archived:
            return inboxView.InboxMode.archive;
        }
        return inboxView.InboxMode.inbox;
      }*/
   // let inboxData = this.cacheInboxData?this.cacheInboxData:inboxView.InboxBuilder(inboxView.InboxMode.inbox,null,connect.state.todos,null);
   // this.cacheInboxData = inboxData;
    var appContentWrapperClass = connect.inMobileNoteView()
      ? ""
      : "app-content-wrapper";
    const { dispatch } = this.props.connect;
        let shell = (
          <div id="app-inner">
            <div className="preloaderbar hide">
              <span className="bar" />
            </div>
            <ReactBody
              className="sidebar-mobile-open"
              if={!this.props.connect.state.uiConifg.layout.navCollapsed}
            />

            <div
              style={{ overflow: "hidden" }}
              className={classnames("app-main full-height fixed-header ", {
                "fixed-header": this.props.connect.state.uiConifg.layout.fixedHeader,
                "nav-collapsed": this.props.connect.state.uiConifg.layout.navCollapsed,
                "nav-behind": this.props.connect.state.uiConifg.layout.navBehind,
                "layout-boxed": this.props.connect.state.uiConifg.layout.layoutBoxed,
                "theme-gray": this.props.connect.state.uiConifg.layout.theme === "gray",
                "theme-dark": this.props.connect.state.uiConifg.layout.theme === "dark",
                "sidebar-sm":
                  this.props.connect.state.uiConifg.layout.sidebarWidth === "small",
                "sidebar-lg":
                  this.props.connect.state.uiConifg.layout.sidebarWidth === "large"
              })}
            >
              <div className="main-app-container">
                <NoteSidebar
                  handleToggleCollapsedNav={ (e:boolean)=>{
                    dispatch<any>(actions.handleToggleCollapsedNav(e))
                  }
                   
                  }
                  uiConfig={this.props.connect.state.uiConifg}
                  connect={connect}
                  loadingState={this.props.connect.state.TagsState}
                />

                <section id="page-container" className="app-page-container">
                  <Header
                    todoView={this.props.connect.state.todoView}
                    connect={connect}
                    handleToggleCollapsedNav={ (e:boolean)=>{
                      dispatch<any>(actions.handleToggleCollapsedNav(e))
                    }}
                    uiConfig={this.props.connect.state.uiConifg}
                  />

                  <div className={appContentWrapperClass}>
                    <div className="app-content">
                      <div className="full-height">
                     
                        {(viewState.appSection as model.AppSections) ==
                          model.AppSections.setup && (
                            <AccountSetup connect={this.props.connect} />
                          )}
                      
                       

                      {viewState.appSection == model.AppSections.search && (
                                     <Search connect={connect}   searchText={connect.state.searchText} />
                            )}
                        {viewState.inAggregateToDoView() && (


                          <div>
                        
                               <SuperList  
                todos= {this.props.connect.state.todos}
                todoQueryId= {this.props.connect.state.todoQueryId}
                notes={this.props.connect.state.notes}
               
                tag={tag}
                mode={getInboxSetting()}
                connect={this.props.connect}
                sortByCreateDate={this.props.connect.state.viewState.sortByCreateDate}
                mobileView={this.props.connect.getPresentationMode() == model.PresentationMode.mobile} />
                          </div>
                        )}
                        {viewState.appSection == model.AppSections.settings && (
                          <Settings connect={connect} />
                        )}
                        {viewState.appSection == model.AppSections.help && (
                          <Help connect={connect} />
                        )}
                      </div>
                    </div>
                    {(this.props.connect.state.TodosState == model.LoadingState.Completed && this.props.connect.state.notes.completed() && (this.props.connect.state.editTodo.completed() || viewState.todoId)) && (
                        <TodoEdit
                            todo={connect.state.editTodo}

                            connect={connect}
                        />
                    )}

                  
                    

                <QuillContainer
                        key="thequileditor"
                        noteChange={() => { }}
                        documentId={
                            viewState.noteViewState
                                ? viewState.noteViewState.noteId
                                : null
                        }
                        connect={connect}
                    />

                   


                    <material.Dialog
                      title="Offline"
                      ref="dialog"
                      modal
                      contentStyle={{
                        width: "50%",
                        maxWidth: "none"
                      }}
                      open={
                        !this.props.connect.state.isOnline &&
                        this.props.connect.state.offlinePersistanceMode !=
                        model.OfflinePersistanceMode.goodToGo
                      }
                    >
                      {this.props.connect.state.offlinePersistanceMode ==
                        model.OfflinePersistanceMode.multipleTabs && (
                          <div>
                            <p>
                              We don't support having multiple tabs open while
                              offline. To fix please open the first TodoZero tab or
                              reconnect to the internet.
                        </p>
                          </div>
                        )}
                      {this.props.connect.state.offlinePersistanceMode ==
                        model.OfflinePersistanceMode.notSupported && (
                          <div>
                            <p>
                              You seems to be offline and we don't support offline
                              mode for your browser. Sorry about that.
                        </p>
                          </div>
                        )}
                    </material.Dialog>
                    <Progress connect={connect} />
         
                    <ErrorBox connect={connect} />
                    {this.state && connect.state.previewImage && (
                                <ImageViewer previewImage={connect.state.previewImage} dispatch={connect.dispatch} />
                            )}
                    {connect.state.snackMessage && (
                      <material.Snackbar
                        open={connect.state.snackMessage != null}
                        message={connect.state.snackMessage.message}
                        action={connect.state.snackMessage.actionText}
                        autoHideDuration={
                          connect.state.snackMessage.autoHideDuration
                        }
                        onRequestClose={e => {
                          connect.dispatch<any>(actions.ar_snackBarHide());
                        }}
                        onActionClick={e => {
                          connect.dispatch<any>(actions.ar_snackBarAction(null));
                        }}
                      />
                    )}
                    {this.state && this.state.tagsearchPopup && (
                      <TagSearch
                        connect={connect}
                        onClose={() => {
                          let state = { ...me.state };
                          state.tagsearchPopup = false;
                          me.setState(state);
                        }}
                      />
                    )}
                       
                    <div style={{ display: "none" }}>
                      <iframe className={"trackingIframe"} />
                      <div className='upload-container'></div>
                    </div>
                  </div>
                  <AddButton connect={connect} />
                </section>

              </div>
            </div>
          </div>
        );
     
      localStorage.setItem("lastHash", window.location.hash);
      return shell;
    }
  
}
const mapStateToProps = function (thestate) {
  var state = thestate.dojo;

  if (!state) state = thestate;
  var newtodos = new Array<pApi.ToDoItem>();
  for (var i in state.todos.todos) {
    newtodos.push(state.todos.todos[i]);
  }

  return {
    state: state
  };
};
const mapDispatchToProps = function (dispatch) {
  var ret = {};
  for (var i in actions) {
    if (typeof actions[i] == "function") {
      ret[i] = dispatch(actions[i]);
    }
  }
  return ret;
};


export default Shell2;// withRouter(connect(mapStateToProps)(Shell2));
