import * as React from "react";

import ProfileIcon from "material-ui/svg-icons/action/account-circle";
import ApiIcon from "material-ui/svg-icons/action/code";
import * as hotkey from "../../lib/hotkeyClient";
import OptionIcon from "material-ui/svg-icons/action/settings"
import BillingIcon from "material-ui/svg-icons/action/account-balance";
import NavigationClose from "material-ui/svg-icons/navigation/close";
import BackIcon from "material-ui/svg-icons/navigation/chevron-left";
import CopyIcon from "material-ui/svg-icons/content/content-copy";
import * as Material from "material-ui";
import * as actions from "../../lib/ActionReducer";
import * as model from "../../lib/model";
import GitInfo from "../../../shared/gitinfo"
import { CacheNotes } from '../../lib/cacheNotes'
import * as pApi from "../../../shared/papi/papi-core";
import IconButton from "material-ui/IconButton";
import convertDown from '../../../shared/markdown/fromDelta';
import { NoteFromMarkdown } from "../../../shared/markdown/deltaUtil"
var ImgCache = require("hacked-imgcache");
let $ = require('jquery')

//@ts-ignore
//let GitInfo = {VERSION:new Date()};//require("../../../../server/gitinfo").default;


interface SettingsProps {
  connect: model.ConnectProps;
}
class SettingsState {
  mobileSelect: boolean = false;
}
class StorageInformation {
  loading: boolean = true;
  quota: number;
  usage: number;
  imageCacheSize: string;
  notFound: boolean = false;
}
let getStorage = (): Promise<StorageInformation> => {
  return new Promise<StorageInformation>(async (resolve, reject) => {
    let sInfo = new StorageInformation();
    if (navigator.storage && navigator.storage.estimate) {
      const estimation = await navigator.storage.estimate();

      sInfo.loading = false;
      sInfo.quota = estimation.quota;
      sInfo.usage = estimation.usage

      try {
        ;
        sInfo.imageCacheSize = ImgCache.getCurrentSize() + " K"
      }
      catch (e) {
        sInfo.imageCacheSize = " unable to detect " + e
      }

    } else {
      sInfo.notFound = true;
    }
    resolve(sInfo)
  })
}

export function Settings(props: SettingsProps) {
  const [mobileState, setMobileState] = React.useState<boolean>(false);
  const [storageInfo, setStorageInfo] = React.useState<StorageInformation>(new StorageInformation());


  React.useEffect(() => {

    let instance = hotkey.HotKeyEngine.instance();
    let handlers = new Map<hotkey.HotKeyActionType, () => boolean>();
    handlers.set(
      hotkey.HotKeyActionType.esc,
      (): boolean => {

        let vs = props.connect.state.viewState.deepCopy();
        vs.appSection = model.AppSections.inbox;
        window.location.hash = vs.getHash();
        return true;
      }
    );
    ;
    instance.registerHandlers("settings", "settings", true, handlers);
    getStorage().then((storage: StorageInformation) => {
      setStorageInfo(storage);
    })

    // Specify how to clean up after this effect:
    return function cleanup() {
      let instance = hotkey.HotKeyEngine.instance();
      instance.unregisterHandlers("settings");
    };
  }, []);
  function copyClick() {
    try {
      /* Get the text field */
      var copyText = document.getElementById("apiKeyInput");

      /* Select the text field */

      //@ts-ignore
      copyText.select();

      /* Copy the text inside the text field */
      document.execCommand("copy");
      //@ts-ignore

      // alert("Copied the text: " + copyText.value);
    } catch (e) {
      console.error(e);
    }
  }
  const handleClose = async (e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    let vs = props.connect.state.viewState.deepCopy();
    vs.appSection = model.AppSections.inbox;
    window.location.hash = vs.getHash();
    //props.connect.dispatch<any>(actions.arclcloseTodoModal());
  }
  const generateKey = async (e) => {
    if (
      !props.connect.state.apiKey ||
      window.confirm(
        "WARNING!!! Are you sure you want to generate a new API Key?  If currently have integrations with TodoZero they will stop working. "
      )
    ) {
      props.connect.dispatch<any>(actions.ar_generateNewApiKey());
    }
  }
  const handleBack = async (e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    let vs = props.connect.state.viewState.deepCopy();
    if (props.connect.state.viewState.settingSection == null) {
      vs.appSection = model.AppSections.inbox;
      window.location.hash = vs.getHash();
    } else {
      vs.settingSection = null;
    }
    window.location.hash = vs.getHash();
    //props.connect.dispatch<any>(actions.arclcloseTodoModal());
  }


  if (props.connect.state.apiKeyState == model.LoadingState.NotLoaded) {
    props.connect.dispatch<any>(actions.ar_loadApiKeys());
  }
  const actionsButtons = [
    <Material.FlatButton
      label="Close"
      primary
      onClick={handleClose.bind(this)}
    />
  ];
  let selectedStyle: any = {
    "font-weight": "bolder",
    color: "rgb(49, 27, 146)"
  };
  let isMobile =
    props.connect.getPresentationMode() == model.PresentationMode.mobile;

  if (isMobile) {
    selectedStyle = {};
  }
  let settingsSection =
    props.connect.state.viewState.settingSection != null
      ? props.connect.state.viewState.settingSection
      : model.SettingSection.profile;

  let Title = "Settings";
  switch (props.connect.state.viewState.settingSection) {
    case model.SettingSection.api:
      Title = "API";
      break;
    case model.SettingSection.options:
      Title = "Options";
      break;
    case model.SettingSection.profile:
      Title = "Profile";
      break;
    case model.SettingSection.billing:
      Title = "Subscription";
      break;
  }

  let menu = (
    <Material.Menu style={{ width: "100%" }}>
      <Material.MenuItem
        style={
          settingsSection == model.SettingSection.profile ? selectedStyle : {}
        }
        leftIcon={
          <ProfileIcon
            color={
              settingsSection == model.SettingSection.billing
                ? "rgb(49, 27, 146)"
                : "default"
            }
          />
        }
        href="#/settings/profile"
        primaryText="Profile"
      />


      <Material.MenuItem
        style={
          settingsSection == model.SettingSection.options ? selectedStyle : {}
        }
        leftIcon={
          <OptionIcon
            color={
              settingsSection == model.SettingSection.billing
                ? "rgb(49, 27, 146)"
                : "default"
            }
          />
        }
        href="#/settings/options"
        primaryText="Options"
      />
      <Material.MenuItem
        style={
          settingsSection == model.SettingSection.api ? selectedStyle : {}
        }
        leftIcon={
          <ApiIcon
            color={
              settingsSection == model.SettingSection.billing
                ? "rgb(49, 27, 146)"
                : "default"
            }
          />
        }
        href="#/settings/api"
        primaryText="API"
      />
      <div>
        {props.connect.state.platform != pApi.Platform.ios && (
          <Material.MenuItem
            style={
              settingsSection == model.SettingSection.billing
                ? selectedStyle
                : {}
            }

            leftIcon={
              <BillingIcon
                color={
                  settingsSection == model.SettingSection.billing
                    ? "rgb(49, 27, 146)"
                    : "default"
                }
              />
            }
            href="#/settings/billing"
            primaryText="Subscription"
          />
        )}
      </div>
    </Material.Menu>
  );

  let showMobileMenu =
    isMobile && props.connect.state.viewState.settingSection == null;

  let settings = (
    <div className="container-fluid-mod  ">
      {showMobileMenu && <div className="row">{menu}</div>}
      {!showMobileMenu && (
        <div className="row">
          <div className="hidden-sm-down col-md-4">{menu}</div>
          <div className="col-md-8 col-12">
            {settingsSection == model.SettingSection.profile && (
              <div>
                <b>Profile</b>
                <p>{props.connect.state.user.displayName}</p>
                <p>{props.connect.state.user.email}</p>
                {window['electron'] && (
                  <div>
                    
                    <Material.RaisedButton

                      label={"Check for Updates"}


                      onClick={async () => {
                        window['api'].send('tz_checkForUpdate', '')
                      }

                      }
                    />
                  </div>)}
                <b>System Information</b>
                <p>
                  version: {GitInfo.VERSION.toString()} <br />
                  date: {GitInfo.DATE}<br />
                  platform:{" "}
                  {props.connect.state.platform == pApi.Platform.ios
                    ? "IOS"
                    : "WEB"}{" "}
                  <br />
                  agent: {navigator.userAgent}
                </p>
                <b>Storage Information</b>
                {storageInfo.loading && (<div>checking...</div>)}
                {!storageInfo.loading && (
                  <p>
                    quota : {storageInfo.quota % (1024 * 1024)} MB  <br />
                    usage : {storageInfo.usage % (1024 * 1024)} MB <br />
                    image usage : {storageInfo.imageCacheSize}
                  </p>
                )}
              </div>
            )}
            {settingsSection == model.SettingSection.billing && (
              <div>
                <b>Subscription</b>
                <p>
                  You are currently on the <b>Free</b> plan. If you are
                  interested in participating in the beta program for other
                  plans contact us at{" "}
                  <a href="mailto:support@todozero.com">
                    support@todozero.com
                  </a>
                </p>
              </div>
            )}
            {settingsSection == model.SettingSection.options && (
              <div><div>
                <div className="col-12"><b>Options</b></div>
              </div>
                <div className="row">
                  <div className="col-12"> <Material.Divider></Material.Divider></div>
                </div>
                <div className="row hotkey-row-pad">
                  <div className="col-12">
                    <Material.Toggle
                      label="Dark Mode"
                      defaultToggled={window['darkmode'].isActivated()}
                      onToggle={async (e, value) => {
                        window['darkmode'].toggle();
                        /*let settings = await me.props.connect.state.context.Settings.get(null);
                        settings.minimalUIMode = value;
                        await me.props.connect.state.context.Settings.set(settings);
                      */
                        setTimeout(() => {
                          window['tz_Reload']();
                          //window.location.reload();


                        }, 500)

                      }}

                    />
                    <Material.Toggle
                      label="Save Notes Locally"
                      defaultToggled={props.connect.state.dojoSettings.embedNoteContent}
                      onToggle={async (e, value) => {
                        
                        let settings = await props.connect.state.context.Settings.get(null);
                        settings.embedNoteContent = !props.connect.state.dojoSettings.embedNoteContent;
                        await props.connect.state.context.Settings.set(settings);

                        setTimeout(() => {
                          //window.location.reload();
                          window['tz_Reload']();

                        }, 500)

                      }}

                    />
                    <Material.Toggle
                      label="Disable Fast Update"
                      defaultToggled={localStorage.getItem("DisableFastUpdate") == "true"}
                      onToggle={async (e, value) => {
                        if (localStorage.getItem("DisableFastUpdate") == "true") {
                          localStorage.setItem("DisableFastUpdate", "false")
                        }
                        else {
                          localStorage.setItem("DisableFastUpdate", "true")
                        }

                      }}

                    />
                    {props.connect.getPresentationMode() == model.PresentationMode.mobile &&
                      <Material.Toggle
                        label="New Mobile Mode"
                        defaultToggled={props.connect.state.dojoSettings.newMobileMode}
                        onToggle={async (e, value) => {

                          let settings = await props.connect.state.context.Settings.get(null);
                          settings.newMobileMode = !props.connect.state.dojoSettings.newMobileMode;
                          await props.connect.state.context.Settings.set(settings);

                          setTimeout(() => {
                            //window.location.reload();
                            window['tz_Reload']();

                          }, 500)

                        }}

                      />
                    }
                    {window['electron'] && (
                      <Material.Toggle
                        label="Debug Electron"
                        defaultToggled={localStorage.getItem("debugElectron") == "true"}
                        onToggle={async (e, value) => {
                          if (localStorage.getItem("debugElectron") == "true") {
                            localStorage.removeItem("debugElectron")
                          }
                          else {
                            localStorage.setItem("debugElectron", "true");
                            window['api'].send('tz_open', '')
                          }


                        }}

                      />
                    )}
                  </div>

                  <div className="col-12 text-center" style={{ "height": "20px" }}>
                  </div>
                  <div className="col-12 text-center">
                   
                    <Material.RaisedButton

                      label={"Cache All Notes"}


                      onClick={async () => {
                        localStorage.removeItem("cacheNotesLastModified")
                        new CacheNotes(props.connect.state.context).loopAllContent(null, 0, null);
                        alert('Started Sync Job.s')
                      }

                      }
                    />
                    <Material.RaisedButton

                      label={"Download All Notes"}


                      onClick={async () => {
                        var JSZip = require("jszip");
                        var zip = new JSZip();
                        let notes = await props.connect.state.context.Notes.list();
                        let count = 0;
                        for (var i in notes) {
                          try {
                            let note = notes[i]
                            count++;

                            let m = new model.ProgressState();
                            m.text = "Preparing Download - " + count + " out of " + notes.length + " note(s)"
                            m.progress = Math.floor(((count / notes.length) * 100))
                            props.connect.dispatch<any>(actions.ar_setProgress(m));

                            let title = note.title ? note.title : "untitled-" + note.created.toISOString();
                            console.log("add " + title)
                            let content = await props.connect.state.context.Notes.getContent(note);

                            let cont = content.content


                            var ops = [];
                            for (var i in cont) {
                              ops.push(cont[i]);
                            }
                            let markdwon = convertDown(ops);
                            zip.file(title + ".md", markdwon);
                          }
                          catch (e) {
                            console.error(e);
                          }
                        }
                        let m = new model.ProgressState();
                        m.text = "Generating Zip";
                        m.progress = count / notes.length
                        props.connect.dispatch<any>(actions.ar_setProgress(m));
                        zip.generateAsync({ type: "base64" }).then(function (base64) {
                          var element = document.createElement('a');
                          element.setAttribute('href', "data:application/zip;base64," + base64);
                          element.setAttribute('download', "notes.zip");

                          element.style.display = 'none';
                          document.body.appendChild(element);

                          element.click();

                          document.body.removeChild(element);
                          let m = new model.ProgressState();
                          m.text = "Generating Zip";
                          m.progress = count / notes.length
                          props.connect.dispatch<any>(actions.ar_setProgress(null));

                        });

                      }

                      }
                    />
                  </div>
                  <div className="col-12 text-center" style={{ "height": "20px" }}>
                  </div>
                  <div className="col-12 text-center">

                    <Material.RaisedButton

                      label={"Clean Image Cache " + storageInfo.imageCacheSize}


                      onClick={() => {

                        ImgCache.clearCache(() => {
                          alert("Cache Cleared Successfully");
                          getStorage().then((storage: StorageInformation) => {
                            setStorageInfo(storage);
                          })

                        }, (err) => {
                          alert('Error clearing Cache ' + err);

                        })
                      }

                      }
                    />


                  </div>
                </div>
              </div>)}



            {settingsSection == model.SettingSection.hotkeys && (
              <div>

                <div className="row hotkey-row-pad">
                  <div className="col-12"><b>Navigation</b></div>
                </div>
                <div className="row">
                  <div className="col-12"> <Material.Divider></Material.Divider></div>
                </div>

                <div className="row hotkey-row-pad">
                  <div className="col-5">next</div>
                  <div className="col-7"><span className="hotkey-button">J</span></div>
                </div>
                <div className="row hotkey-row-pad">
                  <div className="col-5">previous</div>
                  <div className="col-7"><span className="hotkey-button">K</span></div>
                </div>
                <div className="row hotkey-row-pad">
                  <div className="col-5">go to</div>
                  <div className="col-7"><span className="hotkey-button">G</span></div>
                </div>
                <div className="row hotkey-row-pad">
                  <div className="col-5">search</div>
                  <div className="col-7"><span className="hotkey-button">/</span></div>
                </div>
                <div className="row hotkey-row-pad">
                  <div className="col-12"><b>Action</b></div>
                </div>
                <div className="row">
                  <div className="col-12"> <Material.Divider></Material.Divider></div>
                </div>

                <div className="row hotkey-row-pad">
                  <div className="col-5">select</div>
                  <div className="col-7"><span className="hotkey-button">space</span></div>
                </div>
                <div className="row hotkey-row-pad">
                  <div className="col-5">expand / open</div>
                  <div className="col-7"><span className="hotkey-button">enter</span></div>
                </div>
                <div className="row hotkey-row-pad">
                  <div className="col-5">complete</div>
                  <div className="col-7"><span className="hotkey-button">E</span></div>
                </div>
                <div className="row hotkey-row-pad">
                  <div className="col-5">snooze</div>
                  <div className="col-7"><span className="hotkey-button">H</span></div>
                </div>
                <div className="row hotkey-row-pad">
                  <div className="col-5">star</div>
                  <div className="col-7"><span className="hotkey-button">S</span></div>
                </div>
                <div className="row hotkey-row-pad">
                  <div className="col-5">new to-do</div>
                  <div className="col-7"><span className="hotkey-button">C</span></div>
                </div>
                <div className="row hotkey-row-pad">
                  <div className="col-5">new note</div>
                  <div className="col-7"><span className="hotkey-button">Shift</span> + <span className="hotkey-button">C</span></div>
                </div>
                <div className="row hotkey-row-pad">
                  <div className="col-5">undo</div>
                  <div className="col-7"><span className="hotkey-button">Z</span></div>
                </div>
                <div className="row hotkey-row-pad">
                  <div className="col-5">multiselect</div>
                  <div className="col-7"><span className="hotkey-button">Shift</span> + <span className="hotkey-button">J</span> or  <span className="hotkey-button">K</span></div>
                </div>
              </div>
            )}
            {settingsSection == model.SettingSection.api && (
              <div>
                <b>API Key</b>
                {props.connect.state.apiKey && (
                  <p>
                    This is your API Key. You need this when integrating with
                    other apps.{" "}
                  </p>
                )}
                {!props.connect.state.apiKey && (
                  <p>
                    You can integrate TodoZero with other apps but need to
                    generate an API key. Click <b>Generate an Api Key</b> to
                    get started.
                  </p>
                )}
                <p>
                  Integrate TodoZero with 1000s of App using <b>Zapier</b>.
                </p>
                <p>
                  <a
                    target="_new"
                    href="https://zapier.com/platform/public-invite/5548/8de333930b3d33c3258a0c65f728c288/"
                  >
                    Try Zapier with TodoZero
                  </a>
                </p>
                <p />
                <div className="container-fluid">
                  {props.connect.state.apiKey &&
                    props.connect.state.apiKeyState ==
                    model.LoadingState.Completed && (
                      <div className="row">
                        <div className="col-10">
                          <input
                            style={{
                              width: "100%",
                              border: "none",
                              "backgroundColor": "lightgray",
                              padding: "10px"
                            }}
                            type="text"
                            readOnly
                            value={
                              props.connect.state.apiKey
                                ? props.connect.state.apiKey.token
                                : ""
                            }
                            id="apiKeyInput"
                          />
                        </div>
                        <div className="col-2">
                          <Material.IconButton
                            tooltip="copy to clipboard"
                            onClick={copyClick}
                          >
                            <CopyIcon />
                          </Material.IconButton>
                        </div>
                      </div>
                    )}
                  {props.connect.state.apiKey == null &&
                    props.connect.state.apiKeyState ==
                    model.LoadingState.Completed && (
                      <div className="row">
                        <Material.RaisedButton
                          label="Generate an Api Key"
                          onClick={generateKey.bind(this)}
                          buttonStyle={{
                            "backgroundColor": "#388E3C",
                            color: "white"
                          }}
                          labelColor="white"
                        />
                      </div>
                    )}
                  <div className="row">
                    {props.connect.state.apiKeyState ==
                      model.LoadingState.NotLoaded ||
                      (props.connect.state.apiKeyState ==
                        model.LoadingState.Loading && (
                          <div className="col">Loading...</div>
                        ))}
                    {props.connect.state.apiKeyState ==
                      model.LoadingState.Error && (
                        <div className="col">
                          There was a problem. Please contact support at
                          support@todozero.com
                        </div>
                      )}
                  </div>
                </div>
                <p>
                  <a href="https://todozero.com/docs/index.html#introduction" target="_new">
                    Checkout the API documentation here
                  </a>
                  .
                </p>
                <p>
                  <a href="https://todozero.com/docs/index.html#introduction" target="_new">
                    Learn more about connecting to other apps.
                  </a>
                  .
                </p>
                {props.connect.state.apiKeyState ==
                  model.LoadingState.Completed && (
                    <div style={{ textAlign: "center" }}>
                      {props.connect.state.apiKey != null && (
                        <div className="col">
                          <Material.RaisedButton
                            onClick={generateKey.bind(this)}
                            buttonStyle={{
                              "backgroundColor": "#D32F2F",
                              color: "white"
                            }}
                            style={{ color: "white" }}
                            label="Regenerate your  Api Key"
                            labelColor="white"
                          />
                        </div>
                      )}
                    </div>
                  )}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
  //@ts-ignore
  let winHeight = $(window).height()
  //@ts-ignore
  let winWidth = $(window).width()
  return (
    <div id="hey">
      {$(window).width() < 420 && (
        <div
          style={{
            "zIndex": 1000,
            position: "absolute",
            top: "0",
            width: "100%",
            minHeight: props.connect.state.windowHeight,
            background: "white",
            "-webkit-overflow-scrolling": "touch"
          } as React.StyleHTMLAttributes<String>}
        >
          <Material.AppBar
            title={<span>{Title}</span>}
            iconElementRight={
              <IconButton onClick={handleClose.bind(this)}>
                <NavigationClose />
              </IconButton>
            }
            iconElementLeft={
              <IconButton onClick={handleBack.bind(this)}>
                <BackIcon />
              </IconButton>
            }
            onLeftIconButtonTouchTap={handleClose.bind(this)}
            style={{ color: "white", "backgroundColor": "#333C44" }}
          />
          <div
            style={{ padding: "20px" }}
            className="mobile-form-with-header "
          >
            {settings}
          </div>
        </div>
      )}

      <Material.Dialog
        title="Settings"
        actions={actionsButtons}
        modal
        overlayStyle={actions.getOverlayStyle()}
        open={winWidth >= 420}
      >
        <div
          className="mobile-form "
          style={{ height: winHeight - winHeight * 0.2 }}
        >
          {settings}
        </div>
      </Material.Dialog>
    </div>
  );
}




