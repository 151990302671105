import NavigationIcon from "material-ui/svg-icons/navigation/menu";
import * as React from "react";
import * as model from "../lib/model";
import * as pApi from "../../shared/papi/papi-core";
import { SimpleIconButton } from '../components/SimpleIconButton'
import CancelIcon from "material-ui/svg-icons/content/clear";

import * as hotkey from "../lib/hotkeyClient";
import * as actions from "../lib/ActionReducer";




interface SearchProps {
  connect: model.ConnectProps;
}
interface SearchState {
  searchText: string;
  preSearchHash: any;
  isFocus: boolean;
}



export class SearchBar extends React.Component<
  SearchProps,
  SearchState
  > {
  constructor(props: SearchProps, context) {
    super(props, context);
    this.state = {
      searchText: '',
      preSearchHash: '',
      isFocus: true
    }
  }

  cancelSearch(e) {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    if (window['lastPageBeforeSearch']) {
      window.location.hash = window['lastPageBeforeSearch'];
      window['lastPageBeforeSearch'] = null;
    }
    else {
      let viewState = new model.ViewState();
      viewState.appSection = model.AppSections.inbox;
      window.location.hash = viewState.getHash();
    }
  }


  componentDidMount() {
    let me = this;
    let instance = hotkey.HotKeyEngine.instance();
    let handlers = new Map<hotkey.HotKeyActionType, () => boolean>();
    handlers.set(
      hotkey.HotKeyActionType.esc,
      (): boolean => {
        if (me.state.isFocus) {
          me.cancelSearch(null);
        }
        else {
          let s = { ...me.state }
          s.isFocus = true;
          me.setState(s);
          //@ts-ignore
          me.refs['searchInput'].click();
          //@ts-ignore
          me.refs['searchInput'].focus();
        }


        return true;
      }
    );
    //@ts-ignore
    me.refs['searchInput'].click();
    //@ts-ignore
    me.refs['searchInput'].focus();
    instance.registerHandlers("search", "inboxx", false, handlers);
  }

  componentWillUnmount() {
    let instance = hotkey.HotKeyEngine.instance();
    instance.unregisterHandlers("search");
  }
  onChange(event: any, newValue: string) {

  }
  searchInput: any;
  render() {
    let me = this;
    var searchBar = (
      <input
        className='simple-search-input'
        onFocus={async e => {
          /*if (
            this.props.connect.state.viewState.appSection !=
            model.AppSections.search
          ) {
              let state = {...me.state};
              state.preSearchHash = window.location.hash,
            this.setState(state);
          }*/
          me.props.connect.dispatch<any>(actions.ar_search(null))
          if (!me.state.isFocus) {
            let s = { ...me.state }
            {
              s.isFocus = true;
            }
            me.setState(s);
          }

          // window.location.hash = "#/search/";
        }}
        /* onInputMounted={async input => {
           //if (this.state.mobileSearch) {
             input.focus();
             input.click();
             input.setFocus("click");
           //}
           // forgive me for i have sinned
           this.searchInput = input;
         }}*/
        onKeyDown={(e) => {
          if (e.keyCode === 27) {
            me.cancelSearch(e);
          }
         
          if(e.keyCode===13){
            me.props.connect.dispatch<any>(actions.ar_searchEnter())
            //@ts-ignore
            me.refs['searchInput'].blur();
          }
        }}
        onChange={async e => {
          if (e) {
            me.props.connect.dispatch<any>(actions.ar_search(e.target.value))
            // window.location.hash = "#/search/" + encodeURIComponent(e.target.value);
          } else {
            //window.location.hash = "#/search/";
            me.props.connect.dispatch<any>(actions.ar_search(null))
          }

          /*
          this.setState({
            preSearchHash: this.state.preSearchHash,
            searchText: e.target.value,
  
   
          });*/
          //   ;
          // this.processProjectChange(this.refs.searchBar['state'].value, -1)
        }}

        onBlur={e => {
          if (me.state.isFocus) {
            let s = { ...me.state }
            {
              s.isFocus = false;
            }
            me.setState(s);
          }
        }}
        placeholder="Search..."
        ref='searchInput'
        value={this.props.connect.state.searchText}
        style={{
          margin: "0 auto"
        }}
      />
    );

    return (
      <div className='row no-gutters simple-header' >
        <div className="col-12 no-gutters simple-nav" style={{
          width: '100%', paddingLeft: '35px',
          borderLeft: '4px lightgray solid'
        }}>
          {searchBar}
          <div className='float-right' style={{ paddingTop: '15px', paddingRight: '15px', zIndex: 100 }}>
            <SimpleIconButton alt="Cancel Search" icon={CancelIcon} onClick={this.cancelSearch.bind(this)} />
          </div>
        </div>

      </div>
    )
  }
}