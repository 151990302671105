import * as React from "react";
import * as classNames from "classnames";
import * as material from "material-ui";
import * as model from "../../lib/model"
import * as actions from "../../lib/ActionReducer"
import * as pApi from "../../../shared/papi/papi-core"
import * as hotkey from "../../lib/hotkeyClient";

import welcomeJson  from "./welcome";
import tagsJson  from "./tags";
var wec = require('./welcome')
let $ = require('jquery')
interface AccountSetupProps {
  connect: model.ConnectProps;
}
interface AccountSetupPropsState {
  
}

export class AccountSetup extends React.Component<
AccountSetupProps,
AccountSetupPropsState
> {
  constructor(props, context) {
    super(props, context);
    this.state = {
    };
  }
  startingUp:boolean = false;
  setProcess(percentage:number){
    let progress = new model.ProgressState();
    progress.text = 'Setting up your Account.';
    progress.progress=percentage;
    this.props.connect.dispatch<any>(actions.ar_setProgress(progress))
  }
  async setupTodo(description:string,tag:string,priority:boolean):Promise<pApi.ToDoItem>
  { 
    let todo = new pApi.ToDoItem();
    todo.description = description;
    todo.priority = priority?pApi.ToDoItemPriority.High:pApi.ToDoItemPriority.Normal;
    todo.tags=tag?[tag]:[];
    return this.props.connect.state.context.Todos.save(todo);
           

     

  }
  startUp():Promise<void> 
  {
      return new Promise<void>(async (resolve,reject)=>{
          try
          {
            this.setProcess(0);
            let settings = await this.props.connect.state.context.Settings.set (
                new pApi.DojoSettings()
            )
            this.props.connect.dispatch<any>(actions.ar_loadSettingsComplete([settings]))
            //Setup Todos 
            let todos = await this.props.connect.state.context.Todos.getAllOutstandingItems();
            if(todos.length > 0 || !this.props.connect.state.isOnline)
            {
              this.props.connect.dispatch<any>(actions.ar_setProgress(null));
                return window.location.hash = '#/inbox';
            }
            await this.setupTodo("Get the most of of life!",null,true);
            var todo1 = await this.setupTodo("Create my first To-Do",'welcome',false);
            this.setProcess(10);
            var todotags = await this.setupTodo("Create a tag to organize notes and todos",'welcome/tags',false);
            this.setProcess(30);
           
            var welcome = JSON.parse(JSON.stringify(welcomeJson).replace('$$id1',todo1.id));
            var tags = JSON.parse(JSON.stringify(tagsJson).replace('$$id1',todotags.id));
            let welcomeNote = new pApi.Note();
            welcomeNote.title='Welcome to TodoZero';
     
            welcomeNote.tags = ['welcome'];
            welcomeNote.is_new = true;
            welcomeNote.id = pApi.newId();
            let welcomeContent = new pApi.NoteContent();
            welcomeContent.contentType = pApi.ContentType.quillJS;
            welcomeContent.storageType = pApi.StorageType.embedded;
            welcomeContent.content = welcome;
            this.setProcess(60);
            await this.props.connect.state.context.Notes.setContent(welcomeNote, welcomeContent)

            let tagNote = new pApi.Note();
            tagNote.title='Organize with Tags';
            tagNote.tags= ['welcome/tags'];
            tagNote.is_new = true;
            tagNote.id = pApi.newId();
            let tagNoteContent = new pApi.NoteContent();
            tagNoteContent.contentType = pApi.ContentType.quillJS
            tagNoteContent.storageType = pApi.StorageType.embedded;
            tagNoteContent.content = tags;
            this.setProcess(60);
            await this.props.connect.state.context.Notes.setContent(welcomeNote, welcomeContent)
            this.setProcess(90);
            await this.props.connect.state.context.Notes.setContent(tagNote, tagNoteContent)
            this.props.connect.dispatch<any>(actions.ar_setProgress(null));
            let vs = new model.ViewState();
            vs.appSection = model.AppSections.notes;
            vs.noteViewState = new model.NoteViewState();
            vs.noteViewState.noteId = welcomeNote.id;
            window.location.hash = vs.getHash();
            $('iframe').attr('src','https://www.todozero.com/trackaccountcreation');

          } 
          catch(e)
          {
              console.error(e);
              window.location.hash = '#/inbox';
              this.props.connect.dispatch<any>(actions.ar_setProgress(null));
          }
      })
  }
  render() {
      ;

    if(!this.startingUp)
    {
        this.startUp();
    }
    this.startingUp  = true;
    return (
      <div >
         
      </div>
    );
  }
}
