import * as pApi from "../../shared/papi/papi-core";
import { ErrorObj } from "../../shared/track";
import { Dispatch } from "redux";
import { HybridContext } from "./frontEndContext";
import * as sl from '../../shared/lib/superListTypes'

import * as sm from '../../shared/lib/sharedModel'

export * from '../../shared/lib/sharedModel';
export class TodoGroups {
  priorityItems: Array<pApi.ToDoItem> = new Array<pApi.ToDoItem>();
  dueItems: Array<pApi.ToDoItem> = new Array<pApi.ToDoItem>();
  noDueOrPriority: Array<pApi.ToDoItem> = new Array<pApi.ToDoItem>();
  otherWithTags: Array<pApi.ToDoItem> = new Array<pApi.ToDoItem>();
  snoozedItems: Array<pApi.ToDoItem> = new Array<pApi.ToDoItem>();
  constructor(items: Array<pApi.ToDoItem>) {
    let now = new Date();

    function hasTags(t: pApi.ToDoItem): boolean {
      if (!t.tags) return false;
      if (t.tags.length == 0) return false;
      return true;
    }
    this.priorityItems = items.filter(
      x => x.priority == pApi.ToDoItemPriority.High
    );
    this.dueItems = items.filter(
      x =>
        x.due_date &&
        x.due_date < now &&
        x.priority != pApi.ToDoItemPriority.High
    );
    this.noDueOrPriority = items.filter(
      x =>
        (!x.due_date || x.due_date == null) &&
        x.priority != pApi.ToDoItemPriority.High &&
        !hasTags(x)
    );
    this.otherWithTags = items.filter(
      x =>
        (!x.due_date || x.due_date == null) &&
        x.priority != pApi.ToDoItemPriority.High &&
        hasTags(x)
    );

    this.snoozedItems = items.filter(
      x => x.due_date > now && x.priority != pApi.ToDoItemPriority.High
    );
  }
}
export class TodoLinkHelper {
 
  static getLink(note: pApi.ToDoItem): string {
    var expression = /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi;
    // var expression = '/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g'
    var regex = new RegExp(expression);
    if(!note.description)
      return null;
    var linkArray = note.description.match(regex);

    if (linkArray && linkArray.length > 0) {
      for (var i in linkArray) {
        var la = linkArray[i];
        if (la.toLocaleLowerCase().indexOf("http") == 0) {
          // favor links that start with http
          return la;
        }
      }
      return linkArray[0];
    } else return null;
  }

  static getNoteLink(noteId: string, viewState: ViewState): string {
   

    if (noteId) {
      

      var vs = viewState.deepCopy();
      if (vs.appSection == AppSections.notes) {
        vs.noteViewState.noteId = noteId
        vs.noteViewState.noteViewType = NoteViewType.noteList;
      } else {
       // vs.appSection = AppSections.notes;
        vs.noteViewState = new NoteViewState();
        vs.noteViewState.noteId = noteId
        vs.noteViewState.noteViewType = NoteViewType.noteList;
      }
      return vs.getHash();
    } else return null;
  }
}


export function populate<T>(obj: T, values: any): T {
  for (var i in values) {
    obj[i] = values[i];
  }

  return obj;
}

export class EntityState {
  state: sm.LoadingState;
  errorMessage: string;
  old: any;
  ref?: any;
  constructor(state: sm.LoadingState, old?: any, errorMessage?: string) {
    this.state = state;
    this.old = { ...old };
    this.errorMessage = errorMessage;
  }
}
//export class Profile extends pApi.Profile {};

//export class ExternalLink extends pApi.ExternalLink {}

export class ColorSettings {
  primary: string = "#00BCD4";
  success: string = "#8BC34A";
  info: string = "#66BB6A";
  infoAlt: string = "#7E57C2";
  warning: string = "#FFCA28";
  danger: string = "#F44336";
  text: string = "#3D4051";
  gray: string = "#EDF0F1";
}
export class LayoutSettings {
  layoutBoxed: boolean = false; // true, false
  navCollapsed: boolean =
    getPresentationMode() == PresentationMode.mobile ? true : false; // true, false
  navBehind: boolean = true; // true, false
  fixedHeader: boolean = true; // true, false
  sidebarWidth: string = "middle"; // small, middle, large
  colorOption: string = "31"; // String: 11,12,13,14,15,16; 21,22,23,24,25,26; 31,32,33,34,35,36
  theme: string = "light";
  fullWidthHeader: boolean = true;
}

export class ObjectiveHistory {
  objective_id: any;
  since: Date;
  objectiveCheckin: Array<pApi.ObjectiveCheckIn>;
}

export class UIConfig {
  brand: string = "dojo";
  user: string = "paul";
  year: string = "2017";
  productLink: string = "google.com";
  AutoCloseMobileNav: boolean = true;
  color: ColorSettings = new ColorSettings();
  layout: LayoutSettings = new LayoutSettings();
}
export function TodoViewString(v: TodoView): string {
  switch (v) {
    case TodoView.All:
      return "ALL";
    case TodoView.Inbox:
      return "INBOX";
    case TodoView.Snoozed:
      return "SNOOZED";
    case TodoView.AllOpen:
      return "ALLOPEN";
    case TodoView.Completed:
      return "COMPLETED";
    case TodoView.Project:
      return "PROJECT";
    default:
      throw "invalid todo enum " + v;
  }
}
export function TodoViewEnum(v: string): TodoView {
  if (!v) return TodoView.Project;
  switch (v.toUpperCase()) {
    case "ALL":
      return TodoView.All;
    case "INBOX":
      return TodoView.Inbox;
    case "SNOOZED":
      return TodoView.Snoozed;
    case "ALLOPEN":
      return TodoView.AllOpen;
    case "PROJECT":
      return TodoView.Project;
    case "COMPLETED":
      return TodoView.Completed;
    default:
      throw v + " is not a valid ToDo enum";
  }
}

export enum TodoView {
  Inbox,
  Project,
  Snoozed,
  AllOpen,
  All,
  Completed
}


export enum ProjectViewState {
  None,
  NotesTodo,
  NotesFullScreen,
  List,
  ObjectiveList,
  Inbox,
  InboxNotes,
  Notes
}
export enum NoteFunctionType {
  all,
  search,
  tag
}
export enum NoteViewType {
  none = "none",
  noteList = "nl",
  /*todoList = "tl",*/
  fullScreen = "fs"
}
export enum AppSections {
  inbox,
  today,
  thisweek,
  bydate,
  login,
  snoozed,
  objectives,
  notes,
  archived,
  search,
  setup,
  settings,
  help
}
export class LastSnooze {
  action: pApi.SnoozeAction;
  date?: Date;
}
export enum SettingSection
{
    profile,hotkeys,api,billing,options
}
function SettingSectionToString(s:SettingSection):any{
   switch(s)
   {
     case(SettingSection.billing): return 'billing';
     case(SettingSection.api): return 'api';
     case(SettingSection.profile): return 'profile';
     case(SettingSection.options): return 'options';
     case(SettingSection.hotkeys): return 'hotkeys';
     default:
      return null;
   }
}
function SettingSectionFromString(s:string):SettingSection{
  
  switch(s)
  {
    case("billing"): return SettingSection.billing;
    case("api"): return SettingSection.api;
    case("profile"): return SettingSection.profile;
    case("options"): return SettingSection.options;
    case("hotkeys"): return SettingSection.hotkeys;
    default:
     return null;
  }
}
export class ViewState {
  appSection: AppSections;
  settingSection:SettingSection;
  noteViewState: NoteViewState;
  searchText: string;
  redirect: any;
  todoId: any;
  sortByCreateDate:boolean;
  getTag(): string {
    if (this.appSection == AppSections.notes) {
      if (this.noteViewState.functionType == NoteFunctionType.tag) {
        return this.noteViewState.query;
      }
    }
    return null;
  }
  inAggregateToDoView(): boolean {
    switch (this.appSection) {
      case AppSections.inbox:
      case AppSections.snoozed:
      case AppSections.today:
      case AppSections.thisweek:
      case AppSections.notes:
      case AppSections.bydate:
        return true;
      default:
        return false;
    }
  }
  noteOpen(): boolean {
    return  this.noteViewState && this.noteViewState.noteId;
   return (
      this.appSection == AppSections.notes &&
      this.noteViewState
        .noteId 
    );
  }

  static getDefaultHash(appSection: AppSections,viewState:ViewState): string {
    let queryStr = "";
    if(viewState.sortByCreateDate)
    {
      queryStr +="?sortByCreateDate=1"
    }
    switch (appSection) {
      case AppSections.inbox:
        return "/inbox"+queryStr;
      case AppSections.today:
        return "/today"+queryStr;
      case AppSections.thisweek:
        return "/thisweek"+queryStr;
      case AppSections.bydate:
        return "/bydate"+queryStr;
      case AppSections.login:
        return "/login"+queryStr;
      case AppSections.snoozed:
        return "/upcoming"+queryStr;
      case AppSections.search:
        return "/search"+queryStr;
      case AppSections.objectives:
        return "/objectives"+queryStr;
      case AppSections.notes:
        return "/notes/all"+queryStr;
      case AppSections.archived:
        return "/archived"+queryStr;
      case AppSections.setup:
        return "/setup"
      case AppSections.settings:
        
        return "/settings";
      case AppSections.help:
        return "/help";
    }
  }
  static viewStateFromRoute(match: any): ViewState {
    var vs = new ViewState();
   
    vs.appSection = AppSections.inbox;
    if (match.path == "/objectives") {
      vs.appSection = AppSections.objectives;
    }
    if (match.path.indexOf("/today") == 0) {
      vs.appSection = AppSections.today;
    }
    if (match.path.indexOf("/thisweek") == 0) {
      vs.appSection = AppSections.thisweek;
    }
    
    if (match.path.indexOf("/bydate") == 0) {
      vs.appSection = AppSections.bydate;
    }
    if (match.path.indexOf("/upcoming") == 0) {
      vs.appSection = AppSections.snoozed;
    }
    if (match.path == "/archived") {
      vs.appSection = AppSections.archived;
    }
    if (match.path == "/login") {
      vs.appSection = AppSections.login;
    }
    if (match.path == "/setup") {
      vs.appSection = AppSections.setup;
    }
    if (match.path == "/help") {
      vs.appSection = AppSections.help;
    }
    if (match.path.indexOf("/settings") == 0) {
      vs.appSection = AppSections.settings;
   
      vs.settingSection = SettingSectionFromString(match.params.settingSection);
    }
    if (match.path.indexOf("/search") == 0) {
      vs.appSection = AppSections.search;
      vs.searchText = match.params.query;
      
    }
    if (match.path.indexOf("/notes") == 0) {
      vs.appSection = AppSections.notes;
     
    }
    
    if (match.params.todoId) {
      vs.todoId = match.params.todoId;
    }
    else
    {
      
      vs.noteViewState = NoteViewState.NoteViewFromParams(match.params);
    }
    if(match.params.searchText)
    {
       vs.searchText = match.params.searchText;
    }
    if(match.params.sortByCreateDate)
    {
      vs.sortByCreateDate = true;
    }
    else
    {
      vs.sortByCreateDate = false;
    }
    vs.redirect = match.params.redirect ? match.params.redirect : "";
    return vs;
  }
  deepCopy(): ViewState {
    var newState = new ViewState();
    var v: any = this;
    for (var i in v) {
      newState[i] = v[i];
    }
    if (this.noteViewState)
      newState.noteViewState = this.noteViewState.deepCopy();
    return newState;
  }
  getHash(): string {
    let noteQuery = (this.noteViewState && this.noteViewState.noteId)?this.noteViewState.getQuery():'';
    if(this.sortByCreateDate)
    {
      noteQuery = noteQuery?'&':'?';
      noteQuery += "sortByCreateDate=1";
    }
    if(this.searchText)
    {
      noteQuery = noteQuery?'&':'?';
      noteQuery += "searchText="+this.searchText;
    }
    switch (this.appSection) {
      case AppSections.notes:
        return this.noteViewState.getHash()+noteQuery
      case AppSections.today:
        return "#/today"+noteQuery;
      case AppSections.objectives:
        return "#/objectives"+noteQuery
      case AppSections.snoozed:
        return "#/upcoming"+noteQuery
      case AppSections.bydate:
          return "#/bydate"+noteQuery
      case AppSections.archived:
        return "#/archived"
      case AppSections.settings:
        return "#/settings/"+SettingSectionToString(this.settingSection);
      case AppSections.help:
        return "#/help";
      case AppSections.search:
        return "#/search"+noteQuery
      case AppSections.login:
        return this.redirect ? "#/login/?redirect=" + this.redirect : "#/login";
      default:
        return "#/inbox"+noteQuery
    }
  }
}

export class NoteViewState {
  noteId: any;
  noteViewType: NoteViewType = NoteViewType.noteList;
  functionType: NoteFunctionType;
  query: string;
  openFirstNote: boolean = true;
  deepCopy(): NoteViewState {
    var newState = new NoteViewState();
    var v: any = this;
    for (var i in v) {
      newState[i] = v[i];
    }
    return newState;
  }
  static noteViewTypeFromString(str): NoteViewType {
    for (var i in NoteViewType) {
      if (NoteViewType[i] == str) return NoteViewType[i] as NoteViewType;
    }
    return NoteViewType.noteList;
  }
  static NoteViewFromParams(obj: any): NoteViewState {
    
    var retval = new NoteViewState();
    var s: NoteFunctionType;

    retval.functionType = obj["function"]
      ? NoteFunctionType[obj["function"] as string]
      : NoteFunctionType.all;
    retval.noteViewType = this.noteViewTypeFromString(obj["noteViewType"]);
    retval.noteId = obj["noteId"] != "default" &&obj["noteId"] != "null"  ? obj["noteId"] : null;
    retval.openFirstNote = !retval.noteId && obj["noteId"] == "default";
    if (retval.functionType == NoteFunctionType.tag)
    {
      if(obj["query"] && obj["query"].indexOf('~')==0)
      {
        retval.query = obj["query"];
      }
      else{
      retval.query = obj["query"] ? obj["query"].split(">").join("/") : null;
      }
    }
    //.replace('','/'):null;
    else retval.query = obj["query"] ? obj["query"] : null;
    console.log("NOTEID"+retval.noteId)
   
    return retval;
  }
  getQuery()
  {
   
     return "/note/"+this.noteId;
  }
  getHash() {
    ///notes/f/:function/q/:query/:action/:nodeId"
    var url = "notes";
    var escapeQuery = this.query ? this.query.split("/").join(">") : null; //("/", ">") : null;
    var openFirst = this.openFirstNote ? "/default" : "";
    switch (this.functionType) {
      case NoteFunctionType.search:
        if (this.noteId) {
          return (
            url +
            "/f/search/q/" +
            escapeQuery +
            "/" +
            this.noteViewType +
            "/" +
            this.noteId
          );
        } else {
          return (
            url +
            "/f/search/q/" +
            escapeQuery +
            "/" +
            this.noteViewType +
            openFirst
          );
        }

      case NoteFunctionType.tag:
        if (this.noteId) {
          return (
            url +
            "/f/tag/q/" +
            escapeQuery +
            "/" +
            this.noteViewType +
            "/" +
            this.noteId
          );
        } else {
          return (
            url +
            "/f/tag/q/" +
            escapeQuery +
            "/" +
            this.noteViewType +
            openFirst
          );
        }

      default:
        if (this.noteId) {
          return url + "/all/" + this.noteViewType + "/" + this.noteId;
        } else {
          return url + "/all/";
        }
    }
  }
}
export enum AuthState {
  Uninitialized,
  Initializing,
  Unauthenticated,
  Authenticated
}

export enum PresentationMode {
  web,
  mobile
}
export class SearchResultItem {
  type: string;
  todo: pApi.ToDoItem;
  note: pApi.Note;
  id: any;
  modified: Date;
  title: string;
  description: string;
  tags: Array<string>;
  sort: number;
}
export enum SnackCommandAction {
  undoTodoCheck,
  openTodo,
  updateAvailable,
  restartUpdate
}
export class SnackMessage {
  message: string;
  actionText: string;
  actionCommand: SnackCommandAction;
  actionParameter: any;
  autoHideDuration?: number;
}
export enum OfflinePersistanceMode {
  goodToGo,
  multipleTabs,
  notSupported
}

export class HotkeyPress
{
    hotkey:string;
    timestamp:Date = new Date()
    currentFocus:string;
    stack:Array<string> = new Array()
    constructor()
    {
      this.hotkey = null;
      this.currentFocus = null;
      this.timestamp = new Date();
    }
    deepCopy()
    {
        let newHotKey = new HotkeyPress();
        newHotKey.currentFocus = this.currentFocus;
        newHotKey.hotkey = this.hotkey;
        newHotKey.timestamp = this.timestamp;
        newHotKey.stack = this.stack
        return newHotKey;
    }
    setKey(key)
    {
      this.hotkey = key;
      this.timestamp = new Date();
      console.log(`hotkey:${this.currentFocus} ${this.hotkey}`)
    }
    setFocus(focus:string){
       console.log(`hotkey:SetFocus ${focus} - ${this.stack.join(',')}`)
       if(this.currentFocus != focus)
        this.stack.push(this.currentFocus);
       this.currentFocus = focus;
       
    }
    clearFocus(focus:string){
       if(this.currentFocus == focus)
       {
          if(this.stack.length > 0)
            this.currentFocus= this.stack.pop();
          else
            this.currentFocus  =null;
       }
       console.log(`hotkey:ClearFocus ${focus} -> ${this.currentFocus} ${this.stack.join(',')}`)
    }
    compare(hk:HotkeyPress)
    {
      
      if(hk == null)
        return false;
      let diff= (
        hk.currentFocus == this.currentFocus &&
        hk.hotkey == this.hotkey &&
        hk.timestamp == this.timestamp
      )
      console.log(this);
      console.log(hk);
      console.log('hotkey:same'+diff);
      return diff;
    }
}

export type IState = {
  isOnline: boolean;
  offlinePersistanceMode: OfflinePersistanceMode;
  objectives?: pApi.Objective[];
  objectiveHistory?: ObjectiveHistory;
  objectiveListState: sm.LoadingState;
  currentObjective?: pApi.Objective;
  todos: Array<pApi.ToDoItem>;
  todoQueryId:string;
  todoView: TodoView;
  TodosState: sm.LoadingState;
  uiConifg: UIConfig;
  editTodo: sm.LoadingObject<pApi.ToDoItem>;
  /*editTodoState: sm.LoadingState;*/
  AuthState: AuthState;
  user?: sm.User;
  editingProject: boolean;
  checkInModal: boolean;
  objectiveModal: boolean;
  context: HybridContext;
  TagsState: sm.LoadingState;
  tags?: Array<pApi.Tag>;
  notes:sm.LoadingObject<Array<pApi.Note>>;
  noteState:sm.LoadingState;
  viewState: ViewState;
  windowHeight: number;
  lastSnooze?: LastSnooze;
  client_id: number;
  progressState?: ProgressState;
  platform: pApi.Platform;
  searchKeyState: sm.LoadingState;
  searchKey: string;
  searchText?: string;
  searchResults: Array<pApi.ToDoItem>;
  lastError?: ErrorObj;
  errorArray: Array<ErrorObj>;
  errorShow: boolean;
  dojoSettingsState: sm.LoadingState;
  dojoSettings: pApi.DojoSettings;
  snackMessage: SnackMessage;
  archiveTodos: Array<pApi.ToDoItem>;
  archiveTodoState: sm.LoadingState;
  archiveNotes: Array<pApi.Note>;
  archiveNotesState: sm.LoadingState;
  apiKeyState: sm.LoadingState;
  apiKey?: pApi.ApiKey;
  selectedState:sl.SuperListSelectedState
  hotkey:HotkeyPress;
  scrollingTarget?:any
  previewImage?:string;
  canAlwaysTop:boolean;
  alwaysTopOn:boolean;

};

export class ProgressState {
  text: string;
  progress: number;
}
export function getPresentationMode(): PresentationMode {
  if (window["ios"]) {
    return PresentationMode.mobile;
  }
  if (window.innerWidth <= 576) {
    return PresentationMode.mobile;
  } else {
    return PresentationMode.web;
  }
}
export class ConnectProps {
  state: IState;
  dispatch: Dispatch<{}>;
  match: any;
  inMobileNoteView(): boolean {
    return (
      this.getPresentationMode() == PresentationMode.mobile &&
      this.state.viewState.noteOpen()
    );
  }
  getPresentationMode(): PresentationMode {
    return getPresentationMode();
  }
}


export function deepCompare (a:any,b:any):boolean {
  var i, l, leftChain, rightChain;

  function compare2Objects (x, y) {
    var p;

    // remember that NaN === NaN returns false
    // and isNaN(undefined) returns true
    if (isNaN(x) && isNaN(y) && typeof x === 'number' && typeof y === 'number') {
         return true;
    }

    // Compare primitives and functions.     
    // Check if both arguments link to the same object.
    // Especially useful on the step where we compare prototypes
    if (x === y) {
        return true;
    }

    // Works in case when functions are created in constructor.
    // Comparing dates is a common scenario. Another built-ins?
    // We can even handle functions passed across iframes
    if ((typeof x === 'function' && typeof y === 'function') ||
       (x instanceof Date && y instanceof Date) ||
       (x instanceof RegExp && y instanceof RegExp) ||
       (x instanceof String && y instanceof String) ||
       (x instanceof Number && y instanceof Number)) {
        return x.toString() === y.toString();
    }

    // At last checking prototypes as good as we can
    if (!(x instanceof Object && y instanceof Object)) {
        return false;
    }

    if (x.isPrototypeOf(y) || y.isPrototypeOf(x)) {
        return false;
    }

    if (x.constructor !== y.constructor) {
        return false;
    }

    if (x.prototype !== y.prototype) {
        return false;
    }

    // Check for infinitive linking loops
    if (leftChain.indexOf(x) > -1 || rightChain.indexOf(y) > -1) {
         return false;
    }

    // Quick checking of one object being a subset of another.
    // todo: cache the structure of arguments[0] for performance
    for (p in y) {
        if (y.hasOwnProperty(p) !== x.hasOwnProperty(p)) {
            return false;
        }
        else if (typeof y[p] !== typeof x[p]) {
            return false;
        }
    }

    for (p in x) {
        if(p=='connect')
          return true;
        if (y.hasOwnProperty(p) !== x.hasOwnProperty(p)) {
            return false;
        }
        else if (typeof y[p] !== typeof x[p]) {
            return false;
        }

        switch (typeof (x[p])) {
            case 'object':
            case 'function':

                leftChain.push(x);
                rightChain.push(y);

                if (!compare2Objects (x[p], y[p])) {
                    return false;
                }

                leftChain.pop();
                rightChain.pop();
                break;

            default:
                if (x[p] !== y[p]) {
                    return false;
                }
                break;
        }
    }

    return true;
  }

  if (arguments.length < 1) {
    return true; //Die silently? Don't know how to handle such case, please help...
    // throw "Need two or more arguments to compare";
  }

  for (i = 1, l = arguments.length; i < l; i++) {

      leftChain = []; //Todo: this can be cached
      rightChain = [];

      if (!compare2Objects(arguments[0], arguments[i])) {
          return false;
      }
  }

  return true;
}