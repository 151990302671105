import { combineReducers } from 'redux';

import * as actions from '../lib/ActionReducer'


//@ts-ignore
/*
const rootReducer = combineReducers({
   actions.reducer
});*/
 
export default  actions.reducer;
