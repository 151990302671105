import * as React from "react";
import * as material from "material-ui";
import * as model from "../lib/model";
import * as pApi from "../../shared/papi/papi-core";
import RepeatIcon from "material-ui/svg-icons/av/repeat";
import AlarmOn from "material-ui/svg-icons/action/alarm-on";

import StarOn from "material-ui/svg-icons/toggle/star";
import StarOff from "material-ui/svg-icons/toggle/star-border";
import DescriptionIcon from 'material-ui/svg-icons/action/description';
import { SnoozeTyper } from "../components/SnoozeTyper";
import CheckIcon from "material-ui/svg-icons/navigation/check";
import CheckAllIcon from "material-ui/svg-icons/action/done-all";
import LinkIcon from "material-ui/svg-icons/content/link";
import NoteIcon from "material-ui/svg-icons/content/content-paste";
import Snooze from "material-ui/svg-icons/av/snooze";
import * as sl from "../../shared/lib/superListTypes";
import UnWatch from "../components/CustomSvg/UnWatch"
import * as actions from "../lib/ActionReducer";
import { SimpleIconButton } from '../components/SimpleIconButton'
import moment from 'moment'


export class TodoTagSelectorProps {

  selected: boolean;
  connect: model.ConnectProps;
  tagDisplay: string;
  group: any;
  stared: boolean;
  due: boolean;
  note: boolean;



}

/*
    */

export class NewTodoTagSelectorProps {
  todos: Array<pApi.ToDoItem>;
  isNote: boolean;
  selected: boolean;
  group: string;
  tagDisplay: string;
}
export function NewTodoTagSelector(props: NewTodoTagSelectorProps) {

  let me = this;
  let hasMultiple = props.todos && props.todos.length > 1;
  let firstTodo = props.todos && props.todos.length > 0 ? props.todos[0] : null;
  let reoccuring = !hasMultiple && firstTodo.recoccuring_settings;
  let starred = !hasMultiple && firstTodo.priority == pApi.ToDoItemPriority.High;
  let alarm = !hasMultiple && firstTodo.alarm;
  let hidden = !hasMultiple && firstTodo.hide_from_inbox;
  let snoozed = !hasMultiple && firstTodo.snooze_count > 2
  let hasNote = (!hasMultiple && firstTodo.note_id) || props.isNote
  return (
    <div className='superlist-todo-tagname-container'>
      <div className='superlist-todo-tagname-icon-container'>

        {starred && (
          <div className='simple-nav-icon-position'>
            <StarOn
              color="orangered"
              style={{
                width: '12px',
                height: '12px'
              }}
            />
          </div>
        )}
        {props.isNote && (
          <div className='simple-nav-icon-position'>
            <DescriptionIcon
              color="#757575"
              style={{
                width: '12px',
                height: '12px'
              }}
            />
          </div>
        )}


      </div>
      {props.tagDisplay && (
        <div className='superlist-todo-tag-name'>
          #{props.tagDisplay.split("/").join("/")}
        </div>
      )}
      {!props.tagDisplay && (
        <div className='superlist-todo-tag-name'>Inbox</div>
      )}
      {hidden && (
        <div className='simple-nav-icon-position'>
          <UnWatch

            style={{
              width: '12px',
              height: '12px'
            }}
          />
        </div>
      )}
      {reoccuring && (
        <div className='simple-nav-icon-position'>
          <RepeatIcon

            style={{
              width: '12px',
              height: '12px'
            }}
          />
        </div>
      )}
      {alarm && (
        <div className='simple-nav-icon-position'>
          <AlarmOn
            color="orangered"
            style={{
              width: '12px',
              height: '12px'
            }}
          />
        </div>
      )}
    </div>

  )

}
export class TodoTagSelectorState { }

export class TodoTagSelector extends React.Component<
  TodoTagSelectorProps,
  TodoTagSelectorState
> {
  constructor(props, context) {
    super(props, context);
    this.state = {
      hovering: false
    };
  }

  render() {
    let me = this;

    return (


      <div className='superlist-todo-tagname-container'>
        <div className='superlist-todo-tagname-icon-container'>
          {this.props.stared && (
            <div className='simple-nav-icon-position'>
              <StarOn
                color="orangered"
                style={{
                  width: '12px',
                  height: '12px'
                }}
              />
            </div>
          )}
          {this.props.note && (
            <div className='simple-nav-icon-position'>
              <DescriptionIcon
                color="#757575"
                style={{
                  width: '12px',
                  height: '12px'
                }}
              />
            </div>
          )}
          {!this.props.stared && this.props.due && (
            <span className='simple-nav-icon-position'>
              <AlarmOn
                color="red"
                style={{
                  width: '12px',
                  height: '12px'
                }}

              />
            </span>
          )}
          {!this.props.stared && !this.props.due && !this.props.note && (
            <span style={{ paddingLeft: '12px' }}>



            </span>
          )}
        </div>
        {this.props.tagDisplay && (
          <div className='superlist-todo-tag-name'>
            #{this.props.tagDisplay.split("/").join("/")}
          </div>
        )}
        {!this.props.tagDisplay && (
          <div className='superlist-todo-tag-name'>Inbox</div>
        )}
      </div>
    );
  }
}



interface ListRowProps {
  focused: boolean;
  selected: boolean;
  row: sl.SelectableRow;
  todos: Array<pApi.ToDoItem>;
  queryId: any;
  classes?: any;
  multiSelect: boolean;
  onClick: () => void;
  onSnooze: () => void;
  onComplete: () => void;
  connect: model.ConnectProps;
}
interface ListRowState {

}
class ListItem extends React.Component<
  ListRowProps,
  ListRowState
> {
  constructor(props, context) {
    super(props, context);
    this.state = {

    };
  }

  shouldComponentUpdate(nextProps, nextState) {

    if (nextProps.focused === this.props.focused &&
      nextProps.selected === this.props.selected &&
      nextProps.queryId === this.props.queryId &&
      nextProps.multiSelect == this.props.multiSelect

    ) {
      /// console.log(' skip render')
      return false;
    }
    else {
      console.log(' render ')
      return true;
    }
  }
  firstTodo(): pApi.ToDoItem {

    return this.props.todos[0];
  }
  hasMultiple(): boolean {
    return this.props.todos.length > 1;
  }
  /*shouldComponentUpdate(nextProps:TodoItemPageProps, nextState:TodoItemPageState):boolean
  {
      if(!model.deepCompare(this.state, nextState ))
        return true;
        
      if(!model.deepCompare(this.props, nextProps ))
          return true;
      if(!model.deepCompare(this.props.connect.state.inboxData,nextProps.connect.state.inboxData))
          return true;
      console.log('skip update')
      return true;
  }*/
  async unWatchToggle(e: any) {
    e.preventDefault();
    e.stopPropagation();
    if (
      window.confirm(
        `Are you sure you want to hide ${this.props.todos.length
        } from your inbox?`
      )
    ) {
      //;
      for (var i in this.props.todos) {
        this.props.todos[i].hide_from_inbox = true;
      }
      await actions.shared_bulkUpdate(this.props.connect, this.props.todos);
    }
  }
  async setSnoozeDate(snoozeAction: pApi.SnoozeAction, date?: Date) {
    actions.shared_snoozeItems(
      false,
      this.props.connect,
      this.props.todos,
      snoozeAction,
      date
    );


  }


  getLink(s: string): string {
    if (!this.hasMultiple()) {
      return model.TodoLinkHelper.getLink(this.firstTodo());
    } else return null;
  }
  getNoteLink(): string {
    var noteId = this.firstTodo().note_id;
    if (noteId && this.hasMultiple()) {
      for (var i in this.props.todos) {
        if (this.props.todos[i].note_id != noteId) return;
      }
    }
    if (noteId) {
      return model.TodoLinkHelper.getNoteLink(
        this.firstTodo().note_id,
        this.props.connect.state.viewState
      );
    } else return null;
  }
  async docLinkClick(e) {

    e.preventDefault();
    e.stopPropagation();
    if (this.getNoteLink()) {
      window.location.hash = this.getNoteLink();

    }

  }
  async linkClick(e) {
    var link = this.getLink(this.firstTodo().description);
    // @ts-ignore
    // navigator.app.loadUrl(link, {openExternal : true});
    //var win = window.open(link, "_system");
    window["openLink"](link);//
    // @ts-ignore
    // win.addEventListener('loadstart', ()=>{win.show()});;

    //win.focus();
    e.preventDefault();
    e.stopPropagation();
  }



  async onDoubleClick(e) {
    console.log("onDoubleClick");
    e.preventDefault();
    e.stopPropagation();
    this.props.onClick();
    /* if (this.props.connect.state.inboxData.hasSelections()) {
       if (this.props.selected)
         this.props.connect.dispatch(actions.ar_clearSelect(this.props.row.group));
       else this.props.connect.dispatch(actions.ar_setSelect(this.props.row.group));
       return;
     }
     if (this.props.todos.length > 1) {
         alert('onselect')
         this.props.onSelect();
       
       return true;
     }*/
    // this.props.connect.dispatch<any>(actions.showTodoModal(this.firstTodo()));

  }


  render() {
    var link = this.getLink(this.firstTodo().description);
    var noteLink = this.getNoteLink();
    var dateText = this.firstTodo().due_date
      ? moment(this.firstTodo().due_date).calendar(null, {
        lastDay: "[Yesterday]",
        sameDay: "[Today] h:mm a",
        nextDay: "[Tomorrow] h:mm a",
        lastWeek: "[last] dddd",
        nextWeek: "dddd",
        sameElse: "L"
      })
      : "";
    let me = this;
    let getTagForView = function (tags: Array<string>): string {
      if (!tags || tags.length == 0) return null;
      if (tags.length == 1) {
        return tags[0];
      }
      if (
        me.props.connect.state.viewState.noteViewState &&
        /*params.connect.state.viewState.noteViewState.noteViewType ==
            model.NoteViewType.todoList &&*/
        me.props.connect.state.viewState.noteViewState.functionType ==
        model.NoteFunctionType.tag
      ) {
        var filtered = tags.filter(
          x => x != me.props.connect.state.viewState.noteViewState.query
        );
        return filtered[0];
      } else {
        return tags[0];
      }
    };
    let tagDisplay = getTagForView(this.firstTodo().tags);
    let noteDescription = (
      <span>
        {this.hasMultiple() && (
          <span className="bundle-number">
            {this.props.todos.length} to-dos
          </span>
        )}
        {this.hasMultiple() && (
          <span style={{ color: "#757575" }}>

            {this.firstTodo().description}

          </span>
        )}



        {!this.hasMultiple() && (
          <span style={{}}>
            {this.firstTodo().description}

          </span>
        )}

      </span>
    );
    let dateStyle = {}
    let iconStyle = { width: "12px", height: "12px" }
    let snoozeCountStyle = {
      fontSize: 8,
    } as  React.CSSProperties;
    if (this.firstTodo().due_date && this.firstTodo().due_date < new Date()) {
      dateStyle = {
        color: 'red'
      }
      iconStyle['color'] = 'red';
      snoozeCountStyle['color'] = 'red';
    }
    let reoccuring = !this.hasMultiple() && this.firstTodo().recoccuring_settings;
    let alarm = !this.hasMultiple() && this.firstTodo().alarm;
    let hidden = !this.hasMultiple() && this.firstTodo().hide_from_inbox;
    let snoozed = !this.hasMultiple() && this.firstTodo().snooze_count > 2
    let hasLink =   link != null && noteLink == null;
    let hasNote = !this.hasMultiple() && this.firstTodo().note_id != null
    let subtextConponent = (
      <span className="simple-todo-subtext">
         {snoozed && (
          <div className='simple-nav-icon-position' title={"Snoozed " + this.firstTodo().snooze_count + " times."}>
            <Snooze style={iconStyle} />
            <span style={snoozeCountStyle}>{this.firstTodo().snooze_count}</span>
         
          </div>
        )}
        
        {hasNote && (
          <div className='simple-nav-icon-position' title="In a note">
            <NoteIcon style={iconStyle} />
        
          </div>
        )}
        {hasLink && (
             <div className='simple-nav-icon-position' title="Contains a link">
             <LinkIcon style={iconStyle} />
         
           </div>
        )}
        {hidden && (
          <div className='simple-nav-icon-position' title="Hidden from inbox">
            <UnWatch style={iconStyle} />

          </div>
        )}
        {reoccuring && (
          <div className='simple-nav-icon-position' title="Repeats">
            <RepeatIcon style={iconStyle} />
           
          </div>
        )}
        {alarm && (
          <div className='simple-nav-icon-position' title="Alarm Set">
            <AlarmOn style={iconStyle} />
          </div>
        )}
       
        {((reoccuring || alarm || snoozed || hasNote || hasLink || reoccuring) && dateText) && (
          <span style={{ paddingLeft: 3 }}></span>
        )}

        <span style={dateStyle}>{dateText}

        </span>
      </span>
    );

    const iconButtonElement = (
      <div className=''>

        {link == null && noteLink != null &&
          <SimpleIconButton icon={NoteIcon} alt="Open Link" onClick={this.docLinkClick.bind(this)} />

        }
        {link != null && noteLink == null &&
          <SimpleIconButton icon={LinkIcon} alt="Open Note"  onClick={this.linkClick.bind(this)} />

        }

        <SimpleIconButton
          icon={Snooze} alt="Snooze" onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            me.props.onSnooze();

            return true;
          }}
        />
        <SimpleIconButton
        alt={this.hasMultiple() ? "Complete All" : "Complete"}
          icon={this.hasMultiple() ? CheckAllIcon : CheckIcon} onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            me.props.onComplete();

            return true;
          }
          } />
      </div>
    )

    let now = new Date();
    let classDiv = "simple-todo-listitem";
    let focusDiv = ""

    return (
      <div

        style={{} as React.StyleHTMLAttributes<String>}

        onClick={this.onDoubleClick.bind(this)}

      >
        <div className="container-fluid simple-todo-container">
          <div className="row no-gutters"  >

            <div className="col-2 no-gutters "  >
              <TodoTagSelector
                connect={this.props.connect}
                group={this.props.row.group}
                tagDisplay={tagDisplay}
                selected={this.props.selected}
                stared={this.firstTodo().priority == pApi.ToDoItemPriority.High}
                due={this.firstTodo().due_date && this.firstTodo().due_date < now}
                note={false}
              />

            </div>

            <div
              className={"col-8 no-gutters"}
            >
              <div className="simple-todo-description">{noteDescription} </div>
            </div>
            <div className="col-2 no-gutters " style={{ 'textAlign': 'right' }}>
              <div className="simple-todo-subtext-div">
                {(!this.props.focused || this.props.selected) &&
                  <div> {subtextConponent} </div>
                }
                {(this.props.focused && !this.props.selected) &&
                  <div>
                    {iconButtonElement}
                  </div>
                }
              </div>

            </div>

          </div>
        </div>

      </div>
    );
  }



}

//@ts-ignore
export default ListItem
