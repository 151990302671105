export default [
  { "insert": "Welcome to TodoZero" },
  { "attributes": { "header": 1 }, "insert": "\n" },
  {
    "attributes": { "color": "rgba(0, 0, 0, 0.76)" },
    "insert": { "mention": { "id": "welcome", "value": "welcome" } }
  },
  { "attributes": { "color": "rgba(0, 0, 0, 0.76)" }, "insert": "  " },
  {
    "insert":
      "\TodoZero helps you organize your Notes and To-Dos.\nGetting Started"
  },
  { "attributes": { "header": 2 }, "insert": "\n" },
  { "insert": "\nInbox" },
  { "attributes": { "header": 3 }, "insert": "\n" },
  {
    "insert": {
      "image": {
        "external-src": "welcome/inbox.png",
        "src": "welcome/inbox.png"
      }
    }
  },
  {
    "insert":
      "\n\nWhen you create a To-Do it will show up in your Inbox (including any To-Dos you create in a note).  From here you can remove items by completing them (good job), snoozing them for later, or hiding them from your inbox.   A good strategy is to clear everything out of your Inbox at the end of the day. That doesn't mean completing everything, but organizing your To-Dos to what you need to focus on now, and ignoring everything else.\n\n"
  },
  {
    "attributes": { "background": "transparent", "color": "#434343" },
    "insert": "Snoozing "
  },
  { "attributes": { "header": 3 }, "insert": "\n" },
  {
    "attributes": { "background": "transparent", "color": "#000000" },
    "insert":
      "Its ok to snooze to-dos. If you don't intend to get to a To-Do completed until this weekend or next month, just snooze it, and it will pop back up at the appropriate time."
  },
  { "insert": "\n" },
  {
    "insert": {
      "image": {
        "external-src": "welcome/snooze.png",
        "src": "welcome/snooze.png"
      }
    }
  },
  { "insert": "\n\n\n" },
  {
    "attributes": { "background": "transparent", "color": "#000000" },
    "insert": "To snooze, hover over the To-Do and click the"
  },
  { "insert": " " },
  {
    "insert": {
      "image": {
        "external-src": "welcome/snooze-icon.png",
        "src": "welcome/snooze-icon.png"
      }
    }
  },
  {
    "attributes": { "background": "transparent", "color": "#000000" },
    "insert": "icon or swipe left on your mobile device"
  },
  { "insert": "\nTo-Do Grouping" },
  {
    "attributes": { "background": "transparent", "color": "#000000" },
    "insert": " "
  },
  { "attributes": { "header": 3 }, "insert": "\n" },
  {
    "insert":
      "\nNoteDojo groups items in your inbox based on the first Tag on a To-Do. This is helpful to quickly view your Inbox without being overwhelmed by too many to-dos.\n\n"
  },
  {
    "insert": {
      "image": {
        "external-src": "welcome/grouping.png",
        "src": "welcome/grouping.png"
      }
    }
  },
  { "insert": "\n\n" },
  {
    "attributes": { "background": "transparent", "color": "#000000" },
    "insert": "Hide from Inbox "
  },
  { "attributes": { "header": 3 }, "insert": "\n" },
  {
    "insert":
      "Sometimes you might not want certain To-Dos to clutter your inbox.   You can click the"
  },
  {
    "attributes": { "background": "transparent", "color": "#000000" },
    "insert": " "
  },
  {
    "insert": {
      "image": {
        "external-src": "welcome/hideicon.png",
        "src": "welcome/hideicon.png"
      }
    }
  },
  { "insert": " i" },
  {
    "attributes": { "background": "transparent", "color": "#000000" },
    "insert": "con to hide it."
  },
  { "insert": "\n" },
  {
    "attributes": { "background": "transparent", "color": "#000000" },
    "insert": "Starring"
  },
  { "attributes": { "header": 3 }, "insert": "\n" },
  {
    "attributes": { "background": "transparent", "color": "#000000" },
    "insert":
      "Star your most important to-dos. These to-dos will show up at the top of the list."
  },
  { "insert": "\n\n" },
  {
    "attributes": { "background": "transparent", "color": "#000000" },
    "insert": "Creating Notes and To-dos"
  },
  { "attributes": { "header": 2 }, "insert": "\n" },
  { "insert": "\n" },
  {
    "attributes": { "background": "transparent", "color": "#000000" },
    "insert":
      "On any screen in Note-Dojo you can create a To-Do or Note by clicking + at the lower right hand of the screen."
  },
  { "insert": "\n\n" },
  {
    "attributes": { "background": "transparent", "color": "#000000" },
    "insert": "Editing and Organizing Notes"
  },
  { "attributes": { "header": 3 }, "insert": "\n" },
  { "insert": "\n" },
  {
    "attributes": { "background": "transparent", "color": "#000000" },
    "insert":
      "When you create a Note the first line of the Note will be used for the title.   NoteDojo allows you to quickly make list and "
  },
  {
    "attributes": {
      "background": "transparent",
      "color": "#000000",
      "italic": true
    },
    "insert": "formatting"
  },
  {
    "attributes": { "background": "transparent", "color": "#000000" },
    "insert": "  "
  },
  {
    "attributes": {
      "background": "transparent",
      "bold": true,
      "color": "#000000"
    },
    "insert": "changes"
  },
  {
    "attributes": { "background": "transparent", "color": "#000000" },
    "insert": "  using "
  },
  {
    "attributes": {
      "background": "transparent",
      "color": "#1155cc",
      "link": "https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet"
    },
    "insert": "MarkDown"
  },
  {
    "attributes": { "background": "transparent", "color": "#000000" },
    "insert": " shortcuts or using the format toolbar."
  },
  { "insert": "\n\n" },
  {
    "attributes": { "background": "transparent", "color": "#000000" },
    "insert": "To-Dos in Notes"
  },
  { "attributes": { "header": 3 }, "insert": "\n" },
  {
    "insert":
      "\nYou can embed To-Dos in your Notes by typing [ ] followed by a To-Do description or clicking the ☐ icon in the toolbar. The To-Do will automatically be added to your inbox and will automatically sync any changes between the Note and the To-Do.\n\n"
  },
  { "insert": "Create my first To-Do" },
  {
    "attributes": {
      "list": {
        "checked": false,
        "id": "$$id1",
        "mode": "ready",
        "priority": "0"
      }
    },
    "insert": "\n"
  },
  { "insert": "\n\n\n\n\n\n\n\n\n\n" }
]
