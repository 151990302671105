import React, { useState, useEffect } from 'react';
import Viewer from 'react-viewer';
import * as actions from '../lib/ActionReducer'
import * as pApi from "../../shared/papi/papi-core"
import * as sl from '../../shared/lib/superListTypes'
import { formatRelativeDateWithTime } from '../../shared/lib/sharedModel'
import * as model from "../lib/model";
import { debug } from 'console';

class NoteInfo {
    title: string;
    modified: Date;
    tags: Array<string>
}
class LinkInfo {
    title: string;
    description: string;
    url: string;
}
class SelectedSummaryProps {
    connect: model.ConnectProps
}
export default (props: SelectedSummaryProps, dispatch: any) => {
    const [selectedTodo, setSelectedTodo] = React.useState<pApi.ToDoItem>(undefined);
    const [selectedNote, setSelectedNote] = React.useState<pApi.Note>(undefined);
    const [linkInfo, setLinkInfo] = React.useState<LinkInfo>(undefined);
    useEffect(() => {
        let func = async () => {
            try {
                if (props.connect.state.selectedState.selectedRows.length == 0 && props.connect.state.selectedState.focused) {


                    if (props.connect.state.selectedState.focused instanceof sl.NoteRow) {
                        let noteRow = props.connect.state.selectedState.focused as sl.NoteRow;
                        setSelectedNote(noteRow.note)
                    }

                    if (props.connect.state.selectedState.focused instanceof sl.ItemRow) {
                        let itemRow = props.connect.state.selectedState.focused as sl.ItemRow;
                        let todos = itemRow.getTodos();
                        if (todos.length == 1) {
                            let todo = todos[0];
                            setSelectedTodo(todo);
                            if (todo.note_id) {
                                let note = props.connect.state.notes.val().find(x => x.id == todo.note_id);
                                setSelectedNote(note);
                            }
                            else {
                                let link = model.TodoLinkHelper.getLink(todo);
                                if (link) {
                                    try {
                                        let metadata = await actions.qGet("/api/v1/url_metadata/" + encodeURIComponent(link));
                                        let li = new LinkInfo();
                                        if (metadata) {

                                            li.title = metadata.title;
                                            li.description = metadata.description;

                                        }
                                        li.url = link;
                                        setLinkInfo(li)
                                    } catch (e) {
                                        console.error(e)
                                    }
                                }
                            }
                        }
                        else {

                        }
                    }
                }

            }
            catch (e) {
                console.error(e);
                setSelectedTodo(undefined)
                setSelectedNote(undefined)
                setLinkInfo(undefined)
            }

        }
        setSelectedTodo(undefined)
        setSelectedNote(undefined)
        setLinkInfo(undefined)
        func();

    }, [props.connect.state.selectedState.selectedRows, props.connect.state.selectedState.focused])
    let snoozed = selectedTodo && selectedTodo.snooze_count != null ? selectedTodo.snooze_count : 0;
    let snoozedText = snoozed > 1 ? `(Snoozed ${snoozed} times)` : "";
    return (

        <div className='container   no-gutters simple-action-shortcut-row '>
            {selectedTodo && (
                <div>
                    <div className="row  no-gutters">
                        <div className="simple-action-shortcut-name simple-action-shortcut-name">
                            <strong>To-Do: {snoozedText}</strong>
                        </div>
                    </div>
                    <div className="row  no-gutters">
                        <div className="simple-action-shortcut-name simple-action-content-cutoff">
                            <strong> {selectedTodo.description} </strong>
                        </div>
                    </div>
                    <div className="row  no-gutters">
                        <div className="simple-action-shortcut-name simple-action-shortcut-name">
                            Created: <strong>{formatRelativeDateWithTime(selectedTodo.created)}</strong>
                        </div>
                    </div>
                    <div className="row  no-gutters">
                        <div className="simple-action-shortcut-name simple-action-shortcut-name">
                            Modified: <strong>{formatRelativeDateWithTime(selectedTodo.modified)}</strong>
                        </div>
                    </div>

                </div>

            )}
            {selectedNote && (
                <div>
                    <div className="row  no-gutters">
                        <div className="simple-action-shortcut-name simple-action-shortcut-name">
                            <strong>Note: {selectedNote.title}</strong>
                        </div>
                    </div>
                    <div className="row  no-gutters">
                        <div className="simple-action-shortcut-name simple-action-content-cutoff">
                            <strong> {selectedNote.preview_text} </strong>
                        </div>
                    </div>
                    <div className="row  no-gutters">
                        <div className="simple-action-shortcut-name simple-action-shortcut-name">
                            Created: <strong>{formatRelativeDateWithTime(selectedNote.created)}</strong>
                        </div>
                    </div>
                    <div className="row  no-gutters">
                        <div className="simple-action-shortcut-name simple-action-shortcut-name">
                            Modified: <strong>{formatRelativeDateWithTime(selectedNote.modified)}</strong>
                        </div>
                    </div>

                        <div className="row  no-gutters">
                            <div className="simple-action-shortcut-name simple-action-shortcut-name">
                                Storage: <strong>{selectedNote.meta.storageType}</strong>
                            </div>
                        </div>

                    </div>
               





               
        )
        }
        </div>


    )

    }

