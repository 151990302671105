import React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import Typography from '@material-ui/core/Typography';
import { fade, makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import * as model from "../lib/model"
import NavigationIcon from '@material-ui/icons/Navigation';
import { getCommands, CommandResultItem } from '../lib/commands'
import { doSearch, SearchResult } from '../lib/search'
import SearchGrid from "../Search/SearchGrid"
import fuzzysort from 'fuzzysort';
const useStyles2 = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    grow: {
        flexGrow: 1,
    },


    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },

    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        /*  marginRight: theme.spacing(2),*/
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            /*  marginLeft: theme.spacing(3),
              width: 'auto',*/
        },
    },
    searchIcon: {
        /* padding: theme.spacing(0, 2),*/
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
        width: '100%',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        /* paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,*/
        transition: theme.transitions.create('width'),
        width: '100%',

    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    //@ts-ignore
    return <Slide direction="up" ref={ref} {...props} />;
});

class FuzzyResult {

    constructor(public result: any, public resultItem: CommandResultItem) {

    }
}
export interface JumpToProps {
    connect: model.ConnectProps
    searchText: string;
    onClose: () => void;
}
export function JumpTo(props: JumpToProps) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [searchTerm, setSearchTerm] = React.useState(props.searchText ? props.searchText : "");

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        props.onClose()
        //setOpen(false);
    };
    let commands = getCommands(props.connect)
    let getPrimaryText = function (fuzzyResult: FuzzyResult) {
        if (fuzzyResult.result) {
            return (<div dangerouslySetInnerHTML={{ __html: fuzzysort.highlight(fuzzyResult.result) }}></div>)
        }
        else return (<div>{fuzzyResult.resultItem.name}</div>)

    }
    let displayCommands: FuzzyResult[] = [];
    if (!searchTerm) {
        commands.filter(x => x.category == "Jump").forEach(x => displayCommands.push(new FuzzyResult(null, x)))
    }
    else {
        let results = fuzzysort.go(searchTerm, commands, {
            keys: ['name', 'text'],
            limit: 25,
            allowTypo: false, // if you don't care about allowing typos
            threshold: -10000, // don't return bad results
        })

        results.forEach(x => {

            //@ts-ignore
            displayCommands.push(new FuzzyResult(x[0], x['obj'] as CommandResultItem))
        });
        if (results.length == 0) {
            let cmd = new CommandResultItem();
            cmd.name = "Search For " + searchTerm;
            cmd.command = (): void => {
                let vs = props.connect.state.viewState.deepCopy();
                vs.searchText = searchTerm;
                vs.appSection = model.AppSections.search;
                window.location.hash = vs.getHash();
                ;
            }
            displayCommands.push(new FuzzyResult(null, cmd))
        }

    }
    let first = displayCommands[0]
    return (
        <div>
            <Dialog fullScreen open={true} onClose={handleClose} TransitionComponent={Transition as any}>
                <AppBar className={classes.appBar} color="transparent">
                    <Toolbar>
                        <IconButton
                            edge="start"
                            className={classes.menuButton}
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleClose}
                        >
                            <CloseIcon />
                        </IconButton>
                        <div className={classes.search}>
                            <InputBase
                                placeholder="Jump to"
                                classes={{
                                    root: classes.inputRoot,
                                    input: classes.inputInput,
                                }}
                                onFocus={() => {
                                    //props.onJump();
                                }}
                                onKeyPress={(ev) => {
                                    console.log(`Pressed keyCode ${ev.key}`);
                                    if (ev.key === 'Enter') {
                                        // Do code here
                                        ev.preventDefault();

                                        if (first) {
                                            props.onClose();
                                            first.resultItem.command();
                                        }
                                    }
                                }}
                                value={searchTerm}
                                onChange={(e) => {
                                    setSearchTerm(e.target.value)
                                }}
                                autoFocus={true}
                                inputProps={{ 'aria-label': 'search' }}
                            />

                        </div>





                    </Toolbar>
                </AppBar>
                <List>
                    {displayCommands.map(x => (
                        <ListItem button selected={x == first} onClick={(e) => {
                            x.resultItem.command();
                            props.onClose()
                        }}>
                            <ListItemText primary={getPrimaryText(x)} secondary={x.resultItem.category == "Jump" ? "Navigate" : x.resultItem.category} />
                        </ListItem>

                    ))}
                </List>
            </Dialog>
        </div>
    );
}


export function SearchMobile(props: JumpToProps) {
    const classes = useStyles();
    const [searchResult, setSearchResults] = React.useState(new SearchResult());
    const [searchText, setSearchText] = React.useState<string>(props.searchText);
    React.useEffect(() => {
        let funcAsyc = async () => {
           
                setSearchText(props.searchText);
                let rs = await doSearch(props.connect, props.searchText);
                setSearchResults(rs);
           
        }
        ;
        if (props.searchText && props.connect.state.searchKeyState != model.LoadingState.Loading
            && props.connect.state.TodosState == model.LoadingState.Completed
            && !props.connect.state.notes.loading()) {
            funcAsyc()
        }
        return;
    }, [props.searchText,props.connect.state.searchKeyState,props.connect.state.TodosState, props.connect.state.notes])
    const handleClose = () => {
        props.onClose()
        //setOpen(false);
    };
    return (
        <div>
            <Dialog fullScreen open={true} onClose={handleClose} TransitionComponent={Transition as any}>
                <AppBar className={classes.appBar} color="transparent">
                    <Toolbar>
                        <IconButton
                            edge="start"
                            className={classes.menuButton}
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleClose}
                        >
                            <CloseIcon />
                        </IconButton>
                        <div className={classes.search}>
                            <InputBase
                                placeholder="Search ..."
                                classes={{
                                    root: classes.inputRoot,
                                    input: classes.inputInput,
                                }}
                                onFocus={() => {
                                    //props.onJump();
                                }}
                                onKeyPress={(ev) => {
                                    console.log(`Pressed keyCode ${ev.key}`);
                                    if (ev.key === 'Enter') {
                                        // Do code here
                                        ev.preventDefault();

                                    }
                                }}
                                value={searchText}
                                onChange={async (e) => {

                                    setSearchText(e.target.value);
                                    let rs = await doSearch(props.connect, e.target.value);
                                    setSearchResults(rs);
                                }}
                                autoFocus={true}
                                inputProps={{ 'aria-label': 'search' }}
                            />

                        </div>





                    </Toolbar>
                </AppBar>
                <SearchGrid connect={props.connect} searchResults={searchResult} />
            </Dialog>
        </div>
    );
}

