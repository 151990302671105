import * as React from "react";


import * as model  from "../../lib/model";

import * as material from "material-ui";

import {TagNode} from './NoteSidebarContent';
import Note from "../CustomSvg/Hash";

interface TagNodeProps {
    connect:model.ConnectProps;
    
    onClick: any;
    node: TagNode;
  }

interface TagNodeState {
   
}

export class TagNodeView extends React.Component<
TagNodeProps,
TagNodeState
> {
  constructor(props, context) {
    super(props, context);

    this.state = {};
  }

  render() {
    var me = this;
    var nestedItems = [];
    for (var i in this.props.node.children) {
      nestedItems.push(
        <TagNodeView
          connect={this.props.connect}
          node={this.props.node.children[i]}
          key={"likch-" + this.props.node.getTagPath() + "-" + i}
          onClick={e => {
            this.props.onClick(e);
          }}
    
        />
      );
    }
    return (
     
        <material.ListItem
          primaryText={this.props.node.tagName}
          key={"lik-" + this.props.node.getTagPath()}
          onClick={e => {
            //HHAAACCKKK@!!!!!

            if (e.target["tagName"] != "path" && e.target["tagName"] != "svg") {
                this.props.onClick(this.props.node);
            }
          }}
          onNestedListToggle={(e: any) => {}}
          leftIcon={<Note />}
          nestedListStyle={{
            "paddingLeft": "10px"
          }}
          nestedItems={nestedItems}
        />
      )
    
   
  }
}

export default TagNodeView;
//export default fakeIt() as TodoItem;//
