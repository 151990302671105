
//import { TagNode, NoteSidebarContent } from '../../client/app/components/NoteSideNav/NoteSidebarContent';

import moment from 'moment'
import convertDown from '../markdown/fromDelta'
export function toPureJavascript(obj: any): any {
  if (obj === null || typeof obj !== "object" || "isActiveClone" in obj)
    return obj;
  var temp = {};
  if (obj instanceof Date) temp = new Date(obj);
  else
    for (var key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        obj["isActiveClone"] = null;
        var r = toPureJavascript(obj[key]);
        if (typeof r != "undefined") temp[key] = r;
        delete obj["isActiveClone"];
      }
    }

  return temp;
}

export function TodoSort(n1: ToDoItem, n2: ToDoItem) {
  var p1 = n1.priority ? n1.priority : ToDoItemPriority.Normal;
  var p2 = n2.priority ? n2.priority : ToDoItemPriority.Normal;

  if (p1 < p2) {
    return 1;
  }
  if (p1 > p2) {
    return -1;
  }

  if (
    n1.due_date &&
    n2.due_date &&
    n1.due_date.toString() != n2.due_date.toString()
  ) {
    if (new Date(n1.due_date) > new Date(n2.due_date)) return 1;
    else return -1;
  }
  if (n1.due_date && !n2.due_date) return -1;
  if (!n1.due_date && n2.due_date) return 1;
  let n1HasTag = n1.tags && n1.tags.length > 0;
  let n2HasTag = n2.tags && n2.tags.length > 0;
  if (n1HasTag && n2HasTag && n1.tags[0] != n2.tags[0]) {
    return n1.tags[0] > n2.tags[0] ? 1 : -1;
  }
  if (n1HasTag && !n2HasTag) return 1;
  if (!n1HasTag && n2HasTag) return -1;
  if (!n1.description)
    return 1;
  if (!n2.description)
    return -1;
  if (n1.description.toLocaleLowerCase() > n2.description.toLocaleLowerCase())
    return 1;
  else return -1;
}
export function CanHideToDo(todo: ToDoItem): boolean {
  if (todo.priority == ToDoItemPriority.High)
    return false;
  if (todo.due_date) {
    return false;
  }
  return todo.tags && todo.tags.length > 0 ? true : false;
}
let generatePushID = (function () {
  // Modeled after base64 web-safe chars, but ordered by ASCII.
  var PUSH_CHARS =
    "-0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ_abcdefghijklmnopqrstuvwxyz";

  // Timestamp of last push, used to prevent local collisions if you push twice in one ms.
  var lastPushTime = 0;

  // We generate 72-bits of randomness which get turned into 12 characters and appended to the
  // timestamp to prevent collisions with other clients.  We store the last characters we
  // generated because in the event of a collision, we'll use those same characters except
  // "incremented" by one.
  var lastRandChars = [];

  return function () {
    var now = new Date().getTime();
    var duplicateTime = now === lastPushTime;
    lastPushTime = now;

    var timeStampChars = new Array(8);
    for (var i = 7; i >= 0; i--) {
      timeStampChars[i] = PUSH_CHARS.charAt(now % 64);
      // NOTE: Can't use << here because javascript will convert to int and lose the upper bits.
      now = Math.floor(now / 64);
    }
    if (now !== 0)
      throw new Error("We should have converted the entire timestamp.");

    var id = timeStampChars.join("");

    if (!duplicateTime) {
      for (i = 0; i < 12; i++) {
        lastRandChars[i] = Math.floor(Math.random() * 64);
      }
    } else {
      // If the timestamp hasn't changed since last push, use the same random number, except incremented by 1.
      for (i = 11; i >= 0 && lastRandChars[i] === 63; i--) {
        lastRandChars[i] = 0;
      }
      lastRandChars[i]++;
    }
    for (i = 0; i < 12; i++) {
      id += PUSH_CHARS.charAt(lastRandChars[i]);
    }
    if (id.length != 20) throw new Error("Length should be 20.");

    return id;
  };
})();
export function newId(): any {
  return generatePushID().replace("-", "");
}
export class OnboardingState {
  hasOpenedDrawerMobile: boolean = false;
  inboxHover: boolean = false;
  inboxSwipe: boolean = false;
  navigationHelp: boolean = false;
  addHelp: boolean = false;
  editTodoHelp: boolean = false;
  noteHelp: boolean = false;
  hasCreatedTodo: boolean = false;
  hasCreatedNote: boolean = false;
  tagHelp: boolean = false;
  addNote: boolean = false;
  welcomePopup: boolean = false;
}
export abstract class baseItem<T> {
  public id: any;
  public meta: any;
  public created: Date;
  public modified: Date;
  public owner_uid: any;
  public modified_uid: any;
  public localstate: any;
  public is_new: boolean = true;
}

export interface IBaseStore<T> {
  getType(): string;
  load(id: any): Promise<T>;
  save(item: baseItem<T>): Promise<T>;
  search(text: string): Promise<Array<T>>;
}
export interface IUpdatable<T> {
  unregisterCallback(cb: UpdateCallback<T>): Promise<void>;
}
export enum ToDoItemPriority {
  Normal = 0,
  High = 1
}
export interface ICacheProvider {
  get(key: any): Promise<any>;
  set(key: any, value: any): Promise<void>;
}


export enum SnoozeAction {
  snooze2,
  laterToday,
  tomorrow,
  weekend,
  nextWeek,
  custom
}
export function getSnoozeDate(snooze: SnoozeAction, custom?: Date): Date {
  var d = custom ? custom : null;
  switch (snooze) {
    case SnoozeAction.snooze2:
      d = moment()
        .add(2, "hours")
        .toDate();
      break;
    case SnoozeAction.laterToday:
      var tomorrow = new Date();

      tomorrow.setDate(tomorrow.getDate());
      tomorrow.setHours(18);
      tomorrow.setMinutes(0);
      tomorrow.setSeconds(0);
      tomorrow.setMilliseconds(0);
      d = tomorrow;
      /* d = moment_timezone
            .tz(
              moment(tomorrow).format("YYYY-MM-DD HH:mm"),
              "America/Chicago"
            )
            .toDate();*/
      break;
    case SnoozeAction.tomorrow:
      var tomorrow = new Date();
      // tomorrow.setDate(tomorrow.getDate() + 1)
      tomorrow.setHours(8);
      tomorrow.setMinutes(0);
      tomorrow.setSeconds(0);
      tomorrow.setMilliseconds(0);
      var searchDate = moment(tomorrow);
      searchDate.add(1, "day");
      d = searchDate.toDate();
      /*
          d = moment_timezone
            .tz(searchDate.format("YYYY-MM-DD HH:mm"), "America/Chicago")
            .toDate();
*/
      break;
    case SnoozeAction.weekend:
      var weekDayToFind = moment()
        .day("Saturday")
        .weekday(); //change to searched day name
      var tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      tomorrow.setHours(8);
      tomorrow.setMinutes(0);
      tomorrow.setSeconds(0);
      tomorrow.setMilliseconds(0);
      var searchDate = moment(tomorrow); //now or change to any date
      while (searchDate.weekday() !== weekDayToFind) {
        searchDate.add(1, "day");
      }
      d = searchDate.toDate(); /*moment_timezone
            .tz(searchDate.format("YYYY-MM-DD HH:mm"), "America/Chicago")
            .toDate();*/

      break;
    case SnoozeAction.nextWeek:
      var weekDayToFind = moment()
        .day("Mondate")
        .weekday(); //change to searched day name
      var tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      tomorrow.setHours(8);
      tomorrow.setMinutes(0);
      tomorrow.setSeconds(0);
      tomorrow.setMilliseconds(0);
      var searchDate = moment(tomorrow); //now or change to any date
      while (searchDate.weekday() !== weekDayToFind) {
        searchDate.add(1, "day");
      }
      d = searchDate.toDate();
      /*
          d = moment_timezone
            .tz(searchDate.format("YYYY-MM-DD HH:mm"), "America/Chicago")
            .toDate();*/

      break;
  }
  return d;
}
export class UpdateCallback<T> {
  public key: string;
  public dispatch: any;
  public callback: (todos: Array<T>) => void;
  constructor(key: string, dispatch: any, callback: (todos: Array<T>) => void) {
    this.key = key;
    this.dispatch = dispatch;
    if (dispatch) {

      this.callback = (todos: Array<T>) => {

        dispatch(callback(todos));
      };
    } else {
      this.callback = callback;
    }
  }
}
export interface ITodoListStore
  extends IBaseStore<ToDoItem>,
  IUpdatable<ToDoItem> {
  getAllOutstandingItems(
    updateCallback?: UpdateCallback<ToDoItem>
  ): Promise<Array<ToDoItem>>;
  getSnoozedItems(
    updateCallback?: UpdateCallback<ToDoItem>
  ): Promise<Array<ToDoItem>>;
  getClosedItems(startAt: any,
    updateCallback?: UpdateCallback<ToDoItem>
  ): Promise<Array<ToDoItem>>;

  //getDueItems(project_id: any): Promise<Array<ToDoItem>>;
  getByProjectType(projectType: ProjectType): Promise<Array<ToDoItem>>;
  getByNote(
    note_id: any,
    updateCallback?: UpdateCallback<ToDoItem>
  ): Promise<Array<ToDoItem>>;
  listByTag(
    tag: string,
    updateCallback?: UpdateCallback<ToDoItem>
  ): Promise<Array<ToDoItem>>;
  //listByTagAndNotes(tag:string,noteIds:Array<any>):Promise<Array<ToDoItem>>;
  complete(id: any): Promise<ToDoItem>;
  snooze(id: any, snooze: SnoozeAction, custom?: Date): Promise<Date>;
  delete(id: any): Promise<void>;
  startBatch(): Promise<void>;
  commitBatch(): Promise<void>;
  cancelBatch(): Promise<void>;
}

export interface INoteStore extends IBaseStore<Note> {
  searchWithTag(
    queryText: string,
    tags?: Array<string>,
    listener?: (notes: Array<Note>) => void
  ): Promise<Array<Note>>;
  setContent(note: Note, content: NoteContent): Promise<Note>;
  getContent(note: Note): Promise<NoteContent>;
  setTags(id: any, tags: Array<string>);
  archive(id: any): Promise<void>;
  list(listener?: (notes: Array<Note>) => void): Promise<Array<Note>>;
  setDefaultStorageType(storageType:StorageType)
  getDefaultStorageType():StorageType
  // getTags(): Promise<Array<string>>;
}
export interface IObjectiveStore
  extends IBaseStore<Objective>,
  IUpdatable<Objective> {
  checkIn(id: any, checkin: ObjectiveCheckIn): Promise<void>;
  getCheckInHistory(id: any, since: Date): Promise<Array<ObjectiveCheckIn>>;
  list(
    includeClosed: boolean,
    updateCallback?: UpdateCallback<Objective>
  ): Promise<Array<Objective>>;
}

export interface IProfileProvider {
  authenticate(email: string, password: string): Promise<string>;
  setToken(token: string): Promise<Profile>;
  authenticateWithGoogle(googleToken: string): Promise<string>;
  getProfile(): Profile;
}
export interface ISettingStore {
  get(listener?: UpdateCallback<DojoSettings>): Promise<DojoSettings>;
  set(settings: DojoSettings): Promise<DojoSettings>;
}
export interface IApiKeyStore {
  get(): Promise<ApiKey>;
  set(key: ApiKey): Promise<ApiKey>;
}
export interface IWebHookStore {
  list(): Promise<Array<WebHook>>;
  archive(id: any): Promise<void>;
  load(id: any): Promise<WebHook>;
  save(hook: WebHook): Promise<WebHook>;

}
export interface IDeviceStore {
  list(): Promise<Array<Device>>;
  getByToken(token: any): Promise<Device>;
  archive(id: any): Promise<void>;
  load(id: any): Promise<Device>;
  save(device: Device): Promise<Device>;
}
export interface ITagStore {
  getTags(listener?: UpdateCallback<Tag>): Promise<Array<Tag>>;
  addTag(name: string): Promise<void>;
  updateTag(tag: Tag): Promise<Tag>;
  addTags(tags: Array<string>);
  checkForRemoval(name: string): Promise<void>;
}

export enum TagInboxSetting {
  showAll = 0,
  overdueAndStar = 1
}
export class Tag extends baseItem<Tag> {
  id: any;
  name: string;
  stared: boolean;
  color: string;
  inboxSetting: TagInboxSetting = TagInboxSetting.showAll;
}

/*
export interface IKeyResultStore extends IBaseStore<KeyResult> {
    findKeyResultsByObjective(objective_id: any): Promise<KeyResult>;
    updateContent(id:any,content:String):Promise<void>;
    checkIn(id:any,checkin:KeyResultCheckIn):Promise<void>;
    list():Promise<Array<KeyResult>>
}*/

export interface ICtx {
  Todos: ITodoListStore;
  /* Habits: IHabitStore;*/
  /*Projects: IProjectStore;*/
  Objectives: IObjectiveStore;
  ProfileProvider: IProfileProvider;
  Settings: ISettingStore;
  Notes: INoteStore;
  Tags: ITagStore;
  Cache: ICacheProvider;
  Devices: IDeviceStore;
  ApiKeys: IApiKeyStore;
  WebHooks: IWebHookStore;
  getId(): any;
  getSyncId(): any;
  // KeyResults:IKeyResultStore;
}
export enum ObjectiveProgressStatus {
  opsUnknown = 1,
  opsOnTrack = 2,
  opsNotOnTrack = 3
}
export enum RecoccuringIntervalEnum {
  kiDaily = 1,
  kiWeekly = 2,
  kiMonthy = 3,
  kiWeekday = 4
}

export class ReoccuringSettings {
  reoccuring_interval: RecoccuringIntervalEnum;
  reoccuring_day_of_week: Number;
  reoccuring_day_of_month: Number;
  time_of_day: Date;
}
export class ToDoItem extends baseItem<ToDoItem> {
  description: string;
  due_date: Date;
  recoccuring: boolean;
  hide_from_inbox?: boolean;
  alarm: boolean;
  recoccuring_settings?: ReoccuringSettings;
  note_id: any;
  project_type: ProjectType;
  checked: boolean;
  priority: ToDoItemPriority;
  objective_id: any;
  tags: Array<string>;
  sync_id?: any;
  snooze_count: number;
  static getApiObject(todo: ToDoItem): any {
    var item: any = {};

    for (var i in todo) {
      switch (i) {
        case "sync_id":
        case "is_new":
        case "project_type":
        case "objective_id":

        case "meta":
          break;
        default:
          item[i] = todo[i];
      }
    }
    return item;
  }
}

export enum ToDoItemSmartWarningType {
  UrgentImportant, UrgentNotImportant, NotUrgentNotImportant, NotUrgentImportant
}
export class ToDoItemSmartWarning {
  static get(todo: ToDoItem): ToDoItemSmartWarningType {
    function datediff(first, second) {
      // Take the difference between the dates and divide by milliseconds per day.
      // Round to nearest whole number to deal with DST.
      return Math.round((second - first) / (1000 * 60 * 60 * 24));
    }
    if (todo.priority == ToDoItemPriority.High) {
      if (datediff(todo.due_date, new Date()) / todo.snooze_count)
        if (datediff(todo.due_date, new Date()) > 7 && todo.snooze_count > 3) {
          return ToDoItemSmartWarningType.NotUrgentImportant;
        }
        else if (datediff(todo.due_date, new Date()) > 14)
          return ToDoItemSmartWarningType.NotUrgentImportant;
    }
  }
}


/*
export class HabitButton {
    display_text: string;
    value: any;
}
export class Habit extends baseItem<Habit> {
    id: any;
    name: string;
    prompt: string;
    nlp_query: string;
    buttons: Array<HabitButton>;
    habit_key: string;
    next_prompt: Date;
}*/

export enum ProjectType {
  ptInbox = 0,
  ptDojo = 1,
  ptProject = 2,
  ptDocTask = 3
}
export class Profile extends baseItem<Profile> {
  profile_id: any;
  account_id: any;
  display_name: string;
  profile_URL: string;
  uuid: any;
  email: string;
}

export enum Platform {
  web,
  ios
}
export enum WebHookTrigger {
  note_add = "note_add",
  note_update = "note_update",
  note_archive = "note_archive",
  todo_add = "todo_add",
  todo_update = "todo_update",
  todo_archive = "todo_archive",
  todo_complete = "todo_complete",
  todo_reopen = "todo_reopen"
}
export class WebHook extends baseItem<WebHook>
{
  name: string;
  url: string;
  trigger: WebHookTrigger;
  action: string = "POST"
  static getApiObject(hook: WebHook): any {

    var item :any= {};
    for (var i in hook) {
      switch (i) {
        case "sync_id":
        case "is_new":
        case "project_type":
        case "objective_id":

        case "meta":
          break;
        default:
          item[i] = hook[i];
      }
    }
    return item;
  }
}

export class ApiKey extends baseItem<ApiKey>{
  token: any;
}

export class DojoSettings extends baseItem<DojoSettings> {
  onboarding: OnboardingState = new OnboardingState();
  minimalUIMode: boolean = false;
  newMobileMode: boolean = false
  embedNoteContent: boolean = false;
}


export class Device extends baseItem<Device> {
  platform: Platform;
  platform_details: string;
  allow_push: boolean;
  token: any;
  last_used: Date;
  archive_date?: Date = null;
}
export class ExternalLink {
  name: string;
  url: string;
}
export class Note extends baseItem<Note> {
  tags: Array<string>;
  meta: any;
  title: string;
  preview_text: string;
  archive_date?: Date = null;
  static getApiObject(note: Note): any {
    let item: any = {}
    for (var i in note) {
      switch (i) {
        case "sync_id":
        case "is_new":
        case "project_type":
        case "objective_id":
        case "meta":
          break;
        default:
          item[i] = note[i];
      }
    }
    return item;

  }



}
export enum StorageType {
  embedded = "embedded",
  snapshot = "snapshot"
}
export enum ContentType {
  quillJS = "quill",
  markdown = "markdown"
}
export class NoteContent extends baseItem<NoteContent> {
  client_id: string;
  //format: string;
  storageType: StorageType;
  content: Array<any>;
  contentType: ContentType;
  static getMarkDown(notecontent: NoteContent): string {

    let ops = [];
   
    for(var i in notecontent.content)
    {
      ops.push(notecontent.content[i])
    }
    return convertDown(ops);
  }

}

/*
export class Project extends baseItem<Project> {
  id: any;
  parent_id?: any;
  name: string;

  has_objective: boolean;
  project_type: ProjectType;
  links: Array<ExternalLink>;
  order: number;
  color: string;
  isInbox() {
    return this.project_type == ProjectType.ptInbox;
  }
  isDojo() {
    return this.project_type == ProjectType.ptDojo;
  }
  
  
}*/

export class Objective extends baseItem<Objective> {
  content: String;
  project_id: any;
  reminder_todo_id: any;
  progress_status: ObjectiveProgressStatus;
  due_date: Date;
  close_date: Date;
  closed: boolean = false;
  checkin_interval: ReoccuringSettings;
  key_results: Array<KeyResult> = new Array<KeyResult>();
  last_checkin: ObjectiveCheckIn;
}

export enum KeyResultMetricType {
  kmtPercentage = 1,
  kmtNumeric = 2,
  kmtYesNo = 3
}
export class KeyResult extends baseItem<KeyResult> {
  id: any;
  content: String;
  objective_id: any;
  metric_type: KeyResultMetricType;
  numerical_goal: number;
  compleated: Boolean;
  deactive: Boolean;
}

export class KeyResultCheckIn {
  key_result_id: any;
  metric_type: KeyResultMetricType;
  //numerical_goal: number;
  value: any;
  percentage_done: Number;
  completed: boolean;
}
export class ObjectiveCheckIn extends baseItem<KeyResult> {
  objective_id: any;
  content: String;
  close: Boolean;
  objective_progress_status: ObjectiveProgressStatus;
  check_in_date: Date;
  key_results: Array<KeyResultCheckIn> = new Array<KeyResultCheckIn>();
}


