import moment from 'moment'

export enum InboxMode {
    inbox,
    onlysnoozed,
    bytag,
    archive,
    today,
    thisWeek,
    bydate,
    empty,
    allNotes,
    search
  }
export enum LoadingState {
    NotLoaded,
    Loading,
    Completed,
    Error
  }
  export class LoadingObject<T> {
    loadingState: LoadingState = LoadingState.NotLoaded;
    version:string;
    notLoaded(): boolean {
        return this.loadingState == LoadingState.NotLoaded
    }
    loading(): boolean {
        return this.loadingState == LoadingState.Loading;
    }
    completed(): boolean {
        return this.loadingState == LoadingState.Completed;
    }
    error(): boolean {
        return this.loadingState == LoadingState.Error;
    }
    errorMessage: string;
    value?: T
    val(): T {
        //@ts-ignore
        return this.value
    }
    setLoading() {
        this.loadingState = LoadingState.Loading;
        this.version = new Date().toISOString();
    };
    setCompleted(value: T) {
        this.loadingState = LoadingState.Completed;
        this.value = value;
        this.version = new Date().toISOString();
    }
    setError(err: any) {
        this.loadingState = LoadingState.Error;
        this.version = new Date().toISOString();
    }
    reset() {
        this.loadingState = LoadingState.NotLoaded;
        this.value = undefined;
        this.version = new Date().toISOString();
    }
  
    constructor(value?: T,version?:string) {
        if (value) {
            this.value = value;
            this.loadingState = LoadingState.Completed;
        }
        this.version=version?version:new Date().toISOString();
    }
  }

  export class User {
    displayName: string;
    email: string;
    token: string;
    photoURL: string;
    meta: any;
  }

  export function formatRelativeDate(date) {
    if (!date) return "";
  
    return moment(date).calendar(null, {
      lastDay: "[Yesterday]",
      sameDay: "[Today]",
      nextDay: "[Tomorrow]",
      lastWeek: "[last] dddd",
      nextWeek: "dddd",
      sameElse: "L"
    });
  }
  export function formatRelativeDateWithTime(date) {
    if (!date) return "";
  
    return moment(date).calendar(null, {
      lastDay: "[Yesterday] @ h:mm a",
      sameDay: "[Today] @ h:mm a",
      nextDay: "[Tomorrow] @ h:mm a",
      lastWeek: "[last] dddd @ h:mm a",
      nextWeek: "dddd @ h:mm a",
      sameElse: "L @ h:mm a"
    });
  }