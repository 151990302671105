import { HybridContext } from "./frontEndContext";

var ImgCache = require("hacked-imgcache");
export class CacheNotes {
    cacheImage(externalUrl: any) {

        console.log("cache_content:ImgCache " + externalUrl)
        ImgCache.isCached(externalUrl, async function (path, success) {
            ;
            if (success) {
                console.log("cache_content:ImgCache in cache " + externalUrl)
                // already cached
            } else {
                console.log("cache_content:ImgCache:Not incache set it to source " + externalUrl);
                // not there, need to cache the image
                ImgCache.cacheFile(externalUrl, function (path, url) {
                    console.log("cache_content:ImgCache:Cache " + externalUrl);

                }, async function (err) {

                    console.error("ImgCache:Cache  err" + err + " return " + externalUrl);


                })
            }
        })

    }
    loopAllContent(last: any, count: number, modified?: Date) {

       

         throw "SOTP"
         /*
        if (!last) {
            if (localStorage.getItem("cacheNotesLastModified")) {

                //last = JSON.parse(localStorage.getItem("cacheNotesLastModified"));
                modified = new Date(localStorage.getItem("cacheNotesLastModified"))

                // alert(last)
            }
        }
        var newquery = this.ctx.getDb().collectionGroup("snapshot"); //.where('uid','==',this.ctx.ProfileProvider.getProfile().uuid)
        newquery = newquery.where("owner_uid", "==", this.ctx.getId());
        if (modified) {

            newquery = newquery.where("fixedModifiedDate", ">", this.ctx.getDateConverter().convertToFireStoreDate(modified))
        }
        //query = query.where("archive_date", "==", null);

        newquery = newquery.limit(1000);
        newquery = newquery.orderBy("fixedModifiedDate");
        if (last) {
            newquery = newquery.startAfter(last)
        }

        let me = this;
        console.log("cache_content:")
        let lastModified = null
        newquery.get().then((querySnapshot) => {

            ; let lastId = null;
            querySnapshot.forEach(function (doc) {


                lastId = doc;

                let data = doc.data();
                console.log("cache_content:" + count + " " + me.ctx.getDateConverter().convertFromFireStoreDate(data.modified))
                lastModified = data.fixedModifiedDate

                for (var i in data.content) {
                    let x = data.content[i]
                    if (x && x.insert && x.insert.image && x.insert.image["external-src"] != null) {
                        me.cacheImage(x.insert.image["external-src"]);
                    }
                }
                if (lastModified) {
                    localStorage.setItem("cacheNotesLastModified", me.ctx.getDateConverter().convertFromFireStoreDate(lastModified).toString());
                }
                //localStorage.setItem("cacheNotesLastModified",JSON.stringify(lastId));

                count++;
                ;
            });
            if (querySnapshot.size > 0) {

                me.loopAllContent(lastId, count, modified)
                //  localStorage.setItem("cacheNotesLastModified",lastModified);

            }
            else {
                setTimeout(() => {
                    me.loopAllContent(null, 0, null);
                }, 30000);


            }



        });
*/
    }
    constructor(private ctx: HybridContext) {

    }
}